import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Layout from './components/Layout';
import AdminBots from './pages/AdminBots';
import EditAdminBot from './pages/EditAdminBot';
import SpamBots from './pages/SpamBots';
import GreetingBot from './pages/GreetingBot';
import ConnectedBots from './pages/ConnectedBots';
import BotInfo from './pages/BotInfo';
import EditSpamBot from './pages/EditSpamBot';
import EditGreetingBot from './pages/EditGreetingBot';
import Commands from './pages/Commands';
import BotsChat from './pages/BotsChat';
import GreetingMessages from './pages/GreetingMessages';
import SpamBotMessages from './pages/SpamBotMessages';
import { WelcomeBotStatistics } from './pages/WelcomeBotStatistics';
import { ActionLayout } from './components/ActionLayout';

const TelegramRoutes = (): JSX.Element => {
  return (
    <>
      <Route path={r.telegram.index} element={<Layout />}>
        <Route path={r.telegram.index} element={<AdminBots />} />
        <Route path={r.telegram.editAdminBot} element={<EditAdminBot />} />
        <Route path={r.telegram.commands} element={<Commands />} />
        <Route path={r.telegram.greetingsMessage} element={<GreetingMessages />} />
        <Route path={r.telegram.botsChat} element={<BotsChat />} />
        <Route path={r.telegram.spamBots} element={<SpamBots />} />
        <Route path={r.telegram.spamBotsChat} element={<SpamBotMessages />} />
        <Route path={r.telegram.editSpamBot} element={<EditSpamBot />} />
        <Route path={r.telegram.constructor.connectedBots} element={<ConnectedBots />} />
        <Route path={r.telegram.constructor.connectedBotInfo} element={<BotInfo />} />
      </Route>
      <Route path={r.telegram.index} element={<ActionLayout />}>
        <Route path={r.telegram.welcomeBotStatistics} element={<WelcomeBotStatistics />} />
        <Route path={r.telegram.greetingBot} element={<GreetingBot />} />
        <Route path={r.telegram.editGreetingBot} element={<EditGreetingBot />} />
      </Route>
    </>
  );
};

export default TelegramRoutes;
