import { FC } from 'react';
import styles from './FolderNav.module.scss';
import Title from 'components/Title';
import FolderGroupInfo from '../../FolderGroupInfo';
import Icon from 'components/Icon';
import ParsingBtn from '../../ParsingBtn';
import useWindowSize from 'hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import r from 'constants/routes';
import { useGetCurrentFolderData } from '../../../vk.selectors';

const FolderNav: FC = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { folderName } = useGetCurrentFolderData();
  return (
    <nav className={styles.Nav}>
      <div className={styles.NavLeftContainer}>
        <div className={styles.NavLeftInner}>
          <Icon
            iconName="chevron-left"
            size={16}
            className={styles.GoBack}
            onClick={() => navigate(r.vk.groupsActions)}
          />
          <div className={styles.FolderNameContainer}>
            <p className={styles.BackFolders}>Папки</p>
            <Title className={styles.Title}>{folderName}</Title>
          </div>
        </div>
        {width > 1024 && <FolderGroupInfo />}
      </div>

      {width > 1024 && <ParsingBtn />}
    </nav>
  );
};

export default FolderNav;
