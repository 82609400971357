import rootApi from 'config/http';
import { EAppTagType, TResponse } from 'interfaces/api';
import {
  PricesMatrix,
  TPlanPeriodId,
  TPlanService,
  TPlanServiceComponents,
  TPlansWithPrice,
  TUserPlan,
} from './tariff.type';

type TCurrentPlan = {
  dateEnd: string;
  dateStart: string;
  displayName: string;
  id: TPlanPeriodId;
  name: string;
  periodName: string;
  price: number;
};

const tariffApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getPrices: builder.query<TResponse<PricesMatrix[]>, null>({
      query: () => ({
        url: '/payments/prices',
        method: 'GET',
      }),
    }),
    getAllPlansWithPrice: builder.query<Array<TPlansWithPrice>, void>({
      query: () => ({
        url: '/plan',
        method: 'GET',
      }),
      providesTags: [EAppTagType.USER],
    }),
    getUserPlan: builder.query<TCurrentPlan, void>({
      query: () => ({
        url: '/plan/user',
        method: 'GET',
      }),
      providesTags: [EAppTagType.USER],
    }),
    getPlanServices: builder.query<TResponse<TPlanService>, void>({
      query: () => ({
        url: '/plan/services',
        method: 'GET',
      }),
      providesTags: [EAppTagType.USER],
    }),
    getPlanServicesComponents: builder.query<TResponse<TPlanServiceComponents>, { planId: string }>(
      {
        query: ({ planId }) => ({
          url: `/plan/${planId}/services/components`,
          method: 'GET',
        }),
      },
    ),
    updateUserPlan: builder.mutation<string, TPlanPeriodId>({
      query: body => ({
        url: '/plan/user',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.USER, EAppTagType.TRANSACTIONS],
    }),
    calculateUserPlanPrice: builder.query<TUserPlan, TPlanPeriodId>({
      query: ({ planPeriodId }) => ({
        url: `/plan/user/calculate/${planPeriodId}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPricesQuery,
  useGetUserPlanQuery,
  useGetAllPlansWithPriceQuery,
  useGetPlanServicesComponentsQuery,
  useGetPlanServicesQuery,
  useUpdateUserPlanMutation,
  useCalculateUserPlanPriceQuery,
} = tariffApi;

export default tariffApi;
