import { FC } from 'react';

import styles from './Header.module.scss';
import r from 'constants/routes';
import HeaderWithTabs from 'components/HeaderWithTabs';
import NavWithTabsMobile from 'components/NavWithTabsMobile';

const links = [
  { link: r.landingDashboard.pages, name: 'Business card' },
  { link: r.landingDashboard.statistics, name: 'Статистика' },
  { link: r.landingDashboard.applications, name: 'Заявки' },
];

const Header: FC = () => {
  return (
    <>
      <HeaderWithTabs className={styles.TabsDesktop} links={links} />
      <NavWithTabsMobile className={styles.TabsMobile} links={links} />
    </>
  );
};

export default Header;
