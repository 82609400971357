import { FC, FormEvent, ReactNode } from 'react';

import styles from './AuthForm.module.scss';
import Logo from 'components/Logo';
import Card from '../Card';
import Title from 'components/Title';
import AuthButton from '../AuthButton';
import Alternative, { AlternativeProps } from '../Alternative';
import { VideoModal } from '../../../../components/VideoModal';

type Props = {
  title: string;
  buttonText?: string;
  footer: AlternativeProps;
  isValid: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  children: ReactNode;
  text?: string;
  hasVideoPresent?: boolean;
};

const AuthForm: FC<Props> = ({
  title,
  buttonText,
  footer,
  text,
  onSubmit,
  isValid,
  children,
  hasVideoPresent,
}) => {
  return (
    <Card className={styles.Card}>
      <Logo className={styles.Logo} />
      {hasVideoPresent && <VideoModal />}
      <Title noMargin={!!text} className={styles.Title}>
        {title}
      </Title>

      {text && <p className={styles.Text}>{text}</p>}

      <form className={styles.Form} onSubmit={onSubmit}>
        <div className={styles.FormFields}>{children}</div>

        {buttonText && <AuthButton disabled={!isValid}>{buttonText}</AuthButton>}
      </form>

      <div className={styles.Alternative}>
        <Alternative {...footer} />
      </div>
    </Card>
  );
};

export default AuthForm;
