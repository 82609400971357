import { FC } from 'react';

import { useSendMessageMutation } from 'modules/notifications/notifications.api';
import { useSelectMailModal } from 'modules/partnership-program/partnership-program.selectors';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { CommonModalProps } from 'components/Modal';
import PartnersNewsletterModal, { PartnersNewsletterModalHandler } from '.';
import useAppDispatch from 'hooks/useAppDispatch';
import { setMailModal } from 'modules/partnership-program/partnership-program.reducer';

type Props = {
  recipients: (string | number)[];
  onCancel?: () => void;
} & Omit<CommonModalProps, 'onSubmit' | 'onClose' | 'isOpen'>;

const SelectedPartnersModal: FC<Props> = ({ recipients, onCancel, ...modalProps }) => {
  const dispatch = useAppDispatch();
  const mailModal = useSelectMailModal();
  const [sendMessage] = useSendMessageMutation();

  const onClose = () => {
    if (onCancel) {
      onCancel();
    }
    dispatch(setMailModal(false));
  };

  const onSubmit: PartnersNewsletterModalHandler = async ({ message }) => {
    await sendMessage({
      recipients,
      title: 'Сообщение от партнера',
      message,
    });
    onClose();
    showFeedback({
      title: 'Сообщение отправлено',
      text: 'Поздравляем! Сообщение было успешно отправлено',
      type: EFeedbackType.CONGRATS,
    });
  };

  return (
    <PartnersNewsletterModal
      isOpen={mailModal}
      onSubmit={onSubmit}
      onClose={onClose}
      {...modalProps}
    />
  );
};

export default SelectedPartnersModal;
