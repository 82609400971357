import { FC } from 'react';
import styles from './PlannedMessageCard.module.scss';
import Button from 'components/Button';
import { TScheduledMessage } from '../../telegram.types';
import { useBotId } from '../../telegram.hooks';
import { useParams } from 'react-router-dom';
import {
  useDeleteAdminScheduledMessageMutation,
  useUpdateAdminScheduledMessageMutation,
} from '../../codegen/telegram.admin.api';
import useDisclosure from 'hooks/useDisclosure';
import NewMessageModal from '../NewMessageModal';
import { getDatesDiff } from '../../telegram.utils';
import { PlanedMessageCardType } from '../../codegen/telegram.constants';
import CreateSpamBotMessageModal from '../../pages/SpamBotMessages/components/CreateSpamBotMessageModal';
import {
  useDeleteSpamScheduledMessageMutation,
  useUpdateSpamScheduledMessageMutation,
} from '../../codegen/telegram.spam.api';

type PlanedMessageCardTypeKeys = keyof typeof PlanedMessageCardType;

type Props = {
  cardType?: PlanedMessageCardTypeKeys;
} & TScheduledMessage;

const PlannedMessageCard: FC<Props> = ({ text, id, dateSend, persist, cardType }) => {
  const botId = useBotId();
  const { chatId } = useParams();
  const [updateMessageSpamPersist, { isLoading: isUpdatingSpamPersist }] =
    useUpdateSpamScheduledMessageMutation();

  const [updatePersist, { isLoading: isUpdating }] = useUpdateAdminScheduledMessageMutation();
  const [deleteSpamMessage, { isLoading: isSpamMessageDeleting }] =
    useDeleteSpamScheduledMessageMutation();
  const [deleteMessage, { isLoading: isDeleting }] = useDeleteAdminScheduledMessageMutation();
  const isDisabled = isUpdating || isDeleting || isSpamMessageDeleting || isUpdatingSpamPersist;
  let date = { hours: 0, days: 0 };

  const {
    open: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure({ blockScroll: true });

  const togglePersist = () => {
    const formData = new FormData();
    formData.append('persist', '' + !persist);
    if (chatId) {
      if (cardType === PlanedMessageCardType.ADMIN_BOT) {
        updatePersist({ botId, messageId: id, chatId, body: formData });
      }
      if (cardType === PlanedMessageCardType.SPAM_BOT) {
        updateMessageSpamPersist({ botId, messageId: id, chatId, body: formData });
      }
    }
  };

  const deleteMessageHandler = () => {
    if (chatId) {
      if (cardType === PlanedMessageCardType.ADMIN_BOT)
        deleteMessage({ botId, messageId: id, chatId });
      if (cardType === PlanedMessageCardType.SPAM_BOT)
        deleteSpamMessage({ botId, messageId: id, chatId });
    }
  };

  const modalHandler = (type?: PlanedMessageCardTypeKeys) => {
    if (type) {
      switch (type) {
        case 'SPAM_BOT':
          return (
            <>
              {isModalOpen && (
                <CreateSpamBotMessageModal
                  isOpen={isModalOpen}
                  onClose={onModalClose}
                  messageId={id}
                />
              )}
            </>
          );
        case 'ADMIN_BOT':
          return (
            <>
              {isModalOpen && (
                <NewMessageModal isOpen={isModalOpen} onClose={onModalClose} messageId={id} />
              )}
            </>
          );
        default:
          return <NewMessageModal isOpen={isModalOpen} onClose={onModalClose} messageId={id} />;
      }
    }
  };

  if (dateSend) {
    date = getDatesDiff(dateSend);
  }

  return (
    <>
      <div className={styles.Root}>
        <div className={styles.CardUpper}>
          <h3 className={styles.Title}>Будет отправлено через: {`${date.days}`} дней</h3>

          <div className={styles.Buttons}>
            <Button
              onClick={togglePersist}
              variant={'tetriary'}
              centralIcon={persist ? 'pause' : 'play'}
              disabled={isDisabled}
            ></Button>
            <Button onClick={onModalOpen} variant={'tetriary'} centralIcon={'settings'}></Button>
            <Button
              disabled={isDisabled}
              onClick={deleteMessageHandler}
              variant={'tetriary'}
              centralIcon={isDeleting ? 'loader' : 'alert-delete'}
            ></Button>
          </div>
        </div>

        <p className={styles.CardText}>{text}</p>
      </div>
      {isModalOpen && <>{modalHandler(cardType)}</>}
    </>
  );
};

export default PlannedMessageCard;
