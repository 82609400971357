import { FC } from 'react';

import styles from './MailingCard.module.scss';
import Button from 'components/Button';
import ActivityIndicator from '../ActivityIndicator';
import ChangedOrCreated from '../ChangedOrCreated';

type Props = {
  id: number;
  name: string;
  active: boolean;
  shouldRepeat?: boolean;
  created: string;
  changed?: string;
};

const MailingCard: FC<Props> = ({ name, created, changed, shouldRepeat, active }) => {
  return (
    <article className={styles.Card}>
      <div className={styles.Content}>
        <div className={styles.NameWrapper}>
          <ActivityIndicator active={active} />
          <div className={styles.Name}>{name}</div>
        </div>
        <ChangedOrCreated className={styles.Time} created={created} changed={changed} />
      </div>
      <Button className={styles.Btn} variant="tetriary">
        {shouldRepeat ? 'Повторить рассылку ' : 'Начать рассылку'}
      </Button>
    </article>
  );
};

export default MailingCard;
