import { EFeedbackType } from 'components/FeedbackModal';

export enum NavTypes {
  AUTOMATION_NAV = 'AUTOMATION_NAV',
}

export const createPostResult = Object.freeze({
  success: {
    title: 'ГОТОВО!',
    text: 'Теперь новые посты будут появляться автоматически',
    type: EFeedbackType.SUCCESS,
  },
  error: {
    title: 'Ошибка!',
    text: 'Произошла ошибка при создании. Попробуйте еще раз',
    type: EFeedbackType.ERROR,
  },
  successStories: {
    title: 'ГОТОВО!',
    text: 'Теперь новые сториз будут появляться автоматически',
    type: EFeedbackType.SUCCESS,
  },

  successTranslation: {
    title: 'ГОТОВО!',
    text: 'Теперь прямые эфиры будут проходить автоматически',
    type: EFeedbackType.SUCCESS,
  },
});
