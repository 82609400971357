import { ChangeEvent, FC } from 'react';
import range from 'lodash-es/range';
import capitalize from 'lodash-es/capitalize';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import ruLocale from 'dayjs/locale/ru';
import { Popover } from '@headlessui/react';

import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { blockInvalidChar } from 'utils/blockInvalidChar';
import styles from './DateModal.module.scss';
import Day from './Day';

dayjs.locale(ruLocale);

const normalize = (num: number) => {
  const prev = num - 1;
  return (prev === -1 ? 6 : prev) + 1;
};

type DaysBodyProps = {
  current: Dayjs;
  selected: Dayjs;
  setDay: (day: Dayjs) => void;
};

const DaysBody: FC<DaysBodyProps> = ({ current, selected, setDay }) => {
  const days = selected.daysInMonth();

  const startOfMonth = normalize(selected.startOf('month').day()) - 1;
  const daysBefore = selected.startOf('month').subtract(1, 'day').daysInMonth();
  const endOfMonth = 7 - normalize(selected.endOf('month').day());

  return (
    <div className={styles.Days}>
      {range(daysBefore - startOfMonth, daysBefore).map(day => (
        <span key={`prev-${day}`} className={classNames(styles.Day, styles.OtherDay)}>
          {day + 1}
        </span>
      ))}
      {range(days).map(day => {
        return (
          <Day
            key={`curr-${day}`}
            onClick={() => setDay(selected.set('date', day + 1))}
            current={current}
            selected={selected}
            day={day}
          />
        );
      })}
      {range(endOfMonth).map(day => (
        <span key={`next-${day}`} className={classNames(styles.Day, styles.OtherDay)}>
          {day + 1}
        </span>
      ))}
    </div>
  );
};

type Props = {
  className?: string;
  setDay?: (day: Dayjs) => void;
  button?: JSX.Element;
  noHours?: boolean;
};
const DateModal: FC<Props> = ({ className, setDay, button, noHours }) => {
  const now = dayjs();
  const { register, getValues, setValue, watch } = useForm<{
    hour: number;
    minute: number;
    date: Dayjs;
  }>({
    defaultValues: {
      hour: now.hour(),
      minute: now.minute(),
      date: now,
    },
  });

  const values = watch();

  const handleHourChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputNumber = event.target.value;

    if (!Number.isNaN(Number(inputNumber)) && Number(inputNumber) <= 23) {
      setValue('hour', +inputNumber);
    }
  };

  const handleMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputNumber = event.target.value;

    if (!Number.isNaN(Number(inputNumber)) && Number(inputNumber) <= 59) {
      setValue('minute', +inputNumber);
    }
  };

  const prevMonth = () => setValue('date', getValues('date').subtract(1, 'month'));
  const nextMonth = () => setValue('date', getValues('date').add(1, 'month'));

  const onSubmit = (cb?: () => void) => () => {
    const newDate = getValues('date').set('hour', values.hour).set('minute', values.minute);
    if (setDay) setDay(newDate);
    cb?.();
  };

  return (
    <Popover className={styles.Popover}>
      <Popover.Button className={styles.PopoverButton}>{button}</Popover.Button>
      <Popover.Panel as="form" className={classNames(styles.DateModal, className)}>
        {({ close }) => (
          <>
            <div className={styles.Space} />
            <div className={styles.Header}>
              <span>Пн</span>
              <span>Вт</span>
              <span>Ср</span>
              <span>Чт</span>
              <span>Пт</span>
              <span>Сб</span>
              <span>Вс</span>
            </div>
            <section className={styles.Body}>
              <div className={styles.MonthLine}>
                <IconButton
                  iconName="arrow-up"
                  className={styles.LeftArrow}
                  iconSize={10}
                  onClick={prevMonth}
                />
                <span>{capitalize(getValues('date').format('MMMM YYYY'))}</span>
                <IconButton
                  iconName="arrow-up"
                  className={styles.RightArrow}
                  iconSize={10}
                  onClick={nextMonth}
                />
              </div>
              <DaysBody
                current={now}
                selected={getValues('date')}
                setDay={day => setValue('date', day)}
              />
              {noHours ? null : (
                <div className={styles.TimeLine}>
                  <label className={styles.TimeLabel} htmlFor="hours">
                    Выбрать время
                  </label>
                  <input
                    id="hours"
                    className={styles.TimeInput}
                    type="number"
                    {...register('hour')}
                    min={0}
                    max={23}
                    value={values.hour}
                    onKeyDown={blockInvalidChar}
                    onChange={handleHourChange}
                  />
                  <span>:</span>
                  <input
                    className={styles.TimeInput}
                    type="number"
                    {...register('minute')}
                    min={0}
                    max={59}
                    value={values.minute}
                    onKeyDown={blockInvalidChar}
                    onChange={handleMinChange}
                  />
                </div>
              )}
              <Button
                className={styles.Button}
                type="button"
                variant="tetriary"
                onClick={onSubmit(close)}
              >
                Сохранить
              </Button>
            </section>
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default DateModal;
