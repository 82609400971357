import { FC } from 'react';

export const NoPaymentIcon: FC = () => {
  return (
    <svg
      width="120"
      height="113"
      viewBox="0 0 120 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9764 65.5769C32.5614 65.5769 41.9528 56.1644 41.9528 44.5536C41.9528 32.9427 32.5614 23.5303 20.9764 23.5303C9.39146 23.5303 0 32.9427 0 44.5536C0 56.1644 9.39146 65.5769 20.9764 65.5769Z"
        fill="#1E1E24"
      />
      <path
        d="M62.929 113C72.859 113 80.9088 104.932 80.9088 94.9801C80.9088 85.028 72.859 76.9601 62.929 76.9601C52.999 76.9601 44.9492 85.028 44.9492 94.9801C44.9492 104.932 52.999 113 62.929 113Z"
        fill="#1E1E24"
      />
      <path
        d="M90.0337 60.0666C106.584 60.0666 120 46.6202 120 30.0333C120 13.4464 106.584 0 90.0337 0C73.4837 0 60.0674 13.4464 60.0674 30.0333C60.0674 46.6202 73.4837 60.0666 90.0337 60.0666Z"
        fill="#1E1E24"
      />
      <path
        d="M83.9056 6.00662H17.9798C15.4973 6.00662 13.4849 8.02358 13.4849 10.5116V97.6082C13.4849 100.096 15.4973 102.113 17.9798 102.113H83.9056C86.3881 102.113 88.4006 100.096 88.4006 97.6082V10.5116C88.4006 8.02358 86.3881 6.00662 83.9056 6.00662Z"
        fill="#1E1E24"
      />
      <path
        d="M73.4177 24.0265H88.4009V97.6081C88.4009 98.8029 87.9273 99.9487 87.0843 100.794C86.2414 101.638 85.0981 102.113 83.9059 102.113H26.97V70.5781C26.97 58.2318 31.8636 46.3912 40.5742 37.6611C49.2848 28.931 61.099 24.0265 73.4177 24.0265Z"
        fill="#2C2C35"
      />
      <path
        d="M83.9056 103.239H17.9798C16.4896 103.239 15.0605 102.646 14.0068 101.59C12.9531 100.533 12.3611 99.1012 12.3611 97.6077V10.5111C12.3611 9.01757 12.9531 7.58524 14.0068 6.52918C15.0605 5.47311 16.4896 4.87982 17.9798 4.87982H83.9056C85.3945 4.88379 86.8214 5.47835 87.8742 6.53355C88.9271 7.58876 89.5203 9.01879 89.5243 10.5111V97.6077C89.5203 99.0999 88.9271 100.53 87.8742 101.585C86.8214 102.64 85.3945 103.235 83.9056 103.239ZM17.9798 7.13232C17.0857 7.13232 16.2282 7.48829 15.596 8.12193C14.9637 8.75557 14.6086 9.61497 14.6086 10.5111V97.6077C14.6086 98.5038 14.9637 99.3632 15.596 99.9968C16.2282 100.63 17.0857 100.986 17.9798 100.986H83.9056C84.7997 100.986 85.6572 100.63 86.2894 99.9968C86.9216 99.3632 87.2768 98.5038 87.2768 97.6077V10.5111C87.2768 9.61497 86.9216 8.75557 86.2894 8.12193C85.6572 7.48829 84.7997 7.13232 83.9056 7.13232H17.9798Z"
        fill="#8F8F90"
      />
      <path
        d="M31.4646 6.00662H70.4208V13.5149C70.4208 14.7097 69.9472 15.8556 69.1042 16.7005C68.2613 17.5453 67.118 18.0199 65.9258 18.0199H35.9595C34.7674 18.0199 33.6241 17.5453 32.7811 16.7005C31.9382 15.8556 31.4646 14.7097 31.4646 13.5149V6.00662Z"
        fill="#1E1E24"
      />
      <path
        d="M65.9259 18.0203H40.4546C40.4546 16.4273 41.086 14.8994 42.21 13.773C43.3339 12.6465 44.8583 12.0137 46.4478 12.0137H70.4209V13.5153C70.4209 14.7101 69.9473 15.856 69.1043 16.7009C68.2614 17.5457 67.1181 18.0203 65.9259 18.0203Z"
        fill="#2C2C35"
      />
      <path
        d="M65.9258 19.1456H35.9595C34.4693 19.1456 33.0402 18.5524 31.9865 17.4963C30.9328 16.4402 30.3408 15.0079 30.3408 13.5144V6.00607C30.3408 5.70737 30.4592 5.42091 30.67 5.20969C30.8807 4.99848 31.1665 4.87982 31.4646 4.87982H70.4207C70.7176 4.88371 71.0011 5.00362 71.211 5.21399C71.4209 5.42437 71.5406 5.70858 71.5445 6.00607V13.5144C71.5405 15.0067 70.9473 16.4367 69.8944 17.4919C68.8416 18.5471 67.4147 19.1417 65.9258 19.1456ZM32.5883 7.13232V13.5144C32.5883 14.4105 32.9435 15.2699 33.5757 15.9035C34.2079 16.5372 35.0654 16.8931 35.9595 16.8931H65.9258C66.8199 16.8931 67.6774 16.5372 68.3096 15.9035C68.9418 15.2699 69.297 14.4105 69.297 13.5144V7.13232H32.5883Z"
        fill="#8F8F90"
      />
      <path
        d="M79.4104 88.5979C95.9603 88.5979 109.377 75.1515 109.377 58.5646C109.377 41.9776 95.9603 28.5312 79.4104 28.5312C62.8605 28.5312 49.4441 41.9776 49.4441 58.5646C49.4441 75.1515 62.8605 88.5979 79.4104 88.5979Z"
        fill="#1E1E24"
      />
      <path
        d="M109.377 58.5658C109.371 53.9867 108.32 49.4696 106.305 45.36C104.289 41.2503 101.363 37.6569 97.75 34.8545C91.9246 35.6805 86.4706 38.2068 82.0682 42.1185C77.6658 46.0303 74.5097 51.1543 72.9934 56.8517C71.4772 62.5491 71.6679 68.5678 73.5418 74.1573C75.4157 79.7469 78.8898 84.6599 83.531 88.2838C90.6909 87.2876 97.2503 83.7311 102 78.2698C106.75 72.8085 109.37 65.8106 109.377 58.5658Z"
        fill="#2C2C35"
      />
      <path
        d="M79.4106 89.7244C73.2616 89.7244 67.2506 87.8969 62.1379 84.4731C57.0252 81.0492 53.0403 76.1828 50.6872 70.4891C48.334 64.7954 47.7183 58.5303 48.918 52.4859C50.1176 46.4416 53.0786 40.8895 57.4266 36.5317C61.7746 32.174 67.3144 29.2063 73.3452 28.004C79.3761 26.8017 85.6273 27.4188 91.3082 29.7772C96.9892 32.1356 101.845 36.1294 105.261 41.2535C108.677 46.3777 110.501 52.4021 110.501 58.5648C110.489 66.8252 107.209 74.7438 101.381 80.5848C95.5535 86.4258 87.6525 89.7125 79.4106 89.7244ZM79.4106 29.6578C73.7061 29.6578 68.1297 31.3532 63.3865 34.5295C58.6434 37.7059 54.9466 42.2205 52.7636 47.5026C50.5805 52.7847 50.0094 58.5969 51.1222 64.2043C52.2351 69.8118 54.9821 74.9625 59.0158 79.0052C63.0495 83.0479 68.1888 85.8011 73.7837 86.9165C79.3786 88.0318 85.1779 87.4594 90.4482 85.2715C95.7184 83.0836 100.223 79.3785 103.392 74.6247C106.562 69.871 108.253 64.2821 108.253 58.5648C108.245 50.9007 105.204 43.5527 99.7966 38.1332C94.3893 32.7138 87.0577 29.6657 79.4106 29.6578Z"
        fill="#8F8F90"
      />
      <path
        d="M56.7411 51.8381C56.6222 51.8598 56.5004 51.8598 56.3815 51.8381C56.2355 51.7878 56.1011 51.7086 55.9862 51.6053C55.8713 51.5019 55.7783 51.3765 55.7126 51.2365C55.6469 51.0965 55.61 50.9446 55.6039 50.79C55.5979 50.6354 55.6228 50.4812 55.6773 50.3364C56.8545 46.9458 58.7397 43.8457 61.2071 41.2433C63.6746 38.6408 66.6675 36.5958 69.9862 35.2447C70.123 35.1885 70.2694 35.1598 70.4172 35.1603C70.565 35.1609 70.7113 35.1906 70.8476 35.2477C70.984 35.3049 71.1078 35.3884 71.2119 35.4936C71.316 35.5987 71.3985 35.7233 71.4546 35.8604C71.5106 35.9974 71.5392 36.1442 71.5387 36.2923C71.5382 36.4405 71.5086 36.587 71.4515 36.7237C71.3945 36.8603 71.3111 36.9844 71.2063 37.0888C71.1014 37.1931 70.977 37.2758 70.8403 37.332C67.8171 38.5567 65.09 40.4148 62.8418 42.782C60.5937 45.1491 58.8763 47.9706 57.8049 51.0572C57.7303 51.2819 57.5879 51.4778 57.3973 51.6177C57.2067 51.7576 56.9774 51.8346 56.7411 51.8381Z"
        fill="#8F8F90"
      />
      <path
        d="M55.4377 60.9074C55.1498 60.9078 54.8727 60.7974 54.6636 60.599C54.4545 60.4006 54.3293 60.1294 54.314 59.8412C54.314 59.4207 54.314 59.0003 54.314 58.5648C54.314 57.6771 54.359 56.79 54.4488 55.9068C54.4584 55.7551 54.4985 55.6069 54.5668 55.4711C54.6351 55.3353 54.7301 55.2148 54.8461 55.1168C54.9621 55.0188 55.0967 54.9454 55.2418 54.901C55.3869 54.8565 55.5394 54.842 55.6903 54.8582C55.8411 54.8744 55.9871 54.9211 56.1195 54.9954C56.2518 55.0697 56.3678 55.1701 56.4604 55.2906C56.553 55.411 56.6203 55.549 56.6583 55.6962C56.6963 55.8434 56.7041 55.9968 56.6813 56.1471C56.6813 56.943 56.5614 57.7689 56.5614 58.5648C56.5614 58.9552 56.5614 59.3457 56.5614 59.7361C56.5675 59.884 56.5443 60.0317 56.4933 60.1706C56.4424 60.3096 56.3646 60.4371 56.2644 60.5459C56.1642 60.6547 56.0435 60.7426 55.9094 60.8047C55.7753 60.8667 55.6303 60.9016 55.4826 60.9074H55.4377Z"
        fill="#8F8F90"
      />
      <path
        d="M79.4104 59.6911C79.2664 59.7139 79.1198 59.7139 78.9758 59.6911C78.772 59.6012 78.5984 59.4542 78.4761 59.2676C78.3538 59.0811 78.288 58.8631 78.2866 58.6399V28.5315C78.2866 28.2328 78.405 27.9464 78.6158 27.7351C78.8265 27.5239 79.1123 27.4053 79.4104 27.4053C79.7084 27.4053 79.9942 27.5239 80.205 27.7351C80.4157 27.9464 80.5341 28.2328 80.5341 28.5315V55.8618L99.8773 36.5955C99.9725 36.4565 100.098 36.3406 100.243 36.2566C100.389 36.1725 100.552 36.1222 100.72 36.1096C100.887 36.0969 101.056 36.1222 101.212 36.1835C101.369 36.2447 101.51 36.3405 101.625 36.4636C101.74 36.5868 101.826 36.7342 101.876 36.8951C101.926 37.0559 101.94 37.226 101.916 37.3929C101.892 37.5597 101.831 37.7191 101.738 37.8591C101.644 37.9992 101.52 38.1165 101.376 38.2022L80.2045 59.3757C79.9898 59.5788 79.7056 59.6917 79.4104 59.6911Z"
        fill="#8F8F90"
      />
      <path
        d="M59.9325 82.442C59.6631 82.4443 59.4021 82.3482 59.1983 82.1717C58.9747 81.9748 58.8369 81.6979 58.8145 81.4003C58.7921 81.1028 58.8869 80.8083 59.0784 80.58L78.5565 57.8297C78.7271 57.6322 78.9608 57.5002 79.2177 57.4564C79.4747 57.4126 79.7388 57.4596 79.965 57.5895L105.916 72.6061C106.163 72.7564 106.343 72.9951 106.421 73.2739C106.499 73.5528 106.468 73.8508 106.335 74.1078C106.185 74.3555 105.946 74.5359 105.667 74.6114C105.388 74.6869 105.091 74.6517 104.837 74.5132L79.6503 60.0672L60.7116 82.0516C60.5138 82.2801 60.2336 82.4205 59.9325 82.442Z"
        fill="#8F8F90"
      />
      <path
        d="M66.0011 60.9979C65.5378 58.3417 65.8698 55.6075 66.9553 53.1402C68.0407 50.673 69.831 48.5832 72.1002 47.1345C74.3694 45.6858 77.0158 44.9432 79.7057 45.0003C82.3955 45.0574 85.0082 45.9117 87.2142 47.4553C89.4201 48.999 91.1205 51.1629 92.1008 53.674C93.0811 56.1851 93.2973 58.9309 92.7222 61.5651C92.1471 64.1992 90.8065 66.6036 88.8695 68.475C86.9325 70.3464 84.4858 71.6009 81.8382 72.0802C80.0718 72.3999 78.2597 72.3666 76.5061 71.9821C74.7526 71.5975 73.0922 70.8694 71.6204 69.8395C70.1486 68.8096 68.8945 67.4982 67.9301 65.9809C66.9657 64.4635 66.3101 62.77 66.0011 60.9979Z"
        fill="#1E1E24"
      />
      <path
        d="M92.8052 56.131C92.4549 54.192 91.688 52.3525 90.5577 50.74C89.4209 50.6588 88.2785 50.7193 87.1566 50.9202C84.9091 51.3269 82.7999 52.2942 81.0235 53.7327C79.2472 55.1713 77.8607 57.0349 76.992 59.1517C76.1232 61.2686 75.8002 63.5706 76.0526 65.8455C76.305 68.1203 77.1248 70.295 78.4364 72.1688C80.5128 72.3438 82.6014 72.0342 84.5383 71.2642C86.4752 70.4942 88.2078 69.2848 89.6 67.7309C90.9921 66.1771 92.0061 64.321 92.5622 62.3083C93.1183 60.2957 93.2015 58.1813 92.8052 56.131Z"
        fill="#2C2C35"
      />
      <path
        d="M79.4107 73.3411C76.4947 73.3411 73.6442 72.4745 71.2197 70.8508C68.7951 69.2272 66.9054 66.9194 65.7895 64.2194C64.6736 61.5193 64.3817 58.5483 64.9505 55.682C65.5194 52.8156 66.9236 50.1827 68.9855 48.1162C71.0474 46.0497 73.6744 44.6424 76.5344 44.0722C79.3943 43.5021 82.3587 43.7947 85.0527 44.9131C87.7467 46.0315 90.0493 47.9254 91.6694 50.3554C93.2894 52.7853 94.1541 55.6422 94.1541 58.5647C94.1501 62.4824 92.5955 66.2385 89.8314 69.0088C87.0674 71.779 83.3196 73.3371 79.4107 73.3411ZM79.4107 46.0408C76.9392 46.0408 74.5232 46.7753 72.4683 48.1515C70.4133 49.5276 68.8117 51.4836 67.8659 53.772C66.9201 56.0604 66.6727 58.5786 67.1548 61.008C67.637 63.4374 68.8271 65.6689 70.5747 67.4204C72.3223 69.1719 74.5488 70.3647 76.9728 70.8479C79.3968 71.3312 81.9093 71.0832 84.1927 70.1353C86.476 69.1874 88.4276 67.5821 89.8007 65.5226C91.1737 63.4631 91.9066 61.0417 91.9066 58.5647C91.9026 55.2444 90.5848 52.0612 88.2422 49.7134C85.8997 47.3655 82.7236 46.0448 79.4107 46.0408Z"
        fill="#8F8F90"
      />
      <path
        d="M29.9659 27.0302H23.9727C23.1452 27.0302 22.4744 27.7026 22.4744 28.5319V34.5386C22.4744 35.3679 23.1452 36.0402 23.9727 36.0402H29.9659C30.7934 36.0402 31.4643 35.3679 31.4643 34.5386V28.5319C31.4643 27.7026 30.7934 27.0302 29.9659 27.0302Z"
        fill="#1E1E24"
      />
      <path
        d="M29.9659 45.0488H23.9727C23.1452 45.0488 22.4744 45.7211 22.4744 46.5505V52.5572C22.4744 53.3865 23.1452 54.0588 23.9727 54.0588H29.9659C30.7934 54.0588 31.4643 53.3865 31.4643 52.5572V46.5505C31.4643 45.7211 30.7934 45.0488 29.9659 45.0488Z"
        fill="#1E1E24"
      />
      <path
        d="M29.9659 63.085H23.9727C23.1452 63.085 22.4744 63.7573 22.4744 64.5866V70.5933C22.4744 71.4226 23.1452 72.095 23.9727 72.095H29.9659C30.7934 72.095 31.4643 71.4226 31.4643 70.5933V64.5866C31.4643 63.7573 30.7934 63.085 29.9659 63.085Z"
        fill="#1E1E24"
      />
      <path
        d="M29.9659 81.1044H23.9727C23.1452 81.1044 22.4744 81.7767 22.4744 82.6061V88.6128C22.4744 89.4421 23.1452 90.1144 23.9727 90.1144H29.9659C30.7934 90.1144 31.4643 89.4421 31.4643 88.6128V82.6061C31.4643 81.7767 30.7934 81.1044 29.9659 81.1044Z"
        fill="#1E1E24"
      />
      <path
        d="M26.9693 31.5352H31.4642V34.5385C31.4642 34.9368 31.3063 35.3187 31.0254 35.6003C30.7444 35.8819 30.3633 36.0402 29.9659 36.0402H25.4709V33.0368C25.4709 32.6386 25.6288 32.2566 25.9098 31.975C26.1908 31.6934 26.5719 31.5352 26.9693 31.5352Z"
        fill="#2C2C35"
      />
      <path
        d="M26.9693 85.5959H31.4642V88.5993C31.4642 88.9975 31.3063 89.3795 31.0254 89.6611C30.7444 89.9427 30.3633 90.1009 29.9659 90.1009H25.4709V87.0976C25.4709 86.6993 25.6288 86.3174 25.9098 86.0358C26.1908 85.7542 26.5719 85.5959 26.9693 85.5959Z"
        fill="#2C2C35"
      />
      <path
        d="M26.9693 49.5557H31.4642V52.559C31.4642 52.9573 31.3063 53.3392 31.0254 53.6208C30.7444 53.9025 30.3633 54.0607 29.9659 54.0607H25.4709V51.0573C25.4709 50.6591 25.6288 50.2771 25.9098 49.9955C26.1908 49.7139 26.5719 49.5557 26.9693 49.5557Z"
        fill="#2C2C35"
      />
      <path
        d="M26.9693 67.5741H31.4642V70.5775C31.4642 70.9757 31.3063 71.3577 31.0254 71.6393C30.7444 71.9209 30.3633 72.0791 29.9659 72.0791H25.4709V69.0758C25.4709 68.6775 25.6288 68.2956 25.9098 68.014C26.1908 67.7323 26.5719 67.5741 26.9693 67.5741Z"
        fill="#2C2C35"
      />
      <path
        d="M29.9661 37.1659H23.9729C23.2775 37.1659 22.6105 36.8891 22.1188 36.3962C21.6271 35.9034 21.3508 35.235 21.3508 34.538V28.5314C21.3508 27.8344 21.6271 27.166 22.1188 26.6731C22.6105 26.1803 23.2775 25.9034 23.9729 25.9034H29.9661C30.6603 25.9074 31.325 26.1855 31.8159 26.6775C32.3067 27.1695 32.5843 27.8356 32.5882 28.5314V34.538C32.5882 35.235 32.3119 35.9034 31.8202 36.3962C31.3285 36.8891 30.6615 37.1659 29.9661 37.1659ZM23.9729 28.1559C23.8735 28.1559 23.7783 28.1955 23.708 28.2659C23.6378 28.3363 23.5983 28.4318 23.5983 28.5314V34.538C23.5983 34.6376 23.6378 34.7331 23.708 34.8035C23.7783 34.8739 23.8735 34.9134 23.9729 34.9134H29.9661C30.0655 34.9134 30.1608 34.8739 30.231 34.8035C30.3013 34.7331 30.3407 34.6376 30.3407 34.538V28.5314C30.337 28.433 30.2964 28.3396 30.2269 28.27C30.1574 28.2004 30.0643 28.1597 29.9661 28.1559H23.9729Z"
        fill="#8F8F90"
      />
      <path
        d="M29.9661 55.1863H23.9729C23.2775 55.1863 22.6105 54.9095 22.1188 54.4166C21.6271 53.9238 21.3508 53.2554 21.3508 52.5584V46.5517C21.3508 45.8548 21.6271 45.1864 22.1188 44.6935C22.6105 44.2007 23.2775 43.9238 23.9729 43.9238H29.9661C30.6603 43.9278 31.325 44.2059 31.8159 44.6979C32.3067 45.1899 32.5843 45.856 32.5882 46.5517V52.5584C32.5882 53.2554 32.3119 53.9238 31.8202 54.4166C31.3285 54.9095 30.6615 55.1863 29.9661 55.1863ZM23.9729 46.1763C23.8735 46.1763 23.7783 46.2159 23.708 46.2863C23.6378 46.3567 23.5983 46.4522 23.5983 46.5517V52.5584C23.5983 52.658 23.6378 52.7535 23.708 52.8239C23.7783 52.8943 23.8735 52.9338 23.9729 52.9338H29.9661C30.0655 52.9338 30.1608 52.8943 30.231 52.8239C30.3013 52.7535 30.3407 52.658 30.3407 52.5584V46.5517C30.337 46.4534 30.2964 46.36 30.2269 46.2904C30.1574 46.2208 30.0643 46.18 29.9661 46.1763H23.9729Z"
        fill="#8F8F90"
      />
      <path
        d="M29.9662 73.2216H23.9729C23.6273 73.2216 23.2851 73.1531 22.966 73.0201C22.6469 72.8871 22.3572 72.6922 22.1135 72.4466C21.8699 72.201 21.677 71.9095 21.5461 71.5889C21.4153 71.2684 21.3489 70.925 21.3509 70.5787V64.572C21.3509 63.875 21.6271 63.2066 22.1189 62.7138C22.6106 62.221 23.2775 61.9441 23.9729 61.9441H29.9662C30.6604 61.948 31.325 62.2262 31.8159 62.7181C32.3068 63.2101 32.5843 63.8763 32.5882 64.572V70.5787C32.5882 71.277 32.3125 71.947 31.8212 72.4422C31.3299 72.9374 30.663 73.2176 29.9662 73.2216ZM23.9729 64.2116C23.8736 64.2116 23.7783 64.2512 23.7081 64.3216C23.6378 64.392 23.5983 64.4875 23.5983 64.587V70.5937C23.5983 70.6932 23.6378 70.7887 23.7081 70.8591C23.7783 70.9295 23.8736 70.9691 23.9729 70.9691H29.9662C30.0643 70.9654 30.1575 70.9246 30.2269 70.855C30.2964 70.7854 30.3371 70.6921 30.3408 70.5937V64.572C30.3371 64.4736 30.2964 64.3803 30.2269 64.3107C30.1575 64.2411 30.0643 64.2003 29.9662 64.1966L23.9729 64.2116Z"
        fill="#8F8F90"
      />
      <path
        d="M29.9662 91.2401H23.9729C23.6273 91.2401 23.2851 91.1716 22.966 91.0386C22.6469 90.9056 22.3572 90.7107 22.1135 90.4651C21.8699 90.2195 21.677 89.928 21.5461 89.6074C21.4153 89.2869 21.3489 88.9435 21.3509 88.5972V82.5905C21.3548 81.8961 21.6328 81.2316 22.1241 80.742C22.6154 80.2524 23.2801 79.9776 23.9729 79.9776H29.9662C30.659 79.9776 31.3237 80.2524 31.815 80.742C32.3063 81.2316 32.5843 81.8961 32.5882 82.5905V88.5972C32.5882 89.2955 32.3125 89.9655 31.8212 90.4607C31.3299 90.9559 30.663 91.2361 29.9662 91.2401ZM23.9729 82.2301C23.8736 82.2301 23.7783 82.2697 23.7081 82.3401C23.6378 82.4105 23.5983 82.5059 23.5983 82.6055V88.6122C23.5983 88.7117 23.6378 88.8072 23.7081 88.8776C23.7783 88.948 23.8736 88.9876 23.9729 88.9876H29.9662C30.0643 88.9839 30.1575 88.9431 30.2269 88.8735C30.2964 88.8039 30.3371 88.7106 30.3408 88.6122V82.5905C30.3408 82.4909 30.3013 82.3954 30.231 82.325C30.1608 82.2546 30.0655 82.2151 29.9662 82.2151L23.9729 82.2301Z"
        fill="#8F8F90"
      />
      <path
        d="M59.9325 29.6578H48.83C48.532 29.6578 48.2462 29.5391 48.0354 29.3279C47.8247 29.1167 47.7063 28.8302 47.7063 28.5315C47.7063 28.2328 47.8247 27.9464 48.0354 27.7351C48.2462 27.5239 48.532 27.4053 48.83 27.4053H59.9325C60.2306 27.4053 60.5164 27.5239 60.7271 27.7351C60.9379 27.9464 61.0563 28.2328 61.0563 28.5315C61.0563 28.8302 60.9379 29.1167 60.7271 29.3279C60.5164 29.5391 60.2306 29.6578 59.9325 29.6578Z"
        fill="#8F8F90"
      />
      <path
        d="M42.8367 29.6578H37.4577C37.1597 29.6578 36.8739 29.5391 36.6631 29.3279C36.4524 29.1167 36.334 28.8302 36.334 28.5315C36.334 28.2328 36.4524 27.9464 36.6631 27.7351C36.8739 27.5239 37.1597 27.4053 37.4577 27.4053H42.8367C43.1347 27.4053 43.4205 27.5239 43.6313 27.7351C43.842 27.9464 43.9604 28.2328 43.9604 28.5315C43.9604 28.8302 43.842 29.1167 43.6313 29.3279C43.4205 29.5391 43.1347 29.6578 42.8367 29.6578Z"
        fill="#8F8F90"
      />
      <path
        d="M53.9392 35.6646H37.4577C37.1597 35.6646 36.8739 35.546 36.6631 35.3347C36.4524 35.1235 36.334 34.8371 36.334 34.5384C36.334 34.2397 36.4524 33.9532 36.6631 33.742C36.8739 33.5308 37.1597 33.4121 37.4577 33.4121H53.9392C54.2372 33.4121 54.523 33.5308 54.7338 33.742C54.9445 33.9532 55.0629 34.2397 55.0629 34.5384C55.0629 34.8371 54.9445 35.1235 54.7338 35.3347C54.523 35.546 54.2372 35.6646 53.9392 35.6646Z"
        fill="#8F8F90"
      />
      <path
        d="M46.4476 47.6782H37.4577C37.1597 47.6782 36.8739 47.5595 36.6631 47.3483C36.4524 47.1371 36.334 46.8506 36.334 46.5519C36.334 46.2532 36.4524 45.9667 36.6631 45.7555C36.8739 45.5443 37.1597 45.4257 37.4577 45.4257H46.4476C46.7456 45.4257 47.0315 45.5443 47.2422 45.7555C47.4529 45.9667 47.5713 46.2532 47.5713 46.5519C47.5713 46.8506 47.4529 47.1371 47.2422 47.3483C47.0315 47.5595 46.7456 47.6782 46.4476 47.6782Z"
        fill="#8F8F90"
      />
      <path
        d="M44.9493 53.6851H37.4577C37.1597 53.6851 36.8739 53.5665 36.6631 53.3552C36.4524 53.144 36.334 52.8576 36.334 52.5589C36.334 52.2602 36.4524 51.9737 36.6631 51.7625C36.8739 51.5513 37.1597 51.4326 37.4577 51.4326H44.9493C45.2473 51.4326 45.5332 51.5513 45.7439 51.7625C45.9546 51.9737 46.073 52.2602 46.073 52.5589C46.073 52.8576 45.9546 53.144 45.7439 53.3552C45.5332 53.5665 45.2473 53.6851 44.9493 53.6851Z"
        fill="#8F8F90"
      />
      <path
        d="M44.9493 65.698H37.4577C37.1597 65.698 36.8739 65.5793 36.6631 65.3681C36.4524 65.1569 36.334 64.8704 36.334 64.5717C36.334 64.273 36.4524 63.9866 36.6631 63.7754C36.8739 63.5642 37.1597 63.4455 37.4577 63.4455H44.9493C45.2473 63.4455 45.5332 63.5642 45.7439 63.7754C45.9546 63.9866 46.073 64.273 46.073 64.5717C46.073 64.8704 45.9546 65.1569 45.7439 65.3681C45.5332 65.5793 45.2473 65.698 44.9493 65.698Z"
        fill="#8F8F90"
      />
      <path
        d="M46.4476 71.7193H37.4577C37.1597 71.7193 36.8739 71.6006 36.6631 71.3894C36.4524 71.1782 36.334 70.8917 36.334 70.593C36.334 70.2943 36.4524 70.0079 36.6631 69.7967C36.8739 69.5855 37.1597 69.4668 37.4577 69.4668H46.4476C46.7456 69.4668 47.0315 69.5855 47.2422 69.7967C47.4529 70.0079 47.5713 70.2943 47.5713 70.593C47.5713 70.8917 47.4529 71.1782 47.2422 71.3894C47.0315 71.6006 46.7456 71.7193 46.4476 71.7193Z"
        fill="#8F8F90"
      />
      <path
        d="M53.9392 83.733H37.4577C37.1597 83.733 36.8739 83.6143 36.6631 83.4031C36.4524 83.1919 36.334 82.9054 36.334 82.6067C36.334 82.308 36.4524 82.0216 36.6631 81.8103C36.8739 81.5991 37.1597 81.4805 37.4577 81.4805H53.9392C54.2372 81.4805 54.523 81.5991 54.7338 81.8103C54.9445 82.0216 55.0629 82.308 55.0629 82.6067C55.0629 82.9054 54.9445 83.1919 54.7338 83.4031C54.523 83.6143 54.2372 83.733 53.9392 83.733Z"
        fill="#8F8F90"
      />
      <path
        d="M59.9324 89.7388H52.4409C52.1428 89.7388 51.857 89.6202 51.6463 89.409C51.4355 89.1977 51.3171 88.9113 51.3171 88.6126C51.3171 88.3139 51.4355 88.0274 51.6463 87.8162C51.857 87.605 52.1428 87.4863 52.4409 87.4863H59.9324C60.2305 87.4863 60.5163 87.605 60.727 87.8162C60.9378 88.0274 61.0562 88.3139 61.0562 88.6126C61.0562 88.9113 60.9378 89.1977 60.727 89.409C60.5163 89.6202 60.2305 89.7388 59.9324 89.7388Z"
        fill="#8F8F90"
      />
      <path
        d="M46.4476 89.7388H37.4577C37.1597 89.7388 36.8739 89.6202 36.6631 89.409C36.4524 89.1977 36.334 88.9113 36.334 88.6126C36.334 88.3139 36.4524 88.0274 36.6631 87.8162C36.8739 87.605 37.1597 87.4863 37.4577 87.4863H46.4476C46.7456 87.4863 47.0315 87.605 47.2422 87.8162C47.4529 88.0274 47.5713 88.3139 47.5713 88.6126C47.5713 88.9113 47.4529 89.1977 47.2422 89.409C47.0315 89.6202 46.7456 89.7388 46.4476 89.7388Z"
        fill="#8F8F90"
      />
      <path
        d="M100.387 14.6423H94.3938C94.0957 14.6423 93.8099 14.5236 93.5992 14.3124C93.3884 14.1012 93.27 13.8148 93.27 13.5161C93.27 13.2174 93.3884 12.9309 93.5992 12.7197C93.8099 12.5085 94.0957 12.3898 94.3938 12.3898H100.387C100.685 12.3898 100.971 12.5085 101.182 12.7197C101.392 12.9309 101.511 13.2174 101.511 13.5161C101.511 13.8148 101.392 14.1012 101.182 14.3124C100.971 14.5236 100.685 14.6423 100.387 14.6423Z"
        fill="#8F8F90"
      />
      <path
        d="M97.3903 17.6439C97.0923 17.6439 96.8065 17.5253 96.5957 17.3141C96.385 17.1028 96.2666 16.8164 96.2666 16.5177V10.511C96.2666 10.2123 96.385 9.92585 96.5957 9.71464C96.8065 9.50342 97.0923 9.38477 97.3903 9.38477C97.6884 9.38477 97.9742 9.50342 98.1849 9.71464C98.3957 9.92585 98.5141 10.2123 98.5141 10.511V16.5177C98.5102 16.8152 98.3906 17.0994 98.1806 17.3098C97.9707 17.5201 97.6872 17.64 97.3903 17.6439Z"
        fill="#8F8F90"
      />
      <path
        d="M109.377 28.1559H103.384C103.085 28.1559 102.8 28.0373 102.589 27.8261C102.378 27.6149 102.26 27.3284 102.26 27.0297C102.26 26.731 102.378 26.4445 102.589 26.2333C102.8 26.0221 103.085 25.9034 103.384 25.9034H109.377C109.675 25.9034 109.961 26.0221 110.171 26.2333C110.382 26.4445 110.5 26.731 110.5 27.0297C110.5 27.3284 110.382 27.6149 110.171 27.8261C109.961 28.0373 109.675 28.1559 109.377 28.1559Z"
        fill="#8F8F90"
      />
      <path
        d="M106.38 31.1588C106.082 31.1588 105.796 31.0402 105.585 30.8289C105.375 30.6177 105.256 30.3313 105.256 30.0326V24.0259C105.256 23.7272 105.375 23.4407 105.585 23.2295C105.796 23.0183 106.082 22.8997 106.38 22.8997C106.678 22.8997 106.964 23.0183 107.175 23.2295C107.385 23.4407 107.504 23.7272 107.504 24.0259V30.0326C107.5 30.3301 107.38 30.6143 107.17 30.8246C106.96 31.035 106.677 31.1549 106.38 31.1588Z"
        fill="#8F8F90"
      />
      <path
        d="M103.384 92.7272H97.3903C97.0923 92.7272 96.8065 92.6086 96.5957 92.3974C96.385 92.1861 96.2666 91.8997 96.2666 91.601C96.2666 91.3023 96.385 91.0158 96.5957 90.8046C96.8065 90.5934 97.0923 90.4747 97.3903 90.4747H103.384C103.682 90.4747 103.967 90.5934 104.178 90.8046C104.389 91.0158 104.507 91.3023 104.507 91.601C104.507 91.8997 104.389 92.1861 104.178 92.3974C103.967 92.6086 103.682 92.7272 103.384 92.7272Z"
        fill="#8F8F90"
      />
      <path
        d="M100.387 95.7318C100.239 95.7338 100.092 95.7061 99.9546 95.6502C99.8174 95.5943 99.6927 95.5114 99.5879 95.4064C99.4832 95.3015 99.4005 95.1765 99.3447 95.0389C99.289 94.9014 99.2613 94.754 99.2633 94.6056V88.5989C99.2633 88.3002 99.3817 88.0137 99.5924 87.8025C99.8032 87.5913 100.089 87.4727 100.387 87.4727C100.685 87.4727 100.971 87.5913 101.182 87.8025C101.392 88.0137 101.511 88.3002 101.511 88.5989V94.6056C101.511 94.9043 101.392 95.1907 101.182 95.4019C100.971 95.6132 100.685 95.7318 100.387 95.7318Z"
        fill="#8F8F90"
      />
    </svg>
  );
};
