import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './CardButton.module.scss';
import Icon, { IIcon } from 'components/Icon';

export type CardButtonProps = {
  icon: string | JSX.Element;
  children: ReactNode;
  sideIcon?: IIcon;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const CardButton: FC<CardButtonProps> = ({ icon, sideIcon, children, ...btnProps }) => {
  return (
    <div className={styles.Wrapper}>
      <button className={styles.Button} type="button" {...btnProps}>
        {typeof icon === 'string' ? <Icon className={styles.Icon} iconName={icon} /> : icon}
        <div className={styles.Text}>{children}</div>
      </button>

      {sideIcon && (
        <Icon {...sideIcon} className={classNames(styles.SideIcon, sideIcon.className)} />
      )}
    </div>
  );
};

export default CardButton;
