import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Content from '../../components/Content';
import PageHeader from '../../../../components/PageHeader';
// import { useAdminBotName } from '../../telegram.selectors';
import { SimpleCipher } from '../../telegram.utils';
import SpamBotMessagesBody from './components/SpamBotMessagesBody';
import CreateSpamBotMessagesButton from './components/CreateSpamBotMessagesButton';
import r from 'constants/routes';
import { useBotId } from '../../telegram.hooks';

const SpamBotMessages = () => {
  const { messageName } = useParams();
  const decryptedName = messageName ? SimpleCipher.decrypt(messageName) : '';
  const navigate = useNavigate();
  const botId = useBotId();

  return (
    <Content>
      <PageHeader
        subtitle={'Мой первый бот'}
        title={decryptedName}
        elem={<CreateSpamBotMessagesButton />}
        onIconClick={() => {
          navigate(generatePath(r.telegram.editSpamBot, { id: botId }));
        }}
      />
      <SpamBotMessagesBody />
    </Content>
  );
};

export default SpamBotMessages;
