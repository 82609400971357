import { FC, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isUndefined from 'lodash-es/isUndefined';

import styles from './FormEditor.module.scss';
import GoBack from 'components/GoBack';
import EditorForm from '../EditorForm';
import EditorTitle from '../EditorTitle';
import { LandingFormForm, LandingIdType } from 'modules/landing-generator/landing-generator.types';
import EditorBtn from '../EditorBtn';
import AdjustableInput from '../AdjustableInput';
import { landingFormSchema } from 'modules/landing-generator/landing-generator.schema';
import Input from 'components/Input';
import CheckboxSelect from 'components/CheckboxSelect';
import EditorSubtitle from '../EditorSubtitle';
import AddFieldDropdown from '../AddFieldDropdown';
import { convertLandingFormFormToLandingForm } from 'modules/landing-generator/landing-generator.converters';
import { useSelectLanding } from 'modules/landing-generator/landing-generator.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  alignFormText,
  setFormTextColor,
  setLandingForm,
  toggleFormTextBold,
  toggleFormTextItalic,
  toggleFormTextUnderline,
} from 'modules/landing-generator/landing-generator.reducer';
import { fieldsDefault } from 'modules/landing-generator/landing-generator.getters';

type Props = {
  id: LandingIdType;
  onBack: () => void;
};

const FormEditor: FC<Props> = ({ id, onBack }) => {
  const dispatch = useAppDispatch();
  const landing = useSelectLanding(id);

  const defaultValues = useMemo(
    () =>
      landing?.form
        ? {
            title: landing.form.title.content,
            text: landing.form.text.content,
            fields: landing.form.fields,
            buttonName: landing.form.buttonName,
            inModal: !isUndefined(landing.form.modal),
            modalText: landing.form.modal?.buttonName,
          }
        : {
            fields: fieldsDefault,
            inModal: false,
          },
    [landing?.form],
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm<LandingFormForm>({
    mode: 'onChange',
    resolver: yupResolver(landingFormSchema()),
    defaultValues,
  });

  const values = watch();

  const onSubmit: SubmitHandler<LandingFormForm> = data => {
    const form = convertLandingFormFormToLandingForm(data, landing?.form);
    dispatch(setLandingForm({ id: id, form }));
  };

  return (
    <EditorForm hasBack onSubmit={handleSubmit(onSubmit)}>
      <GoBack onClick={onBack} />

      <EditorTitle>Добавление формы заявки</EditorTitle>

      <AdjustableInput
        label="Заголовок"
        register={register('title')}
        handlers={{
          onToggleBold: () => dispatch(toggleFormTextBold({ id, type: 'title' })),
          onToggleItalic: () => dispatch(toggleFormTextItalic({ id, type: 'title' })),
          onToggleUnderline: () => dispatch(toggleFormTextUnderline({ id, type: 'title' })),
          onSetColor: color => dispatch(setFormTextColor({ id, color, type: 'title' })),
          onAlign: alignment => dispatch(alignFormText({ id, alignment, type: 'title' })),
        }}
        invalid={!!errors.title?.message}
        errorMsg={errors.title?.message}
        isTitle
        bold
      />

      <AdjustableInput
        label="Основной текст"
        register={register('text')}
        handlers={{
          onToggleBold: () => dispatch(toggleFormTextBold({ id, type: 'title' })),
          onToggleItalic: () => dispatch(toggleFormTextItalic({ id, type: 'title' })),
          onToggleUnderline: () => dispatch(toggleFormTextUnderline({ id, type: 'title' })),
          onSetColor: color => dispatch(setFormTextColor({ id, color, type: 'title' })),
          onAlign: alignment => dispatch(alignFormText({ id, alignment, type: 'title' })),
        }}
        invalid={!!errors.text?.message}
        errorMsg={errors.text?.message}
      />

      <EditorSubtitle>Сама форма заявки</EditorSubtitle>

      <AddFieldDropdown append={name => setValue(`fields.${name}`, '')} />

      <EditorSubtitle>Кнопка под формой заявки</EditorSubtitle>

      <Input label="Название кнопки" register={register('buttonName')} />

      <CheckboxSelect
        title="Расположение"
        selected={values.inModal}
        selectValue={value => setValue('inModal', value, { shouldValidate: true })}
        options={[
          {
            text: 'Расположить в модальном окне',
            value: true,
            contentIfSelected: (
              <Input
                wrapperClassName={styles.ModalInput}
                label="Название кнопки"
                register={register('modalText')}
              />
            ),
          },
          { text: 'Расположить без дополнительной кнопки и модального окна', value: false },
        ]}
      />

      <EditorBtn type="submit" disabled={!isValid}>
        Сохранить
      </EditorBtn>
    </EditorForm>
  );
};

export default FormEditor;
