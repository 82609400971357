import { FC, useLayoutEffect } from 'react';
import Content from '../../components/Content';
import PageHead from '../../components/PageHead';
import EmptyPage from 'components/EmptyPage';
import asset from 'assets/svg/telegram/command.svg';
import CreateCommandButton from '../../components/CreateCommandButton';
import useWindowSize from 'hooks/useWindowSize';
import useDisclosure from 'hooks/useDisclosure';
import CommandModal from '../../components/CommandModal';
import { useGetAdminCommandsQuery } from '../../codegen/telegram.admin.api';
import { useBotId } from '../../telegram.hooks';
import CommandsTable from '../../components/CommandsTable';
import LoadingState from 'components/LoadingState';
import styles from './Commands.module.scss';
import { useAdminBotName, useCommandsParams } from '../../telegram.selectors';
import isUndefined from 'lodash-es/isUndefined';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { urlOffset } from '../../telegram.utils';
import r from '../../../../constants/routes';
import { generatePath, useNavigate } from 'react-router-dom';
import { setCommandsParams } from '../../codegen/telegram.admin.reducer';

const Commands: FC = () => {
  const { width } = useWindowSize();
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });
  const botId = useBotId();
  const params = useCommandsParams();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(location.search);
  const isParamsMatch = params.offset === +urlOffset(queryParams.get('offset'));
  const botName = useAdminBotName();
  const navigate = useNavigate();

  const { data, isFetching } = useGetAdminCommandsQuery(
    { botId, ...params },
    {
      skip: isUndefined(botId) || !isParamsMatch,
      refetchOnMountOrArgChange: true,
    },
  );

  useLayoutEffect(() => {
    const offset = urlOffset(queryParams.get('offset'));
    dispatch(setCommandsParams({ offset }));
  }, [params.offset]);

  if (isFetching) return <LoadingState />;

  return (
    <>
      <Content>
        <div className={styles.Root}>
          <PageHead
            title={'Команды'}
            subtitle={botName || 'Мой первый бот'}
            btnText={'Создать команду'}
            onButtonClick={onOpen}
            onIconClick={() => {
              navigate(generatePath(r.telegram.editAdminBot, { id: botId }));
            }}
          />
          {data?.count && (
            <>
              {width <= 876 && (
                <div className={styles.CreateButtonComponent}>
                  <CreateCommandButton />
                </div>
              )}
            </>
          )}
          {data?.count ? (
            <CommandsTable />
          ) : (
            <EmptyPage
              title={'Создавай свои команды'}
              img={<img src={asset} alt={''} />}
              text={'В несколько шагов'}
              onClick={() => onOpen()}
              btnText={'Добавить команду'}
            />
          )}
        </div>
      </Content>
      {open && <CommandModal isOpen={open} onClose={onClose} />}
    </>
  );
};
export default Commands;
