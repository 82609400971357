import { FC } from 'react';

interface Props {
  className?: string;
}

export const ArrowRight: FC<Props> = ({ className }) => {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.2002 13.5L7.2002 7.5L1.2002 1.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
