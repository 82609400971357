import { FC } from 'react';
import styles from './Advertising.module.scss';
import classNames from 'classnames';

type Props = {
  isRow?: boolean;
  isMobile?: boolean;
  imageUrl?: string;
  link?: string;
};
export const Advertising: FC<Props> = ({ isRow, isMobile, link, imageUrl }) => {
  return (
    <div
      className={classNames(styles.Root, {
        [styles.IsRow]: isRow,
      })}
    >
      {!isMobile && (
        <div className={styles.TitleWrap}>
          <h1 className={styles.Title}>Место для вашей рекламы</h1>
        </div>
      )}
      <div className={styles.Info}>
        {isMobile ? (
          <h1 className={styles.Title}>Место для вашей рекламы</h1>
        ) : (
          <p className={styles.InfoPar}>Все подробности вы можете узнать написав на почту</p>
        )}
        <a
          href="mailto:stars.team.community@yandex.ru?subject=Subject%20of%20the%20email&body=Body%20of%20the%20email"
          className={styles.Email}
        >
          <u>stars.team.community@yandex.ru</u>
        </a>
      </div>

      {imageUrl && (
        <img
          onClick={() => {
            window.open(link, '_blank')?.focus();
          }}
          className={styles.Image}
          src={imageUrl}
          alt={''}
        />
      )}
    </div>
  );
};
