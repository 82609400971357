import { FC, FormEvent, useState } from 'react';

import styles from './CreateBotModal.module.scss';
import Input from 'components/Input';
import Modal from 'components/Modal';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateBotModal: FC<Props> = ({ isOpen, onClose }) => {
  const [token, setToken] = useState('');

  const handleClose = () => {
    setToken('');
    onClose();
  };

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleClose();
    await showFeedback({
      title: 'Бот подключен',
      text: 'Поздравяем! Вы подключили нового бота',
      type: EFeedbackType.CONGRATS,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isValid={token.length > 0} onSubmit={onSubmit}>
      <h3 className={styles.Title}>Создание бота</h3>
      <p className={styles.Text}>Вставьте TOKEN для того, чтобы продолжить</p>
      <Input label="Token" value={token} onChange={setToken} />
      <span className={styles.Label}>Не знаете что это?</span>
    </Modal>
  );
};

export default CreateBotModal;
