import { FC, ReactNode } from 'react';

import styles from './BlockIcon.module.scss';

type Props = {
  width: number;
  height: number;
  children?: ReactNode;
  inactive?: boolean;
};

const BlockIcon: FC<Props> = ({ width, height, children, inactive }) => {
  return (
    <div className={styles.Block} style={{ width, height }} data-inactive={inactive}>
      {children}
    </div>
  );
};

export default BlockIcon;
