import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from './app.types';
import env from 'config/env';
import { Locale } from 'interfaces/locales';

const initialState: AppState = {
  initializing: false,
  locale: env.LOCALE,
  mobileNavOpen: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startAppInitialization: state => {
      state.initializing = true;
    },
    finishAppInitialization: state => {
      state.initializing = false;
    },
    updateLocale: (state, { payload }: PayloadAction<Locale>) => {
      state.locale = payload;
    },
    setMobileNavOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.mobileNavOpen = payload;
    },
  },
});

export const { startAppInitialization, finishAppInitialization, updateLocale, setMobileNavOpen } =
  appSlice.actions;

export default appSlice.reducer;
