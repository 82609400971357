import { TPlansMap, TPlansWithPrice } from './tariff.type';

export const makeTariffMapByName = (data: TPlansWithPrice[]) => {
  return data.reduce((acc, cur) => {
    acc[cur.name] = cur;
    return acc;
  }, {} as TPlansMap);
};

export function cloneObjectWithExcludedKey<T extends Record<string, unknown>>(
  obj: T,
  excludedKey: keyof T,
): Omit<T, keyof T> {
  const clonedObj = { ...obj };

  delete clonedObj[excludedKey];

  return clonedObj;
}
