// @ts-nocheck
import last from 'lodash-es/last';
import find from 'lodash-es/find';

import { RootState } from 'config/store';
import useTypedSelector from 'hooks/useAppSelector';
import { Landing, LandingIdType, LandingButton } from './landing-generator.types';
import { flattenLandingSorted } from './landing-generator.utils';

export const selectLanding =
  (id: LandingIdType) =>
  (state: RootState): Landing | undefined =>
    state.landingGenerator.present.landings.find(landing => landing.id === id);

export const useSelectLanding = (id: LandingIdType): Landing | undefined =>
  useTypedSelector(selectLanding(id));

export const useSelectLandingAsList = (id: LandingIdType) =>
  useTypedSelector(state => {
    const found = find(state.landingGenerator.present.landings, { id });
    if (!found) return [];
    return flattenLandingSorted(found);
  });

export const useSelectLandingColor = (id: LandingIdType) =>
  useTypedSelector(state => find(state.landingGenerator.present.landings, { id })?.color);

export const selectPrevLanding =
  (id: LandingIdType) =>
  (state: RootState): Landing | undefined =>
    last(state.landingGenerator.past)?.landings.find(landing => landing.id === id);

export const useSelectPrevLanding = (id: LandingIdType): Landing | undefined =>
  useTypedSelector(selectPrevLanding(id));

export const selectLandings = (state: RootState): Landing[] =>
  state.landingGenerator.present.landings;

export const useSelectLandings = (): Landing[] => useTypedSelector(selectLandings);

export const selectCanUndoRedoLandingGenerator = (
  state: RootState,
): {
  canUndo: boolean;
  canRedo: boolean;
} => ({
  canUndo: state.landingGenerator.past.length > 0,
  canRedo: state.landingGenerator.future.length > 0,
});

export const useSelectCanUndoRedoLandingGenerator = (): {
  canUndo: boolean;
  canRedo: boolean;
} => useTypedSelector(selectCanUndoRedoLandingGenerator);

export const selectLandingButtons =
  (landingId: LandingIdType) =>
  (state: RootState): LandingButton[] =>
    selectLanding(landingId)(state)?.buttons ?? [];

export const useSelectLandingButtons = (landingId: LandingIdType): LandingButton[] =>
  useTypedSelector(selectLandingButtons(landingId));

export const selectLandingButton =
  (landingId: LandingIdType, buttonId: string | number) =>
  (state: RootState): LandingButton | undefined =>
    selectLanding(landingId)(state)?.buttons.find(button => button.id === buttonId);

export const useSelectLandingButton = (landingId: LandingIdType, buttonId: string | number) =>
  useTypedSelector(selectLandingButton(landingId, buttonId));

export const selectPrevLandingButton =
  (landingId: LandingIdType, buttonId: string | number) =>
  (state: RootState): LandingButton | undefined =>
    selectPrevLanding(landingId)(state)?.buttons.find(button => button.id === buttonId);

export const useSelectPrevLandingButton = (
  landingId: LandingIdType,
  buttonId: string | number,
): LandingButton | undefined => useTypedSelector(selectPrevLandingButton(landingId, buttonId));
