import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootApi from 'config/http';
import tgApi from 'config/http.tg';
import vkApi from 'config/http.vk';

import rtkQueryErrorLogger from 'config/logger';
// reducers
import landingGeneratorReducer from 'modules/landing-generator/landing-generator.reducer';
import authReducer from 'modules/auth/auth.reducer';
import appReducer from 'modules/app/app.reducer';
import accountReducer from 'modules/account/account.reducer';
import landingDashBoardReducer from 'modules/landing-dashboard/landing-dashboard.reducer';
import tariffReducer from 'modules/tariff/tariff.reducer';
import partnershipProgramReducer from 'modules/partnership-program/partnership-program.reducer';
import financesReducer from 'modules/finances/finances.reducer';
import myPagesReducer from 'modules/my-pages/my-pages.reducer';
import telegramReducer from 'modules/telegram/telegram.reducer';
import vkReducer, { vkGroupsSlice } from 'modules/vk/vk.reducer';
import { telegramPersistSlice } from '../modules/telegram/codegen/telegram.admin.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'landingGenerator',
    'auth',
    'account',
    'app',
    'colorPicker',
    'vkSelectedGroups',
    'telegramPersisted',
  ],
  version: 1,
};

const rootReducer = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  [tgApi.reducerPath]: tgApi.reducer,
  [vkApi.reducerPath]: vkApi.reducer,
  app: appReducer,
  auth: authReducer,
  account: accountReducer,
  landingGenerator: landingGeneratorReducer,
  landingDashBoard: landingDashBoardReducer,
  tariff: tariffReducer,
  finances: financesReducer,
  partnershipProgram: partnershipProgramReducer,
  myPages: myPagesReducer,
  telegram: telegramReducer,
  telegramPersisted: telegramPersistSlice.reducer,
  vk: vkReducer,
  vkSelectedGroups: vkGroupsSlice.reducer,
});

const rootPersistReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootPersistReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(rtkQueryErrorLogger, rootApi.middleware, tgApi.middleware, vkApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store.getState;
