import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
};

const Card: FC<Props> = ({ children, className }) => {
  return <section className={classNames(styles.Card, className)}>{children}</section>;
};

export default Card;
