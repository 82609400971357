import { FC } from 'react';
import CheckBox from 'components/CheckBox';
import Button from 'components/Button';
import styles from './CommandsTableHead.module.scss';
import useWindowSize from 'hooks/useWindowSize';
import useAppDispatch from 'hooks/useAppDispatch';
import { useAdminSelectedCommands, useAdminSelectedCommandsIds } from '../../../telegram.selectors';
import {
  removePersistSelectedAllCommands,
  setSelectAllCommands,
} from '../../../codegen/telegram.admin.reducer';
import { useRemoveSelectedCommandsMutation } from '../../../codegen/telegram.admin.api';
import { useBotId } from '../../../telegram.hooks';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';

const CommandsTableHead: FC = () => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const { data } = useAdminSelectedCommands();
  const botId = useBotId();
  const isAllSelected = data.every(com => com.isChecked);
  const isSomeSelected = data.some(com => com.isChecked);
  const [deleteCommands, { isLoading }] = useRemoveSelectedCommandsMutation();
  const ids = useAdminSelectedCommandsIds();

  const deleteAllCommandsButtonHandler = () => {
    deleteCommands({ botId, ids }).then(res => {
      if ('data' in res) {
        dispatch(removePersistSelectedAllCommands());
        showFeedback({
          title: 'ГОТОВО!',
          text: 'Вы удалили',
          type: EFeedbackType.SUCCESS,
        });
      }
    });
  };

  return (
    <thead className={styles.Root}>
      <tr className={styles.InnerContainer}>
        <th className={styles.Checkbox}>
          <CheckBox
            checked={isAllSelected}
            onChange={() => {
              dispatch(setSelectAllCommands());
            }}
            backgroundColor={isAllSelected ? '#216FE0' : undefined}
          />
          <h1 className={styles.Title}>Выделить все</h1>
        </th>
        <th>
          {isSomeSelected && (
            <Button
              disabled={isLoading}
              onClick={deleteAllCommandsButtonHandler}
              className={styles.Btn}
              variant={'text'}
            >
              {width >= 875 ? 'Удалить выбранные команды' : 'Удалить'}
            </Button>
          )}
        </th>
      </tr>
    </thead>
  );
};

export default CommandsTableHead;
