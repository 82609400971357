import { FC } from 'react';
import isEmpty from 'lodash-es/isEmpty';

import styles from './BusinessCards.module.scss';
import { toggleNewCardDialog } from 'modules/landing-dashboard/landing-dashboard.reducer';
import EmptyPage from '../EmptyPage';
import useAppDispatch from 'hooks/useAppDispatch';
import BusinessCard from '../BusinessCard';
import EmptyCard from 'components/EmptyCard';
import CreatePageBtn from '../CreatePageBtn';
import { BusinessCardType } from '../../landing-dashboard.types';

type Props = {
  landings: BusinessCardType[];
  isLoading: boolean;
  isError: boolean;
};
const BusinessCards: FC<Props> = ({ landings, isError, isLoading }) => {
  const dispatch = useAppDispatch();
  const maxCards = 10;

  if (isLoading) return null;
  if (isError) return null;

  const isLandingsEmpty = isEmpty(landings);

  if (isLandingsEmpty)
    return (
      <section className={styles.Empty}>
        <EmptyPage
          title="Создай свою первую страницу"
          subtitle="В несколько шагов"
          elem={<CreatePageBtn className={styles.CreateBtn}>Создать страницу</CreatePageBtn>}
        />
      </section>
    );

  return (
    <section className={styles.Section}>
      {landings.map(card => (
        <BusinessCard key={card.id} data={card} />
      ))}
      {landings.length < maxCards && (
        <EmptyCard
          className={styles.EmptyCard}
          text="Добавить страницу"
          onClick={() => dispatch(toggleNewCardDialog())}
        />
      )}
    </section>
  );
};

export default BusinessCards;
