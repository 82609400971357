import { FC } from 'react';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import ToggleSwitch from 'components/ToggleSwitch';
import Icon from 'components/Icon';
import { TCommand } from '../../../../telegram.types';
import { useBotId } from '../../../../telegram.hooks';
import { useRemoveAdminCommandByIdMutation } from '../../../../codegen/telegram.admin.api';
import styles from './CommandsTableRow.module.scss';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import useAppDispatch from 'hooks/useAppDispatch';
import classNames from 'classnames';
import CommandsTableRowUpdateButton from './CommandsTableRowUpdateButton';
import SmallSpinner from '../../../SmallSpinner';
import {
  removePersistSelectedCommands,
  setPersistSelectedCommands,
  setSelectCommandById,
} from '../../../../codegen/telegram.admin.reducer';

type Props = {
  isHelp?: boolean;
  noSlash?: boolean;
  noToggle?: boolean;
  isCommand?: boolean;
  onSettingBtnClick?: () => void;
  onDeleteBtn?: () => void;
  onToggleBtn?: () => void;
  toggleStatus?: boolean;
  isToggleLoading?: boolean;
  noCheckbox?: boolean;
} & TCommand;

const CommandsTableRow: FC<Props> = ({
  isHelp,
  files,
  name,
  description,
  id,
  noSlash,
  isChecked,
  noToggle,
  isCommand,
  onSettingBtnClick,
  onDeleteBtn,
  onToggleBtn,
  toggleStatus,
  isToggleLoading,
  noCheckbox,
  isEnable,
}) => {
  const botId = useBotId();
  const [deleteCommand, { isLoading }] = useRemoveAdminCommandByIdMutation();
  const dispatch = useAppDispatch();

  const SettingsButton = isCommand ? (
    <CommandsTableRowUpdateButton id={id} />
  ) : (
    <Button
      onClick={onSettingBtnClick}
      className={styles.Btn}
      centralIcon={'settings'}
      variant={'tetriary'}
    ></Button>
  );

  const DeleteButton = isCommand ? (
    <Button
      onClick={() => {
        if (id)
          deleteCommand({ botId, commandId: id }).then(res => {
            if ('data' in res) {
              showFeedback({
                title: 'ГОТОВО!',
                text: 'Вы удалили',
                type: EFeedbackType.SUCCESS,
              });
            }
          });
      }}
      className={styles.DeleteBtn}
      centralIcon={isLoading ? 'loader' : 'delete'}
      variant={'tetriary'}
      disabled={isLoading}
    ></Button>
  ) : (
    <Button
      onClick={onDeleteBtn}
      className={styles.DeleteBtn}
      centralIcon={isLoading ? 'loader' : 'delete'}
      variant={'tetriary'}
    ></Button>
  );

  const Toggle = isCommand ? (
    <ToggleSwitch toggle={onToggleBtn} active={isEnable} />
  ) : (
    <ToggleSwitch toggle={onToggleBtn} active={toggleStatus} />
  );

  const HelpRow = (
    <tr className={styles.Root}>
      <td className={styles.Col} colSpan={1}>
        <h1 className={styles.Title}>/ Help</h1>
      </td>
      <td className={styles.Col} colSpan={2}>
        <p className={styles.SubTitle}>Выводит список команд</p>
      </td>
      <td className={styles.Col} colSpan={3}>
        {!isHelp && (
          <Button className={styles.Btn} centralIcon={'settings'} variant={'tetriary'}></Button>
        )}
      </td>
    </tr>
  );

  if (isHelp) return HelpRow;

  return (
    <tr
      className={classNames(styles.Root, {
        [styles.IsSelectedRow]: isChecked,
      })}
    >
      <td className={styles.Col} colSpan={1}>
        <div className={styles.CheckboxWrap}>
          {!noCheckbox && (
            <CheckBox
              checked={isChecked}
              defaultChecked={isChecked}
              onChange={() => {
                if (id) {
                  dispatch(setSelectCommandById({ id }));
                  if (!isChecked) {
                    dispatch(setPersistSelectedCommands(id));
                  } else {
                    dispatch(removePersistSelectedCommands(id));
                  }
                }
              }}
              backgroundColor={isChecked ? '#216FE0' : undefined}
            />
          )}

          <h1 className={styles.Title}>
            {noSlash ? ' ' : '/ '} {name}
          </h1>
        </div>
      </td>
      <td className={styles.Col} colSpan={2}>
        <p className={styles.SubTitle}>{description}</p>
      </td>
      <td className={styles.Col} colSpan={3}>
        <div className={styles.Count}>
          <Icon iconName={'brace'} />
          <p className={styles.CountNumber}>{files.length}</p>
        </div>
        {!isChecked && (
          <div className={styles.Buttons}>
            {isToggleLoading ? <SmallSpinner /> : <>{!noToggle && Toggle}</>}
            {SettingsButton}
            {DeleteButton}
          </div>
        )}
      </td>
    </tr>
  );
};

export default CommandsTableRow;
