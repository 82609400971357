import rootApi from 'config/http';
import { EAppTagType, TPaginated, TPaginatedRequest, TResponse } from 'interfaces/api';
import { ElemType } from 'modules/cards/cards.types';
import { Page } from 'modules/my-pages/my-pages.types';
import simpleFormData from 'utils/simpleFormData';
import {
  BusinessCardType,
  ChangePageRequest,
  ChangePageParamsRequest,
  Metrics,
  MetricsRequest,
  MetricsSheet,
  PublishPageRequest,
  PageTemplate,
  ChangeNameRequest,
  ChangePageComponentsRequest,
  FormSendRequest,
  FormComponentsResponse,
  FormGetRequest,
} from './landing-dashboard.types';

const landingDashboardApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getPages: builder.query<TResponse<TPaginated<Array<BusinessCardType>>>, TPaginatedRequest>({
      query: params => ({
        url: '/pages',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.SHEETS],
    }),
    getPage: builder.query<TResponse<Page>, { id: number | string }>({
      query: ({ id }) => ({
        url: `/pages/id/${id}`,
        method: 'GET',
      }),
      providesTags: [EAppTagType.SHEETS],
    }),
    getTemplates: builder.query<TResponse<Array<PageTemplate>>, void>({
      query: () => ({
        url: '/pages/templates',
        method: 'GET',
      }),
      providesTags: [EAppTagType.SHEET_TEMPLATES],
    }),
    getTemplate: builder.query<TResponse<PageTemplate>, void>({
      query: () => ({
        url: '/pages',
        method: 'GET',
      }),
      providesTags: [EAppTagType.SHEET_TEMPLATES],
    }),
    createPageByTemplate: builder.mutation<string, { id: number }>({
      query: ({ id }) => ({
        url: `/pages/createByTemplate/${id}`,
        method: 'POST',
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    clonePage: builder.mutation<string, { id: number }>({
      query: ({ id }) => ({
        url: `/pages/${id}/clone`,
        method: 'POST',
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    changePageComponents: builder.mutation<string, ChangePageComponentsRequest>({
      query: ({ id, components: { components } }) => ({
        url: `/pages/${id}/component`,
        method: 'PATCH',
        body: { components },
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    changePageParams: builder.mutation<string, ChangePageParamsRequest>({
      query: ({ id, ...body }) => ({
        url: `/pages/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    changePage: builder.mutation<string, ChangePageRequest>({
      query: ({ id, ...body }) => ({
        url: `/pages/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    changeName: builder.mutation<string, ChangeNameRequest>({
      query: ({ id, ...body }) => ({
        url: `/pages/${id}/name`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    publishPage: builder.mutation<string, PublishPageRequest>({
      query: ({ id, ...body }) => ({
        url: `/pages/${id}/publish`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    addPage: builder.mutation<TResponse<string>, { sheetName: string }>({
      query: body => ({
        url: '/sheets/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    deletePage: builder.mutation<TResponse<string>, { id: number }>({
      query: ({ id }) => ({
        url: `/pages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTagType.SHEETS],
    }),
    uploadPageImage: builder.mutation<TResponse<string>, { image: File; skipOptimize?: boolean }>({
      query: ({ image, skipOptimize }) => ({
        url: `/pages/image`,
        method: 'POST',
        body: simpleFormData({ image }),
        params: { skipOptimize },
      }),
    }),
    addPagePrerender: builder.mutation<TResponse<string>, { id: number; image: Blob }>({
      query: ({ id, image }) => ({
        url: `/pages/${id}/prerender`,
        method: 'POST',
        body: simpleFormData({ image }),
      }),
    }),
    getPublishedPage: builder.query<TResponse<Page>, { link?: string }>({
      query: ({ link }) => ({
        url: `/pages/link/${link}`,
        method: 'GET',
      }),
      providesTags: [EAppTagType.SHEETS],
    }),
    getMetrics: builder.query<TResponse<Metrics>, MetricsRequest>({
      query: params => ({
        url: '/metrics/components',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.METRICS],
    }),
    getMetricsSheet: builder.query<TResponse<MetricsSheet>, MetricsRequest>({
      query: params => ({
        url: '/metrics/pageByBuckets',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.METRICS],
    }),
    getForms: builder.query<
      TPaginated<FormGetRequest[]>,
      { componentId: string | number; isRead: boolean | undefined } & TPaginatedRequest
    >({
      query: ({ componentId, ...params }) => ({
        url: `/pages/forms/${componentId}`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.APPLICATIONS],
      transformResponse: ({ body }) => {
        return {
          items: body?.items,
          ...body,
        };
      },
    }),
    getFormComponents: builder.query<TResponse<FormComponentsResponse>, { id: string | number }>({
      query: ({ id }) => ({
        url: `/pages/${id}/forms`,
        method: 'GET',
      }),
    }),
    sendForm: builder.mutation<TResponse<string>, FormSendRequest>({
      query: body => ({
        url: `/pages/forms/set`,
        method: 'POST',
        body,
      }),
    }),
    registerMetrics: builder.mutation<TResponse<string>, { id: string | number; type: ElemType }>({
      query: body => ({
        url: '/metrics/register',
        method: 'POST',
        body,
      }),
    }),
    readApplication: builder.mutation<TResponse<string>, { ids: string[] }>({
      query: body => ({
        url: `/pages/forms/read`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.APPLICATIONS],
    }),
    getApplicationsByPage: builder.query<TResponse<never>, { id?: string | number | null }>({
      query: ({ id }) => ({
        url: `/pages/${id}/applications`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPagesQuery,
  useGetPageQuery,
  useAddPageMutation,
  useChangePageMutation,
  useChangePageParamsMutation,
  useChangePageComponentsMutation,
  useChangeNameMutation,
  usePublishPageMutation,
  useClonePageMutation,
  useGetPublishedPageQuery,
  useDeletePageMutation,
  useGetMetricsQuery,
  useGetFormsQuery,
  useGetMetricsSheetQuery,
  useGetTemplateQuery,
  useGetTemplatesQuery,
  useCreatePageByTemplateMutation,
  useUploadPageImageMutation,
  useAddPagePrerenderMutation,
  useSendFormMutation,
  useRegisterMetricsMutation,
  useGetFormComponentsQuery,
  useReadApplicationMutation,
  useGetApplicationsByPageQuery,
} = landingDashboardApi;

export default landingDashboardApi;
