import Icon from 'components/Icon';
import { FC } from 'react';

import styles from './StatCardWithList.module.scss';

type Props = {
  name: string;
  legend: string;
  icon: string;
  value: string;
  listTitle: string;
  listItems: string[];
};

const StatCardWithList: FC<Props> = ({ name, legend, icon, value, listTitle, listItems }) => {
  return (
    <article className={styles.Card}>
      <div className={styles.Header}>
        <h4 className={styles.Title}>{name}</h4>
        <div className={styles.Legend}>{legend}</div>
      </div>

      <div className={styles.IconWrapper}>
        <Icon className={styles.Icon} iconName={icon} size={24} />
        <div className={styles.Value}>{value}</div>
      </div>

      <div className={styles.ListWrapper}>
        <h4 className={styles.Title}>{listTitle}</h4>
        <ul className={styles.List}>
          {listItems.map((item, index) => (
            <li key={index} className={styles.ListItem}>
              <span className={styles.Order}>{index + 1}.</span>{' '}
              <span className={styles.ItemValue}>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
};

export default StatCardWithList;
