import { FC } from 'react';

import styles from './Elements.module.scss';
import AccordionStandard from 'components/AccordionStandard';
import { getElements } from '../../elements.constants';

type Props = {
  elements: ReturnType<typeof getElements>;
};

const Desktop: FC<Props> = ({ elements }) => {
  return (
    <section className={styles.Elements}>
      <h5 className={styles.Title}>Элементы</h5>

      <div className={styles.List}>
        {elements.map(({ title, elements: el }, index) => (
          <AccordionStandard key={index} text={title} contentClassName={styles.Grid} firstOpen>
            {el}
          </AccordionStandard>
        ))}
      </div>
    </section>
  );
};

export default Desktop;
