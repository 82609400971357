import { createRoot } from 'react-dom/client';

import FeedbackModal, { FeedbackModalProps } from './index';

const showFeedback = (props: Omit<FeedbackModalProps, 'isOpen' | 'onClose'>): Promise<void> => {
  return new Promise<void>(resolve => {
    let container: HTMLDivElement | null = document.createElement('div');
    document.body.appendChild(container);
    const root = createRoot(container);

    const handleResolve = () => {
      if (container) {
        root.unmount();
      }

      container = null;
      resolve();
    };

    root.render(<FeedbackModal {...props} onClose={handleResolve} />);
  });
};

export default showFeedback;
