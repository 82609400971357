import { CSSProperties, FC, useId } from 'react';

import styles from './Input.module.scss';
import { EBlockStyle, EFormField, Form } from 'modules/my-pages/my-pages.types';
import { FORM_FIELDS_NAMES } from 'modules/my-pages/my-pages.constants';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  style: Form['inputStyle'];
  type: EFormField;
  register?: UseFormRegisterReturn;
  maxLength?: number;
  labelColor?: string;
};

const Input: FC<Props> = ({ style, type, register, maxLength, labelColor = 'white' }) => {
  const id = useId();

  const handleClick = () => {
    const input = document.getElementById(id);
    if (input) input.focus();
  };

  const styling: CSSProperties = {
    borderRadius: style.blockStyle === EBlockStyle.SQUARE ? '8px' : '20px',
    background: style.bgColor,
    border: `1px solid ${style.borderColor}`,
  };

  return (
    <div className={styles.Wrapper} onClick={handleClick} style={styling}>
      <input id={id} className={styles.Input} placeholder=" " {...register} maxLength={maxLength} />

      <label className={styles.Label} htmlFor={id} style={{ color: labelColor }}>
        {FORM_FIELDS_NAMES[type]}
      </label>
    </div>
  );
};

export default Input;
