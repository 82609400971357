import Icon from 'components/Icon';
import { EDraggableItem } from 'modules/my-pages/my-pages.types';
import BlockIcon from './components/BlockIcon';
import Element from './components/Element';
import EmptyBlockIcon from './components/EmptyBlockIcon';
import FormIcon from './components/FormIcon';
import SocialWrapper from './components/SocialWrapper';

export const getElements = (lastBlockId: string, pageId: string) => [
  {
    title: 'Основные',
    elements: (
      <>
        <Element
          icon={<Icon iconName="text" size={24} />}
          name="Текст"
          type={EDraggableItem.TEXT}
          parentId={lastBlockId}
        />
        <Element
          icon={<Icon iconName="add-img" size={24} />}
          name="Изображение"
          type={EDraggableItem.IMAGE}
          parentId={lastBlockId}
        />
        <Element
          icon={<Icon iconName="play-film" size={24} />}
          name="Видео с YouTube"
          type={EDraggableItem.YOUTUBE_VIDEO}
          parentId={lastBlockId}
        />
        <Element
          icon={<EmptyBlockIcon />}
          name="Пустой блок"
          type={EDraggableItem.BLOCK}
          parentId={pageId}
        />
        <Element
          icon={<BlockIcon width={60} height={24} />}
          name="Кнопка"
          type={EDraggableItem.BUTTON}
          parentId={lastBlockId}
        />
        <Element
          icon={<FormIcon />}
          name="Форма для связи"
          type={EDraggableItem.FORM}
          parentId={lastBlockId}
        />
      </>
    ),
  },
  {
    title: 'Контакты',
    elements: (
      <>
        <Element
          icon={<Icon iconName="phone" size={24} />}
          name="Телефон"
          type={EDraggableItem.PHONE}
          parentId={lastBlockId}
          styleStroke
        />
        <Element
          icon={<Icon iconName="email" size={24} />}
          name="Email"
          type={EDraggableItem.EMAIL}
          parentId={lastBlockId}
          styleStroke
        />
      </>
    ),
  },
  {
    title: 'Социальные',
    elements: (
      <>
        <Element
          icon={
            <SocialWrapper>
              <Icon iconName="navigation-vk-filled" size={20} />
            </SocialWrapper>
          }
          name="ВКонтакте"
          type={EDraggableItem.VK}
          parentId={lastBlockId}
        />
        <Element
          icon={
            <SocialWrapper>
              <Icon iconName="whatsapp-outline" size={20} />
            </SocialWrapper>
          }
          name="WhatsApp"
          type={EDraggableItem.WHATSAPP}
          parentId={lastBlockId}
        />
        <Element
          icon={
            <SocialWrapper>
              <Icon iconName="viber" size={20} />
            </SocialWrapper>
          }
          name="Viber"
          type={EDraggableItem.VIBER}
          parentId={lastBlockId}
        />
        <Element
          icon={
            <SocialWrapper>
              <Icon iconName="insta-outline" size={20} />
            </SocialWrapper>
          }
          name="Instagram"
          type={EDraggableItem.INSTAGRAM}
          parentId={lastBlockId}
        />
        <Element
          icon={
            <SocialWrapper>
              <Icon iconName="tg-outline" size={20} />
            </SocialWrapper>
          }
          name="Telegram"
          type={EDraggableItem.TELEGRAM}
          parentId={lastBlockId}
        />
      </>
    ),
  },
];
