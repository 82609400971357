import { FC } from 'react';

import styles from './ImageStyleSelector.module.scss';
import { EImageStyle } from 'modules/my-pages/my-pages.types';
import Tool from '../Tool';
import Menu from 'components/Menu';
import { IMAGE_STYLE_NAMES } from 'modules/my-pages/my-pages.constants';
import Icon from 'components/Icon';

type Props = {
  style: EImageStyle;
  setStyle: (value: EImageStyle) => void;
};

const ImageStyleSelector: FC<Props> = ({ style, setStyle }) => {
  const menu = (
    <Menu
      btnClassName={styles.MenuBtn}
      contextClassName={styles.MenuContext}
      items={[
        {
          label: IMAGE_STYLE_NAMES[EImageStyle.FILL],
          onClick: () => setStyle(EImageStyle.FILL),
          isSelected: style === EImageStyle.FILL,
        },
        {
          label: IMAGE_STYLE_NAMES[EImageStyle.FIT],
          onClick: () => setStyle(EImageStyle.FIT),
          isSelected: style === EImageStyle.FIT,
        },
        {
          label: IMAGE_STYLE_NAMES[EImageStyle.REPEAT],
          onClick: () => setStyle(EImageStyle.REPEAT),
          isSelected: style === EImageStyle.REPEAT,
        },
      ]}
    >
      {IMAGE_STYLE_NAMES[style]}{' '}
      <Icon className={styles.IconDown} iconName="arrow-down" size={18} />
    </Menu>
  );

  return (
    <Tool
      childrenClassName={styles.Children}
      label="Стиль:"
      right={<div className={styles.DesktopMenu}>{menu}</div>}
    >
      <div className={styles.MobileMenu}>{menu}</div>
    </Tool>
  );
};

export default ImageStyleSelector;
