import { useState, FC } from 'react';
import styles from './BlockSettings.module.scss';
import { Block, BlockComponent, Text } from 'modules/my-pages/my-pages.types';
import { StripesIcon } from './StripesIcon';
import { Reorder, useDragControls } from 'framer-motion';
import { DotsIcon } from './DotsIcon';
import {
  removeComponent,
  setActiveComponent,
  setBlockComponent,
  setOpenModal,
} from 'modules/my-pages/my-pages.reducer';
import useAppDispatch from 'hooks/useAppDispatch';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';
import Menu from 'components/Menu';
import { defaultBackground, settingItems } from 'modules/my-pages/my-pages.constants';
import { BlockComponents } from './components/BlockComponents/BlockComponents';
import { handleOpenSettingModal } from 'modules/my-pages/my-pages.helpers';
import { BlockSettingsTypes } from './BlockSettings.types';

export const BlockSettings: FC<BlockSettingsTypes> = ({ onClose }) => {
  const [openComponentsId, setOpenComponentsId] = useState<string | null>(null);
  const page = useActivePage();
  const blocks = page?.components;
  const controls = useDragControls();
  const dispatch = useAppDispatch();

  const onReorder = (newList: BlockComponent[]) => {
    return dispatch(setBlockComponent([...newList]));
  };

  const handleSettingItemClick = (item: string, id: string) => {
    if (item === 'Перейти') {
      dispatch(setActiveComponent(id));
      dispatch(setOpenModal(true));
      if (onClose) {
        onClose();
      }
    } else {
      dispatch(setActiveComponent(null));
      dispatch(removeComponent({ hash: id }));
    }
  };

  const handleOpenComponents = (id: string) => {
    setOpenComponentsId(prevId => (prevId === id ? null : id));
  };

  return (
    <div>
      <Reorder.Group
        values={page?.components as BlockComponent[]}
        onReorder={onReorder}
        className={styles.reorderGroup}
      >
        <h4 className={styles.title}>Управление блоками</h4>
        {blocks?.map(block => {
          const { hash, components, data } = block;
          const firstImageComponent = components?.find(({ type }) => type === 'IMAGE');
          const blockData = firstImageComponent?.data as Block;
          const isOpen = openComponentsId === hash;
          return (
            <div key={hash}>
              <Reorder.Item
                value={block}
                className={styles.block}
                dragControls={controls}
                tabIndex={0}
                onClick={() => handleOpenComponents(hash)}
              >
                <StripesIcon />
                {firstImageComponent && <img src={blockData.link} alt="Изображение" />}
                {!firstImageComponent && data.bgColor !== defaultBackground && (
                  <div className={styles.backgroundBlock} style={{ background: data.bgColor }} />
                )}
                {!components?.length && <p className={styles.title}>Пустой блок</p>}
                {components?.map(item => {
                  const { textStyle, text } = item.data as Text;
                  if (item.type === 'TEXT') {
                    return (
                      <div className={styles.textBlock} onPointerDown={e => controls.start(e)}>
                        {textStyle === 'HEADING' && <p className={styles.title}>{text}</p>}
                        {textStyle === 'SUBTITLE' && <p className={styles.subtitle}>{text}</p>}
                      </div>
                    );
                  }
                })}
                <div className={styles.rightBlock} onClick={handleOpenSettingModal}>
                  {!!components?.length && !isOpen && (
                    <div className={styles.blocksCounter}>
                      <p>+{components?.length}</p>
                    </div>
                  )}
                  <Menu
                    items={
                      settingItems.map(item => ({
                        isSelected: item === item,
                        label: item,
                        onClick: () => handleSettingItemClick(item, hash),
                      })) ?? []
                    }
                  >
                    <DotsIcon className={styles.dotsIcon} />
                  </Menu>
                </div>
              </Reorder.Item>
              {isOpen && <BlockComponents components={components} />}
            </div>
          );
        })}
      </Reorder.Group>
    </div>
  );
};
