import { FC } from 'react';

import { useSendMessageAllPartnersMutation } from 'modules/notifications/notifications.api';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { CommonModalProps } from 'components/Modal';
import PartnersNewsletterModal, { PartnersNewsletterModalHandler } from '.';

type Props = Omit<CommonModalProps, 'onSubmit'>;

const AllPartnersModal: FC<Props> = props => {
  const line = 1;
  const [sendMessageAll] = useSendMessageAllPartnersMutation();

  const onSubmit: PartnersNewsletterModalHandler = async ({ message }) => {
    await sendMessageAll({ line, message });
    props.onClose();
    showFeedback({
      title: 'Сообщение отправлено',
      text: 'Поздравляем! Сообщение было успешно отправлено',
      type: EFeedbackType.CONGRATS,
    });
  };

  return <PartnersNewsletterModal onSubmit={onSubmit} {...props} />;
};

export default AllPartnersModal;
