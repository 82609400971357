import { FC, useMemo } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import styles from './ChangedOrCreated.module.scss';

type Props = {
  created: string;
  changed?: string;
  className?: string;
};

const ChangedOrCreated: FC<Props> = ({ created, changed, className }) => {
  const timeString = useMemo(() => {
    const createdDate = dayjs(created);
    if (!changed) {
      return `Создан: ${createdDate.format('DD MMMM YYYY')}`;
    }
    const changedDate = dayjs(changed);
    return `Измененен:  ${changedDate.fromNow()}`;
  }, [changed, created]);

  return <time className={classNames(styles.Time, className)}>{timeString}</time>;
};

export default ChangedOrCreated;
