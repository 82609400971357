import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import r from 'constants/routes';

import { signInSchema } from 'modules/auth/auth.schema';
import { useSignInMutation } from 'modules/auth/auth.api';
import useAppDispatch from 'hooks/useAppDispatch';

import { signInStateHandler, updateRefreshToken } from 'modules/auth/auth.reducer';
import { ISignIn } from 'modules/auth/auth.interfaces';

import AuthForm from 'modules/auth/components/AuthForm';
import Input from 'components/Input';
import PasswordInput from 'components/PasswordInput';

import styles from './SignIn.module.scss';
import { useVkInvalidateTag } from '../../../vk/vk.hooks';
import { isAuthError } from '../../auth.utils';
import accountApi from '../../../account/account.api';

const SignIn: FC = () => {
  const [signIn, { data: signInData }] = useSignInMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const invalidate = useVkInvalidateTag();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ISignIn>({
    mode: 'onChange',
    resolver: yupResolver(signInSchema()),
  });

  useEffect(() => {
    if (!signInData) return;
    dispatch(updateRefreshToken(signInData));
    navigate(r.root);
    dispatch(accountApi.util.resetApiState());
  }, [signInData]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit: SubmitHandler<ISignIn> = data => {
    signIn(data).then(res => {
      isAuthError(res);
    });
    invalidate();
    dispatch(signInStateHandler());
  };

  const onRegister = () => {
    navigate(r.auth.signUp.chooseRegistrationType);
  };

  return (
    <div className={styles.SignIn}>
      <AuthForm
        title="Войти"
        buttonText="Войти"
        footer={{ text: 'Нет аккаунта?', btn: { text: 'Зарегистрироваться', onClick: onRegister } }}
        isValid={isValid}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          label="Логин"
          register={register('login')}
          invalid={!!errors.login}
          errorMsg={errors.login?.message}
        />
        <PasswordInput
          label="Пароль"
          register={register('password')}
          invalid={!!errors.password}
          errorMsg={errors.password?.message}
        />
        <Link className={styles.ForgotPasswdLink} to={r.auth.resetPassword}>
          Забыли пароль?
        </Link>
      </AuthForm>
    </div>
  );
};

export default SignIn;
