import { FC } from 'react';

import styles from './SexSelector.module.scss';
import InputWithDropdown from 'components/InputWithDropdown';
import { InputProps } from 'components/Input';
import { ESex } from 'interfaces/common';

type Props = {
  selected?: ESex;
  select: (sex: ESex) => void;
} & Omit<InputProps, 'label'>;

const SexSelector: FC<Props> = ({ selected, select, ...inputProps }) => {
  return (
    <InputWithDropdown
      label="Пол"
      menuClassName={styles.SexMenu}
      options={[
        {
          label: ESex.MAN,
          isSelected: selected === ESex.MAN,
          onClick: () => {
            select(ESex.MAN);
          },
        },
        {
          label: ESex.WOMAN,
          isSelected: selected === ESex.WOMAN,
          onClick: () => select(ESex.WOMAN),
        },
        {
          label: ESex.NOT_IMPORTANT,
          isSelected: selected === ESex.NOT_IMPORTANT,
          onClick: () => select(ESex.NOT_IMPORTANT),
        },
      ]}
      {...inputProps}
    />
  );
};

export default SexSelector;
