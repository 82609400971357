import { FC, useMemo } from 'react';
import { TWelcomeBotContactsStatistic } from '../../../../telegram.types';
import styles from '../WelcomeBotStatisticsTableBody.module.scss';
import { findFirstError } from '../../../../telegram.utils';
import dayjs from 'dayjs';
import Icon from 'components/Icon';

export const WelcomeBotStatisticsTableRow: FC<TWelcomeBotContactsStatistic> = ({
  firstName,
  lastName,
  username,
  phone,
  sendHistory,
  hasError,
}) => {
  const sendHistoryMemorised = useMemo(() => {
    return findFirstError(sendHistory);
  }, [sendHistory]);
  return (
    <tr className={styles.Inner}>
      <td>
        {sendHistoryMemorised?.dateSend
          ? dayjs(sendHistoryMemorised?.dateSend).format('DD.MM.YYYY HH:mm')
          : dayjs(sendHistory[0].dateSend).format('DD.MM.YYYY HH:mm')}
      </td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{username}</td>
      <td>{phone}</td>
      <td className={styles.Status}>
        {hasError ? (
          <>
            <Icon iconName="alert-circle" size={24} />
            <span className={styles.Error}> Не отправлено</span>
          </>
        ) : (
          <>
            <Icon iconName="check-circle" size={24} className={styles.Check} />
            <span className={styles.Text}>Выполнено</span>
          </>
        )}
      </td>
    </tr>
  );
};
