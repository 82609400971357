import { FC } from 'react';

import LandingSelector from '../LandingSelector';
import styles from './StatHeader.module.scss';
import {
  useSelectPeriodType,
  useSelectStatisticsId,
} from 'modules/landing-dashboard/landing-dashboard.selectors';
import {
  setFormComponentId,
  setPeriodType,
  setStatisticsId,
} from 'modules/landing-dashboard/landing-dashboard.reducer';
import useAppDispatch from 'hooks/useAppDispatch';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { EPeriodType } from 'modules/landing-dashboard/landing-dashboard.types';
import { VideoTutorial } from '../../../../components/VideoTutorial';
import { videoTutorials } from '../../../../constants/videos';

const StatHeader: FC = () => {
  const selectedId = useSelectStatisticsId();
  const dispatch = useAppDispatch();
  const handleLandingSelect = (id: number) => {
    dispatch(setFormComponentId(null));
    dispatch(setStatisticsId(id !== selectedId ? id : null));
  };

  const handleCloseFilter = () => {
    dispatch(setFormComponentId(undefined));
    dispatch(setStatisticsId(null));
    dispatch(setPeriodType('month' as EPeriodType));
  };

  const periodType = useSelectPeriodType();

  return (
    <div className={styles.Header}>
      <h1 className={styles.Title}>Статистика</h1>
      <LandingSelector
        landingSelectorClick={handleLandingSelect}
        classname={styles.landingSelector}
      />
      <VideoTutorial link={videoTutorials.workWithStatisticsAndRequestsInBUSINESSCARD} />
      <div className={styles.mobileFilterWrapper}>
        <MobileFilter
          landingSelect={handleLandingSelect}
          closeFilter={handleCloseFilter}
          type={'statistics'}
          periodType={periodType}
        />
      </div>
    </div>
  );
};

export default StatHeader;
