import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'components/Header';
import Circles from 'components/Circles';

const BasicLayout: FC = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Circles />
    </>
  );
};

export default BasicLayout;
