import { FC } from 'react';

import InputWithMultipleChoices from 'components/InputWithMultipleChoices';
import { InputProps } from 'components/Input';

type Props = {
  value: string[];
  setValue: (name: 'groupsLinks', value: string[], options: { shouldValidate: boolean }) => void;
  inputProps?: Omit<InputProps, 'label'>;
};

const GroupsLinksInput: FC<Props> = ({ value, setValue, inputProps }) => {
  return (
    <InputWithMultipleChoices
      addChoice={choice => setValue('groupsLinks', [...value, choice], { shouldValidate: true })}
      choicesProps={{
        choices: value,
        deleteChoice: choice =>
          setValue(
            'groupsLinks',
            value.filter(gl => gl !== choice),
            { shouldValidate: true },
          ),
      }}
      inputProps={{ label: 'Ссылки на группы', type: 'url', variant: 'dark', ...inputProps }}
    />
  );
};

export default GroupsLinksInput;
