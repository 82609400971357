import { FC } from 'react';

import Desktop from './components/Desktop';
import Mobile from './components/Mobile';

type Props = {
  id: string;
};

const BotInfo: FC<Props> = ({}) => {
  return (
    <>
      <Desktop />
      <Mobile />
    </>
  );
};

export default BotInfo;
