import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from './SettingsModal.module.scss';
import Button from 'components/Button';
import Input from 'components/Input';
import Modal, { CommonModalProps } from 'components/Modal';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { LandingGeneratorSettings } from 'modules/landing-generator/landing-generator.types';
import { landingSettingsSchema } from 'modules/landing-generator/landing-generator.schema';
import { setPageDomain } from '../../../../../my-pages/my-pages.reducer';
import { EPageDomain } from '../../../../../my-pages/my-pages.types';
import CheckboxSelect from '../../../../../../components/CheckboxSelect';
import useAppDispatch from 'hooks/useAppDispatch';
import { useActivePage } from '../../../../../my-pages/my-pages.selectors';

type Props = {
  modalProps: CommonModalProps;
};

const SettingsModal: FC<Props> = ({ modalProps }) => {
  const { copyAndNotify } = useCopyToClipboard();
  const page = useActivePage();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm<LandingGeneratorSettings>({
    mode: 'onChange',
    resolver: yupResolver(landingSettingsSchema()),
  });

  const onSubmit: SubmitHandler<LandingGeneratorSettings> = () => {};

  return (
    <Modal {...modalProps} className={styles.Modal}>
      <h3 className={styles.Title}>Настройки страницы</h3>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.FormFields}>
          <Input
            label="Название"
            register={register('name')}
            invalid={!!errors.name}
            errorMsg={errors.name?.message}
          />
          <Input
            label="Ссылка на страницу"
            type="url"
            icons={[{ name: 'copy', onClick: () => copyAndNotify(getValues().link) }]}
            register={register('link')}
            invalid={!!errors.link}
            errorMsg={errors.link?.message}
          />
          <CheckboxSelect
            title="Выберите вид домена"
            selected={page?.domain as EPageDomain}
            selectValue={value => dispatch(setPageDomain(value))}
            options={[
              {
                text: `https://starsonline.cc/${page?.link}`,
                value: EPageDomain.HOST,
              },
              {
                text: `https://${page?.link}/starsonline.wc`,
                value: EPageDomain.PATH,
                disabled: true,
              },
            ]}
          />
        </div>

        <Button className={styles.Button} type="submit" disabled={!isValid}>
          Сохранить изменения
        </Button>
      </form>
    </Modal>
  );
};

export default SettingsModal;
