import { FC } from 'react';
import { generatePath } from 'react-router-dom';

import styles from './PartnershipProgramMobileTabs.module.scss';
import NavWithTabsMobile from 'components/NavWithTabsMobile';
import r from 'constants/routes';
import { useLinesParams } from 'modules/partnership-program/partnership-program.hooks';

const PartnershipProgramMobileTabs: FC = () => {
  const line = useLinesParams();

  return (
    <NavWithTabsMobile
      className={styles.Nav}
      links={[
        { link: generatePath(r.partnershipProgram.index, { line }), name: 'Партнеры' },
        { link: generatePath(r.partnershipProgram.info, { line }), name: 'Другая информация' },
      ]}
    />
  );
};

export default PartnershipProgramMobileTabs;
