import { FC, useMemo } from 'react';
import { isEmpty, isNull } from 'lodash-es';
import { useGetAllVkAccounts } from '../../vk.selectors';
import AutomationPageLayout from '../../components/AutomationPageLayout';
import EmptyPage from 'components/EmptyPage';
import imageImg from 'assets/svg/empty-pages/image.svg';
import useDisclosure from 'hooks/useDisclosure';
import Button from 'components/Button';
import TranslationModalPost from '../../components/TranslationModalPost';
import { useGetAllAutoTranslationsQuery, useGetFilesFromVkQuery } from '../../vk.api';
import PostCard from '../../components/PostCard';
import { combinedTranslations } from '../../vk.utils';
import isUndefined from 'lodash-es/isUndefined';
import LoadingState from '../../components/LoadingState';

const AutoTranslation: FC = () => {
  const vkAccounts = useGetAllVkAccounts();
  const { data: translations, isFetching: isLoading } = useGetAllAutoTranslationsQuery();
  const ids: string[] = useMemo(() => {
    if (translations?.data?.items)
      return translations?.data?.items
        .map(item => {
          if (isNull(item)) return '';
          return item.previewFileId;
        })
        .filter(item => item);
  }, [translations?.data?.items]) as string[];
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });
  const { data: files, isFetching: areFilesLoading } = useGetFilesFromVkQuery(
    { ids },
    { skip: isUndefined(ids) },
  );

  const combinedData = useMemo(() => {
    if (translations && files) return combinedTranslations(translations.data, files);
  }, [translations, files]);

  if (isLoading || areFilesLoading) return <LoadingState />;
  if (isEmpty(vkAccounts)) return null;

  return (
    <>
      <AutomationPageLayout
        title={'Авто-трансляция'}
        emptyPage={
          <EmptyPage
            img={<img src={imageImg} width={205} height={205} alt="Image" />}
            title="добавь свою первую трансляцию "
            text="В несколько шагов"
            btnText="Добавить трансляцию"
            onClick={onOpen}
          />
        }
        cards={combinedData
          ?.filter(i => i)
          .map(card => (
            <PostCard
              key={card?.id}
              id={card?.id}
              img={card?.s3Url}
              date={card?.date}
              time={card?.times && card?.times[0]}
              isDone={card?.isDone}
              title={card?.title}
              type="translation"
            />
          ))}
        onAdd={onOpen}
        addBtnText={'Новая трансляция'}
        navAddon={
          <Button
            variant="primary"
            onClick={onOpen}
            disabled={!isUndefined(combinedData) && combinedData?.filter(i => i).length >= 10}
          >
            Новая трансляция
          </Button>
        }
      />
      {open && <TranslationModalPost isOpen={open} onClose={onClose} />}
    </>
  );
};

export default AutoTranslation;
