import type { AppDispatch } from 'config/store';
import dayjs from 'dayjs';

import { setTimer } from './auth.reducer';
import { IAuthData } from './auth.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SerializedError } from '@reduxjs/toolkit';
import { TAuthErrorResult } from './auth.types';
import toast from 'toasts';

export const passTime = (dispatch: AppDispatch) => {
  const seconds = dayjs().add(3, 'minutes').toString();
  dispatch(setTimer(seconds));
};

export const isAuthError = (
  res: { data: IAuthData } | { error: FetchBaseQueryError | SerializedError },
) => {
  if ('error' in res) {
    const clonedError: TAuthErrorResult = JSON.parse(JSON.stringify(res));
    toast.error(clonedError?.error?.data?.body);
  }
};
