import { useCallback } from 'react';

import useAppDispatch from 'hooks/useAppDispatch';
import { setComponentProperty, setComponentText } from '../my-pages.reducer';
import { FormFields } from '../my-pages.types';

function useSetComponentProperty(hash: string) {
  const dispatch = useAppDispatch();

  const setProperty = useCallback(
    (key: string, value?: string | number | boolean | null | FormFields) =>
      dispatch(setComponentProperty({ hash, key, value })),
    [dispatch, hash],
  );

  const setText = useCallback(
    (key: string, value?: string) => dispatch(setComponentText({ hash, key, value })),
    [dispatch, hash],
  );

  return { setProperty, setText };
}

export default useSetComponentProperty;
