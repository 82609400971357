import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import findIndex from 'lodash-es/findIndex';
import max from 'lodash-es/max';
import zip from 'lodash-es/zip';
import undoable from 'redux-undo';

import {
  Landing,
  LandingText,
  TextAlignment,
  LandingGeneratorSliceState,
  LandingButton,
  LandingButtonSocialNetwork,
  EShape,
  LandingVideoText,
  Website,
  LandingForm,
  LandingElement,
} from './landing-generator.types';
import {
  getLanding,
  getLandingButton,
  getSelectedText,
  getLandingButtonSocialNetwork,
  getLandingVideoText,
  getLandingWebsite,
} from './landing-generator.getters';
import {
  enumirateLanding,
  flattenLandingSorted,
  GlobalIdInstance,
  GlobalOrderInstance,
} from './landing-generator.utils';

const initialState: LandingGeneratorSliceState = {
  landings: [],
};

const landingGeneratorSlice = createSlice({
  name: 'landing-generator',
  initialState,
  reducers: {
    createLanding: {
      reducer: (state, { payload }: PayloadAction<Landing>) => {
        state.landings.push(payload);
      },
      prepare: data => ({ payload: { ...data, id: GlobalIdInstance.inc() } }),
    },
    setLandings: {
      reducer: (state, { payload }: PayloadAction<Landing[]>) => {
        state.landings = payload;
        GlobalOrderInstance.newOrder =
          max(
            payload
              .flatMap(landing => flattenLandingSorted(landing))
              .map(landing => landing.value.order ?? 0),
          ) ?? 0;
      },
      prepare: (data: Landing[]) => ({
        payload: data.map(landing => ({
          ...enumirateLanding(landing),
          id: landing.id ?? GlobalIdInstance.inc(),
        })),
      }),
    },
    setLandingColor: (state, { payload }: PayloadAction<{ id: Landing['id']; color: string }>) => {
      const landing = getLanding(state, payload.id);
      if (landing) {
        landing.color = payload.color;
      }
    },
    setLandingPhoto: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; photo: Landing['photo'] }>,
    ) => {
      const saved = getLanding(state, payload.id);
      if (saved) {
        saved.photo = payload.photo;
        if (saved.photo) saved.photo.order = GlobalOrderInstance.inc();
      }
    },
    setLandingPhotoShape: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; shape: EShape }>,
    ) => {
      const saved = getLanding(state, payload.id);
      if (saved && saved.photo) {
        saved.photo.shape = payload.shape;
      }
    },
    setLandingVideo: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; video: Landing['video'] }>,
    ) => {
      const saved = getLanding(state, payload.id);
      if (saved) {
        saved.video = payload.video;
        if (saved.video) saved.video.order = GlobalOrderInstance.inc();
      }
    },
    updateLandingVideoText: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; type: LandingVideoText; text: string }>,
    ) => {
      const saved = getLandingVideoText(state, payload.id, payload.type);
      if (saved) {
        saved.content = payload.text;
      }
    },
    toggleLandingVideoTextBold: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; type: LandingVideoText }>,
    ) => {
      const saved = getLandingVideoText(state, payload.id, payload.type);
      if (saved) {
        saved.isBold = !saved.isBold;
      }
    },
    toggleLandingVideoTextItalic: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; type: LandingVideoText }>,
    ) => {
      const saved = getLandingVideoText(state, payload.id, payload.type);
      if (saved) {
        saved.isItalic = !saved.isItalic;
      }
    },
    toggleLandingVideoTextUnderline: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; type: LandingVideoText }>,
    ) => {
      const saved = getLandingVideoText(state, payload.id, payload.type);
      if (saved) {
        saved.isUnderline = !saved.isUnderline;
      }
    },
    setLandingVideoTextColor: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: LandingVideoText;
        color: string;
      }>,
    ) => {
      const saved = getLandingVideoText(state, payload.id, payload.type);
      if (saved) {
        saved.color = payload.color;
      }
    },
    alignLandingVideoText: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: LandingVideoText;
        alignment: TextAlignment;
      }>,
    ) => {
      const saved = getLandingVideoText(state, payload.id, payload.type);
      if (saved) {
        saved.alignment = payload.alignment;
      }
    },
    setLandingText: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; text: Landing['text'] }>,
    ) => {
      const saved = getLanding(state, payload.id);
      if (saved) {
        saved.text = payload.text;
        if (saved.text) {
          saved.text.title = saved.text.title.map(text => ({
            ...text,
            order: text.order ?? GlobalOrderInstance.inc(),
          }));
          saved.text.subtitle = saved.text.subtitle.map(text => ({
            ...text,
            order: text.order ?? GlobalOrderInstance.inc(),
          }));
          saved.text.text = saved.text.text.map(text => ({
            ...text,
            order: text.order ?? GlobalOrderInstance.inc(),
          }));
        }
      }
    },
    updateLandingText: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: keyof LandingText;
        index: number;
        text: string;
      }>,
    ) => {
      const selected = getSelectedText(state, payload.id, payload.type, payload.index);
      if (selected) {
        const [text, landing] = selected;
        if (payload.type === 'order') return;
        if (text && payload.text) {
          text.content = payload.text;
        } else {
          landing.text[payload.type].splice(payload.index, 1);
        }
      }
    },
    toggleLandingTextBold: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; type: keyof LandingText; index: number }>,
    ) => {
      const selected = getSelectedText(state, payload.id, payload.type, payload.index);
      if (selected) {
        const [text] = selected;
        if (text) {
          text.isBold = !text.isBold;
        }
      }
    },
    toggleLandingTextItalic: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; type: keyof LandingText; index: number }>,
    ) => {
      const selected = getSelectedText(state, payload.id, payload.type, payload.index);
      if (selected) {
        const [text] = selected;
        if (text) {
          text.isItalic = !text.isItalic;
        }
      }
    },
    toggleLandingTextUnderline: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; type: keyof LandingText; index: number }>,
    ) => {
      const selected = getSelectedText(state, payload.id, payload.type, payload.index);
      if (selected) {
        const [text] = selected;
        if (text) {
          text.isUnderline = !text.isUnderline;
        }
      }
    },
    setLandingTextColor: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: keyof LandingText;
        index: number;
        color: string;
      }>,
    ) => {
      const selected = getSelectedText(state, payload.id, payload.type, payload.index);
      if (selected) {
        const [text] = selected;
        if (text) {
          text.color = payload.color;
        }
      }
    },
    alignLandingText: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: keyof LandingText;
        index: number;
        alignment: TextAlignment;
      }>,
    ) => {
      const selected = getSelectedText(state, payload.id, payload.type, payload.index);
      if (selected) {
        const [text] = selected;
        if (text) {
          text.alignment = payload.alignment;
        }
      }
    },
    setLandingButton: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; btn: LandingButton }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing) {
        const btnIndex = landing.buttons.findIndex(b => b.id === payload.btn.id);
        if (btnIndex !== -1) {
          landing.buttons[btnIndex] = payload.btn;
        } else {
          payload.btn.order = GlobalOrderInstance.inc();
          landing.buttons.push(payload.btn);
        }
      }
    },
    deleteLandingWebsite: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; btnId: string | number }>,
    ) => {
      const button = getLandingButton(state, payload.id, payload.btnId);
      if (button) {
        button.websites = [];
      }
    },
    deleteLandingButtonSocialNetwork: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        type: LandingButtonSocialNetwork;
      }>,
    ) => {
      const button = getLandingButton(state, payload.id, payload.btnId);
      if (button) {
        button[payload.type] = undefined;
      }
    },
    setLandingButtonSocialNetworkShape: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        type: LandingButtonSocialNetwork;
        shape: EShape;
      }>,
    ) => {
      const socialNetwork = getLandingButtonSocialNetwork(
        state,
        payload.id,
        payload.btnId,
        payload.type,
      );
      if (socialNetwork) {
        socialNetwork.shape = payload.shape;
      }
    },
    setLandingButtonSocialNetworkColor: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        type: LandingButtonSocialNetwork;
        color: string;
      }>,
    ) => {
      const socialNetwork = getLandingButtonSocialNetwork(
        state,
        payload.id,
        payload.btnId,
        payload.type,
      );
      if (socialNetwork) {
        socialNetwork.color = payload.color;
      }
    },
    setLandingWebsiteColor: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        index: number;
        color: string;
      }>,
    ) => {
      const website = getLandingWebsite(state, payload.id, payload.btnId, payload.index);
      if (website) {
        website.color = payload.color;
      }
    },
    setLandingModalButtonColor: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; btnId: string | number; color: string }>,
    ) => {
      const button = getLandingButton(state, payload.id, payload.btnId);
      if (button && button.modal) {
        button.modal.buttonColor = payload.color;
      }
    },
    removeLandingModalButton: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; btnId: Landing['id'] }>,
    ) => {
      const button = getLandingButton(state, payload.id, payload.btnId);
      if (button && button.modal) {
        button.modal = undefined;
      }
    },
    toggleWebsiteTextBold: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        index: number;
        type: keyof Pick<Website, 'title' | 'text'>;
      }>,
    ) => {
      const website = getLandingWebsite(state, payload.id, payload.btnId, payload.index);
      if (website) {
        website[payload.type].isBold = !website[payload.type].isBold;
      }
    },
    toggleWebsiteTextItalic: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        index: number;
        type: keyof Pick<Website, 'title' | 'text'>;
      }>,
    ) => {
      const website = getLandingWebsite(state, payload.id, payload.btnId, payload.index);
      if (website) {
        website[payload.type].isItalic = !website[payload.type].isItalic;
      }
    },
    toggleWebsiteTextUnderline: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        index: number;
        type: keyof Pick<Website, 'title' | 'text'>;
      }>,
    ) => {
      const website = getLandingWebsite(state, payload.id, payload.btnId, payload.index);
      if (website) {
        website[payload.type].isUnderline = !website[payload.type].isUnderline;
      }
    },
    setWebsiteTextColor: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        index: number;
        type: keyof Pick<Website, 'title' | 'text'>;
        color: string;
      }>,
    ) => {
      const website = getLandingWebsite(state, payload.id, payload.btnId, payload.index);
      if (website) {
        website[payload.type].color = payload.color;
      }
    },
    alignWebsiteText: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        btnId: string | number;
        index: number;
        type: keyof Pick<Website, 'title' | 'text'>;
        alignment: TextAlignment;
      }>,
    ) => {
      const website = getLandingWebsite(state, payload.id, payload.btnId, payload.index);
      if (website) {
        website[payload.type].alignment = payload.alignment;
      }
    },
    setLandingForm: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; form: LandingForm }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing) {
        landing.form = payload.form;
        if (landing.form) landing.form.order = GlobalOrderInstance.inc();
      }
    },
    removeLandingFormModalButton: (state, { payload }: PayloadAction<{ id: Landing['id'] }>) => {
      const landing = getLanding(state, payload.id);
      if (landing && landing.form) {
        landing.form.modal = undefined;
      }
    },
    setLandingFormModalButtonColor: (
      state,
      { payload }: PayloadAction<{ id: Landing['id']; color: string }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing && landing.form && landing.form.modal) {
        landing.form.modal.buttonColor = payload.color;
      }
    },
    toggleFormTextBold: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: keyof Pick<LandingForm, 'title' | 'text'>;
      }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing && landing.form) {
        landing.form[payload.type].isBold = !landing.form[payload.type].isBold;
      }
    },
    toggleFormTextItalic: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: keyof Pick<Website, 'title' | 'text'>;
      }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing && landing.form) {
        landing.form[payload.type].isItalic = !landing.form[payload.type].isItalic;
      }
    },
    toggleFormTextUnderline: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: keyof Pick<Website, 'title' | 'text'>;
      }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing && landing.form) {
        landing.form[payload.type].isUnderline = !landing.form[payload.type].isUnderline;
      }
    },
    setFormTextColor: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: keyof Pick<Website, 'title' | 'text'>;
        color: string;
      }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing && landing.form) {
        landing.form[payload.type].color = payload.color;
      }
    },
    alignFormText: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        type: keyof Pick<Website, 'title' | 'text'>;
        alignment: TextAlignment;
      }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing && landing.form) {
        landing.form[payload.type].alignment = payload.alignment;
      }
    },
    reorderLanding: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: Landing['id'];
        oldOrder: LandingElement[];
        newOrder: LandingElement[];
      }>,
    ) => {
      const landing = getLanding(state, payload.id);
      if (landing) {
        const elems = flattenLandingSorted(landing);
        zip(elems, payload.oldOrder).map(([elem, oldElem]) => {
          if (elem)
            elem.value.order =
              findIndex(payload.newOrder, { value: { order: oldElem?.value.order } }) ??
              GlobalOrderInstance.inc();
        });
      }
    },
  },
});

export const {
  createLanding,
  setLandings,
  setLandingColor,
  setLandingPhoto,
  setLandingPhotoShape,
  setLandingVideo,
  updateLandingVideoText,
  toggleLandingVideoTextBold,
  toggleLandingVideoTextItalic,
  toggleLandingVideoTextUnderline,
  setLandingVideoTextColor,
  alignLandingVideoText,
  setLandingText,
  toggleLandingTextBold,
  toggleLandingTextItalic,
  toggleLandingTextUnderline,
  setLandingTextColor,
  alignLandingText,
  updateLandingText,
  setLandingButton,
  deleteLandingWebsite,
  deleteLandingButtonSocialNetwork,
  setLandingButtonSocialNetworkShape,
  setLandingButtonSocialNetworkColor,
  setLandingWebsiteColor,
  setLandingModalButtonColor,
  removeLandingModalButton,
  toggleWebsiteTextBold,
  toggleWebsiteTextItalic,
  toggleWebsiteTextUnderline,
  setWebsiteTextColor,
  alignWebsiteText,
  setLandingForm,
  removeLandingFormModalButton,
  setLandingFormModalButtonColor,
  toggleFormTextBold,
  toggleFormTextItalic,
  toggleFormTextUnderline,
  setFormTextColor,
  alignFormText,
  reorderLanding,
} = landingGeneratorSlice.actions;

const landingGeneratorReducer = landingGeneratorSlice.reducer;

const undoableLandingGeneratorReducer = undoable(landingGeneratorReducer);

export default undoableLandingGeneratorReducer;
