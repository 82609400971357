import { FC } from 'react';

import styles from './EmptyBlockIcon.module.scss';
import BlockIcon from '../BlockIcon';

const EmptyBlockIcon: FC = () => {
  return (
    <BlockIcon width={36} height={36}>
      <div className={styles.PlusLine} />
      <div className={styles.PlusLine} />
    </BlockIcon>
  );
};

export default EmptyBlockIcon;
