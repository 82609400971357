import { FC } from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import styles from './InfoCard.module.scss';

type Props = {
  title: string;
  icon: string;
  count: number;
  link?: string;
  backIcon?: string;
  mobile?: boolean;
  onCardClick?: () => void;
};

const InfoCard: FC<Props> = ({ title, icon, count, backIcon, mobile, onCardClick }) => {
  return (
    <div onClick={onCardClick} className={classNames(styles.InfoCard, mobile && styles.Mobile)}>
      {backIcon && <img src={backIcon} alt="backIcon" className={styles.BackIcon} />}
      {icon && <Icon iconName="msg-bg" size={130} className={styles.BackIcon} />}
      <div className={styles.Title}>
        <p>{title}</p>
      </div>
      <div className={styles.Footer}>
        <div className={styles.Count}>
          <Icon iconName={icon} size={20} className={styles.Icon} />
          <div>{count}</div>
        </div>
        <div className={styles.ArrowButton}>
          <Icon iconName="arrow-right" size={20} className={styles.Icon} />
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
