import { FC, ReactNode } from 'react';

import styles from './Section.module.scss';

type Props = {
  children: ReactNode;
};

const Section: FC<Props> = ({ children }) => {
  return <div className={styles.Section}>{children}</div>;
};

export default Section;
