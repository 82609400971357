import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetLandingsByPublishedNameQuery } from '../cards.api';
import LandingElement from '../components/Landing';

import styles from './Card.module.scss';

const Card: FC = () => {
  const { name } = useParams();
  const { data, isLoading, isError } = useGetLandingsByPublishedNameQuery(
    { name: name as string },
    { skip: !name },
  );

  if (isLoading) return null;
  if (isError) return null;
  return (
    <div className={styles.Card}>
      {data?.body.map(landing => (
        <LandingElement key={landing.id} landing={landing} />
      ))}
    </div>
  );
};

export default Card;
