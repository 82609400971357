import { FC, ReactNode } from 'react';
import { useDrag } from 'react-dnd';

import styles from './Element.module.scss';
import { EDraggableItem } from 'modules/my-pages/my-pages.types';
import useAppDispatch from 'hooks/useAppDispatch';
import { appendComponent } from 'modules/my-pages/my-pages.helpers';
import { getClickType } from 'utils/getClickType';

type Props = {
  icon: ReactNode;
  name: string;
  styleStroke?: boolean;
  type: EDraggableItem;
  parentId: string;
};

const Element: FC<Props> = ({ icon, name, styleStroke, type, parentId }) => {
  const dispatch = useAppDispatch();
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const onAppend = () => {
    appendComponent(type, dispatch, -1, parentId);
  };

  return (
    <div
      ref={drag}
      className={styles.Wrapper}
      {...getClickType(onAppend)}
      data-dragging={isDragging}
    >
      <div className={styles.Icon} data-style-stroke={styleStroke}>
        {icon}
      </div>
      <p className={styles.Name}>{name}</p>
    </div>
  );
};

export default Element;
