import classNames from 'classnames';
import { FC, ReactNode, useContext, useRef } from 'react';

import styles from './EditableElement.module.scss';
import ContextMenu from 'components/ContextMenu';
import IconButton from 'components/IconButton';
import useClickOutside from 'hooks/useClickOutside';
import useDisclosure from 'hooks/useDisclosure';
import { DragControlsContext } from 'modules/landing-generator/landing-generator.contexts';

type MenuElement = {
  onClick: () => void;
  iconName: string;
};

type Props = {
  menuElements: MenuElement[];
  children: ReactNode;
  menuAddon?: JSX.Element;
  onClose?: () => void;
  openOnHover?: boolean;
};

const EditableElement: FC<Props> = ({
  menuElements,
  children,
  menuAddon,
  openOnHover,
  onClose,
}) => {
  const controls = useContext(DragControlsContext);
  const ref = useRef<HTMLDivElement>(null);

  const {
    open: editorMode,
    onOpen: enableEditorMode,
    onClose: disableEditorMode,
  } = useDisclosure();

  const handleClose = () => {
    disableEditorMode();
    if (onClose) onClose();
  };

  useClickOutside(ref, handleClose);

  return (
    <div
      className={styles.Wrapper}
      ref={ref}
      onClick={enableEditorMode}
      data-edit-mode={editorMode}
      onMouseEnter={openOnHover ? enableEditorMode : undefined}
      onMouseLeave={openOnHover ? handleClose : undefined}
      onPointerDown={e => !editorMode && controls.start(e)}
    >
      {children}

      <ContextMenu
        open={editorMode}
        onClose={handleClose}
        className={styles.Menu}
        withBridge
        disableClickOutside
        disableFocusTrap
      >
        {menuElements.map(element => (
          <IconButton
            key={element.iconName}
            iconClassName={classNames({
              [styles.Delete]: element.iconName === 'delete',
            })}
            iconSize={16}
            {...element}
          />
        ))}

        {menuAddon}
      </ContextMenu>
    </div>
  );
};

export default EditableElement;
