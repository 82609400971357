import { object, ref, SchemaOf, string } from 'yup';
import i18n from 'i18n';

import { instagramAccount, vkAccount } from 'constants/reg-exps';
import { AccountSchema, NewPasswordRequestBody, ViewPasswordRequestBody } from './settings.types';

export const accountSchema = (): SchemaOf<AccountSchema> =>
  object()
    .shape({
      fullName: string().required().label(i18n.t('labels.fullName')),
      phone: string().required().notRequired().label(i18n.t('labels.phone')),
      vk: string()
        .url()
        .matches(vkAccount, { excludeEmptyString: true })
        .notRequired()
        .label(i18n.t('labels.vk')),
      tg: string().notRequired().label(i18n.t('labels.telegram')),
      instagram: string()
        .url()
        .matches(instagramAccount, { excludeEmptyString: true })
        .notRequired()
        .label(i18n.t('labels.instagram')),
    })
    .required();

export const newPasswordSchema = (): SchemaOf<NewPasswordRequestBody> =>
  object()
    .shape({
      old: string().required().label(i18n.t('labels.oldPassword')),
      new: string().required().label(i18n.t('labels.newPassword')),
      new2: string()
        .oneOf([ref('new')])
        .required()
        .label(i18n.t('labels.confirmPassword')),
    })
    .required();

export const newPasswordsOnlySchema = (): SchemaOf<Pick<NewPasswordRequestBody, 'new' | 'new2'>> =>
  object()
    .shape({
      new: string().required().label(i18n.t('labels.newPassword')),
      new2: string()
        .oneOf([ref('new')])
        .required()
        .label(i18n.t('labels.confirmPassword')),
    })
    .required();

export const viewPasswordSchema = (): SchemaOf<ViewPasswordRequestBody> =>
  object()
    .shape({
      email: string().email().required().label(i18n.t('labels.email')),
    })
    .required();
