import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';

import r from 'constants/routes';
import { ISignUpForm } from 'modules/auth/auth.types';
import { registerSchema } from 'modules/auth/auth.schema';
import { useCreateUserMutation } from 'modules/auth/auth.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { signInStateHandler, updateRefreshToken } from 'modules/auth/auth.reducer';
import useTypedSelector from 'hooks/useAppSelector';
import { selectIsAuthenticated } from 'modules/auth/auth.selectors';

import AccountRegistered from 'modules/auth/components/AccountRegistered';
import AuthForm from 'modules/auth/components/AuthForm';
import Input from 'components/Input';
import PasswordInput from 'components/PasswordInput';
import CheckBox from 'components/CheckBox';

import styles from './Registration.module.scss';

const Registration: FC = () => {
  const [createUser, { isSuccess }] = useCreateUserMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useTypedSelector(selectIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated && !isSuccess) {
      navigate(r.account);
    }
  }, [isAuthenticated, navigate, isSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    getValues,
    formState: { isValid, errors },
  } = useForm<ISignUpForm>({
    mode: 'onChange',
    resolver: yupResolver(registerSchema()),
  });

  const phone = watch('phone');
  const [password, confirmPassword, finPassword, confirmFinPassword] = getValues([
    'password',
    'confirmPass',
    'finPassword',
    'confirmFinPass',
  ]);

  useEffect(() => {
    const referral = searchParams.get('referralCode');
    setValue('referralCode', referral ? referral : null);
  }, [searchParams, setValue]);

  useEffect(() => {
    if (phone) {
      const formattedPhone = formatIncompletePhoneNumber(phone);
      setValue('phone', formattedPhone);
    }
  }, [phone, setValue]);

  const onHandleFocus = () => {
    if (!phone) {
      setValue('phone', formatIncompletePhoneNumber('+7'));
    }
  };

  const onSubmit: SubmitHandler<ISignUpForm> = async ({
    confirmPass,
    confirmFinPass,
    agreement,
    phone: phoneWithMask,
    ...data
  }) => {
    const phoneWithoutMask = phoneWithMask.split(' ').join('');

    const res = await createUser({ ...data, phone: phoneWithoutMask });

    if ('data' in res) {
      dispatch(updateRefreshToken(res.data));
      dispatch(signInStateHandler());
    }
  };

  const onEnter = () => {
    navigate(r.auth.signIn);
  };

  const isNoteOneOfPassword = errors.password?.type === 'notOneOf';
  const isNoteOneOfFinPassword = errors.finPassword?.type === 'notOneOf';

  return isSuccess ? (
    <AccountRegistered />
  ) : (
    <AuthForm
      title="Регистрация"
      buttonText="Зарегистрироваться"
      footer={{ text: 'Уже есть аккаунт?', btn: { text: 'Войти', onClick: onEnter } }}
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
      hasVideoPresent
    >
      <Input
        label="Имя и Фамилия"
        register={register('fullName')}
        bigText
        invalid={!!errors.fullName}
        errorMsg={errors.fullName?.message}
      />
      <Input
        label="Логин"
        register={register('username')}
        invalid={!!errors.username}
        errorMsg={errors.username?.message}
        required={false}
      />
      <Input
        label="Email"
        register={register('email')}
        type="email"
        bigText
        invalid={!!errors.email}
        errorMsg={errors.email?.message}
      />
      <Input label="Telegram username" register={register('telegram')} bigText />
      <Input
        label="Номер телефона"
        register={register('phone')}
        type="tel"
        bigText
        invalid={!!errors.phone}
        errorMsg={errors.phone?.message}
        onFocus={onHandleFocus}
      />
      <PasswordInput
        label="Финансовый пароль"
        register={register('finPassword')}
        invalid={!!errors.finPassword || isNoteOneOfPassword}
        errorMsg={errors.finPassword?.message}
        onBlur={() => {
          if (finPassword && confirmFinPassword) {
            trigger(['confirmFinPass']);
          }
        }}
      />
      <PasswordInput
        label={
          <>
            Подтвердить фин<span className={styles.RepeatFinPassSuffix}>ансовый</span> пароль
          </>
        }
        register={register('confirmFinPass')}
        invalid={!!errors.confirmFinPass}
        errorMsg={errors.confirmFinPass?.message}
        onBlur={() => {
          if (finPassword && confirmFinPassword) {
            trigger(['finPassword']);
          }
        }}
      />
      <PasswordInput
        label="Пароль"
        register={register('password')}
        invalid={!!errors.password || isNoteOneOfFinPassword}
        errorMsg={errors.password?.message}
        onBlur={() => {
          if (password && confirmPassword) {
            trigger(['confirmPass']);
          }
        }}
      />
      <PasswordInput
        label="Подтвердить пароль"
        register={register('confirmPass')}
        invalid={!!errors.confirmPass}
        errorMsg={errors.confirmPass?.message}
        onBlur={() => {
          if (password && confirmPassword) {
            trigger(['password']);
          }
        }}
      />
      <CheckBox
        className={styles.CheckBox}
        textClassName={styles.CheckBoxText}
        {...register('agreement')}
        text={
          <>
            Я согласен с{' '}
            <a
              href={'https://stars-online.pro/offer.pdf'}
              className={styles.Link}
              target={'_blank'}
            >
              Условиями Использования
            </a>{' '}
            и{' '}
            <a
              href={'https://stars-online.pro/policy.pdf'}
              className={styles.Link}
              target={'_blank'}
            >
              Политикой Конфиденциальности
            </a>
          </>
        }
      />
    </AuthForm>
  );
};

export default Registration;
