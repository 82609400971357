import { CSSProperties, FC, useRef } from 'react';

import styles from './DependentText.module.scss';
import { TextAlignment, TextProperties } from 'modules/landing-generator/landing-generator.types';
import useDisclosure from 'hooks/useDisclosure';
import useClickOutside from 'hooks/useClickOutside';
import TextMenu from '../TextMenu';
import classNames from 'classnames';

type Props = {
  type: 'title' | 'subtitle' | 'text';
  saveText?: (text: string) => void;
  className?: string;
} & TextProperties;

const DependentText: FC<Props> = ({
  type,
  content,
  isBold,
  isItalic,
  isUnderline,
  color,
  alignment,
  className,
  saveText,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const {
    open: editorMode,
    onOpen: enableEditorMode,
    onClose: disableEditorMode,
  } = useDisclosure();

  const handleDisableEditorMode = () => {
    if (saveText) {
      saveText(ref.current?.textContent ?? '');
      disableEditorMode();
    }
  };

  const handleDeleteText = () => {
    if (saveText) {
      saveText('');
      disableEditorMode();
    }
  };

  useClickOutside(ref, handleDisableEditorMode);

  const style: CSSProperties = {
    fontSize: type === 'title' ? 24 : 16,
    fontWeight: isBold ? 700 : 400,
    fontStyle: isItalic ? 'italic' : 'normal',
    textDecoration: isUnderline ? 'underline' : 'none',
    color: color ?? 'var(--color-secondary-darkest)',
    textAlign: alignment.toLowerCase() as TextAlignment,
  };

  return (
    <div className={classNames(styles.Wrapper, className)} ref={ref}>
      <p
        className={styles.Text}
        style={style}
        contentEditable={editorMode}
        onClick={saveText && enableEditorMode}
      >
        {content}
      </p>

      <TextMenu open={editorMode} onClose={disableEditorMode} onDelete={handleDeleteText} />
    </div>
  );
};

export default DependentText;
