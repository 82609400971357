import { FC } from 'react';
import classNames from 'classnames';

import r from 'constants/routes';

import styles from './MobileFooter.module.scss';
import { NavLink } from 'react-router-dom';

const links = [
  { link: r.telegram.index, name: 'Бот-администратор' },
  { link: r.telegram.spamBots, name: 'Бот рассылки' },
  { link: r.telegram.greetingBot, name: 'Авто-приветствие' },
];

const MobileFooter: FC = () => {
  return (
    <div className={classNames(styles.FooterWrapper)}>
      <div className={classNames(styles.Footer)}>
        <div className={styles.Links}>
          {links.map(({ link, name }) => (
            <NavLink
              key={link}
              to={link}
              className={({ isActive }) =>
                classNames(styles.Text, {
                  [styles.Active]: isActive,
                })
              }
              end
            >
              {name}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
