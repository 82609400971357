import { FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import {
  ETextStyle,
  NonNullableActiveComponent,
  Text as TText,
} from 'modules/my-pages/my-pages.types';
import InputOutline from 'components/InputOutline';
import TextStyleSelector from '../../tools/TextStyleSelector';
import TextPropertiesEditor from '../../tools/TextPropertiesEditor';
import ClickAction from '../../tools/ClickAction';
import AnchorInput from '../../tools/AnchorInput';
import AddTextBtn from '../../tools/AddTextBtn';
import ToolsWrapper from '../../tools/ToolsWrapper';
import useSetComponentProperty from 'modules/my-pages/hooks/useSetComponentProperty';

type Props = {
  component: NonNullableActiveComponent<TText>;
};

const Text: FC<Props> = ({ component }) => {
  const { hash, order, data } = component.component;
  const { setProperty, setText } = useSetComponentProperty(hash);
  const anchorActive = !!data.isAnchorActive;

  return (
    <ToolsWrapper>
      <TextStyleSelector
        style={data.textStyle}
        setStyle={value => setProperty('textStyle', value)}
      />

      <InputOutline
        label="Текст:"
        as={TextareaAutosize}
        value={data.text}
        onChange={e => setText('text', e.target.value)}
      />

      <TextPropertiesEditor
        properties={data.properties}
        setProperty={(key, value) => setProperty(`properties.${key}`, value)}
        type="text"
      />

      <ClickAction
        click={data.onClick}
        setAction={value => setProperty('onClick.action', value)}
        setLink={value => setText('onClick.link', value)}
      />

      <AnchorInput
        anchor={data.anchor}
        setAnchor={value => setText('anchor', value)}
        setAnchorActive={() => setProperty('isAnchorActive', !anchorActive)}
        active={anchorActive}
      />

      {data.textStyle === ETextStyle.TITLE && (
        <AddTextBtn parentId={component.parent.hash} atOrder={order + 1} />
      )}
    </ToolsWrapper>
  );
};

export default Text;
