import styles from './SpamBotSentMessages.module.scss';
import { useGetSpamSentMessagesQuery } from '../../../../codegen/telegram.spam.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { useBotId } from '../../../../telegram.hooks';
import { useGetSpamSentMessageParams } from '../../../../codegen/telegram.spam.selectors';
import { urlOffset } from '../../../../telegram.utils';
import { useNavigate, useParams } from 'react-router-dom';
import isUndefined from 'lodash-es/isUndefined';
import { useLayoutEffect } from 'react';
import { setSpamSentMessagesPageParams } from '../../../../codegen/telegram.spam.reducer';
import LoadingState from 'components/LoadingState';
import SentMessageCard from '../../../../components/SentMessageCard';
import Pagination from '../../../../../../components/Pagination';
import SentMessagesWrapper from '../../../../components/SentMessagesWrapper';
import EmptyText from '../../../../components/EmptyText';

const SpamBotSentMessages = () => {
  const { chatId } = useParams();

  const dispatch = useAppDispatch();
  const botId = useBotId();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const params = useGetSpamSentMessageParams();
  const isParamsMatch = params.offset === +urlOffset(queryParams.get('offsetSent'));

  const { data, isFetching } = useGetSpamSentMessagesQuery(
    { botId, chatId: String(chatId), ...params },
    { skip: isUndefined(chatId) || !isParamsMatch, refetchOnMountOrArgChange: true },
  );

  useLayoutEffect(() => {
    const offset = urlOffset(queryParams.get('offsetSent'));
    dispatch(setSpamSentMessagesPageParams({ offset }));
  }, []);

  const pageChange = (page: number) => {
    let offset;
    queryParams.set('pageSent', `${page}`);

    if (page === 1) {
      offset = 0;
      queryParams.set('offsetSent', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setSpamSentMessagesPageParams({ offset }));
    } else {
      offset = (page - 1) * 5;
      queryParams.set('offsetSent', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setSpamSentMessagesPageParams({ offset }));
    }
  };

  return (
    <div className={styles.Root}>
      <h1 className={styles.Title}>Отправленные сообщения</h1>

      {data?.count === 0 ? (
        <EmptyText text={'У вас пока нет отправленных сообщений'} />
      ) : (
        <SentMessagesWrapper>
          {isFetching ? (
            <LoadingState />
          ) : (
            <>
              {data?.data?.map(item => (
                <SentMessageCard key={item.createdAt + item.text} {...item} />
              ))}
            </>
          )}
        </SentMessagesWrapper>
      )}

      <Pagination
        onPageChange={pageChange}
        offset={1}
        totalCount={data?.count ? Math.ceil(data?.count / 5) : 0}
        currPage={Number(queryParams.get('pageSent'))}
      />
    </div>
  );
};

export default SpamBotSentMessages;
