import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './GoBack.module.scss';
import Icon from 'components/Icon';

type Props = {
  staticPos?: boolean;
  className?: string;
  onClick?: () => void;
  backWord?: ReactNode;
  backWordClassName?: string;
};

const GoBack: FC<Props> = ({ staticPos, className, onClick, backWord, backWordClassName }) => {
  const navigate = useNavigate();

  return (
    <div
      className={classNames(styles.GoBack, className)}
      onClick={() => (onClick ? onClick() : navigate(-1))}
    >
      <Icon
        iconName="chevron-left"
        size={12}
        className={classNames(styles.GoBack, {
          [styles.GoBackStatic]: staticPos,
        })}
      />
      <span className={classNames(backWordClassName, styles.backWord)} title={String(backWord)}>
        {backWord ?? 'Назад'}
      </span>
    </div>
  );
};

export default GoBack;
