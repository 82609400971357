import { FC } from 'react';

import styles from './BgColorSelector.module.scss';
import Label from 'components/Label';
import Icon from 'components/Icon';
import WithColorPicker from 'components/WithColorPicker';

const presets = {
  1: {
    background: 'linear-gradient(180deg, #f0f0f0 0%, #bebebe 100%)',
    border: 'rgba(255, 255, 255, 0.2)',
  },
  2: {
    background: 'linear-gradient(180deg, #1b1b1b 0%, #101010 100%)',
    border: 'rgba(255, 255, 255, 0.6)',
  },
  3: {
    background: 'linear-gradient(113.96deg, #c893ce 0%, #6205ab 100%)',
    border: 'rgba(255, 255, 255, 0.2)',
  },
  4: {
    background: 'linear-gradient(113.96deg, #43cbb2 0%, #c52397 100%)',
    border: 'rgba(255, 255, 255, 0.2)',
  },
  5: {
    background: 'linear-gradient(113.96deg, #cba543 0%, #c52397 100%)',
    border: 'rgba(255, 255, 255, 0.2)',
  },
  6: {
    background: 'linear-gradient(113.96deg, #329dff 0%, #1411a2 100%)',
    border: 'rgba(255, 255, 255, 0.2)',
  },
};

type Props = {
  color?: string;
  setColor: (value: string) => void;
};

const BgColorSelector: FC<Props> = ({ color, setColor }) => {
  return (
    <div className={styles.Wrapper}>
      <Label>Цвет фона:</Label>

      <div className={styles.Colors}>
        <WithColorPicker panelClassName={styles.PickerPanel} color={color} setColor={setColor}>
          <div className={styles.Color} data-picker="true" style={{ background: color }}>
            <Icon iconName="droplet" />
          </div>
        </WithColorPicker>

        {Object.values(presets).map((preset, index) => (
          <div
            className={styles.Color}
            key={index}
            style={{ background: preset.background, border: `1px solid ${preset.border}` }}
            onClick={() => setColor(preset.background)}
          />
        ))}
      </div>
    </div>
  );
};

export default BgColorSelector;
