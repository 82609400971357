import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './HeaderWithTabs.module.scss';
import { useGetAllVkAccounts } from '../../modules/vk/vk.selectors';
import { isEmpty } from 'lodash-es';
import DisabledHeaderTabs from '../DisabledHeaderTabs';

export type HeaderLink = {
  link: string;
  name?: string;
  disabled?: boolean;
};

type Props = {
  links: HeaderLink[];
  children?: ReactNode;
  className?: string;
  linkClassName?: string;
  isVkHeader?: boolean;
  isVkPage?: boolean;
};
const HeaderWithTabs: FC<Props> = ({ links, children, className, linkClassName, isVkHeader }) => {
  const vkAccounts = useGetAllVkAccounts();
  return (
    <header className={classNames(styles.Header, className)}>
      {isVkHeader ? (
        <>
          <div className={styles.Links}>
            {isEmpty(vkAccounts) ? (
              <DisabledHeaderTabs links={links} />
            ) : (
              <>
                {links.map(({ link, name }) => (
                  <NavLink
                    key={link}
                    to={link}
                    className={({ isActive }) =>
                      classNames(styles.Text, linkClassName, {
                        [styles.Active]: isActive,
                      })
                    }
                    end
                  >
                    {name}
                  </NavLink>
                ))}
              </>
            )}
          </div>
        </>
      ) : (
        <div className={styles.Links}>
          {links.map(({ link, name, disabled = false }) => (
            <NavLink
              key={link}
              to={link}
              className={({ isActive }) =>
                classNames(styles.Text, linkClassName, {
                  [styles.Active]: isActive,
                  [styles.Disabled]: disabled,
                })
              }
              end
            >
              {name}
            </NavLink>
          ))}
        </div>
      )}

      {children}
    </header>
  );
};

export default HeaderWithTabs;
