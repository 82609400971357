import { FC } from 'react';
import classNames from 'classnames';

import styles from './AlignmentSelector.module.scss';
import Tool from '../Tool';
import { EAlignment } from 'modules/my-pages/my-pages.types';
import Icon from 'components/Icon';

type Props = {
  alignment: EAlignment;
  setAlignment: (value: EAlignment) => void;
};

const AlignmentSelector: FC<Props> = ({ alignment, setAlignment }) => {
  return (
    <Tool
      label="Выравние:"
      right={
        <>
          <Icon
            className={classNames(styles.Icon, {
              [styles.Active]: alignment === EAlignment.LEFT,
            })}
            iconName="align-left-block"
            size={24}
            onClick={() => setAlignment(EAlignment.LEFT)}
          />
          <Icon
            className={classNames(styles.Icon, {
              [styles.Active]: alignment === EAlignment.CENTER,
            })}
            iconName="align-center-block"
            size={24}
            onClick={() => setAlignment(EAlignment.CENTER)}
          />
          <Icon
            className={classNames(styles.Icon, {
              [styles.Active]: alignment === EAlignment.RIGHT,
            })}
            iconName="align-right-block"
            size={24}
            onClick={() => setAlignment(EAlignment.RIGHT)}
          />
        </>
      }
    />
  );
};

export default AlignmentSelector;
