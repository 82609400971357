import { FC } from 'react';

import styles from './ColorSelector.module.scss';
import WithColorPicker from 'components/WithColorPicker';
import { ColorPickerProps } from 'components/ColorPicker';
import Tool from '../Tool';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

type Props = {
  label: string;
} & ColorPickerProps;

const ColorSelector: FC<Props> = ({ label, color, setColor }) => {
  const bgColor = '#000000';

  const handleRemove = () => {
    setColor(bgColor);
  };

  const { copyAndNotify } = useCopyToClipboard();

  return (
    <Tool
      label={label}
      right={
        <>
          <span className={styles.Color} onClick={() => copyAndNotify(color)} title={'скопировать'}>
            {color}
          </span>
          <WithColorPicker color={color} setColor={setColor}>
            <div className={styles.Preview} style={{ background: color }} />
          </WithColorPicker>
          {color !== bgColor && (
            <div className={styles.Preview} onClick={handleRemove}>
              <div className={styles.CrossLine} data-left />
              <div className={styles.CrossLine} data-right />
            </div>
          )}
        </>
      }
    />
  );
};

export default ColorSelector;
