import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { CommonModalProps } from 'components/Modal';
import ModalWithForm from 'components/ModalWithForm';
import { addTokenModalSchema } from 'modules/vk/vk.schema';
import { useVkUserAuthAuthorizeMutation } from 'modules/vk/vk.api';

import { isNil } from 'lodash-es';
import styles from './AddAccountModal.module.scss';
import Select from 'react-select';
import { selectStyles } from '../ParsingBtn/ParsingModal/utils';
import { getTimeZones } from 'utils/getTimeZones';

type Props = {
  vkPayload: string | null;
} & CommonModalProps;

const AddAccountModal: FC<Props> = ({ isOpen, onClose, vkPayload }) => {
  const [authtorise, { isLoading }] = useVkUserAuthAuthorizeMutation();
  const {
    formState: { isValid },
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<{
    timezone: string;
  }>({
    mode: 'onChange',
    resolver: yupResolver(addTokenModalSchema()),
  });
  watch();

  const payload = useMemo(() => {
    if (isNil(vkPayload)) return {};
    return JSON.parse(vkPayload);
  }, [vkPayload]);

  const makeTimezoneOptions = useMemo(() => {
    return getTimeZones('RU');
  }, []);

  const onSubmit = handleSubmit(({ timezone }) => {
    const state = '';
    authtorise({
      payload,
      timezone,
      state,
    })
      .unwrap()
      .then(() => {
        reset();
      });
    onClose();
  });

  if (isLoading) return null;

  return (
    <ModalWithForm
      title="Выберете часовой пояс"
      isOpen={isOpen}
      onClose={onClose}
      isValid={isValid}
      onSubmit={onSubmit}
    >
      <div className={styles.Main}>
        <div className={styles.SelectWrapper}>
          <p className={styles.SelectInfo}>Часовой пояс:</p>

          <Controller
            control={control}
            name="timezone"
            render={({ field }) => {
              return (
                <Select
                  className={styles.Select}
                  defaultValue={
                    field.value
                      ? { label: field.value, value: field.value }
                      : { label: '', value: '' }
                  }
                  placeholder={'Часовой пояс:'}
                  onChange={selectedOption => field.onChange(selectedOption?.value)}
                  styles={{
                    ...selectStyles,
                    menuList: base => ({
                      ...base,

                      '::-webkit-scrollbar': {
                        width: '4px',
                        height: '1px',
                      },
                      '::-webkit-scrollbar-track': {
                        background: 'rgba(255, 255, 255, 0.1)',
                        opacity: 0.5,
                      },
                      '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#fff',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        borderRadius: '4px',
                      },
                      '::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                      },
                    }),
                  }}
                  options={makeTimezoneOptions}
                />
              );
            }}
          />
        </div>
      </div>
    </ModalWithForm>
  );
};

export default AddAccountModal;
