import Icon from 'components/Icon';
import { FC } from 'react';

import styles from './FieldError.module.scss';

type Props = {
  errorMsg: string;
};

const FieldError: FC<Props> = ({ errorMsg }) => {
  return (
    <span className={styles.ErrorMsg}>
      <p className={styles.Text}> {errorMsg}</p>
      <Icon className={styles.ErrorIcon} iconName="warning" size={16} />
    </span>
  );
};

export default FieldError;
