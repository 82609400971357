import { FC } from 'react';
import classNames from 'classnames';

import styles from './VideoInstructions.module.scss';
import Icon from 'components/Icon';

type Props = {
  link: string;
  className?: string;
};

const VideoInstructions: FC<Props> = ({ link, className }) => {
  return (
    <a
      className={classNames(styles.VideoLink, className)}
      onClick={() => {
        window.open(link, '_blank')?.focus();
      }}
      target="_blank"
    >
      Видеоинструкция <Icon className={styles.VideoLinkIcon} iconName="video-circle" size={24} />
    </a>
  );
};

export default VideoInstructions;
