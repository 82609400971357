import { FC } from 'react';
import classNames from 'classnames';

import styles from './Tag.module.scss';
import Icon from 'components/Icon';

type Props = {
  text?: string;
  dimmed?: boolean;
  onClick?: () => void;
};
const Tag: FC<Props> = ({ text, dimmed, onClick }) => {
  const isClickable = !!onClick;

  return (
    <div
      className={classNames(styles.Wrapper, {
        [styles.Dimmed]: !!dimmed,
        [styles.Clickable]: isClickable,
      })}
      onClick={onClick}
    >
      <span>{text}</span>
      {isClickable && <Icon iconName="cross" size={14} />}
    </div>
  );
};

export default Tag;
