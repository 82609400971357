import { FC, useEffect, useState } from 'react';

import { useTelegramGreetingModal } from 'modules/telegram/telegram.selectors';

import { GreetinBotModalState } from 'modules/telegram/telegram.types';
import useAppDispatch from 'hooks/useAppDispatch';
import AddGreetingBotAccount from '../AddGreetingBotAccount';
import AddGreetingBotAccountCode from '../AddGreetingBotAccountCode';
import { selectPlan } from 'modules/telegram/telegram.reducer';
import { AddGreetingBotAccountChecking } from '../AddGreetingBotAccountChecking';
import isUndefined from 'lodash-es/isUndefined';
import { useGetWelcomeBotQuery } from '../../codegen/telegram.welcome.api';

const AddGreetingBotAccountModal: FC = () => {
  const { state } = useTelegramGreetingModal();
  const dispatch = useAppDispatch();
  const { data: welcomeBot, isFetching: isBotResLoading } = useGetWelcomeBotQuery({});
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (isUndefined(welcomeBot)) {
      dispatch(selectPlan(GreetinBotModalState.ACCOUNT));
    }
    if (welcomeBot?.data.isAuthorize === false) {
      dispatch(selectPlan(GreetinBotModalState.CHECKING));
    }
  }, [welcomeBot]);

  return (
    <>
      <AddGreetingBotAccount
        isBotLoading={isBotResLoading}
        isOpen={state === GreetinBotModalState.ACCOUNT}
        onClose={() => {}}
      />
      <AddGreetingBotAccountCode
        isBotLoading={isBotResLoading}
        isOpen={state === GreetinBotModalState.CONFIRMATION}
        onClose={() => {}}
        welcomeBot={welcomeBot?.data}
        hasError={hasError}
        handleError={arg => {
          setHasError(arg);
        }}
      />
      <AddGreetingBotAccountChecking
        isBotLoading={isBotResLoading}
        welcomeBot={welcomeBot?.data}
        isOpen={state === GreetinBotModalState.CHECKING}
        onClose={() => {}}
      />
    </>
  );
};

export default AddGreetingBotAccountModal;
