import { FC } from 'react';
import classNames from 'classnames';

import styles from './TextMenu.module.scss';
import Icon from 'components/Icon';
import Menu, { MenuItem } from 'components/Menu';

type Props = {
  label: string;
  items: MenuItem[];
  menuClassName?: string;
  dontShowSelected?: boolean;
  iconPosition?: 'left' | 'right';
};

const TextMenu: FC<Props> = ({ items, label, menuClassName, dontShowSelected, iconPosition }) => {
  const selected = items.find(o => o.isSelected);

  return (
    <Menu
      items={items}
      btnClassName={styles.Btn}
      contextClassName={classNames(styles.Context, menuClassName)}
      iconPosition={iconPosition}
    >
      {label}
      {!dontShowSelected && selected && (
        <span className={styles.Selected}>
          {' '}
          : <span className={styles.Label}>{selected.label}</span>
        </span>
      )}
      <Icon className={styles.Icon} iconName="arrow-down" size={24} />
    </Menu>
  );
};

export default TextMenu;
