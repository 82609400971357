import { FC, FormEvent, useState } from 'react';

import styles from './SelectThematicGroupsModal.module.scss';
import Modal from 'components/Modal';
import Input from 'components/Input';
import VideoInstructions from 'components/VideoInstructions';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSearch: (sq?: string) => void;
};

const SelectThematicGroupsModal: FC<Props> = ({ isOpen, onClose, onSearch }) => {
  const [sq, setSq] = useState('');

  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    onSearch(sq);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isValid={sq.length > 0} onSubmit={onSubmit}>
      <h3 className={styles.Title}>Выбор тематических сообществ</h3>
      <p className={styles.Text}>
        5 key components for aspiring designers that shape stylish design
      </p>
      <Input label="Ссылка на группу" value={sq} onChange={value => setSq(value)} />
      <VideoInstructions className={styles.Instructions} link="#" />
    </Modal>
  );
};

export default SelectThematicGroupsModal;
