import { FC, useState } from 'react';
import Modal, { CommonModalProps } from 'components/Modal';
import styles from './AutoPayingModal.module.scss';
import classNames from 'classnames';
import Button from 'components/Button';
import { useCreateSoftPayProductsMutation, useGetSoftPayProductsQuery } from '../../finances.api';
import isEmpty from 'lodash-es/isEmpty';
import env from 'config/env';

export const AutoPayingModal: FC<CommonModalProps> = ({ ...rest }) => {
  const { data: softPayProducts, isFetching: isProductLoading } = useGetSoftPayProductsQuery({});
  const [createSoftPay, { isLoading: isCreating }] = useCreateSoftPayProductsMutation();
  const [selectedKey, setSelectedKey] = useState('');

  return (
    <Modal
      onSubmit={e => {
        e?.preventDefault();
        createSoftPay({ link: selectedKey })
          .unwrap()
          .then(res => {
            window.open(res.url, '_blank')?.focus();
            rest.onClose();
          });
      }}
      isLoading={isProductLoading}
      className={styles.Root}
      formClassName={styles.WrapperForm}
      noBtn
      {...rest}
    >
      {/* TODO: https://stars-matrix.atlassian.net/browse/SO-1349 */}
      {/* <h1 className={styles.Title}>Автопополнение</h1> */}
      <h1 className={styles.Title}>Пополнение с карты</h1>
      <p className={styles.Par}>
        Выберите необходимую сумму для пополнения в соответствии с желаемым тарифом.
      </p>
      {/* <p className={styles.Par}>
        После выбора одной из указанных сумм, вы активируете автоматическое ежемесячное (каждые 29
        дней) пополнение вашего баланса на выбранную сумму.
      </p>
      <p className={styles.Par}>
        Если вы захотите отключить автопополнение, то сможете это сделать в разделе «настройки».
      </p> */}

      <div className={styles.CardsGrid}>
        {softPayProducts?.products.map(product => (
          <div
            key={product.link}
            className={classNames(styles.Card, {
              [styles.ActiveCard]: selectedKey === product.link,
            })}
            onClick={() => {
              if (product.link === selectedKey) {
                setSelectedKey('');
              } else {
                setSelectedKey(product.link);
              }
            }}
          >
            <div className={styles.NumBack}>
              <div className={styles.Wrapper}>
                <p className={styles.Top}>{product.price.inRussia ?? 0}</p>
                <span>{env.CURRENCY}.</span>
              </div>
              <p className={styles.Back}>{product.price.inRussia ?? 0}</p>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.Btns}>
        <Button
          type="button"
          onClick={(ev: { preventDefault: () => void }) => {
            ev.preventDefault();
            rest.onClose();
          }}
          variant="text"
        >
          Отмена
        </Button>
        <Button disabled={isEmpty(selectedKey) || isCreating} type="submit">
          Перейти к оплате
        </Button>
      </div>
    </Modal>
  );
};
