import rootApi from 'config/http';
import { TResponse } from 'interfaces/api';
import { Landing } from 'modules/landing-generator/landing-generator.types';

const cardsApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getLandingsByPublishedName: builder.query<TResponse<Landing[]>, { name: string }>({
      query: ({ name }) => ({
        url: `/sheets/publishedName/${name}/landings`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetLandingsByPublishedNameQuery } = cardsApi;

export default cardsApi;
