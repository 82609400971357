import { FC } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import styles from './Preview.module.scss';
import Block from './components/Block';
import { EditorMode, EDraggableItem, Page } from 'modules/my-pages/my-pages.types';
import { useShootingPrerender } from 'modules/my-pages/my-pages.selectors';
import { useDrop } from 'react-dnd';
import useAppDispatch from 'hooks/useAppDispatch';
import { appendComponent } from 'modules/my-pages/my-pages.helpers';

type Props = {
  page: Page | null;
  mode?: EditorMode;
  className?: string;
};

const Preview: FC<Props> = ({ mode = 'edit', page, className }) => {
  const dispatch = useAppDispatch();
  const [{ isOver }, drop] = useDrop({
    accept: Object.values(EDraggableItem),
    drop: (item, monitor) => {
      if (!monitor.didDrop() && monitor.isOver({ shallow: true })) {
        appendComponent(
          monitor.getItemType() as EDraggableItem,
          dispatch,
          page?.components.length ?? 0,
          page?.id ?? '',
        );
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });
  const shootingPrerender = useShootingPrerender();

  if (!page) return null;

  const blocks = page.components;

  return (
    <motion.article
      layoutScroll
      id={`page-preview-${page.id}`}
      ref={drop}
      className={classNames(styles.Preview, className)}
      style={{ background: page.bgColor, opacity: isOver ? 0.5 : 1 }}
    >
      {[...blocks]
        .sort((a, b) => a.order - b.order)
        .map(block => (
          <Block key={block.id} mode={shootingPrerender ? 'preview' : mode} block={block} />
        ))}
    </motion.article>
  );
};

export default Preview;
