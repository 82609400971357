import { FC } from 'react';

import styles from './Burger.module.scss';
import useAppDispatch from 'hooks/useAppDispatch';
import { setMobileNavOpen } from 'modules/app/app.reducer';

const Burger: FC = () => {
  const dispatch = useAppDispatch();

  const openMenu = () => {
    dispatch(setMobileNavOpen(true));
  };

  return <button onClick={openMenu} className={styles.Burger} />;
};

export default Burger;
