import { FC } from 'react';

import styles from './PartnershipProgram.module.scss';
import PartnersList from 'modules/partnership-program/components/PartnersList';
import SelectedPartnersNav from 'modules/partnership-program/components/SelectedPartnersNav';
import Pagination from 'components/Pagination';
import useAppDispatch from 'hooks/useAppDispatch';
import { setOffset } from 'modules/partnership-program/partnership-program.reducer';
import { usePartnershipData } from 'modules/partnership-program/partnership-program.hooks';
import PartnershipProgramMobileTabs from 'modules/partnership-program/components/PartnershipProgramMobileTabs';
import ProgramInfo from 'modules/partnership-program/components/ProgramInfo';
import PartnershipHeader from 'modules/partnership-program/components/PartnershipHeader';
import { useSelectPartnerFilters } from '../../partnership-program.selectors';

const PartnershipProgram: FC = () => {
  const [{ data }] = usePartnershipData();
  const dispatch = useAppDispatch();

  const pageChange = (page: number) => {
    if (page === 1) {
      dispatch(setOffset(0));
    } else {
      dispatch(setOffset((page - 1) * 8));
    }
  };

  const filters = useSelectPartnerFilters();
  const currPage = filters.offset === 0 ? 1 : filters.offset / 8 + 1;

  return (
    <div className={styles.Page}>
      <PartnershipHeader />
      <div className={styles.Wrapper}>
        <SelectedPartnersNav />
      </div>
      <div className={styles.Main}>
        <div className={styles.Content}>
          <PartnersList />
        </div>

        <ProgramInfo className={styles.Aside} />
      </div>
      <Pagination
        className={styles.Pagination}
        totalCount={data?.body.count}
        onPageChange={pageChange}
        siblingCount={1}
        offset={8}
        currPage={currPage}
      />
      <PartnershipProgramMobileTabs />
    </div>
  );
};

export default PartnershipProgram;
