import { FC } from 'react';

import styles from './Email.module.scss';
import { Component, Email as TEmail } from 'modules/my-pages/my-pages.types';

type Props = {
  component: Component<TEmail>;
};

const Email: FC<Props> = ({ component }) => {
  const { data } = component;

  return (
    <a href={`mailto:${data.email}`} className={styles.Section}>
      {data.email}
    </a>
  );
};

export default Email;
