import useTypedSelector from 'hooks/useAppSelector';
import { AnyComponent, Page, ActiveComponent, EOrientation } from './my-pages.types';

export const getComponentById = (page: Page, id: string): ActiveComponent<AnyComponent> => {
  const blocks = page.components;
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    if (block.hash === id) {
      return { parent: page, component: block };
    }

    if (block.components) {
      const foundComponent = block.components.find(c => {
        return c.hash === id;
      });
      if (foundComponent) {
        return { parent: block, component: foundComponent };
      }
    }
  }

  return null;
};

export const useActivePage = (): Page | null =>
  useTypedSelector(state => state.myPages.present.activePage);

export const useActiveComponent = (): ActiveComponent<AnyComponent> =>
  useTypedSelector(state => {
    const page = state.myPages.present.activePage;
    const hash = state.myPages.present.activeComponentId;
    if (!page || !hash) return null;
    const data = getComponentById(page, hash);
    return data;
  });

export const useSelectCanUndoRedoPage = (): {
  canUndo: boolean;
  canRedo: boolean;
} =>
  useTypedSelector(state => ({
    canUndo: state.myPages.past.length > 0,
    canRedo: state.myPages.future.length > 0,
  }));

export const usePreviewOrientation = (): EOrientation =>
  useTypedSelector(state => state.myPages.present.previewOrientation);

export const useShootingPrerender = (): boolean =>
  useTypedSelector(state => state.myPages.present.shootingPrerender);

export const useIsValidPage = (): boolean =>
  useTypedSelector(state => state.myPages.present.isValidPage);

export const useOpenModal = (): boolean =>
  useTypedSelector(state => state.myPages.present.openModal);
export const useOpenGoBackModal = (): boolean =>
  useTypedSelector(state => state.myPages.present.openGoBackModal);

export const useOpenSettingsModal = (): boolean =>
  useTypedSelector(state => state.myPages.present.openSettingsModal);
