import { Route } from 'react-router-dom';

import r from 'constants/routes';
// layouts
import Layout from './components/Layout';
import ActionLayout from './components/ActionLayout';
// pages
import First3PhotoComments from './pages/First3PhotoComments';
import AutoPosting from './pages/AutoPosting';
import AutoStories from './pages/AutoStories';
import Mailing from './pages/Mailing';
import DbParsing from './pages/DbParsing';
import ThematicGroups from './pages/ThematicGroups';
import FriendsActions from './pages/FriendsActions';
import GroupsActions from './pages/GroupsActions';
import AutomationActions from './pages/AutomationActions';
import FirstPostsComments from './pages/FirstPostsComments';
import GroupsComments from './pages/GroupsComments';
import MailingEdit from './pages/MailingEdit';
import DbParsingEdit from './pages/DbParsingEdit';
import ThematicGroupsStacks from './pages/ThematicGroupsStacks';
import FolderAction from './pages/FolderAction/FolderAction';
import GroupSettingAutomation from './pages/GroupSettingAutomation';
import AutoTranslation from './pages/AutoTranslation';
import { NavTypes } from './vk.constants';

const VkRoutes = (): JSX.Element => {
  return (
    <>
      <Route path={r.vk.index} element={<Layout />}>
        <Route path={r.vk.index} element={<FriendsActions />} />
        <Route path={r.vk.groupsActions} element={<GroupsActions />} />
        <Route path={r.vk.groupsFolder} element={<FolderAction />} />
        <Route path={r.vk.automationActions} element={<AutomationActions />} />
      </Route>
      <Route path={r.vk.index} element={<Layout type={NavTypes.AUTOMATION_NAV} />}>
        <Route path={r.vk.groupAutomation} element={<GroupSettingAutomation />} />
      </Route>
      <Route path={r.vk.index} element={<ActionLayout />}>
        <Route path={r.vk.first3PhotoComments} element={<First3PhotoComments />} />
        <Route path={r.vk.firstPostsComments} element={<FirstPostsComments />} />
        <Route path={r.vk.groupComments} element={<GroupsComments />} />
        <Route path={r.vk.autoPosting} element={<AutoPosting />} />
        <Route path={r.vk.autoStories} element={<AutoStories />} />
        <Route path={r.vk.autoTranslation} element={<AutoTranslation />} />
        <Route path={r.vk.mailing.index} element={<Mailing />} />
        <Route path={r.vk.mailing.edit} element={<MailingEdit />} />
        <Route path={r.vk.dbParsing.index} element={<DbParsing />} />
        <Route path={r.vk.dbParsing.edit} element={<DbParsingEdit />} />
        <Route path={r.vk.thematicGroups.index} element={<ThematicGroups />} />
        <Route path={r.vk.thematicGroups.stacks} element={<ThematicGroupsStacks />} />
      </Route>
    </>
  );
};

export default VkRoutes;
