import { FC, useState } from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';

import r from 'constants/routes';
import { useSelectUser } from 'modules/account/account.selector';
import useAppDispatch from 'hooks/useAppDispatch';
import { signOut } from 'modules/auth/auth.reducer';
import NavigationEntry from 'components/NavigationEntry';
import { NAVIGATION_OPTIONS } from 'constants/navigation';
import { persistor } from 'config/store';
import { useSignOutRequestMutation } from 'modules/auth/auth.api';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import logoStar from 'assets/svg/navigation/logo.svg';
import logoText from 'assets/svg/navigation/logo-text.svg';
import styles from './Navigation.module.scss';
import { delay } from 'lodash-es';
import { clearVkAccountsFromState } from '../../modules/vk/vk.reducer';
import { useVkInvalidateTag } from '../../modules/vk/vk.hooks';
import { injectedRtkApi } from '../../modules/telegram/codegen/telegram.admin.api';
import accountApi from '../../modules/account/account.api';
import { Advertising } from '../Advertising';
import { addsMap } from '../../constants/adds';

const Navigation: FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelectUser();
  const [isActiveNav, setIsActiveNav] = useState<boolean>(false);
  const [signOutRequest] = useSignOutRequestMutation();
  const invalidateVk = useVkInvalidateTag();

  const onLogOut = () => {
    signOutRequest().then(() => {
      invalidateVk();
      dispatch(injectedRtkApi.util.resetApiState());
      dispatch(accountApi.util.resetApiState());
      dispatch(signOut());
      dispatch(clearVkAccountsFromState());
      delay(() => {
        persistor.purge();
      }, 500);
    });
  };

  return (
    <nav className={styles.Nav} data-active={isActiveNav}>
      <button
        type="button"
        className={styles.NavOpener}
        onClick={() => setIsActiveNav(!isActiveNav)}
      >
        <Icon iconName="icon-toggle-navigation" size={23} />
      </button>

      <div className={styles.Greeting}>
        <Menu as="div" className={styles.AvaWrapper}>
          {({ open }) => (
            <>
              <Menu.Button className={styles.AvatarButton}>
                <Avatar
                  className={styles.Avatar}
                  src={user?.avatar}
                  name={user?.fullName ?? '-'}
                  size={48}
                />
                <Icon
                  iconName={!open ? 'navigation-context' : 'navigation-context-up'}
                  size={7}
                  className={styles.ContextBtn}
                  data-is-active={open}
                />
              </Menu.Button>
              <Menu.Items className={styles.Context}>
                <ul>
                  <li>
                    <Menu.Item as={Link} to={r.settings}>
                      Настройки
                    </Menu.Item>
                  </li>
                  <Menu.Item as="li" onClick={onLogOut}>
                    Выйти
                  </Menu.Item>
                </ul>
              </Menu.Items>
            </>
          )}
        </Menu>

        <div className={styles.GreetingText}>
          <p className={styles.Name}>{user?.fullName}</p>
          <p className={styles.Email}>{user?.email}</p>
        </div>
      </div>

      <div className={styles.Navigation}>
        <NavigationEntry {...NAVIGATION_OPTIONS.ACCOUNT} />
        <NavigationEntry {...NAVIGATION_OPTIONS.VK} />
        <NavigationEntry {...NAVIGATION_OPTIONS.TG} />
        <NavigationEntry {...NAVIGATION_OPTIONS.MY_PAGES} />

        <div className={styles.BottomIcns}>
          <NavigationEntry {...NAVIGATION_OPTIONS.FINANCES} />
        </div>

        {isActiveNav && (
          <div className={styles.Adds}>
            <Advertising imageUrl={addsMap.sidebar.imgUrl} link={addsMap.sidebar.link} />
          </div>
        )}

        <div className={styles.Footer}>
          <div className={styles.Logo}>
            <img src={logoStar} alt="star" className={styles.Star} />
            <img src={logoText} alt="text" className={styles.Text} />
          </div>

          <Icon iconName="navigation-help-circle" size={24} className={styles.HelpCircle} />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
