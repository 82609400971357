import { FC, ReactNode } from 'react';

import Accordion from 'components/Accordion';
import AccordionTriggerStandard from 'components/AccordionTriggerStandard';

type Props = {
  text: string;
  children: ReactNode;
  contentClassName?: string;
  firstOpen?: boolean;
  noDivider?: boolean;
};

const AccordionStandard: FC<Props> = ({
  text,
  contentClassName,
  children,
  firstOpen,
  noDivider,
}) => {
  return (
    <Accordion
      trigger={isOpen => (
        <AccordionTriggerStandard isOpen={isOpen} noDivider={noDivider}>
          {text}
        </AccordionTriggerStandard>
      )}
      contentClassName={contentClassName}
      firstOpen={firstOpen}
    >
      {children}
    </Accordion>
  );
};

export default AccordionStandard;
