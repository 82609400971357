import { FC } from 'react';

import styles from './GroupCardSelectable.module.scss';
import formatNumber from 'utils/formatNumber';
import Button from 'components/Button';
import Icon from 'components/Icon';
import classNames from 'classnames';

export type GroupCardSelectableProps = {
  id: number;
  avatar: string;
  subscribersCount: number;
  type: string;
  name: string;
  subscribed: boolean;
  selected: boolean;
  onSelect: () => void;
  className?: string;
  wrapperClassName?: string;
};

const GroupCardSelectable: FC<GroupCardSelectableProps> = ({
  avatar,
  subscribersCount,
  type,
  name,
  subscribed,
  selected,
  onSelect,
  className,
  wrapperClassName,
}) => {
  return (
    <article className={classNames(styles.Wrapper, wrapperClassName)} data-selected={selected}>
      <div className={classNames(styles.Card, className)}>
        <div className={styles.Checkbox} onClick={onSelect}>
          {selected && <Icon className={styles.Icon} iconName="checkedline-check" size={16} />}
        </div>
        <div className={styles.Header}>
          <img
            className={styles.Avatar}
            src={avatar}
            alt={`${name}'s avatar`}
            width={64}
            height={64}
          />
          <div className={styles.SubsWrapper}>
            <div className={styles.Count}>{formatNumber(subscribersCount, 0)}</div>
            <div className={styles.Label}>подписчиков</div>
          </div>
        </div>
        <div className={styles.Content}>
          <div className={styles.Type}>{type}</div>
          <div className={styles.Name}>{name}</div>
        </div>
        {subscribed ? (
          <Button className={styles.Btn} variant="primary">
            Отписаться
          </Button>
        ) : (
          <Button className={styles.Btn} variant="primary">
            Подписаться
          </Button>
        )}
      </div>
    </article>
  );
};

export default GroupCardSelectable;
