import { EPeriodType } from './landing-dashboard.types';

import sampleBgImg1 from './components/NewPageModal/images/sample-bg-1.png';
import sampleBgImg2 from './components/NewPageModal/images/sample-bg-2.png';
import sampleBgImg3 from './components/NewPageModal/images/sample-bg-3.png';
import sampleBgImg4 from './components/NewPageModal/images/sample-bg-4.png';
import sampleBgImg5 from './components/NewPageModal/images/sample-bg-5.png';
export const menuItems = ['Все', 'Прочитанные', 'Непрочитанные'];

export const standardDate = 'YYYY-MM-DD';

export const standardDateTime = 'YYYY-MM-DDTHH:mm:ss';

export const periodOptions: Record<EPeriodType, string> = {
  [EPeriodType.MONTH]: 'Месяц',
  [EPeriodType.WEEK]: 'Неделя',
  [EPeriodType.DAY]: 'День',
  [EPeriodType.YEAR]: 'Год',
};

export { sampleBgImg1, sampleBgImg2, sampleBgImg3, sampleBgImg4, sampleBgImg5 };
