import styles from './Tabs.module.scss';

type Props<T> = {
  tabs: T[];
  activeTab: string;
  makeActive: (tabName: T) => void;
  getTabDisplayName: (tabName: T) => string;
};

function Tabs<T extends string>({ tabs, activeTab, makeActive, getTabDisplayName }: Props<T>) {
  return (
    <nav className={styles.Nav}>
      {tabs.map(tab => (
        <button
          key={tab}
          className={styles.Tab}
          data-active={activeTab === tab}
          onClick={() => makeActive(tab)}
        >
          {getTabDisplayName(tab)}
        </button>
      ))}
    </nav>
  );
}

export default Tabs;
