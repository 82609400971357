import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { stringify as queryStringify } from 'qs';

import env from 'config/env';
import { EAppTagType, EAppVKTagType } from 'interfaces/api';
import { signOut, updateRefreshToken } from 'modules/auth/auth.reducer';
import { IAuthData } from 'modules/auth/auth.interfaces';
import { RootState } from './store';
import { Mutex } from 'async-mutex';
import { statusesSet } from '../constants/error-statuses';

export const paramsSerializer = (params: Record<string, unknown>): string =>
  queryStringify(params, { arrayFormat: 'indices' });

export const makeBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth.accessToken;
      if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
    paramsSerializer,
  });

const mutex = new Mutex();

export const makeBaseQueryWithRefresh: (
  baseQuery: ReturnType<typeof makeBaseQuery>,
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
  baseQuery => async (args, api, extraOptions) => {
    const {
      auth: { isAuthenticated, refreshToken },
    } = api.getState() as RootState;
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (isAuthenticated && statusesSet.has(Number(result.error?.status))) {
      const credentials = { refreshToken: refreshToken || '' };
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          // try to get a new token
          const baseRefresh = makeBaseQuery(env.APP_API + '/api/v1');
          const refreshResult = await baseRefresh(
            { url: '/refreshToken', method: 'POST', body: credentials },
            api,
            extraOptions,
          );

          if (refreshResult.data) {
            // store the new token
            api.dispatch(updateRefreshToken(refreshResult.data as IAuthData));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(signOut());
          }
        } finally {
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
    return result;
  };

const mainBaseQuery = makeBaseQuery(env.APP_API + '/api/v1');

export const api = createApi({
  reducerPath: 'api',
  baseQuery: makeBaseQueryWithRefresh(mainBaseQuery),
  tagTypes: [...Object.values(EAppTagType), ...Object.values(EAppVKTagType)],
  endpoints: () => ({}),
});

export default api;
