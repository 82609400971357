import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'components/Modal';
import styles from './FinPassModal.module.scss';
import { FinPassModalTypes, Sources } from 'modules/finances/finances.types';
import { finPassModalSchema } from 'modules/finances/finances.schema';
import { useFinancesModal, useOpenModals } from 'modules/finances/finances.utils';
import useAppDispatch from 'hooks/useAppDispatch';
import { openCurrBuyModal, setCurrFinPassword } from '../../finances.reducer';
import PasswordInput from 'components/PasswordInput';
import Button from 'components/Button';

type Props = {
  onMemoriseFinPassword?: (str: string) => void;
  onNextModal?: () => void;
};
const FinPassModal: FC<Props> = () => {
  const { openBuy } = useOpenModals();
  const dispatch = useAppDispatch();
  const [{ isFinPass, isFinPassBuy, isFinPassWithdraw }, onClose] = useFinancesModal();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FinPassModalTypes>({
    mode: 'onChange',
    resolver: yupResolver(finPassModalSchema()),
  });

  const onSubmit = handleSubmit(async ({ finPass }) => {
    dispatch(setCurrFinPassword(finPass));
    if (isFinPassWithdraw) {
      openBuy();
    } else if (isFinPassBuy) {
      onClose();
    } else {
      dispatch(openCurrBuyModal(Sources.PAYEER));
      onClose();
    }
    reset();
  });

  const text = isFinPassBuy ? `пополнить баланс` : isFinPassWithdraw ? 'вывести деньги' : '';

  return (
    <Modal
      noBtn
      onSubmit={onSubmit}
      isOpen={isFinPass}
      onClose={() => {}}
      className={styles.Modal}
      isValid={isValid}
    >
      <h1 className={styles.Title}>
        Финансовый <br /> пароль
      </h1>
      <p className={styles.Question}>Чтобы {text} необходим финансовый пароль</p>
      <PasswordInput
        type="password"
        label="Пароль"
        register={register('finPass')}
        invalid={!!errors.finPass}
        errorMsg={errors.finPass?.message}
      />
      <div className={styles.Btns}>
        <Button
          className={styles.BtnCLose}
          variant="text"
          onClick={() => {
            onClose();
            reset();
          }}
        >
          Закрыть
        </Button>
        <Button className={styles.Btn} variant="primary" type="submit" disabled={!isValid}>
          Подтвердить
        </Button>
      </div>
    </Modal>
  );
};

export default FinPassModal;
