import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Profile from './pages/Profile';

function AccountRoutes(): JSX.Element {
  return (
    <>
      <Route path={r.account} element={<Profile />} />
    </>
  );
}

export default AccountRoutes;
