import { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { CommonModalProps } from 'components/Modal';
import TextArea from 'components/TextArea';
import { GreetingBotTextRequestBody } from 'modules/telegram/telegram.types';
import { greetingBotTextSchema } from 'modules/telegram/telegram.schema';
import ModalWithForm from 'components/ModalWithForm';
import AddFiles from '../AddFiles';
import FilesWrapper from '../FilesWrapper';
import { isEmpty } from 'lodash-es';
import {
  useGetAdminMessagesByIdQuery,
  usePostAdminMessageMutation,
  useUpdateAdminMessageMutation,
} from '../../codegen/telegram.admin.api';
import { useBotId } from '../../telegram.hooks';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import SmallLoader from 'components/SmallLoader';
import isUndefined from 'lodash-es/isUndefined';

type Props = {
  messageId?: string;
} & CommonModalProps;

type TFiles = { url?: string; mime?: string; name?: string };

const GreetingMessageModal: FC<Props> = props => {
  const { messageId, onClose } = props;
  const [createMessage, { isLoading }] = usePostAdminMessageMutation();
  const [updateMessage] = useUpdateAdminMessageMutation();
  const botId = useBotId();
  const { data: messageData, isFetching } = useGetAdminMessagesByIdQuery(
    { botId, messageId: String(messageId) },
    { skip: isUndefined(messageId) },
  );
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isValid, errors },
  } = useForm<GreetingBotTextRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(greetingBotTextSchema()),
  });

  const { prepend } = useFieldArray({
    control,
    name: 'files',
  });
  const values = watch();

  const createFile = (url?: string, type?: string, fileName?: string) => {
    if (url) {
      const metadata = { type: type };
      const data = new Blob([url], metadata);
      return new File([data], `${fileName}`, metadata);
    }
  };

  const onSubmit: SubmitHandler<GreetingBotTextRequestBody> = form => {
    const formData = new FormData();
    formData.append('text', form.text);
    for (let i = 0; i < form?.files?.length; i++) {
      formData.append(`files`, form.files[i]);
    }

    if (messageId) {
      updateMessage({ botId, messageId, form: formData }).then(res => {
        if ('data' in res) {
          showFeedback({
            title: 'ГОТОВО!',
            text: '',
            type: EFeedbackType.SUCCESS,
          });
          onClose();
          reset();
        }
      });
    } else {
      createMessage({ botId, form: formData }).then(res => {
        if ('data' in res) {
          showFeedback({
            title: 'ГОТОВО!',
            text: '',
            type: EFeedbackType.SUCCESS,
          });
          onClose();
          reset();
        }
      });
    }
  };

  useEffect(() => {
    if (messageData) {
      const fileList: TFiles[] = [...messageData.files] as TFiles[];
      reset({
        text: messageData.text,
        files: fileList.map(file => {
          return createFile(file.url, file.mime, file.name);
        }),
      });
    }
  }, [messageData]);

  return (
    <ModalWithForm
      title="Приветсвенный текст"
      text="Редактируйте приветсвенное сообщение"
      onSubmit={handleSubmit(onSubmit)}
      isValid={isValid}
      noBtn={isLoading}
      btnText={'Сохранить'}
      isLoading={isFetching}
      {...props}
    >
      <TextArea
        label="Сообщение"
        register={register('text')}
        invalid={!!errors.text?.message}
        errorMsg={errors.text?.message}
      />
      <br />
      <Controller
        control={control}
        render={() => {
          return (
            <AddFiles
              setFile={() => {}}
              setFilesList={event => {
                if (event.target.files)
                  for (let i = 0; i < event.target.files?.length; i++) {
                    prepend(event.target.files[i]);
                  }
              }}
            />
          );
        }}
        name={'files'}
      />
      <br />
      {!isEmpty(values.files) && (
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <FilesWrapper
                files={values.files}
                onFileClose={(file: File) => {
                  const filtered = values.files.filter((item: File) => item.name !== file.name);
                  field.onChange([...filtered]);
                }}
              />
            );
          }}
          name={'files'}
        />
      )}
      {isLoading && <SmallLoader />}
    </ModalWithForm>
  );
};

export default GreetingMessageModal;
