import { useCallback } from 'react';

function useScrollLock(): { lock: () => void; unlock: () => void } {
  return {
    lock: useCallback(() => (document.body.style.overflow = 'hidden'), []),
    unlock: useCallback(() => (document.body.style.overflow = 'initial'), []),
  };
}

export default useScrollLock;
