import { useParams } from 'react-router-dom';

import { useGetPartnersQuery } from './partnership-program.api';
import { useSelectPartnerFilters } from './partnership-program.selectors';
import { PartnerLines } from './partnership-program.types';

export const useLinesParams = () => {
  const { line } = useParams();

  switch (line) {
    case 'first':
      return PartnerLines.FIRST;
    case 'second':
      return PartnerLines.SECOND;
    case 'third':
      return PartnerLines.THIRD;
    default:
      return PartnerLines.FIRST;
  }
};

export const usePartnershipData = () => {
  const line = useLinesParams();
  const filters = useSelectPartnerFilters();
  const data = useGetPartnersQuery({
    line,
    referralName: filters.search,
    plan: filters.plan || undefined,
    timeFilter: filters.endDate || undefined,
    limit: 8,
    offset: filters.offset,
  });

  return [data, filters] as const;
};
