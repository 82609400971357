import { FC } from 'react';
import styles from './AddNewGroup.module.scss';
import Button from 'components/Button';
import FolderSearchScreen from './FolderSearchScreen';
import useDisclosure from 'hooks/useDisclosure';
import { useGetCurrentFolderData } from '../../../vk.selectors';

const AddNewGroup: FC = () => {
  const { open, onClose, onOpen } = useDisclosure({ blockScroll: true });
  const { sumGroupMembers } = useGetCurrentFolderData();

  return (
    <>
      <div className={styles.RootGroupCard}>
        <h1 className={styles.Title}>Добавить группу в текущую папку</h1>
        <Button disabled={sumGroupMembers >= 500000} onClick={onOpen} leftIcon={'add'}>
          Добавить
        </Button>
      </div>
      {open && <FolderSearchScreen isOpen={open} onClose={onClose} />}
    </>
  );
};

export default AddNewGroup;
