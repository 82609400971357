import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'components/Input';
import FormFieldsWrapper from '../FormFieldsWrapper';
import Section from '../Section';
import SectionTitle from '../SectionTitle';
import SettingsForm from '../SettingsForm';
import ControlsPanel from '../ControlsPanel';
import SettingsButton from '../SettingsButton';
import { accountSchema } from 'modules/settings/settings.schema';
import { AccountSchema } from 'modules/settings/settings.types';
import { useChangeUserMutation } from 'modules/settings/settings.api';
import useTypedSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/account/account.selector';
import AvatarEdit from '../AvatarEdit';
import toast from 'toasts';

const AccountSettings: FC = () => {
  const user = useTypedSelector(selectUser);
  const [changeUser] = useChangeUserMutation();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<AccountSchema>({
    mode: 'onChange',
    resolver: yupResolver(accountSchema()),
  });

  const onSubmit: SubmitHandler<AccountSchema> = async ({ fullName, instagram, phone, tg, vk }) => {
    await changeUser({
      fullName,
      social: {
        phone: phone || undefined,
        instagram: instagram || undefined,
        vk: vk || undefined,
        telegram: tg || undefined,
      },
    });
    toast.success('Изменения успешно сохранены!');
  };

  return (
    <Section>
      <SectionTitle>Настройки аккаунта</SectionTitle>

      <AvatarEdit />

      <SettingsForm onSubmit={handleSubmit(onSubmit)}>
        <FormFieldsWrapper>
          <Input
            label="Полное имя"
            register={register('fullName')}
            invalid={!!errors.fullName}
            errorMsg={errors.fullName?.message}
            defaultValue={user?.fullName}
          />
          <Input label="Email" type="email" defaultValue={user?.email} disabled />
        </FormFieldsWrapper>

        <SectionTitle style={{ fontSize: '16px', marginLeft: '16px', marginTop: '16px' }}>
          Дополнительная информация
        </SectionTitle>

        <FormFieldsWrapper>
          <Input
            label="Номер телефона"
            type="tel"
            register={register('phone')}
            invalid={!!errors.phone}
            errorMsg={errors.phone?.message}
            icons={[{ name: 'edit' }]}
            defaultValue={user?.phone}
          />
          <Input
            label="Ссылка на ВКонтакте"
            type="url"
            register={register('vk')}
            invalid={!!errors.vk}
            errorMsg={errors.vk?.message}
            icons={[{ name: 'edit' }]}
            defaultValue={user?.social?.vk}
          />
          <Input
            label="Telegram username"
            register={register('tg')}
            invalid={!!errors.tg}
            errorMsg={errors.tg?.message}
            icons={[{ name: 'edit' }]}
            defaultValue={user?.social?.telegram}
          />
          <Input
            label="Ссылка на Instagram"
            type="url"
            register={register('instagram')}
            invalid={!!errors.instagram}
            errorMsg={errors.instagram?.message}
            icons={[{ name: 'edit' }]}
            defaultValue={user?.social?.instagram}
          />
        </FormFieldsWrapper>

        <ControlsPanel>
          <SettingsButton size={'l'} type="submit" disabled={!isValid}>
            Сохранить
          </SettingsButton>
        </ControlsPanel>
      </SettingsForm>
    </Section>
  );
};

export default AccountSettings;
