import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import r from 'constants/routes';

import { NewPasswordRequestBody } from 'modules/settings/settings.types';
import { newPasswordSchema } from 'modules/settings/settings.schema';
import { useChangePasswordMutation } from 'modules/settings/settings.api';
import { useSignOutRequestMutation } from 'modules/auth/auth.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { signOut } from 'modules/auth/auth.reducer';

import { CommonModalProps } from 'components/Modal';
import PasswordInput from 'components/PasswordInput';
import SettingsFormModal from '../SettingsFormModal';

import styles from './NewPasswordModal.module.scss';
import { clearVkAccountsFromState } from '../../../vk/vk.reducer';
import { useVkInvalidateTag } from '../../../vk/vk.hooks';
import toast from '../../../../toasts';

const NewPasswordModal: FC<CommonModalProps> = props => {
  const [changePassword, { isSuccess }] = useChangePasswordMutation();
  const [signOutRequest] = useSignOutRequestMutation();
  const invalidateVk = useVkInvalidateTag();

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<NewPasswordRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(newPasswordSchema()),
  });

  const onSubmit: SubmitHandler<NewPasswordRequestBody> = data =>
    changePassword({ oldPassword: data.old, password: data.new }).then(res => {
      if ('data' in res) {
        toast.success('Успешно');
        reset();
        props.onClose();
      }
    });

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearVkAccountsFromState());
      invalidateVk();
      signOutRequest().then(() => {
        dispatch(signOut());
      });
    }
  }, [dispatch, isSuccess, signOutRequest]);

  return (
    <SettingsFormModal
      title="Новый пароль"
      text="Придумайте пароль, который ранее не был использован"
      btnText="Подтвердить"
      isValid={isValid}
      reset={reset}
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <PasswordInput
        label="Старый пароль"
        register={register('old')}
        invalid={!!errors.old}
        errorMsg={errors.old?.message}
      />

      <Link to={r.auth.resetPassword} type="button" className={styles.TextLink}>
        Забыли пароль?
      </Link>

      <div className={styles.FormFields}>
        <PasswordInput
          label="Новый пароль"
          register={register('new')}
          invalid={!!errors.new}
          errorMsg={errors.new?.message}
        />
        <PasswordInput
          label="Подтвердить пароль"
          register={register('new2')}
          invalid={!!errors.new2}
          errorMsg="Пароли не совпадают"
        />
      </div>
    </SettingsFormModal>
  );
};

export default NewPasswordModal;
