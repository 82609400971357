import { FC, ReactNode, useEffect, useState } from 'react';
import { isEmpty, isNil } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import formatLongText from 'utils/formatLongTrxt';
import { editAdminBotSchema } from 'modules/telegram/telegram.schema';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

import ToggleSwitch from 'components/ToggleSwitch';
import Button from 'components/Button';
import Input from 'components/Input';
import Avatar from 'components/Avatar';
import DeleteModal from 'modules/telegram/components/DeleteModal';

import styles from './BotEditModal.module.scss';
import ModalOverlay from 'components/ModalOverlay';
import { Dialog } from '@headlessui/react';
import r from '../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import TimezoneDiv from '../TimezoneDiv';

type Props = {
  isOpenModal: boolean;
  uuid: string;
  name: string | null;
  toggled: boolean;
  onUpdate: (args: { id: string; updatedName: { name: string } }) => void;
  onToggle: () => void;
  onDelete: () => void;
  onSync: () => void;
  onOpen: () => void;
  timezone?: string;
  bottom?: ReactNode;
  botInfo?: {
    displayName: string;
    name: string;
    photoUrl: string | null;
  };
  lastTokenSymbol?: string;
  isDisableToggle?: boolean;
};
const BotEditModal: FC<Props> = ({
  uuid,
  name,
  toggled = false,
  onUpdate,
  onToggle,
  onDelete,
  onSync,
  botInfo,
  lastTokenSymbol,
  onOpen,
  isOpenModal,
  timezone,
  isDisableToggle,
}) => {
  const [innerToggled, setInnerToggled] = useState(toggled);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [edited, setEdited] = useState(false);
  const { copyAndNotify } = useCopyToClipboard();
  const navigate = useNavigate();
  const botName = botInfo ? formatLongText(botInfo.name, 18) : '';

  const {
    register,
    watch,
    setValue,
    resetField,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(editAdminBotSchema()),
    defaultValues: {
      name,
      token: lastTokenSymbol,
    },
  });

  const values = watch();

  const onToggleBot = () => {
    setInnerToggled(!innerToggled);
    onToggle?.();
  };

  useEffect(() => {
    if (dirtyFields.name !== undefined) {
      setEdited(dirtyFields.name);
    } else if (isEmpty(dirtyFields.name)) {
      setEdited(false);
    }
  }, [dirtyFields.name]);

  return (
    <>
      <Dialog open={isOpenModal} onClose={onOpen}>
        <ModalOverlay>
          <Dialog.Panel>
            <div className={styles.Wrapper}>
              <div className={styles.Flex}>
                <div className={styles.AvatarWrapper}>
                  {!isNil(botInfo) && (
                    <>
                      {botInfo.photoUrl ? (
                        <img src={botInfo.photoUrl} className={styles.Image} alt={''} />
                      ) : (
                        <>
                          <Avatar name={botInfo.name} src={botInfo.photoUrl} size={40} />
                        </>
                      )}
                    </>
                  )}
                  <h3 className={styles.Text}>{botName}</h3>
                </div>
                <ToggleSwitch
                  active={innerToggled}
                  toggle={onToggleBot}
                  label={() => (innerToggled ? 'Вкл.' : 'Выкл')}
                  labelLeft
                  disabled={isDisableToggle}
                />
              </div>
              <TimezoneDiv timezone={timezone} />
              <div className={styles.InputsWrapper}>
                <Input
                  label="Название"
                  register={register('name')}
                  invalid={!!errors.name?.message}
                  errorMsg={errors.name?.message}
                  icons={
                    edited
                      ? [
                          {
                            name: 'check-square',
                            onClick: () => {
                              if (onUpdate && !errors.name?.message) {
                                onUpdate({
                                  id: uuid ?? '',
                                  updatedName: { name: values.name ?? '' },
                                });
                                resetField('name');
                                setValue('name', values.name);
                                setEdited(false);
                              }
                            },
                          },
                        ]
                      : [{ name: 'edit', onClick: () => setEdited(true) }]
                  }
                />
                <Input
                  label="Токен"
                  icons={[{ name: 'copy', onClick: () => copyAndNotify(values.token) }]}
                  disabled
                  register={register('token')}
                />
              </div>
              <div className={styles.ButtonsWrapper}>
                <Button
                  disabled={isDisableToggle}
                  className={styles.SyncButton}
                  variant="primary"
                  onClick={() => onSync?.()}
                >
                  Синхронизировать
                </Button>
                <Button
                  className={styles.DeleteButton}
                  variant="tetriary"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  Удалить бота
                </Button>
              </div>
            </div>
          </Dialog.Panel>
        </ModalOverlay>
      </Dialog>
      <DeleteModal
        applyBtnText="Удалить"
        title="Удаление"
        question={`Вы уверены что хотите удалить бот “${botName}”?`}
        info="Все настройки сообщений и рассылок будут удалены!"
        onClose={() => setOpenDeleteModal(false)}
        isOpen={openDeleteModal}
        deleteAction={() => {
          onDelete?.();
          navigate(r.telegram.index);
        }}
      />
    </>
  );
};

export default BotEditModal;
