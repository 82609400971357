import { FC, ReactNode } from 'react';

import styles from './AddBtn.module.scss';

type Props = {
  onAdd: () => void;
  children: ReactNode;
};

const AddBtn: FC<Props> = ({ onAdd, children }) => {
  return (
    <div className={styles.Wrapper}>
      <button className={styles.Btn} onClick={onAdd}>
        + Добавить <span className={styles.ToAdd}>{children}</span>
      </button>
    </div>
  );
};

export default AddBtn;
