import { FC, useEffect, useMemo, useState } from 'react';
import { WelcomeBotStatisticsTableHead } from './WelcomeBotStatisticsTableHead';
import { WelcomeBotStatisticsTableBody } from './WelcomeBotStatisticsTableBody';
import Pagination from 'components/Pagination';
import useWindowSize from 'hooks/useWindowSize';
import styles from './WelcomeBotStatisticsTable.module.scss';
import { WelcomeBotStatisticsMobileTable } from '../WelcomeBotStatisticsMobileTable';
import { useWelcomeGetBotContactQuery } from '../../codegen/telegram.welcome.api';
import { useParams, useSearchParams } from 'react-router-dom';
import { SimpleCipher, urlOffset } from '../../telegram.utils';
import LoadingState from 'components/LoadingState';

export const WelcomeBotStatisticsTable: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const isParamsMatch = offset === +urlOffset(searchParams.get('offset'));

  const { width } = useWindowSize();
  const { service } = useParams();
  const decryptedName = service ? SimpleCipher.decrypt(service) : '';

  const { data, isLoading } = useWelcomeGetBotContactQuery(
    {
      service: decryptedName,
      offset,
      limit,
    },
    {
      skip: !isParamsMatch,
      refetchOnMountOrArgChange: true,
    },
  );

  const pageChange = (page: number) => {
    let offsetVar;

    if (page === 1) {
      offsetVar = 0;
      setOffset(offsetVar);
      setSearchParams({
        limit: limit.toString(),
        offset: offsetVar.toString(),
        page: page.toString(),
      });
    } else {
      offsetVar = (page - 1) * 10;
      setOffset(offsetVar);
      setSearchParams({
        limit: limit.toString(),
        offset: offsetVar.toString(),
        page: page.toString(),
      });
    }
  };

  useEffect(() => {
    const urlLimit = searchParams.get('limit') || 10; // Get limit from URL, default to 10
    const urlGetOffset = searchParams.get('offset') || 0; // Get offset from URL, default to 0

    setLimit(+urlLimit);
    setOffset(+urlGetOffset);
  }, [searchParams]);

  const statistics = useMemo(() => {
    return data?.data;
  }, [data, isLoading]);

  if (isLoading) return <LoadingState />;

  return (
    <>
      {width > 876 ? (
        <table className={styles.Root}>
          <WelcomeBotStatisticsTableHead />
          <WelcomeBotStatisticsTableBody statistics={statistics} />
        </table>
      ) : (
        <WelcomeBotStatisticsMobileTable statistics={statistics} />
      )}
      <Pagination
        onPageChange={pageChange}
        offset={1}
        totalCount={data?.count ? Math.ceil(data?.count / 10) : 0}
        currPage={Number(searchParams.get('page'))}
      />
    </>
  );
};
