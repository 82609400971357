import { FC, useRef } from 'react';
import FormPageTemplate from 'components/FormPageTemplate';
import {
  useCreateGroupCommentMutation,
  useDeleteGroupCommentByIdMutation,
  useEditCommentListMutation,
  useGetGroupCommentQuery,
} from '../../vk.api';
import { useParams } from 'react-router-dom';
import LoadingState from '../../components/LoadingState';
import { isEmpty, isString } from 'lodash-es';
import EmptyCommentsField from '../EmptyCommentsField';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import TextArea from 'components/TextArea';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CommentsRequestBody, TCommentVKSettings, TEditComment } from '../../vk.types';
import { yupResolver } from '@hookform/resolvers/yup';
import { first3PhotoCommentsSchema } from '../../vk.schema';
import { removeDuplicatesById } from '../../vk.utils';

const GroupsComments: FC = () => {
  const { folderId, parsingId } = useParams();
  const { data: comments, isFetching: isCommentsLoading } = useGetGroupCommentQuery({
    folderId,
    parsingId,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm<CommentsRequestBody>({
    mode: 'onChange',
    defaultValues: { hitLike: false },
    resolver: yupResolver(first3PhotoCommentsSchema()),
  });

  const [updateComments] = useEditCommentListMutation();
  const [createComment] = useCreateGroupCommentMutation();
  const [deleteComment] = useDeleteGroupCommentByIdMutation();
  const cachedValues = useRef<CommentsRequestBody>({});

  const deleteCommentHandler = async (commentId: string) => {
    const res = await deleteComment({ commentId, folderId, parsingId });
    if ('data' in res) {
      await showFeedback({
        title: 'ГОТОВО!',
        text: 'Вы удалили комментарий ',
        type: EFeedbackType.SUCCESS,
      });
    }
  };

  const onSubmit: SubmitHandler<CommentsRequestBody> = async formData => {
    const changedComments: TEditComment[] = [];
    const touchedFieldsKeys = Object.keys(touchedFields);
    let successState = false;

    if (formData.edit && isString(formData.edit)) {
      const res = await createComment({
        comment: formData.edit,
        data: {
          folderId,
          parsingId,
        },
      });
      if ('data' in res && res?.data.success) {
        successState = true;
      }
    }
    if (!isEmpty(touchedFieldsKeys)) {
      const tempObj = { ...formData };
      delete tempObj.edit;
      delete tempObj.hitLike;

      for (const key of touchedFieldsKeys) {
        const resultObj: TEditComment = { id: NaN, comment: '' };
        resultObj.id = +key;
        resultObj.comment = tempObj[key] as string;
        if (!Number.isNaN(resultObj.id)) changedComments.push(resultObj);
      }

      const res = await updateComments({
        comments: changedComments,
        data: {
          folderId,
          parsingId,
        },
      });
      if ('data' in res && res?.data.success) {
        successState = true;
        reset();
      }
    }

    if (successState) {
      await showFeedback({
        title: 'ГОТОВО!',
        text: 'Успешно',
        type: EFeedbackType.SUCCESS,
      });
    }
    reset();
  };

  const handleCachedValues = (values: CommentsRequestBody) => {
    cachedValues.current = values;
  };

  if (isCommentsLoading) return <LoadingState />;
  return (
    <>
      {isEmpty(comments?.data) ? (
        <EmptyCommentsField
          cachedValues={cachedValues.current}
          handleCachedValues={handleCachedValues}
        />
      ) : (
        <FormPageTemplate
          description="Стоит написать разный текст, чтобы не улететь в бан за одинаковый текст. Не волнуйтесть, тексты можно будет потом редактировать"
          isValid
          onSubmit={handleSubmit(onSubmit)}
          buttonText="Сохранить"
          title={'Комментарии пользователям'}
        >
          {removeDuplicatesById(comments?.data as TCommentVKSettings[]).map((comment, idx) => {
            return (
              <TextArea
                key={comment.id + idx}
                label={`Вариант текста ${idx + 1}`}
                register={register(`${comment.id}`)}
                invalid={!!errors[`${comment.id}`]?.message}
                errorMsg={errors[`${comment.id}`]?.message}
                defaultValue={comment.comment}
                icons={[
                  { name: 'alert-delete', onClick: () => deleteCommentHandler('' + comment.id) },
                ]}
              />
            );
          })}
          <TextArea
            label="Добавить вариант +"
            register={register('edit')}
            invalid={!!errors.text1?.message}
            errorMsg={errors.text1?.message}
          />
        </FormPageTemplate>
      )}
    </>
  );
};

export default GroupsComments;
