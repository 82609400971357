import { FC } from 'react';
import FolderGroupInfo from '../../components/FolderGroupInfo';
import ParsingBtn from '../../components/ParsingBtn';
import useWindowSize from 'hooks/useWindowSize';
import FoldersParsingSequence from '../../components/FoldersParsingSequence';
import FolderGroups from '../../components/FolderGroups';
import FolderAutoSide from '../../components/FolderAutoSide';
import { useParams } from 'react-router-dom';
import { useGetFolderGroupsByIdQuery } from '../../vk.api';
import LoadingState from '../../components/LoadingState';

const FolderAction: FC = () => {
  const { width } = useWindowSize();
  const { id } = useParams();
  const { isFetching } = useGetFolderGroupsByIdQuery(
    { groupId: id + '' },
    { refetchOnMountOrArgChange: true },
  );

  if (isFetching) return <LoadingState />;

  return (
    <div>
      {width <= 1024 && <FolderGroupInfo />}
      {width <= 1024 && <FolderAutoSide />}
      {width <= 1024 && <ParsingBtn />}
      <FoldersParsingSequence />
      <FolderGroups />
    </div>
  );
};

export default FolderAction;
