import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import styles from './FileThinInput.module.scss';
import Icon from 'components/Icon';
import { ImageFileType, ImgInputProps } from 'components/ImgInput';
import kbToMbConverter from 'utils/kbToMbConverter';
import first from 'lodash-es/first';
import { isEmpty } from 'lodash-es';
import SmallSpinner from '../../modules/telegram/components/SmallSpinner';

type Props = {
  isUploading?: boolean;
  bothImageAndVideo?: boolean;
} & ImgInputProps;

const FileThinInput: FC<Props> = ({
  setFile,
  limit = 1,
  defaultFile,
  isUploading,
  bothImageAndVideo,
}) => {
  const [images, setImages] = useState<ImageFileType[]>([]);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const type = bothImageAndVideo
    ? 'video/mp4,video/x-m4v,video/*,image/jpeg,image/png,image/gif'
    : 'video/mp4,video/x-m4v,video/*';
  const handleReUpload = async (file: File, isFirstUpload: boolean) => {
    setLoading(true);
    if (inputRef.current) inputRef.current.value = '';
    if (!file) return;

    const sizeImageInMb = kbToMbConverter(file.size);

    if (sizeImageInMb > 2048) {
      setIsError(true);
      setTimeout(() => setIsError(false), 2500);
      return;
    }

    const imagesCopy = [...images];

    if (images.length >= limit) imagesCopy.splice(0, 1);

    imagesCopy.push({
      name: file.name,
      img: URL.createObjectURL(file),
      size: sizeImageInMb,
    });
    if (isFirstUpload) setFile(file);
    setImages(imagesCopy);
  };

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = first(e.target.files);
    if (file) handleReUpload(file, true).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (defaultFile) {
      handleReUpload(defaultFile, false).finally(() => setLoading(false));
    }
  }, [defaultFile]);

  const getFileName = (fileType: ImageFileType) => {
    let fullName = '';
    const fileName = fileType.name;
    const fileExtension = fileName.split('.').pop();
    if (fileType.name.length > 3) {
      fullName = fileType.name.slice(0, 3);
    } else {
      fullName = fileType.name;
    }
    if (isError) {
      fullName = '';
    }

    return 'file..' + fullName + '.' + fileExtension;
  };

  return (
    <div className={styles.Container}>
      {loading || isUploading ? (
        <SmallSpinner />
      ) : (
        <>
          <Icon
            onClick={() => {
              if (!isEmpty(images)) {
                setImages([]);
                setFile();
              }
            }}
            className={styles.Icon}
            iconName={isEmpty(images) ? 'file' : 'delete'}
          />
          <p className={styles.Par}>
            {isEmpty(images) ? 'Добавить файл' : getFileName(images[images.length - 1])}
          </p>
          {isEmpty(images) && (
            <input
              className={styles.Input}
              onChange={handleUpload}
              type={'file'}
              accept={type}
              ref={inputRef}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FileThinInput;
