import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormPageTemplate from 'components/FormPageTemplate';
import ToggleSwitchWithLabel from 'components/ToggleSwitchWithLabel';
import TextArea from 'components/TextArea';
import { CommentsRequestBody, TUpdateVkSetting, TVkSettings } from '../../../vk.types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { first3FieldCommentsSchema } from '../../../vk.schema';
import { useCreateFirstThreeCommentsMutation } from '../../../vk.api';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import LoadingState from '../../../components/LoadingState';
import useFirstRender from 'hooks/useFirstRender';
import { isEmpty } from 'lodash-es';

type Props = {
  currSetting: TVkSettings;
  updateSettingHandler: (arg: TUpdateVkSetting) => void;
  cachedValues?: CommentsRequestBody;
  handleCachedValues?: (arg: CommentsRequestBody) => void;
};

const First3EmptyFields: FC<Props> = ({
  currSetting,
  updateSettingHandler,
  cachedValues,
  handleCachedValues,
}) => {
  const { id } = useParams();
  const [createFirstThreeComment, { isLoading }] = useCreateFirstThreeCommentsMutation();
  const isFirstRender = useFirstRender();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<CommentsRequestBody>({
    mode: 'onChange',
    defaultValues: { hitLike: false },
    resolver: yupResolver(first3FieldCommentsSchema()),
  });

  useEffect(() => {
    if (!isEmpty(cachedValues)) {
      reset({
        ...cachedValues,
      });
    }
  }, [isFirstRender, isEmpty(cachedValues)]);

  const onSubmit: SubmitHandler<CommentsRequestBody> = formData => {
    const cloned = { ...formData };
    delete cloned.hitLike;
    const comments: string[] = Object.values(cloned) as string[];

    if (handleCachedValues) {
      handleCachedValues(cloned);
    }

    createFirstThreeComment({ comments }).then(res => {
      if ('data' in res) {
        showFeedback({
          title: 'ГОТОВО!',
          text: 'Успешно',
          type: EFeedbackType.SUCCESS,
        });
        if (handleCachedValues) {
          handleCachedValues({});
        }
      }
    });
  };

  if (isLoading) return <LoadingState />;

  return (
    <FormPageTemplate
      title={currSetting.description}
      description="Стоит написать разный текст, чтобы не улететь в бан за одинаковый текст. Не волнуйтесть, тексты можно будет потом редактировать"
      onSubmit={handleSubmit(onSubmit)}
      buttonText="Сохранить"
      isValid={isValid}
      headerAddon={
        <ToggleSwitchWithLabel
          active={currSetting.isEnabled}
          toggle={() => {
            if (id)
              updateSettingHandler({
                id: +id,
                isEnabled: !currSetting.isEnabled,
              });
          }}
        />
      }
      short
    >
      <>
        <TextArea
          label="Добавить вариант +"
          register={register('1')}
          invalid={!!errors.text1?.message}
          errorMsg={errors.text1?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('2')}
          invalid={!!errors.text2?.message}
          errorMsg={errors.text2?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('3')}
          invalid={!!errors.text3?.message}
          errorMsg={errors.text3?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('4')}
          invalid={!!errors.text4?.message}
          errorMsg={errors.text4?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('5')}
          invalid={!!errors.text5?.message}
          errorMsg={errors.text5?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('6')}
          invalid={!!errors.text6?.message}
          errorMsg={errors.text6?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('7')}
          invalid={!!errors.text7?.message}
          errorMsg={errors.text7?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('8')}
          invalid={!!errors.text8?.message}
          errorMsg={errors.text8?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('9')}
          invalid={!!errors.text9?.message}
          errorMsg={errors.text9?.message}
        />
        <TextArea
          label="Добавить вариант +"
          register={register('10')}
          invalid={!!errors.text10?.message}
          errorMsg={errors.text10?.message}
        />
      </>
    </FormPageTemplate>
  );
};

export default First3EmptyFields;
