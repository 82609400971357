import { FC, ReactNode } from 'react';

import styles from './EmptyPage.module.scss';
import Button from 'components/Button';

type Props = {
  img: ReactNode;
  title?: string;
  text: string;
  onClick?: () => void;
  btnText?: string;
  footer?: ReactNode;
};

const EmptyPage: FC<Props> = ({ img, title, text, onClick, btnText = 'Перейти', footer }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Img}>{img}</div>
      {title && <h2 className={styles.Title}>{title}</h2>}
      <p className={styles.Text}>{text}</p>
      {onClick && (
        <Button variant="magic" onClick={onClick}>
          {btnText}
        </Button>
      )}
      {footer && <div className={styles.Footer}>{footer}</div>}
    </div>
  );
};

export default EmptyPage;
