import { FC } from 'react';

import styles from './NetworkCard.module.scss';
import commonStyles from '../../Sections.module.scss';
import r from 'constants/routes';
import SmallCard from '../../../Card/SmallCard';
import tg from 'assets/svg/account/tg.svg';
import vk from 'assets/svg/account/vk.svg';
import SectionTitle from 'modules/account/components/SectionTitle';

const NetworkCards: FC = () => {
  return (
    <section className={commonStyles.Section}>
      <SectionTitle>Твои возможности</SectionTitle>
      <div className={styles.Wrapper}>
        <div className={styles.Cards}>
          <SmallCard
            icon="telegram"
            title="Telegram"
            link={r.telegram.index}
            backIcon={tg}
            description="Создавай чат-боты, боты с приветсвием, администраторы
          и многое другое"
          />
          <SmallCard
            icon="vk"
            title="Вконтакте"
            link={r.vk.index}
            backIcon={vk}
            description="Создавай чат-боты, боты с приветсвием, администраторы
          и многое другое"
          />
          <SmallCard
            icon="icon-stat"
            title="BusinessCard"
            link={r.landingDashboard.pages}
            description="Создавай чат-боты, боты с приветсвием, администраторы
          и многое другое"
          />
          <div className={styles.GhostCard}>Hi there</div>
        </div>
      </div>
    </section>
  );
};

export default NetworkCards;
