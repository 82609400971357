import { FC } from 'react';

import styles from './ImgInputContent.module.scss';
import imgError from 'assets/svg/input/Error.svg';
import Icon from 'components/Icon';

type Props = {
  isError: boolean;
  hasRecommendedSize?: boolean;
};

const ImgInputContent: FC<Props> = ({ isError, hasRecommendedSize }) => {
  const getErrorLimit = () => {
    return (
      <>
        <img src={imgError} width={28} height={28} alt="Error" className={styles.ImgError} />
        <p className={styles.InputText}>Загрузка файла</p>
      </>
    );
  };

  const getNormal = () => {
    return (
      <>
        <Icon className={styles.Icon} iconName="download-image" size={32} />
        <div className={styles.Text}>
          <p>Перетащите файл сюда.</p>
          <p>
            Или <span className={styles.Blue}>загрузите</span> изображение
          </p>
        </div>
        {hasRecommendedSize && (
          <p className={styles.RecommendedSize}>Рекомендуемый размер изображения: 1920x1080</p>
        )}
      </>
    );
  };

  return <div className={styles.Content}>{isError ? getErrorLimit() : getNormal()}</div>;
};

export default ImgInputContent;
