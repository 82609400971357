import { FC } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import { TTelegramChat } from '../../telegram.types';
import styles from './TableChatRow.module.scss';
import classNames from 'classnames';
import CheckBox from 'components/CheckBox';
import Button from 'components/Button';

type Props = {
  onCheckboxChange: () => void;
  anchorNavigateHandler: () => void;
  onButtonClick: () => void;
} & TTelegramChat;

const TableChatRow: FC<Props> = ({
  chatName,
  isReachable,
  isSelected,
  onCheckboxChange,
  anchorNavigateHandler,
  onButtonClick,
}) => {
  const { width } = useWindowSize();
  const isDesktop = width > 625;

  return (
    <tr
      className={classNames(styles.Row, {
        [styles.SelectedRow]: isSelected,
      })}
    >
      <td className={styles.CheckboxWrap}>
        <CheckBox className={styles.Checkbox} checked={isSelected} onChange={onCheckboxChange} />

        <a onClick={anchorNavigateHandler} className={styles.Par}>
          {chatName}
        </a>
      </td>

      {isDesktop ? (
        <td className={styles.StatusTd}>
          <div
            className={classNames(styles.Status, {
              [styles.DangerStatus]: !isReachable,
            })}
          />
          <p className={styles.Par}>Статус</p>
        </td>
      ) : (
        <div
          className={classNames(styles.Status, {
            [styles.DangerStatus]: !isReachable,
          })}
        />
      )}

      <td className={styles.LastTd}>
        {!isSelected && (
          <Button variant={'tetriary'} className={styles.Btn} onClick={onButtonClick}>
            {isDesktop ? 'Покинуть чат' : 'Покинуть'}
          </Button>
        )}
      </td>
    </tr>
  );
};

export default TableChatRow;
