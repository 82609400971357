import { FC, useMemo } from 'react';
import { Dialog } from '@headlessui/react';

import useWindowSize from 'hooks/useWindowSize';
import useAppDispatch from 'hooks/useAppDispatch';
import { selectSubscriptionPlan } from '../../tariff.reducer';
import ModalOverlay from 'components/ModalOverlay';
import IconButton from 'components/IconButton';
import styles from './TariffModal.module.scss';
import { tariffImages } from './tariffImages';

interface TariffModalProp {
  planName: string;
  isOpen: boolean;
}

const TariffModal: FC<TariffModalProp> = ({ isOpen, planName }) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const planObject = useMemo(() => {
    switch (planName) {
      case 'BASED':
        return tariffImages.BASED;

      case 'STANDARD':
        return tariffImages.STANDARD;

      case 'PREMIUM':
        return tariffImages.PREMIUM;

      default:
        return null;
    }
  }, [planName]);

  const handleOnClose = () => {
    dispatch(selectSubscriptionPlan(null));
  };

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <ModalOverlay>
        <div className={styles.Background}>
          <Dialog.Panel as="div" className={styles.Card}>
            <IconButton
              className={styles.Close}
              onClick={handleOnClose}
              iconName="close"
              iconSize={15}
            />
            {planObject ? (
              <img src={width > 750 ? planObject.desktop : planObject.mobile} alt="" />
            ) : null}
          </Dialog.Panel>
        </div>
      </ModalOverlay>
    </Dialog>
  );
};

export default TariffModal;
