import { FC } from 'react';
import styles from './PlannesMessages.module.scss';
import PlannedMessageCard from '../PlannedMessageCard';
import { useAdminScheduledMessages } from '../../telegram.selectors';
import Pagination from 'components/Pagination';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { setScheduledMessagesPageParams } from '../../codegen/telegram.admin.reducer';
import EmptyText from '../EmptyText';
import { PlanedMessageCardType } from '../../codegen/telegram.constants';

const PlannedMessages: FC = () => {
  const { data, count } = useAdminScheduledMessages();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const pageChange = (page: number) => {
    let offset;
    queryParams.set('page', `${page}`);
    if (page === 1) {
      offset = 0;
      queryParams.set('offset', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setScheduledMessagesPageParams({ offset }));
    } else {
      offset = (page - 1) * 5;
      queryParams.set('offset', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setScheduledMessagesPageParams({ offset }));
    }
  };

  return (
    <div className={styles.Root}>
      <h1 className={styles.Title}>Запланированные сообщения</h1>

      {count === 0 ? (
        <EmptyText text={'У вас пока нет запланированных сообщений'} />
      ) : (
        <>
          <div className={styles.Wrapper}>
            {data.map(msg => (
              <PlannedMessageCard
                key={msg.id}
                cardType={PlanedMessageCardType.ADMIN_BOT}
                {...msg}
              />
            ))}
          </div>
          <Pagination
            onPageChange={num => pageChange(num)}
            offset={1}
            totalCount={Math.ceil(count / 5)}
            currPage={Number(queryParams.get('page'))}
          />
        </>
      )}
    </div>
  );
};

export default PlannedMessages;
