import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PartnershipHeader.module.scss';
import Breadcrumbs from '../Breadcrumbs';
import Title from 'components/Title';
import { useLinesParams } from 'modules/partnership-program/partnership-program.hooks';

const PartnershipHeader: FC = () => {
  const line = useLinesParams();
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs />
      <Title className={styles.Title}>{t(`partnersLine.${line}`)}</Title>
    </>
  );
};

export default PartnershipHeader;
