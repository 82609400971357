import { FC } from 'react';

import styles from './Alternative.module.scss';

export type AlternativeProps = {
  text: string;
  btn: {
    text: string;
    onClick: () => void;
  };
};

const Alternative: FC<AlternativeProps> = ({ text, btn }) => {
  return (
    <div className={styles.Wrapper}>
      <span className={styles.Text}>{text}</span>
      <button className={styles.Btn} onClick={btn.onClick}>
        {btn.text}
      </button>
    </div>
  );
};

export default Alternative;
