import { FC } from 'react';

import styles from './WhiteTabs.module.scss';

type Props = {
  tabs: {
    name: string;
    onClick: () => void;
    isSelected: boolean;
  }[];
};

const WhiteTabs: FC<Props> = ({ tabs }) => {
  return (
    <div className={styles.Tabs}>
      {tabs.map(tab => (
        <button
          key={tab.name}
          className={styles.Tab}
          data-active={tab.isSelected}
          onClick={tab.onClick}
          type="button"
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export default WhiteTabs;
