import { AppDispatch } from 'config/store';
import { createLanding } from './landing-generator.reducer';
import { Landing, TextProperties } from './landing-generator.types';

export const createPage = (dispatch: AppDispatch) => {
  const landing: Omit<Landing, 'id'> = {
    name: 'Test Landing',
    color: 'rgba(234, 234, 234, 1)',
    photo: null,
    video: null,
    form: null,
    text: {
      title: [],
      subtitle: [],
      text: [],
    },
    buttons: [],
  };
  dispatch(createLanding(landing));
};

export const createTextProps = (props: Partial<TextProperties>): TextProperties => ({
  content: props.content ? props.content : '',
  isBold: props.isBold ? true : false,
  isItalic: props.isItalic ? true : false,
  isUnderline: props.isUnderline ? true : false,
  color: props.color ? props.color : 'rgba(0, 0, 0, 1)',
  alignment: props.alignment ? props.alignment : 'center',
});
