import { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useSelectUser } from 'modules/account/account.selector';
import { persistor } from 'config/store';
import useAppDispatch from 'hooks/useAppDispatch';
import { signOut } from 'modules/auth/auth.reducer';
import r from 'constants/routes';
import { useSignOutRequestMutation } from 'modules/auth/auth.api';

import Icon from 'components/Icon';
import styles from './Header.module.scss';
import Accordion from 'components/Accordion';
import Avatar from 'components/Avatar';
import { delay } from 'lodash-es';
import { clearVkAccountsFromState } from '../../../../modules/vk/vk.reducer';
import { useVkInvalidateTag } from '../../../../modules/vk/vk.hooks';

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelectUser();
  const [signOutRequest] = useSignOutRequestMutation();
  const invalidateVk = useVkInvalidateTag();

  const onLogOut = () => {
    signOutRequest().then(() => {
      dispatch(clearVkAccountsFromState());
      invalidateVk();
      dispatch(signOut());
      delay(() => {
        persistor.purge();
      }, 500);
    });
  };

  return (
    <Accordion
      trigger={isOpen => (
        <div className={styles.Header}>
          <div className={styles.Left}>
            <Avatar
              className={styles.Avatar}
              src={user?.avatar}
              name={user?.fullName ?? '-'}
              size={40}
            />
            <div className={styles.Info}>
              <span className={styles.FullName}>{user?.fullName}</span>
              <span className={styles.Email}>{user?.email}</span>
            </div>
          </div>
          <Icon
            className={styles.Icon}
            iconName={isOpen ? 'navigation-context-up' : 'navigation-context'}
            size={8}
          />
        </div>
      )}
      contentClassName={styles.Options}
    >
      <Link className={classNames(styles.Option, styles.Link)} to={r.settings}>
        Настройки
      </Link>
      <button className={classNames(styles.Option, styles.LogOut)} onClick={onLogOut}>
        Выйти
      </button>
    </Accordion>
  );
};

export default Header;
