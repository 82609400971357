import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './PreviewNavMobile.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import useDisclosure from 'hooks/useDisclosure';
import PublishModal from '../PublishModal';
import useSavePage from 'modules/my-pages/hooks/useSavePage';
import { useGetPageQuery } from 'modules/landing-dashboard/landing-dashboard.api';

const PreviewNavMobile: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetPageQuery({ id: id as string });
  const {
    open: publishOpen,
    onOpen: onPublishOpen,
    onClose: onPublishClose,
  } = useDisclosure({ blockScroll: true });
  const onSave = useSavePage();

  const isPublished = data?.body?.published;

  return (
    <>
      <nav className={styles.Nav}>
        <div className={styles.Content}>
          <button className={styles.Btn} onClick={() => navigate(-1)}>
            <Icon className={styles.ArrowIcon} iconName="arrow-right" size={16} />
          </button>
          <Button className={styles.PublishBtn} variant="primary" onClick={onPublishOpen}>
            {isPublished ? 'Сохранить' : 'Опубликовать'}
          </Button>
          <button className={styles.Btn} onClick={onSave}>
            <Icon className={styles.SaveIcon} iconName="save" size={18} />
          </button>
        </div>
      </nav>

      <PublishModal isOpen={publishOpen} onClose={onPublishClose} isPublished={isPublished} />
    </>
  );
};

export default PreviewNavMobile;
