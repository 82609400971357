import { FC, InputHTMLAttributes, ReactNode, useId, useState } from 'react';
import classNames from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form';
import isUndefined from 'lodash-es/isUndefined';
import isEmpty from 'lodash-es/isEmpty';

import styles from './Input.module.scss';
import Icon from 'components/Icon';
import FieldError from 'components/FieldError';

export type InputIcon = {
  name: string;
  onClick?: () => void;
  className?: string;
  sideContent?: ReactNode;
};

export type InputProps = {
  label: ReactNode;
  register?: UseFormRegisterReturn;
  bigText?: boolean;
  invalid?: boolean;
  errorMsg?: string;
  example?: string;
  isTitle?: boolean;
  variant?: 'light' | 'lighter' | 'dark';
  icons?: InputIcon[];
  bold?: boolean;
  wrapperClassName?: string;
  sideRender?: JSX.Element;
  onWrapperClick?: () => void;
  onChange?: (value: string) => void;
  inputWidth?: string;
  renderInput?: (props: InputHTMLAttributes<HTMLInputElement>) => JSX.Element;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

const Input: FC<InputProps> = ({
  register,
  label,
  icons = [],
  invalid,
  errorMsg,
  example,
  isTitle,
  bold,
  wrapperClassName,
  bigText,
  onChange,
  variant = 'dark',
  sideRender,
  onWrapperClick,
  renderInput,
  inputWidth,
  ...inputProps
}) => {
  const [isActive, setIsActive] = useState(false);
  const id = useId();
  const { onBlur, ...registerWithoutBlur } = register ?? {};

  const handleClick = () => {
    const input = document.getElementById(id);
    if (input) input.focus();
    if (onWrapperClick) onWrapperClick();
  };

  const inputElementProps = {
    id,
    className: styles.Input,
    placeholder: ' ',
    onChange: e => onChange?.(e.target.value),
    onFocus: () => setIsActive(true),
    onBlur: e => {
      if (onBlur) {
        onBlur(e);
      }
      setIsActive(false);
    },
    ['data-title']: isTitle,
    ['data-bold']: bold,
    ['data-big']: bigText,
    ...registerWithoutBlur,
    ...inputProps,
  } as Omit<InputHTMLAttributes<HTMLInputElement>, 'style'>;

  return (
    <div
      className={classNames(styles.Wrapper, wrapperClassName)}
      data-has-side-render={!isUndefined(sideRender)}
      style={{ width: inputWidth }}
    >
      <div
        className={classNames(styles.InputWrapper, {
          [styles.InputWrapperWithIcon]: !!icons.length,
        })}
        style={{ paddingRight: !isEmpty(icons) ? 16 + icons.length * 34 : undefined }}
        data-variant={variant}
        data-disabled={!!inputProps.disabled}
        data-invalid={invalid}
        data-active={isActive}
        onClick={handleClick}
      >
        {renderInput ? renderInput(inputElementProps) : <input {...inputElementProps} />}

        <label className={styles.Label} data-title={isTitle} htmlFor={id}>
          {label}
        </label>

        {icons.length > 0 && (
          <div className={styles.SideRender}>
            {icons.map(icon => (
              <div className={styles.IconWrapper} key={icon.name}>
                <Icon
                  className={classNames(styles.Icon, icon.className, {
                    [styles.IconWithClickHandler]: !isUndefined(icon.onClick),
                  })}
                  iconName={icon.name}
                  size={18}
                  onClick={icon.onClick}
                />
                {icon.sideContent}
              </div>
            ))}
          </div>
        )}
      </div>

      {example && <span className={styles.Example}>{example}</span>}

      {invalid && errorMsg && <FieldError errorMsg={errorMsg} />}

      {!isUndefined(sideRender) && <div className={styles.SideContent}>{sideRender}</div>}
    </div>
  );
};

export default Input;
