import { FC, useMemo } from 'react';
import r from 'constants/routes';
import ActionsLayout from 'modules/vk/components/ActionsLayout';
import ActionCard from 'modules/vk/components/ActionCard';
import {
  useGetAutomationSettingsQuery,
  useUpdateAutomationSettingsMutation,
} from 'modules/vk/vk.api';
import { isEmpty } from 'lodash-es';
import { useGetAllVkAccounts, useGetAutomationSettingsList } from '../../vk.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { toggleVkAutomationSettings } from '../../vk.reducer';
import { namingMapHandler } from '../../vk.utils';
import LoadingState from '../../components/LoadingState';
import EmptyVkPage from '../EmptyVkPage';
import { videoTutorials } from '../../../../constants/videos';
import { TVKAutomationSettings } from '../../vk.types';

const AutomationActions: FC = () => {
  const { isFetching } = useGetAutomationSettingsQuery();
  const [updateSetting] = useUpdateAutomationSettingsMutation();
  const vkAccounts = useGetAllVkAccounts();
  const dispatch = useAppDispatch();
  const settingsList = useGetAutomationSettingsList();

  const namingMap = useMemo(() => {
    return namingMapHandler(settingsList);
  }, [settingsList]);

  const routingHandler = (nameType: string) => {
    const routingMap = {
      [namingMap.AUTO_POSTING]: r.vk.autoPosting,
      [namingMap.AUTO_STORY]: r.vk.autoStories,
      [namingMap.AUTO_TRANSLATION]: r.vk.autoTranslation,
    };

    return routingMap[nameType] || '';
  };

  const finalArr = useMemo(() => {
    return settingsList.map(card => {
      if (card.name === namingMap.AUTO_POSTING) {
        return {
          ...card,
          videoLink: videoTutorials.autoPostingAutomation,
        };
      }
      if (card.name === namingMap.AUTO_STORY) {
        return {
          ...card,
          videoLink: videoTutorials.autoStoriesAutomation,
        };
      }
      if (card.name === namingMap.AUTO_TRANSLATION) {
        return {
          ...card,
          videoLink: videoTutorials.autoBroadcastingAutomation,
        };
      }

      return card;
    });
  }, [settingsList, namingMap]) as TVKAutomationSettings[];

  if (isFetching) return <LoadingState />;
  if (isEmpty(vkAccounts) && !isFetching) return <EmptyVkPage />;

  return (
    <ActionsLayout title="Автоматизация">
      {finalArr.map(card => {
        return (
          <ActionCard
            key={card.id}
            name={card.description}
            text={card.fullDescription}
            onAdjust={routingHandler(card.name)}
            videoLink={card.videoLink as string}
            toggle={{
              active: card.isEnabled,
              toggle: () => {
                dispatch(toggleVkAutomationSettings(card.id));
                updateSetting({
                  data: [
                    {
                      id: card.id,
                      isEnabled: !card.isEnabled,
                    },
                  ],
                });
              },
            }}
          />
        );
      })}
    </ActionsLayout>
  );
};

export default AutomationActions;
