import { FC } from 'react';
import { AnimatePresence } from 'framer-motion';

import { NotificationTypes } from 'modules/notifications/notifications.types';
import { useGetMailQuery } from 'modules/notifications/notifications.api';
import Notification from '../Notification';

type Props = {
  type: NotificationTypes;
};

const NotificationPanel: FC<Props> = ({ type }) => {
  const { data } = useGetMailQuery({ type });

  // TODO: add infinite scroll
  return (
    <AnimatePresence>
      {data?.body.items.map(message => (
        <Notification key={message.id} message={message} />
      ))}
    </AnimatePresence>
  );
};

export default NotificationPanel;
