import { FC, useMemo } from 'react';
import ToggleActionCard from '../../components/ToggleActionCard';
import automessage from 'assets/svg/vk/automessage.svg';
import imageIcon from 'assets/svg/vk/imageIcon.svg';
import likeIcon from 'assets/svg/vk/likeIcon.svg';
import styles from './GroupSettingAutomation.module.scss';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  useGetParsingAutomationSettingsQuery,
  useUpdateParsingAutomationSettingsMutation,
} from '../../vk.api';
import { useGetVkParsedFolderAutomationSettings } from '../../vk.selectors';
import LoadingState from '../../components/LoadingState';
import Icon from '../../../../components/Icon';
import Select, { CSSObjectWithLabel } from 'react-select';
import { selectStyles } from '../../components/ParsingBtn/ParsingModal/utils';
import { TAutoSettings } from '../../vk.types';
import { isNull } from 'lodash-es';
import r from '../../../../constants/routes';

const GroupSettingAutomation: FC = () => {
  const { id: groupId, parsingId } = useParams();
  const { isFetching } = useGetParsingAutomationSettingsQuery({
    parsingId: Number(parsingId),
    groupId: groupId + '',
  });
  const cardArr = useGetVkParsedFolderAutomationSettings();
  const [updateSetting] = useUpdateParsingAutomationSettingsMutation();
  const navigate = useNavigate();

  const typeMap = useMemo(() => {
    return cardArr.reduce((acc, cur) => {
      acc[cur.name] = cur.name;
      return acc;
    }, {} as { [key: string]: string });
  }, [cardArr]);

  const iconHandler = (type: string) => {
    const map = {
      [typeMap.AUTO_COMMENTING]: automessage,
      [typeMap.AUTO_LIKING]: likeIcon,
      [typeMap.AUTO_SENDING_FRIEND_REQUEST]: imageIcon,
    };
    return map[type];
  };

  const options = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
  ];

  const rightComponentHandler = (card: TAutoSettings) => {
    const map = {
      [typeMap.AUTO_COMMENTING]: (
        <Icon
          iconName={'settings'}
          onClick={() =>
            navigate(generatePath(r.vk.groupComments, { folderId: groupId, parsingId }))
          }
        />
      ),
      [typeMap.AUTO_LIKING]: (
        <>
          <Select
            styles={{
              ...selectStyles,

              control: (baseStyles: CSSObjectWithLabel) => ({
                ...baseStyles,
                borderColor: 'var(--m-2-outline, #2c2c35)',
                backgroundColor: 'var(--color-modal-bg)',
                paddingTop: '25px',
                paddingLeft: '5px',
                borderRadius: '12px',
                padding: '3px 2.5px',
                '&:hover': {
                  border: '1px solid var(--color-m4)',
                  transition: 'border-color var(--animation-transition)',
                },
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
            options={options}
            defaultValue={options.find(item => +item.value === card.count)}
            onChange={selectedOption => {
              if (!isNull(selectedOption))
                updateSetting({
                  groupId: groupId + '',
                  parsingId: Number(parsingId),
                  data: [
                    {
                      id: card.id,
                      count: +selectedOption.value,
                      isEnabled: card.isEnabled,
                    },
                  ],
                }).then(response => response);
            }}
          />
        </>
      ),
      [typeMap.AUTO_SENDING_FRIEND_REQUEST]: <></>,
    };

    return map[card.name];
  };

  if (isFetching) return <LoadingState />;

  return (
    <div className={styles.Root}>
      <div className={styles.Grid}>
        {cardArr.map(card => {
          if (card.name === 'AUTO_COMMENTING') {
            return null;
          }

          return (
            <ToggleActionCard
              key={card.id}
              image={iconHandler(card.name)}
              name={card.description}
              description={card.fullDescription}
              rightIcon={rightComponentHandler(card)}
              toggle={{
                active: card.isEnabled,
                toggle: () => {
                  updateSetting({
                    groupId: groupId + '',
                    parsingId: Number(parsingId),
                    data: [
                      {
                        id: card.id,
                        count: card.count,
                        isEnabled: !card.isEnabled,
                      },
                    ],
                  }).then(response => response);
                },
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GroupSettingAutomation;
