import { EPlanName } from 'modules/account/account.interfaces';
import { EFormField } from 'modules/my-pages/my-pages.types';
import { EndDate, PartnerLines } from 'modules/partnership-program/partnership-program.types';

// default types for locales
export type LocaleType = typeof locale;

const locale = {
  translation: {
    labels: {
      email: 'Адрес почты',
      username: 'Логин',
      password: 'Пароль',
      confirmPassword: 'Повторите пароль',
      newPassword: 'Новый пароль',
      oldPassword: 'Старый пароль',
      finPassword: 'Финансовый пароль',
      phone: 'Телефон',
      firstName: 'Имя',
      lastName: 'Фамилия',
      fullName: 'Полное имя',
      text: 'Текст',
      message: 'Сообщение',
      link: 'Ссылка',
      linkGroup: 'Ссылка на группу',
      referral: 'Пригласитель',
      this: 'Это',
      vk: 'Вконтакте',
      viber: 'Вайбер',
      whatsapp: 'Ватсапп',
      instagram: 'Инстаграм',
      facebook: 'Фэйсбук',
      telegram: 'Телеграм',
      api: 'Апи',
      interval: 'Интервал',
      name: 'Название',
      nameGroup: 'Название группы',
      geolocation: 'Геолокация',
      sex: 'Пол',
      age: 'Возраст',
      friendsCount: 'Количество друзей',
      lastActivity: 'Последняя активность',
      groupLink: 'Ссылка на группу',
      date: 'Дата',
      dateAndTime: 'Дата и время выгрузки',
      time: 'Время',
      account: 'Аккаунт',
      accountUrl: 'Ссылка на аккаунт',
      translationKey: 'Ключ трансляции',
      translationUrl: 'URL трансляции',
      videoLink: 'Ссылка на видео',
      spectators: 'Зрители',
      description: 'Описание',
      sheetName: 'Название',
      tariff: 'Тариф',
      periods: 'Периоды',
      token: 'Токен',
      field: 'Поле',
      id: 'Id',
    },
    plans: {
      [EPlanName.trial]: 'Пробный период',
      [EPlanName.default]: 'Default',
    },
    endDate: {
      [EndDate.days7]: '7 дней',
      [EndDate.days14]: '14 дней',
      [EndDate.days30plus]: '30 дней',
    },
    partnersLine: {
      [PartnerLines.FIRST]: 'Партнерская программа первой линии',
      [PartnerLines.SECOND]: 'Партнерская программа второй линии',
      [PartnerLines.THIRD]: 'Партнерская программа третьей линии',
    },
    formFields: {
      [EFormField.COUNTRY]: 'Страна',
      [EFormField.EMAIL]: 'Email',
      [EFormField.FIRST_NAME]: 'Имя',
      [EFormField.LAST_NAME]: 'Фамилия',
      [EFormField.OTHER]: 'Другое',
      [EFormField.PHONE]: 'Номер телефона',
      [EFormField.POSITION]: 'Должность',
      [EFormField.DATE]: 'Дата и время',
    },
    days: {
      [0]: 'Пн',
      [1]: 'Вт',
      [2]: 'Ср',
      [3]: 'Чт',
      [4]: 'Пт',
      [5]: 'Сб',
      [6]: 'Вс',
    },
    validations: {
      mixed: {
        notType: '${path} является недействительным',
        default: '${path} является недействительным',
        required: '${path} обязательное поле',
        oneOf: '${path} должно быть одним из следующих значений: ${values}',
        notOneOf: '${path} не должно быть одним из следующих значений: ${values}',
        defined: '${path} должны быть определены',
        confirmPassword: 'Пароли должны совпадать',
        differentPasswords: 'Финансовый пароль и пароль от аккаунта не должны совпадать',
        vk: '${path} должен быть валидным адресом вконтакте',
        tg: '${path} должен быть валидным адресом телеграм',
        tgOrPhone:
          '${path} должен быть либо валидным юзернеймом телеграм, либо валидным номером телефона',
        instagram: '${path} должен быть валидным адресом инстаграм',
        facebook: '${path} должен быть валидным адресом фэйсбук',
        viber: '${path} должен быть валидным адресом вайбер',
        whatsapp: '${path} должен быть валидным адресом ватсапп',
        youtube: '${path} должен быть валидной ссылкой ютуб',
        phone: '${path} должен быть валидным номером телефона',
      },
      string: {
        length: '${path} должно быть ровно ${length} символов',
        min: '${path} должно быть не менее ${min} символов',
        max: '${path} должно быть не более ${max} символов',
        matches: '${path} должно соответствовать следующему: "${regex}"',
        email: '${path} должен быть действительный адрес электронной почты',
        url: '${path} должен быть действительным URL',
        uuid: '${path} должен быть допустимым UUID',
        trim: '${path} должна быть обрезанная строка',
        lowercase: '${path} должна быть строчной строкой',
        uppercase: '${path} должна быть строка в верхнем регистре',
      },
      number: {
        min: '${path} должно быть больше или равно ${min}',
        max: '${path} должно быть меньше или равно ${max}',
        lessThan: '${path} должно быть меньше, чем ${less}',
        moreThan: '${path} должно быть больше, чем ${more}',
        positive: '${path} должно быть положительным числом',
        negative: '${path} должно быть отрицательное число',
        integer: '${path} должно быть целым числом',
      },
      date: {
        min: '${path} поле должно быть позже, чем ${min}',
        max: '${path} поле должно быть раньше, чем ${max}',
      },
      boolean: {
        isValue: '${path} поле должно быть ${value}',
      },
      object: {
        noUnknown: '${path} поле имеет неуказанные ключи: ${unknown}',
      },
      array: {
        min: '${path} поле должно содержать не менее ${min} элементов',
        max: '${path} поле должно содержать меньше или равно ${max} элементов',
        length: '${path} должно быть элементов ${length}',
      },
    },
    modules: {},
  },
};

export default locale;
