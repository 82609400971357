import React, { useMemo, useCallback, useId } from 'react';
import { AreaClosed, Bar } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { withTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { LinePath } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { GridColumns } from '@visx/grid';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { max, extent, bisector } from 'd3-array';

import { EPeriodType } from 'modules/landing-dashboard/landing-dashboard.types';
import { getFilterDate, getFormatLeftTickValue } from '../../landing-dashboard.helper';

type TooltipData = { time: string; count?: number };

const transparent = 'transparent';
const accentColor = '#edffea';
const lightest = 'var(--color-secondary-lightest)';
const colorD7 = 'var(--color-d7)';
const magenta = 'var(--color-magenta)';
const magentaGradientFrom = 'rgba(232, 15, 233, 0.3)';
const magentaGradientTo = 'rgba(232, 15, 233, 0)';

const tooltipStyles: React.CSSProperties = {
  ...defaultStyles,
  opacity: 0.8,
  background: 'rgba(15, 15, 19, 0.7)',
  backdropFilter: 'blur(40px)',
  color: lightest,
  padding: 8,
};

// accessors
const getDate = (d: TooltipData) => new Date(d.time).valueOf();
const getDataCount = (d: TooltipData) => d.count || 0;
const bisectDate = bisector<TooltipData, Date>(d => getDate(d)).left;

const defaultMargin = { top: 40, right: 40, bottom: 40, left: 40 };

export type AreaProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data: TooltipData[];
  period?: EPeriodType;
};

const StatGraph = withTooltip<AreaProps, TooltipData>(
  ({
    width,
    height,
    margin = defaultMargin,
    data,
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    period,
  }: // period = EPeriodType.MONTH,
  AreaProps & WithTooltipProvidedProps<TooltipData>) => {
    const id = useId();
    const { periodValues, numberOfPeriod, timeFormat } = getFilterDate(period as EPeriodType);
    const tickValues = periodValues?.map((d: TooltipData) => new Date(d.time));
    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    // scales
    const dateScale = useMemo(
      () =>
        scaleTime({
          range: [margin.left, xMax + margin.left],
          domain: extent(periodValues, getDate) as [number, number],
        }),
      [xMax, margin.left, data, periodValues],
    );

    const countValueScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax + margin.top, margin.top],
          domain: [0, (max(data, getDataCount) || 0) + yMax / 3],
          nice: true,
        }),
      [margin.top, yMax, data],
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        if (data.length === 0) return;
        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x - margin.left);
        const index = bisectDate(data, x0, 0) - 1;
        const currentItem = data[index] || data[0];

        showTooltip({
          tooltipData: currentItem,
          tooltipLeft: x - margin.left,
          tooltipTop: countValueScale(getDataCount(currentItem)),
        });
      },
      [showTooltip, countValueScale, dateScale, data, margin.left],
    );

    dateScale.range([0, xMax]);
    countValueScale.range([yMax, 0]);

    if (width < 10) return null;

    return (
      <div>
        <svg width={width} height={height}>
          <rect x={0} y={0} width={width} height={height} fill={transparent} />
          <Group left={margin.left} top={margin.top}>
            <AxisBottom
              hideTicks
              top={yMax}
              scale={dateScale}
              tickFormat={timeFormat}
              numTicks={numberOfPeriod}
              tickStroke={colorD7}
              tickValues={tickValues}
              tickLabelProps={() => ({
                fill: colorD7,
                textAnchor: 'middle',
              })}
            />
            <AxisLeft
              hideTicks
              scale={countValueScale}
              tickStroke={colorD7}
              tickLabelProps={() => ({
                fill: colorD7,
                textAnchor: 'end',
                dy: '0.33em',
              })}
              tickFormat={getFormatLeftTickValue}
            />
            <GridColumns
              scale={dateScale}
              numTicks={numberOfPeriod}
              width={xMax}
              height={yMax}
              strokeDasharray="1,3"
              stroke={accentColor}
              strokeOpacity={0.2}
              pointerEvents="none"
              tickValues={tickValues}
            />
            <LinearGradient
              id="area-gradient"
              from={magentaGradientFrom}
              to={magentaGradientTo}
              toOpacity={0.1}
            />
            <AreaClosed<TooltipData>
              data={data}
              x={d => dateScale(getDate(d)) ?? 0}
              y={d => countValueScale(getDataCount(d)) ?? 0}
              yScale={countValueScale}
              strokeWidth={1}
              stroke="url(#area-gradient)"
              fill="url(#area-gradient)"
              curve={curveMonotoneX}
            />
            <LinePath
              stroke={magenta}
              data={data}
              strokeWidth={2}
              x={d => dateScale(getDate(d)) ?? 0}
              y={d => countValueScale(getDataCount(d)) ?? 0}
              curve={curveMonotoneX}
            />
            <Bar
              x={0}
              y={0}
              width={xMax}
              height={yMax}
              fill={transparent}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={hideTooltip}
            />
            {tooltipData && (
              <g>
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop}
                  r={4}
                  stroke={magenta}
                  strokeWidth={2}
                  fill={lightest}
                  pointerEvents="none"
                />
              </g>
            )}
          </Group>
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              key={id}
              top={tooltipTop + 5}
              left={tooltipLeft + 40}
              style={tooltipStyles}
            >
              {getDataCount(tooltipData)}
              <br />
              <span style={{ color: colorD7 }}>Показов</span>
            </TooltipWithBounds>
          </div>
        )}
      </div>
    );
  },
);

export default StatGraph;
