import api from 'config/http.tg';
import simpleFormData from 'utils/simpleFormData';
import {
  AddGreetingBotAccountValidate,
  TAutoBotResponse,
  WelcomeBotAuthBody,
} from '../telegram.types';
import { EAppTGTagType } from 'interfaces/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    s3ControllerUploadFile: build.mutation<
      S3ControllerUploadFileApiResponse,
      S3ControllerUploadFileApiArg
    >({
      query: queryArg => ({
        url: `/s3/upload`,
        method: 'POST',
        body: simpleFormData(queryArg.fileDto.file),
      }),
    }),
    spamBotControllerGetBot: build.query<
      SpamBotControllerGetBotApiResponse,
      SpamBotControllerGetBotApiArg
    >({
      query: queryArg => ({ url: `/spam-bot/${queryArg.token}` }),
    }),
    spamBotControllerDeleteBot: build.mutation<
      SpamBotControllerDeleteBotApiResponse,
      SpamBotControllerDeleteBotApiArg
    >({
      query: queryArg => ({ url: `/spam-bot/${queryArg.token}`, method: 'DELETE' }),
    }),
    spamBotControllerCreateBot: build.mutation<
      SpamBotControllerCreateBotApiResponse,
      SpamBotControllerCreateBotApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot`,
        method: 'POST',
        body: queryArg.createBotSpamPayload,
      }),
    }),
    spamBotControllerGetBotList: build.query<
      SpamBotControllerGetBotListApiResponse,
      SpamBotControllerGetBotListApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/list`,
        method: 'POST',
        body: queryArg.botSpamListParams,
      }),
    }),
    spamBotControllerToggleBot: build.mutation<
      SpamBotControllerToggleBotApiResponse,
      SpamBotControllerToggleBotApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/toggle`,
        method: 'PATCH',
        body: queryArg.toggleBotSpamPayload,
      }),
    }),
    spamBotControllerRefreshBot: build.mutation<
      SpamBotControllerRefreshBotApiResponse,
      SpamBotControllerRefreshBotApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/refresh`,
        method: 'PATCH',
        body: queryArg.refreshBotSpamPayload,
      }),
    }),
    spamBotMessagesControllerCreateMessage: build.mutation<
      SpamBotMessagesControllerCreateMessageApiResponse,
      SpamBotMessagesControllerCreateMessageApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/messages`,
        method: 'POST',
        body: queryArg.createScheduledMessage,
      }),
    }),
    spamBotMessagesControllerUpdateMessage: build.mutation<
      SpamBotMessagesControllerUpdateMessageApiResponse,
      SpamBotMessagesControllerUpdateMessageApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/messages`,
        method: 'PATCH',
        body: queryArg.updateScheduledMessage,
      }),
    }),
    spamBotMessagesControllerDeleteMessage: build.mutation<
      SpamBotMessagesControllerDeleteMessageApiResponse,
      SpamBotMessagesControllerDeleteMessageApiArg
    >({
      query: queryArg => ({ url: `/spam-bot/messages/${queryArg.id}`, method: 'DELETE' }),
    }),
    adminBotControllerGetBot: build.query<
      AdminBotControllerGetBotApiResponse,
      AdminBotControllerGetBotApiArg
    >({
      query: queryArg => ({ url: `/admin-bot/${queryArg.token}` }),
    }),
    adminBotControllerDeleteBot: build.mutation<
      AdminBotControllerDeleteBotApiResponse,
      AdminBotControllerDeleteBotApiArg
    >({
      query: queryArg => ({ url: `/admin-bot/${queryArg.token}`, method: 'DELETE' }),
    }),
    adminBotControllerCreateBot: build.mutation<
      AdminBotControllerCreateBotApiResponse,
      AdminBotControllerCreateBotApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot`,
        method: 'POST',
        body: queryArg.createBotAdminPayload,
      }),
    }),
    adminBotControllerGetBotList: build.query<
      AdminBotControllerGetBotListApiResponse,
      AdminBotControllerGetBotListApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/list`,
        method: 'POST',
        body: queryArg.botAdminListParams,
      }),
    }),
    adminBotControllerToggleBot: build.mutation<
      AdminBotControllerToggleBotApiResponse,
      AdminBotControllerToggleBotApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/toggle`,
        method: 'PATCH',
        body: queryArg.toggleBotAdminPayload,
      }),
    }),
    adminBotControllerRefreshBot: build.mutation<
      AdminBotControllerRefreshBotApiResponse,
      AdminBotControllerRefreshBotApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/refresh`,
        method: 'PATCH',
        body: queryArg.refreshBotAdminPayload,
      }),
    }),
    adminBotMessagesControllerCreateMessage: build.mutation<
      AdminBotMessagesControllerCreateMessageApiResponse,
      AdminBotMessagesControllerCreateMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/messages`,
        method: 'POST',
        body: queryArg.createScheduledMessage,
      }),
    }),
    adminBotMessagesControllerUpdateMessage: build.mutation<
      AdminBotMessagesControllerUpdateMessageApiResponse,
      AdminBotMessagesControllerUpdateMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/messages`,
        method: 'PATCH',
        body: queryArg.updateScheduledMessage,
      }),
    }),
    adminBotMessagesControllerDeleteMessage: build.mutation<
      AdminBotMessagesControllerDeleteMessageApiResponse,
      AdminBotMessagesControllerDeleteMessageApiArg
    >({
      query: queryArg => ({ url: `/admin-bot/messages/${queryArg.id}`, method: 'DELETE' }),
    }),
    adminBotCommandsControllerCreateCommand: build.mutation<
      AdminBotCommandsControllerCreateCommandApiResponse,
      AdminBotCommandsControllerCreateCommandApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/commands`,
        method: 'POST',
        body: queryArg.createBotAdminCommandPayload,
      }),
    }),
    adminBotCommandsControllerUpdateCommand: build.mutation<
      AdminBotCommandsControllerUpdateCommandApiResponse,
      AdminBotCommandsControllerUpdateCommandApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/commands`,
        method: 'PATCH',
        body: queryArg.updateBotAdminCommandPayload,
      }),
    }),
    adminBotCommandsControllerDeleteCommand: build.mutation<
      AdminBotCommandsControllerDeleteCommandApiResponse,
      AdminBotCommandsControllerDeleteCommandApiArg
    >({
      query: queryArg => ({ url: `/admin-bot/commands/${queryArg.id}`, method: 'DELETE' }),
    }),
    adminBotWelcomeMessagesControllerCreateWelcomeMessage: build.mutation<
      AdminBotWelcomeMessagesControllerCreateWelcomeMessageApiResponse,
      AdminBotWelcomeMessagesControllerCreateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages`,
        method: 'POST',
        body: queryArg.botAdminCreateWelcomeMessagePayload,
      }),
    }),
    adminBotWelcomeMessagesControllerUpdateWelcomeMessage: build.mutation<
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiResponse,
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages`,
        method: 'PATCH',
        body: queryArg.botAdminUpdateWelcomeMessageContentPayload,
      }),
    }),
    adminBotWelcomeMessagesControllerUpdateWelcomeMessageStatus: build.mutation<
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiResponse,
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages/status`,
        method: 'PATCH',
        body: queryArg.botAdminUpdateWelcomeMessageStatusPayload,
      }),
    }),
    adminBotWelcomeMessagesControllerDeleteWelcomeMessage: build.mutation<
      AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiResponse,
      AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    welcomeBotControllerGetBot: build.query<
      WelcomeBotControllerGetBotApiResponse,
      WelcomeBotControllerGetBotApiArg
    >({
      query: queryArg => ({ url: `/welcome-bot/account/${queryArg.account}` }),
    }),
    createWelcomeBot: build.mutation<
      WelcomeBotControllerStartAuthenticationApiResponse,
      WelcomeBotAuthBody
    >({
      query: body => ({
        url: `/bot/welcome`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTGTagType.WELCOME_BOT],
    }),
    deleteWelcomeBotUser: build.mutation<
      WelcomeBotControllerStartAuthenticationApiResponse,
      unknown
    >({
      query: () => ({
        url: `/bot/welcome`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        EAppTGTagType.WELCOME_BOT,
        EAppTGTagType.WELCOME_BOT_CONTACTS,
        EAppTGTagType.WELCOME,
        EAppTGTagType.WELCOME_MESSAGES,
      ],
    }),
    welcomeBotUserValidate: build.mutation<unknown, unknown>({
      query: () => ({
        url: `/bot/welcome/auth`,
        method: 'POST',
      }),
      invalidatesTags: [EAppTGTagType.WELCOME_BOT, EAppTGTagType.WELCOME],
    }),
    welcomeBotAuth: build.mutation<TAutoBotResponse<unknown>, AddGreetingBotAccountValidate>({
      query: body => ({
        url: `/bot/welcome/auth/code`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTGTagType.WELCOME_BOT],
    }),
    welcomeBotControllerStartAuthentication: build.mutation<
      WelcomeBotControllerStartAuthenticationApiResponse,
      WelcomeBotControllerStartAuthenticationApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/auth`,
        method: 'POST',
        body: queryArg.createBotWelcomePayload,
      }),
    }),
    welcomeBotControllerFinishAuthentication: build.mutation<
      WelcomeBotControllerFinishAuthenticationApiResponse,
      WelcomeBotControllerFinishAuthenticationApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/code`,
        method: 'POST',
        body: queryArg.finishAuthRequest,
      }),
    }),
    welcomeBotControllerDeleteBot: build.mutation<
      WelcomeBotControllerDeleteBotApiResponse,
      WelcomeBotControllerDeleteBotApiArg
    >({
      query: queryArg => ({ url: `/welcome-bot/${queryArg.account}`, method: 'DELETE' }),
    }),
    welcomeBotControllerGetBotList: build.query<
      WelcomeBotControllerGetBotListApiResponse,
      WelcomeBotControllerGetBotListApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/list`,
        method: 'POST',
        body: queryArg.botWelcomeListParams,
      }),
    }),
    welcomeBotControllerToggleBot: build.mutation<
      WelcomeBotControllerToggleBotApiResponse,
      WelcomeBotControllerToggleBotApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/toggle`,
        method: 'PATCH',
        body: queryArg.toggleBotWelcomePayload,
      }),
    }),
    welcomeBotControllerSendMessage: build.mutation<
      WelcomeBotControllerSendMessageApiResponse,
      WelcomeBotControllerSendMessageApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/send`,
        method: 'POST',
        body: queryArg.sendWelcomeMessagePayload,
        headers: { 'X-Stars-key': queryArg['X-Stars-key'] },
      }),
    }),
    welcomeBotControllerSendMessageByServer: build.mutation<
      WelcomeBotControllerSendMessageByServerApiResponse,
      WelcomeBotControllerSendMessageByServerApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/send_server`,
        method: 'POST',
        body: queryArg.sendWelcomeMessagePayloadByServer,
        headers: { 'X-Stars-key': queryArg['X-Stars-key'] },
      }),
    }),
    welcomeBotControllerGetKey: build.query<
      WelcomeBotControllerGetKeyApiResponse,
      WelcomeBotControllerGetKeyApiArg
    >({
      query: () => ({ url: `/welcome-bot/key` }),
    }),
    welcomeBotMessagesControllerCreateWelcomeMessage: build.mutation<
      WelcomeBotMessagesControllerCreateWelcomeMessageApiResponse,
      WelcomeBotMessagesControllerCreateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/welcomeMessages`,
        method: 'POST',
        body: queryArg.botWelcomeCreateWelcomeMessagePayload,
      }),
    }),
    welcomeBotMessagesControllerUpdateWelcomeMessage: build.mutation<
      WelcomeBotMessagesControllerUpdateWelcomeMessageApiResponse,
      WelcomeBotMessagesControllerUpdateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/welcomeMessages`,
        method: 'PATCH',
        body: queryArg.botWelcomeUpdateWelcomeMessageContentPayload,
      }),
    }),
    welcomeBotMessagesControllerUpdateWelcomeMessageStatus: build.mutation<
      WelcomeBotMessagesControllerUpdateWelcomeMessageStatusApiResponse,
      WelcomeBotMessagesControllerUpdateWelcomeMessageStatusApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/welcomeMessages/status`,
        method: 'PATCH',
        body: queryArg.botWelcomeUpdateWelcomeMessageStatusPayload,
      }),
    }),
    welcomeBotMessagesControllerDeleteWelcomeMessage: build.mutation<
      WelcomeBotMessagesControllerDeleteWelcomeMessageApiResponse,
      WelcomeBotMessagesControllerDeleteWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/welcomeMessages/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    constructorBotControllerGetBot: build.query<
      ConstructorBotControllerGetBotApiResponse,
      ConstructorBotControllerGetBotApiArg
    >({
      query: queryArg => ({ url: `/constructor-bot/${queryArg.token}` }),
    }),
    constructorBotControllerDeleteBot: build.mutation<
      ConstructorBotControllerDeleteBotApiResponse,
      ConstructorBotControllerDeleteBotApiArg
    >({
      query: queryArg => ({ url: `/constructor-bot/${queryArg.token}`, method: 'DELETE' }),
    }),
    constructorBotControllerCreateBot: build.mutation<
      ConstructorBotControllerCreateBotApiResponse,
      ConstructorBotControllerCreateBotApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot`,
        method: 'POST',
        body: queryArg.createBotConstructorPayload,
      }),
    }),
    constructorBotControllerGetBotList: build.query<
      ConstructorBotControllerGetBotListApiResponse,
      ConstructorBotControllerGetBotListApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/list`,
        method: 'POST',
        body: queryArg.botConstructorListParams,
      }),
    }),
    constructorBotControllerToggleBot: build.mutation<
      ConstructorBotControllerToggleBotApiResponse,
      ConstructorBotControllerToggleBotApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/toggle`,
        method: 'PATCH',
        body: queryArg.toggleBotConstructorPayload,
      }),
    }),
    constructorBotControllerRefreshBot: build.mutation<
      ConstructorBotControllerRefreshBotApiResponse,
      ConstructorBotControllerRefreshBotApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/refresh`,
        method: 'PATCH',
        body: queryArg.refreshBotConstructorPayload,
      }),
    }),
    constructorBotGraphControllerCreateGraph: build.mutation<
      ConstructorBotGraphControllerCreateGraphApiResponse,
      ConstructorBotGraphControllerCreateGraphApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/graph`,
        method: 'POST',
        body: queryArg.createGraphPayload,
      }),
    }),
    constructorBotGraphControllerUpdateGraph: build.mutation<
      ConstructorBotGraphControllerUpdateGraphApiResponse,
      ConstructorBotGraphControllerUpdateGraphApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/graph`,
        method: 'PATCH',
        body: queryArg.updateGraphPayload,
      }),
    }),
    constructorBotGraphControllerDeleteGraph: build.mutation<
      ConstructorBotGraphControllerDeleteGraphApiResponse,
      ConstructorBotGraphControllerDeleteGraphApiArg
    >({
      query: queryArg => ({ url: `/constructor-bot/graph/${queryArg.id}`, method: 'DELETE' }),
    }),
    constructorBotEdgeControllerCreateEdge: build.mutation<
      ConstructorBotEdgeControllerCreateEdgeApiResponse,
      ConstructorBotEdgeControllerCreateEdgeApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/edge`,
        method: 'POST',
        body: queryArg.createEdgePayload,
      }),
    }),
    constructorBotEdgeControllerUpdateEdge: build.mutation<
      ConstructorBotEdgeControllerUpdateEdgeApiResponse,
      ConstructorBotEdgeControllerUpdateEdgeApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/edge`,
        method: 'PATCH',
        body: queryArg.updateEdgePayload,
      }),
    }),
    constructorBotEdgeControllerDeleteEdge: build.mutation<
      ConstructorBotEdgeControllerDeleteEdgeApiResponse,
      ConstructorBotEdgeControllerDeleteEdgeApiArg
    >({
      query: queryArg => ({ url: `/constructor-bot/edge/${queryArg.id}`, method: 'DELETE' }),
    }),
    constructorBotNodeMessageControllerCreateNodeMessage: build.mutation<
      ConstructorBotNodeMessageControllerCreateNodeMessageApiResponse,
      ConstructorBotNodeMessageControllerCreateNodeMessageApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/nodeMessage`,
        method: 'POST',
        body: queryArg.createNodeMessagePayload,
      }),
    }),
    constructorBotNodeMessageControllerUpdateNodeMessage: build.mutation<
      ConstructorBotNodeMessageControllerUpdateNodeMessageApiResponse,
      ConstructorBotNodeMessageControllerUpdateNodeMessageApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/nodeMessage`,
        method: 'PATCH',
        body: queryArg.updateNodeMessagePayload,
      }),
    }),
    constructorBotNodeMessageControllerDeleteNodeMessage: build.mutation<
      ConstructorBotNodeMessageControllerDeleteNodeMessageApiResponse,
      ConstructorBotNodeMessageControllerDeleteNodeMessageApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/nodeMessage/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    constructorBotNodeMediaGroupControllerCreateNodeMediaGroup: build.mutation<
      ConstructorBotNodeMediaGroupControllerCreateNodeMediaGroupApiResponse,
      ConstructorBotNodeMediaGroupControllerCreateNodeMediaGroupApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/nodeMediaGroup`,
        method: 'POST',
        body: queryArg.createNodeMediaGroupPayload,
      }),
    }),
    constructorBotNodeMediaGroupControllerUpdateNodeMediaGroup: build.mutation<
      ConstructorBotNodeMediaGroupControllerUpdateNodeMediaGroupApiResponse,
      ConstructorBotNodeMediaGroupControllerUpdateNodeMediaGroupApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/nodeMediaGroup`,
        method: 'PATCH',
        body: queryArg.updateNodeMediaGroupPayload,
      }),
    }),
    constructorBotNodeMediaGroupControllerDeleteNodeMediaGroup: build.mutation<
      ConstructorBotNodeMediaGroupControllerDeleteNodeMediaGroupApiResponse,
      ConstructorBotNodeMediaGroupControllerDeleteNodeMediaGroupApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/nodeMediaGroup/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    constructorBotCommandControllerCreateCommand: build.mutation<
      ConstructorBotCommandControllerCreateCommandApiResponse,
      ConstructorBotCommandControllerCreateCommandApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/command`,
        method: 'POST',
        body: queryArg.createCommandPayload,
      }),
    }),
    constructorBotCommandControllerUpdateCommand: build.mutation<
      ConstructorBotCommandControllerUpdateCommandApiResponse,
      ConstructorBotCommandControllerUpdateCommandApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/command`,
        method: 'PATCH',
        body: queryArg.updateCommandPayload,
      }),
    }),
    constructorBotCommandControllerDeleteCommand: build.mutation<
      ConstructorBotCommandControllerDeleteCommandApiResponse,
      ConstructorBotCommandControllerDeleteCommandApiArg
    >({
      query: queryArg => ({ url: `/constructor-bot/command/${queryArg.id}`, method: 'DELETE' }),
    }),
    constructorBotMenuControllerCreateMenu: build.mutation<
      ConstructorBotMenuControllerCreateMenuApiResponse,
      ConstructorBotMenuControllerCreateMenuApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/menu`,
        method: 'POST',
        body: queryArg.createMenuPayload,
      }),
    }),
    constructorBotMenuControllerUpdateMenu: build.mutation<
      ConstructorBotMenuControllerUpdateMenuApiResponse,
      ConstructorBotMenuControllerUpdateMenuApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/menu`,
        method: 'PATCH',
        body: queryArg.updateMenuPayload,
      }),
    }),
    constructorBotMenuControllerDeleteGraph: build.mutation<
      ConstructorBotMenuControllerDeleteGraphApiResponse,
      ConstructorBotMenuControllerDeleteGraphApiArg
    >({
      query: queryArg => ({ url: `/constructor-bot/menu/${queryArg.id}`, method: 'DELETE' }),
    }),
    constructorBotMenuButtonControllerCreateMenuButton: build.mutation<
      ConstructorBotMenuButtonControllerCreateMenuButtonApiResponse,
      ConstructorBotMenuButtonControllerCreateMenuButtonApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/menuButton`,
        method: 'POST',
        body: queryArg.createMenuButtonPayload,
      }),
    }),
    constructorBotMenuButtonControllerUpdateMenuButton: build.mutation<
      ConstructorBotMenuButtonControllerUpdateMenuButtonApiResponse,
      ConstructorBotMenuButtonControllerUpdateMenuButtonApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/menuButton`,
        method: 'PATCH',
        body: queryArg.updateMenuButtonPayload,
      }),
    }),
    constructorBotMenuButtonControllerDeleteGraph: build.mutation<
      ConstructorBotMenuButtonControllerDeleteGraphApiResponse,
      ConstructorBotMenuButtonControllerDeleteGraphApiArg
    >({
      query: queryArg => ({
        url: `/constructor-bot/menuButton/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
// export { injectedRtkApi as tgApiInjected };
export type S3ControllerUploadFileApiResponse =
  /** status 200 Возращает путь до файла (т.е. нужно префиксить домен с s3) */
  ImageResponse | /** status 201  */ undefined;
export type S3ControllerUploadFileApiArg = {
  fileDto: FileDto;
};
export type SpamBotControllerGetBotApiResponse = /** status 200  */ BotSpamFull;
export type SpamBotControllerGetBotApiArg = {
  token: string;
};
export type SpamBotControllerDeleteBotApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotControllerDeleteBotApiArg = {
  token: string;
};
export type SpamBotControllerCreateBotApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type SpamBotControllerCreateBotApiArg = {
  createBotSpamPayload: CreateBotSpamPayload;
};
export type SpamBotControllerGetBotListApiResponse =
  /** status 200  */
  BotSpamListResponse | /** status 201  */ undefined;
export type SpamBotControllerGetBotListApiArg = {
  botSpamListParams: BotSpamListParams;
};
export type SpamBotControllerToggleBotApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotControllerToggleBotApiArg = {
  toggleBotSpamPayload: ToggleBotSpamPayload;
};
export type SpamBotControllerRefreshBotApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotControllerRefreshBotApiArg = {
  refreshBotSpamPayload: RefreshBotSpamPayload;
};
export type SpamBotMessagesControllerCreateMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type SpamBotMessagesControllerCreateMessageApiArg = {
  createScheduledMessage: CreateScheduledMessage;
};
export type SpamBotMessagesControllerUpdateMessageApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotMessagesControllerUpdateMessageApiArg = {
  updateScheduledMessage: UpdateScheduledMessage;
};
export type SpamBotMessagesControllerDeleteMessageApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotMessagesControllerDeleteMessageApiArg = {
  id: number;
};
export type AdminBotControllerGetBotApiResponse = /** status 200  */ BotAdminFull;
export type AdminBotControllerGetBotApiArg = {
  token: string;
};
export type AdminBotControllerDeleteBotApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotControllerDeleteBotApiArg = {
  token: string;
};
export type AdminBotControllerCreateBotApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotControllerCreateBotApiArg = {
  createBotAdminPayload: CreateBotAdminPayload;
};
export type AdminBotControllerGetBotListApiResponse =
  /** status 200  */
  BotAdminListResponse | /** status 201  */ undefined;
export type AdminBotControllerGetBotListApiArg = {
  botAdminListParams: BotAdminListParams;
};
export type AdminBotControllerToggleBotApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotControllerToggleBotApiArg = {
  toggleBotAdminPayload: ToggleBotAdminPayload;
};
export type AdminBotControllerRefreshBotApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotControllerRefreshBotApiArg = {
  refreshBotAdminPayload: RefreshBotAdminPayload;
};
export type AdminBotMessagesControllerCreateMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotMessagesControllerCreateMessageApiArg = {
  createScheduledMessage: CreateScheduledMessage;
};
export type AdminBotMessagesControllerUpdateMessageApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotMessagesControllerUpdateMessageApiArg = {
  updateScheduledMessage: UpdateScheduledMessage;
};
export type AdminBotMessagesControllerDeleteMessageApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotMessagesControllerDeleteMessageApiArg = {
  id: number;
};
export type AdminBotCommandsControllerCreateCommandApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotCommandsControllerCreateCommandApiArg = {
  createBotAdminCommandPayload: CreateBotAdminCommandPayload;
};
export type AdminBotCommandsControllerUpdateCommandApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotCommandsControllerUpdateCommandApiArg = {
  updateBotAdminCommandPayload: UpdateBotAdminCommandPayload;
};
export type AdminBotCommandsControllerDeleteCommandApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotCommandsControllerDeleteCommandApiArg = {
  id: number;
};
export type AdminBotWelcomeMessagesControllerCreateWelcomeMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotWelcomeMessagesControllerCreateWelcomeMessageApiArg = {
  botAdminCreateWelcomeMessagePayload: BotAdminCreateWelcomeMessagePayload;
};
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiArg = {
  botAdminUpdateWelcomeMessageContentPayload: BotAdminUpdateWelcomeMessageContentPayload;
};
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiArg = {
  botAdminUpdateWelcomeMessageStatusPayload: BotAdminUpdateWelcomeMessageStatusPayload;
};
export type AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiArg = {
  id: number;
};
export type WelcomeBotControllerGetBotApiResponse = /** status 200  */ BotWelcomeFull;
export type WelcomeBotControllerGetBotApiArg = {
  account: string;
};
export type WelcomeBotControllerStartAuthenticationApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotControllerStartAuthenticationApiArg = {
  createBotWelcomePayload: CreateBotWelcomePayload;
};
export type WelcomeBotControllerFinishAuthenticationApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotControllerFinishAuthenticationApiArg = {
  finishAuthRequest: FinishAuthRequest;
};
export type WelcomeBotControllerDeleteBotApiResponse = /** status 200  */ DefaultResponse;
export type WelcomeBotControllerDeleteBotApiArg = {
  account: string;
};
export type WelcomeBotControllerGetBotListApiResponse =
  /** status 200  */
  BotWelcomeListResponse | /** status 201  */ undefined;
export type WelcomeBotControllerGetBotListApiArg = {
  botWelcomeListParams: BotWelcomeListParams;
};
export type WelcomeBotControllerToggleBotApiResponse = /** status 200  */ DefaultResponse;
export type WelcomeBotControllerToggleBotApiArg = {
  toggleBotWelcomePayload: ToggleBotWelcomePayload;
};
export type WelcomeBotControllerSendMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotControllerSendMessageApiArg = {
  'X-Stars-key': string;
  sendWelcomeMessagePayload: SendWelcomeMessagePayload;
};
export type WelcomeBotControllerSendMessageByServerApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotControllerSendMessageByServerApiArg = {
  'X-Stars-key': string;
  sendWelcomeMessagePayloadByServer: SendWelcomeMessagePayloadByServer;
};
export type WelcomeBotControllerGetKeyApiResponse = /** status 200  */ string;
export type WelcomeBotControllerGetKeyApiArg = void;
export type WelcomeBotMessagesControllerCreateWelcomeMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotMessagesControllerCreateWelcomeMessageApiArg = {
  botWelcomeCreateWelcomeMessagePayload: BotWelcomeCreateWelcomeMessagePayload;
};
export type WelcomeBotMessagesControllerUpdateWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type WelcomeBotMessagesControllerUpdateWelcomeMessageApiArg = {
  botWelcomeUpdateWelcomeMessageContentPayload: BotWelcomeUpdateWelcomeMessageContentPayload;
};
export type WelcomeBotMessagesControllerUpdateWelcomeMessageStatusApiResponse =
  /** status 200  */ DefaultResponse;
export type WelcomeBotMessagesControllerUpdateWelcomeMessageStatusApiArg = {
  botWelcomeUpdateWelcomeMessageStatusPayload: BotWelcomeUpdateWelcomeMessageStatusPayload;
};
export type WelcomeBotMessagesControllerDeleteWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type WelcomeBotMessagesControllerDeleteWelcomeMessageApiArg = {
  id: number;
};
export type ConstructorBotControllerGetBotApiResponse = /** status 200  */ BotConstructorFull;
export type ConstructorBotControllerGetBotApiArg = {
  token: string;
};
export type ConstructorBotControllerDeleteBotApiResponse = /** status 200  */ DefaultResponse;
export type ConstructorBotControllerDeleteBotApiArg = {
  token: string;
};
export type ConstructorBotControllerCreateBotApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type ConstructorBotControllerCreateBotApiArg = {
  createBotConstructorPayload: CreateBotConstructorPayload;
};
export type ConstructorBotControllerGetBotListApiResponse =
  /** status 200  */
  BotConstructorListResponse | /** status 201  */ undefined;
export type ConstructorBotControllerGetBotListApiArg = {
  botConstructorListParams: BotConstructorListParams;
};
export type ConstructorBotControllerToggleBotApiResponse = /** status 200  */ DefaultResponse;
export type ConstructorBotControllerToggleBotApiArg = {
  toggleBotConstructorPayload: ToggleBotConstructorPayload;
};
export type ConstructorBotControllerRefreshBotApiResponse = /** status 200  */ DefaultResponse;
export type ConstructorBotControllerRefreshBotApiArg = {
  refreshBotConstructorPayload: RefreshBotConstructorPayload;
};
export type ConstructorBotGraphControllerCreateGraphApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type ConstructorBotGraphControllerCreateGraphApiArg = {
  createGraphPayload: CreateGraphPayload;
};
export type ConstructorBotGraphControllerUpdateGraphApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotGraphControllerUpdateGraphApiArg = {
  updateGraphPayload: UpdateGraphPayload;
};
export type ConstructorBotGraphControllerDeleteGraphApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotGraphControllerDeleteGraphApiArg = {
  id: number;
};
export type ConstructorBotEdgeControllerCreateEdgeApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type ConstructorBotEdgeControllerCreateEdgeApiArg = {
  createEdgePayload: CreateEdgePayload;
};
export type ConstructorBotEdgeControllerUpdateEdgeApiResponse = /** status 200  */ DefaultResponse;
export type ConstructorBotEdgeControllerUpdateEdgeApiArg = {
  updateEdgePayload: UpdateEdgePayload;
};
export type ConstructorBotEdgeControllerDeleteEdgeApiResponse = /** status 200  */ DefaultResponse;
export type ConstructorBotEdgeControllerDeleteEdgeApiArg = {
  id: number;
};
export type ConstructorBotNodeMessageControllerCreateNodeMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type ConstructorBotNodeMessageControllerCreateNodeMessageApiArg = {
  createNodeMessagePayload: CreateNodeMessagePayload;
};
export type ConstructorBotNodeMessageControllerUpdateNodeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotNodeMessageControllerUpdateNodeMessageApiArg = {
  updateNodeMessagePayload: UpdateNodeMessagePayload;
};
export type ConstructorBotNodeMessageControllerDeleteNodeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotNodeMessageControllerDeleteNodeMessageApiArg = {
  id: number;
};
export type ConstructorBotNodeMediaGroupControllerCreateNodeMediaGroupApiResponse =
  /** status 200  */ DefaultResponse | /** status 201  */ undefined;
export type ConstructorBotNodeMediaGroupControllerCreateNodeMediaGroupApiArg = {
  createNodeMediaGroupPayload: CreateNodeMediaGroupPayload;
};
export type ConstructorBotNodeMediaGroupControllerUpdateNodeMediaGroupApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotNodeMediaGroupControllerUpdateNodeMediaGroupApiArg = {
  updateNodeMediaGroupPayload: UpdateNodeMediaGroupPayload;
};
export type ConstructorBotNodeMediaGroupControllerDeleteNodeMediaGroupApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotNodeMediaGroupControllerDeleteNodeMediaGroupApiArg = {
  id: number;
};
export type ConstructorBotCommandControllerCreateCommandApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type ConstructorBotCommandControllerCreateCommandApiArg = {
  createCommandPayload: CreateCommandPayload;
};
export type ConstructorBotCommandControllerUpdateCommandApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotCommandControllerUpdateCommandApiArg = {
  updateCommandPayload: UpdateCommandPayload;
};
export type ConstructorBotCommandControllerDeleteCommandApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotCommandControllerDeleteCommandApiArg = {
  id: number;
};
export type ConstructorBotMenuControllerCreateMenuApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type ConstructorBotMenuControllerCreateMenuApiArg = {
  createMenuPayload: CreateMenuPayload;
};
export type ConstructorBotMenuControllerUpdateMenuApiResponse = /** status 200  */ DefaultResponse;
export type ConstructorBotMenuControllerUpdateMenuApiArg = {
  updateMenuPayload: UpdateMenuPayload;
};
export type ConstructorBotMenuControllerDeleteGraphApiResponse = /** status 200  */ DefaultResponse;
export type ConstructorBotMenuControllerDeleteGraphApiArg = {
  id: number;
};
export type ConstructorBotMenuButtonControllerCreateMenuButtonApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type ConstructorBotMenuButtonControllerCreateMenuButtonApiArg = {
  createMenuButtonPayload: CreateMenuButtonPayload;
};
export type ConstructorBotMenuButtonControllerUpdateMenuButtonApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotMenuButtonControllerUpdateMenuButtonApiArg = {
  updateMenuButtonPayload: UpdateMenuButtonPayload;
};
export type ConstructorBotMenuButtonControllerDeleteGraphApiResponse =
  /** status 200  */ DefaultResponse;
export type ConstructorBotMenuButtonControllerDeleteGraphApiArg = {
  id: number;
};
export type ImageResponse = {
  image: string;
};
export type FileDto = {
  file: {
    file?: Blob;
  };
};
export type Chat = {
  id: number;
  chatId: string;
  chatName: string | null;
  isReachable: boolean;
  botSpamToken: string;
};
export type ScheduledMessageDate = {
  id: number;
  date: string;
  scheduledMessageId: number | null;
};
export type ScheduledMessageFile = {
  id: number;
  mime: string;
  url: string;
  scheduledMessageId: number | null;
};
export type ScheduledMessageFull = {
  id: number;
  text: string | null;
  persist: boolean;
  botSpamToken: string | null;
  dates: ScheduledMessageDate[];
  files: ScheduledMessageFile[];
};
export type BotSpamFull = {
  chats: Chat[];
  messages: ScheduledMessageFull[];
  token: string;
  userId: string;
  status: boolean;
  username: string | null;
  firstName: string | null;
  photoUrl: string | null;
};
export type DefaultResponse = {
  status: boolean;
};
export type CreateBotSpamPayload = {
  token: string;
};
export type BotSpamListResponse = {
  list: BotSpamFull[];
  total: number;
};
export type BotSpamListParams = {
  range?: number[];
  sort?: string[][];
  filter?: string[][];
};
export type ToggleBotSpamPayload = {
  token: string;
};
export type RefreshBotSpamPayload = {
  token: string;
};
export type FileType = {
  mime: string;
  url: string;
};
export type CreateScheduledMessage = {
  text?: string;
  persist: boolean;
  dates: string[];
  files: FileType[];
  token: string;
};
export type UpdateScheduledMessage = {
  id: number;
  requestBody: {
    text?: string;
    persist?: boolean;
    dates?: string[];
    files?: FileType[];
  };
};
export type CommandFile = {
  id: number;
  mime: string;
  url: string;
  commandId: number | null;
};
export type CommandFull = {
  files: CommandFile[];
  id: number;
  name: string;
  description: string;
  text: string | null;
  callsCount: number;
  botAdminToken: string | null;
};
export type WelcomeMessageFile = {
  id: number;
  mime: string;
  url: string;
  welcomeMessageId: number | null;
};
export type BotAdminWelcomeMessageFull = {
  files: WelcomeMessageFile[];
  id: number;
  text: string | null;
  status: boolean;
  botAdminToken: string | null;
};
export type BotAdminFull = {
  chats: Chat[];
  messages: ScheduledMessageFull[];
  commands: CommandFull[];
  welcomeMessages: BotAdminWelcomeMessageFull[];
  token: string;
  userId: string;
  status: boolean;
  username: string | null;
  firstName: string | null;
  photoUrl: string | null;
};
export type CreateBotAdminPayload = {
  token: string;
};
export type BotAdminListResponse = {
  list: BotAdminFull[];
  total: number;
};
export type BotAdminListParams = {
  range?: number[];
  sort?: string[][];
  filter?: string[][];
};
export type ToggleBotAdminPayload = {
  token: string;
};
export type RefreshBotAdminPayload = {
  token: string;
};
export type CreateBotAdminCommandPayload = {
  name: string;
  description: string;
  files: FileType[];
  text?: string;
  token: string;
};
export type UpdateBotAdminCommandPayload = {
  id: number;
  requestBody: {
    name?: string;
    description?: string;
    files?: FileType[];
    text?: string;
  };
};
export type BotAdminCreateWelcomeMessagePayload = {
  text?: string;
  files: FileType[];
  token: string;
};
export type BotAdminUpdateWelcomeMessageContentPayload = {
  welcomeMessageId: number;
  requestBody: {
    files?: FileType[];
    text?: string;
  };
};
export type BotAdminUpdateWelcomeMessageStatusPayload = {
  welcomeMessageId: number;
  status: boolean;
};
export type Contact = {
  id: number;
  username: string | null;
  phone: string;
  firstName: string | null;
  lastName: string | null;
  status: object;
  botWelcomeAccount: string | null;
};
export type BotWelcomeFullMessages = {
  files: WelcomeMessageFile[];
  id: number;
  text: string | null;
  status: boolean;
  botWelcomeAccount: string | null;
};
export type BotWelcomeFull = {
  contacts: Contact[];
  messages: BotWelcomeFullMessages[];
  account: string;
  userId: string;
  status: boolean;
  timezone?: string;
};
export type CreateBotWelcomePayload = {
  account: string;
};
export type FinishAuthRequest = {
  account: string;
  code: string;
};
export type BotWelcomeListResponse = {
  list: BotWelcomeFull[];
  total: number;
};
export type BotWelcomeListParams = {
  range?: number[];
  sort?: string[][];
  filter?: string[][];
};
export type ToggleBotWelcomePayload = {
  account: string;
};
export type SendWelcomeMessagePayload = {
  account: string;
  contact: {
    phone?: string;
    firstName?: string;
    lastName?: string;
    username?: string;
  };
};
export type SendWelcomeMessagePayloadByServer = {
  userId: string;
  contact: {
    phone?: string;
    firstName?: string;
    lastName?: string;
    username?: string;
  };
};
export type BotWelcomeCreateWelcomeMessagePayload = {
  files: FileType[];
  text?: string;
  status?: boolean;
  account: string;
};
export type BotWelcomeUpdateWelcomeMessageContentPayload = {
  welcomeMessageId: number;
  requestBody: {
    files?: FileType[];
    text?: string;
  };
};
export type BotWelcomeUpdateWelcomeMessageStatusPayload = {
  welcomeMessageId: number;
  status: boolean;
};
export type NodeMessage = {
  id: number;
  text: string | null;
  nodeId: number;
};
export type NodeMediaGroup = {
  id: number;
  text: string | null;
  nodeId: number;
};
export type Command = {
  id: number;
  name: string;
  description: string;
  nodeId: number;
};
export type NodeFull = {
  message: NodeMessage | null;
  mediaGroup: NodeMediaGroup | null;
  command: Command | null;
  id: number;
  type: object;
  graphId: number | null;
};
export type Edge = {
  id: number;
  type: object;
  name: string | null;
  source: number;
  target: number;
  graphId: number | null;
};
export type GraphFull = {
  nodes: NodeFull[];
  edges: Edge[];
  id: number;
  name: string;
  start: number;
  status: boolean;
  botConstructorToken: string | null;
};
export type MenuButtonFile = {
  id: number;
  mime: string;
  url: string;
  menuButtonId: number | null;
};
export type MenuButtonFull = {
  files: MenuButtonFile[];
  id: number;
  name: string;
  text: string | null;
  menuId: number | null;
};
export type MenuFull = {
  buttons: MenuButtonFull[];
  id: number;
  name: string;
  status: boolean;
  botConstructorToken: string | null;
};
export type BotConstructorFull = {
  graphs: GraphFull[];
  menus: MenuFull[];
  chats: Chat[];
  token: string;
  userId: string;
  status: boolean;
  username: string | null;
  firstName: string | null;
  photoUrl: string | null;
};
export type CreateBotConstructorPayload = {
  token: string;
};
export type BotConstructorListResponse = {
  list: BotConstructorFull[];
  total: number;
};
export type BotConstructorListParams = {
  range?: number[];
  sort?: string[][];
  filter?: string[][];
};
export type ToggleBotConstructorPayload = {
  token: string;
};
export type RefreshBotConstructorPayload = {
  token: string;
};
export type CreateGraphPayload = {
  token: string;
  name: string;
  start: number;
  nodes: number[];
  edges: number[];
  status?: boolean;
};
export type UpdateGraphPayload = {
  id: number;
  requestBody: {
    name?: string;
    start?: number;
    nodes?: number[];
    edges?: number[];
    status?: boolean;
  };
};
export type CreateEdgePayload = {
  type: object;
  name?: string;
  source: number;
  target: number;
  graphId: number;
};
export type UpdateEdgePayload = {
  id: number;
  requestBody: {
    type?: object;
    name?: string;
    source?: number;
    target?: number;
  };
};
export type TimerType = {
  interval: number;
  text: string;
};
export type CreateNodeMessagePayload = {
  text?: string;
  file?: FileType;
  timer?: TimerType;
  graphId: number;
};
export type UpdateNodeMessagePayload = {
  id: number;
  requestBody: {
    text?: string;
    file?: FileType;
    timer?: TimerType;
  };
};
export type CreateNodeMediaGroupPayload = {
  text?: string;
  files?: FileType[];
  graphId: number;
};
export type UpdateNodeMediaGroupPayload = {
  id: number;
  requestBody: {
    text?: string;
    files?: FileType[];
  };
};
export type CreateCommandPayload = {
  name: string;
  description: string;
  nodeId: number;
};
export type UpdateCommandPayload = {
  id: number;
  requestBody: {
    name?: string;
    description?: string;
  };
};
export type CreateMenuPayload = {
  token: string;
  name: string;
};
export type UpdateMenuPayload = {
  id: number;
  requestBody: {
    name?: string;
    status?: boolean;
  };
};
export type CreateMenuButtonPayload = {
  name: string;
  text?: string;
  files?: object[];
  menuId: number;
};
export type UpdateMenuButtonPayload = {
  id: number;
  requestBody: {
    name?: string;
    text?: string;
    files?: object[];
  };
};
export const {
  useWelcomeBotControllerGetBotQuery,
  useCreateWelcomeBotMutation,
  useDeleteWelcomeBotUserMutation,
  useWelcomeBotUserValidateMutation,
  useWelcomeBotAuthMutation,
  useWelcomeBotControllerDeleteBotMutation,
  useWelcomeBotControllerGetBotListQuery,
  useWelcomeBotControllerToggleBotMutation,
} = injectedRtkApi;
