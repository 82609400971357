import { FC, useEffect, useRef } from 'react';
import SearchField from 'components/SearchField';
import { setVkGroupsParams } from '../../vk.reducer';
import useAppDispatch from 'hooks/useAppDispatch';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNull } from 'lodash-es';
import { useGetVkGroupParams } from '../../vk.selectors';

type Props = {
  className: string;
};

const GroupsSearch: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { name: defaultValue } = useGetVkGroupParams();
  const querySet = useRef(new Set([defaultValue]));

  useEffect(() => {
    const name = queryParams.get('name') ?? '';
    let offset: number;
    if (isNull(queryParams.get('offset'))) {
      offset = 0;
    } else {
      offset = Number(queryParams.get('offset'));
    }
    dispatch(setVkGroupsParams({ name, offset }));
  }, []);

  return (
    <SearchField
      className={className}
      placeholder="Найти сообщества"
      defaultValue={defaultValue}
      onSearch={searchQuery => {
        if (searchQuery) {
          if (querySet.current.has(searchQuery)) {
            queryParams.set('name', searchQuery);
            navigate({ search: queryParams.toString() });
            dispatch(setVkGroupsParams({ name: searchQuery }));
          } else {
            querySet.current.clear();
            queryParams.set('name', searchQuery);
            queryParams.set('offset', '0'); // Reset offset for new search
            queryParams.set('page', `1`);
            navigate({ search: queryParams.toString() });
            dispatch(setVkGroupsParams({ name: searchQuery }));
          }
        } else {
          queryParams.set('name', '');
          navigate({ search: queryParams.toString() });
          dispatch(setVkGroupsParams({ name: '' }));
        }
      }}
      debounceDelay={1500}
    />
  );
};

export default GroupsSearch;
