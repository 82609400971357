import { FC, useMemo, useRef } from 'react';
import dayjs from 'dayjs';

import styles from './TransactionsTable.module.scss';
import formatNumber from 'utils/formatNumber';
import env from 'config/env';
import TableGeneric from 'components/TableGeneric';
import { ETransactionStatus, UserTransaction } from 'modules/finances/finances.types';
import useAppDispatch from 'hooks/useAppDispatch';
import { useSelectFinancesFilters } from 'modules/finances/finances.selectors';
import { setFilter } from 'modules/finances/finances.reducer';
import { ComplexColumn } from 'components/TableGeneric/TableGeneric.types';
import { TransactionTypes } from './components/TransactionTypes';
import { TransactionStatuses } from './components/TransactionStatuses';
import TransactionEntry from './components/TransactionEntry';
import { TRANSACTION_TYPES_NAMES } from '../../finances.constants';

type Props = {
  items: UserTransaction[];
};

type TTypes = {
  [key: string]: {
    name: string;
    elem: JSX.Element;
  };
};

const TransactionsTable: FC<Props> = ({ items }) => {
  const dispatch = useAppDispatch();
  const filters = useSelectFinancesFilters();
  const hasTypesSet = useRef(new Set(Object.values(TransactionTypes).map(obj => obj.name)));

  const transactionTypeMap = useMemo(() => {
    return items.reduce(
      (acc, cur) => {
        if (!acc.setArr.has(cur.type)) {
          acc.map.set(`type${cur.id}`, {
            name: cur.type,
            elem: <TransactionEntry text={cur.type} />,
          });
          acc.setArr.add(cur.type);
        }
        return acc;
      },
      {
        map: new Map(),
        setArr: new Set(hasTypesSet.current),
      },
    );
  }, [items]);

  const combined: TTypes = {
    ...Object.fromEntries(transactionTypeMap.map),
    ...TransactionTypes,
  };

  const columns = useMemo<ComplexColumn<UserTransaction>[]>(
    () => [
      {
        header: 'Дата и время',
        accessor: 'createdAt',
        accessorFn: ({ createdAt }) => {
          return (
            <div className={styles.Text}>
              <span>{dayjs.utc(createdAt).local().format('DD.MM.YYYY')}</span>
              <span className={styles.Time}>{dayjs.utc(createdAt).local().format('HH:mm')}</span>
            </div>
          );
        },
      },
      {
        header: 'Вид операции',
        accessor: 'type',
        accessorFn: ({ type }) => {
          return Object.values(combined).find(({ name }) => name === type)?.elem;
        },
        filters: Object.entries(TRANSACTION_TYPES_NAMES).map(([value, name]) => ({ name, value })),
      },
      {
        header: 'Статус',
        accessor: 'status',
        accessorFn: ({ status }) => TransactionStatuses[status as ETransactionStatus]?.elem,
        filters: Object.entries(TransactionStatuses).map(([value, { name }]) => ({ name, value })),
      },
      {
        header: 'Сумма',
        accessor: 'amount',
        accessorFn: ({ amount }) => (
          <span className={styles.Text}>{`${formatNumber(amount)} ${env.CURRENCY}`}</span>
        ),
      },
    ],
    [],
  );

  return (
    <TableGeneric
      className={styles.Table}
      columns={columns}
      data={items}
      filters={{
        type: {
          selected: filters.type,
          onChange: value => dispatch(setFilter({ field: 'type', value })),
        },
        status: {
          selected: filters.status,
          onChange: value => dispatch(setFilter({ field: 'status', value })),
        },
      }}
      type={'transactions'}
    />
  );
};

export default TransactionsTable;
