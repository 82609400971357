import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './Card.module.scss';
import LinkOverlay from 'components/LinkOverlay';
import Icon from 'components/Icon';

type Props = {
  children: React.ReactNode;
  dataPlan?: string;
  planCycle?: string;
  className?: string;
  link: string;
};

const Card: FC<Props> = ({ children, className, dataPlan, planCycle, link }) => {
  return (
    <article
      className={classNames(styles.Card, className)}
      data-plan={dataPlan}
      data-plan-cycle={planCycle}
    >
      {children}

      <LinkOverlay to={link} />
      <Icon className={styles.MobileIcon} iconName="arrow-right" size={14} />
    </article>
  );
};

export default Card;
