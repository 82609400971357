import { CreateBotAdminCommandPayload } from './codegen/telegram.admin.api';
import { CreateBotSpamScheduledMessage } from './codegen/telegram.spam.api';
import { isNull } from 'lodash-es';
import dayjs from 'dayjs';
import { SendHistory } from './telegram.types';

export const getDefaultSpamMessage = (): CreateBotSpamScheduledMessage => ({
  dates: [],
  files: [],
  persist: false,
  text: "5 key components for aspiring designers that shape stylish design. Practice in Figma and create our first layout. That's all we're going to do in this lesson, buddy! Practice in Figma and create our first layout components for aspiring designers",
});

export const getDefaultCommand = (): CreateBotAdminCommandPayload => ({
  description: 'Displays all commands',
  files: [],
  name: 'help',
});

export const urlOffset = (param: string | null): number => {
  let offset: number;
  if (isNull(param)) {
    offset = 0;
  } else {
    offset = Number(param);
  }
  return offset;
};

export const getFileName = (fileType: File) => {
  let fullName: string;
  const fileName = fileType.name;
  const fileExtension = fileName.split('.').pop();
  if (fileType.name.length > 3) {
    fullName = fileType.name.slice(0, 10);
  } else {
    fullName = fileType.name;
  }

  return fullName + '.' + fileExtension;
};

export const getPersistedState = (stateName: string) => {
  const statePersist = localStorage.getItem('persist:root');
  const parsed = statePersist ? JSON.parse(statePersist) : {};
  return parsed ? JSON.parse(parsed[stateName]) : {};
};

export class SimpleCipher {
  private static key = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  private static reverseKey = SimpleCipher.key.split('').reverse().join('');

  public static encrypt(text: string): string {
    let encrypted = '';
    for (let i = 0; i < text.length; i++) {
      const char = text.charAt(i);
      const index = SimpleCipher.key.indexOf(char);
      if (index !== -1) {
        encrypted += SimpleCipher.reverseKey.charAt(index);
      } else {
        encrypted += char;
      }
    }
    return encrypted;
  }

  public static decrypt(encryptedText: string): string {
    let decrypted = '';
    for (let i = 0; i < encryptedText.length; i++) {
      const char = encryptedText.charAt(i);
      const index = SimpleCipher.reverseKey.indexOf(char);
      if (index !== -1) {
        decrypted += SimpleCipher.key.charAt(index);
      } else {
        decrypted += char;
      }
    }
    return decrypted;
  }
}

export const getDatesDiff = (d: string) => {
  const date1 = dayjs(d);
  const date2 = dayjs();

  let hours = date1.diff(date2, 'hours');
  const days = Math.floor(hours / 24);
  hours = hours - days * 24;

  return { hours, days };
};

export function findFirstError(messages: SendHistory[]): SendHistory | undefined {
  return messages.find(message => !message.hasError);
}
