import { CSSProperties, FC } from 'react';

import styles from './SocialNetwork.module.scss';
import {
  Component,
  EditorMode,
  ESocialNetwork,
  SocialNetwork as TSocialNetwork,
} from 'modules/my-pages/my-pages.types';
import { SOCIAL_NETWORKS_NAMES } from 'modules/my-pages/my-pages.constants';
import Icon from 'components/Icon';
import useOnClick from 'modules/my-pages/hooks/useOnClick';
import { useRegisterMetricsMutation } from 'modules/landing-dashboard/landing-dashboard.api';
import { ElemType } from 'modules/cards/cards.types';

type SocialNetworkProps = {
  icon: string;
  iconClassName?: string;
} & CSSProperties;

const SOCIAL_NETWORK_PROPS: Record<
  ESocialNetwork,
  { fill: SocialNetworkProps; stroke: SocialNetworkProps }
> = {
  [ESocialNetwork.INSTAGRAM]: {
    fill: {
      icon: 'insta-outline',
      iconClassName: styles.IconFill,
      background:
        'linear-gradient(95.75deg, #FEB100 0%, #FD2F3D 28.27%, #B305F2 79.91%, #7335F9 104.37%)',
      boxShadow: '0px 0px 24px rgba(235, 37, 107, 0.36)',
    },
    stroke: {
      icon: 'insta-outline',
      iconClassName: styles.IconInstaStroke,
      background:
        'linear-gradient(95.75deg, #FEB100 0%, #FD2F3D 28.27%, #B305F2 79.91%, #7335F9 104.37%)',
      filter: 'drop-shadow(0px 0px 24px rgba(235, 37, 107, 0.36))',
    },
  },
  [ESocialNetwork.TELEGRAM]: {
    fill: {
      icon: 'tg-outline',
      iconClassName: styles.IconFill,
      background: 'linear-gradient(95.27deg, #5CA7D6 7.66%, #0566A5 93.08%)',
      boxShadow: '0px 0px 24px rgba(1, 119, 255, 0.36)',
    },
    stroke: {
      icon: 'tg-outline',
      iconClassName: styles.IconTgStroke,
      background: 'linear-gradient(95.3deg, #5DA7D7 2.45%, #002D61 67.47%, #5DA7D7 100%)',
      filter: 'drop-shadow(0px 0px 24px rgba(1, 119, 255, 0.35))',
    },
  },
  [ESocialNetwork.VIBER]: {
    fill: {
      icon: 'viber',
      iconClassName: styles.IconFill,
      background: 'linear-gradient(95.27deg, #7360F2 7.66%, #5944E3 93.08%)',
      boxShadow: '0px 0px 24px rgba(113, 94, 241, 0.36)',
    },
    stroke: {
      icon: 'viber',
      iconClassName: styles.IconViberStroke,
      background: 'linear-gradient(95.27deg, #7360F2 7.66%, #1F1177 66.1%, #5944E3 93.08%)',
      filter: 'drop-shadow(0px 0px 24px rgba(113, 94, 241, 0.36))',
    },
  },
  [ESocialNetwork.VK]: {
    fill: {
      icon: 'navigation-vk-filled',
      iconClassName: styles.IconFill,
      background: 'linear-gradient(95.27deg, #0077FF 7.66%, #0653AA 93.08%)',
      boxShadow: '0px 0px 24px rgba(1, 119, 255, 0.36)',
    },
    stroke: {
      icon: 'navigation-vk-filled',
      iconClassName: styles.IconVkStroke,
      background: 'linear-gradient(95.3deg, #0077FF 2.45%, #002D61 67.47%, #0653AA 100%)',
      filter: 'drop-shadow(0px 0px 24px rgba(1, 119, 255, 0.35))',
    },
  },
  [ESocialNetwork.WHATSAPP]: {
    fill: {
      icon: 'whatsapp-outline',
      iconClassName: styles.IconFill,
      background: 'linear-gradient(95.27deg, #25D366 7.66%, #0EA747 69.96%)',
      boxShadow: '0px 0px 24px rgba(30, 215, 49, 0.36)',
    },
    stroke: {
      icon: 'whatsapp-outline',
      iconClassName: styles.IconWhatsappStroke,
      background: 'linear-gradient(95.32deg, #25D366 7.66%, #06431D 54.14%, #0EA747 96.89%)',
      filter: 'drop-shadow(0px 0px 24px rgba(30, 215, 49, 0.36))',
    },
  },
};

type Props = {
  component: Component<TSocialNetwork>;
  mode?: EditorMode;
};

const SocialNetwork: FC<Props> = ({ component, mode }) => {
  const { data } = component;
  const [registerMetric] = useRegisterMetricsMutation();
  const onClick = useOnClick(data.onClick);
  const { icon, iconClassName, ...style } =
    SOCIAL_NETWORK_PROPS[data.type][data.filled ? 'fill' : 'stroke'];

  return (
    <div className={styles.Wrapper} data-alignment={data?.alignment?.toLowerCase()}>
      <button
        className={styles.Button}
        style={style}
        data-block-style={data?.blockStyle?.toLowerCase()}
        data-has-border={data.hasBorder}
        onClick={async () => {
          if (mode === 'published')
            await registerMetric({ id: component.id as string, type: ElemType.LINK }).unwrap();
          if (mode === 'edit') {
            return null;
          } else {
            return onClick();
          }
        }}
      >
        <div className={styles.Content} data-filled={data.filled}>
          <Icon className={iconClassName} iconName={icon} size={14} />
          {SOCIAL_NETWORKS_NAMES[data.type]}
        </div>
      </button>
    </div>
  );
};

export default SocialNetwork;
