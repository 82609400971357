import { FC } from 'react';

import styles from './Header.module.scss';
import GoBack from 'components/GoBack';
import MyPagesUndoRedo from './MyPagesUndoRedo';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';
import PageActions from '../PageActions';
import { useNavigate } from 'react-router-dom';

const Header: FC = () => {
  const page = useActivePage();
  const navigate = useNavigate();
  const openModal = () => {
    navigate(-1);
  };

  return (
    <header className={styles.Header}>
      <GoBack backWord={page?.name} staticPos onClick={openModal} />
      <PageActions />
      <MyPagesUndoRedo />
    </header>
  );
};

export default Header;
