import { FC } from 'react';

import styles from './Update.module.scss';
import Icon from 'components/Icon';

type Props = {
  onUpdate: () => void;
};

const Update: FC<Props> = ({ onUpdate }) => {
  return (
    <button className={styles.Update} onClick={onUpdate}>
      <Icon className={styles.Icon} iconName="update" size={16} /> Обновить
    </button>
  );
};

export default Update;
