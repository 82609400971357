import { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './LinkUnderlined.module.scss';
import { TableCellValue } from 'components/Table';
import IconButton from 'components/IconButton';

type Props = {
  btnText: 'Подробнее' | 'Просмотреть' | 'Сменить' | TableCellValue;
  link?: string;
  onClick?: () => void;
  className?: string;
};

const LinkUnderlined: FC<Props> = ({ btnText, className, onClick, link }) => {
  return (
    <div className={classNames(styles.Wrapper, className)}>
      {link ? (
        <Link to={link} className={classNames(styles.Link)}>
          {btnText}
        </Link>
      ) : (
        <span className={classNames(styles.Link)} onClick={onClick}>
          {btnText}
        </span>
      )}
      <IconButton iconName="arrow-right" iconSize={9} iconClassName={styles.Icon} />
    </div>
  );
};

export default LinkUnderlined;
