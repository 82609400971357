import { FC, ReactNode } from 'react';
import styles from './HeadTitle.module.scss';

type Props = {
  children: ReactNode | string;
};

const HeadTitle: FC<Props> = ({ children }) => {
  return <h1 className={styles.Title}>{children}</h1>;
};

export default HeadTitle;
