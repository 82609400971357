import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from 'i18n';

import { NewPassRequestBody } from 'modules/auth/auth.types';
import { newPassSchema } from 'modules/auth/auth.schema';
import r from 'constants/routes';
import { useRestorePasswordMutation } from 'modules/auth/auth.api';

import PasswordInput from 'components/PasswordInput';
import AuthForm from 'modules/auth/components/AuthForm';
import AccountRecovered from 'modules/auth/components/AccountRecovered';

const NewPassWord: FC = () => {
  const { hash } = useParams();
  const [restorePassword] = useRestorePasswordMutation();

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const onEnter = () => {
    navigate(r.auth.signIn);
  };

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<NewPassRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(newPassSchema()),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit: SubmitHandler<NewPassRequestBody> = async ({ password }) => {
    const res = await restorePassword({ token: hash ?? '', newPassword: password });

    if ('data' in res) {
      setSuccess(true);
    }
  };

  return success ? (
    <AccountRecovered />
  ) : (
    <AuthForm
      title="Новый пароль"
      buttonText="Сбросить пароль"
      footer={{ text: 'Назад к', btn: { text: 'Войти', onClick: onEnter } }}
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
      text="Придумайте пароль, который ранее не был использован"
    >
      <PasswordInput
        label="Пароль"
        register={register('password')}
        errorMsg={errors.password?.message}
      />
      <PasswordInput
        label="Подтвердить пароль"
        register={register('passwordConfirm')}
        invalid={!!errors.passwordConfirm}
        errorMsg={i18n.t('validations.mixed.confirmPassword')}
      />
    </AuthForm>
  );
};

export default NewPassWord;
