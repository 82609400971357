import { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './LinkOverlay.module.scss';

type Props = {
  to: string;
};

const LinkOverlay: FC<Props> = ({ to }) => {
  return <Link className={styles.Link} to={to} />;
};

export default LinkOverlay;
