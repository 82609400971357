import { FC } from 'react';
import classNames from 'classnames';
import { Reorder, useDragControls, useMotionValue } from 'framer-motion';

import styles from './DraggableComponent.module.scss';
import { AnyComponent } from 'modules/my-pages/my-pages.types';
import IconButton from 'components/IconButton';
import useAppDispatch from 'hooks/useAppDispatch';
import { removeComponent, setActiveComponent } from 'modules/my-pages/my-pages.reducer';
import useIsDragging from 'hooks/useIsDragging';
import { getComponentNameAndValue } from 'modules/my-pages/my-pages.helpers';
import formatLongText from 'utils/formatLongTrxt';

type Props = {
  component: AnyComponent;
};

const DraggableComponent: FC<Props> = ({ component }) => {
  const dispatch = useAppDispatch();
  const controls = useDragControls();
  const name = getComponentNameAndValue(component);
  const y = useMotionValue(0);
  const isDragging = useIsDragging(y);

  const onEdit = () => dispatch(setActiveComponent(component.hash));

  const onDelete = () => dispatch(removeComponent({ hash: component.hash }));

  return (
    <Reorder.Item
      value={component}
      dragListener={false}
      dragControls={controls}
      className={styles.Wrapper}
      style={{ y }}
      data-dragging={isDragging}
    >
      <div className={styles.Info}>
        <div className={styles.DragPad} onPointerDown={e => controls.start(e)}>
          <div className={styles.Line} data-top />
          <div className={styles.Line} data-middle />
          <div className={styles.Line} data-bottom />
        </div>

        <div className={styles.Title}>{name.title}</div>
        <div className={styles.Subtitle}>{formatLongText(name.value, 24)}</div>
      </div>

      <div className={styles.Controls}>
        <IconButton
          className={styles.Icon}
          iconName="edit"
          iconSize={18}
          onClick={onEdit}
          disabled={isDragging}
        />
        <IconButton
          className={classNames(styles.Icon, styles.Trash)}
          iconName="trash-2"
          iconSize={18}
          onClick={onDelete}
          disabled={isDragging}
        />
      </div>

      <IconButton
        className={styles.Options}
        iconName="options"
        size={24}
        iconSize={24}
        onClick={onEdit}
        disabled={isDragging}
      />
    </Reorder.Item>
  );
};

export default DraggableComponent;
