import toast from 'toasts';

import copyToClipboard from 'utils/copyToClipboard';

type CopyToClipboardFunc = typeof copyToClipboard;

function useCopyToClipboard(): {
  copyToClipboard: CopyToClipboardFunc;
  copyAndNotify: CopyToClipboardFunc;
} {
  const copyAndNotify: CopyToClipboardFunc = async text => {
    const success = await copyToClipboard(text);

    if (success) {
      toast.success('Скопировано!');
    } else {
      toast.error('Ошибка во время копирования');
    }

    return success;
  };

  return {
    copyToClipboard,
    copyAndNotify,
  };
}

export default useCopyToClipboard;
