import { FC } from 'react';
import styles from './NoPaymentHistoryBlock.module.scss';
import { NoPaymentIcon } from './icons/NoPaymentIcon';
import { NoPaymentHistoryBlockTypes } from './NoPaymentHistoryBlock.types';
import NoPaymentMobileIcon from './icons/NoPaymentMobileIcon';

export const NoPaymentHistoryBlock: FC<NoPaymentHistoryBlockTypes> = ({ type }) => {
  const mobile = type === 'mobile';
  return (
    <div className={mobile ? styles.NoPayHistoryWrapperMobile : styles.NoPayHistoryWrapper}>
      {mobile ? <NoPaymentMobileIcon /> : <NoPaymentIcon />}
      <div className={styles.NoPaymentTextBlock}>
        <h4>
          У вас пока нет истории платежей. Пополнение, списание и остальные операции будут
          отображаться тут.
        </h4>
      </div>
    </div>
  );
};
