import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import styles from './BotCard.module.scss';
import Icon from 'components/Icon';
import Menu from 'components/Menu';
import r from 'constants/routes';

type Props = {
  id: number;
  avatar: string;
  username: string;
  created: string;
  scenarios: number;
};

const BotCard: FC<Props> = ({ avatar, username, created, scenarios }) => {
  const navigate = useNavigate();

  const onDuplicate = () => {};
  const onRename = () => {};
  const onShare = () => {};
  const onDelete = () => {};

  return (
    <article
      className={styles.Card}
      onClick={() => navigate(generatePath(r.botBuilder.scenarios.scenarios, { id: '1' }))}
    >
      <div className={styles.Header}>
        <img className={styles.Avatar} src={avatar} alt="Avatar" width={36} height={36} />
        <div className={styles.Scenarios}>
          {scenarios} <Icon className={styles.Icon} iconName="branch" size={16} />
        </div>
      </div>
      <div className={styles.Footer}>
        <div className={styles.Info}>
          <div className={styles.Username}>{username}</div>
          <time className={styles.Time}>Создан: {dayjs(created).format('DD.MM.YYYY')}</time>
        </div>
        <Menu
          className={styles.Menu}
          contextClassName={styles.Context}
          items={[
            { label: 'Дублировать', isSelected: false, onClick: onDuplicate },
            { label: 'Переименовать', isSelected: false, onClick: onRename },
            { label: 'Поделиться', isSelected: false, onClick: onShare },
            { label: 'Удалить', isSelected: false, onClick: onDelete, className: styles.Delete },
          ]}
          iconName="options-vertical"
          iconSize={24}
        >
          <Icon iconName="options-vertical" size={24} />
        </Menu>
      </div>
    </article>
  );
};

export default BotCard;
