export type LandingIdType = string | number;

export type Ordered = {
  order?: number;
};

export enum EShape {
  CIRCLE,
  SQUARE,
  RECTANGLE,
}

export type LandingPhoto = Ordered & {
  url: string;
  shape: EShape;
};

export type TextAlignment = 'left' | 'center' | 'right';

export type TextProperties = Ordered & {
  content: string;
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  color: string;
  alignment: TextAlignment;
};

export type LandingVideo = Ordered & {
  link: string;
  title?: TextProperties;
  text?: TextProperties;
};

export type LandingVideoText = keyof Omit<LandingVideo, 'link'>;

export type LandingVideoForm = Ordered & {
  link: string;
  title: string;
  text: string;
};

export type LandingText = Ordered & {
  title: TextProperties[];
  subtitle: TextProperties[];
  text: TextProperties[];
};

export type LandingTextForm = {
  title: Pick<TextProperties, 'content'>[];
  subtitle: Pick<TextProperties, 'content'>[];
  text: Pick<TextProperties, 'content'>[];
};

export enum EOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export type LandingModal = {
  buttonName: string;
  buttonColor?: string;
};

export type Website = {
  title: TextProperties;
  text: TextProperties;
  url: string;
  color?: string;
};

export type SocialNetworkProperties = Ordered & {
  content: string;
  color?: string;
  shape: EShape;
};

export type LandingWebsiteForm = {
  title: string;
  text: string;
  url: string;
};

export type LandingButton = Ordered & {
  id: Landing['id'];
  vk?: SocialNetworkProperties;
  viber?: SocialNetworkProperties;
  whatsapp?: SocialNetworkProperties;
  instagram?: SocialNetworkProperties;
  facebook?: SocialNetworkProperties;
  telegram?: SocialNetworkProperties;
  orientation: EOrientation;
  modal?: LandingModal;
  websites: Website[];
};

export type LandingButtonWithoutId = Omit<LandingButton, 'id'>;

export type LandingButtonSocialNetwork = keyof Pick<
  Required<LandingButton>,
  'vk' | 'viber' | 'whatsapp' | 'instagram' | 'facebook' | 'telegram'
>;

export type LandingButtonForm = {
  vk?: string;
  viber?: string;
  whatsapp?: string;
  instagram?: string;
  facebook?: string;
  telegram?: string;
  orientation: EOrientation;
  inModal: boolean;
  modalText?: string;
  websites: LandingWebsiteForm[];
};

export type LandingFormField = { [k in LandingFormFormFields]?: string | null };

export type LandingForm = Ordered & {
  title: TextProperties;
  text: TextProperties;
  fields: LandingFormField;
  buttonName: string;
  modal?: LandingModal;
};

export type Landing = {
  id: LandingIdType;
  name: string;
  photo?: LandingPhoto | null;
  video?: LandingVideo | null;
  text: LandingText;
  buttons: LandingButton[];
  form?: LandingForm | null;
  color?: string;
};

type LandingFlatten = Omit<Landing, 'id' | 'color' | 'name'>;

export type LandingArrayKeys = keyof LandingFlatten;
export type LandingArrayValues = Exclude<LandingFlatten[LandingArrayKeys], null | undefined>;

type LandingFlattenUnwrapped = Omit<Landing, 'id' | 'color' | 'name' | 'text'> & LandingText;

export type LandingArrayKeysUnwrapped = keyof LandingFlattenUnwrapped;
export type LandingArrayValuesUnwrapped = Exclude<
  LandingFlattenUnwrapped[LandingArrayKeysUnwrapped],
  null | undefined
>;
export type LandingElement =
  | { type: 'photo'; value: LandingPhoto; index: undefined }
  | { type: 'video'; value: LandingVideo; index: undefined }
  | { type: 'button'; value: LandingButton; index: undefined }
  | { type: 'form'; value: LandingForm; index: undefined }
  | { type: 'title' | 'subtitle' | 'text'; value: TextProperties; index: number };
export type LandingArray = Array<LandingElement>;

export type LandingNormalized = {
  name: string;
  photo?: LandingPhoto | null;
  video?: LandingVideo | null;
  text: LandingText;
  buttons: Omit<LandingButton, 'id'>[];
  form?: LandingForm | null;
};

export type LandingGeneratorSliceState = {
  landings: Landing[];
};

export type LandingGeneratorSettings = {
  name: string;
  link: string;
};

export const LandingFormFormFieldsArr = [
  'firstname',
  'lastname',
  'phone',
  'email',
  'dateOfBirth',
  'country',
  'position',
  'extra',
  'other',
] as const;
export type LandingFormFormFields = typeof LandingFormFormFieldsArr[number];

export type LandingFormForm = {
  title: string;
  text: string;
  fields: LandingFormField;
  buttonName: string;
  inModal: boolean;
  modalText?: string;
};

export enum ELandingLinkType {
  IN_PATH = 'in-path',
  IN_URL = 'in-url',
}

export type PublishLandingForm = {
  type: ELandingLinkType;
  username: string;
};

export type PublishSheetRequest = {
  sheetId: number | string;
  publishedName: string;
};
