import { FC } from 'react';

import Icon from 'components/Icon';
import useDisclosure from 'hooks/useDisclosure';
import useWindowSize from 'hooks/useWindowSize';
import Button from 'components/Button';
import Modal from 'components/Modal';
import styles from './Tutorials.module.scss';

interface TutorialsProps {
  label?: string;
  small?: boolean;
  windowSizeSmall?: number;
  tutorials: { label: string; url: string }[];
}

const Tutorials: FC<TutorialsProps> = ({
  label = 'Видеоинструкции',
  windowSizeSmall = 600,
  small = false,
  tutorials = [],
}) => {
  const { width } = useWindowSize();
  const { open, onClose, onOpen } = useDisclosure();

  const handleOnClick = () => {
    if (tutorials.length > 1) {
      onOpen();
    } else if (tutorials.length === 1) {
      window.open(tutorials[0].url, '_blank')?.focus();
    }
  };

  return (
    <>
      <button type="button" onClick={handleOnClick} className={styles.Container}>
        {width >= windowSizeSmall && !small ? <div className={styles.Label}>{label}</div> : null}
        <Icon className={styles.Icon} iconName="video-circle" size={24} />
      </button>
      {open && tutorials.length > 1 ? (
        <Modal isOpen onClose={onClose} noBtn>
          <div className={styles.Header}>
            <h2>{label}</h2>
            <Button variant="text" className={styles.Close} onClick={onClose}>
              <Icon iconName="close" size={18} />
            </Button>
          </div>
          <div className={styles.List}>
            {tutorials.map(({ label: itemLabel, url }, index) => (
              <a href={url} key={index} target="_blank" className={styles.ListItem}>
                <div className={styles.ListItemLabel}>{itemLabel}</div>
                <Icon className={styles.Icon} iconName="video-circle" size={24} />
              </a>
            ))}
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default Tutorials;
