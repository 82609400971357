import { FC } from 'react';
import classNames from 'classnames';

import styles from './ImgInputText.module.scss';

type Props = {
  isError: boolean;
};

const ImgInputText: FC<Props> = ({ isError }) => {
  const getErrorLimit = () => {
    return (
      <p className={classNames(styles.InputText, styles.Error)}>Превышен размер файла: 2,5 MB</p>
    );
  };

  const getNormal = () => {
    return <p className={styles.InputText}>Максимальный размер файла: 2,5 MB</p>;
  };

  return <>{isError ? getErrorLimit() : getNormal()}</>;
};

export default ImgInputText;
