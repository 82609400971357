import { FC } from 'react';
import Button from 'components/Button';
import CommandModal from '../CommandModal';
import useDisclosure from 'hooks/useDisclosure';

const CreateCommandButton: FC = () => {
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });

  return (
    <>
      <Button onClick={onOpen} size={'l'} variant={'magic'}>
        Создать команду
      </Button>
      {open && <CommandModal isOpen={open} onClose={onClose} />}
    </>
  );
};

export default CreateCommandButton;
