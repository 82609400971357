import { FC } from 'react';

import styles from './FormRequestItem.module.scss';
import { FormGetRequest } from 'modules/landing-dashboard/landing-dashboard.types';
import Accordion from 'components/Accordion';
import DateTime from 'components/DateTime';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { PhoneSvg } from 'components/TableGeneric/svg/phone';
import EmailSvg from 'components/TableGeneric/svg/email';

const FormRequestItem: FC<FormGetRequest> = ({ id, firstName, lastName, created, ...fields }) => {
  const { copyAndNotify } = useCopyToClipboard();

  const getIcon = (field: string) => {
    if (field === 'phone') {
      return <PhoneSvg className={styles.svgIcon} />;
    }

    if (field === 'email') {
      return <EmailSvg className={styles.svgIcon} />;
    }

    return null;
  };

  return (
    <Accordion
      wrapperClassName={styles.Accordion}
      contentClassName={styles.Content}
      contentMargin={16}
      trigger={isOpen => (
        <div className={styles.Container} data-open={isOpen}>
          <div className={styles.Wrapper}>
            <div className={styles.Info}>
              <p className={styles.FullName}>
                {firstName} {lastName}
              </p>
              <DateTime className={styles.Created} date={created} size={12} noDivider />
            </div>
          </div>
          <div className={styles.Trigger}>
            <span className={styles.Label}>Данные</span>
            <Icon className={styles.Icon} iconName="arrow-up" size={12} />
          </div>
        </div>
      )}
    >
      {Object.entries(fields).map(([key, value], index) => {
        if (typeof value !== 'boolean') {
          const createdLocalDate = key == 'createdLocalDate';
          return (
            !createdLocalDate && (
              <div key={index} className={styles.Field}>
                <div className={styles.ValueWrapper}>
                  {getIcon(key)}
                  <p className={styles.Value}>{value}</p>
                </div>
                <IconButton
                  className={styles.CopyIcon}
                  iconName="copy"
                  iconSize={20}
                  size={20}
                  onClick={() => copyAndNotify(value as string)}
                />
              </div>
            )
          );
        }
      })}
    </Accordion>
  );
};

export default FormRequestItem;
