import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/react';
import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';

import 'styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from 'config/store';
import env from 'config/env';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (env.SENTRY_DNS) {
  Sentry.init({
    dsn: env.SENTRY_DNS,
    release: env.APP_VERSION,
    environment: env.ENVIRONMENT,
    integrations: [new Sentry.Replay({ maskAllText: false, blockAllMedia: false })],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

root.render(
  <Provider store={store}>
    <PersistGate loading={<div>Persistor loading...</div>} persistor={persistor}>
      <BrowserRouter>
        <DndProvider backend={HTML5Backend}>
          <App />
          <ToastContainer />
        </DndProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
