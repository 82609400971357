import styles from './Content.module.scss';
import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};
const Content: FC<Props> = ({ children }) => {
  return <div className={styles.Content}>{children}</div>;
};

export default Content;
