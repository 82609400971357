import { useEffect, useRef } from 'react';
import HeadTitle from '../HeadTitle';
import SearchField from 'components/SearchField';
import ChatTable from './ChatTable';
import useAppDispatch from 'hooks/useAppDispatch';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './ChatList.module.scss';
import { isNull } from 'lodash-es';
import { useAdminBotsChatParams } from '../../telegram.selectors';
import { setAdminChatsParams } from '../../codegen/telegram.admin.reducer';

const ChatList = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { chatName: defaultValue } = useAdminBotsChatParams();
  const querySet = useRef(new Set([defaultValue]));

  useEffect(() => {
    const chatName = queryParams.get('chatName') ?? '';
    let offset: number;
    if (isNull(queryParams.get('offset'))) {
      offset = 0;
    } else {
      offset = Number(queryParams.get('offset'));
    }
    dispatch(setAdminChatsParams({ chatName, offset }));
  }, []);

  return (
    <div className={styles.Main}>
      <div className={styles.Head}>
        <HeadTitle>Чаты</HeadTitle>
        <SearchField
          placeholder={'Поиск'}
          onSearch={searchQuery => {
            if (searchQuery) {
              if (querySet.current.has(searchQuery)) {
                queryParams.set('chatName', searchQuery);
                navigate({ search: queryParams.toString() });
                dispatch(setAdminChatsParams({ chatName: searchQuery }));
              } else {
                querySet.current.clear();
                queryParams.set('chatName', searchQuery);
                queryParams.set('offset', '0'); // Reset offset for new search
                queryParams.set('page', `1`);
                navigate({ search: queryParams.toString() });
                dispatch(setAdminChatsParams({ chatName: searchQuery }));
              }
            } else {
              queryParams.set('chatName', '');
              navigate({ search: queryParams.toString() });
              dispatch(setAdminChatsParams({ chatName: '' }));
            }
          }}
          debounceDelay={1500}
        />
      </div>
      <ChatTable />
    </div>
  );
};

export default ChatList;
