import React, { FC, Fragment, ReactElement, ReactNode, Ref, useEffect, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';

import styles from './BottomSheet.module.scss';
import Icon from 'components/Icon';
import { useOpenModal } from 'modules/my-pages/my-pages.selectors';
import { setOpenSettingsModal } from '../../modules/my-pages/my-pages.reducer';
import useAppDispatch from '../../hooks/useAppDispatch';
type Props = {
  trigger?: ReactNode;
  isOpen?: boolean;
  children: ReactElement;
  type?: string;
};

type ButtonRefType = HTMLButtonElement;

const BottomSheet: FC<Props> = ({ trigger, children, type, isOpen }) => {
  const test = useOpenModal();
  const buttonRef: Ref<ButtonRefType> = useRef(null);
  const dispatch = useAppDispatch();

  const handlePopoverModal = () => {
    buttonRef?.current?.click();
  };

  useEffect(() => {
    if (type === 'settings' && test) {
      handlePopoverModal();
    }
  }, [test]);

  return (
    <Popover className={styles.Wrapper}>
      {({ open, close }) => {
        const handleClose = () => {
          dispatch(setOpenSettingsModal(false));
          close();
        };
        return (
          <div>
            {trigger && (
              <Popover.Button ref={buttonRef} className={styles.Btn}>
                {trigger}
              </Popover.Button>
            )}
            <Transition
              show={isOpen || open}
              as={Fragment}
              enter={styles.Enter}
              enterFrom={styles.EnterFrom}
              enterTo={styles.EnterTo}
              leave={styles.Leave}
              leaveFrom={styles.EnterTo}
              leaveTo={styles.EnterFrom}
            >
              <Popover.Panel static className={styles.Panel}>
                <>
                  <Popover.Button className={styles.Close}>
                    <Icon
                      className={styles.Cross}
                      iconName="cross"
                      size={24}
                      onClick={handleClose}
                    />
                  </Popover.Button>
                  <div>{React.cloneElement(children, { onClose: close })}</div>
                </>
              </Popover.Panel>
            </Transition>
          </div>
        );
      }}
    </Popover>
  );
};

export default BottomSheet;
