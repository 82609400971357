import { FC } from 'react';
import TableChatRow from '../../../../../../components/TableChatRow';
import { useGetSpamBotChats } from '../../../../../../codegen/telegram.spam.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { setSelectSpamChatsById } from '../../../../../../codegen/telegram.spam.reducer';
import { useDeleteSpamBotChatMutation } from '../../../../../../codegen/telegram.spam.api';
import { useBotId } from '../../../../../../telegram.hooks';
import { TTelegramErrorResult } from '../../../../../../telegram.types';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { generatePath, useNavigate } from 'react-router-dom';
import r from 'constants/routes';
import { SimpleCipher } from '../../../../../../telegram.utils';

const SpamBotChatTableBody: FC = () => {
  const botId = useBotId();
  const data = useGetSpamBotChats();
  const [deleteChatById] = useDeleteSpamBotChatMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <tbody>
      {data.data.map(chat => (
        <TableChatRow
          key={chat.id}
          onCheckboxChange={() => {
            dispatch(setSelectSpamChatsById({ id: chat.id }));
          }}
          anchorNavigateHandler={() => {
            navigate(
              generatePath(r.telegram.spamBotsChat, {
                id: botId,
                chatId: chat.id,
                messageName: SimpleCipher.encrypt(chat.chatName),
              }),
            );
          }}
          onButtonClick={() => {
            deleteChatById({ chatId: chat.id, botId }).then(res => {
              if ('error' in res) {
                const clonedError: TTelegramErrorResult = JSON.parse(JSON.stringify(res));
                showFeedback({
                  title: 'Ошибка',
                  text: clonedError.error.data.description,
                  type: EFeedbackType.ERROR,
                });
              } else {
                showFeedback({
                  title: 'ГОТОВО!',
                  text: 'Вы удалили чат',
                  type: EFeedbackType.SUCCESS,
                });
              }
            });
          }}
          {...chat}
        />
      ))}
    </tbody>
  );
};

export default SpamBotChatTableBody;
