import i18n from 'i18n';
import { object, SchemaOf, string, ref, bool } from 'yup';

import { ISignUpForm, NewPassRequestBody, ResetPasswordRequestBody } from './auth.types';
import { ISignIn } from './auth.interfaces';
import { password, userName } from 'constants/reg-exps';
import { getPhoneSchema } from 'modules/auth/auth.phone.schema';

export const signInSchema = (): SchemaOf<ISignIn> =>
  object()
    .shape({
      login: string()
        .required()
        .matches(
          userName,
          'Допустимы заглавные буквы (A-Z), строчные буквы (a-z), цифры (0-9). Другие символы не допускаются',
        )
        .label(i18n.t('labels.username')),
      password: string().required().label(i18n.t('labels.password')),
    })
    .required();

export const registerSchema = (): SchemaOf<ISignUpForm> =>
  object().shape({
    fullName: string().required().label(i18n.t('labels.username')),
    username: string()
      .required()
      .matches(
        userName,
        'Допустимы заглавные буквы (A-Z), строчные буквы (a-z), цифры (0-9). Другие символы не допускаются',
      )
      .label(i18n.t('labels.username')),
    email: string().email().required().label(i18n.t('labels.email')),
    telegram: string().transform(value => (value === '' ? undefined : value)),
    phone: getPhoneSchema().required().label(i18n.t('labels.phone')),
    finPassword: string()
      .oneOf([ref('confirmFinPass')], i18n.t('validations.mixed.confirmPassword'))
      .required()
      .label(i18n.t('labels.finPassword'))
      .matches(
        password,
        'Пароль должен содержать как минимум одну строчную букву (a-z), одну заглавную букву (A-Z) и одну цифру (0-9).',
      )
      .test({
        name: 'notOneOf',
        message: i18n.t('validations.mixed.differentPasswords'),
        test: function (value) {
          const finPassword = this.resolve(ref('password'));
          return value !== finPassword;
        },
      }),
    confirmFinPass: string()
      .oneOf([ref('finPassword')], i18n.t('validations.mixed.confirmPassword'))
      .required()
      .label(i18n.t('labels.confirmPassword')),
    password: string()
      .test({
        name: 'notOneOf',
        message: i18n.t('validations.mixed.differentPasswords'),
        test: function (value) {
          const finPassword = this.resolve(ref('finPassword'));
          return value !== finPassword;
        },
      })
      .oneOf([ref('confirmPass')], i18n.t('validations.mixed.confirmPassword'))
      .required()
      .label(i18n.t('labels.password'))
      .matches(
        password,
        'Пароль должен содержать как минимум одну строчную букву (a-z), одну заглавную букву (A-Z) и одну цифру (0-9).',
      ),
    confirmPass: string()
      .oneOf([ref('password')], i18n.t('validations.mixed.confirmPassword'))
      .required()
      .label(i18n.t('labels.confirmPassword')),
    agreement: bool().oneOf([true], 'Accept conditions').required(),
    referralCode: string()
      .notRequired()
      .transform((value: string, originalValue: string) =>
        originalValue && originalValue.trim() === '' ? null : value,
      )
      .nullable(true),
  });

export const ResetPasswordSchema = (): SchemaOf<ResetPasswordRequestBody> =>
  object()
    .shape({
      email: string().email().required().label(i18n.t('labels.email')),
    })
    .required();

// not finished
export const newPassSchema = (): SchemaOf<NewPassRequestBody> =>
  object()
    .shape({
      password: string().required().label(i18n.t('labels.password')),
      passwordConfirm: string()
        .oneOf([ref('password')])
        .label(i18n.t('labels.confirmPassword')),
    })
    .required();

export const invitorRegistrationSchema = (): SchemaOf<NewPassRequestBody> =>
  object()
    .shape({
      username: string()
        .required()
        .matches(
          userName,
          'Допустимы заглавные буквы (A-Z), строчные буквы (a-z), цифры (0-9). Другие символы не допускаются',
        )
        .label(i18n.t('labels.username')),
    })
    .required();
