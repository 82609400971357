import { FC, useState } from 'react';

import styles from './AddGroupsStackModal.module.scss';
import Modal, { CommonModalProps } from 'components/Modal';
import Input from 'components/Input';

const AddGroupsStackModal: FC<CommonModalProps> = props => {
  const [name, setName] = useState('');

  return (
    <Modal {...props} isValid={name.length > 0}>
      <h3 className={styles.Title}>Добавление стопки</h3>
      <p className={styles.Text}>Придумайте название для новой стопки</p>
      <Input label="Название" value={name} onChange={setName} />
    </Modal>
  );
};

export default AddGroupsStackModal;
