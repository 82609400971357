import { FC } from 'react';
import styles from './ChatTableBody.module.scss';
import ChatRow from '../../../ChatRow';
import { useTelegramAdminBotChats } from '../../../../telegram.selectors';

const ChatTableBody: FC = () => {
  const { data } = useTelegramAdminBotChats();

  return (
    <>
      <tbody className={styles.Main}>
        {data?.map(card => (
          <ChatRow key={card.id} {...card} />
        ))}
      </tbody>
    </>
  );
};

export default ChatTableBody;
