import { FC } from 'react';
import styles from './FolderAutoSide.module.scss';
import Button from 'components/Button';
import ToggleSwitch from 'components/ToggleSwitch';
import { useDeleteFolderByIdMutation, useToggleLikeCommentFolderMutation } from '../../vk.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCurrentFolderData } from '../../vk.selectors';
import confirm from 'utils/confirm';
import ConfirmModal from 'components/ConfirmModal';
import r from 'constants/routes';
import AddNewGroup from './AddNewGroup';

const FolderAutoSide: FC = () => {
  const [toggleLike] = useToggleLikeCommentFolderMutation();
  const [deleteFolder] = useDeleteFolderByIdMutation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { needLikeComments } = useGetCurrentFolderData();

  const onDelete = async () => {
    const confirms = await confirm(handleConfirm => (
      <ConfirmModal
        handleConfirm={handleConfirm}
        title="Вы действительно хотите удалить папку?"
        content={`Данное дейсвие невозможно будет отменить.`}
        confirmText="Удалить"
      />
    ));

    if (confirms) {
      const res = await deleteFolder({ groupId: id + '' });
      if ('data' in res) {
        navigate(r.vk.groupsActions);
      }
    }
  };

  return (
    <div className={styles.Root}>
      <div className={styles.RootGroupCard}>
        <h1 className={styles.Title}>Ставить лайк под комментариями в постах групп</h1>
        <p className={styles.Par}>
          Хотите, что бы вас заметили? Проявляйте активность! В этом вам поможет данная функция.
        </p>
        <div className={styles.Switcher}>
          <ToggleSwitch
            toggle={() => {
              toggleLike({ groupId: id + '' });
            }}
            active={needLikeComments}
            defaultChecked={needLikeComments}
          />
          <p className={styles.Par}>{needLikeComments ? 'Активировано' : 'Не активировано'}</p>
        </div>
      </div>
      <AddNewGroup />
      <div className={styles.DeleteFolderCard}>
        <h1 className={styles.Title}>Удалить папку</h1>
        <Button onClick={onDelete} leftIcon={'alert-delete'} variant={'tetriary'}>
          <span className={styles.DeleteBtn}>Удалить</span>
        </Button>
      </div>
    </div>
  );
};

export default FolderAutoSide;
