import { FC, ReactNode } from 'react';
import styles from './WelcomeBotList.module.scss';
import isEmpty from 'lodash-es/isEmpty';
import { WelcomeBot } from '../WelcomeBot';
import { TWelcomeBot } from '../../telegram.types';
import { useWelcomeGetChangeBotMessageQuery } from '../../codegen/telegram.welcome.api';
import LoadingState from '../../../../components/LoadingState';

type Props = {
  bots?: TWelcomeBot[];
  emptyMessage?: ReactNode;
};

export const WelcomeBotList: FC<Props> = ({ bots, emptyMessage }) => {
  const botsIsEmpty = isEmpty(bots);
  const { isLoading, data: messagesArray } = useWelcomeGetChangeBotMessageQuery({});
  if (isLoading) return <LoadingState />;

  return (
    <section className={styles.Wrapper}>
      {botsIsEmpty ? (
        emptyMessage
      ) : (
        <>
          <section className={styles.Bots}>
            {bots?.map((bot, idx) => {
              return <WelcomeBot key={idx} messagesArray={messagesArray?.data} {...bot} />;
            })}
          </section>
        </>
      )}
    </section>
  );
};
