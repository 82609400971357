import { FC } from 'react';
import getWordByAmount from 'utils/getWordByAmount';

import styles from './SelectedCount.module.scss';

type Props = {
  count: number;
};

const SelectedCount: FC<Props> = ({ count }) => {
  return (
    <div className={styles.Count}>
      Выбрано: {count} {getWordByAmount(count, ['группа', 'группы', 'групп'])}
    </div>
  );
};

export default SelectedCount;
