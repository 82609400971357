import { FC } from 'react';
import StatCardWithList from '../StatCardWithList';

import styles from './BotStatsSection.module.scss';

const BotStatsSection: FC = () => {
  return (
    <section className={styles.Section}>
      <h2 className={styles.Subtitle}>Статистика</h2>
      <div className={styles.Grid}>
        <StatCardWithList
          name="Сообщения"
          legend="День"
          icon="heart"
          value="1234"
          listTitle="Топ пользователей"
          listItems={[
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
          ]}
        />
        <StatCardWithList
          name="Команд"
          legend="День"
          icon="heart"
          value="1234"
          listTitle="Топ команд"
          listItems={[
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
          ]}
        />
        <StatCardWithList
          name="Переходов"
          legend="День"
          icon="heart"
          value="1234"
          listTitle="Топ ссылок"
          listItems={[
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
          ]}
        />
        <StatCardWithList
          name="Диалоги"
          legend="День"
          icon="heart"
          value="1234"
          listTitle="Уведомления"
          listItems={[
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
            'Кирилл Ровнягин',
          ]}
        />
      </div>
    </section>
  );
};

export default BotStatsSection;
