import { FC } from 'react';

import ToggleSwitch, { ToggleSwitchProps } from 'components/ToggleSwitch';

const ToggleSwitchWithLabel: FC<ToggleSwitchProps> = props => {
  return (
    <ToggleSwitch {...props} label={active => (active ? 'Активировано' : 'Не активировано')} />
  );
};

export default ToggleSwitchWithLabel;
