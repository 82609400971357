import { FC } from 'react';
import Modal, { CommonModalProps } from 'components/Modal';
import Button from 'components/Button';
import styles from './ResultModal.module.scss';
import smile from 'assets/img/emojis/smile.png';
import think from 'assets/img/emojis/thinkEmoji.png';

type Props = {
  isSuccess: boolean;
  isLoading: boolean;
} & CommonModalProps;

export const resultModalContent = (isSuccess: boolean) => {
  const resultModalContents = {
    success: { title: 'Успешно!', thumb: smile, desc: 'Приобретение тарифа совершено успешно' },
    error: {
      title: 'Ошибка',
      thumb: think,
      desc: 'Произошла ошибка при сохранении. Попробуйте еще раз',
    },
  };
  const type = isSuccess ? 'success' : 'error';

  return resultModalContents[type];
};

const ResultModal: FC<Props> = ({ isSuccess, isLoading, ...rest }) => {
  const content = resultModalContent(isSuccess);

  if (isLoading) return null;

  return (
    <Modal noBtn isNoColorBorder {...rest}>
      <div className={styles.Container}>
        <h1 className={styles.Title}>{content.title}</h1>
        <img className={styles.Thumb} src={content.thumb} alt="emoji" />
        <p className={styles.Paragraph}>{content.desc}</p>
        <Button onClick={rest.onClose} type={'submit'}>
          Закрыть
        </Button>
      </div>
    </Modal>
  );
};

export default ResultModal;
