import { setApplicationsIds } from './landing-dashboard.reducer';
import { AppDispatch } from '../../config/store';
import { EPeriodType, PeriodValues, TimeFormatValue } from './landing-dashboard.types';
import moment from 'moment-timezone';
import { standardDate, standardDateTime } from './landing-dashboard.consts';
import { getDeviceType } from '../../utils/getDeviceType';

export const handleSelectCheckBox = (value: string, ids: string[], dispatch: AppDispatch) => {
  if (ids.includes(value)) {
    const removeItem = ids.indexOf(value);

    const copyArr = [...ids];
    copyArr.splice(removeItem, 1);
    dispatch(setApplicationsIds(copyArr));
  } else {
    dispatch(setApplicationsIds([...ids, value]));
  }
};

export const checkReadApplications = (selected: string) => {
  if (selected === 'Прочитанные') return true;
  if (selected === 'Непрочитанные') return false;
};

export const getFormatLeftTickValue = (value: TimeFormatValue) => {
  if (value === 0) {
    return '';
  }

  return value.toString();
};
const getTimeFormat = (date: TimeFormatValue, format: string) => {
  moment.locale('ru');
  return moment(String(date)).format(format);
};

const getDataForMobile = (data: { time: string }[], step: number) => {
  return data.filter((item, index) => index % step === 0);
};

export const getHourlyTimes = () => {
  const currentDate = moment().format(standardDate);
  const hours = [];

  for (let i = 0; i < 24; i++) {
    hours.push({
      time: `${currentDate}T${String(i).padStart(2, '0')}:00:00`,
    });
  }

  const mobileHoursFormat = getDataForMobile(hours, 4);

  return getDeviceType() !== 'desktop' ? mobileHoursFormat : hours;
};

const getLastSevenDays = () => {
  const startOfWeek = moment().startOf('week').add(1, 'days');
  const week = [];

  for (let i = 0; i < 7; i++) {
    week.push({
      time: startOfWeek.clone().add(i, 'days').format(standardDateTime),
    });
  }

  return week;
};

const getCurrentMonthInDays = () => {
  const startDate = moment().startOf('month');
  const daysInMonth = startDate.daysInMonth();
  const interval = [30, 31].includes(daysInMonth) ? 5 : 4;
  const lastDayInMonth = moment().endOf('month').format(standardDateTime);
  const isMobile = getDeviceType() === 'mobile';
  const daysArray = [];

  for (let i = 0; i < daysInMonth; i += isMobile ? interval : 1) {
    daysArray.push({
      time: startDate.clone().add(i, 'days').format(standardDateTime),
    });
  }

  if (isMobile) {
    const lastDayInArray = moment(daysArray[daysArray.length - 1].time).day();

    if (lastDayInArray !== moment(lastDayInMonth).day()) {
      daysArray.push({
        time: lastDayInMonth,
      });
    }
  }

  return daysArray;
};

const getCurrentYearInMonths = () => {
  const dates = [];
  const year = moment().year();

  for (let i = 0; i < 12; i++) {
    dates.push({
      time: `${year}-${String(i + 1).padStart(2, '0')}-01T00:00:00`,
    });
  }

  return dates;
};

const displayYear = () => {
  if (getDeviceType() === 'mobile') {
    return 'M';
  } else if (getDeviceType() === 'tablet') {
    return 'MMM';
  } else {
    return 'MMMM';
  }
};

export const getFilterDate = (periodType: EPeriodType): PeriodValues => {
  const dataPeriods = {
    [EPeriodType.DAY]: {
      func: getHourlyTimes,
      timeFormat: (date: TimeFormatValue) => getTimeFormat(date, 'HH:mm'),
    },
    [EPeriodType.WEEK]: {
      func: getLastSevenDays,
      timeFormat: (date: TimeFormatValue) => getTimeFormat(date, 'dd'),
    },
    [EPeriodType.MONTH]: {
      func: getCurrentMonthInDays,
      timeFormat: (date: TimeFormatValue) => getTimeFormat(date, 'DD'),
    },
    [EPeriodType.YEAR]: {
      func: getCurrentYearInMonths,
      timeFormat: (date: TimeFormatValue) => getTimeFormat(date, displayYear()),
    },
  };

  const data = dataPeriods[periodType];

  return {
    periodValues: data.func(),
    numberOfPeriod: data.func()?.length,
    timeFormat: data.timeFormat,
  };
};
