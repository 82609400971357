import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Published from './pages/Published';
import { PagesWrapper } from './pages/Wrapper/PagesWrapper';

function MyPagesRoutes(): JSX.Element {
  return (
    <>
      <Route path={r.myPages.edit} element={<PagesWrapper />} />
      <Route path={r.myPages.preview} element={<PagesWrapper />} />
      <Route path={r.myPages.published} element={<Published />} />
    </>
  );
}

export default MyPagesRoutes;
