import { FC, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import styles from './Notification.module.scss';
import { MessageType } from 'modules/notifications/notifications.types';
import Button from 'components/Button';
import {
  useDeleteMailMutation,
  useReadMailMutation,
} from 'modules/notifications/notifications.api';
import useAppearanceInViewport from 'hooks/useAppearanceInViewport';

type Props = {
  message: MessageType;
};

const Notification: FC<Props> = ({ message: { id, date, message, title, read } }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [readMail] = useReadMailMutation();
  const [deleteMail] = useDeleteMailMutation();

  const readNotification = useCallback(() => {
    if (!read) {
      readMail([id]);
    }
  }, [id, read, readMail]);

  useAppearanceInViewport({
    ref,
    containerId: 'notifications-container',
    callback: readNotification,
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      layout="position"
      className={styles.Notification}
      ref={ref}
    >
      <div
        className={classNames(styles.Circle, {
          [styles.Active]: !read,
        })}
      />
      <h1 className={styles.Title}>{title}</h1>
      <h3 className={styles.Data}>{dayjs(date).format('DD MMMM YYYY HH:mm')}</h3>
      <p className={styles.Content}>{message}</p>
      <Button
        className={styles.Button}
        variant="secondary"
        size="s"
        onClick={() => deleteMail([id])}
      >
        Удалить
      </Button>
    </motion.div>
  );
};

export default Notification;
