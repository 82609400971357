import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './ActionLayout.module.scss';
import HeaderWithTabs from 'components/HeaderWithTabs';
import VkAccountsSelector from 'modules/vk/components/VkAccountsSelector';
import Circles from 'components/Circles';
import { links } from '../Header';

const ActionLayout: FC = () => {
  return (
    <>
      <HeaderWithTabs className={styles.Header} links={links}>
        <VkAccountsSelector />
      </HeaderWithTabs>
      <Outlet />
      <Circles />
    </>
  );
};

export default ActionLayout;
