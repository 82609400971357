import { CSSProperties, FC, ReactNode } from 'react';

import styles from './SectionTitle.module.scss';

type Props = {
  children: ReactNode;
  style?: CSSProperties;
};

const SectionTitle: FC<Props> = ({ children, style }) => {
  return (
    <h2 className={styles.Title} style={style}>
      {children}
    </h2>
  );
};

export default SectionTitle;
