import { FC, useMemo, useState } from 'react';

import Input, { InputIcon, InputProps } from 'components/Input';

const PasswordInput: FC<InputProps> = ({ icons = [], ...props }) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const initialIcon = useMemo(
    (): InputIcon => ({
      name: passwordShown ? 'eye' : 'eye-closed',
      onClick: () => setPasswordShown(prev => !prev),
    }),
    [passwordShown],
  );

  return (
    <Input
      {...props}
      type={passwordShown ? 'text' : 'password'}
      icons={[initialIcon].concat(icons)}
    />
  );
};

export default PasswordInput;
