import { FC } from 'react';
import classNames from 'classnames';

import styles from './Supervisor.module.scss';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useTypedSelector from 'hooks/useAppSelector';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import instaImg from 'assets/svg/social/insta.svg';
import vkImg from 'assets/svg/social/vk.svg';
import tgImg from 'assets/svg/social/telegram.svg';
import phoneImg from 'assets/svg/social/phone.svg';
import { selectInviter } from 'modules/account/account.selector';
import { Social } from 'modules/account/account.interfaces';
import isNull from 'lodash-es/isNull';

type ElementProps = {
  name: string;
  src: string;
  iconClassName?: string;
  value?: string;
};

const SocialElement: FC<ElementProps> = ({ name, src, iconClassName, value }) => {
  const { copyAndNotify } = useCopyToClipboard();

  if (!value) return null;

  return (
    <div className={styles.Element}>
      <img src={src} alt={name} className={classNames(iconClassName, styles.Icon)} />
      <div className={styles.Info}>
        <span className={styles.Value}>{value}</span>
        <span className={styles.Name}>{name}</span>
      </div>
      <div className={styles.Copy}>
        <Icon
          className={styles.CopyIcon}
          iconName="copy"
          size={24}
          onClick={() => copyAndNotify(value)}
        />
      </div>
    </div>
  );
};

type SocialBlockProps = {
  social?: Social;
};

const SocialBlock: FC<SocialBlockProps> = ({ social }) => {
  if (!social || Object.values(social).every(val => isNull(val))) return null;

  return (
    <div className={styles.Social}>
      <SocialElement
        name="Инстаграм"
        src={instaImg}
        iconClassName={styles.Insta}
        value={social.instagram}
      />
      <SocialElement name="Вконтакте" src={vkImg} iconClassName={styles.Vk} value={social.vk} />
      <SocialElement
        name="Телеграм"
        src={tgImg}
        iconClassName={styles.Tg}
        value={social.telegram}
      />
      <SocialElement
        name="Телефон"
        src={phoneImg}
        iconClassName={styles.Phone}
        value={social.phone}
      />
    </div>
  );
};

const Supervisor: FC = () => {
  const inviter = useTypedSelector(selectInviter);

  if (!inviter) return null;

  return (
    <div className={styles.Block}>
      <div className={styles.Badge}>Куратор</div>
      <div className={styles.Data}>
        <Avatar src={inviter.avatar} name="supervisor-ava" size={66} />
        <p className={styles.TextName}>{inviter.fullName}</p>
      </div>
      <SocialBlock social={{ phone: inviter.phone, ...inviter.social }} />
    </div>
  );
};

export default Supervisor;
