import { FC } from 'react';

import styles from './AvatarEdit.module.scss';

import { selectUser } from 'modules/account/account.selector';

import Avatar from 'components/Avatar';
import useTypedSelector from 'hooks/useAppSelector';
import Button from 'components/Button';
import AvatarEditModal from '../AvatarEditModal';
import useDisclosure from 'hooks/useDisclosure';
import { useDeleteAvatarMutation } from 'modules/settings/settings.api';

const AvatarEdit: FC = () => {
  const [deleteAvatart] = useDeleteAvatarMutation();
  const { open, onClose, onOpen } = useDisclosure();
  const user = useTypedSelector(selectUser);

  return (
    <>
      <div className={styles.AvatarWrapper}>
        <Avatar src={user?.avatar} name={user?.fullName ?? ''} size={80} />
        <div className={styles.Buttons}>
          <Button className={styles.ChangePicBtn} variant="tetriary" size="s" onClick={onOpen}>
            Сменить фото
          </Button>
          <Button variant="text" className={styles.Delete} onClick={() => deleteAvatart()}>
            Удалить
          </Button>
        </div>
      </div>
      <AvatarEditModal isOpen={open} onClose={onClose} />
    </>
  );
};

export default AvatarEdit;
