import { useCallback } from 'react';

import { Click, EClickAction } from '../my-pages.types';

const processLink = (link: string) => {
  const url = 'https://';
  if (link.startsWith(url)) return link;

  return url + link;
};

function useOnClick(click: Click): () => void {
  return useCallback(() => {
    switch (click.action) {
      case EClickAction.ANCHOR:
        if (click.link) {
          const element = document.getElementById(click.link);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
        return;
      case EClickAction.LINK:
        if (click.link) {
          window.open(processLink(click.link));
        }
        return;
    }
  }, [click]);
}

export default useOnClick;
