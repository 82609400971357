import dayjs, { Dayjs } from 'dayjs';

function getTariffPlanDatesInfo(
  planStart?: Dayjs | string | null,
  planEnd?: Dayjs | string | null,
): {
  readonly range: [Dayjs, Dayjs];
  readonly daysTillEnd: number;
} {
  const today = dayjs();
  const planStartDay = dayjs(planStart);

  const planEndDay = dayjs(planEnd);

  const range: [Dayjs, Dayjs] = [planStartDay, planEndDay];
  const daysTillEnd = planEndDay.diff(today, 'day');

  return { range, daysTillEnd };
}

export default getTariffPlanDatesInfo;
