import TextMenu from 'components/TextMenu';
import { FC } from 'react';

import styles from './Header.module.scss';

const Header: FC = () => {
  return (
    <div className={styles.Header}>
      <h1 className={styles.Text}>Уведомления</h1>
      <TextMenu label="Фильтровать по дате" items={[]} />
    </div>
  );
};

export default Header;
