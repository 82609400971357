import { FC } from 'react';

import Button from 'components/Button';
import Modal, { CommonModalProps } from 'components/Modal';
import styles from './LandingDelPgModal.module.scss';

type Props = {
  modalProps: CommonModalProps;
};

const LandingDeletePageModal: FC<Props> = ({ modalProps }) => {
  const { onClose } = modalProps;

  return (
    <Modal {...modalProps} className={styles.Modal}>
      <h1 className={styles.Title}>Удалить страницу</h1>
      <p className={styles.Text}>
        Вы точно уверены, что хотите удалить страницу? Действие нельзя будет отменить
      </p>
      <div className={styles.BtnWrapper}>
        <Button onClick={onClose} className={styles.Cancel} variant="white">
          Отменить
        </Button>
        <Button onClick={onClose} className={styles.Confirm} variant="primary">
          Да
        </Button>
      </div>
    </Modal>
  );
};

export default LandingDeletePageModal;
