import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Phone.module.scss';
import { EditorMode, EOrientation } from 'modules/my-pages/my-pages.types';

type Props = {
  children: ReactNode;
  mode?: EditorMode;
  orientation?: EOrientation;
  className?: string;
  wrapperClassName?: string;
};

const Phone: FC<Props> = ({
  children,
  mode = 'edit',
  orientation = EOrientation.VERTICAL,
  className,
  wrapperClassName,
}) => {
  return (
    <div className={classNames(styles.Wrapper, wrapperClassName)} data-orientation={orientation}>
      <div className={classNames(styles.Phone, className)} data-mode={mode}>
        {children}
      </div>

      {mode === 'preview' && (
        <>
          <div className={styles.Bang} />
          <div className={styles.Power} />
          <div className={styles.Mute} />
          <div className={styles.Volume} />
        </>
      )}
    </div>
  );
};

export default Phone;
