import { FC } from 'react';

import r from 'constants/routes';
import { EPlanName } from 'modules/account/account.interfaces';
import getTariffPlanDatesInfo from 'utils/tariff-plan-dates-info';

import Icon from 'components/Icon';
import { useSelectUser } from 'modules/account/account.selector';
import Card from '../Card';
import ButtonWithArrow from '../ButtonWithArrow';

import styles from './Plan.module.scss';

const PlanCard: FC = () => {
  const user = useSelectUser();

  const [start, end] = getTariffPlanDatesInfo(user?.plan.startData, user?.plan.expiredDate).range;
  const isTrial = user?.plan.name === EPlanName.trial;

  return (
    <Card
      className={styles.Card}
      dataPlan={user?.plan.displayName.toLowerCase()}
      planCycle={user?.plan.periodName}
      link={r.tariff}
    >
      <span className={styles.Title}>{user?.plan.displayName}</span>
      <span className={styles.Text} data-is-trial={isTrial}>
        {isTrial ? 'Пробный период' : 'Выбранный тариф'}
      </span>
      <div className={styles.Link}>
        <ButtonWithArrow>
          Перейти в Тарифы
          <Icon iconName="arrow-right" size={12} />
        </ButtonWithArrow>
        <div className={styles.Info}>
          <span className={styles.TariffDateLabel} data-is-trial={isTrial}>
            {isTrial ? 'Действие пробного тарифа' : 'Действие тарифа'}
          </span>
          <span className={styles.Date}>
            {start.format('DD.MM.YYYY')} <span className={styles.DateDivider}>—</span>{' '}
            {end.format('DD.MM.YYYY')}
          </span>
        </div>
      </div>
      <div className={styles.Blank} />
    </Card>
  );
};

export default PlanCard;
