import { FC } from 'react';
import { ModalWithoutForm } from 'components/ModalWithoutForm/ModalWithoutForm';
import { GoBackModalTypes } from './GoBackModal.types';
import styles from './GoBackModal.module.scss';
import useWindowSize from 'hooks/useWindowSize';

export const GoBackModal: FC<GoBackModalTypes> = ({ openModal, closeModal, onClick }) => {
  const { width } = useWindowSize();
  const buttonText = width > 670 ? 'Сохранить и выйти' : 'Сохранить';

  return (
    <ModalWithoutForm
      modalTitle={'Изменения не сохранены'}
      onClick={onClick}
      isOpen={openModal}
      onClose={closeModal}
      btnText={buttonText}
      className={styles.ModalWrapper}
      isValid={true}
    >
      <p className={styles.Description}>
        Уверены, что хотите закрыть страницу ? Ваши изменения не сохранятся
      </p>
    </ModalWithoutForm>
  );
};
