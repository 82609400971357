import { FC, useEffect, useRef } from 'react';
import styles from './SpamBotChatList.module.scss';
import SearchField from 'components/SearchField';
import LoadingState from 'components/LoadingState';
import HeadTitle from '../../../../components/HeadTitle';
import SpamBotChatTable from './SpamBotChatTable';
import { useGetAllSpamChatsBotsQuery } from '../../../../codegen/telegram.spam.api';
import { useBotId } from '../../../../telegram.hooks';
import { useGetSpamBotChatsParams } from '../../../../codegen/telegram.spam.selectors';
import Pagination from 'components/Pagination';
import { urlOffset } from '../../../../telegram.utils';
import isUndefined from 'lodash-es/isUndefined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { setSpamChatsParams } from '../../../../codegen/telegram.spam.reducer';
import { isEmpty, isNull } from 'lodash-es';

const SpamBotChatList: FC = () => {
  const botId = useBotId();
  const params = useGetSpamBotChatsParams();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isParamsMatch = params.offset === +urlOffset(queryParams.get('offset'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const querySet = useRef(new Set([params.chatName]));

  const { isFetching, data } = useGetAllSpamChatsBotsQuery(
    { botId, ...params },
    { skip: isUndefined(id) || !isParamsMatch, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    const chatName = queryParams.get('chatName') ?? '';

    let offset: number;
    if (isNull(queryParams.get('offset'))) {
      offset = 0;
    } else {
      offset = Number(queryParams.get('offset'));
    }

    dispatch(setSpamChatsParams({ chatName, offset }));
  }, []);

  const pageChange = (page: number) => {
    let offset;
    queryParams.set('page', `${page}`);

    if (page === 1) {
      offset = 0;
      queryParams.set('offset', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setSpamChatsParams({ offset }));
    } else {
      offset = (page - 1) * 12;
      queryParams.set('offset', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setSpamChatsParams({ offset }));
    }
  };

  if (isFetching) return <LoadingState />;

  return (
    <div className={styles.Main}>
      <div className={styles.Head}>
        <HeadTitle>Чаты</HeadTitle>
        <SearchField
          placeholder={'Поиск'}
          defaultValue={params.chatName}
          onSearch={searchQuery => {
            if (searchQuery) {
              if (querySet.current.has(searchQuery)) {
                queryParams.set('chatName', searchQuery);
                navigate({ search: queryParams.toString() });
                dispatch(setSpamChatsParams({ chatName: searchQuery }));
              } else {
                querySet.current.clear();
                queryParams.set('chatName', searchQuery);
                queryParams.set('offset', '0'); // Reset offset for new search
                queryParams.set('page', `1`);
                navigate({ search: queryParams.toString() });
                dispatch(setSpamChatsParams({ chatName: searchQuery }));
              }
            } else {
              queryParams.set('chatName', '');
              navigate({ search: queryParams.toString() });
              dispatch(setSpamChatsParams({ chatName: '' }));
            }
          }}
          debounceDelay={1500}
        />
      </div>
      <SpamBotChatTable isDataEmpty={isEmpty(data?.data)} />
      <Pagination
        onPageChange={num => pageChange(num)}
        offset={1}
        totalCount={data ? Math.ceil(data.count / 12) : 0}
        currPage={Number(queryParams.get('page'))}
      />
    </div>
  );
};

export default SpamBotChatList;
