interface Props {
  className?: string;
}
export const PhoneSvg = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.1"
        width="24"
        height="24"
        rx="12"
        transform="matrix(-1 0 0 1 24 0.5)"
        fill="#E80FE9"
      />
      <path
        d="M9.69832 6.78299C9.51299 6.60795 9.2648 6.5 8.99212 6.5H7.03978C6.4501 6.5 5.96446 7.00181 6.00204 7.59573C6.37272 13.4456 11.0544 18.1274 16.9043 18.498C17.4982 18.5356 18 18.0499 18 17.4602V15.5079C18 14.9625 17.5678 14.5152 17.0324 14.4721C16.122 14.3989 15.2577 14.1663 14.4649 13.8028C14.0724 13.6228 13.6046 13.7045 13.2981 14.0109L12.7665 14.5425C12.7264 14.5825 12.6683 14.5885 12.6285 14.5626C11.5556 13.8621 10.6377 12.9443 9.93734 11.8714C9.91137 11.8316 9.91725 11.7736 9.95742 11.7334L10.489 11.2018C10.7954 10.8954 10.8771 10.4275 10.6971 10.035C10.3336 9.24222 10.1011 8.37789 10.0278 7.46748C10.0062 7.19974 9.88355 6.95794 9.69822 6.7829L9.69832 6.78299ZM9.22856 7.28532C9.28976 7.34384 9.33014 7.42667 9.33787 7.5234C9.41771 8.5157 9.67154 9.45936 10.068 10.3237C10.1293 10.4574 10.1024 10.6093 9.99948 10.7122L9.46789 11.2438C9.20355 11.5081 9.14822 11.9289 9.35766 12.2496C10.1106 13.4029 11.097 14.3892 12.2503 15.1422C12.571 15.3516 12.9918 15.2962 13.2561 15.032L13.7877 14.5004C13.8906 14.3975 14.0424 14.3706 14.1762 14.4319C15.0406 14.8283 15.9843 15.0822 16.9765 15.162C17.17 15.1776 17.3076 15.322 17.3076 15.5078V17.4602C17.3076 17.6628 17.1449 17.8194 16.948 17.8069C11.4418 17.4581 7.04168 13.0579 6.69277 7.55166C6.6803 7.35479 6.83689 7.19211 7.03952 7.19211H8.99186C9.08479 7.19211 9.1671 7.22663 9.2283 7.28514L9.22856 7.28532Z"
        fill="url(#paint0_linear_5547_25728)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5547_25728"
          x1="6"
          y1="6.5"
          x2="20.346"
          y2="10.9691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E80FE9" />
          <stop offset="1" stopColor="#A40BEB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
