import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { signOut } from 'modules/auth/auth.reducer';
import { IUser } from './account.interfaces';
import accountApi from './account.api';

type AccountSliceState = {
  user: IUser | null;
  accounts: {
    vk?: number | string;
  };
};

const initialState: AccountSliceState = {
  user: null,
  accounts: {},
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setSelectedVkAccount: (state, { payload }: PayloadAction<number | string | undefined>) => {
      state.accounts.vk = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signOut, state => {
        state.user = null;
        state.accounts = {};
      })
      .addMatcher(accountApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
        state.user = payload.body;
      });
  },
});

export const { setSelectedVkAccount } = accountSlice.actions;

export default accountSlice.reducer;
