import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isNil } from 'lodash-es';

import r from 'constants/routes';
import { invitorRegistrationSchema as inviterRegistrationSchema } from 'modules/auth/auth.schema';
import { IInvitorRegistration, IInvitorRegistrationResponse } from 'modules/auth/auth.types';
import { useGetInvitorMutation } from 'modules/auth/auth.api';

import AuthForm from 'modules/auth/components/AuthForm';
import Input from 'components/Input';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';

import styles from './InvitorRegistration.module.scss';
import useFirstRender from 'hooks/useFirstRender';

const InvitorRegistration: FC = () => {
  const [getInvitor] = useGetInvitorMutation();
  const [invitor, setInvitor] = useState<IInvitorRegistrationResponse | null>(null);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const firstRender = useFirstRender();
  const ref = params.get('ref');

  const onEnter = () => {
    navigate(r.auth.signIn);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IInvitorRegistration>({
    mode: 'onChange',
    resolver: yupResolver(inviterRegistrationSchema()),
  });

  const username = watch('username');

  useEffect(() => {
    if (username !== invitor?.username) {
      setInvitor(null);
    }
  }, [username, firstRender, invitor?.username]);

  useEffect(() => {
    if (ref) {
      getInvitor({ username: ref }).then(res => {
        if ('data' in res) {
          setInvitor(res.data);
          setValue('username', res.data.username);
        }
      });
    }
  }, [getInvitor, ref, setValue]);

  const handleClick = async () => {
    if (username) {
      const res = await getInvitor({ username });

      if ('data' in res) {
        setInvitor(res.data);
      }
    }
  };

  const onSubmit: SubmitHandler<IInvitorRegistration> = async ({ username: referralCode }) => {
    navigate(`${r.auth.signUp.registration}?referralCode=${referralCode}`);
  };

  const isValidInvitor = !isNil(invitor) && invitor.username === username;

  return (
    <AuthForm
      title="Регистрация"
      buttonText="Продолжить"
      footer={{ text: 'Уже есть аккаунт?', btn: { text: 'Войти', onClick: onEnter } }}
      isValid={isValidInvitor}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.InvitorAvatarWrapper}>
        {!isNil(invitor) && (
          <div className={styles.InvitorBlock}>
            <Avatar
              className={styles.Avatar}
              size={80}
              src={invitor.avatar?.src}
              name={invitor.fullName ?? '-'}
            />
            <div className={styles.InvitorName}>{invitor?.fullName}</div>
          </div>
        )}
      </div>
      <div className={styles.Search}>
        <Icon className={styles.Icon} onClick={handleClick} iconName="search" size={20} />
        <Input
          label="Логин пригласителя"
          type="search"
          register={register('username')}
          bigText
          invalid={!!errors.username}
          errorMsg={errors.username?.message}
        />
      </div>
    </AuthForm>
  );
};

export default InvitorRegistration;
