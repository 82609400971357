import { FC, useState } from 'react';

import WithMultipleChoices, { WithMultipleChoicesProps } from 'components/WithMultipleChoices';
import Input, { InputProps } from 'components/Input';

type Props = {
  addChoice: (choice: string) => void;
  choicesProps: Omit<WithMultipleChoicesProps, 'children'>;
  inputProps: InputProps;
};

const InputWithMultipleChoices: FC<Props> = ({ choicesProps, inputProps, addChoice }) => {
  const [value, setValue] = useState('');

  const canAddChoice = value.length > 0 && !choicesProps.choices?.includes(value);

  const handleAddChoice = () => {
    if (canAddChoice) {
      addChoice(value);
      setValue('');
    }
  };

  return (
    <WithMultipleChoices {...choicesProps}>
      <Input
        {...inputProps}
        value={value}
        onChange={setValue}
        onKeyDown={e => {
          const keyCode = e.code || e.key;
          if (keyCode == 'Enter') {
            handleAddChoice();
          }
        }}
        icons={
          canAddChoice
            ? [
                { name: 'cross', onClick: () => setValue('') },
                { name: 'checkedline-check', onClick: handleAddChoice },
              ]
            : undefined
        }
      />
    </WithMultipleChoices>
  );
};

export default InputWithMultipleChoices;
