import { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './ActionCard.module.scss';
import Button from 'components/Button';
import ToggleSwitch, { ToggleSwitchProps } from 'components/ToggleSwitch';
import VideoInstructions from 'components/VideoInstructions';

type Props = {
  name: string;
  onAdjust: string;
  text: string;
  videoLink: string;
  toggle: ToggleSwitchProps;
  toggleText?: string;
  icon?: string;
};

const ActionCard: FC<Props> = ({ name, text, onAdjust, videoLink, toggle, toggleText, icon }) => {
  return (
    <article className={styles.Card}>
      <div className={styles.Header}>
        {icon && <img src={icon} alt={'icon'} />}

        <ToggleSwitch {...toggle} />

        {toggleText && <span className={styles.ToggleText}>{toggleText}</span>}
      </div>

      <h3 className={styles.Name}>{name}</h3>

      <p className={styles.Text}>{text}</p>

      <VideoInstructions link={videoLink} className={styles.VideoLink} />
      <Button as={Link} to={onAdjust} variant="magic" size="l">
        Настроить
      </Button>
    </article>
  );
};

export default ActionCard;
