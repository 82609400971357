import { useSelectUser } from '../../../account/account.selector';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from '../Layout/Layout.module.scss';
import { links } from '../Header';
import CenterBox from 'components/CenterBox';
import EmptyPage from 'components/EmptyPage';
import lock from 'assets/svg/lock.svg';
import r from 'constants/routes';
import Circles from 'components/Circles';
import MobileFooter from '../MobileFooter';
import HeaderWithTabs from 'components/HeaderWithTabs';
import { useGetWelcomeBotQuery } from '../../codegen/telegram.welcome.api';
import Spinner from 'components/Spinner';
import { DeleteBotButton } from '../DeleteBotButton';

export const ActionLayout = () => {
  const user = useSelectUser();
  const navigate = useNavigate();

  const { data: botRes, isLoading: isBotResLoading } = useGetWelcomeBotQuery({});

  return (
    <>
      <div className={styles.Header}>
        {user?.permissions.telegramAllowed && (
          <HeaderWithTabs className={styles.Header} links={links}>
            {isBotResLoading ? (
              <Spinner />
            ) : (
              <>
                {botRes?.success && botRes?.data.isAuthorize && (
                  <DeleteBotButton name={botRes.data.name} />
                )}
              </>
            )}
          </HeaderWithTabs>
        )}
      </div>
      {user?.permissions.telegramAllowed ? (
        <Outlet />
      ) : (
        <CenterBox>
          <EmptyPage
            img={<img src={lock} alt="" />}
            title={'Упс, У вас нет доступа'}
            text={
              'Для того, чтобы воспользоваться функционалом “Telegram”, вам необходимо приобрести тарифный план премиум или стандарт'
            }
            btnText={'Выбрать тариф'}
            onClick={() => {
              navigate(r.tariff);
            }}
          />
        </CenterBox>
      )}

      <Circles />
      <MobileFooter />
    </>
  );
};
