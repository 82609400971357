function customClipboard(text: string) {
  return new Promise((resolve, reject) => {
    const tempInput = document.createElement('input');
    tempInput.style.position = 'absolute';
    tempInput.style.left = '-1000px';
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();

    let success = false;
    try {
      success = document.execCommand('copy');
    } catch (err) {
      reject(err);
    }

    document.body.removeChild(tempInput);
    if (success) {
      resolve(true);
    } else {
      reject(new Error('Failed to copy to clipboard.'));
    }
  });
}

const copyToClipboard = async (text?: string): Promise<boolean> => {
  try {
    if (text) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
      } else {
        await customClipboard(text);
      }
    }
    return true;
  } catch (err) {
    return false;
  }
};

export default copyToClipboard;
