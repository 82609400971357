import { FC } from 'react';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';

import styles from './StackSelector.module.scss';
import Icon from 'components/Icon';
import useAppDispatch from 'hooks/useAppDispatch';
import { selectThematicGroup } from 'modules/vk/vk.reducer';

const StackSelector: FC = () => {
  const dispatch = useAppDispatch();

  const items = [
    {
      label: 'Катерина',
      onClick: () => {
        dispatch(selectThematicGroup(1));
        dispatch(selectThematicGroup(2));
      },
    },
    {
      label: 'Абрикос',
      onClick: () => {
        dispatch(selectThematicGroup(2));
      },
    },
    {
      label: 'Спортивная',
      onClick: () => {
        dispatch(selectThematicGroup(2));
        dispatch(selectThematicGroup(3));
      },
    },
    {
      label: 'Фиолетовая',
      onClick: () => {
        dispatch(selectThematicGroup(1));
        dispatch(selectThematicGroup(2));
        dispatch(selectThematicGroup(3));
      },
    },
  ];

  return (
    <Menu as="div" className={styles.Menu}>
      <Menu.Button className={styles.Btn}>
        {({ open }) => (
          <>
            Выбор стопки{' '}
            <Icon
              className={classNames(styles.Icon, { [styles.IconUp]: open })}
              iconName="arrow-up"
              size={12}
            />
          </>
        )}
      </Menu.Button>

      <Menu.Items as="ul" className={styles.Context}>
        {items.map(item => (
          <Menu.Item key={item.label} as="li" className={styles.Item} onClick={item.onClick}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default StackSelector;
