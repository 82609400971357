import { FC, useEffect } from 'react';
import find from 'lodash-es/find';

import styles from './LandingSelector.module.scss';

import { setFormComponentId } from 'modules/landing-dashboard/landing-dashboard.reducer';
import { useSelectStatisticsId } from 'modules/landing-dashboard/landing-dashboard.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import Menu from 'components/Menu';
import Icon from 'components/Icon';
import { SlicedText } from 'components/SlicedText/SlicedText';
import { useGetPagesFiltered } from 'modules/landing-dashboard/landing-dashboard.hooks';
import classNames from 'classnames';
import { LandingSelectorTypes } from './LandingSelector.types';
const LandingSelector: FC<LandingSelectorTypes> = ({
  type,
  label,
  isLabel,
  landingSelectorClick,
  classname,
}) => {
  const { data, isError, isLoading } = useGetPagesFiltered();
  const selectedId = useSelectStatisticsId();
  const dispatch = useAppDispatch();
  const menuText = find(data, { id: Number(selectedId) })?.name ?? 'Страница';

  useEffect(() => {
    if (selectedId === undefined) {
      dispatch(setFormComponentId(undefined));
    }
  }, [selectedId]);

  if (isLoading) return null;
  if (isError) return null;

  return (
    <Menu
      className={classNames(styles.Menu, classname)}
      items={
        data?.map(landing => ({
          isSelected: landing.id === selectedId,
          label: landing.name,
          onClick: () => landingSelectorClick(landing.id),
        })) ?? []
      }
      btnClassName={styles.btn}
      datatype={type}
    >
      <div>
        {isLabel && <p className={styles.Label}>{label}</p>}
        <SlicedText text={menuText} className={styles.menuText} />
      </div>
      <Icon className={styles.IconDown} iconName="arrow-down" size={18} />
    </Menu>
  );
};

export default LandingSelector;
