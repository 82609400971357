import styles from './ChatTable.module.scss';
import CheckBox from 'components/CheckBox';
import Button from 'components/Button';
import SortIcon from './SortIcon';
import ChatTableBody from './ChatTableBody';
import useWindowSize from 'hooks/useWindowSize';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  useAdminBotsChatParams,
  useAdminSelectedChats,
  useTelegramAdminBotChats,
} from '../../../telegram.selectors';
import { isEmpty } from 'lodash-es';
import {
  useGetAdminBotChatListByIdQuery,
  useRemoveAdminAllBotChatsMutation,
} from '../../../codegen/telegram.admin.api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  clearSelectedChats,
  setAdminChatsParams,
  setSelectAllChats,
  setSelectedAllChats,
} from '../../../codegen/telegram.admin.reducer';
import Pagination from 'components/Pagination';
import { urlOffset } from '../../../telegram.utils';
import isUndefined from 'lodash-es/isUndefined';
import LoadingState from 'components/LoadingState';
import { TTelegramErrorResult } from '../../../telegram.types';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import EmptyText from '../../EmptyText';

const ChatTable = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 625;
  const { id } = useParams();
  const { id: botId } = useParams();
  const [deleteAllChats] = useRemoveAdminAllBotChatsMutation();
  const chats = useAdminSelectedChats();
  const queryParams = new URLSearchParams(location.search);
  const params = useAdminBotsChatParams();
  const isParamsMatch = params.offset === +urlOffset(queryParams.get('offset'));

  const { isFetching } = useGetAdminBotChatListByIdQuery(
    { botId: String(id), ...params },
    {
      skip: isUndefined(id) || !isParamsMatch,
      refetchOnMountOrArgChange: true,
    },
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { count, data } = useTelegramAdminBotChats();
  const isChecked = !isEmpty(data) && data.every(chat => chat.isSelected === true);
  const isButtonActive = data.some(i => i.isSelected);
  const pageChange = (page: number) => {
    let offset;
    queryParams.set('page', `${page}`);
    if (page === 1) {
      offset = 0;
      queryParams.set('offset', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setAdminChatsParams({ offset }));
    } else {
      offset = (page - 1) * 12;
      queryParams.set('offset', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setAdminChatsParams({ offset }));
    }
  };

  if (isFetching) return <LoadingState />;

  if (isEmpty(data)) return <EmptyText text={'Данный бот пока не добавлен в чаты'} />;

  return (
    <>
      <table className={styles.Main}>
        <thead className={styles.TableHead}>
          <tr className={styles.TableHeadTr}>
            <th>
              <div className={styles.Section}>
                <CheckBox
                  className={styles.Checkbox}
                  onChange={() => {
                    dispatch(setSelectAllChats());
                    if (data.every(item => item.isSelected)) {
                      dispatch(clearSelectedChats());
                    } else {
                      dispatch(setSelectedAllChats(data.map(i => i.id)));
                    }
                  }}
                  checked={isChecked}
                />
                <p className={styles.Par}>Название чата</p>
                <SortIcon />
              </div>
            </th>

            {isDesktop && (
              <th>
                <div className={styles.Section}>
                  <p className={styles.Par}>Статус</p>
                  <SortIcon />
                </div>
              </th>
            )}

            <th className={styles.LastTh}>
              {isButtonActive && (
                <Button
                  disabled={isEmpty(chats) || !isButtonActive}
                  variant={'text'}
                  className={styles.Btn}
                  onClick={() => {
                    if (botId) {
                      deleteAllChats({ botId, chatIds: [...chats] }).then(res => {
                        if ('error' in res) {
                          const clonedError: TTelegramErrorResult = JSON.parse(JSON.stringify(res));
                          showFeedback({
                            title: 'Ошибка',
                            text: clonedError.error.data.description,
                            type: EFeedbackType.ERROR,
                          });
                        } else {
                          showFeedback({
                            title: 'ГОТОВО!',
                            text: 'Вы удалили чат',
                            type: EFeedbackType.SUCCESS,
                          });
                        }
                      });
                    }
                  }}
                >
                  Покинуть выбранные чаты
                </Button>
              )}
            </th>
          </tr>
        </thead>
        <ChatTableBody />
      </table>
      <Pagination
        onPageChange={num => pageChange(num)}
        offset={1}
        totalCount={Math.ceil(count / 12)}
        currPage={Number(queryParams.get('page'))}
      />
    </>
  );
};

export default ChatTable;
