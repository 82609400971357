import { FC } from 'react';
import classNames from 'classnames';
import range from 'lodash-es/range';

import styles from './BotMessageCard.module.scss';

import { getImage } from 'utils/getImage';

type Props = {
  onClick?: () => void;
};
const BotMessageCardDisplay: FC<Props> = ({ onClick }) => {
  return (
    <div className={classNames(styles.Card, styles.Clickable)} onClick={onClick}>
      <section className={styles.Header}>
        <span>Пн, Вт, Ср, Пт</span>
        <span>08:00, 18:30, 21:00, 23:15</span>
      </section>
      <p className={styles.Body}>
        5 key components for aspiring designers that shape stylish design. Practice in Figma and
        create our first layout. That's all we're going to do in this lesson, buddy! Practice in
        Figma and create our first layout components for aspiring designers
      </p>
      <div className={styles.Images}>
        {range(3).map(n => (
          <img src={getImage()} alt={`${n}`} key={n} className={styles.Image} />
        ))}
      </div>
    </div>
  );
};

export default BotMessageCardDisplay;
