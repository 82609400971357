import { useEffect, useState } from 'react';
import { MotionValue } from 'framer-motion';

function useIsDragging(value: MotionValue<number>): boolean {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    value.onChange(latest => {
      if (latest !== 0) {
        setIsDragging(true);
      } else {
        setIsDragging(false);
      }
    });
  }, [value]);

  return isDragging;
}

export default useIsDragging;
