import { useEffect, useRef } from 'react';

function useFirstRender(callback?: () => void): boolean {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender) {
      if (callback) {
        callback();
      }
      firstRender.current = false;
    }
  }, [callback]);

  return firstRender.current;
}

export default useFirstRender;
