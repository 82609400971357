import { FC } from 'react';

import styles from './SaveToTable.module.scss';
import Icon from 'components/Icon';

type Props = {
  onSave: () => void;
};

const SaveToTable: FC<Props> = ({ onSave }) => {
  return (
    <button className={styles.Btn} onClick={onSave}>
      <Icon className={styles.Icon} iconName="save" size={18} /> Сохранить в таблицу
    </button>
  );
};

export default SaveToTable;
