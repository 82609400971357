import { FC, ReactNode } from 'react';
import { Dialog } from '@headlessui/react';

import styles from './ConfirmModal.module.scss';
import ModalOverlay from 'components/ModalOverlay';
import ColorfulBorder from 'components/ColorfulBorder';
import Button from 'components/Button';

type Props = {
  handleConfirm: (confirms: boolean) => void;
  title: string;
  content: ReactNode;
  confirmText?: string;
};

const ConfirmModal: FC<Props> = ({
  handleConfirm,
  title,
  content,
  confirmText = 'Подтвердить',
}) => {
  return (
    <Dialog open onClose={() => handleConfirm(false)}>
      <ModalOverlay>
        <Dialog.Panel>
          <ColorfulBorder>
            <div className={styles.Content}>
              <h3 className={styles.Title}>{title}</h3>
              <p className={styles.Text}>{content}</p>
              <div className={styles.Footer}>
                <Button
                  className={styles.CancelBtn}
                  variant="text"
                  onClick={() => handleConfirm(false)}
                >
                  Отменить
                </Button>
                <Button
                  className={styles.ConfirmBtn}
                  variant="primary"
                  onClick={() => handleConfirm(true)}
                >
                  {confirmText}
                </Button>
              </div>
            </div>
          </ColorfulBorder>
        </Dialog.Panel>
      </ModalOverlay>
    </Dialog>
  );
};

export default ConfirmModal;
