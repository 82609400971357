import { FC } from 'react';
import styles from './WelcomeBotStatistics.module.scss';
import PageHeader from 'components/PageHeader';
import { WelcomeBotStatisticsTable } from '../../components/WelcomeBotStatisticsTable';
import { videoTutorials } from '../../../../constants/videos';

export const WelcomeBotStatistics: FC = () => {
  return (
    <div className={styles.Root}>
      <PageHeader
        title="Авто-приветствие"
        subtitle={'Telegram'}
        videoTutorials={[
          { label: 'Настройка бота приветствие', url: videoTutorials.bot_greeting },
          { label: 'Получение токена', url: videoTutorials.token_reception },
          { label: 'Настройка бота', url: videoTutorials.bot_setup },
        ]}
      />
      <WelcomeBotStatisticsTable />
    </div>
  );
};
