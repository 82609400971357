import { ChangeEvent, FC } from 'react';
import first from 'lodash-es/first';

import styles from './ImageSelector.module.scss';
import IconButton from 'components/IconButton';
import Tool from '../Tool';
import { useUploadPageImageMutation } from 'modules/landing-dashboard/landing-dashboard.api';
import { getSheetImage } from 'utils/getImage';

type Props = {
  image?: string | null;
  setImage: (value: string | null) => void;
};

const ImageSelector: FC<Props> = ({ image, setImage }) => {
  const [uploadImage] = useUploadPageImageMutation();

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = first(e.target.files);
    if (!file) return;
    const imageRet = await uploadImage({ image: file, skipOptimize: true }).unwrap();
    setImage(getSheetImage(imageRet.body));
  };

  const handleRemove = () => {
    setImage(null);
  };

  return (
    <Tool
      label="Фон:"
      right={
        <>
          <div className={styles.InputWrapper}>
            <input className={styles.Input} type="file" accept="image/*" onChange={handleUpload} />
            <IconButton iconName="attach" iconSize={18} size={24} />
          </div>

          {image && (
            <div className={styles.Preview} onClick={handleRemove}>
              <img className={styles.Img} src={image} width={24} height={24} />

              <div className={styles.CrossLine} data-left />
              <div className={styles.CrossLine} data-right />
            </div>
          )}
        </>
      }
    />
  );
};

export default ImageSelector;
