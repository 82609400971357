import { FC, ReactNode } from 'react';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';

import styles from './WithColorPicker.module.scss';
import ColorPicker, { ColorPickerProps } from 'components/ColorPicker';

type Props = {
  children: ReactNode;
  panelClassName?: string;
} & ColorPickerProps;

const WithColorPicker: FC<Props> = ({ children, panelClassName, ...props }) => {
  return (
    <Popover className={styles.Popover}>
      <Popover.Button as="div" className={styles.Button}>
        {children}
      </Popover.Button>
      <Popover.Panel className={classNames(styles.Panel, panelClassName)}>
        {({ close }) => <ColorPicker {...props} close={close} />}
      </Popover.Panel>
    </Popover>
  );
};

export default WithColorPicker;
