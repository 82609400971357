import { EWelcomeBotState } from '../telegram/telegram.types';

export enum EPlanDisplayName {
  default = 'default',
  trial = 'trial',
}

export enum EPlanName {
  trial = 'TRIAL',
  default = 'DEFAULT',
}

export type Social = {
  phone?: string;
  telegram?: string;
  instagram?: string;
  vk?: string;
};

export type TWelcome = {
  countReferrals: number;
  isLinked: boolean;
  isActive?: boolean;
  service: string;
  serviceUsername: string;
  status: EWelcomeBotState;
};

export interface IUserPlan {
  id: string;
  name: EPlanName;
  displayName: EPlanDisplayName;
  periodName: string;
  brand?: string;
  startData: string;
  expiredDate: string;
}

export interface IUser {
  id: string;
  timezone: string;
  username: string;
  email: string;
  fullName: string;
  phone: string;
  avatar: string | null;
  balance: number;
  social: Social;
  role: string[];
  permissions: {
    trialExpired: boolean;
    emailNotConfirmed: boolean;
    firstEntry: boolean;
    telegramAllowed: boolean;
    businessCardAllowed: boolean;
    vkAllowed: boolean;
  };
  enabled: boolean;
  notification: number;
  plan: IUserPlan;
  inviter: Omit<IUser, 'inviter' | 'balance' | 'permissions' | 'notification' | 'plan'> | null;
}
