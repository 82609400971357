import { FC, useRef } from 'react';

import styles from './Finances.module.scss';
import TopSection from 'modules/finances/components/TopSection';
import TopUpMethods from 'modules/finances/components/TopUpMethods';
import WithdrawMethods from 'modules/finances/components/WithdrawMethods';
import Title from 'components/Title';
import ChangeSourceModal from 'modules/finances/components/ChangeSourceModal';
import FinPassModal from 'modules/finances/components/FinPassModal';
import PayeerConfirmModal from 'modules/finances/components/PayeerConfirmModal';
import { FreeKassaConfirmModal } from 'modules/finances/components/FreeKassaConfirmModal/FreeKassaConfirmModal';
import { AutoPayingMethods } from '../../components/AutoPayingMethods';
import { useSearchParams } from 'react-router-dom';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import useAppDispatch from 'hooks/useAppDispatch';
import financesApi from '../../finances.api';
import { EAppTagType } from '../../../../interfaces/api';

const Finances: FC = () => {
  const dispatch = useAppDispatch();
  const memoFinPassword = useRef<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const softpayParams = searchParams.get('softpay');
  const isSuccessWebHook = softpayParams === 'success';
  const isErrorWebHook = softpayParams === 'error';

  if (isSuccessWebHook) {
    showFeedback({
      title: 'Ураа',
      // TODO: https://stars-matrix.atlassian.net/browse/SO-1349
      // text: 'У вас активен Автоплатеж ',
      text: 'Пополнение прошло успешно',
      type: EFeedbackType.SUCCESS,
    }).then(() => {
      setSearchParams({});
      dispatch(
        financesApi.util.invalidateTags([
          EAppTagType.USER,
          EAppTagType.TRANSACTIONS,
          EAppTagType.SOFTPAYPRODUCTS_INFO,
        ]),
      );
    });
  }

  if (isErrorWebHook) {
    showFeedback({
      title: 'Ошибка',
      text: 'Произошла ошибка. Попробуйте еще раз',
      type: EFeedbackType.ERROR,
    }).then(() => {
      setSearchParams({});
    });
  }

  return (
    <div className={styles.Content}>
      <Title className={styles.Title}>Финансы</Title>
      <TopSection finPassword={memoFinPassword.current} />
      <AutoPayingMethods />
      <TopUpMethods />
      <WithdrawMethods />
      <PayeerConfirmModal />
      <FreeKassaConfirmModal />
      <FinPassModal
        onMemoriseFinPassword={password => {
          memoFinPassword.current = password;
        }}
      />
      <ChangeSourceModal />
    </div>
  );
};

export default Finances;
