import { FC, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';
import CheckedLine from '../CheckedLine';
import { TPeriodDto, TNumMap, TError, TPlanService } from 'modules/tariff/tariff.type';
import useAppDispatch from 'hooks/useAppDispatch';
import useTabs from 'hooks/useTabs';
import useDisclosure from 'hooks/useDisclosure';
import ResultModal from 'components/ResultModal';
import { selectSubscriptionPlan } from '../../tariff.reducer';
import LinkUnderlined from 'components/LinkUnderlined';
import confirm from 'utils/confirm';
import ConfirmModal from 'components/ConfirmModal';
import env from 'config/env';
import formatNumber from 'utils/formatNumber';
import { useUpdateUserPlanMutation } from '../../tariff.api';
import { selectUser } from 'modules/account/account.selector';
import useTypedSelector from 'hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import r from 'constants/routes';
import Tooltip from 'components/Tooltip';
import { colorLuminance } from 'utils/colorConverters';
import { delay } from 'lodash-es';

import styles from './Card.module.scss';
import useFirstRender from 'hooks/useFirstRender';

export type CardProps = {
  popular?: boolean;
  tariff: TPlanService;
  plan?: string | null;
  isLines?: boolean;
  brand?: string;
  mainImage?: string;
};

type TMap = {
  [key: string]: TPeriodDto;
};

type Props = {
  planData: TPeriodDto[];
} & CardProps;

const Card: FC<Props> = ({
  tariff: { displayName, description, services },
  plan,
  brand,
  isLines = true,
  planData,
  mainImage,
}) => {
  const dispatch = useAppDispatch();

  const tabs = useMemo(() => {
    return planData.reduce((acc, cur) => {
      acc[cur.days] = cur.name;
      return acc;
    }, {} as TNumMap);
  }, [planData]);

  const { activeTab, switchTab } = useTabs<string, typeof tabs>(tabs);
  const user = useTypedSelector(selectUser);

  const navigate = useNavigate();

  const [updatePlan, { isSuccess, isLoading }] = useUpdateUserPlanMutation();
  const isFirstRender = useFirstRender();
  const {
    open: successOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure({ blockScroll: true });

  const priceMap: TMap =
    useMemo(() => {
      return planData?.reduce((acc, cur) => {
        acc[cur.days] = { ...cur };
        return acc;
      }, {} as TMap);
    }, []) || {};

  const selectedTabPriceInfo = priceMap[activeTab?.name];
  const { price, planPeriodId, name } = selectedTabPriceInfo || ({} as TPeriodDto);
  const isActive = user?.plan.name === plan && name === user?.plan.periodName;

  const onModalClose = () => {
    onSuccessClose();
    delay(() => {
      window.location.reload();
    }, 0);
  };

  const getActivePlanKey = useMemo(
    () => Object.entries(tabs).find(i => i[1] === user?.plan.periodName)?.[0],
    [user?.plan.periodName, tabs],
  );

  useEffect(() => {
    if (user?.plan.name === plan && getActivePlanKey && isFirstRender) switchTab(getActivePlanKey);
  }, [user?.plan.name, isFirstRender, getActivePlanKey]);

  const onBuy = async () => {
    const confirms = await confirm(handleConfirm => (
      <ConfirmModal
        handleConfirm={handleConfirm}
        title="Приобретение тарифа"
        content={`Вы приобретаете тариф ${displayName.toUpperCase()}, стоимость которого ${formatNumber(
          price ?? 0,
        )} ${env.CURRENCY}, срок приобретаемого тарифа составит ${activeTab.content}.`}
        confirmText="Приобрести"
      />
    ));
    if (confirms) {
      let newObj: TError = {} as TError;
      const res = await updatePlan({
        planPeriodId,
      });

      if ('data' in res) {
        onSuccessOpen();
      }

      if ('error' in res) {
        newObj = JSON.parse(JSON.stringify(res.error));
        const { body } = newObj.data;
        if (body === 'Недостаточно средств') navigate(r.finances.index);
      }
      onSuccessOpen();
    }
  };

  const calculateBrandGradient = useMemo(() => {
    if (!brand) return 'rgba(20, 20, 20, 0.8)';
    return `linear-gradient(135.23deg, ${brand} 0%, ${colorLuminance(brand, 0.3)} 99.95%)`;
  }, [brand]);

  return (
    <>
      <div
        style={{ background: calculateBrandGradient }}
        className={classNames(styles.Card, {
          [styles.Small]: !isLines,
        })}
      >
        <div className={styles.InnerCard}>
          <div>
            <div className={styles.Top}>
              <Tooltip label={displayName}>
                <p className={styles.Title}>{displayName}</p>
              </Tooltip>
            </div>
            <div className={styles.NumBack}>
              <div className={styles.Wrapper}>
                <p className={styles.Top}>{price ?? 0}</p>
                <span>Руб.</span>
              </div>
              <p className={styles.Back}>{price}</p>
            </div>
            <ul className={styles.Periods}>
              {Object.entries(tabs).map(([key, value]) => (
                <li
                  key={key}
                  data-is-active={activeTab.name === key}
                  onClick={() => switchTab(key)}
                >
                  {value}
                </li>
              ))}
            </ul>
            <div className={styles.Video}>
              {mainImage ? <img src={mainImage} alt={displayName} /> : null}
            </div>
          </div>

          <div className={styles.InnerTextContainer}>
            <p className={styles.Text}>{description ?? 'No description'}</p>

            {isLines && (
              <div className={styles.Lines}>
                {services.map((service, i) => (
                  <CheckedLine
                    key={service + i}
                    notActive={!Boolean(services)}
                    text={service.toLowerCase()}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className={styles.Footer}>
          <LinkUnderlined
            btnText="Подробнее"
            onClick={() => dispatch(selectSubscriptionPlan(plan))}
          />

          {isActive ? (
            <Tooltip label="Продлить тариф ">
              <p onClick={onBuy} className={styles.SelectedPlan}>
                Ваш план
              </p>
            </Tooltip>
          ) : (
            <Button variant="white" className={styles.Btn} onClick={onBuy}>
              Выбрать план
            </Button>
          )}
        </div>
      </div>
      <ResultModal
        isSuccess={isSuccess}
        isLoading={isLoading}
        onSubmit={e => {
          e?.preventDefault();
          onModalClose();
        }}
        isOpen={successOpen}
        onClose={onModalClose}
      />
    </>
  );
};

export default Card;
