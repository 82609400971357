import { FC, useMemo } from 'react';
import styles from './WelcomeBotStatisticsMobileCard.module.scss';
import { TWelcomeBotContactsStatistic } from '../../../telegram.types';
import { findFirstError } from '../../../telegram.utils';
import dayjs from 'dayjs';
import Icon from 'components/Icon';
import { isEmpty } from 'lodash-es';

export const WelcomeBotStatisticsMobileCard: FC<TWelcomeBotContactsStatistic> = ({
  lastName,
  firstName,
  sendHistory,
  phone,
  username,
  hasError,
}) => {
  const sendHistoryMemorised = useMemo(() => {
    return findFirstError(sendHistory);
  }, [sendHistory]);
  return (
    <div className={styles.Root}>
      <div className={styles.Upper}>
        <h5 className={styles.DateString}>
          {sendHistoryMemorised?.dateSend
            ? dayjs(sendHistoryMemorised?.dateSend).format('DD.MM.YYYY HH:mm')
            : dayjs(sendHistory[0].dateSend).format('DD.MM.YYYY HH:mm')}
        </h5>
        <h5 className={styles.Status}>
          {hasError ? (
            <>
              <Icon iconName="alert-circle" size={24} />
              <span className={styles.Error}> Не отправлено</span>
            </>
          ) : (
            <>
              <Icon iconName="check-circle" size={24} className={styles.Check} />
              <span className={styles.Text}>Выполнено</span>
            </>
          )}
        </h5>
      </div>
      <div className={styles.Name}>
        <span>{firstName}</span> <span>{lastName}</span>
      </div>
      <div className={styles.Login}>
        <h5>@{username}</h5>
      </div>
      <div className={styles.Phone}>
        <h5>{isEmpty(phone) ? '-' : phone}</h5>
      </div>
    </div>
  );
};
