import { FC, ReactNode } from 'react';
import { Dialog } from '@headlessui/react';

import styles from './FeedbackModal.module.scss';
import Button from 'components/Button';
import thinkingFaceImg from 'assets/svg/feedback/thinking-face.svg';
import partyingFaceImg from 'assets/svg/feedback/partying-face.svg';
import smilingFaceWithHaloImg from 'assets/svg/feedback/smiling-face-with-halo.svg';
import ModalOverlay from 'components/ModalOverlay';

export enum EFeedbackType {
  ERROR,
  CONGRATS,
  SUCCESS,
}

const FEEDBACK_IMAGES: Record<EFeedbackType, string> = {
  [EFeedbackType.ERROR]: thinkingFaceImg,
  [EFeedbackType.CONGRATS]: partyingFaceImg,
  [EFeedbackType.SUCCESS]: smilingFaceWithHaloImg,
};

export type FeedbackModalProps = {
  title: ReactNode;
  text: ReactNode;
  onClose: () => void;
  type: EFeedbackType;
};

const FeedbackModal: FC<FeedbackModalProps> = ({ title, text, onClose, type }) => {
  return (
    <Dialog open onClose={onClose}>
      <ModalOverlay>
        <Dialog.Panel>
          <div className={styles.Content}>
            <h3 className={styles.Title}>{title}</h3>
            <img
              className={styles.Img}
              src={FEEDBACK_IMAGES[type]}
              width={94}
              height={98}
              alt="Feedback image"
            />
            <p className={styles.Text}>{text}</p>
            <Button className={styles.Button} variant="primary" size="l" onClick={onClose}>
              Закрыть
            </Button>
          </div>
        </Dialog.Panel>
      </ModalOverlay>
    </Dialog>
  );
};

export default FeedbackModal;
