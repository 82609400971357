import { FC } from 'react';

import styles from './Header.module.scss';
import HeaderWithTabs from 'components/HeaderWithTabs';
import r from 'constants/routes';
import VkAccountsSelector from '../VkAccountsSelector';
import NavWithTabsMobile from 'components/NavWithTabsMobile';

export const links = [
  { name: 'Друзья', link: r.vk.index },
  { name: 'Группы', link: r.vk.groupsActions },
  { name: 'Автоматизация', link: r.vk.automationActions },
];

const Header: FC = () => {
  return (
    <>
      <HeaderWithTabs isVkHeader className={styles.Header} links={links}>
        <VkAccountsSelector />
      </HeaderWithTabs>
      <NavWithTabsMobile isVkNav className={styles.Nav} links={links} />
    </>
  );
};

export default Header;
