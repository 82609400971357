import { FC, useEffect } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import find from 'lodash-es/find';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';

import styles from './AccountSelector.module.scss';
import Icon from 'components/Icon';
import { isFunction } from 'lodash-es';

export type Account = {
  id: number | string;
  avatar: string;
  name: string;
  onDelete?: () => void;
};

type Props = {
  accounts?: Account[] | null;
  selectedAccount?: Account['id'];
  onSelectAccount?: (acc: Account) => void;
};

const AccountSelector: FC<Props> = ({ accounts, selectedAccount, onSelectAccount }) => {
  const selectedAcc = find(accounts, { id: selectedAccount });

  useEffect(() => {
    if (
      !selectedAcc &&
      !isNull(accounts) &&
      !isUndefined(accounts) &&
      !isEmpty(accounts) &&
      onSelectAccount
    ) {
      onSelectAccount(accounts[0]);
    }
  }, [accounts, onSelectAccount, selectedAcc]);

  return isEmpty(accounts) ? (
    <>
      <p className={styles.NoAccText}>Аккаунт не добавлен</p>
    </>
  ) : (
    <Menu as="div" className={({ open }) => classNames(styles.Menu, { [styles.Open]: open })}>
      <Menu.Button as="button" className={styles.Btn}>
        <>
          <div className={styles.Name}>{selectedAcc?.name}</div>
          <div className={styles.Trigger}>
            <img
              className={styles.Avatar}
              src={selectedAcc?.avatar}
              alt={`${selectedAcc?.name}'s avatar`}
              width={36}
              height={36}
            />
            <Icon className={styles.Icon} iconName="arrow-up" size={8} />
          </div>
        </>
      </Menu.Button>
      <Menu.Items as="ul" className={styles.Items}>
        {accounts?.map(acc => (
          <Menu.Item
            key={acc.name}
            as="li"
            className={styles.Item}
            data-selected={selectedAcc?.name === acc.name}
          >
            <span onClick={() => onSelectAccount?.(acc)}>{acc.name}</span>
            <button
              onClick={() => {
                if (isFunction(acc?.onDelete)) acc.onDelete();
              }}
              className={styles.Span}
            >
              Выйти
            </button>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default AccountSelector;
