import { FC } from 'react';

import styles from './Nav.module.scss';
import LandingGeneratorUndoRedo from './components/LandingGeneratorUndoRedo';
import Options from './components/Options';
import ColorChanger from './components/ColorChanger';
import { LandingIdType } from 'modules/landing-generator/landing-generator.types';

type Props = {
  id: LandingIdType;
};

const Nav: FC<Props> = ({ id }) => {
  return (
    <nav className={styles.Nav}>
      <ColorChanger id={id} />

      <div className={styles.Right}>
        <LandingGeneratorUndoRedo />
        <Options />
      </div>
    </nav>
  );
};

export default Nav;
