import { FC } from 'react';

import styles from './Partnership.module.scss';
import commonStyles from '../../Sections.module.scss';
import MediumCard from '../../../Card/MediumCard';
import num1 from 'assets/svg/account/middleCard/firstNum.svg';
import num2 from 'assets/svg/account/middleCard/secondNum.svg';
import num3 from 'assets/svg/account/middleCard/thirdNum.svg';
import back1 from 'assets/svg/account/middleCard/FirstBack.svg';
import back2 from 'assets/svg/account/middleCard/SecondBack.svg';
import back3 from 'assets/svg/account/middleCard/ThirdBack.svg';
import SectionTitle from 'modules/account/components/SectionTitle';
import { PartnerLines } from 'modules/partnership-program/partnership-program.types';

const middleCardValues = [
  { id: PartnerLines.FIRST, num: num1, backPhoto: back1, background: 'persian' },
  { id: PartnerLines.SECOND, num: num2, backPhoto: back2, background: 'magic' },
  { id: PartnerLines.THIRD, num: num3, backPhoto: back3, background: 'magenta' },
];

const Partnership: FC = () => {
  return (
    <section className={commonStyles.Section}>
      <SectionTitle>Партнерская программа</SectionTitle>
      <div className={styles.Cards}>
        {middleCardValues.map(value => (
          <MediumCard key={value.id} {...value} />
        ))}
      </div>
    </section>
  );
};

export default Partnership;
