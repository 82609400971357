import { FC, ReactNode, useRef } from 'react';
import FocusTrap from 'focus-trap-react';
import classNames from 'classnames';

import styles from './ContextMenu.module.scss';
import useClickOutside from 'hooks/useClickOutside';

export type CommonContextMenuProps = {
  open: boolean;
  onClose: () => void;
  className?: string;
  disableClickOutside?: boolean;
  disableFocusTrap?: boolean;
  withBridge?: boolean;
};

type Props = {
  children: ReactNode;
} & CommonContextMenuProps;

const ContextMenu: FC<Props> = ({
  open,
  onClose,
  children,
  className,
  disableClickOutside,
  disableFocusTrap,
  withBridge,
}) => {
  const ref = useRef<HTMLElement>(null);

  useClickOutside(ref, disableClickOutside ? () => {} : onClose);

  return open ? (
    <FocusTrap focusTrapOptions={{ initialFocus: false }} active={!disableFocusTrap}>
      <nav ref={ref} className={classNames(styles.Nav, className)} data-with-bridge={withBridge}>
        {children}
      </nav>
    </FocusTrap>
  ) : null;
};

export default ContextMenu;
