import { FC } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import r from 'constants/routes';
import styles from './Breadcrumbs.module.scss';
import Icon from 'components/Icon';
import Title from 'components/Title';
import PartnershipSearchField from '../PartnershipSearchField';
import { useLinesParams } from 'modules/partnership-program/partnership-program.hooks';
import { PartnerLines } from 'modules/partnership-program/partnership-program.types';
import { setOffset } from '../../partnership-program.reducer';
import useAppDispatch from 'hooks/useAppDispatch';

const getLines = (line: PartnerLines) => {
  switch (line) {
    case PartnerLines.FIRST:
      return [PartnerLines.FIRST, PartnerLines.SECOND] as const;
    case PartnerLines.SECOND:
      return [PartnerLines.FIRST, PartnerLines.THIRD] as const;
    case PartnerLines.THIRD:
      return [PartnerLines.SECOND, PartnerLines.THIRD] as const;
  }
};
const Breadcrumbs: FC = () => {
  const { t } = useTranslation();
  const line = useLinesParams();
  const [prev, next] = getLines(line);

  const dispatch = useAppDispatch();

  const handleRemovePagination = () => {
    dispatch(setOffset(0));
  };

  return (
    <div className={styles.Breadcrumbs}>
      <div className={styles.Left}>
        <div className={styles.Navigation}>
          <NavLink
            to={generatePath(r.partnershipProgram.index, { line: prev })}
            className={styles.Link}
            onClick={handleRemovePagination}
          >
            <Icon
              iconName="arrow-right"
              size={12}
              className={classNames(styles.Left, {
                [styles.Clickable]: line !== PartnerLines.FIRST,
              })}
            />
          </NavLink>
          <NavLink
            to={generatePath(r.partnershipProgram.index, { line: next })}
            className={styles.Link}
            onClick={handleRemovePagination}
          >
            <Icon
              iconName="arrow-right"
              size={12}
              className={classNames(styles.Right, {
                [styles.Clickable]: line !== PartnerLines.THIRD,
              })}
            />
          </NavLink>
        </div>
        <NavLink
          to={generatePath(r.partnershipProgram.index, { line })}
          className={classNames(styles.Link, styles.TitleLink)}
        >
          <Title className={styles.Title}>{t(`partnersLine.${line}`)}</Title>
        </NavLink>
      </div>

      <div>
        <PartnershipSearchField />
      </div>
    </div>
  );
};

export default Breadcrumbs;
