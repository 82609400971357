import { CSSProperties, FC } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import styles from './DateTime.module.scss';

type Props = {
  date: string;
  size?: number;
  className?: string;
  noDivider?: boolean;
};

const DateTime: FC<Props> = ({ date, size = 14, className, noDivider }) => {
  const style: CSSProperties = {
    fontSize: size,
  };

  return (
    <time className={classNames(styles.Wrapper, className)}>
      <div className={styles.Date} style={style}>
        {dayjs(date).format('DD.MM.YYYY')}
      </div>
      <div className={styles.Divider} data-no-dot={noDivider} />
      <div className={styles.Date} style={style}>
        {dayjs(date).format('HH:mm')}
      </div>
    </time>
  );
};

export default DateTime;
