import { FC, FormEvent } from 'react';

import styles from './Form.module.scss';
import { LandingForm, LandingIdType } from 'modules/landing-generator/landing-generator.types';
import DependentText from '../DependentText';
import Button from 'components/Button';
import Input from './components/Input';
import Modalable from '../Modalable';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  removeLandingFormModalButton,
  setLandingFormModalButtonColor,
} from 'modules/landing-generator/landing-generator.reducer';
import isNull from 'lodash-es/isNull';
import { fieldsNamedEntries } from 'modules/landing-generator/landing-generator.getters';

type Props = {
  id: LandingIdType;
  form?: LandingForm | null;
};

const Form: FC<Props> = ({ id, form }) => {
  const dispatch = useAppDispatch();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  return form ? (
    <Modalable
      button={form.modal}
      onSetColor={color => dispatch(setLandingFormModalButtonColor({ id, color }))}
      onRemoveBtn={() => dispatch(removeLandingFormModalButton({ id }))}
    >
      <div className={styles.Wrapper}>
        <div className={styles.Header}>
          <DependentText type="title" {...form.title} />
          <DependentText type="text" {...form.text} />
        </div>

        <form className={styles.Form} onSubmit={onSubmit}>
          {fieldsNamedEntries.map(
            ([key, value]) =>
              !isNull(form.fields[key]) && <Input key={key} label={value ?? ''} required />,
          )}

          <Button className={styles.Button} type="submit">
            {form.buttonName}
          </Button>
        </form>
      </div>
    </Modalable>
  ) : null;
};

export default Form;
