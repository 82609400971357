import { SchemaOf, object, string, number, boolean, array } from 'yup';
import i18n from 'i18n';

import {
  AddBotRequestBody,
  AddSpamBotAccountRequestBody,
  SetIntervalRequestBody,
  AddSpamBotGroupRequestBody,
  GreetingBotTextRequestBody,
  AddGreetingBotAccountRequestBody,
  AddGroupRequestBody,
  UpdateAdminMessage,
  TCommand,
  TAdminMessageForm,
  WelcomeBotAuthBody,
  AddGreetingBotAccountValidate,
} from './telegram.types';
import {
  BotAdminUpdateWelcomeMessagePayload,
  UpdateBotAdminCommandPayload,
} from './codegen/telegram.admin.api';

export const addBotSchema = (): SchemaOf<AddBotRequestBody> =>
  object()
    .shape({
      name: string()
        .required()
        .matches(/^[a-zA-Z0-9А-Яа-я\s]*$/, 'неправильный формат')
        .label(i18n.t('labels.name')),
      token: string().required().label(i18n.t('labels.token')),
    })
    .required();

export const editAdminBotSchema = () =>
  object()
    .shape({
      name: string().required().label(i18n.t('labels.name')),
      token: string().required().label(i18n.t('labels.token')),
    })
    .required();

export const addSpamBotAccountSchema = (): SchemaOf<AddSpamBotAccountRequestBody> =>
  object()
    .shape({
      firstName: string().required().label(i18n.t('labels.firstName')),
      lastName: string().required().label(i18n.t('labels.lastName')),
      message: string().required().label(i18n.t('labels.message')),
    })
    .required();

export const setIntervalSchema = (): SchemaOf<SetIntervalRequestBody> =>
  object()
    .shape({
      interval: number().required().label(i18n.t('labels.interval')),
    })
    .required();

export const addSpamBotGroupSchema = (): SchemaOf<AddSpamBotGroupRequestBody> =>
  object()
    .shape({
      name: string().required().label(i18n.t('labels.name')),
      link: string().url().required().label(i18n.t('labels.linkGroup')),
      message: string().required().label(i18n.t('labels.message')),
    })
    .required();

export const greetingBotTextSchema = (): SchemaOf<GreetingBotTextRequestBody> =>
  object()
    .shape({
      text: string().required().label(i18n.t('labels.text')),
    })
    .required();

export const addGreetingBotAccountSchema = (): SchemaOf<AddGreetingBotAccountRequestBody> =>
  object()
    .shape({
      firstName: string().required().label(i18n.t('labels.firstName')),
      lastName: string().required().label(i18n.t('labels.lastName')),
    })
    .required();

export const addGroupSchema = (): SchemaOf<AddGroupRequestBody> =>
  object()
    .shape({
      name: string().required().label(i18n.t('labels.nameGroup')),
    })
    .required();

export const addGreetingBotAccount = (): SchemaOf<WelcomeBotAuthBody> =>
  object()
    .shape({
      phone: string().required().label(i18n.t('labels.nameGroup')),
      name: string().required().label(i18n.t('labels.nameGroup')),
    })
    .required();

export const addGreetingBotAccountCode = (): SchemaOf<AddGreetingBotAccountValidate> =>
  object()
    .shape({
      code: number().required().label(i18n.t('labels.nameGroup')),
      cloudCode: string().label(i18n.t('labels.nameGroup')),
    })
    .required();

export const adminBotCommandUpdate = (): SchemaOf<Omit<UpdateBotAdminCommandPayload, 'files'>> =>
  object()
    .shape({
      name: string(),
      description: string(),
      text: string(),
    })
    .required();

export const botWelcomeMessage = (): SchemaOf<Omit<BotAdminUpdateWelcomeMessagePayload, 'files'>> =>
  object()
    .shape({
      text: string(),
    })
    .required();

export const botMessage = (): SchemaOf<UpdateAdminMessage> =>
  object()
    .shape({
      text: string(),
      persist: boolean(),
      dates: array().of(string()),
      hours: number(),
      minutes: number(),
    })
    .required();

export const commandSchema = (): SchemaOf<TCommand> =>
  object()
    .shape({
      name: string().required(),
      description: string().required(),
      text: string().required(),
    })
    .required();

export const plannedMessageSchema = (): SchemaOf<
  Pick<TAdminMessageForm, 'dates' | 'text' | 'times' | 'files' | 'repeatType'>
> => {
  return object()
    .shape({
      dates: array().of(string()).notRequired(),
      text: string().required(),
      times: array().of(string()).required(),
      files: array().of(string()).required(),
    })
    .notRequired();
};

export const connectWelcomeBotSchema = (): SchemaOf<{ serviceName: string }> =>
  object()
    .shape({
      serviceName: string().required(),
    })
    .required();

export const settingWelcomeBotSchema = (): SchemaOf<{ text: string }> =>
  object()
    .shape({
      text: string().required(),
    })
    .required();
