import { FC } from 'react';
import styles from './SpamBotChatTableHead.module.scss';
import useWindowSize from 'hooks/useWindowSize';
import CheckBox from 'components/CheckBox';
import SortIcon from '../../../../../../components/ChatList/ChatTable/SortIcon';
import Button from 'components/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { setSelectedAllChats } from '../../../../../../codegen/telegram.spam.reducer';
import { useGetSpamBotChats } from '../../../../../../codegen/telegram.spam.selectors';
import { useDeleteAllSpamChatsBotsMutation } from '../../../../../../codegen/telegram.spam.api';
import { useBotId } from '../../../../../../telegram.hooks';
import { TTelegramErrorResult } from '../../../../../../telegram.types';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { isEmpty } from 'lodash-es';

const SpamBotChatTableHead: FC = () => {
  const { width } = useWindowSize();
  const botId = useBotId();
  const [deleteChats, { isLoading }] = useDeleteAllSpamChatsBotsMutation();
  const isDesktop = width > 625;
  const data = useGetSpamBotChats();
  const isButtonActive = data.data.some(i => i.isSelected);
  const isChecked = !isEmpty(data.data) && data.data.every(chat => chat.isSelected === true);

  const deleteChatsHandler = () => {
    const chatIds: string[] = [];

    data.data.forEach(i => {
      if (i.isSelected) {
        chatIds.push(i.id);
      }
    });

    deleteChats({ chatIds, botId }).then(res => {
      if ('error' in res) {
        const clonedError: TTelegramErrorResult = JSON.parse(JSON.stringify(res));
        showFeedback({
          title: 'Ошибка',
          text: clonedError.error.data.description,
          type: EFeedbackType.ERROR,
        });
      } else {
        showFeedback({
          title: 'ГОТОВО!',
          text: 'Вы удалили чат',
          type: EFeedbackType.SUCCESS,
        });
      }
    });
  };

  const dispatch = useAppDispatch();
  return (
    <thead className={styles.TableHead}>
      <tr className={styles.TableHeadTr}>
        <th>
          <div className={styles.Section}>
            <CheckBox
              className={styles.Checkbox}
              onClick={() => {
                dispatch(setSelectedAllChats());
              }}
              checked={isChecked}
            />
            <p className={styles.Par}>Название чата</p>
            <SortIcon />
          </div>
        </th>

        {isDesktop && (
          <th>
            <div className={styles.Section}>
              <p className={styles.Par}>Статус</p>
              <SortIcon />
            </div>
          </th>
        )}

        <th className={styles.LastTh}>
          {isButtonActive && (
            <Button
              onClick={deleteChatsHandler}
              disabled={isLoading}
              variant={'text'}
              className={styles.Btn}
            >
              {/*{isDesktop ? 'Покинуть выбранные чаты' : 'Покинуть выбранные'}*/}
              Покинуть выбранные чаты
            </Button>
          )}
        </th>
      </tr>
    </thead>
  );
};

export default SpamBotChatTableHead;
