import IconButton from 'components/IconButton';
import SearchField from 'components/SearchField';
import MailingCard from 'modules/bot-builder/components/MailingCard';
import { FC } from 'react';

import styles from './Mailing.module.scss';

const Mailing: FC = () => {
  return (
    <div className={styles.Page}>
      <div className={styles.Header}>
        <SearchField
          className={styles.SearchField}
          placeholder="Поиск по названию"
          onSearch={() => {}}
        />
        <IconButton
          className={styles.AddBtn}
          iconClassName={styles.AddBtnIcon}
          iconName="add"
          size={52}
          iconSize={28}
          onClick={() => {}}
          asCircle
          withBorder
        />
      </div>
      <div className={styles.List}>
        <MailingCard
          id={1}
          name="Каталог опрыскивателей"
          created="2022/08/12"
          changed="2022/08/12"
          active
        />
        <MailingCard
          id={2}
          name="Каталог опрыскивателей"
          created="2022/08/12"
          active={false}
          shouldRepeat
        />
      </div>
    </div>
  );
};

export default Mailing;
