import isEqual from 'lodash-es/isEqual';

import { Landing, LandingButton } from './landing-generator.types';

export const compareLandingText = (a: Landing, b: Landing): boolean => {
  if (a?.text && b?.text) {
    const prev = [
      ...b.text.title.map(t => t.content),
      ...b.text.subtitle.map(t => t.content),
      ...b.text.text.map(t => t.content),
    ];
    const curr = [
      ...a.text.title.map(t => t.content),
      ...a.text.subtitle.map(t => t.content),
      ...a.text.text.map(t => t.content),
    ];
    return isEqual(curr, prev);
  }
  return true;
};

export const compareLandingButtons = (a: LandingButton, b: LandingButton): boolean => {
  const prev = [
    b.vk?.content,
    b.viber?.content,
    b.whatsapp?.content,
    b.instagram?.content,
    b.facebook?.content,
    b.telegram?.content,
    b.modal?.buttonName,
    b.orientation,
    ...b.websites.map(w => w.text.content),
    ...b.websites.map(w => w.title.content),
    ...b.websites.map(w => w.url),
  ];
  const curr = [
    a.vk?.content,
    a.viber?.content,
    a.whatsapp?.content,
    a.instagram?.content,
    a.facebook?.content,
    a.telegram?.content,
    a.modal?.buttonName,
    a.orientation,
    ...a.websites.map(w => w.text.content),
    ...a.websites.map(w => w.title.content),
    ...a.websites.map(w => w.url),
  ];
  return isEqual(curr, prev);
};
