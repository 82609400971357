import { FC } from 'react';

import styles from './Notifications.module.scss';

import Header from '../components/Header';
import Messages from '../components/Messages';

const Notifications: FC = () => {
  return (
    <div className={styles.Page}>
      <Header />
      <Messages />
    </div>
  );
};

export default Notifications;
