import { FC } from 'react';
import styles from './FilesWrapper.module.scss';
import { formatBytesToGB } from 'utils/formatBytesToGb';
import Icon from 'components/Icon';
import { getFileName } from '../../telegram.utils';

type Props = {
  files: File[];
  onFileClose: (file: File) => void;
};

const FilesWrapper: FC<Props> = ({ files, onFileClose }) => {
  return (
    <div className={styles.FilesWrapper}>
      <p className={styles.Par}>Прикреплённые файлы:</p>

      <div className={styles.Files}>
        {files?.map((file: File) => (
          <div key={file?.name} className={styles.File}>
            <h5 className={styles.FileName}>{getFileName(file)}</h5>
            <p className={styles.FileSize}>{formatBytesToGB(file.size)}</p>
            <Icon size={18} iconName={'close'} onClick={() => onFileClose(file)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilesWrapper;
