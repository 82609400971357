import { FC, CSSProperties, useMemo } from 'react';

import styles from './SocialNetwork.module.scss';
import {
  EOrientation,
  EShape,
  Landing,
  LandingButtonSocialNetwork,
  SocialNetworkProperties,
} from 'modules/landing-generator/landing-generator.types';
import EditableElement from '../../../EditableElement';
import useAppDispatch from 'hooks/useAppDispatch';
// img
import vkImg from 'assets/img/social/vk.png';
import viberImg from 'assets/img/social/viber.png';
import whatsappImg from 'assets/img/social/whatsapp.png';
import instagramImg from 'assets/img/social/instagram.png';
import facebookImg from 'assets/img/social/facebook.png';
import tgImg from 'assets/img/social/tg.png';
import {
  deleteLandingButtonSocialNetwork,
  // setLandingButtonSocialNetworkColor,
  setLandingButtonSocialNetworkShape,
} from 'modules/landing-generator/landing-generator.reducer';
import useDisclosure from 'hooks/useDisclosure';
// import ColorPickerInContextMenu from 'components/ColorPickerInContextMenu';
// import { rgba2string, stringToRgba } from 'utils/colorConverters';

const imgMap: Record<LandingButtonSocialNetwork, { img: string; name: string }> = {
  vk: { img: vkImg, name: 'Vkontakte' },
  viber: { img: viberImg, name: 'Viber' },
  whatsapp: { img: whatsappImg, name: 'WhatsApp' },
  instagram: { img: instagramImg, name: 'Instagram' },
  facebook: { img: facebookImg, name: 'Facebook' },
  telegram: { img: tgImg, name: 'Telegram' },
};

type Props = {
  id: Landing['id'];
  btnId: Landing['id'];
  orientation: EOrientation;
  type: LandingButtonSocialNetwork;
  network: SocialNetworkProperties;
};

const SocialNetwork: FC<Props> = ({ id, btnId, type, network, orientation }) => {
  const { onOpen: onColorOpen, onClose: onColorClose } = useDisclosure();
  const dispatch = useAppDispatch();

  const cssVars = {
    '--bg-color': network.color,
  } as CSSProperties;

  const menuElement = useMemo(() => {
    const elements = [
      { iconName: 'pick-color', onClick: onColorOpen },
      {
        iconName: 'delete',
        onClick: () => dispatch(deleteLandingButtonSocialNetwork({ id, btnId, type })),
      },
    ];

    if (orientation === EOrientation.HORIZONTAL) {
      elements.splice(
        1,
        0,
        {
          iconName: 'circle',
          onClick: () =>
            dispatch(setLandingButtonSocialNetworkShape({ id, btnId, type, shape: EShape.CIRCLE })),
        },
        {
          iconName: 'square',
          onClick: () =>
            dispatch(setLandingButtonSocialNetworkShape({ id, btnId, type, shape: EShape.SQUARE })),
        },
      );
    }

    return elements;
  }, [orientation, id, btnId, type, onColorOpen, dispatch]);

  return (
    <EditableElement
      menuElements={menuElement}
      onClose={onColorClose}
      // menuAddon={
      //   <ColorPickerInContextMenu
      //     open={colorOpen}
      //     color={stringToRgba(network.color)}
      //     setColor={color =>
      //       dispatch(
      //         setLandingButtonSocialNetworkColor({ id, btnId, type, color: rgba2string(color) }),
      //       )
      //     }
      //   />
      // }
    >
      <div
        className={styles.Wrapper}
        data-shape={network.shape}
        data-orientation={orientation}
        style={cssVars}
      >
        <img
          className={styles.Logo}
          src={imgMap[type].img}
          alt={`${network}'s logo`}
          width={32}
          height={32}
        />

        {orientation === EOrientation.VERTICAL && (
          <h3 className={styles.Name}>{imgMap[type].name}</h3>
        )}
      </div>
    </EditableElement>
  );
};

export default SocialNetwork;
