import { FC, FormEvent, ReactNode } from 'react';

import styles from './SettingsForm.module.scss';

type Props = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  children: ReactNode;
};

const SettingsForm: FC<Props> = ({ onSubmit, children }) => {
  return (
    <form className={styles.Form} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default SettingsForm;
