import { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  children: ReactNode;
  container?: Element;
};

const Portal: FC<Props> = ({ container = document.body, children }) => {
  return ReactDOM.createPortal(children, container);
};

export default Portal;
