import { FC, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './NavigationMobile.module.scss';
import { useMobileNavOpen } from 'modules/app/app.selectors';
import IconButton from 'components/IconButton';
import useAppDispatch from 'hooks/useAppDispatch';
import { setMobileNavOpen } from 'modules/app/app.reducer';
import Header from './components/Header';
import useClickOutside from 'hooks/useClickOutside';
import Divider from 'components/Divider';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import TariffEndNotice from '../TariffEndNotice';
import { Advertising } from '../Advertising';
import { addsMap } from '../../constants/adds';

const NavigationMobile: FC = () => {
  const dispatch = useAppDispatch();
  const open = useMobileNavOpen();
  const navRef = useRef<HTMLElement>(null);
  const { pathname } = useLocation();

  const closeNav = useCallback(() => {
    dispatch(setMobileNavOpen(false));
  }, [dispatch]);

  useClickOutside(navRef, closeNav);
  useEffect(closeNav, [pathname, closeNav]);

  return (
    <div className={styles.Wrapper} data-open={open}>
      <nav className={styles.Nav} ref={navRef}>
        <IconButton iconName="close" iconSize={14} className={styles.Close} onClick={closeNav} />
        <Header />
        <Divider />
        <Navigation />
        <Divider />
        <div className={styles.Margin} />
        <div className={styles.Adds}>
          <Advertising imageUrl={addsMap.sidebar.imgUrl} link={addsMap.sidebar.link} />
        </div>
        <div className={styles.Margin} />
        <TariffEndNotice />
        <Footer />
      </nav>
    </div>
  );
};

export default NavigationMobile;
