import { FC, useEffect } from 'react';

import styles from './Published.module.scss';

import Preview from 'modules/my-pages/components/Preview';
import { useGetPublishedPageQuery } from 'modules/landing-dashboard/landing-dashboard.api';
import { useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { setActivePage } from 'modules/my-pages/my-pages.reducer';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';

const Published: FC = () => {
  const dispatch = useAppDispatch();
  const { link } = useParams();
  const { data, isError, isLoading } = useGetPublishedPageQuery({ link });
  const page = useActivePage();

  useEffect(() => {
    if (!isError && data) dispatch(setActivePage(data.body));
  }, [data]);

  if (isLoading) return null;
  if (isError) return null;
  return (
    <main className={styles.Doc}>
      <section className={styles.Preview}>
        <Preview mode="published" page={page} />
      </section>
    </main>
  );
};

export default Published;
