export const FilterIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5295_62570)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.819428 1.93434C0.942321 1.66947 1.20777 1.5 1.49977 1.5H16.4998C16.7918 1.5 17.0572 1.66947 17.1801 1.93434C17.303 2.19922 17.261 2.51134 17.0724 2.73429L11.2498 9.61961V15.75C11.2498 16.0099 11.1152 16.2513 10.8941 16.388C10.673 16.5246 10.3968 16.5371 10.1644 16.4208L7.16435 14.9208C6.91027 14.7938 6.74977 14.5341 6.74977 14.25V9.61961L0.927088 2.73429C0.73854 2.51134 0.696534 2.19922 0.819428 1.93434ZM3.11624 3L8.07244 8.86071C8.18694 8.9961 8.24977 9.16768 8.24977 9.345V13.7865L9.74977 14.5365V9.345C9.74977 9.16768 9.81259 8.9961 9.92709 8.86071L14.8833 3H3.11624Z"
          fill="#D0D0DA"
        />
      </g>
      <defs>
        <clipPath id="clip0_5295_62570">
          <rect width="18" height="18" fill="white" transform="translate(-0.000244141)" />
        </clipPath>
      </defs>
    </svg>
  );
};
