import { ChangeEvent, FC, useEffect, useState } from 'react';

import styles from './OpacitySelector.module.scss';
import Tool from '../Tool';

type Props = {
  opacity: number;
  setOpacity: (opacity: number) => void;
};

const OpacitySelector: FC<Props> = ({ opacity, setOpacity }) => {
  const [inputValue, setInputValue] = useState<string>((opacity * 100).toString());

  useEffect(() => {
    setInputValue((opacity * 100).toString());
  }, [opacity]);

  useEffect(() => {
    const nextOpacity = inputValue ? +inputValue / 100 : 0;
    setOpacity(nextOpacity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleSetInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numValue = +value;
    if (!value || (numValue >= 0 && numValue <= 100)) {
      setInputValue(value);
    }
  };

  const handleBlur = () => {
    if (!inputValue) {
      setInputValue('0');
    }
  };

  return (
    <Tool
      label="Прозрачность:"
      right={
        <>
          <input
            className={styles.Input}
            value={inputValue}
            onChange={handleSetInputValue}
            onBlur={handleBlur}
            type="number"
            min={0}
            max={100}
          />
          <span className={styles.Percent}>%</span>
        </>
      }
    />
  );
};

export default OpacitySelector;
