import { FC, useEffect, useState } from 'react';

import styles from './Photo.module.scss';
import { LandingPhoto } from 'modules/landing-generator/landing-generator.types';

const photoStub = 'https://lightwidget.com/wp-content/uploads/local-file-not-found-480x488.png';

type Props = {
  photo?: LandingPhoto | null;
};

const Photo: FC<Props> = ({ photo }) => {
  const [showStub, setShowStub] = useState(false);

  const onImgError = () => {
    setShowStub(true);
  };

  useEffect(() => {
    setShowStub(false);
  }, [photo]);

  if (!photo) return null;
  return (
    <div className={styles.Wrapper}>
      <img
        className={styles.Photo}
        onError={onImgError}
        src={showStub ? photoStub : photo.url}
        alt="Landing photo"
        data-shape={photo.shape}
      />
    </div>
  );
};

export default Photo;
