import { FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '../Header';
import Circles from 'components/Circles';

import styles from './Layout.module.scss';
import MobileFooter from 'modules/telegram/components/MobileFooter';
import { useSelectUser } from '../../../account/account.selector';
import EmptyPage from 'components/EmptyPage';
import lock from 'assets/svg/lock.svg';
import r from 'constants/routes';
import CenterBox from 'components/CenterBox';

const Layout: FC = () => {
  const user = useSelectUser();
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.Header}>{user?.permissions.telegramAllowed && <Header />}</div>
      {user?.permissions.telegramAllowed ? (
        <Outlet />
      ) : (
        <CenterBox>
          <EmptyPage
            img={<img src={lock} alt="" />}
            title={'Упс, У вас нет доступа'}
            text={
              'Для того, чтобы воспользоваться функционалом “Telegram”, вам необходимо приобрести тарифный план премиум или стандарт'
            }
            btnText={'Выбрать тариф'}
            onClick={() => {
              navigate(r.tariff);
            }}
          />
        </CenterBox>
      )}

      <Circles />
      <MobileFooter />
    </>
  );
};

export default Layout;
