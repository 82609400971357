import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './BotsLayout.module.scss';
import BotBuilderLogo from '../BotBuilderLogo';
import Circles from 'components/Circles';

const BotsLayout: FC = () => {
  return (
    <div className={styles.Layout}>
      <header className={styles.Header}>
        <BotBuilderLogo />
      </header>
      <Outlet />
      <Circles />
    </div>
  );
};

export default BotsLayout;
