import { FC, ReactNode } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import isUndefined from 'lodash-es/isUndefined';

import styles from './WithMultipleChoices.module.scss';
import Tag from 'components/Tag';

export type WithMultipleChoicesProps = {
  children: ReactNode;
  choices?: string[];
  deleteChoice: (choice: string) => void;
};

const WithMultipleChoices: FC<WithMultipleChoicesProps> = ({ children, choices, deleteChoice }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Input}>{children}</div>
      {!isUndefined(choices) && !isEmpty(choices) && (
        <div className={styles.Choices}>
          {choices.map(choice => (
            <Tag key={choice} text={choice} onClick={() => deleteChoice(choice)} />
          ))}
        </div>
      )}
    </div>
  );
};

export default WithMultipleChoices;
