import fallback from 'assets/img/fallback/fallback.png';
import styles from './GroupNameDisplay.module.scss';
import { FC } from 'react';
import Tooltip from '../../../../components/Tooltip';

type Props = {
  title?: string;
  subTitle: string;
  image?: string;
};

const GroupNameDisplay: FC<Props> = ({ image, subTitle, title }) => {
  return (
    <div className={styles.CardHeaderLeft}>
      <img className={styles.Img} src={image || fallback} alt={'img'} />
      <div className={styles.CardText}>
        <Tooltip label={title}>{title && <h2 className={styles.CardTitle}>{title}</h2>}</Tooltip>
        <p className={styles.SubTitle}>{subTitle}</p>
      </div>
    </div>
  );
};

export default GroupNameDisplay;
