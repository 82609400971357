import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './AccordionTriggerStandard.module.scss';
import Icon from 'components/Icon';
import WithSideIcon, { SideIcon } from 'components/WithSideIcon';

type Props = {
  isOpen: boolean;
  children: ReactNode;
  sideIcon?: SideIcon;
  noDivider?: boolean;
};

const AccordionTriggerStandard: FC<Props> = ({ isOpen, sideIcon, noDivider, children }) => {
  return (
    <WithSideIcon sideIcon={sideIcon}>
      <button className={styles.Trigger} type="button" data-no-divider={noDivider}>
        {children}{' '}
        <Icon
          iconName="arrow-down"
          className={classNames(styles.Icon, {
            [styles.IconOpen]: isOpen,
          })}
          size={24}
        />
      </button>
    </WithSideIcon>
  );
};

export default AccordionTriggerStandard;
