import classNames from 'classnames';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavWithTabsMobile.module.scss';
import { useGetAllVkAccounts } from '../../modules/vk/vk.selectors';
import { isEmpty } from 'lodash-es';
import DisabledHeaderTabs from '../DisabledHeaderTabs';

type HeaderLink = {
  link: string;
  name?: string;
};

type Props = {
  links: HeaderLink[];
  className?: string;
  isVkNav?: boolean;
};

const NavWithTabsMobile: FC<Props> = ({ links, className, isVkNav }) => {
  const vkAccounts = useGetAllVkAccounts();

  return (
    <nav className={classNames(styles.Nav, className)}>
      {isVkNav ? (
        <>
          {isEmpty(vkAccounts) ? (
            <DisabledHeaderTabs links={links} />
          ) : (
            <>
              {links.map(link => (
                <NavLink
                  key={link.link}
                  to={link.link}
                  className={({ isActive }) =>
                    classNames(styles.Link, {
                      [styles.Active]: isActive,
                    })
                  }
                  end
                >
                  {link.name}
                </NavLink>
              ))}
            </>
          )}
        </>
      ) : (
        <>
          {links.map(link => (
            <NavLink
              key={link.link}
              to={link.link}
              className={({ isActive }) =>
                classNames(styles.Link, {
                  [styles.Active]: isActive,
                })
              }
              end
            >
              {link.name}
            </NavLink>
          ))}
        </>
      )}
    </nav>
  );
};

export default NavWithTabsMobile;
