import { FC } from 'react';
import Button from 'components/Button';
import { useDeleteWelcomeBotUserMutation } from '../../codegen/telegram.api';
import styles from './DeleteBotButton.module.scss';

export const DeleteBotButton: FC<{ name?: string }> = ({ name }) => {
  const [deleteAccount, { isLoading }] = useDeleteWelcomeBotUserMutation();

  return (
    <div className={styles.Wrapper}>
      {name && <h1>{name}</h1>}
      <Button
        className={styles.Root}
        disabled={isLoading}
        variant="delete"
        onClick={() => {
          deleteAccount({}).then(() => {
            window.location.reload();
          });
        }}
      >
        Выйти
      </Button>
    </div>
  );
};
