import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Generator from './pages/Generator';
import PublishLanding from './pages/PublishLanding';

const LandingGeneratorRoutes = (): JSX.Element => {
  return (
    <>
      <Route path={r.landingGenerator.index} element={<Generator />} />
      <Route path={r.landingGenerator.publish} element={<PublishLanding />} />
    </>
  );
};

export default LandingGeneratorRoutes;
