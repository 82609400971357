import { FC, ReactNode, useState, MouseEvent } from 'react';
import { isNil } from 'lodash-es';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import r from 'constants/routes';
import formatLongText from 'utils/formatLongTrxt';

import ToggleSwitch from 'components/ToggleSwitch';
import Avatar from 'components/Avatar';
import DataEntry from './components/DataEntry';
import DataEntryLong from './components/DataEntryLong';
import Update from './components/Update';
import LastUpdate from './components/LastUpdate';
import SaveToTable from './components/SaveToTable';
import Loading from './components/Loading';

import styles from './BotCard.module.scss';
import useAppDispatch from '../../hooks/useAppDispatch';
import { setBotName } from '../../modules/telegram/codegen/telegram.admin.reducer';

type BotCardExtensions = {
  DataEntry: typeof DataEntry;
  DataEntryLong: typeof DataEntryLong;
  Update: typeof Update;
  LastUpdate: typeof LastUpdate;
  SaveToTable: typeof SaveToTable;
  Loading: typeof Loading;
};

export type BotCardProps = {
  name?: string | null;
  toggled?: boolean;
  onToggle?: () => void;
  onDelete?: () => void;
  data?: JSX.Element;
  link?: string;
  botInfo?: {
    displayName: string;
    name: string;
    photoUrl: string | null;
  };
  canNotStart?: null | {
    message: string;
  };
  headerRight?: ReactNode;
  subHeader?: ReactNode;
  subFooter?: ReactNode;
  footer?: ReactNode;
};

const BotCard: FC<BotCardProps> & BotCardExtensions = ({
  name,
  link = r.telegram.index,
  onToggle,
  toggled = false,
  botInfo,
  headerRight,
  subHeader,
  subFooter,
  footer,
  canNotStart,
}) => {
  const [innerToggled, setInnerToggled] = useState(toggled);
  const hasAvatar = !!botInfo;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onToggleBot = () => {
    setInnerToggled(!innerToggled);
    onToggle?.();
  };

  const onBotClick = (e: MouseEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    if (element) {
      const classList = element?.className || '';
      const isToggle = !!classList.includes('ToggleSwitch');
      dispatch(setBotName(name || ''));
      if (!isToggle) navigate(link);
    }
  };

  return (
    <article
      className={classNames(styles.Section, {
        [styles.Avatar]: hasAvatar,
      })}
      onClick={e => onBotClick(e)}
    >
      <div className={styles.Flex}>
        <div className={styles.AvatarWrapper}>
          {!isNil(botInfo) && <Avatar name={botInfo.name} src={botInfo.photoUrl} size={36} />}
          <h3 className={styles.Text}>{botInfo && formatLongText(botInfo.name, 18)}</h3>
        </div>
        {headerRight ?? (
          <ToggleSwitch
            active={innerToggled}
            toggle={onToggleBot}
            label={() => (!isNil(canNotStart) ? 'Недост.' : innerToggled ? 'Вкл.' : 'Выкл')}
            labelLeft
            disabled={!isNil(canNotStart)}
          />
        )}
      </div>
      {subHeader && <div className={styles.SubHeader}>{subHeader}</div>}
      <div className={classNames(styles.Data)}>{name && formatLongText(name, 30)}</div>
      {footer && <div className={styles.Footer}>{footer}</div>}
      {subFooter && <div className={styles.SubFooter}>{subFooter}</div>}
      {!isNil(canNotStart) && <p className={styles.Error}>{canNotStart.message}</p>}
    </article>
  );
};

BotCard.DataEntry = DataEntry;
BotCard.DataEntryLong = DataEntryLong;
BotCard.Update = Update;
BotCard.LastUpdate = LastUpdate;
BotCard.SaveToTable = SaveToTable;
BotCard.Loading = Loading;

export default BotCard;
