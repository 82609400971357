import { FC, useEffect } from 'react';

import styles from './ClickAction.module.scss';
import Tool from '../Tool';
import { Click, EClickAction } from 'modules/my-pages/my-pages.types';
import InputOutline from 'components/InputOutline';
import Menu from 'components/Menu';
import { CLICK_ACTIONS_NAMES } from 'modules/my-pages/my-pages.constants';
import Icon from 'components/Icon';

type Props = {
  click: Click;
  setAction: (action: EClickAction) => void;
  setLink: (link?: string) => void;
};

const ClickAction: FC<Props> = ({ click, setAction, setLink }) => {
  const showInput = click.action !== EClickAction.NONE;

  useEffect(() => {
    if (click.action === 'NONE') {
      setLink('');
    }
  }, [click]);

  const menu = (
    <Menu
      btnClassName={styles.MenuBtn}
      contextClassName={styles.MenuContext}
      items={[
        {
          label: CLICK_ACTIONS_NAMES[EClickAction.ANCHOR],
          onClick: () => setAction(EClickAction.ANCHOR),
          isSelected: click.action === EClickAction.ANCHOR,
        },
        {
          label: CLICK_ACTIONS_NAMES[EClickAction.LINK],
          onClick: () => setAction(EClickAction.LINK),
          isSelected: click.action === EClickAction.LINK,
        },
        {
          label: CLICK_ACTIONS_NAMES[EClickAction.NONE],
          onClick: () => setAction(EClickAction.NONE),
          isSelected: click.action === EClickAction.NONE,
        },
      ]}
    >
      {click.action !== EClickAction.FORM ? CLICK_ACTIONS_NAMES[click.action] : ''}{' '}
      <Icon className={styles.IconDown} iconName="arrow-down" size={18} />
    </Menu>
  );

  return (
    <Tool label="Действие по клику:" right={<div className={styles.DesktopMenu}>{menu}</div>}>
      <div className={styles.MobileMenu} data-has-input={showInput}>
        {menu}
      </div>
      {showInput && (
        <InputOutline
          value={click.link}
          placeholder={click.action === EClickAction.ANCHOR ? 'Якорь' : 'Ссылка'}
          onChange={e => setLink(e.target.value)}
          noBorder
        />
      )}
    </Tool>
  );
};

export default ClickAction;
