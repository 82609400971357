import { Route } from 'react-router-dom';

import r from 'constants/routes';
import MyPages from './pages/MyPages';
import Layout from './components/Layout';
import Statistics from './pages/Statistics';
import Applications from './pages/Applications';

const LandingDashboardRoutes = (): JSX.Element => {
  return (
    <Route path={r.landingDashboard.index} element={<Layout />}>
      <Route path={r.landingDashboard.pages} element={<MyPages />} />
      <Route path={r.landingDashboard.statistics} element={<Statistics />} />
      <Route path={r.landingDashboard.applications} element={<Applications />} />
    </Route>
  );
};

export default LandingDashboardRoutes;
