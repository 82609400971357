import { FC } from 'react';
import dayjs from 'dayjs';

import styles from './Message.module.scss';

import Button from 'components/Button';

import { MessageType } from 'modules/notifications/notifications.types';
import { useReadMailMutation } from 'modules/notifications/notifications.api';

const Message: FC<MessageType> = ({ id, title, message, date, read }) => {
  const [readNotification] = useReadMailMutation();
  return (
    <div className={styles.Message}>
      <div className={styles.MessageTitleLine}>
        <h2 className={styles.MessageTitle}>{title}</h2>
        <span className={styles.MessageDate}>{dayjs(date).format('DD.MM.YYYY')}</span>
      </div>
      <p className={styles.MessageBody}>{message}</p>
      <div className={styles.MessageButtonWrapper}>
        {read ? (
          <Button className={styles.MessageButton} disabled>
            Прочитано
          </Button>
        ) : (
          <Button className={styles.MessageButton} onClick={() => readNotification([id])}>
            Прочитать
          </Button>
        )}
      </div>
    </div>
  );
};

export default Message;
