import { FC } from 'react';

import styles from './EmptyPage.module.scss';

type Props = {
  title?: string;
  subtitle?: string;
  elem?: JSX.Element;
};
const EmptyPage: FC<Props> = ({ title, subtitle, elem }) => {
  return (
    <div className={styles.Page}>
      <h1 className={styles.Title}>{title}</h1>
      {subtitle && <h3 className={styles.Subtitle}>{subtitle}</h3>}
      {elem}
    </div>
  );
};

export default EmptyPage;
