import { FC } from 'react';
import classNames from 'classnames';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

import styles from './TextArea.module.scss';
import Input, { InputProps } from 'components/Input';

export type TextAreaProps = InputProps;

const TextArea: FC<TextAreaProps> = props => {
  return (
    <Input
      {...props}
      renderInput={inputProps => {
        const textAreaProps = inputProps as TextareaAutosizeProps;
        return (
          <TextareaAutosize
            {...textAreaProps}
            className={classNames(styles.TextArea, textAreaProps.className)}
            data-invalid={props.invalid}
            data-title={props.isTitle}
            data-bold={props.bold}
            data-big={props.bigText}
          />
        );
      }}
    />
  );
};

export default TextArea;
