import { getDeviceType } from './getDeviceType';

export const getClickType = (func?: () => void) => {
  const deviceType = getDeviceType();

  if (deviceType === 'desktop') {
    return { onDoubleClick: func };
  }

  return { onClick: func };
};
