import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable, { excludeAction } from 'redux-undo';
import set from 'lodash-es/set';
import { getComponentById } from './my-pages.selectors';
import {
  AnyComponent,
  EComponentType,
  EOrientation,
  Page,
  BlockComponent,
  EPageDomain,
} from 'modules/my-pages/my-pages.types';
import { createBlock, createComponent } from './my-pages.creators';

type MyPagesSliceState = {
  activePage: Page | null;
  activeComponentId: string | null;
  previewOrientation: EOrientation;
  shootingPrerender: boolean;
  isValidPage: boolean;
  openModal: boolean;
  openGoBackModal: boolean;
  openSettingsModal: boolean;
};

const initialState: MyPagesSliceState = {
  activePage: null,
  activeComponentId: null,
  previewOrientation: EOrientation.VERTICAL,
  shootingPrerender: false,
  isValidPage: true,
  openModal: false,
  openGoBackModal: false,
  openSettingsModal: false,
};

const myPagesSlice = createSlice({
  name: 'my-pages',
  initialState,
  reducers: {
    setActivePage: (state, { payload }: PayloadAction<Page | null>) => {
      state.activePage = payload;
    },
    setActiveComponent: (state, { payload }: PayloadAction<string | null>) => {
      state.activeComponentId = payload;
    },
    setPageName: (state, { payload }: PayloadAction<string | undefined>) => {
      if (state.activePage) {
        state.activePage.name = payload;
      }
    },
    setPageLink: (state, { payload }: PayloadAction<string | undefined>) => {
      if (state.activePage) {
        state.activePage.link = payload;
      }
    },
    setPageBgColor: (state, { payload }: PayloadAction<string>) => {
      if (state.activePage) {
        state.activePage.bgColor = payload;
      }
    },
    setPageDomain: (state, { payload }: PayloadAction<EPageDomain>) => {
      if (state.activePage) {
        state.activePage.domain = payload;
      }
    },
    addComponent: (
      state,
      {
        payload: { parentId, component },
      }: PayloadAction<{ parentId: string; component: AnyComponent }>,
    ) => {
      if (state.activePage) {
        const isBlock = component.type === EComponentType.BLOCK;
        const parentData = isBlock
          ? { component: state.activePage }
          : getComponentById(state.activePage, parentId);

        let list = parentData?.component.components;
        if (!isBlock && !list) {
          // if adding elem with no block -> fallback
          const block = createComponent(createBlock(), EComponentType.BLOCK, 0);
          state.activePage.components.push(block);
          list = block.components;
        }

        if (list) {
          if (component.order === -1) {
            // append at the end
            component.order = list.length;
          } else {
            // insert at a specified place by
            // incrementing orders of following list by 1
            list.filter(b => b.order >= component.order).forEach(b => (b.order += 1));
          }

          // LOL
          // if (isBlock) (component as BlockComponent).data.bgColor = state.activePage.bgColor;

          list.push(component as BlockComponent);
          state.activeComponentId = component.hash;
        }
      }
    },
    moveComponent: (state, { payload }: PayloadAction<{ hash: string; newOrder: number }>) => {
      const { hash, newOrder } = payload;
      if (state.activePage) {
        const data = getComponentById(state.activePage, hash);
        if (data) {
          const blockToChange = data.component;
          const blockAtNewOrder = data.parent.components?.find(c => {
            return c.order === newOrder;
          });
          if (blockToChange && blockAtNewOrder) {
            blockAtNewOrder.order = blockToChange.order;
            blockToChange.order = newOrder;
          }
        }
      }
    },
    removeComponent: (state, { payload }: PayloadAction<{ hash: string }>) => {
      if (state.activePage) {
        const data = getComponentById(state.activePage, payload.hash);
        if (data && data.parent.components) {
          data.parent.components = data.parent.components.filter(c => c.hash !== payload.hash);
          data.parent.components = [...data.parent.components]
            .sort((a, b) => a.order - b.order)
            .map((c, index) => ({ ...c, order: index }));
        }
      }
    },
    setComponentProperty: (
      state,
      { payload }: PayloadAction<{ hash: string; key: string; value: unknown }>,
    ) => {
      const { hash, key, value } = payload;
      if (state.activePage) {
        const data = getComponentById(state.activePage, hash);
        if (data) {
          set(data.component.data, key, value);
        }
      }
    },
    setComponentText: (
      state,
      { payload }: PayloadAction<{ hash: string; key: string; value: unknown }>,
    ) => {
      // TODO: maybe create a separate function in order to not violate DRY principle?
      // duplicates body of setComponentProperty.
      // needed in order to exclude from undo/redo.
      const { hash, key, value } = payload;
      if (state.activePage) {
        const data = getComponentById(state.activePage, hash);
        if (data) {
          set(data.component.data, key, value);
        }
      }
    },
    reorderComponents: (
      state,
      { payload }: PayloadAction<{ parentId: string; newList: AnyComponent[] }>,
    ) => {
      const { parentId, newList } = payload;
      if (state.activePage) {
        const data = getComponentById(state.activePage, parentId);
        if (data) {
          data.component.components = newList.map((item, index) => ({
            ...item,
            order: index,
          }));
        }
      }
    },
    setPreviewOrientation: (state, { payload }: PayloadAction<EOrientation>) => {
      state.previewOrientation = payload;
    },
    setShootingPrerender: (state, { payload }: PayloadAction<boolean>) => {
      state.shootingPrerender = payload;
    },
    setValidPageValue: (state, { payload }: PayloadAction<boolean>) => {
      state.isValidPage = payload;
    },

    setOpenModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openModal = payload;
    },

    setBlockComponent: (state, { payload }: PayloadAction<BlockComponent[]>) => {
      if (state.activePage) {
        state.activePage.components = payload.map((item: BlockComponent, index: number) => ({
          ...item,
          order: index,
        }));
      }
    },
    resetPageValues: state => {
      state.activePage = null;
      state.activeComponentId = null;
      state.previewOrientation = EOrientation.VERTICAL;
      state.shootingPrerender = false;
      state.isValidPage = true;
    },
    setOpenGoBackModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openGoBackModal = payload;
    },
    setOpenSettingsModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openSettingsModal = payload;
    },
  },
});

export const {
  setActivePage,
  setActiveComponent,
  setPageName,
  setPageLink,
  setPageBgColor,
  setPageDomain,

  addComponent,
  moveComponent,
  removeComponent,
  setComponentProperty,
  setComponentText,
  reorderComponents,
  setPreviewOrientation,
  setShootingPrerender,
  setValidPageValue,
  setBlockComponent,
  setOpenModal,
  resetPageValues,
  setOpenGoBackModal,
  setOpenSettingsModal,
} = myPagesSlice.actions;

const myPagesReducer = myPagesSlice.reducer;

const undoableMyPagesReducer = undoable(myPagesReducer, {
  ignoreInitialState: true,
  syncFilter: true,
  filter: excludeAction([
    setActivePage.type,
    setActiveComponent.type,
    setPageName.type,
    setPageLink.type,
    setComponentText.type,
    setPreviewOrientation.type,
    setShootingPrerender.type,
    setOpenGoBackModal.type,
    resetPageValues.type,
  ]),
});

export default undoableMyPagesReducer;
