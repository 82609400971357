import { FC } from 'react';
import { Reorder } from 'framer-motion';
import pick from 'lodash-es/pick';

import styles from './Landing.module.scss';

import { LandingElement, LandingIdType } from 'modules/landing-generator/landing-generator.types';
import {
  useSelectLandingAsList,
  useSelectLandingColor,
} from 'modules/landing-generator/landing-generator.selectors';
import RenderElement from './components/RenderElement';
import useAppDispatch from 'hooks/useAppDispatch';
import { reorderLanding } from 'modules/landing-generator/landing-generator.reducer';

const makeKey = (elem: LandingElement) =>
  JSON.stringify({
    type: elem.type,
    value: pick(elem.value, ['id', 'orientation']),
    index: elem?.index ?? 0,
  });

type Props = {
  id: LandingIdType;
};
const Landing: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const landingElements = useSelectLandingAsList(id);
  const backgroundColor = useSelectLandingColor(id);

  return (
    <article className={styles.Wrapper} style={{ backgroundColor }}>
      <Reorder.Group
        axis="y"
        values={landingElements}
        onReorder={newOrder => {
          dispatch(reorderLanding({ id, oldOrder: landingElements, newOrder }));
        }}
        className={styles.List}
      >
        {landingElements.map(elem => (
          <RenderElement key={makeKey(elem)} id={id} elem={elem} />
        ))}
      </Reorder.Group>
    </article>
  );
};

export default Landing;
