import { FC, useState } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from 'hooks/usePagination';

import styles from './Pagination.module.scss';

type Props = {
  onPageChange: (a: number) => void;
  totalCount?: number;
  siblingCount?: number;
  className?: string;
  offset: number;
  currPage?: number;
};

const Pagination: FC<Props> = ({
  onPageChange,
  totalCount = 0,
  siblingCount = 1,
  className,
  offset,
  currPage,
}) => {
  const [page, setPage] = useState(currPage || 1);

  const totalPages = (num: number | undefined) => {
    if (!num) {
      return 0;
    }
    return Math.ceil(num / offset);
  };

  const totalCounts = totalPages(totalCount) ?? 0;

  const paginationRange = usePagination({
    currentPage: page,
    totalCount: totalCounts,
    siblingCount,
  });

  if (page === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    setPage(page + 1);
    onPageChange(page + 1);
  };

  const onPrevious = () => {
    setPage(page - 1);
    onPageChange(page - 1);
  };

  const handlePageChange = (num: number) => {
    setPage(num);
    onPageChange(num);
  };

  const lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : null;
  return (
    <div className={classnames(styles.PaginationContainer, className)}>
      <div
        className={classnames(styles.PaginationItem, {
          [styles.Disabled]: page === 1,
        })}
        onClick={onPrevious}
      >
        <div className={classnames(styles.Arrow, styles.ArrowLeft)} />
      </div>

      {paginationRange?.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <div key={index} className={styles.Dots}>
              &#8230;
            </div>
          );
        }

        const isActive = currPage ? currPage : page;

        return (
          <div
            key={index}
            className={classnames(styles.PaginationItem, {
              [styles.Selected]: pageNumber === isActive,
            })}
            onClick={() => handlePageChange(pageNumber as number)}
          >
            {pageNumber}
          </div>
        );
      })}
      <div
        className={classnames(styles.PaginationItem, {
          [styles.Disabled]: page === lastPage,
        })}
        onClick={onNext}
      >
        <div className={classnames(styles.Arrow, styles.ArrowRight)} />
      </div>
    </div>
  );
};

export default Pagination;
