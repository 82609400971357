import isEmpty from 'lodash-es/isEmpty';
import { FC, ReactNode } from 'react';

import styles from './AutomationPageLayout.module.scss';
import GoBack from 'components/GoBack';
import SearchField from 'components/SearchField';
import Button from 'components/Button';
import Title from 'components/Title';
import EmptyCard from 'components/EmptyCard';
import { useGetAllVkAccounts } from '../../vk.selectors';
import AutomationStorage from '../AutomationStorage';
import { useClearStorageMutation } from 'modules/vk/vk.api';
import LoadingState from '../LoadingState';

type Props = {
  title: string;
  emptyPage: ReactNode;
  cards?: ReactNode[] | null;
  onSearch?: (searchQuery?: string) => void;
  onAdd?: () => void;
  addText?: string;
  navAddon?: ReactNode;
  dontChangeHeaderDirectionOnMobile?: boolean;
  subTitle?: string;
  addBtnText?: string;
  storage?: boolean;
  disabled?: boolean;
};

const AutomationPageLayout: FC<Props> = ({
  title,
  emptyPage,
  cards,
  onSearch,
  onAdd,
  addText,
  navAddon,
  dontChangeHeaderDirectionOnMobile,
  subTitle,
  addBtnText,
  disabled,
}) => {
  const hasItems = !isEmpty(cards);
  const vkAccounts = useGetAllVkAccounts();
  const [clearStorage, { isLoading }] = useClearStorageMutation();

  if (isEmpty(vkAccounts)) return null;
  if (isLoading) return <LoadingState />;

  return (
    <div className={styles.Page}>
      <nav className={styles.Nav}>
        <div className={styles.NavLeft}>
          <GoBack staticPos />
          <div className={styles.TitleContainer}>
            <Title className={styles.Title}>{title}</Title>
          </div>
        </div>
        <div className={styles.NavRight}>{navAddon && hasItems && navAddon}</div>
      </nav>
      <div
        className={styles.Header}
        data-dont-change-direction-on-mobile={!!dontChangeHeaderDirectionOnMobile}
      >
        <div className={styles.TitleAndStorage}>
          {subTitle && <h2 className={styles.SubTitle}>{subTitle}</h2>}
          <AutomationStorage />
          <Button size="s" variant="tetriary" disabled={isLoading} onClick={clearStorage}>
            Очистить Хранилище
          </Button>
        </div>

        {hasItems && (
          <div className={styles.HeaderRight}>
            {onSearch && (
              <SearchField
                className={styles.SearchField}
                placeholder="Поиск по названию"
                onSearch={onSearch}
              />
            )}
            {onAdd && (
              <Button
                disabled={disabled}
                className={styles.AddBtn}
                variant="primary"
                onClick={onAdd}
              >
                {addBtnText}
              </Button>
            )}
          </div>
        )}
      </div>
      {hasItems ? (
        <div className={styles.Grid}>
          {onAdd && addText && (
            <EmptyCard className={styles.EmptyCard} text={addText} onClick={onAdd} />
          )}
          {cards}
        </div>
      ) : (
        emptyPage
      )}
    </div>
  );
};

export default AutomationPageLayout;
