import { FC, ReactNode } from 'react';
import PageHeader from 'components/PageHeader';
import CreateCommandButton from '../CreateCommandButton';
import useWindowSize from 'hooks/useWindowSize';
import Button from 'components/Button';

import styles from './PageHead.module.scss';

type Props = {
  className?: string;
  title?: string;
  subtitle?: string;
  elem?: JSX.Element;
  defaultLink?: string;
  onButtonClick?: () => void;
  buttonComponent?: ReactNode;
  btnText?: string;
  onIconClick?: () => void;
};

const PageHead: FC<Props> = ({ onButtonClick, btnText, buttonComponent, ...rest }) => {
  const { width } = useWindowSize();

  const ButtonComponent = (
    <Button onClick={onButtonClick} size={'l'} variant={'magic'}>
      {btnText}
    </Button>
  ) || <CreateCommandButton />;

  return (
    <div className={styles.Head}>
      <PageHeader {...rest} />
      {width >= 876 && ButtonComponent}
    </div>
  );
};

export default PageHead;
