import { FC, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import styles from './Preview.module.scss';
import { useActivePage, usePreviewOrientation } from 'modules/my-pages/my-pages.selectors';
import r from 'constants/routes';
import PreviewHeader from 'modules/my-pages/components/PreviewHeader';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  setActivePage,
  setOpenGoBackModal,
  setPreviewOrientation,
} from 'modules/my-pages/my-pages.reducer';
import { EOrientation } from 'modules/my-pages/my-pages.types';
import Phone from 'modules/my-pages/components/Phone';
import PreviewComponent from 'modules/my-pages/components/Preview';
import PreviewNavMobile from 'modules/my-pages/components/PreviewNavMobile';
import useWindowSize from 'hooks/useWindowSize';
import { useGetPageQuery } from 'modules/landing-dashboard/landing-dashboard.api';

const Preview: FC = () => {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetPageQuery({ id: id as string });
  const dispatch = useAppDispatch();
  const page = useActivePage();
  const orientation = usePreviewOrientation();
  const { width } = useWindowSize();

  useEffect(() => {
    dispatch(setOpenGoBackModal(false));
  }, []);

  useEffect(() => {
    if (data?.body && data.body.id !== page?.id) {
      dispatch(setActivePage(data.body));
    }
  }, [data]);

  useEffect(() => {
    // reset preview orientation on page mount
    dispatch(setPreviewOrientation(EOrientation.VERTICAL));
  }, [dispatch]);

  useEffect(() => {
    // reset preview orientation on mobile devices
    if (width <= 784 && orientation !== EOrientation.VERTICAL) {
      dispatch(setPreviewOrientation(EOrientation.VERTICAL));
    }
  }, [width, dispatch, orientation]);

  if (isError) return <Navigate to={r.landingDashboard.pages} />;

  return (
    <>
      <PreviewHeader />

      <div className={styles.Preview}>
        {!isLoading && (
          <Phone className={styles.Phone} mode="preview" orientation={orientation}>
            <PreviewComponent className={styles.PreviewComponent} mode="preview" page={page} />
          </Phone>
        )}
      </div>

      <PreviewNavMobile />
    </>
  );
};

export default Preview;
