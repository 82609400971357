import { IUser, EPlanName } from 'modules/account/account.interfaces';

export type Partner = Pick<IUser, 'id' | 'avatar' | 'plan'> & {
  fullname: string;
  referrals: number;
  activeReferrals: number;
  username: string;
  telegram: string;
  email: string;
  phone: string;
};

export type PartnersRequestParams = {
  line: PartnerLines;
  referralName?: string;
  plan?: EPlanName;
  timeFilter?: EndDate;
};

export enum EndDate {
  days7 = 'seven',
  days14 = 'fourteen',
  days30plus = 'thirty',
}

export type PartnershipProgramSliceState = {
  filters: {
    search?: string;
    plan?: EPlanName | null;
    endDate?: EndDate | null;
    offset: number;
  };
  selectedPartners: Array<string | number>;
  mailModal: boolean;
};

export type PartnersNewsletterRequestBody = {
  message: string;
};

export enum PartnerLines {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
}

export type LineDetailsResponse = {
  percents: number;
  profit: number;
  allProfit: number;
};
