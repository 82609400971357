const r = {
  root: '/',
  // auth
  auth: {
    index: '/auth',
    signIn: '/auth/sign-in',
    signOut: '/auth/sign-out',
    resetPassword: '/auth/reset-password',
    newPass: '/auth/new-password/:hash',
    dropPassword: '/auth/new-fin-password/:hash',
    signUp: {
      index: '/auth/sign-up',
      chooseRegistrationType: '/auth/sign-up/registration-type',
      invitor: '/auth/sign-up/invitor',
      registration: '/auth/sign-up/registration',
    },
  },
  // account
  account: '/account',
  tariff: '/tariff',
  // analytics
  analytics: '/analytics',
  // settings
  settings: '/settings',
  // finances
  finances: {
    index: '/finances',
    transactions: '/finances/transactions',
  },
  notifications: '/notifications',
  // partnership program
  partnershipProgram: {
    index: '/partnership-program/:line',
    info: '/partnership-program-info/:line',
  },
  // landing generator
  landingGenerator: {
    index: '/landing-generator/:id',
    publish: '/landing-generator/:id/publish',
  },
  // my pages
  myPages: {
    edit: '/my-pages/:id/editor',
    preview: '/my-pages/:id/preview',
    published: '/my-pages/published/:link',
    page: '/my-pages/:id',
  },
  // landing dashboard
  landingDashboard: {
    index: '/landing-dashboard',
    pages: '/landing-dashboard/pages',
    statistics: '/landing-dashboard/statistics',
    applications: '/landing-dashboard/applications',
  },
  // cards
  card: '/card/:name',
  // vk
  vk: {
    index: '/vk',
    groupsActions: '/vk/groups-actions',
    groupsFolder: '/vk/groups-actions/folder/:id',
    groupAutomation: '/vk/groups-actions/folder/:id/groupAutomation/:parsingId',
    groupComments: '/vk/group-comments/:folderId/parsing-id/:parsingId',
    automationActions: '/vk/automation',
    first3PhotoComments: '/vk/first-3-photo-comments/:id',
    firstPostsComments: '/vk/first-posts-comments',
    autoPosting: '/vk/auto-posting',
    autoStories: '/vk/auto-stories',
    autoTranslation: '/vk/auto-translation',
    mailing: {
      index: '/vk/mailing',
      edit: '/vk/mailing/:id',
    },
    dbParsing: {
      index: '/vk/db-parsing',
      edit: '/vk/db-parsing/:id',
    },
    thematicGroups: {
      index: '/vk/thematic-groups/subscriptions',
      stacks: '/vk/thematic-groups/stacks',
    },
  },
  // telegram
  telegram: {
    index: '/telegram',
    editAdminBot: '/telegram/bots/admin/:id',
    commands: '/telegram/bots/commands/:id',
    botsChat: '/telegram/bots/botsChat/message-name/:messageName/:id/chat/:chatId',
    greetingsMessage: '/telegram/bots/greeting-message/:id',
    spamBots: '/telegram/bots/spam',
    editSpamBot: '/telegram/bots/spam/:id',
    spamBotsChat: '/telegram/bots/spam-bots-chat/message-name/:messageName/:id/chat/:chatId',
    greetingBot: '/telegram/bots/greeting',
    welcomeBotStatistics: '/telegram/bots/greeting/statistics/:service',
    editGreetingBot: '/telegram/bots/greeting/:id',
    constructor: {
      connectedBots: '/telegram/constructor/connected-bots',
      connectedBotInfo: '/telegram/constructor/connected-bots/:id',
    },
  },
  // bot constructor
  botBuilder: {
    index: '/telegram/bot-builder',
    scenarios: {
      index: '/telegram/bot-builder/:id',
      scenarios: '/telegram/bot-builder/:id/scenarios',
      mailing: '/telegram/bot-builder/:id/mailing',
    },
  },
  // admin
  admin: {
    index: '/admin',
  },
  // errors
  notFound: '/not-found',
  // development
  ongoingDevelopment: '/ongoing-development',
} as const;

export default r;
