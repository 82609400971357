import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useDisclosure from 'hooks/useDisclosure';
import PasswordInput from 'components/PasswordInput';
import ResultModal from 'components/ResultModal';
import { OnSubmitDefaultHandler } from 'components/Modal';
import Button from 'components/Button';

import r from 'constants/routes';
import SettingsForm from '../../components/SettingsForm';
import FormFieldsWrapper from '../../components/FormFieldsWrapper';
import { NewPasswordRequestBody } from '../../settings.types';
import { newPasswordsOnlySchema } from '../../settings.schema';

import { useConfirmResetFinPasswordMutation } from '../../settings.api';
import styles from './SettingsResetPassword.module.scss';

type TResetPassword = Pick<NewPasswordRequestBody, 'new' | 'new2'>;

const SettingsResetPassword: FC = () => {
  const [confirmReset, { isSuccess, isLoading }] = useConfirmResetFinPasswordMutation();
  const { hash } = useParams();
  const navigate = useNavigate();

  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<TResetPassword>({
    mode: 'onChange',
    resolver: yupResolver(newPasswordsOnlySchema()),
  });

  const onSubmit: SubmitHandler<TResetPassword> = async data => {
    if (hash) {
      confirmReset({ token: hash, newPassword: data.new2 });
      onOpen();
    }
  };

  const onModalSubmitHandler: OnSubmitDefaultHandler = e => {
    e?.preventDefault();
    if (isSuccess) navigate(r.root);
    onClose();
  };

  return (
    <>
      <div className={styles.Container}>
        <div>
          <h3 className={styles.Title}>Новый пароль</h3>
          <p className={styles.Sub}>Вы произвели сброс финансового пароля!</p>
          <SettingsForm onSubmit={handleSubmit(onSubmit)}>
            <FormFieldsWrapper>
              <PasswordInput
                label={'Новый пароль'}
                register={register('new')}
                invalid={!!errors.new}
                errorMsg={errors.new?.message}
              />
              <PasswordInput
                label={'Подтвердить пароль'}
                register={register('new2')}
                invalid={!!errors.new2}
                errorMsg="Пароли не совпадают"
              />
            </FormFieldsWrapper>
            <div className={styles.ButtonContainer}>
              <Button disabled={!isValid} className={styles.Btn} type={'submit'}>
                Сохранить
              </Button>
            </div>
          </SettingsForm>
        </div>
      </div>
      <ResultModal
        isLoading={isLoading}
        isSuccess={isSuccess}
        onClose={onClose}
        isOpen={open}
        onSubmit={onModalSubmitHandler}
      />
    </>
  );
};

export default SettingsResetPassword;
