import { number, object, SchemaOf, string } from 'yup';
import i18n from 'i18n';

import { WithdrawModalTypes, FinPassModalTypes } from './finances.types';

export const finPassModalSchema = (): SchemaOf<FinPassModalTypes> =>
  object({
    finPass: string().required().label(i18n.t('labels.finPassword')),
  }).required();

export const WithdrawModalSchema = (): SchemaOf<WithdrawModalTypes> =>
  object({
    account: string().required(),
    amount: number().min(100, 'Сумма вывода должна быть больше либо ровна ${min}р.').required(),
    type: string(),
  })
    .required()
    .label(i18n.t('labels.finPassword'));
