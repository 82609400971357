import { FC } from 'react';

import {
  NonNullableActiveComponent,
  SocialNetwork as TSocialNetwork,
} from 'modules/my-pages/my-pages.types';
import ToolsWrapper from '../../tools/ToolsWrapper';
import BlockStyleSelector from '../../tools/BlockStyleSelector';
import ClickAction from '../../tools/ClickAction';
import AnchorInput from '../../tools/AnchorInput';
import useSetComponentProperty from 'modules/my-pages/hooks/useSetComponentProperty';
import AlignmentSelector from '../../tools/AlignmentSelector';

type Props = {
  component: NonNullableActiveComponent<TSocialNetwork>;
};

const SocialNetwork: FC<Props> = ({ component }) => {
  const { hash, data } = component.component;
  const { setProperty, setText } = useSetComponentProperty(hash);
  const anchorActive = !!data.isAnchorActive;

  return (
    <ToolsWrapper>
      <BlockStyleSelector
        style={data.blockStyle}
        setStyle={value => setProperty('blockStyle', value)}
      />
      <AlignmentSelector
        alignment={data.alignment}
        setAlignment={value => setProperty('alignment', value)}
      />
      <ClickAction
        click={data.onClick}
        setAction={value => setProperty('onClick.action', value)}
        setLink={value => setText('onClick.link', value)}
      />
      <AnchorInput
        anchor={data.anchor}
        setAnchor={value => setText('anchor', value)}
        setAnchorActive={() => setProperty('isAnchorActive', !anchorActive)}
        active={anchorActive}
      />
    </ToolsWrapper>
  );
};

export default SocialNetwork;
