import { FC, useCallback } from 'react';
import { UseFormRegister } from 'react-hook-form';

import styles from './WebsiteForm.module.scss';
import Input from 'components/Input';
import AdjustableInput from '../AdjustableInput';
import {
  LandingButtonForm,
  LandingIdType,
  LandingWebsiteForm,
} from 'modules/landing-generator/landing-generator.types';
import AccordionStandard from 'components/AccordionStandard';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  alignWebsiteText,
  setWebsiteTextColor,
  toggleWebsiteTextBold,
  toggleWebsiteTextItalic,
  toggleWebsiteTextUnderline,
} from 'modules/landing-generator/landing-generator.reducer';

type Props = {
  id: LandingIdType;
  btnId?: string | number;
  index: number;
  renderInAccordion: boolean;
  onRemove: () => void;
  getError: (key: keyof LandingWebsiteForm) => string | undefined;
  register: UseFormRegister<LandingButtonForm>;
};

const WebsiteForm: FC<Props> = ({ id, btnId, index, renderInAccordion, register, getError }) => {
  const dispatch = useAppDispatch();

  const toRender = useCallback(
    () => (
      <div className={styles.Fields}>
        <AdjustableInput
          label="Заголовок"
          isTitle
          handlers={{
            onToggleBold: () =>
              btnId && dispatch(toggleWebsiteTextBold({ id, btnId, index, type: 'title' })),
            onToggleItalic: () =>
              btnId && dispatch(toggleWebsiteTextItalic({ id, btnId, index, type: 'title' })),
            onToggleUnderline: () =>
              btnId && dispatch(toggleWebsiteTextUnderline({ id, btnId, index, type: 'title' })),
            onSetColor: color =>
              btnId && dispatch(setWebsiteTextColor({ id, btnId, index, color, type: 'title' })),
            onAlign: alignment =>
              btnId && dispatch(alignWebsiteText({ id, btnId, index, alignment, type: 'title' })),
          }}
          register={register(`websites.${index}.title`)}
          invalid={!!getError('title')}
          errorMsg={getError('title')}
        />

        <AdjustableInput
          label="Основной текст"
          handlers={{
            onToggleBold: () =>
              btnId && dispatch(toggleWebsiteTextBold({ id, btnId, index, type: 'text' })),
            onToggleItalic: () =>
              btnId && dispatch(toggleWebsiteTextItalic({ id, btnId, index, type: 'text' })),
            onToggleUnderline: () =>
              btnId && dispatch(toggleWebsiteTextUnderline({ id, btnId, index, type: 'text' })),
            onSetColor: color =>
              btnId && dispatch(setWebsiteTextColor({ id, btnId, index, color, type: 'text' })),
            onAlign: alignment =>
              btnId && dispatch(alignWebsiteText({ id, btnId, index, alignment, type: 'text' })),
          }}
          register={register(`websites.${index}.text`)}
          invalid={!!getError('text')}
          errorMsg={getError('text')}
        />

        <Input
          label="URL"
          register={register(`websites.${index}.url`)}
          invalid={!!getError('url')}
          errorMsg={getError('url')}
        />
      </div>
    ),
    [index, register, getError, btnId, id, dispatch],
  );

  return renderInAccordion ? (
    <AccordionStandard text={`Сайт ${index + 1}`}>{toRender()}</AccordionStandard>
  ) : (
    toRender()
  );
};

export default WebsiteForm;
