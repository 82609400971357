import { FC, ReactNode } from 'react';

import styles from './SelectedThematicGroupsNav.module.scss';
import CancelSelection from './components/CancelSelection';
import SelectedCount from './components/SelectedCount';
import StackSelector from './components/StackSelector';

type Props = {
  selected: number[];
  hasSelected: boolean;
  left?: ReactNode;
};

const SelectedThematicGroupsNav: FC<Props> = ({ selected, hasSelected, left }) => {
  if (!hasSelected) return null;

  return (
    <nav className={styles.Nav}>
      <div className={styles.Left}>
        {left ?? <StackSelector />}
        <CancelSelection />
      </div>

      <SelectedCount count={selected.length} />
    </nav>
  );
};

export default SelectedThematicGroupsNav;
