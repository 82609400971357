import { FC } from 'react';
import styles from './WeekDaysInput.module.scss';
import classNames from 'classnames';
import { WeekDays } from 'constants/date-durations';

type Props = {
  selectedDays: Set<WeekDays>;
  onDayClick: (id: WeekDays) => void;
};

const WeekDaysInput: FC<Props> = ({ selectedDays, onDayClick }) => {
  const weekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  const weeksEnum = Object.values(WeekDays);

  return (
    <div className={styles.Container}>
      {weekDays.map((week, idx) => (
        <div
          key={week}
          className={classNames(styles.Item, {
            [styles.SelectedItem]: selectedDays.has(weeksEnum[idx] as WeekDays),
          })}
          onClick={() => {
            onDayClick(weeksEnum[idx]);
          }}
        >
          <p className={styles.Par}>{week}</p>
        </div>
      ))}
    </div>
  );
};

export default WeekDaysInput;
