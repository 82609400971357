import { FC } from 'react';
import isEmpty from 'lodash-es/isEmpty';

import styles from './Bots.module.scss';
import EmptyPage from 'components/EmptyPage';
import botHeadImg from 'assets/svg/empty-pages/bot-head.svg';
import VideoInstructions from 'components/VideoInstructions';
import useDisclosure from 'hooks/useDisclosure';
import CreateBotModal from 'modules/bot-builder/components/CreateBotModal';
import Button from 'components/Button';
import BotCard from 'modules/bot-builder/components/BotCard';

const Bots: FC = () => {
  const { open: createOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();

  const bots = [
    {
      id: 1,
      username: '@salonbotky',
      avatar: 'https://placebear.com/300/300',
      created: '12/12/2012',
      scenarios: 3,
    },
    {
      id: 2,
      username: '@salonbotky',
      avatar: 'https://placebear.com/300/300',
      created: '12/12/2012',
      scenarios: 3,
    },
  ];

  return (
    <div className={styles.Page}>
      {isEmpty(bots) ? (
        <EmptyPage
          img={<img src={botHeadImg} alt="Bot" width={196} height={196} />}
          title="связать BotBuilder и Telegram"
          text="В несколько шагов"
          btnText="Подключить"
          onClick={onCreateOpen}
          footer={<VideoInstructions link="#" />}
        />
      ) : (
        <div className={styles.Content}>
          <div className={styles.Header}>
            <h2 className={styles.Title}>Выберите проект или создайте новый, чтобы начать ;)</h2>
            <p className={styles.Text}>
              consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla.
            </p>
            <VideoInstructions link="#" />
          </div>
          <div className={styles.Grid}>
            <div className={styles.EmptyCard}>
              <Button variant="primary" onClick={onCreateOpen}>
                Создать новый
              </Button>
            </div>
            {bots.map(b => (
              <BotCard key={b.id} {...b} />
            ))}
          </div>
        </div>
      )}

      <CreateBotModal isOpen={createOpen} onClose={onCreateClose} />
    </div>
  );
};

export default Bots;
