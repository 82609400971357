import i18n from 'i18n';
import { mixed, object, SchemaOf, string, boolean, array } from 'yup';

import {
  LandingVideoForm,
  LandingGeneratorSettings,
  LandingTextForm,
  LandingButtonForm,
  EOrientation,
  LandingFormForm,
  ELandingLinkType,
  PublishLandingForm,
} from './landing-generator.types';
import {
  youtubeVideo,
  vkAccount,
  instagramAccount,
  facebook,
  mobilePhone,
  telegramUsername,
} from 'constants/reg-exps'; //whatsapp and viber have to find

export const landingVideoSchema = (): SchemaOf<LandingVideoForm> =>
  object()
    .shape({
      link: string()
        .url()
        .matches(youtubeVideo, {
          excludeEmptyString: true,
          message: i18n.t('validations.mixed.youtube'),
        })
        .required()
        .label(i18n.t('labels.link')),
      title: string().notRequired().label(i18n.t('labels.title')),
      text: string().notRequired().label(i18n.t('labels.text')),
    })
    .required();

// not finished
export const landingSettingsSchema = (): SchemaOf<LandingGeneratorSettings> =>
  object()
    .shape({
      name: string().required().label(i18n.t('labels.title')),
      link: string().url().required().label(i18n.t('labels.link')),
    })
    .required();

export const landingTextSchema = (): SchemaOf<LandingTextForm> =>
  object()
    .shape({
      title: array().of(
        object().shape({
          content: string().notRequired().label(i18n.t('labels.title')),
        }),
      ),
      subtitle: array().of(
        object().shape({
          content: string().notRequired().label(i18n.t('labels.title')),
        }),
      ),
      text: array().of(
        object().shape({
          content: string().notRequired().label(i18n.t('labels.text')),
        }),
      ),
    })
    .required();

export const landingButtonSchema = (): SchemaOf<LandingButtonForm> =>
  object()
    .shape({
      vk: string()
        .url()
        .matches(vkAccount, {
          excludeEmptyString: true,
          message: i18n.t('validations.mixed.vk'),
        })
        .notRequired()
        .label(i18n.t('labels.vk')),
      viber: string()
        .matches(mobilePhone, {
          excludeEmptyString: true,
          message: i18n.t('validations.mixed.phone'),
        })
        .notRequired()
        .label(i18n.t('labels.viber')),
      whatsapp: string()
        .matches(mobilePhone, {
          excludeEmptyString: true,
          message: i18n.t('validations.mixed.phone'),
        })
        .notRequired()
        .label(i18n.t('labels.whatsapp')),
      instagram: string()
        .matches(instagramAccount, {
          excludeEmptyString: true,
          message: i18n.t('validations.mixed.instagram'),
        })
        .notRequired()
        .label(i18n.t('labels.instagram')),
      facebook: string()
        .url()
        .matches(facebook, {
          excludeEmptyString: true,
          message: i18n.t('validations.mixed.facebook'),
        })
        .notRequired()
        .label(i18n.t('labels.facebook')),
      telegram: string()
        .test({
          name: 'phone-or-tg',
          message: i18n.t('validations.mixed.tgOrPhone'),
          test: value => telegramUsername.test(value ?? '') || mobilePhone.test(value ?? ''),
        })
        .notRequired()
        .label(i18n.t('labels.telegram')),
      orientation: mixed<EOrientation>().oneOf(Object.values(EOrientation)).required(),
      inModal: boolean().required(),
      modalText: string().when('inModal', {
        is: true,
        then: string().required(),
        otherwise: string().notRequired(),
      }),
      websites: array().of(
        object().shape(
          {
            title: string().when(['text', 'url'], {
              is: (a: string, b: string) => a === '' && b === '',
              then: string().notRequired(),
              otherwise: string().required('Обязательное поле'),
            }),
            text: string().when(['title', 'url'], {
              is: (a: string, b: string) => a === '' && b === '',
              then: string().notRequired(),
              otherwise: string().required('Обязательное поле'),
            }),
            url: string()
              .url('Должно быть действительным URL')
              .when(['title', 'text'], {
                is: (a: string, b: string) => a === '' && b === '',
                then: string().notRequired(),
                otherwise: string().required('Обязательное поле'),
              }),
          },
          [
            ['title', 'text'],
            ['title', 'url'],
            ['text', 'url'],
          ],
        ),
      ),
    })
    .required();

export const landingFormSchema = (): SchemaOf<LandingFormForm> =>
  object()
    .shape({
      title: string().required(),
      text: string(),
      // fields: array()
      //   .of(
      //     object().shape({
      //       name: string().required(),
      //     }),
      //   )
      //   .min(1),
      buttonName: string().required(),
      inModal: boolean(),
      modalText: string().when('inModal', {
        is: true,
        then: string().required(),
        otherwise: string().notRequired(),
      }),
    })
    .required();

export const publishLandingSchema = (): SchemaOf<PublishLandingForm> =>
  object()
    .shape({
      type: mixed<ELandingLinkType>().oneOf(Object.values(ELandingLinkType)).required(),
      username: string().trim().required(),
    })
    .required();
