import { FC } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import styles from './DeleteModal.module.scss';
import SmallLoader from 'components/SmallLoader';

type Props = {
  title: string;
  question: string;
  info: string;
  applyBtnText: string;
  onClose: () => void;
  deleteAction: () => void;
  isOpen: boolean;
  isLoading?: boolean;
};

const DeleteModal: FC<Props> = ({
  applyBtnText,
  title,
  question,
  info,
  onClose,
  deleteAction,
  isOpen,
  isLoading,
}) => {
  const onApply = () => {
    deleteAction();
    // onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.Modal} noBtn>
      {isLoading ? (
        <SmallLoader />
      ) : (
        <div className={styles.ModalContent}>
          <p className={styles.Title}>{title}</p>
          <p className={styles.Question}>{question}</p>
          <p className={styles.Info}>{info}</p>
          <div className={styles.BtnWrapper}>
            <Button className={styles.BtnCLose} variant="text" onClick={onClose}>
              Отмена
            </Button>
            <Button onClick={onApply} className={styles.Confirm}>
              {applyBtnText}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DeleteModal;
