import { FC } from 'react';
import dayjs from 'dayjs';

import styles from './DatePicker.module.scss';
import DateModal from 'components/DateModal';
import Input from 'components/Input';

type Props = {
  day?: Date | null;
  setDay: (day: Date) => void;
  label: string;
};

const DatePicker: FC<Props> = ({ day = new Date(), setDay, label }) => {
  return (
    <DateModal
      setDay={newDay => {
        const currentDate = dayjs();
        if (newDay.isAfter(currentDate) || newDay.isSame(currentDate, 'day'))
          setDay(newDay.toDate());
      }}
      button={
        <Input
          label={label}
          icons={[{ name: 'date', className: styles.Icon }]}
          value={dayjs(day).format('DD.MM.YYYY в HH:mm')}
          readOnly
        />
      }
    />
  );
};

export default DatePicker;
