import { FC, useMemo, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import './ColorPicker.scss';
import 'react-contexify/dist/ReactContexify.css';

import styles from './ColorPicker.module.scss';
import Button from 'components/Button';
import { rgb2hex, stringToRgba } from 'utils/colorConverters';
import IconButton from 'components/IconButton';

export type ColorPickerProps = {
  color?: string;
  setColor: (color: string) => void;
  inline?: boolean;
  close?: () => void;
};

const ColorPicker: FC<ColorPickerProps> = ({ color, setColor, inline, close }) => {
  const [internalColor, setInternalColor] = useState(
    stringToRgba(color) ?? { r: 255, g: 0, b: 0, a: 1 },
  );
  const [inputColor, setInputColor] = useState(color as string);
  const hex = useMemo(() => rgb2hex(internalColor), [internalColor]);

  const onApply = () => {
    if (inputColor !== color) {
      setColor(inputColor);
    } else {
      setColor(hex);
    }
    if (close) {
      close();
    }
  };

  return (
    <div className={styles.Wrapper}>
      <div className={'CustomWrapper'}>
        {inline && (
          <IconButton className={styles.Icon} iconName="text-color" iconSize={24} onClick={close} />
        )}

        <RgbaColorPicker
          color={{
            r: Number(internalColor.r),
            g: Number(internalColor.g),
            b: Number(internalColor.b),
            a: 1,
          }}
          defaultValue={rgb2hex(internalColor)}
          onChange={newColor => {
            setInternalColor(newColor);
          }}
        />
        <div className={styles.Values}>
          <div className={styles.Card}>
            <div className={styles.Value}>{internalColor.r}</div>
            <div className={styles.Legend}>R</div>
          </div>
          <div className={styles.Card}>
            <div className={styles.Value}>{internalColor.g}</div>
            <div className={styles.Legend}>G</div>
          </div>
          <div className={styles.Card}>
            <div className={styles.Value}>{internalColor.b}</div>
            <div className={styles.Legend}>B</div>
          </div>
          <div className={styles.Card}>
            <div className={styles.Value}>{hex.toUpperCase()}</div>
            <div className={styles.Legend}>HEX</div>
          </div>
        </div>
        <input
          type="text"
          className={styles.Input}
          value={inputColor}
          onChange={e => setInputColor(e.target.value)}
        />
        <div className={styles.BtnWrapper}>
          <Button variant="tetriary" onClick={onApply}>
            Применить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
