import { FC } from 'react';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import styles from './GroupCard.module.scss';
import GroupNameDisplay from '../GroupNameDisplay';

type Props = {
  image?: string;
  title: string;
  subTitle: string;
  subscribers: string | number;
  isChecked?: boolean;
  onChoose: () => void;
  onFollowLink?: () => void;
};

const GroupCard: FC<Props> = ({
  image,
  title,
  subTitle,
  subscribers,
  isChecked,
  onChoose,
  onFollowLink,
}) => {
  return (
    <div key={subTitle} className={styles.Root}>
      <div className={styles.CardHeader}>
        <GroupNameDisplay image={image} subTitle={subTitle} title={title} />
        <CheckBox onChange={() => onChoose()} checked={isChecked} />
      </div>
      <p className={styles.Subtitle}>{subscribers} подписчика </p>
      <div className={styles.Buttons}>
        {isChecked ? (
          <Button className={styles.DeleteButton} variant="tetriary" onClick={() => onChoose()}>
            Отменить
          </Button>
        ) : (
          <Button className={styles.SelectBtn} onClick={() => onChoose()}>
            Выбрать
          </Button>
        )}
        <Button className={styles.MoveBtn} variant="tetriary" onClick={onFollowLink}>
          Перейти
        </Button>
      </div>
    </div>
  );
};

export default GroupCard;
