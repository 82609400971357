import { FC } from 'react';

import ToolsWrapper from '../../tools/ToolsWrapper';
import { Image as TImage, NonNullableActiveComponent } from 'modules/my-pages/my-pages.types';
import OpacitySelector from '../../tools/OpacitySelector';
import useSetComponentProperty from 'modules/my-pages/hooks/useSetComponentProperty';
import ClickAction from '../../tools/ClickAction';
import AnchorInput from '../../tools/AnchorInput';
import AddTextBtn from '../../tools/AddTextBtn';
import ImgInput from 'components/ImgInput';
import ImageStyleSelector from '../../tools/ImageStyleSelector';
import { useUploadPageImageMutation } from 'modules/landing-dashboard/landing-dashboard.api';
import { getSheetImage } from 'utils/getImage';

type Props = {
  component: NonNullableActiveComponent<TImage>;
};

const Image: FC<Props> = ({ component }) => {
  const [uploadImage] = useUploadPageImageMutation();
  const { hash, order, data } = component.component;
  const { setProperty, setText } = useSetComponentProperty(hash);
  const anchorActive = !!data.isAnchorActive;

  return (
    <ToolsWrapper>
      <ImgInput
        hasRecommendedSize
        setFile={async file => {
          if (!file) return;
          const image = await uploadImage({ image: file }).unwrap();
          setProperty('link', getSheetImage(image.body));
        }}
      />
      <ImageStyleSelector
        style={data.imageStyle}
        setStyle={value => setProperty('imageStyle', value)}
      />
      <OpacitySelector opacity={data.opacity} setOpacity={value => setProperty('opacity', value)} />
      <ClickAction
        click={data.onClick}
        setAction={value => setProperty('onClick.action', value)}
        setLink={value => setText('onClick.link', value)}
      />
      <AnchorInput
        anchor={data.anchor}
        setAnchor={value => setText('anchor', value)}
        setAnchorActive={() => setProperty('isAnchorActive', !anchorActive)}
        active={anchorActive}
      />
      <AddTextBtn parentId={component.parent.hash} atOrder={order} />
    </ToolsWrapper>
  );
};

export default Image;
