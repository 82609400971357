import { FC } from 'react';
import CheckBox from 'components/CheckBox';
import Button from 'components/Button';
import { TTelegramChat, TTelegramErrorResult } from '../../telegram.types';
import classNames from 'classnames';
import useWindowSize from 'hooks/useWindowSize';
import useAppDispatch from 'hooks/useAppDispatch';
import styles from './ChatRow.module.scss';
import { useRemoveAdminBotChatByIdMutation } from '../../codegen/telegram.admin.api';
import { generatePath, useNavigate } from 'react-router-dom';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { useBotId } from '../../telegram.hooks';
import r from 'constants/routes';
import { SimpleCipher } from '../../telegram.utils';
import { setSelectChatById, setSelectedChats } from '../../codegen/telegram.admin.reducer';

const ChatRow: FC<TTelegramChat> = ({ chatName, isReachable, isSelected, id }) => {
  const { width } = useWindowSize();
  const botId = useBotId();
  const isDesktop = width > 625;
  const dispatch = useAppDispatch();
  const [deleteChatById] = useRemoveAdminBotChatByIdMutation();
  const navigate = useNavigate();
  const encryptedName = SimpleCipher.encrypt(chatName);

  return (
    <tr
      className={classNames(styles.Row, {
        [styles.SelectedRow]: isSelected,
      })}
    >
      <td className={styles.CheckboxWrap}>
        <CheckBox
          className={styles.Checkbox}
          checked={isSelected}
          onChange={() => {
            dispatch(setSelectChatById({ chatId: id }));
            dispatch(setSelectedChats(id));
          }}
        />
        <a
          onClick={() =>
            navigate(
              generatePath(r.telegram.botsChat, {
                id: botId,
                chatId: id,
                messageName: encryptedName,
              }),
            )
          }
          className={styles.Par}
        >
          {chatName}
        </a>
      </td>
      {isDesktop ? (
        <td className={styles.StatusTd}>
          <div
            className={classNames(styles.Status, {
              [styles.DangerStatus]: !isReachable,
            })}
          />
          <p className={styles.Par}>Статус</p>
        </td>
      ) : (
        <div
          className={classNames(styles.Status, {
            [styles.DangerStatus]: !isReachable,
          })}
        />
      )}

      <td className={styles.LastTd}>
        {!isSelected && (
          <Button
            variant={'tetriary'}
            className={styles.Btn}
            onClick={() => {
              if (botId) {
                deleteChatById({ chatId: id, botId }).then(res => {
                  if ('error' in res) {
                    const clonedError: TTelegramErrorResult = JSON.parse(JSON.stringify(res));
                    showFeedback({
                      title: 'Ошибка',
                      text: clonedError.error.data.description,
                      type: EFeedbackType.ERROR,
                    });
                  } else {
                    showFeedback({
                      title: 'ГОТОВО!',
                      text: 'Вы удалили чат',
                      type: EFeedbackType.SUCCESS,
                    });
                  }
                });
              }
            }}
          >
            {isDesktop ? 'Покинуть чат' : 'Покинуть'}
          </Button>
        )}
      </td>
    </tr>
  );
};

export default ChatRow;
