import useTypedSelector from 'hooks/useAppSelector';

export const useGetSpamBotChats = () =>
  useTypedSelector(state => state.telegram.telegramSpamBot.spamBotChats);
export const useGetSpamBotChatsParams = () =>
  useTypedSelector(state => state.telegram.telegramSpamBot.spamChatsParams);

export const useGetSpamScheduledMessageParams = () =>
  useTypedSelector(state => state.telegram.telegramSpamBot.scheduledSpamMessagesPageParams);

export const useGetSpamSentMessageParams = () =>
  useTypedSelector(state => state.telegram.telegramSpamBot.sentSpamBotMessagesPageParams);
