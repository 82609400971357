import { CSSObjectWithLabel } from 'react-select';

export const selectStyles = {
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    borderColor: 'var(--m-2-outline, #2c2c35)',
    backgroundColor: 'var(--color-modal-bg)',
    paddingTop: '25px',
    paddingLeft: '5px',
    borderRadius: '12px',
    padding: '14px 5px',
    '&:hover': {
      border: '1px solid var(--color-m4)',
      transition: 'border-color var(--animation-transition)',
    },
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    backgroundColor: 'var(--color-modal-bg)',
  }),
  input: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: '#D0D0D0',
    fontSize: '16px',
    alignItems: 'center',
  }),
  singleValue: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: '#D0D0D0',
    fontSize: '16px',
  }),
  option: (
    baseStyles: CSSObjectWithLabel,
    { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean },
  ) => ({
    ...baseStyles,
    color: '#A9A9B7',
    backgroundColor: isSelected ? 'var(--dark-background)' : isFocused ? '#2C2C35' : 'transparent',
    '&:active': {
      backgroundColor: 'var(--color-modal-bg)',
    },
  }),
};

export const errorStyle = {
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    borderColor: 'red',
    backgroundColor: 'var(--color-modal-bg)',
    paddingTop: '25px',
    paddingLeft: '5px',
    borderRadius: '12px',
    padding: '14px 5px',
    '&:hover': {
      border: '1px solid var(--color-m4)',
      transition: 'border-color var(--animation-transition)',
    },
  }),
};
