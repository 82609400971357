import { FC } from 'react';

import styles from './TextMenu.module.scss';
import ContextMenu from 'components/ContextMenu';
import IconButton from 'components/IconButton';

type Props = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const TextMenu: FC<Props> = ({ open, onClose, onDelete }) => {
  return (
    <ContextMenu
      open={open}
      onClose={onClose}
      className={styles.Menu}
      disableClickOutside
      disableFocusTrap
    >
      <IconButton
        onClick={onDelete}
        iconClassName={styles.DeleteIcon}
        iconName="delete"
        iconSize={16}
      />
    </ContextMenu>
  );
};

export default TextMenu;
