import { FC } from 'react';
import styles from './AutoGroupNav.module.scss';
import Title from 'components/Title';
import Icon from 'components/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCurrentFolderData } from '../../vk.selectors';
import { useDeleteParsingMutation, useGetFolderGroupsByIdQuery } from '../../vk.api';
import DownloadCsvBtn from '../FoldersParsingSequence/ParsingCard/DownloadCsvBtn';
import Button from 'components/Button';
import { generatePath } from 'react-router-dom';

import r from 'constants/routes';

const AutoGroupNav: FC = () => {
  const navigate = useNavigate();
  const [deleteParsing] = useDeleteParsingMutation();
  const { id, parsingId } = useParams();
  const { isFetching } = useGetFolderGroupsByIdQuery({ groupId: id + '' });
  const { folderName } = useGetCurrentFolderData();

  if (isFetching) return <h1>...Loading</h1>;
  return (
    <nav className={styles.Nav}>
      <div className={styles.NavLeftInner}>
        <Icon
          iconName="chevron-left"
          size={16}
          className={styles.GoBack}
          onClick={() => navigate(-1)}
        />
        <div className={styles.FolderNameContainer}>
          <p className={styles.BackFolders}>Папки</p>
          <Title className={styles.Title}>{folderName}</Title>
        </div>
      </div>
      <div className={styles.Controls}>
        {parsingId && (
          <DownloadCsvBtn id={+parsingId} className={styles.RegBtn} name={folderName} />
        )}
        <Button
          className={styles.RegBtn}
          variant={'tetriary'}
          centralIcon={'alert-delete'}
          onClick={() => {
            if (parsingId)
              deleteParsing({ groupId: id + '', parsingId: +parsingId }).then(() => {
                navigate(generatePath(r.vk.groupsFolder, { id }));
              });
          }}
        ></Button>
      </div>
    </nav>
  );
};

export default AutoGroupNav;
