import { FC } from 'react';

import { Block as TBlock, NonNullableActiveComponent } from 'modules/my-pages/my-pages.types';
import ColorSelector from 'modules/my-pages/components/Settings/components/tools/ColorSelector';
import ImageSelector from 'modules/my-pages/components/Settings/components/tools/ImageSelector';
import OpacitySelector from '../../tools/OpacitySelector';
import BlockComponents from './components/BlockComponents';
import ToolsWrapper from '../../tools/ToolsWrapper';
import useSetComponentProperty from 'modules/my-pages/hooks/useSetComponentProperty';

type Props = {
  component: NonNullableActiveComponent<TBlock>;
};

const Block: FC<Props> = ({ component }) => {
  const { hash, data, components } = component.component;
  const { setProperty } = useSetComponentProperty(hash);

  return (
    <ToolsWrapper>
      <ColorSelector
        label="Цвет фона:"
        color={data.bgColor}
        setColor={value => setProperty('bgColor', value)}
      />
      <ImageSelector image={data.bgImage} setImage={value => setProperty('bgImage', value)} />
      <OpacitySelector opacity={data.opacity} setOpacity={value => setProperty('opacity', value)} />
      <BlockComponents parentId={hash} components={components ?? []} />
    </ToolsWrapper>
  );
};

export default Block;
