import { object, SchemaOf, string } from 'yup';
import { ISignIn } from '../auth/auth.interfaces';
import { pageLink, pageName } from './my-pages.constants';

export const myPagesSchema = (): SchemaOf<ISignIn> =>
  object()
    .shape({
      name: string()
        .required('Обязательное поле')
        .matches(pageName, 'Допустимо использовать a-z, A-Z, а-я, А-Я, 0-9 и ПРОБЕЛ'),
      link: string()
        .required('Обязательное поле')
        .matches(pageLink, 'Допустимо использовать a-z, A-Z, 0-9'),
    })
    .required();
