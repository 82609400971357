import { FC } from 'react';

import styles from './FormFieldsSelector.module.scss';
import { EFormField, FormFields } from 'modules/my-pages/my-pages.types';
import AddBtn from '../AddBtn';
import Tool from '../Tool';
import Label from 'components/Label';
import { FORM_FIELDS_NAMES } from 'modules/my-pages/my-pages.constants';
import Menu, { MenuItem } from 'components/Menu';
import Icon from 'components/Icon';
import _ from 'lodash';

type Props = {
  fields: FormFields;
  setFields: (fields: FormFields) => void;
};

const FormFieldsSelector: FC<Props> = ({ fields, setFields }) => {
  const { [EFormField.DATE]: omitted, ...clonedFormFields } = FORM_FIELDS_NAMES;
  const changeFieldType = (atOrder: number, toType: EFormField) => {
    const fieldsCopy: FormFields = JSON.parse(JSON.stringify(fields));
    const fieldAtOrder = fieldsCopy.find(field => field.order === atOrder);
    if (fieldAtOrder) {
      fieldAtOrder.type = toType;
      setFields(fieldsCopy);
    }
  };

  const getOptions = (order: number): (MenuItem & { type: EFormField })[] => {
    const entries = Object.entries(clonedFormFields);
    const pickedTypes = fields.map(field => field.type);
    const entriesWithoutPickedFields = entries.filter(
      ([type]) => !pickedTypes.includes(type as EFormField),
    );
    const options = entriesWithoutPickedFields.map(([type, name]) => ({
      label: name,
      isSelected: false,
      onClick: () => changeFieldType(order, type as EFormField),
      type: type as EFormField,
    }));
    return options;
  };

  const onAdd = () => {
    const options = getOptions(-1);
    setFields([...fields, { type: options[0].type, order: fields.length }]);
  };

  const canAddNewField = fields.length < Object.values(clonedFormFields).length;

  const handleDeleteField = (order: number) => {
    const fieldsCopy: FormFields = _.values(fields);
    const fieldAtOrder = fieldsCopy.find(field => field.order === order);
    if (fieldAtOrder) {
      fieldsCopy.splice(fieldsCopy.indexOf(fieldAtOrder), 1);
      setFields(fieldsCopy);
    }
  };

  return (
    <>
      <Label>Данные:</Label>

      {[...fields]
        .sort((a, b) => a.order - b.order)
        .map(field => (
          <Tool key={field.type}>
            <p className={styles.deleteBtn} onClick={() => handleDeleteField(field.order)}>
              Удалить
            </p>
            <Menu contextClassName={styles.Context} items={getOptions(field.order)}>
              {FORM_FIELDS_NAMES[field.type]}{' '}
              <Icon className={styles.IconDown} iconName="arrow-down" size={18} />
            </Menu>
          </Tool>
        ))}

      {canAddNewField && <AddBtn onAdd={onAdd}>данные</AddBtn>}
    </>
  );
};

export default FormFieldsSelector;
