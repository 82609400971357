import { FC } from 'react';

import styles from './EditorBtn.module.scss';
import Button, { ButtonProps } from 'components/Button';
import classNames from 'classnames';

const EditorBtn: FC<ButtonProps<'button'>> = ({ children, className, ...props }) => {
  return (
    <Button className={classNames(styles.EditorBtn, className)} {...props}>
      {children}
    </Button>
  );
};

export default EditorBtn;
