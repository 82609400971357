import { FC } from 'react';
import styles from './DisabledHeaderTabs.module.scss';
import { HeaderLink } from '../HeaderWithTabs';

type Props = {
  links: HeaderLink[];
};

const DisabledHeaderTabs: FC<Props> = ({ links }) => {
  return (
    <div className={styles.Container}>
      {links.map(({ link, name }) => (
        <p key={link + name} className={styles.Par}>
          {name}
        </p>
      ))}
    </div>
  );
};

export default DisabledHeaderTabs;
