import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ETransactionStatus, ETransactionType, FinanceModals, Sources } from './finances.types';

type FinancesState = {
  filters: {
    page: number;
    type?: ETransactionType;
    status?: ETransactionStatus;
  };
  buyModal: FinanceModals | null;
  type: Sources;
  currFinModal: Sources | null;
  currFinPassword?: string;
};

type SetFilterActions =
  | { field: 'page'; value: number }
  | { field: 'type'; value?: ETransactionType }
  | { field: 'status'; value?: ETransactionStatus };

const initialState: FinancesState = {
  filters: {
    page: 0,
  },
  buyModal: null,
  type: Sources.PAYEER,
  currFinModal: null,
  currFinPassword: '',
};

const financesSlice = createSlice({
  name: 'finances',
  initialState,
  reducers: {
    selectType: (state, { payload }: PayloadAction<Sources>) => {
      state.type = payload;
    },
    openFinPass: (state, { payload }: PayloadAction<Sources>) => {
      state.type = payload;
      state.buyModal = FinanceModals.FIN_PASS_BUY;
    },
    openFinPassWithdraw: state => {
      state.buyModal = FinanceModals.FIN_PASS_WITHDRAW;
    },
    setModal: (state, { payload }: PayloadAction<FinancesState['buyModal']>) => {
      state.buyModal = payload;
    },

    openCurrBuyModal: (state, { payload }: PayloadAction<Sources>) => {
      state.currFinModal = payload;
    },
    setFilter: (state, { payload }: PayloadAction<SetFilterActions>) => {
      if (payload.field === 'page') {
        state.filters.page = payload.value;
      }
      if (payload.field === 'type') {
        state.filters.type = state.filters.type === payload.value ? undefined : payload.value;
      }
      if (payload.field === 'status') {
        state.filters.status = state.filters.status === payload.value ? undefined : payload.value;
      }
    },

    setCurrFinPassword: (state, { payload }: PayloadAction<string>) => {
      state.currFinPassword = payload;
    },
    closeModal: state => {
      state.buyModal = null;
    },

    closeCurrModal: state => {
      state.currFinModal = null;
    },
  },
});

export const {
  openFinPass,
  openFinPassWithdraw,
  selectType,
  setModal,
  openCurrBuyModal,
  closeCurrModal,
  setFilter,
  closeModal,
  setCurrFinPassword,
} = financesSlice.actions;

export default financesSlice.reducer;
