import { FC, ReactNode } from 'react';

import styles from './ActionsLayout.module.scss';

type Props = {
  title: string;
  actionsTitle?: string;
  aside?: ReactNode;
  children: ReactNode;
};

const ActionsLayout: FC<Props> = ({ title, aside, actionsTitle, children }) => {
  return (
    <section className={styles.Section}>
      <h2 className={styles.Title}>{title}</h2>
      <div className={styles.Content}>
        {aside && <aside className={styles.Aside}>{aside}</aside>}
        <div className={styles.Actions}>
          {actionsTitle && <h2 className={styles.ActionsTitle}>{actionsTitle}</h2>}
          <div className={styles.Grid}>{children}</div>
        </div>
      </div>
    </section>
  );
};

export default ActionsLayout;
