import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import r from 'constants/routes';

import partyFace from 'assets/svg/auth/feedback/partyFace.svg';
import FeedbackCard from '../FeedbackCard';

const AccountRegistered: FC = () => {
  const navigate = useNavigate();

  return (
    <FeedbackCard
      title="Регистрация успешна"
      text="Поздравляем! Вы прошли регистрацию. Теперь можно начинать работу"
      button={{ text: 'Начнем', onClick: () => navigate(r.account) }}
      img={partyFace}
    />
  );
};

export default AccountRegistered;
