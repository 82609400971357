import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import masterCard from 'assets/svg/finances/Mastercard.svg';
import visa from 'assets/svg/finances/Visa.svg';
import qiwi from 'assets/svg/finances/Qiwi.svg';
import payeer from 'assets/svg/finances/Payeer.svg';

import Input from 'components/Input';
import Modal from 'components/Modal';

import styles from './WithdrawModal.module.scss';

import { Sources, WithdrawModalTypes } from 'modules/finances/finances.types';
import { WithdrawModalSchema } from 'modules/finances/finances.schema';
import { useSelectBalance } from 'modules/account/account.selector';
import { useFinancesModal } from 'modules/finances/finances.utils';
import { useCurrFinPassword, useSelectFinancesType } from 'modules/finances/finances.selectors';
import classNames from 'classnames';
import { useWithdrawPayeerAmountMutation } from '../../finances.api';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { TError } from '../../../tariff/tariff.type';
import Button from 'components/Button';
import env from '../../../../config/env';

type Props = {
  memoFinPassword?: string;
};

const WithdrawModal: FC<Props> = () => {
  const [withdrawPayeer, { isLoading: isPayeerWithdrawLoading }] =
    useWithdrawPayeerAmountMutation();
  const finPassword = useCurrFinPassword();

  const [{ isWithdraw }, onClose] = useFinancesModal();
  const source = useSelectFinancesType();
  const balance = useSelectBalance();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<WithdrawModalTypes>({
    mode: 'onChange',
    resolver: yupResolver(WithdrawModalSchema()),
    defaultValues: {
      //ToDo user phoneNum user.phoneNum//
    },
  });

  const src = useMemo(() => {
    switch (source) {
      case Sources.VISA:
        return visa;
      case Sources.MASTERCARD:
        return masterCard;
      case Sources.QIWI:
        return qiwi;
      case Sources.PAYEER:
        return payeer;
      default:
        return payeer;
    }
  }, [source]);

  const onSubmit = handleSubmit(({ account, amount }) => {
    if (source === Sources.PAYEER) {
      if (finPassword)
        withdrawPayeer({
          finPassword,
          account,
          amount: String(amount),
        })
          .then(res => {
            const result = res as unknown as TError;
            if (result.data.success) {
              showFeedback({
                title: 'ГОТОВО!',
                text: 'Вывод денежных средств\n' + 'совершен успешно',
                type: EFeedbackType.SUCCESS,
              });
              onClose();
            }
          })
          .catch(() => {
            onClose();
          });
    }
  });

  return (
    <Modal
      isOpen={isWithdraw}
      onClose={() => {}}
      className={classNames(styles.Modal, styles.ModalNew)}
      onSubmit={onSubmit}
      isLoading={isPayeerWithdrawLoading}
      isValid={isValid}
      noBtn
    >
      <p className={styles.Title}>Вывод денежных средств</p>
      {isWithdraw && (
        <p className={styles.Par}>Комиссия на вывод составит {env.WITHDRAW_COMMISSION}% </p>
      )}
      <Input
        label="Аккаунт"
        type="text"
        register={register('account')}
        wrapperClassName={styles.Phone}
      />
      <Input
        label="Сумма вывода"
        type="number"
        min={100}
        max={balance}
        register={register('amount')}
        invalid={!!errors.amount}
        errorMsg={errors.amount?.message}
        wrapperClassName={styles.Phone}
      />
      <div
        className={classNames(styles.Available, {
          [styles.Error]: !!errors.amount,
        })}
      >
        <span className={styles.Main}>Доступная сумма для вывода: </span>
        <span>{balance} р.</span>
      </div>
      <div className={styles.Info}>
        <img className={styles.Pay} src={src} alt="buy" />
      </div>
      <div className={styles.Btns}>
        <Button
          className={styles.BtnCLose}
          variant="text"
          onClick={() => {
            onClose();
            reset();
          }}
        >
          Закрыть
        </Button>
        <Button className={styles.Btn} variant="primary" type="submit" disabled={!isValid}>
          Подтвердить
        </Button>
      </div>
    </Modal>
  );
};

export default WithdrawModal;
