import { FC } from 'react';

import styles from './Profile.module.scss';
import NetworkCards from '../../components/Sections/components/NetworkCards';
import Partnership from '../../components/Sections/components/Partnership';
import Tariffs from '../../components/Sections/components/Tariffs';
import Tariff from 'modules/account/components/Tariff';

const Profile: FC = () => {
  return (
    <div className={styles.Profile}>
      <Tariff />
      <NetworkCards />
      <Partnership />
      <Tariffs />
    </div>
  );
};

export default Profile;
