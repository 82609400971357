import { FC } from 'react';
import styles from './SentMessageCard.module.scss';
import { TSentMessage } from '../../telegram.types';
import dayjs from 'dayjs';

const SentMessageCard: FC<TSentMessage> = ({ isSend, createdAt, text }) => {
  return (
    <div className={styles.Root}>
      <p className={styles.MainText}>{text}</p>
      <div className={styles.UnderWrap}>
        <div className={styles.Info}>
          <p className={styles.InfoPar}>{isSend ? 'Отправлено' : ' He Отправлено'}</p>
        </div>
        <div className={styles.Date}>
          <p className={styles.InfoPar}>{dayjs(createdAt).locale('ru').format('DD.MM.YYYY')}</p>
        </div>
      </div>
    </div>
  );
};

export default SentMessageCard;
