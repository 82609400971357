import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import EmptyPage from 'components/EmptyPage';
import r from 'constants/routes';
import messageImg from 'assets/svg/empty-pages/message.svg';
import BotCard, { BotCardProps } from 'components/BotCard';
import AutomationPageLayout from 'modules/vk/components/AutomationPageLayout';

const Mailing: FC = () => {
  const navigate = useNavigate();
  const mailings: BotCardProps[] = [
    {
      name: 'Салон красоты',
      data: (
        <>
          <BotCard.DataEntry
            iconName="target"
            name="Цель на день"
            value={<BotCard.DataEntry.Success>Выполнена</BotCard.DataEntry.Success>}
          />
          <BotCard.DataEntry iconName="account" name="Аккаунт" value="Лариса Ник" />
          <BotCard.DataEntryLong
            iconName="message"
            name="Сообщение"
            value="5 key components for aspiring designers that shape stylish design. Practice in Figma and create our first layout. That's all we're going to do in this lesson, buddy!"
          />
        </>
      ),
      link: generatePath(r.vk.mailing.edit, { id: '1' }),
    },
    {
      name: 'Салон красоты 2',
      data: (
        <>
          <BotCard.DataEntry
            iconName="target"
            name="Цель на день"
            value={<BotCard.DataEntry.Range total={24} of={13} />}
          />
          <BotCard.DataEntry iconName="account" name="Аккаунт" value="@dfsldksjdf" />
          <BotCard.DataEntryLong
            iconName="message"
            name="Сообщение"
            value="5 key components for aspiring designers that shape stylish design. Practice in Figma and create our first layout. That's all we're going to do in this lesson, buddy!"
          />
        </>
      ),
      link: generatePath(r.vk.mailing.edit, { id: '1' }),
    },
  ];

  return (
    <AutomationPageLayout
      title="Рассылки"
      emptyPage={
        <EmptyPage
          img={<img src={messageImg} width={205} height={190} alt="Message" />}
          title="Создайте свои первые рассылки"
          text="В несколько шагов"
          btnText="Создать"
          onClick={() => navigate(generatePath(r.vk.mailing.edit, { id: '1' }))}
        />
      }
      cards={mailings.map(mailing => (
        <BotCard key={mailing.name} {...mailing} />
      ))}
      onSearch={() => {}}
      onAdd={() => navigate(generatePath(r.vk.mailing.edit, { id: '1' }))}
      addText="Новая рассылка"
    />
  );
};

export default Mailing;
