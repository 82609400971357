import { TAdminChatsParams } from '../telegram.types';

export const dataCountLoadingState = {
  loading: true,
  count: 0,
  data: [],
};

export const paginationParams = {
  limit: 12,
  offset: 1,
};

export const adminBotChatParams: TAdminChatsParams = {
  chatName: '',
  ...paginationParams,
};

export enum PlanedMessageCardType {
  SPAM_BOT = 'SPAM_BOT',
  ADMIN_BOT = 'ADMIN_BOT',
}

export const welcomeBotState: Record<string, string> = {
  PROFIT_BOX: 'ProfitBox',
  STARS: 'Stars',
};
