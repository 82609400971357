import { FC, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash-es/isEmpty';

import EditorBtn from '../EditorBtn';
import EditorForm from '../EditorForm';
import EditorTitle from '../EditorTitle';
import { LandingIdType, LandingTextForm } from 'modules/landing-generator/landing-generator.types';
import { landingTextSchema } from 'modules/landing-generator/landing-generator.schema';
import {
  useSelectLanding,
  useSelectPrevLanding,
} from 'modules/landing-generator/landing-generator.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { setLandingText } from 'modules/landing-generator/landing-generator.reducer';
import { compareLandingText } from 'modules/landing-generator/landing-generator.comparators';
import { convertLandingTextFormToLandingText } from 'modules/landing-generator/landing-generator.converters';

type Props = {
  id: LandingIdType;
};

const TextEditor: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const landing = useSelectLanding(id);
  const prevLanding = useSelectPrevLanding(id);

  const defaultValues = useMemo(
    () => ({
      title: !isEmpty(landing?.text.title)
        ? landing?.text?.title.map(t => ({ content: t.content }))
        : [{ content: '' }],
      subtitle: !isEmpty(landing?.text.subtitle)
        ? landing?.text?.subtitle.map(t => ({ content: t.content }))
        : [{ content: '' }],
      text: !isEmpty(landing?.text.text)
        ? landing?.text?.text.map(t => ({ content: t.content }))
        : [{ content: '' }],
    }),
    [landing?.text],
  );

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<LandingTextForm>({
    mode: 'onChange',
    resolver: yupResolver(landingTextSchema()),
    defaultValues,
  });

  useEffect(() => {
    if (landing && prevLanding) {
      if (!compareLandingText(landing, prevLanding)) {
        reset(defaultValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, defaultValues]);

  const onSubmit: SubmitHandler<LandingTextForm> = data => {
    const text = convertLandingTextFormToLandingText(data, landing?.text);
    dispatch(setLandingText({ id, text }));
  };

  return (
    <EditorForm onSubmit={handleSubmit(onSubmit)}>
      <EditorTitle>Добавление и редактирование текста</EditorTitle>

      <EditorBtn disabled={!isValid}>Сохранить</EditorBtn>
    </EditorForm>
  );
};

export default TextEditor;
