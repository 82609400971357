import { ComponentPropsWithoutRef, ElementType, PropsWithChildren } from 'react';
import classNames from 'classnames';
import isUndefined from 'lodash-es/isUndefined';

import Icon from 'components/Icon';

import styles from './Button.module.scss';

type PolymorphicAsProp<E extends ElementType> = {
  as?: E;
};

type PolymorphicProps<E extends ElementType> = PropsWithChildren<
  ComponentPropsWithoutRef<E> & PolymorphicAsProp<E>
>;

export type ButtonProps<E extends ElementType> = PolymorphicProps<E> & {
  variant?:
    | 'primary'
    | 'secondary'
    | 'tetriary'
    | 'tetriary-blue'
    | 'ghost'
    | 'quaternary'
    | 'magic'
    | 'white'
    | 'text'
    | 'delete'
    | 'viper';
  size?: 'l' | 'm' | 's';
  shape?: 'rounded' | 'square';
  leftIcon?: string;
  centralIcon?: string;
  rightIcon?: string;
};

const Button = <E extends ElementType = 'button'>({
  as,
  variant = 'primary',
  size = 'm',
  shape = 'rounded',
  className,
  leftIcon,
  centralIcon,
  rightIcon,
  children,
  ...props
}: ButtonProps<E>) => {
  const Component = as ?? 'button';
  const iconSize = size === 'l' ? 20 : 18;

  return (
    <Component
      className={classNames(className, styles.Button)}
      data-variant={variant}
      data-size={size}
      data-shape={shape}
      data-with-central-icon={!isUndefined(centralIcon)}
      {...props}
    >
      {leftIcon && (
        <Icon
          className={classNames(styles.Icon, styles.LeftIcon)}
          iconName={leftIcon}
          size={iconSize}
        />
      )}
      {centralIcon ? (
        <Icon className={styles.Icon} iconName={centralIcon} size={iconSize} />
      ) : (
        children
      )}
      {rightIcon && (
        <Icon
          className={classNames(styles.Icon, styles.RightIcon)}
          iconName={rightIcon}
          size={iconSize}
        />
      )}
    </Component>
  );
};

export default Button;
