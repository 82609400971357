import { FC } from 'react';

import styles from './MyPagesHeader.module.scss';
import { useGetPagesQuery } from 'modules/landing-dashboard/landing-dashboard.api';
import CreatePageBtn from '../CreatePageBtn';
import { videoTutorials } from '../../../../constants/videos';
import { BusinessCardType } from '../../landing-dashboard.types';
import Tutorials from 'components/Tutorials';

type Props = {
  landings: BusinessCardType[];
};
const MyPagesHeader: FC<Props> = () => {
  const { data, isLoading } = useGetPagesQuery({});
  const numberOfPages = data?.body?.count ?? 0;

  return (
    <div className={styles.Header}>
      <h1 className={styles.Title}>Business card</h1>
      {numberOfPages < 10 && !isLoading && (
        <>
          <CreatePageBtn className={styles.CreateBtn}>Создать страницу</CreatePageBtn>
        </>
      )}
      <Tutorials
        tutorials={[
          {
            label: 'Как создавать сайт-визитку без использования шаблона',
            url: videoTutorials.createBusinessCardSitewithoutTemplate,
          },
          {
            label: 'Как создавать сайт-визитку',
            url: videoTutorials.createBusinessCardSite,
          },
        ]}
      />
    </div>
  );
};

export default MyPagesHeader;
