import { FC } from 'react';
import styles from './TimeMiniCard.module.scss';

type Props = {
  time: string;
  onCloseClick: () => void;
};

const TimeMiniCard: FC<Props> = ({ time, onCloseClick }) => {
  return (
    <div className={styles.Container}>
      <p className={styles.Par}>{time}</p>
      <svg
        className={styles.IconClose}
        onClick={onCloseClick}
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="9"
        viewBox="0 0 5 6"
        fill="none"
      >
        <path
          d="M3.83434 0.553704L2.50001 1.88803L1.16568 0.553704C0.938703 0.326723 0.571724 0.326714 0.344733 0.553704C0.117743 0.780695 0.117742 1.14766 0.344733 1.37466L1.67906 2.70898L0.344733 4.04331C0.117752 4.27029 0.117743 4.63727 0.344734 4.86427C0.571724 5.09126 0.938694 5.09126 1.16568 4.86427L2.50001 3.52994L3.83434 4.86426C4.06132 5.09125 4.4283 5.09126 4.65529 4.86427C4.88229 4.63727 4.88228 4.2703 4.65529 4.04331L3.32096 2.70899L4.65529 1.37466C4.88228 1.14767 4.88228 0.780696 4.65529 0.553705C4.4283 0.326714 4.06022 0.327823 3.83434 0.553704Z"
          fill="#5B5B65"
        />
      </svg>
    </div>
  );
};

export default TimeMiniCard;
