import { FC } from 'react';
import isUndefined from 'lodash-es/isUndefined';
import classNames from 'classnames';

import Card from '../Card';
import Title from 'components/Title';
import Alternative, { AlternativeProps } from '../Alternative';
import AuthButton from '../AuthButton';

import styles from './FeedbackCard.module.scss';

type Props = {
  title: string;
  text: string;
  button?: { text: string; onClick: () => void };
  img?: string;
  alternatives?: AlternativeProps[];
  cardStyles?: string;
};

const FeedbackCard: FC<Props> = ({ title, text, button, alternatives, img, cardStyles }) => {
  return (
    <Card className={classNames(styles.Card, cardStyles)}>
      <Title className={styles.Title}>{title}</Title>

      <img className={styles.Img} src={img} alt="feedback" width={185} height={155} />

      <p className={styles.Text}>{text}</p>

      {!isUndefined(button) && (
        <AuthButton onClick={button.onClick} className={styles.Btn}>
          {button.text}
        </AuthButton>
      )}

      {!isUndefined(alternatives) && (
        <div className={styles.Alternatives}>
          {alternatives.map((alternative, index) => (
            <Alternative key={index} {...alternative} />
          ))}
        </div>
      )}
    </Card>
  );
};

export default FeedbackCard;
