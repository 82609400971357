import { FC } from 'react';
import styles from './FolderGroupInfo.module.scss';
import { useGetCurrentFolderData } from '../../vk.selectors';

const FolderGroupInfo: FC = () => {
  const { maxMembersCount, sumGroupMembers, groupCount } = useGetCurrentFolderData();
  return (
    <div className={styles.Root}>
      <h2 className={styles.GroupNum}>
        Групп: <span>{groupCount}</span>
      </h2>
      <p className={styles.UsersNum}>
        Пользователей:{' '}
        <span>
          {sumGroupMembers} / {maxMembersCount}
        </span>
      </p>
    </div>
  );
};

export default FolderGroupInfo;
