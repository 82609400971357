import i18n from 'i18n';
import { SchemaOf, object, string } from 'yup';

import { PartnersNewsletterRequestBody } from './partnership-program.types';

export const partnersNewsletterSchema = (): SchemaOf<PartnersNewsletterRequestBody> =>
  object()
    .shape({
      message: string().max(255).required().label(i18n.t('labels.message')),
    })
    .required();
