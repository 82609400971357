import { FC } from 'react';
import DateModal from 'components/DateModal';
import DisplayField from 'components/DisplayField';

type Props = {
  day?: Date | null;
  setDay: (day: Date) => void;
  label: string;
  dateTime: string;
  timesArr: string[];
  isWeeklyOption: boolean;
  noValue?: boolean;
};

const TranslationDatePicker: FC<Props> = ({ setDay, dateTime, isWeeklyOption, noValue }) => {
  return (
    <>
      <DateModal
        setDay={newDay => setDay(newDay.toDate())}
        noHours
        button={
          <>
            {!isWeeklyOption && <DisplayField noValue={noValue} label={'Дата:'} value={dateTime} />}
          </>
        }
      />
    </>
  );
};

export default TranslationDatePicker;
