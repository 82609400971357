import { FC, useMemo, useRef } from 'react';
import classNames from 'classnames';

import styles from './DropdownTemplate.module.scss';
import useClickOutside from 'hooks/useClickOutside';
import DropdownMenu, { DropdownOption } from 'components/DropdownMenu';
import useDisclosure from 'hooks/useDisclosure';

type Props = {
  button: {
    content: (isOpen: boolean) => JSX.Element;
    className?: string;
  };
  menu: {
    options: DropdownOption[];
    title?: string;
    className?: string;
  };
  wrapperClassName?: string;
};

const DropdownTemplate: FC<Props> = ({ button, menu, wrapperClassName }) => {
  const ref = useRef<HTMLElement>(null);

  const { open, toggleOpen, onClose } = useDisclosure();

  useClickOutside(ref, onClose);

  const btnContent = useMemo(() => button.content(open), [open, button]);

  return (
    <nav className={classNames(styles.Wrapper, wrapperClassName)} ref={ref}>
      <button
        className={classNames(styles.Btn, button.className)}
        onClick={toggleOpen}
        type="button"
      >
        {btnContent}
      </button>

      <DropdownMenu
        className={classNames(styles.Menu, menu.className)}
        options={menu.options.map(option => ({
          ...option,
          onClick: () => {
            option.onClick();
            onClose();
          },
        }))}
        title={menu.title}
        open={open}
      />
    </nav>
  );
};

export default DropdownTemplate;
