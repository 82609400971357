import { FC, ReactNode } from 'react';

import styles from './GreetingModalEntry.module.scss';

import Avatar from 'components/Avatar';

type Props = {
  src?: string;
  name: string;
  value: ReactNode;
};
const GreetingModalEntry: FC<Props> = ({ src, name, value }) => {
  return (
    <div className={styles.Entry}>
      <Avatar src={src} name={name} size={36} />
      <span>{name}</span>
      <span className={styles.Value}>{value}</span>
    </div>
  );
};

export default GreetingModalEntry;
