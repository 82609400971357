import { FC } from 'react';

import styles from './SettingsButton.module.scss';
import Button, { ButtonProps } from 'components/Button';

const SettingsButton: FC<ButtonProps<'button'>> = ({ children, ...props }) => {
  return (
    <Button {...props} variant="tetriary" className={styles.SettingsButton}>
      {children}
    </Button>
  );
};

export default SettingsButton;
