import { FC } from 'react';
import classNames from 'classnames';

import styles from './ProgramInfo.module.scss';
import ReferralLink from 'modules/partnership-program/components/ReferralLink';
import Income from 'modules/partnership-program/components/Income';
import Supervisor from 'modules/partnership-program/components/Supervisor';
import WriteAllPartners from 'modules/partnership-program/components/WriteAllPartners';
import { useLinesParams } from 'modules/partnership-program/partnership-program.hooks';

type Props = {
  className?: string;
};

const ProgramInfo: FC<Props> = ({ className }) => {
  const line = useLinesParams();
  const isFirstLine = line === 'first';

  return (
    <section className={classNames(styles.Info, className)}>
      <ReferralLink />
      {isFirstLine && <WriteAllPartners />}
      <Income />
      <Supervisor />
    </section>
  );
};

export default ProgramInfo;
