import { FC, useState } from 'react';

import styles from './ModalWithImgInput.module.scss';
import ImgInput from 'components/ImgInput';
import Modal, { CommonModalProps } from 'components/Modal';

export type ModalWithImgInputProps = {
  images: string[];
  setImages: (images: string[]) => void;
  limit?: number;
};

type Props = {
  modalProps: CommonModalProps;
} & ModalWithImgInputProps;

const ModalWithImgInput: FC<Props> = ({ modalProps, setImages, limit = 1 }) => {
  const [internalState, setInternalState] = useState<File[]>([]);

  const onSubmit = () => {
    const nextImages = internalState.map(img => URL.createObjectURL(img));
    setImages(nextImages);
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} onSubmit={onSubmit} isValid={true}>
      <h3 className={styles.Title}>Добавить фото</h3>
      <p className={styles.Text}>Загрузите фотографию по инструкции</p>
      <ImgInput limit={limit} setFile={file => file && setInternalState(prev => [...prev, file])} />
    </Modal>
  );
};

export default ModalWithImgInput;
