import { UpdateScheduledMessage } from './codegen/telegram.admin.api';
import {
  TranslationEndingTime,
  TranslationPeriodTime,
  TranslationRepeatTime,
  WeekDays,
} from '../../constants/date-durations';
import { TWelcome } from '../account/account.interfaces';

export type TResponseData<T, Err = T> = { success: true; data: T } | { success: false; data: Err };

export type WelcomeBot = {
  id: string;
  isAuthorize: boolean;
  name: string;
  phone: string;
  status: boolean;
  userId: string;
};

export type TDataResponse<T> = {
  count: number;
  data: T;
};

export type TAutoErrorBotResponse = {
  error: {
    data: {
      status: boolean;
      type: WelcomeBotError;
      description: string;
    };
    status: number;
  };
};

export type TAutoBotResponse<T> =
  | {
      status: number;
      data: T;
    }
  | TAutoErrorBotResponse;

export type AdminBot = {
  id: number;
  name: string;
  username: string;
};

export type WelcomeBotAuthBody = {
  phone: string;
  name: string;
};

export type AddBotRequestBody = {
  token: string;
  name: string;
  timezone: string;
};

export type AddGroupRequestBody = {
  link: string;
};

export type EditAdminBotForm = {
  name: File;
  message: string;
};

export type AddSpamBotAccountRequestBody = {
  firstName: string;
  lastName: string;
  message: string;
};

export type SetIntervalRequestBody = {
  interval: number;
};

export type AddSpamBotGroupRequestBody = {
  name: string;
  link: string;
  message: string;
};

export type GreetingBotTextRequestBody = {
  text: string;
  files: File[];
};

export type AddGreetingBotAccountRequestBody = {
  firstName: string;
  lastName: string;
};

export type AddGreetingBotAccountCodeSchema = {
  code: number | string;
};

export interface AddGreetingBotAccountValidate extends AddGreetingBotAccountCodeSchema {
  cloudCode?: string;
}

export enum GreetinBotModalState {
  ACCOUNT,
  CONFIRMATION,
  REFERAL,
  CHECKING,
}

export interface TWelcomeBot extends TWelcome {
  logoThumb?: string;
  onClick?: () => void;
  isConnected?: boolean;
}

export type UpdateAdminMessage = UpdateScheduledMessage & {
  hours: number;
  minutes: number;
};

export type TCountType<T> = {
  count: number;
  data: T;
};

export type TTelegramChat = {
  chatId: string;
  chatName: string;
  createdAt: string;
  id: string;
  isReachable: boolean;
  status: string;
  updatedAt: string;
  isSelected?: boolean;
};

export type TAdminChatsParams = {
  chatName?: string;
  limit?: number;
  offset?: number;
  maxPageCount?: number;
};

export type TPaginationParams = {
  limit?: number;
  offset?: number;
};

export type TTelegramErrorResult = {
  error: {
    status: number;
    data: {
      description: string;
      status: boolean;
    };
  };
};

export type TTelegramResult<T> = {
  res: {
    data: T;
  };
};

export type TCommand = {
  id?: string;
  name: string;
  description: string;
  text: string;
  isChecked?: boolean;
  isEnable?: boolean;
  files: File[];
};

export type TAdminBotState<T> = {
  loading: boolean;
  count: number;
  data: T;
};

export type TAdminMessages = {
  files: File[];
  id: string;
  isChecked: boolean;
  status: boolean;
  text: string;
};

export type TAdminMessageSchedule = {
  week: string;
  time: string;
};

export type TAdminMessageForm = {
  text: string;
  persist: string;
  schedule?: TAdminMessageSchedule[];
  weekdays: WeekDays[];
  times: string[];
  dates: string[];
  weeks: string[];
  files: File[];
  repeatType?: TranslationRepeatTime | string;
  endingType?: TranslationEndingTime;
  periodType?: TranslationPeriodTime | null;
};

export type TFile = {
  id: string;
  mime: string;
  url: string;
  name: string;
  key: string;
  size: string;
};

export type TScheduledMessage = {
  id: string;
  text: string;
  persist: boolean;
  botId: string;
  files: TFile[];
  dateSend?: null | string;
  repeatType: string;
  times?: string[];
  weeks: {
    week: string;
    id: string;
  }[];
  date: [
    {
      id: string;
      date: string;
    },
  ];
  schedule: TAdminMessageSchedule[];
};

export type TSentMessage = {
  id: string;
  text: string;
  createdAt: string;
  isSend: boolean;
  files: [
    {
      id: string;
      mime: string;
      url: string;
      name: string;
      key: string;
      size: string;
    },
  ];
  date: [
    {
      id: string;
      date: string;
      isSend: boolean;
      scheduledMessageId: string;
      deleted: boolean;
      createdAt: string;
      updatedAt: string;
    },
  ];
  schedule: TAdminMessageSchedule[];
};

export type TWelcomeBotStatistic = {
  status: string;
  date: string;
  name: string;
  surname: string;
  login: string;
  phone: string;
};

// Define a type guard function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTTelegramErrorResult(obj: any): obj is TTelegramErrorResult {
  return (
    obj &&
    obj.error &&
    typeof obj.error.status === 'number' &&
    obj.error.data &&
    typeof obj.error.data.description === 'string' &&
    typeof obj.error.data.status === 'boolean'
  );
}

export type ModalObject = {
  [TranslationRepeatTime.NOT_REPEAT]: {
    fileId?: string; // Replace with the actual type of fileId
    title: string; // Replace with the actual type of title
    repeatType: string; // Replace with the actual type of repeatType
    date: string | number | Date; // Replace with the actual type of date
    times: string[]; // Replace with the actual type of times
  };
  [TranslationRepeatTime.DAILY]: {
    fileId?: string;
    title: string;
    repeatType: string;
    date: string | number | Date;
    times: string[];
  };
  [TranslationRepeatTime.WEEKLY]: {
    fileId?: string;
    title: string;
    repeatType: string;
    weekDays?: string[]; // Replace with the actual type of weekDays
    times: string[];
  };
  [TranslationRepeatTime.WORKDAY]: {
    fileId?: string;
  };
  [TranslationRepeatTime.WEEKEND]: {
    fileId?: string;
  };
  [TranslationRepeatTime.YEARLY]: {
    fileId?: string;
  };
  [TranslationRepeatTime.OTHER]: {
    fileId?: string;
  };
};

export type TWelcomeBotContactsParams = {
  phone: string;
  username: string;
  hasError: boolean;
  isSend: boolean;
  service: string;
  limit: number;
  offset: number;
};

export enum EMessageService {
  STARS = 'STARS',
  STARS_ONLINE = 'STARS_ONLINE',
  PROFIT_BOX = 'PROFIT_BOX',
}

export enum EWelcomeBotState {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  NONE = 'NONE',
}

export type SendHistory = {
  id: number;
  dateSend: string; // Consider using Date type if possible
  isSend: boolean;
  hasError: boolean;
  description: string;
};

export type TWelcomeBotContactsStatistic = {
  id: string;
  phone: string;
  service: string;
  username: string;
  firstName: string;
  lastName: string;
  hasError: boolean;
  sendHistory: SendHistory[];
};

export enum WelcomeBotError {
  NEED_2FA_CODE = 'NEED_2FA_CODE',
  INVALID_CODE = 'INVALID_CODE',
  FLOOD = 'FLOOD',
  BOT_NOT_FOUND = 'BOT_NOT_FOUND',
}
export type TWelcomeBotMessage = {
  id: string;
  text: string;
  service: EMessageService;
  status: boolean;
  files: unknown[];
};
