import { FC } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import { Reorder } from 'framer-motion';

import styles from './BlockComponents.module.scss';
import { AnyComponent } from 'modules/my-pages/my-pages.types';
import Label from 'components/Label';
import DraggableComponent from '../DraggableComponent';
import useAppDispatch from 'hooks/useAppDispatch';
import { reorderComponents } from 'modules/my-pages/my-pages.reducer';

type Props = {
  parentId: string;
  components: AnyComponent[];
};

const BlockComponents: FC<Props> = ({ parentId, components }) => {
  const dispatch = useAppDispatch();

  const items = [...components].sort((a, b) => a.order - b.order);

  const onReorder = (newList: AnyComponent[]) => {
    dispatch(reorderComponents({ parentId, newList }));
  };

  return (
    <div className={styles.Wrapper}>
      <Label className={styles.Label}>Компoненты блока:</Label>
      <div className={styles.Components}>
        {isEmpty(items) ? (
          <Label className={styles.Label}>Тут нет компонентов</Label>
        ) : (
          <Reorder.Group values={items} onReorder={onReorder} className={styles.Group}>
            {items.map(component => (
              <DraggableComponent key={component.hash} component={component} />
            ))}
          </Reorder.Group>
        )}
      </div>
    </div>
  );
};

export default BlockComponents;
