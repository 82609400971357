import { FC } from 'react';
import { BlockComponentsTypes } from './BlockComponents.types';
import styles from './BlockComponents.module.scss';
import { getComponentNameAndValue } from 'modules/my-pages/my-pages.helpers';
import formatLongText from 'utils/formatLongTrxt';

export const BlockComponents: FC<BlockComponentsTypes> = ({ components }) => {
  return (
    <div>
      {components?.map(component => {
        const name = getComponentNameAndValue(component);
        return (
          <div key={component.id} className={styles.componentWrapper}>
            <p className={styles.elementType}>{name.title}</p>
            <p className={styles.elementContent}>{formatLongText(name.value, 30)}</p>
          </div>
        );
      })}
    </div>
  );
};
