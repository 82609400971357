import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

import ru from './locales/ru-RU';

import { LOCALES } from 'constants/locales';

dayjs.extend(utc);
dayjs.extend(timezone);

i18n.use(initReactI18next).init({
  fallbackLng: LOCALES.RUSSIAN,
  lng: LOCALES.RUSSIAN,
  resources: {
    [LOCALES.RUSSIAN]: ru,
  },
});

setLocale(ru.translation.validations);

export default i18n;
