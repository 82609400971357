import { tgApi as api } from 'config/http.tg';
import { EAppTGTagType } from 'interfaces/api';
import { CreateBotAdminPayload } from './telegram.admin.api';
import {
  TAdminChatsParams,
  TCountType,
  TScheduledMessage,
  TSentMessage,
  TTelegramChat,
} from '../telegram.types';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    createSpamBot: build.mutation<CreateBotAdminPayload, unknown>({
      query: ({ body }) => ({
        url: '/bot/spam',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTGTagType.SPAM],
    }),

    toggleSpamBot: build.mutation<void, { botId: string }>({
      query: ({ botId }) => ({
        url: `/bot/spam/${botId}/toggle`,
        method: 'PATCH',
      }),
      invalidatesTags: [EAppTGTagType.SPAM],
    }),

    deleteSpamBot: build.mutation<void, { botId: string }>({
      query: ({ botId }) => ({
        url: `/bot/spam/${botId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.SPAM],
    }),

    deleteAllSpamChatsBots: build.mutation<void, { botId: string; chatIds: string[] }>({
      query: ({ botId, ...body }) => ({
        url: `/bot/spam/${botId}/chat`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [EAppTGTagType.SPAM_CHATS],
    }),

    getAllSpamBots: build.query<TCountType<TSpamBot[]>, void>({
      query: () => ({ url: `/bot/spam`, method: 'GET' }),
      providesTags: [EAppTGTagType.SPAM],
    }),

    getSpamSentMessages: build.query<
      TCountType<TSentMessage[]>,
      { botId: string; chatId: string } & TAdminChatsParams
    >({
      query: ({ botId, chatId, ...params }) => ({
        url: `/bot/spam/${botId}/chat/${chatId}/message/sent`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTGTagType.SPAM_SCHEDULED_MESSAGES],
    }),

    getAllSpamChatsBots: build.query<
      TCountType<TTelegramChat[]>,
      { botId: string } & TAdminChatsParams
    >({
      query: ({ botId, ...params }) => ({
        url: `/bot/spam/${botId}/chat`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTGTagType.SPAM_CHATS],
    }),
    deleteSpamBotChat: build.mutation<void, { botId: string; chatId: string }>({
      query: ({ botId, chatId }) => ({
        url: `/bot/spam/${botId}/chat/${chatId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.SPAM_CHATS],
    }),
    deleteSpamScheduledMessage: build.mutation<
      TCountType<unknown>,
      { botId: string; chatId: string; messageId: string }
    >({
      query: ({ botId, chatId, messageId }) => ({
        url: `/bot/spam/${botId}/chat/${chatId}/message/${messageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.SPAM_SCHEDULED_MESSAGES],
    }),
    getAllScheduledMessageSpamBot: build.query<
      TCountType<TScheduledMessage[]>,
      { botId: string; chatId: string } & TAdminChatsParams
    >({
      query: ({ botId, chatId, ...params }) => ({
        url: `/bot/spam/${botId}/chat/${chatId}/message`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTGTagType.SPAM_SCHEDULED_MESSAGES],
    }),
    getScheduledSpamMessageById: build.query<
      TScheduledMessage,
      { botId: string; chatId: string; messageId: string }
    >({
      query: ({ botId, chatId, messageId }) => ({
        url: `/bot/spam/${botId}/chat/${chatId}/message/${messageId}`,
        method: 'GET',
      }),
      providesTags: [EAppTGTagType.SPAM_SCHEDULED_MESSAGES],
    }),
    createScheduledMessageSpamBot: build.mutation<
      void,
      { botId: string; chatId: string; body: FormData }
    >({
      query: ({ botId, chatId, body }) => ({
        url: `/bot/spam/${botId}/chat/${chatId}/message`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTGTagType.SPAM_SCHEDULED_MESSAGES],
    }),
    updateSpamScheduledMessage: build.mutation<
      TCountType<unknown>,
      { botId: string; chatId: string; messageId: string; body: FormData }
    >({
      query: ({ botId, chatId, messageId, body }) => ({
        url: `/bot/spam/${botId}/chat/${chatId}/message/${messageId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppTGTagType.SPAM_SCHEDULED_MESSAGES],
    }),
    spamBotControllerGetBot: build.query<TSpamBot, SpamBotControllerGetBotApiArg>({
      query: queryArg => ({ url: `/bot/spam/${queryArg.id}` }),
      providesTags: [EAppTGTagType.SPAM],
    }),
    spamBotControllerDeleteBot: build.mutation<
      SpamBotControllerDeleteBotApiResponse,
      SpamBotControllerDeleteBotApiArg
    >({
      query: queryArg => ({ url: `/bot/spam/${queryArg.id}`, method: 'DELETE' }),
      invalidatesTags: [EAppTGTagType.SPAM],
    }),
    spamBotControllerCreateBot: build.mutation<
      SpamBotControllerCreateBotApiResponse,
      SpamBotControllerCreateBotApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot`,
        method: 'POST',
        body: queryArg.createBotSpamPayload,
      }),
      invalidatesTags: [EAppTGTagType.SPAM],
    }),
    spamBotControllerGetBotList: build.query<
      SpamBotControllerGetBotListApiResponse,
      SpamBotControllerGetBotListApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/list`,
        method: 'POST',
        body: queryArg.botSpamListParams,
      }),
      providesTags: [EAppTGTagType.SPAM],
    }),
    spamBotControllerToggleBot: build.mutation<
      SpamBotControllerToggleBotApiResponse,
      SpamBotControllerToggleBotApiArg
    >({
      query: queryArg => ({ url: `/bot/spam/${queryArg.id}/toggle`, method: 'PATCH' }),
      invalidatesTags: [EAppTGTagType.SPAM],
    }),
    spamBotControllerSyncBot: build.mutation<
      SpamBotControllerToggleBotApiResponse,
      SpamBotControllerToggleBotApiArg
    >({
      query: queryArg => ({ url: `/bot/spam/${queryArg.id}/sync`, method: 'PATCH' }),
      invalidatesTags: [EAppTGTagType.SPAM],
    }),
    spamBotControllerRefreshBot: build.mutation<
      SpamBotControllerRefreshBotApiResponse,
      SpamBotControllerRefreshBotApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/${queryArg.id}/update_token`,
        method: 'PATCH',
        body: queryArg.updateBotSpamTokenPayload,
      }),
      invalidatesTags: [EAppTGTagType.SPAM],
    }),
    spamBotIdMessagesControllerCreateMessage: build.mutation<
      SpamBotIdMessagesControllerCreateMessageApiResponse,
      SpamBotIdMessagesControllerCreateMessageApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/${queryArg.botId}/messages`,
        method: 'POST',
        body: queryArg.createBotSpamScheduledMessage,
      }),
      invalidatesTags: [EAppTGTagType.SPAM_MESSAGES],
    }),
    spamBotIdMessagesControllerGetMessageList: build.query<
      SpamBotIdMessagesControllerGetMessageListApiResponse,
      SpamBotIdMessagesControllerGetMessageListApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/${queryArg.botId}/messages/list`,
        method: 'POST',
        body: queryArg.botSpamScheduledMessageListParams,
      }),
      providesTags: [EAppTGTagType.SPAM_MESSAGES],
    }),
    spamBotMessagesControllerUpdateMessage: build.mutation<
      SpamBotMessagesControllerUpdateMessageApiResponse,
      SpamBotMessagesControllerUpdateMessageApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/messages/${queryArg.messageId}`,
        method: 'PATCH',
        body: queryArg.updateBotSpamScheduledMessage,
      }),
      invalidatesTags: [EAppTGTagType.SPAM_MESSAGES],
    }),
    spamBotMessagesControllerDeleteMessage: build.mutation<
      SpamBotMessagesControllerDeleteMessageApiResponse,
      SpamBotMessagesControllerDeleteMessageApiArg
    >({
      query: queryArg => ({ url: `/spam-bot/messages/${queryArg.messageId}`, method: 'DELETE' }),
      invalidatesTags: [EAppTGTagType.SPAM_MESSAGES],
    }),
    spamBotIdChatsControllerGetChatList: build.query<
      SpamBotIdChatsControllerGetChatListApiResponse,
      SpamBotIdChatsControllerGetChatListApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/${queryArg.botId}/chats/list`,
        method: 'POST',
        body: queryArg.botSpamChatListParams,
      }),
      providesTags: [EAppTGTagType.SPAM_CHATS],
    }),
    spamBotIdChatsControllerCreateChat: build.mutation<
      SpamBotIdChatsControllerCreateChatApiResponse,
      SpamBotIdChatsControllerCreateChatApiArg
    >({
      query: queryArg => ({
        url: `/spam-bot/${queryArg.botId}/chats/${queryArg.chatId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.SPAM_CHATS],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as tgSpamApiInjected };
export type SpamBotControllerGetBotApiResponse = /** status 200  */ BotSpamTotal;
export type SpamBotControllerGetBotApiArg = {
  id: string;
};
export type SpamBotControllerDeleteBotApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotControllerDeleteBotApiArg = {
  id: string;
};
export type SpamBotControllerCreateBotApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type SpamBotControllerCreateBotApiArg = {
  createBotSpamPayload: CreateBotSpamPayload;
};
export type SpamBotControllerGetBotListApiResponse = /** status 200  */ BotSpamListResponse;
export type SpamBotControllerGetBotListApiArg = {
  botSpamListParams: BotSpamListParams;
};
export type SpamBotControllerToggleBotApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotControllerToggleBotApiArg = {
  id: string;
};
export type SpamBotControllerRefreshBotApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotControllerRefreshBotApiArg = {
  id: string;
  updateBotSpamTokenPayload: UpdateBotSpamTokenPayload;
};
export type SpamBotIdMessagesControllerCreateMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type SpamBotIdMessagesControllerCreateMessageApiArg = {
  botId: string;
  createBotSpamScheduledMessage: CreateBotSpamScheduledMessage;
};
export type SpamBotIdMessagesControllerGetMessageListApiResponse =
  /** status 200  */
  BotSpamScheduledMessageListResponse;
export type SpamBotIdMessagesControllerGetMessageListApiArg = {
  botId: string;
  botSpamScheduledMessageListParams: BotSpamScheduledMessageListParams;
};
export type SpamBotMessagesControllerUpdateMessageApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotMessagesControllerUpdateMessageApiArg = {
  messageId: number;
  updateBotSpamScheduledMessage: UpdateBotSpamScheduledMessage;
};
export type SpamBotMessagesControllerDeleteMessageApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotMessagesControllerDeleteMessageApiArg = {
  messageId: number;
};
export type SpamBotIdChatsControllerGetChatListApiResponse =
  /** status 200  */
  BotSpamChatListResponse;
export type SpamBotIdChatsControllerGetChatListApiArg = {
  botId: string;
  botSpamChatListParams: BotSpamChatListParams;
};
export type SpamBotIdChatsControllerCreateChatApiResponse = /** status 200  */ DefaultResponse;
export type SpamBotIdChatsControllerCreateChatApiArg = {
  botId: string;
  chatId: string;
};
export type BotSpamCount = {
  chats: number;
  messages: number;
};
export type BotSpamTotal = {
  _count: BotSpamCount;
  id: string;
  token: string;
  userId: string;
  status: boolean;
  username: string | null;
  firstName: string | null;
  photoUrl: string | null;
};

export type DefaultResponse = {
  status: boolean;
};
export type CreateBotSpamPayload = {
  token: string;
};
export type BotSpamListResponse = {
  list: BotSpamTotal[];
  total: number;
};
export type BotSpamListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export type UpdateBotSpamTokenPayload = {
  token: string;
};
export type FileType = {
  mime: string;
  url: string;
};
export type CreateBotSpamScheduledMessage = {
  text?: string;
  persist: boolean;
  dates: string[];
  files: FileType[];
};
export type BotSpamScheduledMessageDate = {
  id: number;
  date: string;
  scheduledMessageId: number;
};
export type BotSpamScheduledMessageFile = {
  id: number;
  mime: string;
  url: string;
  scheduledMessageId: number;
};
export type BotSpamScheduledMessageFull = {
  dates: BotSpamScheduledMessageDate[];
  files: BotSpamScheduledMessageFile[];
  id: number;
  text: string | null;
  persist: boolean;
  botSpamId: number;
};
export type BotSpamScheduledMessageListResponse = {
  list: BotSpamScheduledMessageFull[];
  total: number;
};
export type BotSpamScheduledMessageListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export type UpdateBotSpamScheduledMessage = {
  text?: string;
  persist?: boolean;
  dates?: string[];
  files?: FileType[];
};
export type BotSpamChat = {
  id: number;
  chatId: string;
  chatName: string | null;
  isReachable: boolean;
  botSpamId: number;
};
export type BotSpamChatListResponse = {
  list: BotSpamChat[];
  total: number;
};
export type BotSpamChatListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};

export type TSpamBot = {
  id: string;
  lastTokenSymbol: string;
  name: string;
  status: boolean;
  telegram: { name: string; displayName: string; photoUrl: string };
  timezone: string;
  userId: string;
  canNotStart?: null | {
    message: string;
  };
};
export const {
  useGetAllSpamBotsQuery,
  useToggleSpamBotMutation,
  useDeleteSpamBotMutation,
  useCreateSpamBotMutation,
  useDeleteSpamBotChatMutation,
  useGetAllSpamChatsBotsQuery,
  useSpamBotControllerGetBotQuery,
  useSpamBotControllerDeleteBotMutation,
  useCreateScheduledMessageSpamBotMutation,
  useUpdateSpamScheduledMessageMutation,
  useGetAllScheduledMessageSpamBotQuery,
  useGetScheduledSpamMessageByIdQuery,
  useDeleteSpamScheduledMessageMutation,
  useDeleteAllSpamChatsBotsMutation,
  useSpamBotControllerCreateBotMutation,
  useSpamBotControllerGetBotListQuery,
  useGetSpamSentMessagesQuery,
  useSpamBotControllerSyncBotMutation,
  useSpamBotControllerToggleBotMutation,
  useSpamBotControllerRefreshBotMutation,
  useSpamBotIdMessagesControllerCreateMessageMutation,
  useSpamBotIdMessagesControllerGetMessageListQuery,
  useSpamBotMessagesControllerUpdateMessageMutation,
  useSpamBotMessagesControllerDeleteMessageMutation,
  useSpamBotIdChatsControllerGetChatListQuery,
  useSpamBotIdChatsControllerCreateChatMutation,
} = injectedRtkApi;
