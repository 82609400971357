import { FC } from 'react';
import styles from './FolderGroups.module.scss';
import FolderGroupsCard from './FolderGroupsCard';
import FolderAutoSide from '../FolderAutoSide';
import useWindowSize from 'hooks/useWindowSize';
import { useGetCurrentFolderData } from '../../vk.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { setFolderGroupCheck } from '../../vk.reducer';
import { useUpdateFolderFromGroupsByIdMutation } from '../../vk.api';
import { useParams } from 'react-router-dom';

const FolderGroups: FC = () => {
  const { width } = useWindowSize();
  const { groups, folderName } = useGetCurrentFolderData();
  const { id } = useParams();
  const [deleteFolder] = useUpdateFolderFromGroupsByIdMutation();
  const dispatch = useAppDispatch();

  return (
    <div className={styles.Root}>
      <div className={styles.LeftGroups}>
        {groups?.map(group => (
          <FolderGroupsCard
            key={group.id}
            onChoose={() => {
              dispatch(setFolderGroupCheck(group.id));
            }}
            onDelete={() => {
              deleteFolder({
                groupId: id + '',
                folderName,
                removedIds: [group.id],
                addedIds: [],
              });
            }}
            {...group}
          />
        ))}
      </div>
      {width > 1024 && (
        <div className={styles.RightGroups}>
          <FolderAutoSide />
        </div>
      )}
    </div>
  );
};

export default FolderGroups;
