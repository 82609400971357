import { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './NavigationEntry.module.scss';
import Icon from 'components/Icon';

type Props = {
  iconName: string;
  name: string;
  to: string;
};
const NavigationEntry: FC<Props> = ({ iconName, name, to }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames(styles.Wrapper, {
          [styles.Active]: isActive,
        })
      }
      to={to}
    >
      <div className={styles.Background}>
        <Icon iconName={iconName} size={24} />
      </div>
      <p className={styles.Text}>{name}</p>
      <div className={styles.Banner}>
        <p>{name}</p>
      </div>
    </NavLink>
  );
};

export default NavigationEntry;
