import { FC } from 'react';

import styles from './TextPropertiesEditor.module.scss';
import { EAlignment, TextProps } from 'modules/my-pages/my-pages.types';
import Tool from '../Tool';
import IconButton from 'components/IconButton';
import WithColorPicker from 'components/WithColorPicker';

type Props = {
  properties?: TextProps;
  setProperty: (key: keyof TextProps, value: boolean | string | EAlignment) => void;
  type?: string;
};

const TextPropertiesEditor: FC<Props> = ({ properties, setProperty, type }) => {
  return (
    <Tool childrenClassName={styles.Wrapper}>
      <div className={styles.Options}>
        <IconButton
          iconName="bold"
          onClick={() => setProperty('bold', !properties?.bold)}
          iconSize={24}
        />
        <IconButton
          iconName="italic"
          onClick={() => setProperty('italic', !properties?.italic)}
          iconSize={24}
        />
        <IconButton
          iconName="underline"
          onClick={() => setProperty('underline', !properties?.underline)}
          iconSize={24}
        />
        {type === 'text' && (
          <WithColorPicker
            panelClassName={styles.ColorPicker}
            color={properties?.color}
            setColor={color => setProperty('color', color)}
            inline
          >
            <IconButton iconName="text-color" iconSize={24} />
          </WithColorPicker>
        )}
      </div>
      <div className={styles.Options}>
        <IconButton
          iconName="align-left"
          onClick={() => setProperty('alignment', EAlignment.LEFT)}
          iconSize={24}
        />
        <IconButton
          iconName="align-center"
          onClick={() => setProperty('alignment', EAlignment.CENTER)}
          iconSize={24}
        />
        <IconButton
          iconName="align-right"
          onClick={() => setProperty('alignment', EAlignment.RIGHT)}
          iconSize={24}
        />
      </div>
    </Tool>
  );
};

export default TextPropertiesEditor;
