import { useDispatch } from 'react-redux';
import vkApi from '../../config/http.vk';
import { EAppVKTagType } from '../../interfaces/api';

export function useVkInvalidateTag() {
  const dispatch = useDispatch();

  const invalidate = () => {
    Object.values(EAppVKTagType).forEach(tag => {
      dispatch(vkApi.util?.invalidateTags([tag]));
    });
  };

  return invalidate;
}
