import { FC } from 'react';

import styles from './Footer.module.scss';
import logo from 'assets/svg/logo.svg';
import IconButton from 'components/IconButton';

const Footer: FC = () => {
  return (
    <div className={styles.Wrapper}>
      <img className={styles.Logo} src={logo} width={84} height={24} alt="Logo" />
      <IconButton className={styles.HelpCircle} iconName="navigation-help-circle" iconSize={18} />
    </div>
  );
};

export default Footer;
