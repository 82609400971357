import { FC } from 'react';

import styles from './Divider.module.scss';

type Props = {
  mt?: number;
  mb?: number;
};

const Divider: FC<Props> = ({ mt = 16, mb = 16 }) => {
  return <hr className={styles.Divider} style={{ marginTop: mt, marginBottom: mb }} />;
};

export default Divider;
