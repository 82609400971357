import { FC, ReactNode } from 'react';

import styles from './Error.module.scss';

type Props = {
  children: ReactNode;
};

const Error: FC<Props> = ({ children }) => {
  return <div className={styles.Error}>{children}</div>;
};

export default Error;
