import { FC, useState, useEffect } from 'react';

import styles from './Applications.module.scss';
import FormRequestsTable from 'modules/landing-dashboard/components/FormRequestsTable';
import { useReadApplicationMutation } from 'modules/landing-dashboard/landing-dashboard.api';
import {
  useApplicationsSelectIds,
  useSelectFormComponentId,
  useSelectStatisticsId,
} from 'modules/landing-dashboard/landing-dashboard.selectors';
import { setApplicationsIds } from 'modules/landing-dashboard/landing-dashboard.reducer';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  setFormComponentId,
  setStatisticsId,
} from 'modules/landing-dashboard/landing-dashboard.reducer';
import { MobileFilter } from 'modules/landing-dashboard/components/MobileFilter/MobileFilter';
import { StatusSelector } from 'modules/landing-dashboard/components/StatusSelector/StatusSelector';
import LandingSelector from 'modules/landing-dashboard/components/LandingSelector';
import FormComponentSelector from 'modules/landing-dashboard/components/FormComponentSelector';

const Applications: FC = () => {
  const [filterParam, setFilterParam] = useState('Все');
  const selectApplicationIds = useApplicationsSelectIds();
  const [readApplication] = useReadApplicationMutation();
  const dispatch = useAppDispatch();
  const selectedId = useSelectStatisticsId();
  const formComponentId = useSelectFormComponentId();

  const markReadApplications = () => {
    readApplication({ ids: selectApplicationIds });
    dispatch(setApplicationsIds([]));
  };

  const handleSelectStatus = (value: string) => {
    setFilterParam(value);
  };

  const handleLandingSelect = (id: number) => {
    dispatch(setFormComponentId(null));
    dispatch(setStatisticsId(id !== selectedId ? id : null));
  };

  const handleSelectForm = (id: number) => {
    dispatch(setFormComponentId(id !== formComponentId ? id : undefined));
  };

  const handleCloseFilter = () => {
    dispatch(setFormComponentId(undefined));
    dispatch(setStatisticsId(null));
    setFilterParam('Все');
  };

  useEffect(() => {
    dispatch(setStatisticsId(null));
    dispatch(setFormComponentId(null));

    return () => {
      dispatch(setStatisticsId(null));
    };
  }, []);

  return (
    <div className={styles.Applications}>
      <div className={styles.Header}>
        <h1 className={styles.Title}>Заявки</h1>
        {/*<VideoTutorial link={videoTutorials.workWithStatisticsAndRequestsInBUSINESSCARD} />*/}
        <div className={styles.desktopFilters}>
          <LandingSelector label={'Страница'} landingSelectorClick={handleLandingSelect} />
          <FormComponentSelector label={'Элемент'} selectFormClick={handleSelectForm} />
          {selectApplicationIds.length ? (
            <span className={styles.readButton} onClick={markReadApplications}>
              Отметить как прочитанные
            </span>
          ) : (
            <div className={styles.dateSelectorWrapper}>
              <span>Показывать:</span>
              <StatusSelector selected={filterParam} setSelected={handleSelectStatus} />
            </div>
          )}
        </div>

        <div className={styles.mobileFilterWrapper}>
          <MobileFilter
            statusSelect={handleSelectStatus}
            formSelect={handleSelectForm}
            landingSelect={handleLandingSelect}
            statusParams={filterParam}
            closeFilter={handleCloseFilter}
            type={'applications'}
          />
        </div>
      </div>
      <FormRequestsTable selected={filterParam} />
    </div>
  );
};

export default Applications;
