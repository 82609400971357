import { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import r from 'constants/routes';
import styles from './Layout.module.scss';
import Navigation from 'components/Navigation';
import useTypedSelector from 'hooks/useAppSelector';
import { selectIsAuthenticated } from 'modules/auth/auth.selectors';
import { useGetUserQuery } from 'modules/account/account.api';
import HeaderMobile from 'components/HeaderMobile';
import NavigationMobile from 'components/NavigationMobile';
import { GoBackModal } from 'modules/my-pages/components/GoBackModal/GoBackModal';
import { useOpenGoBackModal } from 'modules/my-pages/my-pages.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { resetPageValues, setOpenGoBackModal } from 'modules/my-pages/my-pages.reducer';
import useSavePage from 'modules/my-pages/hooks/useSavePage';
import toast from 'toasts';

const Layout: FC = () => {
  const openModal = useOpenGoBackModal();
  const isAuthenticated = useTypedSelector(selectIsAuthenticated);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const onSave = useSavePage();

  const handleCloseModal = () => {
    dispatch(setOpenGoBackModal(false));
    dispatch(resetPageValues());
  };

  const onSaveClick = () => {
    dispatch(setOpenGoBackModal(false));
    dispatch(resetPageValues());
    toast.success('Изменения сохранены');
    return onSave();
  };

  const { refetch } = useGetUserQuery(null, { skip: !isAuthenticated });

  useEffect(() => {
    if (isAuthenticated) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    // Redirect them to the /signin page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={r.auth.signIn} state={{ from: location }} />;
  }

  if (location.pathname === r.root) {
    // Redirect user to account page in case he's in the root
    // as we have no root page
    return <Navigate to={r.account} />;
  }

  return (
    <div className={styles.Layout}>
      <div className={styles.Navigation}>
        <Navigation />
      </div>

      <div className={styles.Header}>
        <HeaderMobile />
      </div>

      <div className={styles.MobileNav}>
        <NavigationMobile />
      </div>

      <main className={styles.Main} id="layout-main">
        <Outlet />
      </main>
      <GoBackModal openModal={openModal} closeModal={handleCloseModal} onClick={onSaveClick} />
    </div>
  );
};

export default Layout;
