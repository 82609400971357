import { FC } from 'react';

import styles from './TinyStatCard.module.scss';

type Props = {
  name: string;
  value: number;
  legend: string;
};

const TinyStatCard: FC<Props> = ({ name, value, legend }) => {
  return (
    <div className={styles.Card}>
      <div className={styles.Value}>{value}</div>
      <div className={styles.Name}>{name}</div>
      <div className={styles.Legend}>{legend}</div>
    </div>
  );
};

export default TinyStatCard;
