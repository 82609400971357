import { FC } from 'react';

import { Button as TButton, NonNullableActiveComponent } from 'modules/my-pages/my-pages.types';
import ToolsWrapper from '../../tools/ToolsWrapper';
import BlockStyleSelector from '../../tools/BlockStyleSelector';
import ColorSelector from '../../tools/ColorSelector';
import AlignmentSelector from '../../tools/AlignmentSelector';
import InputOutline from 'components/InputOutline';
import ClickAction from '../../tools/ClickAction';
import AnchorInput from '../../tools/AnchorInput';
import useSetComponentProperty from 'modules/my-pages/hooks/useSetComponentProperty';
import TextPropertiesEditor from '../../tools/TextPropertiesEditor';

type Props = {
  component: NonNullableActiveComponent<TButton>;
};

const Button: FC<Props> = ({ component }) => {
  const { hash, data } = component.component;
  const { setProperty, setText } = useSetComponentProperty(hash);
  const anchorActive = !!data.isAnchorActive;

  return (
    <ToolsWrapper>
      <BlockStyleSelector
        style={data.blockStyle}
        setStyle={value => setProperty('blockStyle', value)}
      />
      <ColorSelector
        label="Фон:"
        color={data.bgColor}
        setColor={value => setProperty('bgColor', value)}
      />
      <ColorSelector
        label="Контур::"
        color={data.borderColor}
        setColor={value => setProperty('borderColor', value)}
      />
      <TextPropertiesEditor
        properties={data.properties}
        setProperty={(key, value) => setProperty(`properties.${key}`, value)}
      />
      <AlignmentSelector
        alignment={data.alignment}
        setAlignment={value => setProperty('alignment', value)}
      />
      <InputOutline
        label="Текст:"
        value={data.text}
        onChange={e => setText('text', e.target.value)}
      />
      <ClickAction
        click={data.onClick}
        setAction={value => setProperty('onClick.action', value)}
        setLink={value => setText('onClick.link', value)}
      />
      <AnchorInput
        anchor={data.anchor}
        setAnchor={value => setText('anchor', value)}
        setAnchorActive={() => setProperty('isAnchorActive', !anchorActive)}
        active={anchorActive}
      />
    </ToolsWrapper>
  );
};

export default Button;
