import {
  LandingForm,
  LandingFormForm,
  LandingText,
  LandingTextForm,
} from 'modules/landing-generator/landing-generator.types';
import isEmpty from 'lodash-es/isEmpty';
import compact from 'lodash-es/compact';

import {
  LandingButton,
  LandingButtonForm,
  LandingButtonSocialNetwork,
  LandingButtonWithoutId,
  LandingVideo,
  LandingVideoForm,
} from './landing-generator.types';
import { getSocialNetworkPropertiesForConversion } from './landing-generator.getters';
import { createTextProps } from './landing-generator.creators';

export const convertLandingFormToLandingButton = (
  form: LandingButtonForm,
  saved?: LandingButton,
): LandingButtonWithoutId => {
  const converted: LandingButtonWithoutId = {
    orientation: form.orientation,
    websites: [],
  };

  const socialNets: LandingButtonSocialNetwork[] = [
    'vk',
    'viber',
    'whatsapp',
    'instagram',
    'facebook',
    'telegram',
  ];

  // convert social nets
  for (const socialNet of socialNets) {
    const netContent = form[socialNet];
    if (netContent) {
      converted[socialNet] = getSocialNetworkPropertiesForConversion(
        netContent,
        saved?.[socialNet],
      );
    }
  }

  // convert modal
  if (form.inModal && form.modalText) {
    converted.modal = {
      buttonName: form.modalText,
      buttonColor: saved?.modal?.buttonColor,
    };
  }

  // convert website
  form.websites.map((website, index) => {
    const savedWebsite = saved?.websites[index];
    const filled = compact([website.title, website.text, website.url]);
    if (!isEmpty(filled)) {
      converted.websites.push({
        title: createTextProps({ isBold: true, ...savedWebsite?.title, content: website.title }),
        text: createTextProps({ ...savedWebsite?.text, content: website.text }),
        url: website.url,
      });
    }
  });

  return converted;
};

export const convertLandingVideoFormToLandingVideo = (
  form: LandingVideoForm,
  saved?: LandingVideo | null,
): LandingVideo => {
  return {
    link: form.link,
    title: createTextProps({ isBold: true, ...saved?.title, content: form.title }),
    text: createTextProps({ ...saved?.text, content: form.text }),
  };
};

export const convertLandingTextFormToLandingText = (
  form: LandingTextForm,
  text?: LandingText | null,
): LandingText => {
  return {
    title: form.title.map((title, index) =>
      createTextProps({ isBold: true, ...text?.title[index], ...title }),
    ),
    subtitle: form.subtitle.map((subtitle, index) =>
      createTextProps({ isBold: true, ...text?.subtitle[index], ...subtitle }),
    ),
    text: form.text.map((textEl, index) => createTextProps({ ...text?.text[index], ...textEl })),
  };
};

export const convertLandingFormFormToLandingForm = (
  form: LandingFormForm,
  saved?: LandingForm | null,
): LandingForm => {
  const converted: LandingForm = {
    title: createTextProps({ isBold: true, ...saved?.title, content: form.title }),
    text: createTextProps({ ...saved?.text, content: form.text }),
    fields: form.fields,
    buttonName: form.buttonName ?? saved?.buttonName,
  };

  // convert modal
  if (form.inModal && form.modalText) {
    converted.modal = {
      buttonName: form.modalText,
      buttonColor: saved?.modal?.buttonColor,
    };
  }

  return converted;
};
