import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CommonModalProps } from 'components/Modal';
import { addGreetingBotAccountCode } from 'modules/telegram/telegram.schema';
import {
  AddGreetingBotAccountValidate,
  WelcomeBotError,
  TAutoErrorBotResponse,
  WelcomeBot,
  GreetinBotModalState,
} from 'modules/telegram/telegram.types';
import ModalWithForm from 'components/ModalWithForm';
import useAppDispatch from 'hooks/useAppDispatch';
import { closeModal, selectPlan } from 'modules/telegram/telegram.reducer';
import styles from './AddGreetingBotAccountCode.module.scss';
import Button from 'components/Button';
import Input from 'components/Input';
import {
  useWelcomeBotAuthMutation,
  useWelcomeBotUserValidateMutation,
} from '../../codegen/telegram.api';
import { EAppTagType } from 'interfaces/api';
import accountApi from '../../../account/account.api';
import PasswordInput from 'components/PasswordInput';
import { delay, isEmpty } from 'lodash-es';
import { AvoidEnums } from 'constants/avoidEnums';
import { useWelcomeBotFloodError } from '../../telegram.selectors';

interface Props extends CommonModalProps {
  welcomeBot?: WelcomeBot;
  hasError: boolean;
  handleError: (arg: boolean) => void;
  isBotLoading: boolean;
}
const AddGreetingBotAccountCode: FC<Props> = ({ welcomeBot, hasError, handleError, ...props }) => {
  const dispatch = useAppDispatch();
  const isFlood = useWelcomeBotFloodError();
  const [authenticate, { isLoading: isAuthenticateLoading }] = useWelcomeBotAuthMutation();
  const [auth, { isLoading: isAuthLoading }] = useWelcomeBotUserValidateMutation();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    watch,
    setError,
    setValue,
  } = useForm<AddGreetingBotAccountValidate>({
    mode: 'onChange',
    resolver: yupResolver(addGreetingBotAccountCode()),
  });

  const onSubmit = handleSubmit(async form => {
    if (welcomeBot?.phone) {
      const res = await authenticate({ code: String(form.code), cloudCode: form.cloudCode });
      if ('error' in res) {
        const error: TAutoErrorBotResponse = res as TAutoErrorBotResponse;

        if (error.error.data.type === WelcomeBotError.NEED_2FA_CODE) {
          setValue('code', '');
          handleError(true);
        } else {
          setError('code', {
            type: 'custom',
            message: error.error.data.description,
          });
        }

        auth({});
      } else {
        dispatch(accountApi.util.invalidateTags([EAppTagType.INTEGRATION_CONTROLLER]));
        dispatch(closeModal());
        handleError(false);
      }
    }
  });

  useEffect(() => {
    if (hasError) {
      delay(() => {
        setError('cloudCode', {
          type: 'custom',
          message: AvoidEnums.MESSAGE_AVOID_BOT_TWO,
        });
      }, 1500);
    }
  }, [hasError]);

  const text = isFlood
    ? 'Вы сможете повторить действие через 2 минуты.'
    : `
        Мы отправили код через Telegram на другое устройство, где авторизован  ${welcomeBot?.phone}
      `;
  const values = watch();

  const isValidForm = hasError ? isValid && !isEmpty(values.cloudCode) : isValid;
  return (
    <ModalWithForm
      title="ВВЕДИТЕ КОД"
      text={text}
      onSubmit={onSubmit}
      isValid={isValidForm}
      noBtn
      formClassName={styles.Root}
      isLoading={props.isBotLoading || isAuthLoading || isAuthenticateLoading}
      {...props}
    >
      {!isFlood && (
        <>
          <Input
            label="Введите код"
            register={register('code')}
            invalid={!!errors.code?.message}
            errorMsg={errors.code?.message}
            type={'number'}
          />
          {hasError && (
            <PasswordInput
              label="Введите облачный пароль"
              register={register('cloudCode')}
              invalid={!!errors.cloudCode?.message}
              errorMsg={errors.cloudCode?.message}
              type={'password'}
            />
          )}
        </>
      )}

      <div className={styles.Buttons}>
        <Button
          disabled={isFlood || isAuthLoading || isAuthenticateLoading || !isValidForm}
          type={'submit'}
        >
          Продолжить
        </Button>
        <Button
          onClick={(event: { preventDefault: () => void }) => {
            event.preventDefault();
            // deleteNumber({});
            dispatch(selectPlan(GreetinBotModalState.CHECKING));
          }}
          variant={'text'}
        >
          Hазад
        </Button>
      </div>
    </ModalWithForm>
  );
};

export default AddGreetingBotAccountCode;
