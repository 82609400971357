import { FC } from 'react';

import Photo from './Photo';
import Video from './Video';
import Link from './Link';
import Form from './Form';
import DependentText from './DependentText';

import { LandingElement } from 'modules/landing-generator/landing-generator.types';

type Props = { id: number; elem: LandingElement };
const RenderElement: FC<Props> = ({ id, elem }) => {
  switch (elem.type) {
    case 'title':
    case 'subtitle':
    case 'text':
      return <DependentText type={elem.type} {...elem.value} />;
    case 'photo':
      return <Photo photo={elem.value} />;
    case 'video':
      return <Video video={elem.value} />;
    case 'form':
      return <Form id={id} form={elem.value} />;
    case 'button':
      return <Link button={elem.value} />;
    default:
      return null;
  }
};
export default RenderElement;
