import { FC } from 'react';

import styles from './ModalButton.module.scss';
import Button, { ButtonProps } from 'components/Button';
import EditableElement from '../EditableElement';
import useDisclosure from 'hooks/useDisclosure';
// import ColorPickerInContextMenu from 'components/ColorPickerInContextMenu';
import { LandingModal } from 'modules/landing-generator/landing-generator.types';
// import { rgba2string, stringToRgba } from 'utils/colorConverters';

type Props = {
  button: LandingModal;
  onSetColor: (color: string) => void;
  onRemoveBtn: () => void;
} & Omit<ButtonProps<'button'>, 'children'>;

const ModalButton: FC<Props> = ({ button, onRemoveBtn, onSetColor, ...btnProps }) => {
  const { toggleOpen: toggleColorOpen, onClose: onColorClose } = useDisclosure();

  return (
    <EditableElement
      menuElements={[
        { iconName: 'pick-color', onClick: toggleColorOpen },
        { iconName: 'delete', onClick: onRemoveBtn },
      ]}
      onClose={onColorClose}
      // menuAddon={
      // <ColorPickerInContextMenu
      //   open={colorOpen}
      //   color={stringToRgba(button.buttonColor)}
      //   setColor={color => onSetColor(rgba2string(color))}
      // />
      // }
      openOnHover
    >
      <div className={styles.Wrapper}>
        <Button className={styles.Button} {...btnProps} color={button.buttonColor}>
          {button.buttonName}
        </Button>
      </div>
    </EditableElement>
  );
};

export default ModalButton;
