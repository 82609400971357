import { FC } from 'react';

import styles from './Phone.module.scss';
import { Component, Phone as TPhone } from 'modules/my-pages/my-pages.types';

type Props = {
  component: Component<TPhone>;
};

const Phone: FC<Props> = ({ component }) => {
  const { data } = component;

  return (
    <a href={`tel:${data.phone}`} className={styles.Section}>
      {data.phone}
    </a>
  );
};

export default Phone;
