import { FC } from 'react';

import styles from './Editor.module.scss';

import Header from 'modules/my-pages/components/Header';
import Elements from 'modules/my-pages/components/Elements';
import Phone from 'modules/my-pages/components/Phone';
import Preview from 'modules/my-pages/components/Preview';
import Settings from 'modules/my-pages/components/Settings';
import NavMobile from 'modules/my-pages/components/NavMobile';
import { Page } from '../../my-pages.types';

interface Props {
  page: Page | null;
}

const Editor: FC<Props> = ({ page }) => {
  return (
    <div className={styles.Page}>
      <Header />

      <div className={styles.Editor}>
        <Elements className={styles.Elements} />

        <Phone className={styles.Phone} wrapperClassName={styles.PhoneWrapper}>
          <Preview className={styles.Preview} page={page} />
        </Phone>

        <Settings className={styles.Settings} type={'settings'} />
      </div>

      <NavMobile />
    </div>
  );
};

export default Editor;
