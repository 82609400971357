import { FC } from 'react';
import classNames from 'classnames';

import styles from './Mobile.module.scss';
import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import Stats from '../Stats';
import DateTime from 'components/DateTime';

const Mobile: FC = () => {
  return (
    <Accordion
      wrapperClassName={styles.Wrapper}
      contentClassName={styles.Stats}
      contentMargin={0}
      trigger={open => (
        <nav className={styles.Trigger}>
          <div className={styles.Content}>
            <img
              className={styles.Avatar}
              src={'https://placebear.com/300/300'}
              alt="Bot's avatar"
              width={40}
              height={40}
            />
            <div className={styles.Info}>
              <div className={styles.Name}>@kitchenBOT</div>
              <DateTime className={styles.Time} date="12/12/2012" size={12} />
            </div>
          </div>
          <Icon
            className={classNames(styles.Icon, { [styles.IconOpen]: open })}
            iconName="arrow-up"
            size={16}
          />
        </nav>
      )}
    >
      <Stats subscribers={14.3} unsubscribed={4} subscribed={7} messages={54.3} commands={54} />
    </Accordion>
  );
};

export default Mobile;
