function getElementWordByAmount(amount: number): 'партнер' | 'партнера' | 'партнеров' {
  const firstOption = 'партнер';
  const secondOption = 'партнера';
  const thirdOption = 'партнеров';

  const lastDigit = amount % 10;

  if (lastDigit === 1 && amount !== 11) {
    return firstOption;
  } else if ([2, 3, 4].includes(amount)) {
    return secondOption;
  } else {
    return thirdOption;
  }
}

export default getElementWordByAmount;
