import { FC } from 'react';

import styles from './CancelSelection.module.scss';
import useAppDispatch from 'hooks/useAppDispatch';
import { resetSelectedThematicGroups } from 'modules/vk/vk.reducer';

const CancelSelection: FC = () => {
  const dispatch = useAppDispatch();

  const onCancel = () => {
    dispatch(resetSelectedThematicGroups());
  };

  return (
    <button className={styles.Btn} onClick={onCancel}>
      Отменить
    </button>
  );
};

export default CancelSelection;
