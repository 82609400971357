import { FC } from 'react';
import range from 'lodash-es/range';

import styles from './BotMessageCard.module.scss';

import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { getImage } from 'utils/getImage';

type Props = {
  onClick?: () => void;
};
const BotMessageCardEdit: FC<Props> = ({ onClick }) => {
  return (
    <div className={styles.Card}>
      <section className={styles.Header}>
        <span>Пн, Вт, Ср, Пт</span>
        <span>08:00, 18:30, 21:00, 23:15</span>
      </section>
      <div className={styles.BodyWrapper}>
        <p className={styles.Body}>
          5 key components for aspiring designers that shape stylish design. Practice in Figma and
          create our first layout. That's all we're going to do in this lesson, buddy! Practice in
          Figma and create our first layout components for aspiring designers
        </p>
        <IconButton className={styles.Icon} iconName="edit" iconSize={18} />
      </div>
      <div className={styles.Bottom}>
        <IconButton iconName="clippy" iconSize={18} />
        <div className={styles.Images}>
          {range(3).map(n => (
            <img src={getImage()} alt={`${n}`} key={n} className={styles.Image} />
          ))}
        </div>
        <Button variant="tetriary" onClick={onClick} className={styles.Button}>
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default BotMessageCardEdit;
