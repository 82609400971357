import env from 'config/env';

export const getImage = (src?: string | null) =>
  src ? `${env.APP_IMAGES}/${src}` : 'https://placebear.com/300/300';

export const getAvatar = (src?: string | null) =>
  src ? `${env.APP_IMAGES}/avatars/${src}` : undefined;

export const getSheetImage = (src?: string | null) =>
  src ? `${env.APP_IMAGES}/sheet-images/${src}` : 'https://placebear.com/300/300';

// TODO: need correct url from backend
export const getSheetPrerenderImage = (src: string | null = 'https://placebear.com/300/300') => {
  if (src?.startsWith('http')) return src;

  return `${env.APP_IMAGES}/prerender/${src}`;
};
