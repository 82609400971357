import { FC, useMemo, useEffect } from 'react';

import styles from './Editor.module.scss';
import useTabs from 'hooks/useTabs';
import PhotoEditor from './components/PhotoEditor';
import VideoEditor from './components/VideoEditor';
import TextEditor from './components/TextEditor';
import LinksEditor from './components/LinksEditor';
import Tabs from './components/Tabs';
import { LandingIdType } from 'modules/landing-generator/landing-generator.types';
import { useGetLandingsByIDQuery } from 'modules/landing-generator/landings-generator.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { setLandings } from 'modules/landing-generator/landing-generator.reducer';

type Props = {
  id: LandingIdType;
};

const Editor: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { data, isError, isLoading } = useGetLandingsByIDQuery({ id });

  useEffect(() => {
    dispatch(setLandings(data?.body ?? []));
  }, [data, isError]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = useMemo(
    () => ({
      photo: { element: <PhotoEditor id={id} />, displayName: 'Фото' },
      video: { element: <VideoEditor id={id} />, displayName: 'Видео' },
      text: { element: <TextEditor id={id} />, displayName: 'Текст' },
      links: { element: <LinksEditor id={id} />, displayName: 'Кнопки' },
    }),
    [id],
  );

  const { activeTab, switchTab } = useTabs(tabs);

  if (isLoading) return null;
  return (
    <section className={styles.Section} id="landing-generator-editor">
      <Tabs
        tabs={['photo', 'video', 'text', 'links']}
        activeTab={activeTab.name}
        makeActive={switchTab}
        getTabDisplayName={tabName => tabs[tabName].displayName}
      />
      <div className={styles.ContentWrapper}>
        <div className={styles.Content}>{activeTab.content.element}</div>
      </div>
    </section>
  );
};

export default Editor;
