import { SchemaOf, object, string, boolean, mixed, date, array, number } from 'yup';
import i18n from 'i18n';

import { ELastActivity, ESex } from 'interfaces/common';
import {
  EditPostsRequestBody,
  EditStoriesRequestBody,
  VkMailingRequestBody,
  DbParsingRequestBody,
  ThematicGroupsRequestBody,
  AddGroupRequestBody,
  SelectableField,
  First3PhotoCommentsRequestBody,
  TFormAddFolder,
  TFirst3EmptyFields,
  TNewParsingForm,
  EditTranslationRequestBody,
} from './vk.types';

const geolocationSchema = (): SchemaOf<string[]> =>
  array()
    .min(0)
    .of(string().required().label(i18n.t('labels.geolocation')));

const groupsLinksSchema = (min: number): SchemaOf<string[]> =>
  array()
    .min(min)
    .of(string().url().required().label(i18n.t('labels.groupLink')));

const stacksSchema = (min: number): SchemaOf<string[]> => array().min(min).of(string().required());

const photosSchema = (): SchemaOf<string[]> => array().of(string().required()).min(0).required();

export const editPostsSchema = (): SchemaOf<EditPostsRequestBody> =>
  object().shape({
    photo: mixed().notRequired(),
    text: string().required().label(i18n.t('labels.text')),
    date: date().required().label(i18n.t('labels.date')),
  });

export const editStoriesSchema = (): SchemaOf<EditStoriesRequestBody> =>
  object().shape({
    photo: mixed().required(),
    date: date().required().label(i18n.t('labels.date')),
  });

export const vkMailingFormSchema = (): SchemaOf<VkMailingRequestBody> =>
  object().shape({
    name: string().required(),
    account: string().required(),
    text: string().required(),
    photos: photosSchema(),
    file: mixed().required(),
    messagesCount: number().positive().required(),
  });

export const selectableFieldSchema = <T>(value: SchemaOf<T>): SchemaOf<SelectableField<T>> =>
  object().shape({
    selected: boolean().default(false).required(),
    value,
  });

const selectableStacksSchema = (min: number): SchemaOf<SelectableField<string[]>> =>
  object().shape({
    selected: boolean().required(),
    value: stacksSchema(min).required(),
  });

const selectableGroupsLinksSchema = (min: number): SchemaOf<SelectableField<string[]>> =>
  object().shape({
    selected: boolean().required(),
    value: groupsLinksSchema(min).required(),
  });

export const dbParsingSchema = (): SchemaOf<DbParsingRequestBody> =>
  object().shape({
    isStack: boolean().required(),
    name: selectableFieldSchema(string().required()),
    stacks: selectableStacksSchema(0).when('isStack', {
      is: true,
      then: selectableStacksSchema(1).required(),
      otherwise: selectableStacksSchema(0).notRequired(),
    }),
    groupsLinks: selectableGroupsLinksSchema(0).when('isStack', {
      is: true,
      then: selectableGroupsLinksSchema(0).notRequired(),
      otherwise: selectableGroupsLinksSchema(1).required(),
    }),
    geolocation: object()
      .shape({
        selected: boolean().required(),
        value: geolocationSchema(),
      })
      .required(),
    sex: object().shape({
      selected: boolean().required(),
      value: mixed<ESex>().oneOf(Object.values(ESex)).notRequired().label(i18n.t('labels.sex')),
    }),
    age: object().shape({
      selected: boolean().required(),
      value: string().notRequired().label(i18n.t('labels.age')),
    }),
    friendsCount: object().shape({
      selected: boolean().required(),
      value: string().notRequired().label(i18n.t('labels.friendsCount')),
    }),
    lastActivity: object().shape({
      selected: boolean().required(),
      value: mixed<ELastActivity>()
        .oneOf(Object.values(ELastActivity))
        .notRequired()
        .label(i18n.t('labels.lastActivity')),
    }),
    maxAccountCount: object().shape({
      selected: boolean().required(),
      value: number()
        .positive()
        .transform(v => (!v ? undefined : v))
        .notRequired(),
    }),
  });

export const thematicGroupsSchema = (): SchemaOf<ThematicGroupsRequestBody> =>
  object()
    .shape({
      link: string().url().required().label(i18n.t('labels.groupLink')),
    })
    .required();

export const addGroupSchema = (): SchemaOf<AddGroupRequestBody> =>
  object()
    .shape({
      name: string().required().label(i18n.t('labels.nameGroup')),
    })
    .required();

export const addTokenModalSchema = (): SchemaOf<{
  timezone: string;
}> =>
  object()
    .shape({
      timezone: string().required().label(i18n.t('labels.timezone')),
    })
    .required();

export const commentSchema = string().max(255).notRequired().label(i18n.t('labels.message'));
export const commentRequiredSchema = string().required().label(i18n.t('labels.message'));

export const first3PhotoCommentsSchema = (): SchemaOf<First3PhotoCommentsRequestBody> =>
  object().shape({
    text1: commentSchema,
    text2: commentSchema,
    text3: commentSchema,
    text4: commentSchema,
    text5: commentSchema,
    text6: commentSchema,
    text7: commentSchema,
    text8: commentSchema,
    text9: commentSchema,
    text10: commentSchema,
    hitLike: boolean().required(),
  });

export const first3FieldCommentsSchema = (): SchemaOf<TFirst3EmptyFields> =>
  object()
    .shape({
      1: commentRequiredSchema,
      2: commentRequiredSchema,
      3: commentRequiredSchema,
      4: commentRequiredSchema,
      5: commentRequiredSchema,
      6: commentRequiredSchema,
      7: commentRequiredSchema,
      8: commentRequiredSchema,
      9: commentRequiredSchema,
      10: commentRequiredSchema,
    })
    .required();

export const createFolderScheme = (): SchemaOf<TFormAddFolder> =>
  object().shape({
    folderName: string().required().label('Название'),
  });

export const vkNewParsingFolderFormSchema = (): SchemaOf<TNewParsingForm> =>
  object()
    .shape({
      name: string().required().label('Название'),
      cityId: string().required(),
      sex: string(),
      ageFrom: string().required().label('Возраст'),
      ageTo: string().required().label('Возраст'),
      needPhoneNumber: string(),
    })
    .required();

export const vkPostingSchema = (): SchemaOf<{ photo?: string; text: string; date?: string }> => {
  return object().shape({
    photo: string().required(),
    text: string().required(),
    date: string(),
  });
};

export const vkStorySchema = (): SchemaOf<EditStoriesRequestBody> => {
  return object().shape({
    photo: string().required(),
    date: date().required(),
  });
};

export const vkTranslationSchema = (): SchemaOf<EditTranslationRequestBody> => {
  return object().shape({
    id: string(),
    description: string(),
    needPublished: boolean(),
    fileId: string(),
    title: string().required(),
    calendar: object()
      .shape({
        repeatType: string(),
        date: array().of(string()).required(),
        times: array().of(string()).required(),
        needRepeat: boolean(),
        periodType: string(),
        interval: number(),
        maxNumberOfUses: number(),
        expireDate: date(),
        weekDays: string(),
        endingType: string(),
      })
      .required(),
  });
};
