import { FC, useMemo } from 'react';

import styles from './GreetingBot.module.scss';

import AddGreetingBotAccountModal from 'modules/telegram/components/AddGreetingBotAccountModal';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';

import useAppDispatch from 'hooks/useAppDispatch';
import { selectPlan } from 'modules/telegram/telegram.reducer';
import { GreetinBotModalState } from 'modules/telegram/telegram.types';
import thumb from 'assets/svg/vk/userPlusBottom.svg';
import EmptyPage from 'components/EmptyPage';
import { useTelegramGreetingModal } from '../../telegram.selectors';
import isUndefined from 'lodash-es/isUndefined';
import { WelcomeBotList } from '../../components/WelcomeBotList';
import logo from 'assets/svg/auth/newLogo.svg';
import profitBox from 'assets/svg/telegram/profitBox.svg';
import starsAstrix from 'assets/svg/telegram/starsAstrix.svg';
import { useIntegrationControllerQuery } from '../../../account/account.api';
import LoadingState from 'components/LoadingState';
import { useGetWelcomeBotQuery } from '../../codegen/telegram.welcome.api';
import { DeleteBotButton } from '../../components/DeleteBotButton';
import useWindowSize from 'hooks/useWindowSize';
import { videoTutorials } from '../../../../constants/videos';

const GreetingBotsList: FC = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const { data: botRes, isLoading: isBotResLoading } = useGetWelcomeBotQuery({});

  const { data, isLoading } = useIntegrationControllerQuery(
    {},
    {
      skip: !(botRes?.success && botRes?.data.isAuthorize),
      refetchOnMountOrArgChange: true,
    },
  );
  const bots = useMemo(() => {
    if (!isLoading && data?.success) {
      const res = data.body.map(obj => {
        if (obj.service === 'STARS') {
          return {
            ...obj,
            logoThumb: starsAstrix,
          };
        }
        if (obj.service === 'PROFIT_BOX') {
          return {
            ...obj,
            logoThumb: profitBox,
          };
        }

        if (obj.service === 'STARS_ONLINE') {
          return {
            ...obj,
            logoThumb: logo,
          };
        }

        return obj;
      });

      return res.reverse();
    }
  }, [data, isLoading]);

  if (isLoading || isBotResLoading) return <LoadingState />;

  return (
    <>
      <WelcomeBotList
        bots={botRes?.success && botRes?.data.isAuthorize ? bots : []}
        emptyMessage={
          <EmptyPage
            img={<img src={thumb} alt={'img'} />}
            title={'Добавьте новый аккаунт'}
            text={''}
            footer={
              <Button
                variant="primary"
                onClick={() => dispatch(selectPlan(GreetinBotModalState.ACCOUNT))}
              >
                Добавить аккаунт
              </Button>
            }
          />
        }
      />
      {botRes?.success && botRes?.data.isAuthorize && width < 876 && <DeleteBotButton />}
    </>
  );
};

const GreetingBot: FC = () => {
  const { state } = useTelegramGreetingModal();

  return (
    <div className={styles.Page}>
      <PageHeader
        title="Авто-приветствие"
        subtitle={'Telegram'}
        videoTutorials={[{ label: 'Настройка бота приветствие', url: videoTutorials.bot_greeting }]}
      />
      <GreetingBotsList />

      {!isUndefined(state) && <AddGreetingBotAccountModal />}
    </div>
  );
};

export default GreetingBot;
