import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PostModalProps } from '../PostModalPost';
import Modal from 'components/Modal';
import CheckBox from 'components/CheckBox';
import Select from 'react-select';
import { selectStyles } from '../ParsingBtn/ParsingModal/utils';
import TranslationDatePicker from '../../../../components/TranslationDatePicker';
import Input from 'components/Input';
import { createPostResult } from '../../vk.constants';
import { Controller, useForm } from 'react-hook-form';
import { EditTranslationRequestBody } from '../../vk.types';
import styles from './TranslationModalPost.module.scss';
import dayjs from 'dayjs';
import TimeMiniCard from 'components/TimeMiniCard';
import {
  useCreateAutoTranslationsMutation,
  useGetAutoTranslationByIdQuery,
  useGetFilesFromVkQuery,
  useUploadFileIntoVkMutation,
  useUpdateAutoTranslationMutation,
} from '../../vk.api';
import { delay, isNull, unset } from 'lodash-es';
import DisplayField from 'components/DisplayField';
import DateModal from 'components/DateModal';
import cloneDeep from 'lodash-es/cloneDeep';
import showFeedback from 'components/FeedbackModal/showFeedback';
import classNames from 'classnames';
import { createFile, isSomeObjectKeysEmpty } from '../../vk.utils';
import isUndefined from 'lodash-es/isUndefined';
import {
  endingSelect,
  intervalOptions,
  periodDurations,
  selectOptions,
  TranslationEndingTime,
  TranslationPeriodTime,
  TranslationRepeatTime,
  WeekDays,
} from 'constants/date-durations';
import WeekDaysInput from 'components/WeekDaysInput';
import TimeDisplay from 'components/TimeDisplay';
import FileThinInput from 'components/FileThinInput';
import isNil from 'lodash-es/isNil';
import { ModalObject } from '../../../telegram/telegram.types';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

const TranslationModalPost: FC<PostModalProps> = ({ postId, ...modalProps }) => {
  const timesArrRef = useRef<string[]>([]);
  const [, updateState] = useState<string[]>(['']);
  const forceUpdate = useCallback(() => updateState(['']), []);
  const [uploadImage, { isLoading: isUploading }] = useUploadFileIntoVkMutation();
  const [createTranslation] = useCreateAutoTranslationsMutation();
  const [updateTranslation] = useUpdateAutoTranslationMutation();
  const { data: editData, isFetching } = useGetAutoTranslationByIdQuery(
    { streamId: postId + '' },
    { skip: isUndefined(postId) },
  );
  const { data: images } = useGetFilesFromVkQuery(
    { ids: [String(editData?.data.fileId)] },
    { skip: isUndefined(editData?.data.fileId) },
  );

  const [editingFile, setEditingFile] = useState<File>();

  const fromStringToDate = (str: string) => {
    const parts = str?.split('.');
    if (parts) return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  };

  const onClose = () => {
    modalProps.onClose();
  };

  const { control, register, watch, setValue, handleSubmit, reset } =
    useForm<EditTranslationRequestBody>({
      mode: 'onChange',
      defaultValues: {
        fileId: '',
        needPublished: false,
        description: '',
        calendar: {
          repeatType: '',
          date: '',
          needRepeat: false,
          times: timesArrRef.current,
          weekDays: [],
          maxNumberOfUses: 0,
          periodType: null,
          interval: 0,
          expireDate: '',
        },
      },
    });

  const values = watch();

  const onSubmit = handleSubmit(async formData => {
    const cloned = cloneDeep(formData);
    unset(cloned, 'calendar.endingType');

    if (!(cloned.calendar.repeatType in TranslationRepeatTime)) {
      cloned.calendar.repeatType = TranslationRepeatTime.NOT_REPEAT;
    }

    if (
      isNull(formData.calendar.endingType) ||
      formData.calendar.endingType === TranslationEndingTime.AFTER
    ) {
      cloned.calendar.expireDate = null;
    }
    if (isNull(cloned.calendar.periodType)) cloned.calendar.periodType = TranslationPeriodTime.DAY;
    if (cloned.calendar.repeatType !== TranslationRepeatTime.OTHER)
      cloned.calendar.expireDate = null;
    if (formData.calendar.endingType === TranslationEndingTime.NEVER)
      cloned.calendar.expireDate = null;
    if (formData.calendar.endingType === TranslationEndingTime.AFTER)
      cloned.calendar.expireDate = null;
    if (formData.calendar.endingType === TranslationEndingTime.DATE) {
      if (!isNull(formData.calendar.expireDate)) {
        const tempDate = fromStringToDate(cloned.calendar.expireDate as string);
        cloned.calendar.expireDate = tempDate?.getTime() || null;
      }
    }

    if (cloned.calendar.repeatType === TranslationRepeatTime.NOT_REPEAT) {
      cloned.calendar.periodType = null;
    }

    cloned.calendar.needRepeat = !(cloned.calendar.repeatType === TranslationRepeatTime.NOT_REPEAT);

    if (postId) {
      updateTranslation({ streamId: String(postId), ...cloned })
        .then(res => {
          if ('data' in res) {
            showFeedback(createPostResult.successTranslation);
            onClose();
            reset();
          }
        })
        .catch(() => {
          showFeedback(createPostResult.error);
          onClose();
        });
    } else {
      createTranslation(cloned)
        .then(res => {
          if ('data' in res) {
            showFeedback(createPostResult.successTranslation);
            onClose();
            reset();
          }
        })
        .catch(() => {
          showFeedback(createPostResult.error);
          onClose();
        });
    }

    modalProps.onClose();
  });

  const deleteSelectedTime = (time: string) => {
    const cloned = new Set(values.calendar.times);
    cloned.delete(time);
    return Array.from(cloned);
  };

  const currRepeatType = useMemo(() => {
    return selectOptions.find(obj => {
      return obj.value === values.calendar.repeatType;
    })?.value;
  }, [values.calendar.repeatType]);

  const currEndingType = useMemo(() => {
    return endingSelect.find(obj => {
      return obj.value === values.calendar.endingType;
    })?.value;
  }, [values.calendar.endingType]);

  const isWeeklyOption = currRepeatType === TranslationRepeatTime.WEEKLY;
  const isOtherOption = currRepeatType === TranslationRepeatTime.OTHER;
  const isNeverEnding = currEndingType === TranslationEndingTime.NEVER;
  const isDateEnding = currEndingType === TranslationEndingTime.DATE;

  useEffect(() => {
    if (editData) {
      reset({
        title: editData.data.title,
        description: editData.data.description,
        needPublished: editData.data.needPublished,
        fileId: editData.data.fileId,
        calendar: {
          date: editData.data.calendar.date,
          needRepeat: editData.data.calendar.needRepeat,
          repeatType: editData.data.calendar.repeatType,
          periodType: editData.data.calendar.periodType,
          endingType: editData.data.calendar.endingType,
          interval: editData.data.calendar.interval,
          maxNumberOfUses: editData.data.calendar.maxNumberOfUses,
          expireDate: editData.data.calendar.expireDate,
          weekDays: editData.data.calendar.weekDays,
          times: editData.data.calendar.times,
        },
      });
    }
  }, [editData]);

  useEffect(() => {
    const nameArr = images?.data[0].s3Url?.split('/');
    if (nameArr) {
      if (postId) {
        const name = nameArr[nameArr.length - 1];
        createFile(images?.data[0].s3Url, images?.data[0].type, name).then(res => {
          if (res) setEditingFile(res);
        });
      }
    }
  }, [images?.data]);

  const modalObjects = (currentType: keyof typeof TranslationRepeatTime): object => {
    const temp: ModalObject = {
      [TranslationRepeatTime.NOT_REPEAT]: {
        fileId: values.fileId,
        title: values.title,
        repeatType: values.calendar.repeatType,
        date: values.calendar.date,
        times: values.calendar.times,
      },
      [TranslationRepeatTime.DAILY]: {
        fileId: values.fileId,
        title: values.title,
        repeatType: values.calendar.repeatType,
        date: values.calendar.date,
        times: values.calendar.times,
      },
      [TranslationRepeatTime.WEEKLY]: {
        fileId: values.fileId,
        title: values.title,
        repeatType: values.calendar.repeatType,
        weekDays: values.calendar.weekDays,
        times: values.calendar.times,
      },
      [TranslationRepeatTime.WORKDAY]: {
        fileId: values.fileId,
      },
      [TranslationRepeatTime.WEEKEND]: {
        fileId: values.fileId,
      },
      [TranslationRepeatTime.YEARLY]: {
        fileId: values.fileId,
      },
      [TranslationRepeatTime.OTHER]: {
        fileId: values.fileId,
      },
    };
    return temp[currentType];
  };

  const currObject = useMemo(() => {
    if (currRepeatType) return modalObjects(currRepeatType);
    return {};
  }, [currRepeatType, values.fileId]);

  return (
    <Modal
      className={styles.ModalConatiner}
      {...modalProps}
      btnText={'Сохранить'}
      onClose={onClose}
      onSubmit={onSubmit}
      isValid={!isSomeObjectKeysEmpty<typeof currObject>(currObject)}
      isLoading={isFetching}
    >
      <h3
        className={classNames(styles.Title, {
          [styles.IsEdit]: Boolean(postId),
        })}
      >
        {postId ? 'Редактирование Трансляции' : 'Настройки Трансляции'}
      </h3>
      <div className={styles.FormFields}>
        <div className={styles.CheckPublishTranslation}>
          <CheckBox
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValue('needPublished', e.target.checked)
            }
            backgroundColor={values.needPublished ? '#216FE0' : undefined}
          />
          <p className={styles.CheckText}>{'Опубликовать трансляцию'}</p>
        </div>
        <div className={styles.TextArea}>
          <Input label="Заголовок" variant="dark" register={register('title')} maxLength={30} />
        </div>
        <Controller
          control={control}
          name={'calendar.repeatType'}
          render={({ field }) => {
            return (
              <Select
                className={styles.Select}
                classNamePrefix={styles.SelectContainer}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={selectStyles}
                placeholder={'Повторять:'}
                options={selectOptions}
                onChange={selectedOption => {
                  if (!isNull(selectedOption)) field.onChange(selectedOption.value);
                }}
              />
            );
          }}
        />

        {isOtherOption && (
          <div className={styles.Interval}>
            <Controller
              control={control}
              name={'calendar.periodType'}
              render={({ field }) => {
                return (
                  <Select
                    className={styles.Select}
                    classNamePrefix={styles.SelectContainer}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={selectStyles}
                    placeholder={'Окончание:'}
                    options={intervalOptions}
                    onChange={selectedOption => {
                      if (!isNull(selectedOption)) field.onChange(selectedOption.value);
                    }}
                    defaultValue={
                      field.value ? { label: field.value, value: field.value } : intervalOptions[0]
                    }
                  />
                );
              }}
            />

            <DisplayField
              label={`Количество ${
                isNil(values.calendar.periodType)
                  ? periodDurations[TranslationPeriodTime.DAY]
                  : periodDurations[values?.calendar?.periodType]
              }`}
              value={'2'}
              isInput
              onInputChange={e => setValue('calendar.interval', +e.target.value)}
            />
          </div>
        )}
        {isWeeklyOption && (
          <Controller
            control={control}
            render={() => {
              return (
                <>
                  <WeekDaysInput
                    selectedDays={new Set<WeekDays>(values.calendar.weekDays)}
                    onDayClick={id => {
                      const selDays = new Set(values.calendar.weekDays);
                      if (selDays.has(id)) {
                        selDays.delete(id);
                      } else {
                        selDays.add(id);
                      }
                      setValue('calendar.weekDays', Array.from(selDays));
                    }}
                  />
                </>
              );
            }}
            name={'calendar.weekDays'}
          />
        )}

        <Controller
          control={control}
          render={() => {
            return (
              <TranslationDatePicker
                label={''}
                dateTime={String(values.calendar.date)}
                isWeeklyOption={isWeeklyOption}
                timesArr={values.calendar.times}
                setDay={value => {
                  const now = dayjs();
                  if (now.isBefore(value) || now.isToday()) {
                    setValue('calendar.date', dayjs(value).format('DD.MM.YYYY'));
                  }
                }}
              />
            );
          }}
          name={'calendar.date'}
        />

        <Controller
          control={control}
          render={() => {
            return (
              <TimeDisplay
                setDay={value => {
                  if (timesArrRef.current.length < 5) {
                    timesArrRef.current.push(dayjs(value).format('HH:mm'));
                    timesArrRef.current = Array.from(new Set(timesArrRef.current));
                    setValue('calendar.times', timesArrRef.current);
                  }
                }}
              />
            );
          }}
          name={'calendar.times'}
        />

        <div className={styles.Times}>
          {values?.calendar?.times?.map(time => {
            return (
              <TimeMiniCard
                key={time}
                time={time}
                onCloseClick={() => {
                  setValue('calendar.times', deleteSelectedTime(time));
                }}
              />
            );
          })}
        </div>

        {isOtherOption && (
          <div className={styles.Interval}>
            <Controller
              control={control}
              name={'calendar.endingType'}
              render={({ field }) => {
                return (
                  <Select
                    className={styles.Select}
                    classNamePrefix={styles.SelectContainer}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={selectStyles}
                    placeholder={'Интервал:'}
                    options={endingSelect}
                    onChange={selectedOption => {
                      if (!isNull(selectedOption)) field.onChange(selectedOption.value);
                    }}
                    defaultValue={
                      field.value ? { label: field.value, value: field.value } : endingSelect[0]
                    }
                  />
                );
              }}
            />

            {!isNeverEnding && (
              <>
                {isDateEnding ? (
                  <DateModal
                    setDay={newDay =>
                      setValue('calendar.expireDate', dayjs(newDay).format('DD.MM.YYYY'))
                    }
                    button={
                      <>
                        <DisplayField label={'Дата:'} value={String(values.calendar.expireDate)} />
                      </>
                    }
                  />
                ) : (
                  <DisplayField
                    label={'Количество дней:'}
                    value={'2'}
                    isInput
                    onInputChange={e => setValue('calendar.maxNumberOfUses', +e.target?.value)}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>

      <p className={styles.InfoText}>Минимальная длительность загружаемого видеофайла 1 минута</p>

      <Controller
        control={control}
        render={() => {
          return (
            <FileThinInput
              defaultFile={editingFile}
              isUploading={isUploading}
              setFile={file => {
                const formData = new FormData();
                if (file) {
                  formData.append('file', file);
                  formData.append('type', 'post');
                  uploadImage(formData).then(res => {
                    if ('data' in res) {
                      setValue('fileId', res?.data.fileId, { shouldValidate: false });
                      delay(() => {
                        forceUpdate();
                      }, 400);
                    }
                  });
                }
                setValue('fileId', '', { shouldValidate: false });
              }}
            />
          );
        }}
        name={'fileId'}
      />
    </Modal>
  );
};

export default TranslationModalPost;
