import { FC, ReactNode } from 'react';

import styles from './EditorTitle.module.scss';

type Props = {
  children: ReactNode;
};

const EditorTitle: FC<Props> = ({ children }) => {
  return <h3 className={styles.Title}>{children}</h3>;
};

export default EditorTitle;
