import { FC } from 'react';
import last from 'lodash-es/last';
import classNames from 'classnames';

import styles from './Elements.module.scss';
import Desktop from './components/Desktop';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';
import { getElements } from './elements.constants';
import Mobile from './components/Mobile';

type Props = {
  className?: string;
  onClose?: () => void;
};

const Elements: FC<Props> = ({ className, onClose }) => {
  const page = useActivePage();

  if (!page) return null;

  const lastBlockId = last([...page.components].sort((a, b) => a.order - b.order))?.hash ?? '';
  const elements = getElements(lastBlockId, page.id);

  return (
    <div className={classNames(styles.Elements, className)}>
      <Desktop elements={elements} />
      <Mobile onClose={onClose as () => void} elements={elements} />
    </div>
  );
};

export default Elements;
