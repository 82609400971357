import { FC, ReactNode } from 'react';

import styles from './ToggleActionCard.module.scss';
import { ToggleSwitchProps } from 'components/ToggleSwitch';
import ToggleSwitchWithLabel from 'components/ToggleSwitchWithLabel';

type Props = {
  name: string;
  toggle: ToggleSwitchProps;
  variant?: 'default' | 'form-field';
  image?: string;
  description?: string;
  rightIcon?: ReactNode;
};

const ToggleActionCard: FC<Props> = ({
  name,
  toggle,
  variant = 'default',
  image,
  description,
  rightIcon,
}) => {
  return (
    <article className={styles.Card} data-variant={variant}>
      <div className={styles.Upper}>
        {image && <img className={styles.Img} src={image} alt="" />}
        {rightIcon}
      </div>
      <h3 className={styles.Title}>{name}</h3>
      {description && <p className={styles.Desc}>{description}</p>}
      <ToggleSwitchWithLabel {...toggle} />
    </article>
  );
};

export default ToggleActionCard;
