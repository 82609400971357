import { FC, useLayoutEffect } from 'react';
import Content from '../../components/Content';
import PageHeader from 'components/PageHeader';
import BotChatBody from '../../components/BotChatBody';
import { SimpleCipher, urlOffset } from '../../telegram.utils';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useGetScheduledAdminMessagesQuery } from '../../codegen/telegram.admin.api';
import { useBotId } from '../../telegram.hooks';
import isUndefined from 'lodash-es/isUndefined';
import LoadingState from 'components/LoadingState';
import BotsChatButtons from '../../components/BotsChatButtons';
import { useAdminBotName } from '../../telegram.selectors';
import { useAdminScheduledMessageParams } from '../../telegram.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { setScheduledMessagesPageParams } from '../../codegen/telegram.admin.reducer';
import r from '../../../../constants/routes';

const BotsChat: FC = () => {
  const { messageName, chatId } = useParams();
  const botId = useBotId();
  const params = useAdminScheduledMessageParams();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(location.search);
  const isParamsMatch = params.offset === +urlOffset(queryParams.get('offset'));
  const navigate = useNavigate();
  const decryptedName = messageName ? SimpleCipher.decrypt(messageName) : '';
  const botName = useAdminBotName();
  const { isFetching } = useGetScheduledAdminMessagesQuery(
    { botId, chatId: String(chatId), ...params },
    { skip: isUndefined(chatId) || !isParamsMatch, refetchOnMountOrArgChange: true },
  );

  useLayoutEffect(() => {
    const offset = urlOffset(queryParams.get('offset'));
    dispatch(setScheduledMessagesPageParams({ offset }));
  }, [params.offset]);

  if (isFetching) return <LoadingState />;
  return (
    <Content>
      <PageHeader
        subtitle={botName || 'Мой первый бот'}
        title={decryptedName}
        elem={<BotsChatButtons />}
        onIconClick={() => {
          navigate(generatePath(r.telegram.editAdminBot, { id: botId }));
        }}
      />
      <BotChatBody />
    </Content>
  );
};

export default BotsChat;
