import classNames from 'classnames';
import Icon from 'components/Icon';
import { FC } from 'react';

import styles from './CheckedLine.module.scss';

type Props = {
  notActive?: boolean;
  text?: string;
  className?: string;
};
const CheckedLine: FC<Props> = ({ notActive, text, className }) => {
  return (
    <div
      className={classNames(
        styles.Line,
        {
          [styles.NotActive]: !!notActive,
        },
        className,
      )}
    >
      <div className={styles.Icon}>
        <Icon iconName="checkedline-check" size={11} />
      </div>
      <span className={styles.Span}>{text}</span>
    </div>
  );
};

export default CheckedLine;
