import moment from 'moment-timezone';

export const getTimeZones = (zoneCode: string) => {
  const russianCities = moment.tz.zonesForCountry(zoneCode);
  return russianCities.map(item => {
    return {
      label: item,
      value: item,
    };
  });
};
