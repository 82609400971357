import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Card from './pages/Card';

const PublicCardRoutes = (): JSX.Element => {
  return (
    <>
      <Route path={r.card} element={<Card />} />
    </>
  );
};

export default PublicCardRoutes;
