import VideoInstructions from '../VideoInstructions';
import { FC } from 'react';
import styles from './VideoTutorial.module.scss';

export const VideoTutorial: FC<{ link: string }> = ({ link }) => {
  return (
    <div className={styles.Root}>
      <VideoInstructions link={link} />
    </div>
  );
};
