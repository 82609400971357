import { FC } from 'react';

import styles from './Header.module.scss';
import TariffEndNotice from 'components/TariffEndNotice';
import BellNotifications from 'components/BellNotifications';
import UserBalance from 'components/UserBalance';
import { Advertising } from '../Advertising';
import useWindowSize from '../../hooks/useWindowSize';
import { addsMap } from '../../constants/adds';

const Header: FC = () => {
  const { width } = useWindowSize();
  return (
    <header className={styles.Header}>
      <div className={styles.HeaderContent}>
        <TariffEndNotice />

        <div className={styles.Adds}>
          <Advertising
            imageUrl={addsMap.header.imgUrl}
            link={addsMap.header.link}
            isRow
            isMobile={width <= 1200}
          />
        </div>

        <div className={styles.HeaderControls}>
          <UserBalance />
          <BellNotifications />
        </div>
      </div>
    </header>
  );
};

export default Header;
