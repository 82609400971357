import { FC } from 'react';

import styles from './TextAreaWithImgInput.module.scss';
import TextArea, { TextAreaProps } from 'components/TextArea';
import ModalWithImgInput, { ModalWithImgInputProps } from 'components/ModalWithImgInput';
import useDisclosure from 'hooks/useDisclosure';

type Props = {
  textareaProps: TextAreaProps;
  modalWithImgInputProps: ModalWithImgInputProps;
};

const TextAreaWithImgInput: FC<Props> = ({ textareaProps, modalWithImgInputProps }) => {
  const { open, onOpen, onClose } = useDisclosure();

  const imagesCount = modalWithImgInputProps.images.length;

  return (
    <>
      <TextArea
        {...textareaProps}
        icons={[
          {
            name: 'add-img',
            onClick: onOpen,
            className: imagesCount > 0 ? styles.IconActive : undefined,
            sideContent: imagesCount > 0 && (
              <span className={styles.ImagesCount}>{imagesCount}</span>
            ),
          },
        ]}
      />
      <ModalWithImgInput modalProps={{ isOpen: open, onClose }} {...modalWithImgInputProps} />
    </>
  );
};

export default TextAreaWithImgInput;
