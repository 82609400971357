import { FC } from 'react';

import styles from './TransactionType.module.scss';
import { ETransactionType } from 'modules/finances/finances.types';
import Icon from 'components/Icon';
import { ICONS_MAP } from 'modules/finances/finances.constants';

type Props = {
  type: ETransactionType;
};

const TransactionType: FC<Props> = ({ type }) => {
  return (
    <div className={styles.Circle} data-type={type}>
      <Icon iconName={ICONS_MAP[type]} size={24} />
    </div>
  );
};

export default TransactionType;
