import { FC } from 'react';

import styles from './ThematicGroupsStacks.module.scss';
import Title from 'components/Title';
import { GroupCardSelectableProps } from 'modules/vk/components/GroupCardSelectable';
import { useSelectedThematicGroups } from 'modules/vk/vk.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { selectThematicGroup } from 'modules/vk/vk.reducer';
import GroupsStack from 'modules/vk/components/GroupsStack';
import NavWithTabsMobile from 'components/NavWithTabsMobile';
import r from 'constants/routes';

const ThematicGroupsStacks: FC = () => {
  const dispatch = useAppDispatch();
  const selected = useSelectedThematicGroups();

  const groups: GroupCardSelectableProps[] = [
    {
      id: 1,
      avatar: 'https://placebear.com/300/300',
      subscribersCount: 123_343,
      type: 'Еда / рецепты',
      name: 'Кулинарная книга',
      selected: selected.includes(1),
      onSelect: () => dispatch(selectThematicGroup(1)),
      subscribed: false,
    },
    {
      id: 2,
      avatar: 'https://placebear.com/300/300',
      subscribersCount: 123_343,
      type: 'Еда / рецепты',
      name: 'Кулинарная книга',
      selected: selected.includes(2),
      onSelect: () => dispatch(selectThematicGroup(2)),
      subscribed: true,
    },
    {
      id: 3,
      avatar: 'https://placebear.com/300/300',
      subscribersCount: 123_343,
      type: 'Еда / рецепты',
      name: 'Кулинарная книга',
      selected: selected.includes(3),
      onSelect: () => dispatch(selectThematicGroup(3)),
      subscribed: false,
    },
  ];

  return (
    <div className={styles.Page}>
      <Title className={styles.Title}>Стопки</Title>
      <div className={styles.Grid}>
        <GroupsStack id={1} name="Кулинарная" groups={groups} />
        <GroupsStack id={2} name="Кулинарная" groups={groups} />
        <GroupsStack id={3} name="Кулинарная" groups={groups} />
      </div>

      <NavWithTabsMobile
        links={[
          { name: 'Подписки', link: r.vk.thematicGroups.index },
          { name: 'Просмотр стопок', link: r.vk.thematicGroups.stacks },
        ]}
      />
    </div>
  );
};

export default ThematicGroupsStacks;
