import { FC } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';

import styles from './DropdownMenu.module.scss';

export type DropdownOption = {
  isSelected: boolean;
  displayValue: string | JSX.Element;
  onClick: () => void;
};

type Props = {
  options: DropdownOption[];
  open: boolean;
  title?: string;
  className?: string;
};

const DropdownMenu: FC<Props> = ({ options, className, open, title }) => {
  return (
    <div className={classNames(styles.DropdownMenu, className)} data-open={open}>
      {title && <div className={styles.DropdownTitle}>{title}</div>}

      {isEmpty(options) ? (
        <div className={styles.DropdownTitle}>Нет опций</div>
      ) : (
        options.map((option, index) => (
          <button
            className={styles.DropdownOption}
            key={typeof option.displayValue === 'string' ? option.displayValue : index}
            data-selected={option.isSelected}
            onClick={option.onClick}
            type="button"
          >
            {option.displayValue}
          </button>
        ))
      )}
    </div>
  );
};

export default DropdownMenu;
