import { FC } from 'react';

import styles from './Settings.module.scss';
import AccountSettings from '../components/AccountSettings';
import PasswordSettings from '../components/PasswordSettings';

const Settings: FC = () => {
  return (
    <div className={styles.Settings}>
      <AccountSettings />
      <PasswordSettings />
      {/* <AutoPayCardsSetting /> */}
    </div>
  );
};

export default Settings;
