function getWordByAmount(amount: number, options: string[]): string {
  const lastDigit = amount % 10;

  if (lastDigit === 1 && amount !== 11) {
    return options[0];
  } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(amount)) {
    return options[1];
  } else {
    return options[2];
  }
}

export default getWordByAmount;
