import { FC } from 'react';

import styles from './LinksList.module.scss';
import SocialNetwork from '../SocialNetwork';
import {
  Landing,
  LandingButton,
  LandingButtonSocialNetwork,
  SocialNetworkProperties,
} from 'modules/landing-generator/landing-generator.types';
import { sortBy } from 'lodash-es';
// import { Reorder } from 'framer-motion';

type Props = {
  id: Landing['id'];
  button: LandingButton;
};

const LinksList: FC<Props> = ({ id, button }) => {
  const commonProps = {
    id,
    btnId: button.id,
    orientation: button.orientation,
  };

  const buttons: [LandingButtonSocialNetwork, SocialNetworkProperties][] = [];
  if (button.vk) buttons.push(['vk', button.vk]);
  if (button.viber) buttons.push(['viber', button.viber]);
  if (button.whatsapp) buttons.push(['whatsapp', button.whatsapp]);
  if (button.instagram) buttons.push(['instagram', button.instagram]);
  if (button.facebook) buttons.push(['facebook', button.facebook]);
  if (button.facebook) buttons.push(['facebook', button.facebook]);
  if (button.telegram) buttons.push(['telegram', button.telegram]);

  return (
    <div className={styles.Socials} data-orientation={button.orientation}>
      {/* <Reorder.Group values={buttons} onReorder={console.log} className={styles.List}> */}
      {sortBy(buttons, ([, value]) => value.order).map(
        ([type, props]) => (
          // <Reorder.Item key={props.content} value={props} whileDrag={{scale: 1.1}}>
          <SocialNetwork key={props.content} {...commonProps} type={type} network={props} />
        ),
        // </Reorder.Item>
      )}
      {/* </Reorder.Group> */}
    </div>
  );
};

export default LinksList;
