import { FC } from 'react';

import styles from './Tariffs.module.scss';
import commonStyles from '../../Sections.module.scss';
import { CardSelected } from 'modules/tariff/components/CardHolder';
import SectionTitle from 'modules/account/components/SectionTitle';
import { useSelectUser } from 'modules/account/account.selector';
import { EPlanName } from 'modules/account/account.interfaces';

const Tariffs: FC = () => {
  const user = useSelectUser();

  if (user?.plan.name !== EPlanName.default) return null;
  return (
    <section className={commonStyles.Section}>
      <SectionTitle>Тарифные планы</SectionTitle>
      <div className={styles.Wrapper}>
        <div className={styles.Cards}>
          <CardSelected isLines={false} />
          <div className={styles.GhostCard} />
        </div>
      </div>
    </section>
  );
};

export default Tariffs;
