import { FC, useLayoutEffect } from 'react';
import styles from './SpamBotPlannedMessages.module.scss';
import { useGetAllScheduledMessageSpamBotQuery } from '../../../../codegen/telegram.spam.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useBotId } from '../../../../telegram.hooks';
import isUndefined from 'lodash-es/isUndefined';
import PlannedMessageCard from '../../../../components/PlannedMessageCard';
import { PlanedMessageCardType } from '../../../../codegen/telegram.constants';
import { useGetSpamScheduledMessageParams } from '../../../../codegen/telegram.spam.selectors';
import { urlOffset } from '../../../../telegram.utils';
import useAppDispatch from 'hooks/useAppDispatch';
import { setSpamScheduledMessagesPageParams } from '../../../../codegen/telegram.spam.reducer';
import LoadingState from 'components/LoadingState';
import Pagination from 'components/Pagination';
import EmptyText from '../../../../components/EmptyText';

const SpamBotPlannedMessages: FC = () => {
  const { chatId } = useParams();
  const botId = useBotId();
  const params = useGetSpamScheduledMessageParams();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(location.search);
  const isParamsMatch = params.offset === +urlOffset(queryParams.get('offset'));
  const navigate = useNavigate();

  const { data, isFetching } = useGetAllScheduledMessageSpamBotQuery(
    { chatId: String(chatId), botId, ...params },
    { skip: isUndefined(chatId) || !isParamsMatch, refetchOnMountOrArgChange: true },
  );

  useLayoutEffect(() => {
    const offset = urlOffset(queryParams.get('offset'));
    dispatch(setSpamScheduledMessagesPageParams({ offset }));
  }, []);

  const pageChange = (page: number) => {
    let offset;
    queryParams.set('page', `${page}`);

    if (page === 1) {
      offset = 0;
      queryParams.set('offset', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setSpamScheduledMessagesPageParams({ offset }));
    } else {
      offset = (page - 1) * 5;
      queryParams.set('offset', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setSpamScheduledMessagesPageParams({ offset }));
    }
  };

  if (isFetching) return <LoadingState />;

  return (
    <div className={styles.Root}>
      <h1 className={styles.Title}>Запланированные сообщения</h1>
      {data?.count === 0 ? (
        <EmptyText text={'У вас пока нет запланированных сообщений'} />
      ) : (
        <>
          <div className={styles.Wrapper}>
            {data?.data.map(msg => (
              <PlannedMessageCard key={msg.id} cardType={PlanedMessageCardType.SPAM_BOT} {...msg} />
            ))}
          </div>
          <Pagination
            onPageChange={num => pageChange(num)}
            offset={1}
            totalCount={data?.count ? Math.ceil(data?.count / 5) : 5}
            currPage={Number(queryParams.get('page'))}
          />
        </>
      )}
    </div>
  );
};

export default SpamBotPlannedMessages;
