import { FC } from 'react';
import Button from 'components/Button';
import { TResVkAccount } from '../../../../vk.types';
import { useUnLoadCsvFileMutation } from '../../../../vk.api';
import { useParams } from 'react-router-dom';
import CountdownTimer from '../CountdownTimer';
import { useGetCurrentTimerState } from '../../../../vk.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { setTimerInPersist } from '../../../../vk.reducer';

type Props = {
  className: string;
  id: number;
  name: string;
};

const DownloadCsvBtn: FC<Props> = ({ className, id, name }) => {
  const { id: groupId } = useParams();
  const [downloadCsv, { isLoading: isCsvDownloading }] = useUnLoadCsvFileMutation();
  const currName = name + id;
  const state = useGetCurrentTimerState() || {};
  const currObj = state[currName];
  const dispatch = useAppDispatch();

  function downloadBlobAsFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  return (
    <>
      {currObj?.isRunning ? (
        <CountdownTimer id={id} name={name} />
      ) : (
        <Button
          className={className}
          variant={'tetriary'}
          centralIcon={isCsvDownloading ? 'loader' : 'download-cloud'}
          disabled={isCsvDownloading}
          onClick={async () => {
            const res: TResVkAccount<Blob> = (await downloadCsv({
              groupId: groupId + '',
              parsingId: id,
            })) as unknown as TResVkAccount<Blob>;
            if ('data' in res) {
              downloadBlobAsFile(res.data, `${name}_data_${id}.xlsx`);
              const currentDate = new Date();

              dispatch(
                setTimerInPersist({
                  [currName]: {
                    timeLeft: 300,
                    isRunning: true,
                    createdTime: new Date(),
                    expireTime: new Date(currentDate.setMinutes(currentDate.getMinutes() + 5)),
                  },
                }),
              );
            }
          }}
        ></Button>
      )}
    </>
  );
};

export default DownloadCsvBtn;
