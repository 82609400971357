import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from './PostModal.module.scss';
import Modal, { CommonModalProps } from 'components/Modal';
import DatePicker from 'components/DatePicker';
import {
  useCreateAutomationStoryMutation,
  useGetFilesFromVkQuery,
  useGetVkAutomationStoryQuery,
  useUpdateAutomationStoryMutation,
  useUploadFileIntoVkMutation,
} from 'modules/vk/vk.api';
import { EditStoriesRequestBody } from 'modules/vk/vk.types';
import { automationFormHandler, createFile, fromStringToDate } from '../../vk.utils';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { createPostResult } from '../../vk.constants';
import isUndefined from 'lodash-es/isUndefined';
import { yupResolver } from '@hookform/resolvers/yup';
import { vkStorySchema } from '../../vk.schema';
import FileThinInput from 'components/FileThinInput';

export type PostModalProps = {
  postId?: number;
} & CommonModalProps;

const PostModalStory: FC<PostModalProps> = ({ postId, ...modalProps }) => {
  const { data: storyData, isFetching } = useGetVkAutomationStoryQuery(
    { storyId: String(postId) },
    { skip: isUndefined(postId) },
  );
  const { data: images } = useGetFilesFromVkQuery(
    { ids: [String(storyData?.data?.fileId)] },
    { skip: isUndefined(storyData?.data?.fileId) },
  );
  const [createPost] = useCreateAutomationStoryMutation();
  const [uploadImage, { isLoading: isUploading }] = useUploadFileIntoVkMutation();
  const [updateStory] = useUpdateAutomationStoryMutation();
  const [editingFile, setEditingFile] = useState<File>();

  const { watch, setValue, handleSubmit, reset } = useForm<EditStoriesRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(vkStorySchema()),
    defaultValues: {
      date: new Date(),
    },
  });

  const onClose = () => {
    reset();
    modalProps.onClose();
  };

  const onSubmit = handleSubmit(async formData => {
    const form = automationFormHandler(formData);
    if (postId) {
      updateStory({ storyId: postId, ...form })
        .then(res => {
          if ('data' in res) {
            showFeedback(createPostResult.successStories);
            onClose();
          }
        })
        .catch(() => {
          showFeedback(createPostResult.error);
        });
    } else {
      createPost(form)
        .then(res => {
          if ('data' in res) {
            showFeedback(createPostResult.successStories);
            onClose();
          }
        })
        .catch(() => {
          showFeedback(createPostResult.error);
        });
    }

    modalProps.onClose();
  });

  useEffect(() => {
    if (storyData) {
      reset({
        date: fromStringToDate(storyData?.data?.date, storyData?.data?.time),
        photo: storyData?.data?.fileId,
      });
    }
  }, [storyData, reset]);

  useEffect(() => {
    const nameArr = images?.data[0].s3Url?.split('/');
    if (nameArr) {
      if (postId) {
        const name = nameArr[nameArr.length - 1];
        createFile(images?.data[0].s3Url, images?.data[0].type, name).then(res => {
          setEditingFile(res);
        });
      }
    }
  }, [images?.data]);

  const values = watch();

  const isValid = Boolean(values.date) && Boolean(values.photo);

  return (
    <Modal
      {...modalProps}
      onClose={onClose}
      onSubmit={onSubmit}
      isValid={isValid}
      isLoading={isFetching}
    >
      <h3 className={styles.Title}>{postId ? 'Редактирование' : 'авто-сториз'}</h3>
      <p className={styles.Text}>
        {'Подберите определенное время выгрузки для каждого медиафайла'}
      </p>

      <p className={styles.InfoText}>
        {'При загрузке видеофайлов, используйте файл длительностью не более 15 секунд.'}
      </p>

      <div className={styles.FormFields}>
        <FileThinInput
          defaultFile={editingFile}
          bothImageAndVideo
          isUploading={isUploading}
          setFile={file => {
            const formData = new FormData();
            if (file) {
              formData.append('file', file);
              formData.append('type', 'post');
              uploadImage(formData).then(res => {
                if ('data' in res) {
                  setValue('photo', res?.data.fileId, { shouldValidate: false });
                }
              });
            }
            setValue('photo', '', { shouldValidate: false });
          }}
        />
        <DatePicker
          label="Дата и время выгрузки"
          day={values.date}
          setDay={value => setValue('date', value, { shouldValidate: true })}
        />
      </div>
    </Modal>
  );
};

export default PostModalStory;
