import { FC } from 'react';

import styles from './Controls.module.scss';
import { BlockComponent, EComponentType } from 'modules/my-pages/my-pages.types';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { addComponent, moveComponent, setActiveComponent } from 'modules/my-pages/my-pages.reducer';
import ControlButton from 'components/ControlButton';
import { createBlock, createComponent } from 'modules/my-pages/my-pages.creators';

const Controls: FC<BlockComponent> = props => {
  const dispatch = useAppDispatch();
  const page = useActivePage();

  if (!page) return null;

  const canMoveUp = props.order !== 0;
  const canMoveDown = props.order !== page.components.length - 1;

  const onMoveUp = () => dispatch(moveComponent({ hash: props.hash, newOrder: props.order - 1 }));

  const onMoveDown = () => dispatch(moveComponent({ hash: props.hash, newOrder: props.order + 1 }));

  const onEdit = () => dispatch(setActiveComponent(props.hash));

  const onAdd = () =>
    dispatch(
      addComponent({
        parentId: page.id,
        component: createComponent(createBlock(), EComponentType.BLOCK, props.order + 1),
      }),
    );

  return (
    <>
      <div className={styles.Group} data-position="left">
        <ControlButton
          icon={{ iconName: 'arrow-down-long', size: 16 }}
          size={28}
          disabled={!canMoveDown}
          onClick={onMoveDown}
        />
        <ControlButton
          icon={{ iconName: 'arrow-up-long', size: 16 }}
          size={28}
          disabled={!canMoveUp}
          onClick={onMoveUp}
        />
      </div>

      <div className={styles.Group} data-position="right">
        <ControlButton icon={{ iconName: 'settings', size: 16 }} size={28} onClick={onEdit} />
        <ControlButton icon={{ iconName: 'add', size: 16 }} size={28} onClick={onAdd} />
      </div>
    </>
  );
};

export default Controls;
