import { FC, useState } from 'react';
import fallback from 'assets/img/fallback/fallback.png';
import styles from './FolderSearchCard.module.scss';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import useWindowSize from 'hooks/useWindowSize';
import GroupCard from '../../../GroupCard';
import env from '../../../../../../config/env';

type Props = {
  image?: string;
  title: string;
  subTitle: string;
  subscribers: string | number;
  isChecked?: boolean;
  onChoose: () => void;
  screenName: string;
};

const FolderSearchCard: FC<Props> = props => {
  const { width } = useWindowSize();
  const { image, title, subTitle, subscribers, isChecked, onChoose, screenName } = props;
  const [checked, setChecked] = useState<boolean>(false);

  if (width <= 600)
    return (
      <div className={styles.MobileCard}>
        <GroupCard
          isChecked={checked}
          onChoose={() => {
            onChoose();
            setChecked(prev => !prev);
          }}
          onFollowLink={() => {
            window.open(`${env.VK_URL}/${screenName}`, '_blank')?.focus();
          }}
          subTitle={subTitle}
          image={image}
          subscribers={subscribers}
          title={title}
        />
      </div>
    );
  return (
    <div className={styles.Root}>
      <div className={styles.CardLeft}>
        <img className={styles.Img} src={image || fallback} alt="img" />
        <div className={styles.CardText}>
          <h1 className={styles.CardTitle}>{title}</h1>
          <p className={styles.SubTitle}>{subTitle}</p>
          <p className={styles.Subscribers}>{subscribers}</p>
        </div>
      </div>
      <div className={styles.CardRight}>
        <Button
          variant={'tetriary'}
          onClick={() => {
            window.open(`${env.VK_URL}/${screenName}`, '_blank')?.focus();
          }}
        >
          Перейти
        </Button>
        <CheckBox checked={isChecked} onChange={onChoose} />
      </div>
    </div>
  );
};

export default FolderSearchCard;
