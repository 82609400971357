import { FC } from 'react';
import styles from './TimezoneDiv.module.scss';

const TimezoneDiv: FC<{ timezone?: string }> = ({ timezone }) => {
  return (
    <div className={styles.TimeZone}>
      <p className={styles.TimeZonePar}>Часовой пояс:</p>
      {timezone && <p className={styles.TimeZonePar}>{timezone}</p>}
    </div>
  );
};

export default TimezoneDiv;
