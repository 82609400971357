import { UseFormRegisterReturn } from 'react-hook-form';

import styles from './RadioSelect.module.scss';
import { CheckboxOption } from 'components/CheckboxSelect';
import RadioInput from 'components/RadioInput';

type Props<T> = {
  selectValue: (value: T) => void;
  selected: T;
  options: CheckboxOption<T>[];
};

const RadioSelect = <T extends string | number | boolean>({
  selectValue,
  selected,
  options,
}: Props<T>) => {
  return (
    <div className={styles.Wrapper}>
      {options.map(option => (
        <RadioInput
          key={option.text}
          label={option.text}
          disabled={option.disabled}
          register={
            {
              checked: option.value === selected,
              onChange: () => selectValue(option.value),
            } as unknown as UseFormRegisterReturn
          }
        />
      ))}
    </div>
  );
};

export default RadioSelect;
