import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormPageTemplate from 'components/FormPageTemplate';
import { DbParsingRequestBody } from 'modules/vk/vk.types';
import { dbParsingSchema } from 'modules/vk/vk.schema';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import WhiteTabs from 'modules/vk/components/WhiteTabs';
import Input from 'components/Input';
import CheckBox from 'components/CheckBox';
import SexSelector from 'components/SexSelector';
import LastActivitySelector from 'components/LastActivitySelector';
import GroupsLinksInput from 'modules/vk/components/inputs/GroupsLinksInput';
import GeolocationInput from 'modules/vk/components/inputs/GeolocationInput';
import StackSelector from 'modules/vk/components/inputs/StackSelector';

const DbParsingEdit: FC = () => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<DbParsingRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(dbParsingSchema()),
    defaultValues: {
      isStack: false,
      name: { selected: false },
      groupsLinks: { selected: false, value: [] },
      stacks: { selected: false, value: [] },
      geolocation: { selected: false },
      sex: { selected: false },
      age: { selected: false },
      friendsCount: { selected: false },
      lastActivity: { selected: false },
      maxAccountCount: { selected: false },
    },
  });

  const onSubmit: SubmitHandler<DbParsingRequestBody> = async () => {
    await showFeedback({
      title: 'Бог готов',
      text: 'Теперь он будет писать сообщения за вас',
      type: EFeedbackType.SUCCESS,
    });
  };

  const values = watch();

  return (
    <FormPageTemplate title="Выборка" onSubmit={handleSubmit(onSubmit)} isValid={isValid} short>
      <WhiteTabs
        tabs={[
          {
            name: 'Отдельная группа',
            isSelected: !values.isStack,
            onClick: () => setValue('isStack', false, { shouldValidate: true }),
          },
          {
            name: 'Стопка из тематических',
            isSelected: values.isStack,
            onClick: () => setValue('isStack', true, { shouldValidate: true }),
          },
        ]}
      />
      <Input
        label="Название выборки"
        register={register('name.value')}
        sideRender={
          <CheckBox
            checked={values.name.selected}
            onChange={() => setValue('name.selected', !values.name.selected)}
          />
        }
        variant="dark"
      />
      {values.isStack ? (
        <StackSelector
          value={values.stacks.value ?? []}
          setValue={(name, value, options) => setValue('stacks.value', value, options)}
          inputProps={{
            sideRender: (
              <CheckBox
                checked={values.stacks.selected}
                onChange={() => setValue('stacks.selected', !values.groupsLinks.selected)}
              />
            ),
          }}
        />
      ) : (
        <GroupsLinksInput
          value={values.groupsLinks.value ?? []}
          setValue={(name, value, options) => setValue('groupsLinks.value', value, options)}
          inputProps={{
            sideRender: (
              <CheckBox
                checked={values.groupsLinks.selected}
                onChange={() => setValue('groupsLinks.selected', !values.groupsLinks.selected)}
              />
            ),
          }}
        />
      )}
      <GeolocationInput
        value={values.geolocation?.value ?? []}
        setValue={(name, value, options) => setValue('geolocation.value', value, options)}
        inputProps={{
          sideRender: (
            <CheckBox
              checked={values.geolocation?.selected}
              onChange={() => setValue('geolocation.selected', !values.geolocation?.selected)}
            />
          ),
        }}
      />
      <SexSelector
        value={values.sex?.value}
        select={value => setValue('sex.value', value, { shouldValidate: true })}
        sideRender={
          <CheckBox
            checked={values.sex?.selected}
            onChange={() => setValue('sex.selected', !values.sex?.selected)}
          />
        }
        variant="dark"
      />
      <Input
        label="Возраст"
        register={register('age.value')}
        sideRender={
          <CheckBox
            checked={values.age?.selected}
            onChange={() => setValue('age.selected', !values.age?.selected)}
          />
        }
        example="Пример: 24 - 34"
        variant="dark"
      />
      <Input
        label="Количество друзей"
        register={register('friendsCount.value')}
        sideRender={
          <CheckBox
            checked={values.friendsCount?.selected}
            onChange={() => setValue('friendsCount.selected', !values.friendsCount?.selected)}
          />
        }
        example="Пример: от 2343 до 34534"
        variant="dark"
      />
      <LastActivitySelector
        value={values.lastActivity?.value}
        select={value => setValue('lastActivity.value', value, { shouldValidate: true })}
        sideRender={
          <CheckBox
            checked={values.lastActivity?.selected}
            onChange={() => setValue('lastActivity.selected', !values.lastActivity?.selected)}
          />
        }
        variant="dark"
      />
      <Input
        label="Максимальное кол-во аккаунтов"
        type="number"
        register={register('maxAccountCount.value')}
        sideRender={
          <CheckBox
            checked={values.maxAccountCount?.selected}
            onChange={() => setValue('maxAccountCount.selected', !values.maxAccountCount?.selected)}
          />
        }
        variant="dark"
      />
    </FormPageTemplate>
  );
};

export default DbParsingEdit;
