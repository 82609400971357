import { FC } from 'react';

import styles from './ModalButton.module.scss';
import Button, { ButtonProps } from 'components/Button';
import { LandingModal } from 'modules/landing-generator/landing-generator.types';

type Props = {
  button: LandingModal;
} & Omit<ButtonProps<'button'>, 'children'>;

const ModalButton: FC<Props> = ({ button, ...btnProps }) => {
  return (
    <div className={styles.Wrapper}>
      <Button className={styles.Button} {...btnProps} color={button.buttonColor}>
        {button.buttonName}
      </Button>
    </div>
  );
};

export default ModalButton;
