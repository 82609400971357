import numbro from 'numbro';

export function formatBytesToGB(bytes?: number): string {
  if (bytes) {
    return numbro(bytes).format({
      output: 'byte',
      base: 'general',
      spaceSeparated: true,
      mantissa: 1,
    });
  }
  return '0 GB';
}
