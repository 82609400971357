import { RefObject, useEffect } from 'react';

function useClickOutside(
  ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  onClickOutside?: (() => void) | null,
): null {
  useEffect(() => {
    if (onClickOutside) {
      const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        const target = event.target as HTMLElement;
        const refsToCheck = Array.isArray(ref) ? ref : [ref];
        let hasClickInside = false;

        for (const item of refsToCheck) {
          if (item.current && item.current.contains(target)) {
            hasClickInside = true;
            break;
          }
        }

        if (!hasClickInside) onClickOutside();
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [ref, onClickOutside]);

  return null;
}

export default useClickOutside;
