import { FC } from 'react';

import styles from './PaginationFlexEnd.module.scss';
import Pagination from 'components/Pagination';

const PaginationFlexEnd: FC = () => {
  return (
    <div className={styles.Wrapper}>
      <Pagination
        className={styles.Pagination}
        totalCount={180}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onPageChange={(page: number) => {}}
        siblingCount={1}
        offset={5}
      />
    </div>
  );
};

export default PaginationFlexEnd;
