import { FC } from 'react';

import styles from './MyPages.module.scss';
import MyPagesHeader from 'modules/landing-dashboard/components/MyPagesHeader';
import BusinessCards from 'modules/landing-dashboard/components/BusinessCards';
import NewPageModal from 'modules/landing-dashboard/components/NewPageModal';
import { useGetPagesQuery } from '../../landing-dashboard.api';

const MyPages: FC = () => {
  const { data, isLoading, isError } = useGetPagesQuery({});
  const landings = data?.body.items ?? [];

  return (
    <>
      <div className={styles.Page}>
        <MyPagesHeader landings={landings} />
        <BusinessCards landings={landings} isLoading={isLoading} isError={isError} />
      </div>
      <NewPageModal />
    </>
  );
};

export default MyPages;
