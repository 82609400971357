import { FC, useMemo } from 'react';

import Button from 'components/Button';
import EmptyPage from 'components/EmptyPage';
import imageImg from 'assets/svg/empty-pages/image.svg';
import useDisclosure from 'hooks/useDisclosure';
import PostCard from 'modules/vk/components/PostCard';
import AutomationPageLayout from 'modules/vk/components/AutomationPageLayout';
import { useGetAllAutomationPostsQuery, useGetFilesFromVkQuery } from 'modules/vk/vk.api';
import PostModalPost from 'modules/vk/components/PostModalPost';
import { isEmpty } from 'lodash-es';
import { useGetAllVkAccounts } from '../../vk.selectors';
import { combinedPosts, getFileIds } from '../../vk.utils';
import isUndefined from 'lodash-es/isUndefined';
import LoadingState from '../../components/LoadingState';

const AutoPosting: FC = () => {
  const { data: postsData, isFetching } = useGetAllAutomationPostsQuery();
  const ids: string[] = useMemo(() => {
    if (postsData) return getFileIds(postsData);
  }, [postsData]) as string[];

  const { data: files, isFetching: areFilesLoading } = useGetFilesFromVkQuery(
    { ids },
    { skip: isUndefined(ids) },
  );
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });
  const vkAccounts = useGetAllVkAccounts();

  const combinedData = useMemo(() => {
    if (postsData && files) return combinedPosts(postsData, files);
  }, [postsData, files]);
  const disabledBtn = !isUndefined(combinedData) && combinedData?.length >= 50;

  if (isEmpty(vkAccounts)) return null;
  if (isFetching || areFilesLoading) return <LoadingState />;

  return (
    <>
      <AutomationPageLayout
        title="Авто-постинг"
        subTitle="Не более 3 постов в сутки"
        addBtnText={'Новый пост'}
        disabled={disabledBtn}
        emptyPage={
          <EmptyPage
            img={<img src={imageImg} width={205} height={205} alt="Image" />}
            title="добавь свой первый пост"
            text="В несколько шагов"
            btnText="Добавить пост"
            onClick={onOpen}
          />
        }
        cards={combinedData?.map(post => (
          <PostCard
            key={post?.id}
            id={post?.id as number}
            img={post?.s3Url}
            date={post?.date as string}
            time={post?.time}
            text={post?.text}
            type="post"
          />
        ))}
        onAdd={onOpen}
        navAddon={
          <Button variant="primary" onClick={onOpen} disabled={disabledBtn}>
            Новый пост
          </Button>
        }
        dontChangeHeaderDirectionOnMobile
      />
      {open && <PostModalPost isOpen={open} onClose={onClose} />}
    </>
  );
};

export default AutoPosting;
