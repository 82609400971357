import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Label.module.scss';

type Props = {
  children: ReactNode;
  htmlFor?: string;
  right?: ReactNode;
  className?: string;
};

const Label: FC<Props> = ({ htmlFor, children, right, className }) => {
  return (
    <div className={styles.Wrapper}>
      <label htmlFor={htmlFor} className={classNames(styles.Label, className)}>
        {children}
      </label>
      <div className={styles.Right}>{right}</div>
    </div>
  );
};

export default Label;
