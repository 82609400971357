import * as yup from 'yup';
import { AsYouType, validatePhoneNumberLength } from 'libphonenumber-js';
import isNil from 'lodash-es/isNil';

export function getPhoneSchema(errorMessage?: string) {
  return yup
    .string()
    .test({
      name: 'phoneStartsWithPlus',
      message: 'Пожалуйста, введите номер телефона в международном формате со знаком (+)',
      test(value = '') {
        return value ? /^[+]/.test(value) : true;
      },
    })
    .test({
      name: 'phoneLength',
      // eslint-disable-next-line @typescript-eslint/default-param-last
      test(value = '', ctx) {
        const length = validatePhoneNumberLength(value.trim());
        switch (length) {
          case 'TOO_LONG':
            return ctx.createError({ message: 'Введенный номер телефона слишком длинный.' });
          case 'TOO_SHORT':
            return ctx.createError({ message: 'Введенный номер телефона слишком короткий.' });

          default:
            return true;
        }
      },
    })
    .test({
      name: 'phoneIsPossible',
      message: errorMessage,
      test(value = '') {
        if (isNil(value) || value === '') {
          return true;
        }

        const asYouType = new AsYouType();
        asYouType.input(value);

        return asYouType.isPossible();
      },
    });
}
