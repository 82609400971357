import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import r from 'constants/routes';

import styles from './PageHeader.module.scss';
import Icon from 'components/Icon';
import { delay } from 'lodash-es';
import Tutorials from '../Tutorials';

type TPageHeader = {
  className?: string;
  title?: string;
  subtitle?: string;
  elem?: ReactNode;
  defaultLink?: string;
  onIconClick?: () => void;
  videoTutorials?: { label: string; url: string }[];
};

const PageHeader: FC<TPageHeader> = ({
  title,
  onIconClick,
  subtitle,
  className,
  elem,
  defaultLink,
  videoTutorials,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const doesAnyHistoryEntryExist = location.key !== 'default';

  const onBackClick = () => {
    if (doesAnyHistoryEntryExist) {
      return navigate(-1);
    } else if (defaultLink) {
      navigate(defaultLink);
    } else {
      navigate(r.account);
    }
  };

  return (
    <header className={classNames(styles.Header, className)}>
      <div className={styles.HeaderLeftPart}>
        <div onClick={onBackClick} className={styles.Link}>
          <Icon
            onClick={() => {
              delay(() => {
                if (onIconClick) {
                  onIconClick();
                }
              }, 400);
            }}
            iconName="arrow-right"
            size={12}
            className={styles.Left}
          />
        </div>
        <div className={styles.Wrapper}>
          <p className={styles.Subtitle}>{subtitle}</p>
          <h1 className={styles.Title}>{title}</h1>
        </div>
        {videoTutorials && <Tutorials tutorials={videoTutorials} />}
      </div>
      <div className={styles.Element}>{elem}</div>
    </header>
  );
};

export default PageHeader;
