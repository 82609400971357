import { FC, useState } from 'react';

import Modal, { OnSubmitDefaultHandler } from 'components/Modal';
import styles from './PublishModal.module.scss';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';
import { setPageLink } from 'modules/my-pages/my-pages.reducer';
import useAppDispatch from 'hooks/useAppDispatch';
import Input from 'components/Input';
import {
  useChangePageComponentsMutation,
  useChangePageParamsMutation,
  usePublishPageMutation,
} from 'modules/landing-dashboard/landing-dashboard.api';
import { validateInputWithoutForm } from 'modules/my-pages/my-pages.helpers';
import toast from 'toasts';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isPublished?: boolean;
  currentLink?: string;
};

const PublishModal: FC<Props> = ({ isOpen, onClose, isPublished, currentLink }) => {
  const [error, setError] = useState();
  const [publishPage] = usePublishPageMutation();
  const [changeParams] = useChangePageParamsMutation();
  const [changeComponents] = useChangePageComponentsMutation();
  const page = useActivePage();
  const dispatch = useAppDispatch();
  const handleInputChange = async (link: string) => {
    const errorMessage = await validateInputWithoutForm(link);
    setError(errorMessage);

    dispatch(setPageLink(link as string));
  };
  if (!page) return null;

  const handlePageChange: OnSubmitDefaultHandler = async e => {
    e?.preventDefault();
    // TODO domain/pageName
    const id = Number(page.id);
    await changeParams({ id, name: page.name, bgColor: page.bgColor });
    await changeComponents({ id, components: page });
    if (currentLink !== page.link) await publishPage({ id, link: page.link as string }).unwrap();
    onClose();
    toast.success(isPublished ? 'Страница сохранена' : 'Страница опубликована');
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isValid={!error}
      btnText={isPublished ? 'Сохранить' : 'Опубликовать'}
      onSubmit={handlePageChange}
    >
      <h1 className={styles.Title}>Опубликовать страницу</h1>
      <p className={styles.linkText}>https://starsonline.cc/{page.link}</p>
      <div className={styles.LinksWrapper}>
        <Input
          label="Ссылка"
          wrapperClassName={styles.Link}
          defaultValue={page?.link}
          onChange={handleInputChange}
          inputWidth={'100%'}
          maxLength={15}
        />
        {error && <p className={styles.errorMsg}>{error}</p>}
      </div>
    </Modal>
  );
};

export default PublishModal;
