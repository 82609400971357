import styles from './FolderSearchScreen.module.scss';
import SearchField from 'components/SearchField';
import FolderSearchCard from '../FolderSearchCard';
import Button from 'components/Button';
import { FC, useRef, useState } from 'react';
import { useGetCurrentFolderData, useParseFolderSearchParams } from '../../../../vk.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { useSearchGroupQuery, useUpdateFolderFromGroupsByIdMutation } from '../../../../vk.api';
import { setVkParseFolderSearchParams } from '../../../../vk.reducer';
import classNames from 'classnames';
import { delay, isEmpty, size } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { TUserVkGroup } from '../../../../vk.types';
import Spinner from '../../../Spinner';
import LoadingState from '../../../LoadingState';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const FolderSearchScreen: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const params = useParseFolderSearchParams();
  const { id } = useParams();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);
  const [selectedGroupsSum, setSelectedGroupsSum] = useState(new Map());
  const [selectedMemberSum, setSelectedMemberSum] = useState<number>(0);
  const { maxMembersCount, sumGroupMembers, folderName, groups } = useGetCurrentFolderData();
  const [addFolder] = useUpdateFolderFromGroupsByIdMutation();

  const { data, isFetching } = useSearchGroupQuery(params);

  const handleScroll = () => {
    setIsFirstLoad(false);
    delay(() => {
      if (!isFetching) {
        if (params.limit && params.offset) {
          dispatch(
            setVkParseFolderSearchParams({
              limit: params?.limit + 12,
              name: params.name,
              offset: params.offset + 1,
            }),
          );
        }
      }
    }, 2000);
  };

  const toggleNumber = ({ id: itemId, membersCount }: TUserVkGroup) => {
    const newMap = new Map(selectedGroupsSum);

    if (newMap.has(itemId)) {
      newMap.delete(itemId);
      setSelectedMemberSum(prev => prev - membersCount);
    } else {
      newMap.set(itemId, membersCount);
      setSelectedMemberSum(prev => prev + membersCount);
    }

    setSelectedGroupsSum(newMap);
  };

  const hasOverflowMembers = sumGroupMembers + selectedMemberSum > 500000;

  const isDisabledButton: boolean = (() => {
    if (size(groups) < 1 && selectedGroupsSum.size === 1) {
      return false;
    } else {
      return hasOverflowMembers;
    }
  })();

  if (!isOpen) return null;

  return (
    <div className={styles.Background}>
      <div
        className={classNames(styles.Wrapper, {
          [styles.Empty]: isEmpty(data?.data.data),
        })}
      >
        <SearchField
          className={styles.Search}
          placeholder={'Поиск '}
          onSearch={searchQuery => {
            if (searchQuery) {
              dispatch(setVkParseFolderSearchParams({ name: searchQuery, limit: 12, offset: 1 }));
              containerRef.current?.scrollTo({
                top: 0,
              });
              setIsFirstLoad(true);
            }
          }}
          debounceDelay={1500}
        />

        {isFetching && isFirstLoad ? (
          <LoadingState />
        ) : (
          <>
            <div className={styles.Info}>
              <p
                className={classNames(styles.InfoPar, {
                  [styles.MoreThanDanger]: isDisabledButton,
                })}
              >
                {isDisabledButton
                  ? 'не больше 500000'
                  : `Пользователей: ${sumGroupMembers + selectedMemberSum} / ${maxMembersCount}`}
              </p>
            </div>
            <div id="scrollableDiv" className={styles.SearchField}>
              <InfiniteScroll
                next={handleScroll}
                loader={
                  <div className={styles.LoadingScroll}>
                    <Spinner />
                  </div>
                }
                dataLength={data?.data.data.length || 12}
                hasMore={data?.data.count !== data?.data.data.length}
                style={{ overflowY: 'hidden' }}
                scrollableTarget="scrollableDiv"
                initialScrollY={4}
                className={styles.SearchField}
              >
                {data?.data.data.map(item => (
                  <FolderSearchCard
                    key={item.id}
                    title={item.name}
                    subTitle={item.activity}
                    subscribers={item.membersCount}
                    onChoose={() => {
                      toggleNumber(item);
                    }}
                    screenName={item.screenName}
                    image={item.photoUrl}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </>
        )}

        <div className={styles.Buttons}>
          <Button
            onClick={() => {
              onClose();
              dispatch(setVkParseFolderSearchParams({ name: '', limit: 12, offset: 1 }));
            }}
            className={styles.Btn}
            size={'l'}
            variant={'tetriary'}
          >
            Отмена
          </Button>
          <Button
            onClick={() => {
              addFolder({
                groupId: id + '',
                folderName,
                addedIds: Array.from(selectedGroupsSum.keys()),
                removedIds: [] as number[],
              });
              onClose();
            }}
            className={styles.SelectBtn}
            size={'l'}
            disabled={isEmpty(data?.data.data) || selectedGroupsSum.size === 0 || isDisabledButton}
          >
            <span className={styles.ButtonSpan}>Добавить выбранные</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FolderSearchScreen;
