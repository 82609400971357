import { FC, useLayoutEffect, useMemo } from 'react';
import Content from '../../components/Content';
import PageHead from '../../components/PageHead';
import { generatePath, useNavigate } from 'react-router-dom';
import r from 'constants/routes';
import { useBotId } from '../../telegram.hooks';
import styles from './GreetingMessages.module.scss';
import useDisclosure from 'hooks/useDisclosure';
import LoadingState from 'components/LoadingState';
import GreetingMessageModal from '../../components/GreetingMessageModal';
import { useGetAdminMessagesQuery } from '../../codegen/telegram.admin.api';
import { useAdminBotName, useAdminMessageChatParams } from '../../telegram.selectors';
import GreetingMessageRow from './GreetingMessageRow';
import GreetingsMessageHead from '../../components/GreetingsMessageHead';
import Pagination from 'components/Pagination';
import useAppDispatch from 'hooks/useAppDispatch';
import Button from 'components/Button';
import { urlOffset } from '../../telegram.utils';
import { isEmpty } from 'lodash-es';
import { setMessagesParams } from '../../codegen/telegram.admin.reducer';
import useWindowSize from 'hooks/useWindowSize';
import EmptyPage from 'components/EmptyPage';
import asset from 'assets/svg/telegram/command.svg';

const GreetingMessages: FC = () => {
  const botId = useBotId();
  const { width } = useWindowSize();
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });
  const params = useAdminMessageChatParams();
  const queryParams = new URLSearchParams(location.search);
  const isParamsMatch = params.offset === +urlOffset(queryParams.get('offset'));
  const { isFetching, data } = useGetAdminMessagesQuery(
    { botId, ...params },
    {
      refetchOnMountOrArgChange: true,
      skip: !isParamsMatch,
    },
  );
  const { isFetching: isSuccessLoading, data: successData } = useGetAdminMessagesQuery(
    { botId, status: true },
    {
      refetchOnMountOrArgChange: true,
      skip: !isParamsMatch,
    },
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const botName = useAdminBotName();

  const isOneStatusToggled = useMemo(() => {
    return !isEmpty(successData?.data);
  }, [successData?.data]);

  const pageChange = (page: number) => {
    let offset;
    queryParams.set('page', `${page}`);

    if (page === 1) {
      offset = 0;
      queryParams.set('offset', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setMessagesParams({ offset }));
    } else {
      offset = (page - 1) * 12;
      queryParams.set('offset', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setMessagesParams({ offset }));
    }
  };

  useLayoutEffect(() => {
    const offset = urlOffset(queryParams.get('offset'));
    dispatch(setMessagesParams({ offset }));
  }, [params.offset]);

  if (isFetching || isSuccessLoading) return <LoadingState />;
  return (
    <>
      <Content>
        <div className={styles.Root}>
          <div className={styles.Inner}>
            <PageHead
              title={'Приветственные сообщения'}
              subtitle={botName || 'Мой первый бот'}
              btnText={'Новое сообщение'}
              defaultLink={generatePath(r.telegram.editAdminBot, { id: botId })}
              onButtonClick={onOpen}
              onIconClick={() => {
                navigate(generatePath(r.telegram.editAdminBot, { id: botId }));
              }}
            />
            {isEmpty(data) ? (
              <EmptyPage
                title={'Создавай свои сообщения'}
                img={<img src={asset} alt={''} />}
                text={'В несколько шагов'}
                onClick={() => onOpen()}
                btnText={'Добавить сообщения'}
              />
            ) : (
              <table className={styles.Table}>
                {isOneStatusToggled && (
                  <thead className={styles.THead}>
                    <GreetingsMessageHead data={successData?.data} />
                  </thead>
                )}
                <tbody className={styles.TBody}>
                  {data?.data?.map(message => {
                    return (
                      <GreetingMessageRow
                        isOneStatusToggled={isOneStatusToggled}
                        key={message.id}
                        {...message}
                      />
                    );
                  })}
                </tbody>
                <Pagination
                  onPageChange={num => pageChange(num)}
                  offset={1}
                  totalCount={Math.ceil(Number(data?.count) / 12)}
                  currPage={Number(queryParams.get('page'))}
                />
              </table>
            )}
          </div>

          {!isEmpty(data) && (
            <>
              {width <= 876 && (
                <div className={styles.MobileCreateMessage}>
                  <Button onClick={onOpen} size={'l'} variant={'magic'}>
                    Новое сообщение
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </Content>
      {open && <GreetingMessageModal isOpen={open} onClose={onClose} />}
    </>
  );
};

export default GreetingMessages;
