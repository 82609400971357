import rootApi from 'config/http';
import { EAppTagType, TResponse } from 'interfaces/api';
import { IUser } from 'modules/account/account.interfaces';
import simpleFormData from 'utils/simpleFormData';
import { AvatarRequest, PasswordRequest, TResetPassword } from './settings.types';

const authApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    changeUser: builder.mutation<
      TResponse<boolean>,
      Partial<Pick<IUser, 'username' | 'email' | 'fullName' | 'social'>>
    >({
      query: body => ({ url: '/users/profile', method: 'PATCH', body }),
      invalidatesTags: [EAppTagType.USER],
    }),
    changeAvatar: builder.mutation<TResponse<string>, AvatarRequest>({
      query: body => ({ url: '/users/avatar', method: 'POST', body: simpleFormData(body) }),
      invalidatesTags: [EAppTagType.USER],
    }),
    deleteAvatar: builder.mutation<TResponse<string>, void>({
      query: () => ({ url: '/users/avatar', method: 'DELETE' }),
      invalidatesTags: [EAppTagType.USER],
    }),
    changePassword: builder.mutation<TResponse<boolean>, PasswordRequest>({
      query: body => ({ url: '/users/change-password', method: 'PATCH', body }),
      invalidatesTags: [EAppTagType.USER],
    }),
    changeFinPassword: builder.mutation<TResponse<boolean>, PasswordRequest>({
      query: body => ({ url: '/users/change-fin-password', method: 'PATCH', body }),
      invalidatesTags: [EAppTagType.USER],
    }),
    resetFinPassword: builder.mutation<TResponse<boolean>, void>({
      query: body => ({ url: '/users/reset-fin-password', method: 'PATCH', body }),
      invalidatesTags: [EAppTagType.USER],
    }),
    confirmResetFinPassword: builder.mutation<TResponse<boolean>, TResetPassword>({
      query: body => ({ url: '/users/reset-fin-password/confirm', method: 'PATCH', body }),
      invalidatesTags: [EAppTagType.USER],
    }),
  }),
  overrideExisting: false,
});

export const {
  useChangeUserMutation,
  useDeleteAvatarMutation,
  useChangeFinPasswordMutation,
  useChangePasswordMutation,
  useChangeAvatarMutation,
  useResetFinPasswordMutation,
  useConfirmResetFinPasswordMutation,
} = authApi;
