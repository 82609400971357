import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TPlansMap, TPlanPeriodId } from './tariff.type';

type TariffState = {
  plan: string;
  selectedSubscriptionPlan?: string | null;
  tariffPlans: TPlansMap;
} & TPlanPeriodId;

const initialState: TariffState = {
  plan: 'month',
  selectedSubscriptionPlan: null,
  tariffPlans: {} as TPlansMap,
  planPeriodId: '',
};

const tariffSlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {
    selectPlan: (state, { payload }: PayloadAction<string>) => {
      state.plan = payload;
    },
    selectSubscriptionPlan: (state, { payload }: PayloadAction<string | null | undefined>) => {
      state.selectedSubscriptionPlan = payload;
    },
    setTariffPlans: (state, { payload }: PayloadAction<TPlansMap>) => {
      state.tariffPlans = payload;
    },
    setPlanPeriodId: (state, { payload }: PayloadAction<string>) => {
      state.planPeriodId = payload;
    },
  },
});

export const { selectPlan, selectSubscriptionPlan, setTariffPlans, setPlanPeriodId } =
  tariffSlice.actions;

export default tariffSlice.reducer;
