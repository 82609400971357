import { ETransactionStatus } from 'modules/finances/finances.types';
import styles from '../TransactionsTable.module.scss';
import Icon from 'components/Icon';
import classNames from 'classnames';

export const TransactionStatuses = {
  [ETransactionStatus.COMPLETED]: {
    name: 'Выполнено',
    elem: (
      <div className={styles.Entry}>
        <Icon iconName="check-circle" size={24} className={styles.Check} />
        <span className={styles.Text}>Выполнено</span>
      </div>
    ),
  },
  [ETransactionStatus.FAILED]: {
    name: 'Ошибка',
    elem: (
      <div className={styles.Entry}>
        <Icon iconName="alert-circle" size={24} />
        <span className={classNames(styles.Text, styles.Error)}>Ошибка</span>
      </div>
    ),
  },
  [ETransactionStatus.IN_PROCESS]: {
    name: 'В обработке',
    elem: (
      <div className={styles.Entry}>
        <Icon iconName="loader" size={24} />
        <span className={styles.Text}>В обработке</span>
      </div>
    ),
  },
  [ETransactionStatus.ON_VERIFICATION]: {
    name: 'На верификации',
    elem: (
      <div className={styles.Entry}>
        <Icon iconName="loader" size={24} />
        <span className={styles.Text}>На верификации</span>
      </div>
    ),
  },
  [ETransactionStatus.CANCELLED]: {
    name: 'Отклонено',
    elem: (
      <div className={styles.Entry}>
        <Icon iconName="alert-circle" size={24} />
        <span className={classNames(styles.Text, styles.Error)}>Отклонено</span>
      </div>
    ),
  },
};
