import { FC, ReactNode } from 'react';
import styles from './FinanceTooltip.module.scss';

type Props = {
  label: ReactNode;
  children: ReactNode;
};

export const FinanceTooltip: FC<Props> = ({ label, children }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Tool}>{children}</div>
      <div className={styles.Tip}>{label}</div>
    </div>
  );
};
