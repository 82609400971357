import { FC, useMemo } from 'react';
import { Reorder, useDragControls } from 'framer-motion';

import styles from './Component.module.scss';
import {
  AnyComponent,
  EComponentType,
  Component as TComponent,
  Button as TButton,
  Text as TText,
  YouTubeVideo as TYouTubeVideo,
  Phone as TPhone,
  Email as TEmail,
  SocialNetwork as TSocialNetwork,
  EditorMode,
  Image as TImage,
  Form as TForm,
} from 'modules/my-pages/my-pages.types';
import Text from '../Text';
import useAppDispatch from 'hooks/useAppDispatch';
import { setActiveComponent } from 'modules/my-pages/my-pages.reducer';
import Button from '../Button';
import YouTubeVideo from '../YouTubeVideo';
import Phone from '../Phone';
import Email from '../Email';
import SocialNetwork from '../SocialNetwork';
import Image from '../Image';
import Form from '../Form';

type Props = {
  component: AnyComponent;
  mode?: EditorMode;
};

const Component: FC<Props> = ({ component, mode = 'edit' }) => {
  const dispatch = useAppDispatch();
  const controls = useDragControls();
  const isEdit = mode === 'edit';
  const data = component.data;
  const { imageStyle } = data as TImage;

  const content = useMemo(() => {
    switch (component.type) {
      case EComponentType.TEXT:
        return <Text component={component as TComponent<TText>} />;
      case EComponentType.BUTTON:
        return <Button component={component as TComponent<TButton>} mode={mode} />;
      case EComponentType.YOUTUBE_VIDEO:
        return <YouTubeVideo component={component as TComponent<TYouTubeVideo>} />;
      case EComponentType.PHONE:
        return <Phone component={component as TComponent<TPhone>} />;
      case EComponentType.EMAIL:
        return <Email component={component as TComponent<TEmail>} />;
      case EComponentType.SOCIAL_NETWORK:
        return <SocialNetwork component={component as TComponent<TSocialNetwork>} mode={mode} />;
      case EComponentType.IMAGE:
        return <Image component={component as TComponent<TImage>} />;
      case EComponentType.FORM:
        return <Form component={component as TComponent<TForm>} mode={mode} />;
      default:
        return 'Invalid component type';
    }
  }, [component, mode]);

  const makeActive = () => {
    dispatch(setActiveComponent(component.hash));
  };

  const handleActive = () => {
    if (isEdit) {
      makeActive();
    }
  };

  return (
    <Reorder.Item
      id={'anchor' in data ? data.anchor : undefined}
      value={component}
      dragListener={false}
      dragControls={controls}
      className={styles.Component}
      tabIndex={0}
      data-is-preview={!isEdit}
      onClick={handleActive}
      data-image-style={imageStyle}
    >
      {isEdit && (
        <div className={styles.DragPad} onPointerDown={e => controls.start(e)}>
          <div className={styles.Dot} />
          <div className={styles.Dot} />
          <div className={styles.Dot} />
        </div>
      )}

      {content}
    </Reorder.Item>
  );
};

export default Component;
