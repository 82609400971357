import { FC } from 'react';
import payeer from 'assets/svg/finances/Payeer.svg';
import WithdrawMethod from '../WithdrawMethod';
import MethodsSection from '../MethodsSection';

const WithdrawMethods: FC = () => {
  return (
    <MethodsSection title="Вывести">
      {/*<WithdrawMethod photo={qiwi} />*/}
      <WithdrawMethod photo={payeer} />
      {/*<WithdrawMethod photo={visa} />*/}
      {/*<WithdrawMethod photo={masterCard} />*/}
    </MethodsSection>
  );
};

export default WithdrawMethods;
