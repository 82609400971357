import { FC, useEffect, useState } from 'react';

import styles from './Photo.module.scss';
import {
  EShape,
  LandingIdType,
  LandingPhoto,
} from 'modules/landing-generator/landing-generator.types';
import EditableElement from '../EditableElement';
import useAppDispatch from 'hooks/useAppDispatch';
import { setLandingPhotoShape } from 'modules/landing-generator/landing-generator.reducer';

const photoStub = 'https://lightwidget.com/wp-content/uploads/local-file-not-found-480x488.png';

type Props = {
  id: LandingIdType;
  photo?: LandingPhoto | null;
};

const Photo: FC<Props> = ({ id, photo }) => {
  const dispatch = useAppDispatch();
  const [showStub, setShowStub] = useState(false);

  const onImgError = () => {
    setShowStub(true);
  };

  useEffect(() => {
    setShowStub(false);
  }, [photo]);

  return !!photo ? (
    <div className={styles.Wrapper}>
      <EditableElement
        menuElements={[
          {
            iconName: 'circle',
            onClick: () => dispatch(setLandingPhotoShape({ id, shape: EShape.CIRCLE })),
          },
          {
            iconName: 'square',
            onClick: () => dispatch(setLandingPhotoShape({ id, shape: EShape.SQUARE })),
          },
          {
            iconName: 'rectangle',
            onClick: () => dispatch(setLandingPhotoShape({ id, shape: EShape.RECTANGLE })),
          },
        ]}
      >
        <img
          className={styles.Photo}
          onError={onImgError}
          src={showStub ? photoStub : photo.url}
          alt="Landing photo"
          data-shape={photo.shape}
        />
      </EditableElement>
    </div>
  ) : null;
};

export default Photo;
