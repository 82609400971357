import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Tool.module.scss';
import Label from 'components/Label';

type Props = {
  label?: string;
  right?: ReactNode;
  children?: ReactNode;
  childrenClassName?: string;
};

const Tool: FC<Props> = ({ label, right, children, childrenClassName }) => {
  return (
    <div className={styles.Wrapper}>
      {label && (
        <Label className={styles.Label} right={right}>
          {label}
        </Label>
      )}

      {children && <div className={classNames(styles.Children, childrenClassName)}>{children}</div>}
    </div>
  );
};

export default Tool;
