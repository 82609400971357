import useTypedSelector from 'hooks/useAppSelector';

export const useSelectedPartners = () =>
  useTypedSelector(state => state.partnershipProgram.selectedPartners);

export const useSelectPartnerFilters = () =>
  useTypedSelector(state => state.partnershipProgram.filters);

export const useSelectPartnerPlan = () =>
  useTypedSelector(state => state.partnershipProgram.filters.plan);

export const useSelectPartnerEndDate = () =>
  useTypedSelector(state => state.partnershipProgram.filters.endDate);

export const useSelectMailModal = () =>
  useTypedSelector(state => state.partnershipProgram.mailModal);
