import { FC } from 'react';

import styles from './EditAdd.module.scss';

type Props = {
  name?: string;
  onClick?: () => void;
};
const EditAdd: FC<Props> = ({ name, onClick }) => {
  return (
    <button className={styles.Button} onClick={onClick}>
      + {name}
    </button>
  );
};

export default EditAdd;
