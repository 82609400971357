import { FC, useEffect, useMemo } from 'react';
import Modal, { CommonModalProps } from 'components/Modal';
import styles from './NewMessageModal.module.scss';
import TextArea from 'components/TextArea';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import { selectStyles } from '../../../vk/components/ParsingBtn/ParsingModal/utils';
import {
  endingSelect,
  intervalOptions,
  periodDurations,
  selectOptions,
  shortWeeks,
  TranslationPeriodTime,
  TranslationRepeatTime,
  WeekDays,
} from 'constants/date-durations';
import { invert, isEmpty, isNull } from 'lodash-es';
import DisplayField from 'components/DisplayField';
import WeekDaysInput from 'components/WeekDaysInput';
import TimeDisplay from 'components/TimeDisplay';
import dayjs from 'dayjs';
import TimeMiniCard from 'components/TimeMiniCard';
import TranslationDatePicker from 'components/TranslationDatePicker';
import { TAdminMessageForm, TFile } from '../../telegram.types';
import isUndefined from 'lodash-es/isUndefined';
import { uniq } from 'lodash';
import AddFiles from '../AddFiles';
import FilesWrapper from '../FilesWrapper';
import {
  useCreateAdminScheduledMessageMutation,
  useGetScheduledAdminMessageByIdQuery,
  useUpdateAdminScheduledMessageMutation,
} from '../../codegen/telegram.admin.api';
import { useBotId } from '../../telegram.hooks';
import { useParams } from 'react-router-dom';
import SmallLoader from '../../../../components/SmallLoader';
import isToday from 'dayjs/plugin/isToday';

type Props = {
  messageId?: string;
} & CommonModalProps;

dayjs.extend(isToday);

const NewMessageModal: FC<Props> = ({ messageId, ...modalProps }) => {
  const { control, reset, register, handleSubmit, watch, setValue } = useForm<TAdminMessageForm>({
    mode: 'onChange',
    defaultValues: {
      dates: [],
      times: [],
      repeatType: TranslationRepeatTime.NOT_REPEAT,
    },
  });

  const botId = useBotId();
  const { chatId } = useParams();

  const [createMessage, { isLoading: isCreating }] = useCreateAdminScheduledMessageMutation();
  const [updateMessage, { isLoading: isUpdating }] = useUpdateAdminScheduledMessageMutation();

  const { data: editData, isFetching } = useGetScheduledAdminMessageByIdQuery(
    {
      botId,
      chatId: String(chatId),
      messageId: String(messageId),
    },
    { skip: isUndefined(chatId) || isUndefined(messageId) },
  );

  const { prepend } = useFieldArray({
    control,
    name: 'files',
  });

  const isLoading = isUpdating || isCreating;
  const values = watch();
  const isValid = Boolean(values.text) || !isEmpty(values.files);

  const currRepeatType = useMemo(() => {
    return selectOptions.find(obj => {
      return obj.value === values?.repeatType;
    })?.value;
  }, [values?.repeatType]);

  const isWeeklyOption = currRepeatType === TranslationRepeatTime.WEEKLY;
  const isOtherOption = currRepeatType === TranslationRepeatTime.OTHER;

  const onSubmit = handleSubmit(async form => {
    const formData = new FormData();

    formData.append('text', form.text);
    for (let i = 0; i < form?.files?.length; i++) {
      formData.append(`files`, form.files[i]);
    }

    for (let i = 0; i < form?.times?.length; i++) {
      if (form.times[i]) {
        formData.append(`times[]`, form.times[i]);
      }
    }

    for (let i = 0; i < form?.weekdays?.length; i++) {
      if (form.weekdays[i]) {
        formData.append(`weeks[]`, shortWeeks[form?.weekdays[i]]);
      }
    }

    if (isWeeklyOption) {
    } else {
      for (let i = 0; i < form?.dates?.length; i++) {
        if (form.dates[i]) {
          formData.append(`dates[]`, form.dates[i]);
        }
      }
    }

    if (chatId) {
      if (messageId) {
        updateMessage({ botId, chatId, messageId, body: formData }).then(() => {
          modalProps.onClose();
          reset();
        });
      } else {
        createMessage({ botId, chatId, body: formData }).then(() => {
          modalProps.onClose();
          reset();
        });
      }
    }
  });

  const createFile = (url?: string, type?: string, fileName?: string) => {
    if (url) {
      const metadata = { type: 'image/' + type };
      const data = new Blob([url], metadata);
      return new File([data], `${fileName}.${type}`, metadata);
    }
  };

  useEffect(() => {
    if (!messageId) {
      reset();
      return;
    }

    const editWeekDays: WeekDays[] = editData?.weeks.map(i => {
      return invert(shortWeeks)[i.week];
    }) as WeekDays[];

    if (messageId) {
      reset({
        text: editData?.text,
        dates: editData?.date.map(i => i.date),
        files: editData?.files.map(item => {
          const cloned = { ...item } as unknown as TFile;
          return createFile(cloned.url, cloned.mime, cloned.name);
        }),
        repeatType: editData?.repeatType,
        weekdays: editWeekDays,
        times: editData?.times,
      });
    }
  }, [messageId, reset, isFetching]);

  return (
    <Modal
      noBtn={isLoading}
      onSubmit={onSubmit}
      isValid={isValid}
      className={styles.Container}
      btnText={'Создать'}
      {...modalProps}
    >
      {isFetching ? (
        <SmallLoader />
      ) : (
        <>
          <h3 className={styles.Title}>Новое сообщение</h3>
          <div className={styles.FormFields}>
            <div>
              <TextArea maxLength={255} label={'Сообщение'} register={register('text')} />
            </div>
            <Controller
              control={control}
              name={'repeatType'}
              render={({ field }) => {
                return (
                  <Select
                    className={styles.Select}
                    classNamePrefix={styles.SelectContainer}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={selectStyles}
                    placeholder={'Повторять:'}
                    options={selectOptions.filter(item => {
                      if (
                        item.value === TranslationRepeatTime.WEEKLY ||
                        item.value === TranslationRepeatTime.NOT_REPEAT
                      ) {
                        return item;
                      }
                    })}
                    onChange={selectedOption => {
                      if (!isNull(selectedOption)) {
                        field.onChange(selectedOption.value);
                        setValue('dates', []);
                        // setValue('weekdays', []);
                      }
                    }}
                  />
                );
              }}
            />
            {isOtherOption && (
              <div className={styles.Interval}>
                <Controller
                  control={control}
                  name={'periodType'}
                  render={({ field }) => {
                    return (
                      <Select
                        className={styles.Select}
                        classNamePrefix={styles.SelectContainer}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        styles={selectStyles}
                        placeholder={'Окончание:'}
                        options={intervalOptions}
                        onChange={selectedOption => {
                          if (!isNull(selectedOption)) {
                            field.onChange(selectedOption.value);
                          }
                        }}
                        defaultValue={
                          field.value
                            ? { label: field.value, value: field.value }
                            : intervalOptions[0]
                        }
                      />
                    );
                  }}
                />

                <DisplayField
                  label={`Количество ${
                    isNull(values?.periodType) || isUndefined(values?.periodType)
                      ? periodDurations[TranslationPeriodTime.DAY]
                      : periodDurations[values?.periodType]
                  }`}
                  value={'2'}
                  isInput
                />
              </div>
            )}
            {isWeeklyOption && (
              <WeekDaysInput
                selectedDays={new Set<WeekDays>(values.weekdays)}
                onDayClick={id => {
                  const selDays = new Set(values.weekdays);
                  if (selDays.has(id)) {
                    selDays.delete(id);
                  } else {
                    selDays.add(id);
                  }
                  setValue('weekdays', Array.from(selDays));
                }}
              />
            )}
            <TranslationDatePicker
              label={''}
              dateTime={''}
              noValue
              isWeeklyOption={isWeeklyOption}
              timesArr={values?.times?.map(i => i)}
              setDay={value => {
                const now = dayjs();
                const timeSet = new Set(values.dates);
                const time = String(dayjs(value).format('YYYY-MM-DD'));
                timeSet.delete(time);
                if (now.isBefore(value) || now.isToday()) {
                  timeSet.add(time);
                  setValue('dates', Array.from(timeSet));
                  setValue('times', []);
                }
              }}
            />
            <div className={styles.Times}>
              {values.dates?.map(item => {
                return (
                  <TimeMiniCard
                    key={item}
                    time={item}
                    onCloseClick={() => {
                      const timesSet = new Set(values.dates);
                      timesSet.delete(item);
                      setValue('dates', Array.from(timesSet));
                    }}
                  />
                );
              })}
            </div>
            <TimeDisplay
              setDay={value => {
                const [hour, minute] = dayjs(value).format('HH:mm').split(':');
                const tempArr = [...values.times];
                tempArr.push(`${hour}:${minute}`);
                setValue('times', uniq(tempArr));
              }}
            />
            <div className={styles.Times}>
              {values.times?.map(item => {
                return (
                  <TimeMiniCard
                    key={item}
                    time={item}
                    onCloseClick={() => {
                      const timesSet = new Set(values.times);
                      timesSet.delete(item);
                      setValue('times', Array.from(timesSet));
                    }}
                  />
                );
              })}
            </div>
            {isOtherOption && (
              <div className={styles.Interval}>
                <Controller
                  control={control}
                  name={'endingType'}
                  render={({ field }) => {
                    return (
                      <Select
                        className={styles.Select}
                        classNamePrefix={styles.SelectContainer}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        styles={selectStyles}
                        placeholder={'Интервал:'}
                        options={endingSelect}
                        onChange={selectedOption => {
                          if (!isNull(selectedOption)) {
                            field.onChange(selectedOption.value);
                          }
                        }}
                      />
                    );
                  }}
                />
              </div>
            )}
          </div>

          <Controller
            control={control}
            render={() => {
              return (
                <AddFiles
                  setFile={() => {}}
                  setFilesList={event => {
                    if (event.target.files)
                      for (let i = 0; i < event.target.files?.length; i++) {
                        prepend(event.target.files[i]);
                      }
                  }}
                />
              );
            }}
            name={'files'}
          />

          <br />

          {!isEmpty(values.files) && (
            <Controller
              control={control}
              render={({ field }) => {
                return (
                  <FilesWrapper
                    files={values.files}
                    onFileClose={(file: File) => {
                      const filtered = values.files.filter((item: File) => item.name !== file.name);
                      field.onChange([...filtered]);
                    }}
                  />
                );
              }}
              name={'files'}
            />
          )}

          {isLoading && <SmallLoader />}
        </>
      )}
    </Modal>
  );
};

export default NewMessageModal;
