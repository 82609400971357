import { FC } from 'react';

import styles from './AddedButtons.module.scss';
import { Landing, LandingButton } from 'modules/landing-generator/landing-generator.types';
import Button from 'components/Button';

type Props = {
  buttons: LandingButton[];
  onEdit: (btnId: Landing['id']) => void;
};

const AddedButtons: FC<Props> = ({ buttons }) => {
  const getButtonName = (btn: LandingButton, num: number): string => {
    const buttonName = btn.modal?.buttonName;
    if (buttonName) {
      return buttonName;
    } else {
      return 'Unnamed group ' + num;
    }
  };

  return (
    <div className={styles.AddedButtons}>
      {buttons.map((btn, index) => (
        <Button
          className={styles.Button}
          key={btn.id}
          // sideIcon={{
          //   iconName: 'edit',
          //   onClick: () => onEdit(btn.id),
          // }}
          type="button"
        >
          {getButtonName(btn, index + 1)}
        </Button>
      ))}
    </div>
  );
};

export default AddedButtons;
