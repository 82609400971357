import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import useFirstRender from 'hooks/useFirstRender';
import useDisclosure from 'hooks/useDisclosure';
import { useSelectUser } from 'modules/account/account.selector';

import NotificationsDrawer from 'components/Header/components/NotificationsDrawer';
import IconButton from 'components/IconButton';

import styles from './BellNotifications.module.scss';

type Props = {
  iconSize?: number;
};

const BellNotifications: FC<Props> = ({ iconSize }) => {
  const firstRender = useFirstRender();
  const { open, onClose, onOpen } = useDisclosure();
  const user = useSelectUser();

  const notsCount = user?.notification || 0;
  const notsString = notsCount > 9 ? '9+' : notsCount.toString();
  const prevNotsCount = useRef(notsCount);
  const hasNots = notsCount > 0;
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    if (hasNots ? (firstRender ? true : notsCount > prevNotsCount.current) : false) {
      setAnimating(true);

      setTimeout(() => {
        setAnimating(false);
      }, 3 * 60 * 1000); // 3 minutes
    }

    if (notsCount <= 0) {
      setAnimating(false);
    }

    prevNotsCount.current = notsCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notsCount]);

  return (
    <>
      <div className={styles.Wrapper}>
        <IconButton
          iconName="bell"
          iconSize={iconSize ?? 22}
          onClick={onOpen}
          className={classNames(styles.Bell, {
            [styles.BellAnimated]: animating,
          })}
        />
        {hasNots && <div className={styles.NotsCount}>{notsString}</div>}
      </div>
      <NotificationsDrawer open={open} onClose={onClose} />
    </>
  );
};

export default BellNotifications;
