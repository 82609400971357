import { FC, useMemo } from 'react';

import styles from './ActionsNav.module.scss';
import Title from 'components/Title';
import { useLocation } from 'react-router-dom';
import r from 'constants/routes';
import { useGetAllVkAccounts } from '../../vk.selectors';
import FormingFoldersBtn from '../FormingFoldersBtn';
import useWindowSize from 'hooks/useWindowSize';
import GroupsSearch from '../GroupsSearch';
import FolderNav from './FolderNav';
import { videoTutorials } from '../../../../constants/videos';
import Tutorials from '../../../../components/Tutorials';

const ActionsNav: FC = () => {
  const { pathname } = useLocation();
  const vkAccounts = useGetAllVkAccounts();
  const { width } = useWindowSize();

  const hasSearchField = r.vk.groupsActions === pathname && vkAccounts.length;

  const setNavBtnHandler = (route: string, hasAccount: boolean) => {
    switch (true) {
      case !hasAccount:
        return null;
      case route === r.vk.groupsActions && hasAccount:
        return <FormingFoldersBtn />;
      default:
        return null;
    }
  };

  const getPathName = (path: string) => {
    const temp = path.split('/');
    temp.pop();
    return temp.join('/');
  };

  const searchField = hasSearchField && width > 1361 && (
    <GroupsSearch className={styles.SearchField} />
  );

  const searchVisibleHandler = () => {
    if (searchField === 0) return null;
    return searchField;
  };

  const videoLink = useMemo(() => {
    if (pathname === r.vk.groupsActions)
      return [
        {
          label: 'Как работать с разделом «группы»',
          url: videoTutorials.workWithGroups,
        },
      ];
    if (pathname === r.vk.index && Boolean(vkAccounts.length))
      return [
        {
          label: 'Как работать с разделом «друзья»',
          url: videoTutorials.workWithFriendsSection,
        },
      ];
    if (pathname === r.vk.automationActions && Boolean(vkAccounts.length)) {
      return [
        {
          label: 'Автоматизация авто-постинг',
          url: videoTutorials.autoPostingAutomation,
        },
        {
          label: 'Автоматизация авто-сториз',
          url: videoTutorials.autoStoriesAutomation,
        },
        {
          label: 'Автоматизация авто-трансляция',
          url: videoTutorials.autoBroadcastingAutomation,
        },
      ];
    }

    return [];
  }, [pathname, vkAccounts.length]);

  if (getPathName(pathname) === getPathName(r.vk.groupsFolder)) return <FolderNav />;

  return (
    <nav className={styles.Nav}>
      <Title className={styles.Title}>ВКонтакте</Title>
      {Boolean(vkAccounts.length) && Boolean(videoLink.length) ? (
        <Tutorials tutorials={videoLink} />
      ) : null}
      {searchVisibleHandler()}
      <div className={styles.Controls}>
        {setNavBtnHandler(pathname, Boolean(vkAccounts.length))}
      </div>
    </nav>
  );
};

export default ActionsNav;
