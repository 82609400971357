import { FC } from 'react';

import styles from './ShareLinkButton.module.scss';
import Icon from 'components/Icon';

type Props = {
  icon: string;
  name: string;
  onClick: () => void;
  disabled?: boolean;
};

const ShareLinkButton: FC<Props> = ({ icon, name, onClick, disabled }) => {
  return (
    <button className={styles.Wrapper} disabled={disabled} onClick={onClick}>
      <div className={styles.IconWrapper}>
        <Icon className={styles.Icon} iconName={icon} size={24} />
      </div>

      <div className={styles.Name}>{name}</div>
    </button>
  );
};

export default ShareLinkButton;
