import { FC, useState } from 'react';

import styles from './Transactions.module.scss';
import TransactionsTable from 'modules/finances/components/TransactionsTable';
import GoBack from 'components/GoBack';
import Title from 'components/Title';
import { useSelectFinancesFilters } from 'modules/finances/finances.selectors';
import { useGetFinancesQuery } from 'modules/finances/finances.api';
import Pagination from 'components/Pagination';
import TransactionsList from 'modules/finances/components/TransactionsList';

const Transactions: FC = () => {
  const [offset, setOffset] = useState(0);
  const filters = useSelectFinancesFilters();
  const { data, isError, isLoading } = useGetFinancesQuery({
    type: filters.type,
    status: filters.status,
    offset: offset,
    limit: 9,
  });
  const { items = [], count } = data || {};

  const pageChange = (page: number) => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset((page - 1) * 9);
    }
  };

  if (isLoading) return null;
  if (isError) return null;

  return (
    <section className={styles.Section}>
      <GoBack className={styles.GoBack} />

      <div className={styles.Content}>
        <Title className={styles.Title}>История платежей</Title>
        <TransactionsTable items={items} />
        <TransactionsList items={items} />
        <Pagination totalCount={count} onPageChange={pageChange} siblingCount={1} offset={9} />
      </div>
    </section>
  );
};

export default Transactions;
