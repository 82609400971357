import { FC } from 'react';
import BlockIcon from '../BlockIcon';

import styles from './FormIcon.module.scss';

const FormIcon: FC = () => {
  return (
    <div className={styles.Wrapper}>
      <BlockIcon width={60} height={10} inactive />
      <BlockIcon width={60} height={10} inactive />
      <BlockIcon width={32} height={10} />
    </div>
  );
};

export default FormIcon;
