import { FC, ReactNode } from 'react';

import styles from './DataEntry.module.scss';
import Icon from 'components/Icon';
import Success from './components/Success';
import Error from './components/Error';
import Range from './components/Range';

type DataEntryExtensions = {
  Success: typeof Success;
  Error: typeof Error;
  Range: typeof Range;
};

type Props = {
  iconName: string;
  name: string;
  nameLabel?: string;
  value: ReactNode;
  nameMaxWidth?: boolean;
};

const DataEntry: FC<Props> & DataEntryExtensions = ({
  iconName,
  name,
  nameLabel,
  value,
  nameMaxWidth,
}) => {
  return (
    <div className={styles.Entry}>
      <Icon iconName={iconName} size={18} className={styles.Icon} />
      <span className={styles.Name} data-max-width={nameMaxWidth}>
        {name} {nameLabel && <span className={styles.NameLabel}>{nameLabel}</span>}
      </span>
      <span className={styles.Value}>{value}</span>
    </div>
  );
};

DataEntry.Success = Success;
DataEntry.Error = Error;
DataEntry.Range = Range;

export default DataEntry;
