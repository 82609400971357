import { FC, ReactNode } from 'react';

import styles from './AddFieldButton.module.scss';
import Button from 'components/Button';

type Props = {
  append: () => void;
  children: ReactNode;
};

const AddFieldButton: FC<Props> = ({ append, children }) => {
  return (
    <Button onClick={append} className={styles.Btn} variant="text" type="button">
      {children}
    </Button>
  );
};

export default AddFieldButton;
