import { ReactElement } from 'react';
import { Listbox as ListboxInner } from '@headlessui/react';
import classNames from 'classnames';

import styles from './Listbox.module.scss';
import Icon from 'components/Icon';

type Props<Key> = {
  value?: Key;
  onChange: (value: Key) => void;
  button?: ReactElement;
  buttonClassName?: string;
  options: Array<{ name: string; value: Key }>;
  rightMargin?: string;
};
const Listbox = <T extends string | number>({
  button,
  buttonClassName,
  options,
  value,
  onChange,
  rightMargin,
}: Props<T>) => {
  return (
    <ListboxInner value={value} onChange={onChange} as={'div'} className={styles.TableFilters}>
      <ListboxInner.Button className={buttonClassName}>{button}</ListboxInner.Button>
      <ListboxInner.Options
        className={styles.TableFilterOptionsWrapper}
        style={{ right: rightMargin }}
      >
        <div className={styles.TableFilterOptions}>
          <ListboxInner.Button className={styles.InnerButton}>
            <Icon iconName="chevron-left" size={10} className={styles.Chevron} />
          </ListboxInner.Button>

          {options.map(option => (
            <ListboxInner.Option
              as="div"
              key={option.value}
              value={option.value}
              className={({ active, selected }) =>
                classNames(styles.Option, {
                  [styles.Active]: active || selected,
                })
              }
            >
              {option.name}
            </ListboxInner.Option>
          ))}
        </div>
      </ListboxInner.Options>
    </ListboxInner>
  );
};

export default Listbox;
