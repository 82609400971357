import { FC } from 'react';
import styles from './WelcomeBotStatisticsMobileTable.module.scss';
import { WelcomeBotStatisticsMobileCard } from './WelcomeBotStatisticsMobileCard';
import { TWelcomeBotContactsStatistic } from '../../telegram.types';

export const WelcomeBotStatisticsMobileTable: FC<{
  statistics?: TWelcomeBotContactsStatistic[];
}> = ({ statistics }) => {
  return (
    <div className={styles.Root}>
      {statistics?.map(statistic => (
        <WelcomeBotStatisticsMobileCard key={statistic.id} {...statistic} />
      ))}
    </div>
  );
};
