import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './Layout.module.scss';

import hands from 'assets/svg/auth/frame.png';
import roundDown from 'assets/svg/auth/roundDown.png';
import roundUp from 'assets/svg/auth/roundUp.png';
import roundLeft from 'assets/svg/auth/roundLeft.png';

const Layout: FC = () => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Content}>
        <Outlet />
      </div>
      <div className={styles.Banner}>
        <img src={hands} alt="star" className={styles.Front} />
        <img className={styles.RoundDown} src={roundDown} alt="" />
        <img className={styles.RoundUp} src={roundUp} alt="" />
        <img className={styles.RoundLeft} src={roundLeft} alt="" />
      </div>
    </div>
  );
};

export default Layout;
