import { RefObject, useEffect } from 'react';

type Props = {
  ref: RefObject<HTMLElement>;
  containerId: string;
  callback: () => void;
};

function useAppearanceInViewport({ ref, containerId, callback }: Props) {
  useEffect(() => {
    const onIntersection: IntersectionObserverCallback = entries => {
      entries
        .filter(entry => entry.isIntersecting)
        .forEach(() => {
          callback();
        });
    };

    const observer = new IntersectionObserver(onIntersection, {
      root: document.getElementById(containerId),
      threshold: 1,
    });

    const element = ref.current;

    if (element) {
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [callback, ref, containerId]);
}

export default useAppearanceInViewport;
