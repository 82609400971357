import rootApi from 'config/http';
import vkAuthApi from 'config/http.vk';
import {
  EAppTagType,
  EAppVKTagType,
  TPaginated,
  TPaginatedRequest,
  TResponse,
} from 'interfaces/api';
import formDataFromObject from 'utils/formDataFromObj';
import {
  EditTranslationRequestBody,
  TAddFolder,
  TAutoPosts,
  TAutoPostsData,
  TAutoSettings,
  TBulkFirstThreeComments,
  TCommentVKSettings,
  TCreateAutomationPostBody,
  TEditCommentsObj,
  TFindCityParsing,
  TFolderParsing,
  TFoldersItem,
  TFoldersResponse,
  TGroupId,
  TNewParsingForm,
  TParseRes,
  TParsingIds,
  TPatchFolderData,
  TResVkAccount,
  TStorage,
  TUpdateSettings,
  TUserGroupParams,
  TUserVkGroupsResponse,
  TVkAccount,
  TVKAutomationSettings,
  TVkFilesRes,
  TVkSettings,
  UploadPhotoResponse,
  VkAccount,
  VkAutomationSwitchesResponse,
  VkCity,
  VkCountry,
  VkCreatePostRequest,
  VkCreateStoryRequest,
  VkEditPostRequest,
  VkEditStoryRequest,
  VkFriendPhotoComment,
  VkFriendsSwitchesResponse,
  VkGroupsSwitchesResponse,
  VkParsedGroups,
  VkParsedGroupsMembers,
  VkParsedGroupsMembersRequest,
  VkRepost,
  VkRepostGroup,
  VkSheduledPost,
  VkStoryType,
  VkUploadStoryRequest,
  VkUploadStoryResponse,
  VkUserPayload,
} from './vk.types';

type AccountId = { vkId: number | string };

const vkApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    // utils media
    uploadPhoto: builder.mutation<TResponse<UploadPhotoResponse>, AccountId & { image: File }>({
      query: ({ vkId, ...body }) => ({
        url: '/vk/photos/upload',
        method: 'POST',
        params: { vkId },
        body: formDataFromObject(body),
        // headers: { 'Content-Type': 'multipart/form-data' },
      }),
    }),
    uploadVideo: builder.mutation<TResponse<string>, AccountId & { video: File }>({
      query: ({ vkId, ...body }) => ({
        url: '/vk/video/upload',
        method: 'POST',
        params: { vkId },
        headers: { 'Content-Type': 'multipart/form-data' },
        body,
      }),
    }),
    // main
    getVkAccounts: builder.query<TResponse<VkAccount[]>, void>({
      query: () => ({
        url: '/vk/accounts',
        method: 'GET',
      }),
    }),
    // friendship
    toggleApproveFriendship: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/approvingFriendship',
        method: 'PATCH',
        params,
      }),
    }),
    // auto-posting
    getScheduledPosts: builder.query<
      TResponse<TPaginated<VkSheduledPost[]>>,
      AccountId & TPaginatedRequest
    >({
      query: params => ({
        url: '/vk/getScheduledPosts',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VKPosts],
    }),
    getScheduledPost: builder.query<
      TResponse<VkSheduledPost>,
      AccountId & { postId: number | string }
    >({
      query: params => ({
        url: '/vk/getScheduledPost',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VKPosts],
    }),
    createPost: builder.mutation<TResponse<string>, VkCreatePostRequest>({
      query: ({ vkId, ...body }) => ({
        url: '/vk/wall/post',
        method: 'POST',
        params: { vkId },
        body,
      }),
      invalidatesTags: [EAppTagType.VKPosts],
    }),
    editPost: builder.mutation<TResponse<string>, VkEditPostRequest>({
      query: ({ vkId, ...body }) => ({
        url: '/vk/editScheduledPosts',
        method: 'PATCH',
        params: { vkId },
        body,
      }),
      invalidatesTags: [EAppTagType.VKPosts],
    }),
    deletePost: builder.mutation<TResponse<string>, AccountId & { postId: number | string }>({
      query: params => ({
        url: '/vk/deleteScheduledPost',
        method: 'DELETE',
        params,
      }),
      invalidatesTags: [EAppTagType.VKPosts],
    }),
    // stories
    getStories: builder.query<TResponse<TPaginated<VkStoryType[]>>, AccountId>({
      query: params => ({
        url: '/vk/stories',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VKStories],
    }),
    getStoryDetails: builder.query<
      TResponse<VkStoryType>,
      AccountId & { storiesId: string | number }
    >({
      query: ({ storiesId, ...params }) => ({
        url: `/vk/stories/${storiesId}`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VKStories],
    }),
    editStory: builder.mutation<TResponse<string>, VkEditStoryRequest>({
      query: ({ vkId, ...body }) => ({
        url: '/vk/stories/edit',
        method: 'PATCH',
        params: { vkId },
        body,
      }),
      invalidatesTags: [EAppTagType.VKStories],
    }),
    uploadStory: builder.mutation<TResponse<VkUploadStoryResponse>, VkUploadStoryRequest>({
      query: ({ vkId, ...body }) => ({
        url: '/vk/stories/upload',
        method: 'POST',
        params: { vkId },
        body: formDataFromObject(body),
      }),
    }),
    createStory: builder.mutation<TResponse<string>, VkCreateStoryRequest>({
      query: ({ vkId, ...body }) => ({
        url: '/vk/stories/save',
        method: 'POST',
        params: { vkId },
        body: [body],
      }),
      invalidatesTags: [EAppTagType.VKStories],
    }),
    deleteStory: builder.mutation<TResponse<string>, AccountId & { storiesId: string | number }>({
      query: ({ storiesId, ...params }) => ({
        url: `/vk/stories/${storiesId}`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: [EAppTagType.VKStories],
    }),
    // reposts
    getReposts: builder.query<TResponse<VkRepost[]>, AccountId>({
      query: params => ({
        url: '/vk/getAllRepostedGroups',
        method: 'GET',
        params,
      }),
    }),
    // reposts-groups
    getRepostsGroup: builder.query<TResponse<VkRepostGroup>, AccountId>({
      query: params => ({
        url: '/vk/getGroupComments',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VkGroupComments],
    }),
    changeRepostsGroup: builder.mutation<TResponse<string>, AccountId & Partial<VkRepostGroup>>({
      query: ({ vkId, ...body }) => ({
        url: '/vk/patchGroupComments',
        method: 'PATCH',
        params: { vkId },
        body,
      }),
      invalidatesTags: [EAppTagType.VkGroupComments],
    }),
    // friend-photo-auto-comments
    getFriendPhotoComments: builder.query<TResponse<VkFriendPhotoComment>, AccountId>({
      query: params => ({
        url: '/vk/getFriendComments',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VkFriendsPhotos],
    }),
    changeFriendPhotoComments: builder.mutation<
      TResponse<string>,
      AccountId & Partial<VkFriendPhotoComment>
    >({
      query: ({ vkId, ...body }) => ({
        url: '/vk/patchFriendComments',
        method: 'PATCH',
        params: { vkId },
        body,
      }),
      invalidatesTags: [EAppTagType.VkFriendsPhotos],
    }),
    // parsing
    getParsingGroups: builder.query<TResponse<TPaginated<VkParsedGroups[]>>, AccountId>({
      query: params => ({
        url: '/vk/parsedGroups',
        method: 'GET',
        params,
      }),
    }),
    getParsingGroupsMembers: builder.query<
      TResponse<TPaginated<VkParsedGroupsMembers[]>>,
      VkParsedGroupsMembersRequest & TPaginatedRequest
    >({
      query: params => ({
        url: '/vk/parsedGroups/members',
        method: 'GET',
        params,
      }),
    }),
    // utils
    getCountries: builder.query<TResponse<VkCountry[]>, AccountId>({
      query: params => ({
        url: '/vk/parsedGroups/members',
        method: 'GET',
        params,
      }),
    }),
    getCities: builder.query<TResponse<VkCity[]>, AccountId & { countryId: number; q?: string }>({
      query: params => ({
        url: '/vk/parsedGroups/members',
        method: 'GET',
        params,
      }),
    }),
    // thematic-groups
    subscribeToNewGroup: builder.query<TResponse<string>, AccountId & { domain: string }>({
      query: params => ({
        url: '/vk/parsedGroups/members',
        method: 'GET',
        params,
      }),
    }),
    showSimilarGroups: builder.query<TResponse<string>, AccountId & { url: string }>({
      query: params => ({
        url: '/vk/parsedGroups/members',
        method: 'GET',
        params,
      }),
    }),
    // switches-get
    getVkGroupsPageSwitches: builder.query<TResponse<VkGroupsSwitchesResponse>, AccountId>({
      query: params => ({
        url: '/vk/vkGroupsPage',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VkGroupsPage],
    }),
    getVkFriendsPageSwitches: builder.query<TResponse<VkFriendsSwitchesResponse>, AccountId>({
      query: params => ({
        url: '/vk/vkFriendsPage',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VkFriendsPage],
    }),
    getVkAutomationPageSwitches: builder.query<TResponse<VkAutomationSwitchesResponse>, AccountId>({
      query: params => ({
        url: '/vk/vkAutomationPage',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.VkAutomationPage],
    }),
    // switches
    toggleLikingPostsInGroups: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/likingPostsInGroups',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkGroupsPage],
    }),
    toggleCommentingNewPostsInGroups: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/commentingNewPostsInGroups',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkGroupsPage],
    }),
    toggleMailing: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/mailing',
        method: 'PATCH',
        params,
      }),
    }),
    toggleLikingPhotosWhenApproveFriendship: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/likingPhotosWhenApproveFriendship',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkFriendsPage],
    }),
    toggleLikingNewPhotosOfFriends: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/likingNewPhotosOfFriends',
        method: 'PATCH',
        params,
      }),
    }),
    toggleCommentingPostsWhenSubscribeGroup: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/commentingPostsWhenSubscribeGroup',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkFriendsPage],
    }),
    toggleCommentingPhotosWhenApproveFriendship: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/commentingPhotosWhenApproveFriendship',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkFriendsPage],
    }),
    toggleChangeRepostStatus: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/changeRepostStatus',
        method: 'PATCH',
        params,
      }),
    }),
    toggleBaseParsing: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/baseParsing',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkAutomationPage],
    }),
    toggleAutoPostingStories: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/autoPostingStories',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkAutomationPage],
    }),
    toggleAutoPostingPosts: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/autoPostingPosts',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkAutomationPage],
    }),
    toggleApprovingFriendship: builder.mutation<TResponse<string>, AccountId>({
      query: params => ({
        url: '/vk/approvingFriendship',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: [EAppTagType.VkGroupsPage, EAppTagType.VkFriendsPage],
    }),
    // utils
    getVkAuthUrl: builder.mutation<string, void>({
      query: () => ({
        url: '/vk/addVkAccount',
        method: 'GET',
        responseHandler: response => response.text(),
      }),
    }),
    postVkAccessToken: builder.mutation<string, { id: string | number; access_token: string }>({
      query: body => ({
        url: '/vk/credentials',
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetVkAccountsQuery,
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetFriendPhotoCommentsQuery,
  useGetParsingGroupsMembersQuery,
  useGetParsingGroupsQuery,
  useGetRepostsGroupQuery,
  useGetRepostsQuery,
  // auto posting
  useGetScheduledPostsQuery,
  useGetScheduledPostQuery,
  useCreatePostMutation,
  useEditPostMutation,
  useDeletePostMutation,
  // auto stories
  useGetStoriesQuery,
  useGetStoryDetailsQuery,
  useCreateStoryMutation,
  useUploadStoryMutation,
  useEditStoryMutation,
  // groups
  useShowSimilarGroupsQuery,
  useSubscribeToNewGroupQuery,
  useChangeFriendPhotoCommentsMutation,
  useChangeRepostsGroupMutation,
  useDeleteStoryMutation,
  useUploadPhotoMutation,
  useUploadVideoMutation,
  // switches-get
  useGetVkAutomationPageSwitchesQuery,
  useGetVkFriendsPageSwitchesQuery,
  useGetVkGroupsPageSwitchesQuery,
  // switches
  useToggleApproveFriendshipMutation,
  useToggleMailingMutation,
  useToggleLikingPostsInGroupsMutation,
  useToggleLikingPhotosWhenApproveFriendshipMutation,
  useToggleLikingNewPhotosOfFriendsMutation,
  useToggleCommentingPostsWhenSubscribeGroupMutation,
  useToggleCommentingPhotosWhenApproveFriendshipMutation,
  useToggleCommentingNewPostsInGroupsMutation,
  useToggleChangeRepostStatusMutation,
  useToggleBaseParsingMutation,
  useToggleAutoPostingStoriesMutation,
  useToggleAutoPostingPostsMutation,
  useToggleApprovingFriendshipMutation,
  // utils
  useGetVkAuthUrlMutation,
  usePostVkAccessTokenMutation,
} = vkApi;

const vkAuth = vkAuthApi.injectEndpoints({
  endpoints: builder => ({
    vkAuthCallback: builder.mutation<
      TResVkAccount<TVkAccount>,
      { token: string; userId: string; timezone: string }
    >({
      query: ({ token, userId, timezone }) => ({
        url: `/vk/account/authorize/callback?token=${token}&userId=${userId}&timezone=${timezone}`,
        method: 'GET',
      }),
      invalidatesTags: [EAppVKTagType.VKACCOUNT, EAppVKTagType.VK_SETTINGS],
    }),

    getVkAccount: builder.query<TResVkAccount<TVkAccount>, void>({
      query: () => ({
        url: `/vk/account`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VKACCOUNT],
    }),

    vkAccountAuthorize: builder.mutation<string, void>({
      query: () => ({
        url: `/vk/account/authorize`,
        method: 'GET',
      }),
    }),

    deleteVkAccount: builder.mutation<TResVkAccount<TVkAccount>, { vkId: string }>({
      query: ({ vkId }) => ({
        url: `/vk/account/${vkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppVKTagType.VKACCOUNT],
    }),

    getVkUserSetting: builder.query<{ settings: TVkSettings[] }, void>({
      query: () => ({
        url: `/vk/settings/friend`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_SETTINGS],
    }),

    updateVkUserSetting: builder.mutation<TUpdateSettings, TUpdateSettings>({
      query: body => ({
        url: `/vk/settings/friend`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_SETTINGS],
    }),

    getListComment: builder.query<TResVkAccount<TCommentVKSettings[]>, void>({
      query: () => ({
        url: `/vk/friend/comment`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_SETTINGS_COMMENTS],
    }),

    getGroupComment: builder.query<TResVkAccount<TCommentVKSettings[]>, TFolderParsing>({
      query: params => ({
        url: `/vk/friend/comment`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppVKTagType.VK_SETTINGS_COMMENTS],
    }),

    createComment: builder.mutation<TResVkAccount<TCommentVKSettings>, { comment: string }>({
      query: body => ({
        url: `/vk/friend/comment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_SETTINGS_COMMENTS],
    }),

    createGroupComment: builder.mutation<
      TResVkAccount<TCommentVKSettings>,
      { comment: string; data: TFolderParsing }
    >({
      query: body => ({
        url: `/vk/friend/comment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_SETTINGS_COMMENTS],
    }),

    createFirstThreeComments: builder.mutation<
      TResVkAccount<TCommentVKSettings>,
      TBulkFirstThreeComments
    >({
      query: body => ({
        url: `/vk/friend/comment/bulk`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_SETTINGS_COMMENTS],
    }),

    editCommentList: builder.mutation<TResVkAccount<TCommentVKSettings>, TEditCommentsObj>({
      query: body => ({
        url: `/vk/friend/comment`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_SETTINGS_COMMENTS],
    }),

    deleteCommentById: builder.mutation<TResVkAccount<TCommentVKSettings>, { commentId: string }>({
      query: ({ commentId }) => ({
        url: `/vk/friend/comment/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppVKTagType.VK_SETTINGS_COMMENTS],
    }),

    deleteGroupCommentById: builder.mutation<
      TResVkAccount<TCommentVKSettings>,
      { commentId: string } & TFolderParsing
    >({
      query: ({ commentId, ...params }) => ({
        url: `/vk/friend/comment/${commentId}`,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: [EAppVKTagType.VK_SETTINGS_COMMENTS],
    }),

    searchGroup: builder.query<TResVkAccount<TUserVkGroupsResponse>, TUserGroupParams>({
      query: params => ({
        url: `/vk/group/search`,
        method: 'GET',
        params,
      }),
    }),

    addFolderGroup: builder.mutation<TResVkAccount<TFoldersResponse>, TAddFolder>({
      query: body => ({
        url: `/vk/group/folder`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_FOLDER_GROUPS],
    }),

    getAllFolders: builder.query<TResVkAccount<TFoldersItem[]>, void>({
      query: () => ({
        url: `/vk/group/folder`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_FOLDER_GROUPS],
    }),

    deleteFolderById: builder.mutation<TResVkAccount<TFoldersItem[]>, TGroupId>({
      query: ({ groupId }) => ({
        url: `/vk/group/folder/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppVKTagType.VK_FOLDER_GROUPS],
    }),

    getFolderGroupsById: builder.query<TResVkAccount<TFoldersResponse>, TGroupId>({
      query: ({ groupId }) => ({
        url: `/vk/group/folder/${groupId}`,
        method: 'GET',
      }),
      providesTags: [{ type: EAppVKTagType.VK_FOLDER, id: '*' }],
    }),

    updateFolderFromGroupsById: builder.mutation<TResVkAccount<TFoldersResponse>, TPatchFolderData>(
      {
        query: ({ groupId, ...body }) => ({
          url: `/vk/group/folder/${groupId}`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: [EAppVKTagType.VK_FOLDER],
      },
    ),

    toggleLikeCommentFolder: builder.mutation<TResVkAccount<void>, TGroupId>({
      query: ({ groupId }) => ({
        url: `/vk/group/folder/${groupId}/like-comment/toggle`,
        method: 'PATCH',
      }),
      invalidatesTags: [EAppVKTagType.VK_FOLDER],
    }),

    findCityFormParsingModal: builder.query<
      TResVkAccount<{ id: number; name: string }[]>,
      TFindCityParsing
    >({
      query: params => ({
        url: `/vk/group/city`,
        method: 'GET',
        params,
      }),
    }),

    newParsing: builder.mutation<TResVkAccount<void>, TGroupId & TNewParsingForm>({
      query: ({ groupId, ...body }) => ({
        url: `/vk/group/folder/${groupId}/parsing`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_ALL_PARSED],
    }),

    getAllParsed: builder.query<TResVkAccount<TParseRes>, TGroupId>({
      query: ({ groupId }) => ({
        url: `/vk/group/folder/${groupId}/parsing`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_ALL_PARSED],
    }),

    deleteParsing: builder.mutation<TResVkAccount<void>, TParsingIds>({
      query: ({ groupId, parsingId }) => ({
        url: `/vk/group/folder/${groupId}/parsing/${parsingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppVKTagType.VK_ALL_PARSED],
    }),

    stopParsing: builder.mutation<TResVkAccount<void>, TParsingIds>({
      query: ({ groupId, parsingId }) => ({
        url: `/vk/group/folder/${groupId}/parsing/${parsingId}/stop`,
        method: 'PATCH',
      }),
      invalidatesTags: [EAppVKTagType.VK_ALL_PARSED],
    }),

    unLoadCsvFile: builder.mutation<TResVkAccount<Blob>, TParsingIds>({
      query: ({ groupId, parsingId }) => ({
        url: `/vk/group/folder/${groupId}/parsing/${parsingId}/unload/file/csv`,
        method: 'GET',
        responseHandler: response => response.blob(),
      }),
    }),

    getParsingAutomationSettings: builder.query<TResVkAccount<TAutoSettings[]>, TParsingIds>({
      query: ({ groupId, parsingId }) => ({
        url: `/vk/group/folder/${groupId}/parsing/${parsingId}/automation/settings`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_GROUP_SETTINGS_AUTO],
    }),

    updateParsingAutomationSettings: builder.mutation<
      TResVkAccount<TAutoSettings[]>,
      TParsingIds & { data: Pick<TAutoSettings, 'id' | 'count' | 'isEnabled'>[] }
    >({
      query: ({ groupId, parsingId, ...body }) => ({
        url: `/vk/group/folder/${groupId}/parsing/${parsingId}/automation/settings`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_GROUP_SETTINGS_AUTO],
    }),

    getAutomationSettings: builder.query<{ settings: TVKAutomationSettings[] }, void>({
      query: () => ({
        url: `/vk/settings/automation`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_ALL_AUTOMATION_SETTING],
    }),
    updateAutomationSettings: builder.mutation<
      { settings: TVKAutomationSettings[] },
      TUpdateSettings
    >({
      query: body => ({
        url: `/vk/settings/automation`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_ALL_AUTOMATION_SETTING],
    }),

    // upload files to vk
    uploadFileIntoVk: builder.mutation<{ fileId: string }, FormData>({
      query: formData => ({
        url: `/vk/storage/file/upload`,
        method: 'POST',
        body: formData,
      }),
    }),

    getFilesFromVk: builder.query<TResVkAccount<TVkFilesRes[]>, { ids: string[] }>({
      query: body => ({
        url: `/vk/storage/file/get`,
        method: 'POST',
        body,
      }),
      providesTags: [EAppVKTagType.VK_ALL_AUTOMATION_SETTING],
    }),

    // auto-post

    createAutomationPost: builder.mutation<TResVkAccount<void>, TCreateAutomationPostBody>({
      query: body => ({
        url: `/vk/automation/post`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_AUTO_POSTING_POSTS, EAppVKTagType.VK_AUTOMATION_STORAGE],
    }),

    updateAutomationPost: builder.mutation<
      TResVkAccount<void>,
      TCreateAutomationPostBody & { postId: string | number }
    >({
      query: ({ postId, ...body }) => ({
        url: `/vk/automation/post/${postId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_AUTO_POSTING_POSTS, EAppVKTagType.VK_AUTOMATION_STORAGE],
    }),

    deleteAutomationPost: builder.mutation<TResVkAccount<void>, { postId: string | number }>({
      query: ({ postId }) => ({
        url: `/vk/automation/post/${postId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppVKTagType.VK_AUTO_POSTING_POSTS, EAppVKTagType.VK_AUTOMATION_STORAGE],
    }),

    getVkAutomationPost: builder.query<TResVkAccount<TAutoPosts>, { postId: string }>({
      query: ({ postId }) => ({
        url: `/vk/automation/post/${postId}`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_AUTO_POSTING_POSTS],
    }),

    getAllAutomationPosts: builder.query<TAutoPostsData, void>({
      query: () => ({
        url: `/vk/automation/post`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_AUTO_POSTING_POSTS],
    }),

    // auto-stories

    getAllAutoStories: builder.query<TAutoPostsData, void>({
      query: () => ({
        url: `/vk/automation/story`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_AUTO_POSTING_STORIES],
    }),

    getVkAutomationStory: builder.query<TResVkAccount<TAutoPosts>, { storyId: string }>({
      query: ({ storyId }) => ({
        url: `/vk/automation/story/${storyId}`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_AUTO_POSTING_STORIES],
    }),

    createAutomationStory: builder.mutation<TResVkAccount<void>, TCreateAutomationPostBody>({
      query: body => ({
        url: `/vk/automation/story`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_AUTO_POSTING_STORIES, EAppVKTagType.VK_AUTOMATION_STORAGE],
    }),

    deleteAutomationStory: builder.mutation<TResVkAccount<void>, { storyId: string | number }>({
      query: ({ storyId }) => ({
        url: `/vk/automation/story/${storyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppVKTagType.VK_AUTO_POSTING_STORIES, EAppVKTagType.VK_AUTOMATION_STORAGE],
    }),

    updateAutomationStory: builder.mutation<
      TResVkAccount<void>,
      TCreateAutomationPostBody & { storyId: string | number }
    >({
      query: ({ storyId, ...body }) => ({
        url: `/vk/automation/story/${storyId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VK_AUTO_POSTING_STORIES, EAppVKTagType.VK_AUTOMATION_STORAGE],
    }),

    // auto translations

    getAllAutoTranslations: builder.query<TResVkAccount<TAutoPostsData>, void>({
      query: () => ({
        url: `/vk/automation/translation`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_AUTO_POSTING_TRANSLATIONS],
    }),

    getAutoTranslationById: builder.query<
      TResVkAccount<EditTranslationRequestBody>,
      { streamId: string }
    >({
      query: ({ streamId }) => ({
        url: `/vk/automation/translation/${streamId}`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_AUTO_POSTING_TRANSLATIONS],
    }),

    createAutoTranslations: builder.mutation<TResVkAccount<void>, EditTranslationRequestBody>({
      query: body => ({
        url: `/vk/automation/translation`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        EAppVKTagType.VK_AUTO_POSTING_TRANSLATIONS,
        EAppVKTagType.VK_AUTOMATION_STORAGE,
      ],
    }),

    deleteAutoTranslation: builder.mutation<TResVkAccount<void>, { stramId: string | number }>({
      query: ({ stramId }) => ({
        url: `/vk/automation/translation/${stramId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        EAppVKTagType.VK_AUTO_POSTING_TRANSLATIONS,
        EAppVKTagType.VK_AUTOMATION_STORAGE,
      ],
    }),

    updateAutoTranslation: builder.mutation<
      TResVkAccount<void>,
      EditTranslationRequestBody & { streamId: string }
    >({
      query: ({ streamId, ...body }) => ({
        url: `/vk/automation/translation/${streamId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        EAppVKTagType.VK_AUTO_POSTING_TRANSLATIONS,
        EAppVKTagType.VK_AUTOMATION_STORAGE,
      ],
    }),

    getAutomationStorageInfo: builder.query<TResVkAccount<TStorage>, void>({
      query: () => ({
        url: `/vk/storage/info`,
        method: 'GET',
      }),
      providesTags: [EAppVKTagType.VK_AUTOMATION_STORAGE],
    }),

    clearStorage: builder.mutation<TResVkAccount<TStorage>, unknown>({
      query: () => ({
        url: `/vk/storage`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppVKTagType.VK_AUTOMATION_STORAGE],
    }),

    vkUserAuthAuthorize: builder.mutation<
      TResVkAccount<TStorage>,
      { payload: VkUserPayload; state: string; timezone: string }
    >({
      query: ({ ...body }) => ({
        url: `/vk/account/authorize`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppVKTagType.VKACCOUNT, EAppVKTagType.VK_SETTINGS],
    }),
  }),
});

export const {
  useVkAuthCallbackMutation,
  useVkUserAuthAuthorizeMutation,
  useVkAccountAuthorizeMutation,
  useGetVkAccountQuery,
  useDeleteVkAccountMutation,
  useGetListCommentQuery,
  useGetVkUserSettingQuery,
  useUpdateVkUserSettingMutation,
  useCreateCommentMutation,
  useCreateGroupCommentMutation,
  useCreateFirstThreeCommentsMutation,
  useEditCommentListMutation,
  useDeleteCommentByIdMutation,
  useDeleteGroupCommentByIdMutation,
  useSearchGroupQuery,
  useAddFolderGroupMutation,
  useDeleteFolderByIdMutation,
  useGetAllFoldersQuery,
  useGetFolderGroupsByIdQuery,
  useUpdateFolderFromGroupsByIdMutation,
  useToggleLikeCommentFolderMutation,
  useFindCityFormParsingModalQuery,
  useNewParsingMutation,
  useGetAllParsedQuery,
  useDeleteParsingMutation,
  useStopParsingMutation,
  useUnLoadCsvFileMutation,
  useGetAutomationSettingsQuery,
  useUpdateAutomationSettingsMutation,
  useGetParsingAutomationSettingsQuery,
  useUpdateParsingAutomationSettingsMutation,
  useUploadFileIntoVkMutation,
  useCreateAutomationPostMutation,
  useUpdateAutomationPostMutation,
  useGetVkAutomationPostQuery,
  useGetAllAutomationPostsQuery,
  useGetFilesFromVkQuery,
  useDeleteAutomationPostMutation,
  useGetAllAutoStoriesQuery,
  useGetVkAutomationStoryQuery,
  useCreateAutomationStoryMutation,
  useDeleteAutomationStoryMutation,
  useUpdateAutomationStoryMutation,
  useGetAllAutoTranslationsQuery,
  useGetAutoTranslationByIdQuery,
  useCreateAutoTranslationsMutation,
  useDeleteAutoTranslationMutation,
  useUpdateAutoTranslationMutation,
  useGetAutomationStorageInfoQuery,
  useGetGroupCommentQuery,
  useClearStorageMutation,
} = vkAuth;

export { vkAuth };

export default vkApi;
