import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Tariff from './pages/Tariff';

function TariffRoutes(): JSX.Element {
  return (
    <>
      <Route path={r.tariff} element={<Tariff />} />
    </>
  );
}

export default TariffRoutes;
