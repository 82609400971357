import { FC } from 'react';

import styles from './BotInfo.module.scss';
import GoBack from 'components/GoBack';
import BotStatCard from 'modules/telegram/components/BotStatCard';
import BotStatsSection from 'modules/telegram/components/BotStatsSection';
import Title from 'components/Title';

const BotInfo: FC = () => {
  return (
    <div className={styles.Page}>
      <GoBack />

      <section className={styles.Content}>
        <Title className={styles.Title}>Информация о боте</Title>
        <BotStatCard avatar={null} username="@loremipsum" name="Lorem Ipsum" />
        <BotStatsSection />
      </section>
    </div>
  );
};

export default BotInfo;
