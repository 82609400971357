import { Route } from 'react-router-dom';

import r from 'constants/routes';
import PartnershipProgram from './pages/PartnershipProgram';
import PartnershipProgramInfo from './pages/PartnershipProgramInfo';

const PartnershipProgramRoutes = (): JSX.Element => {
  return (
    <>
      <Route path={r.partnershipProgram.index} element={<PartnershipProgram />} />
      <Route path={r.partnershipProgram.info} element={<PartnershipProgramInfo />} />
    </>
  );
};

export default PartnershipProgramRoutes;
