import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate } from 'react-router-dom';

import { EPlanName } from 'modules/account/account.interfaces';
import r from 'constants/routes';
import getWordByAmount from 'utils/getWordByAmount';
import useTypedSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/account/account.selector';
import { useGetPlanShotInfoQuery, useStartTrialMutation } from 'modules/finances/finances.api';
import getTariffPlanDatesInfo from 'utils/tariff-plan-dates-info';

import Button from 'components/Button';

import styles from './TariffEndNotice.module.scss';
import {
  useGetAllPlansWithPriceQuery,
  useGetUserPlanQuery,
  useUpdateUserPlanMutation,
} from 'modules/tariff/tariff.api';
import confirm from 'utils/confirm';
import ConfirmModal from '../ConfirmModal';
import formatNumber from 'utils/formatNumber';
import env from 'config/env';
import { TError, TPeriodDto, TPlansMap } from 'modules/tariff/tariff.type';
import ResultModal from '../ResultModal';
import useDisclosure from 'hooks/useDisclosure';
import { makeTariffMapByName } from '../../modules/tariff/tariff.utils';
import Spinner from '../Spinner';

dayjs.extend(customParseFormat);

const BuyBtn = () => {
  const navigate = useNavigate();
  const onBuy = () => navigate(r.tariff);

  return (
    <Button variant="tetriary" size="s" onClick={onBuy} className={styles.Btn}>
      Приобрести тариф
    </Button>
  );
};

const DemoPeriod = () => {
  const [startTrial] = useStartTrialMutation();
  const { data, isLoading } = useGetPlanShotInfoQuery({});

  const day = useMemo(() => {
    if (data?.success) {
      return data.body;
    }
    return 0;
  }, [data?.success]);

  if (isLoading) return <Spinner />;

  return (
    <div className={styles.Period}>
      <p className={styles.Text} data-big-margin="true">
        Пробный период на {day} {getWordByAmount(day, ['день', 'дня', 'дней'])}
      </p>
      <Button variant="tetriary" size="s" className={styles.Btn} onClick={() => startTrial()}>
        Активировать
      </Button>
    </div>
  );
};

const TrialPeriod: FC<{ days: number }> = ({ days }) => {
  return (
    <div className={styles.Period}>
      <p className={styles.Text}>
        До конца пробного периода {getWordByAmount(days, ['остался', 'осталось', 'осталось'])}{' '}
        {days} {getWordByAmount(days, ['день', 'дня', 'дней'])}
      </p>
      <BuyBtn />
    </div>
  );
};

const TariffPeriod: FC<{ days: number }> = ({ days }) => {
  const navigate = useNavigate();
  const [updatePlan, { isSuccess, isLoading }] = useUpdateUserPlanMutation();
  const { data: userPlanData, isLoading: isUserDataLoading } = useGetUserPlanQuery();
  const { data: tariffsData, isSuccess: isTariffSuccess } = useGetAllPlansWithPriceQuery();

  const {
    open: successOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure({ blockScroll: true });

  const getUserPeriodId = () => {
    let tariffsMap: TPlansMap;
    if (Array.isArray(tariffsData) && isTariffSuccess) {
      tariffsMap = makeTariffMapByName(tariffsData);
      return tariffsMap[userPlanData?.name as string]?.periodDto.find(
        val => val.name === userPlanData?.periodName,
      );
    }

    return {};
  };

  const onBuy = async () => {
    const confirms = await confirm(handleConfirm => (
      <ConfirmModal
        handleConfirm={handleConfirm}
        title="Продление тарифа"
        content={`Вы продлеваете тариф ${userPlanData?.displayName.toUpperCase()}, стоимость которого ${formatNumber(
          userPlanData?.price ?? 0,
        )} ${env.CURRENCY}, срок приобретаемого тарифа составит ${userPlanData?.periodName}.`}
        confirmText="Продлить"
      />
    ));

    if (confirms) {
      let newObj: TError = {} as TError;
      const { planPeriodId } = getUserPeriodId() as TPeriodDto;
      const res = await updatePlan({
        planPeriodId,
      });
      if ('error' in res) {
        newObj = JSON.parse(JSON.stringify(res.error));
        const { body } = newObj.data;
        if (body === 'Недостаточно средств') navigate(r.finances.index);
      }
      if ('data' in res) {
        onSuccessOpen();
      }
    }
  };

  if (isUserDataLoading) return null;
  return (
    <>
      <div className={styles.Period}>
        <p className={styles.Text}>
          До конца тарифа {getWordByAmount(days, ['остался', 'осталось', 'осталось'])} {days}{' '}
          {getWordByAmount(days, ['день', 'дня', 'дней'])}
        </p>
        <Button variant="tetriary" size="s" className={styles.Btn} onClick={() => onBuy()}>
          Продлить
        </Button>
      </div>
      <ResultModal
        isSuccess={isSuccess}
        isLoading={isLoading}
        onSubmit={() => {
          onSuccessClose();
        }}
        isOpen={successOpen}
        onClose={onSuccessClose}
      />
    </>
  );
};

const TariffEndNotice: FC = () => {
  const user = useTypedSelector(selectUser);

  if (!user) return <div />;

  if (user.permissions.trialExpired && user.plan.name !== EPlanName.trial) return <BuyBtn />;

  if (user.plan.name === EPlanName.trial)
    return (
      <TrialPeriod
        days={getTariffPlanDatesInfo(user.plan.startData, user.plan.expiredDate).daysTillEnd}
      />
    );

  if (!user.permissions.trialExpired && user.plan.name === EPlanName.default) return <DemoPeriod />;

  if (user.plan)
    return (
      <TariffPeriod
        days={getTariffPlanDatesInfo(user.plan.startData, user.plan.expiredDate).daysTillEnd}
      />
    );
  return <BuyBtn />;
};

export default TariffEndNotice;
