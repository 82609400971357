import { FC, ReactNode } from 'react';

import { ToastType } from 'toasts/toasts.types';
import Block from './Block';

export type ToastsProps = {
  type: ToastType;
  content: string | ReactNode;
  closeToast?: () => void;
};

const ToastsConstructor: FC<ToastsProps> = ({ type, content, closeToast }) => {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return 'checkMark';
      case 'error':
        return 'alert';
      default:
        return 'checkMark';
    }
  };

  return <Block content={content} iconName={getIcon()} closeToast={closeToast} type={type} />;
};

export default ToastsConstructor;
