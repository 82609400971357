import flow from 'lodash-es/flow';

export type RGBA = { r: number; g: number; b: number; a: number };

export const hex2rgba = (hex: string, a = 1) => {
  const [r, g, b] =
    hex.match(/\w\w/g)?.map(x => {
      const num = parseInt(x, 16);
      return isNaN(num) ? undefined : num;
    }) ?? [];
  return { r, g, b, a };
};

export const rgba2string = ({ r, g, b, a }: RGBA) => `rgba(${r ?? 0},${g ?? 0},${b ?? 0},${a})`;

export const stringToRgba = (str?: string): RGBA | undefined => {
  if (!str) return;
  try {
    const [wordW0, w1, w2, wordW3] = str.split(',');
    const w0 = wordW0.split('(')[1];
    const opacity = wordW3.split(')')[0];
    return {
      r: parseInt(w0),
      g: parseInt(w1),
      b: parseInt(w2),
      a: parseFloat(opacity),
    };
  } catch {}
};

export const hex2rgbastring = flow(hex2rgba, rgba2string);

export const rgb2hex = ({ r, g, b }: Required<RGBA>) =>
  '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

export const colorLuminance = (hex: string, lum: number) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
};
