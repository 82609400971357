import isNil from 'lodash-es/isNil';
import { IObject } from 'interfaces/common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseEnvironmentVariable = (value: any): unknown => {
  if (isNil(value)) {
    return null;
  }

  // If the value is wrapped in backticks e.g. (`value`) then just return its value.
  if (
    value.toString().indexOf('`') === 0 &&
    value.toString().lastIndexOf('`') === value.toString().length - 1
  ) {
    return value.toString().substring(1, value.toString().length - 1);
  }

  // If the value ends in an asterisk then just return its value.
  if (
    value.toString().lastIndexOf('*') === value.toString().length - 1 &&
    value.toString().indexOf(',') === -1
  ) {
    return value.toString().substring(0, value.toString().length - 1);
  }

  // Boolean.
  if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
    return value === 'true';
  }

  if (value.indexOf(',') !== -1) {
    return value.split(',').map(parseEnvironmentVariable);
  }

  // String.
  return value;
};

const parseEnvironmentVariables = (env: IObject): IObject => {
  const parsedEnv = {} as IObject;
  const envKeys = Object.keys(env) as Array<keyof typeof env>;

  envKeys.forEach(key => {
    parsedEnv[key] = parseEnvironmentVariable(env[key]);
  });

  return parsedEnv;
};

export default parseEnvironmentVariables;
