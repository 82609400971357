import { FC, FormEvent, useState } from 'react';

import Input from 'components/Input';
import ModalWithForm from 'components/ModalWithForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const AddScenarioModal: FC<Props> = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');

  const handleClose = () => {
    setName('');
    onClose();
  };

  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleClose();
  };

  return (
    <ModalWithForm
      isOpen={isOpen}
      onClose={handleClose}
      title="Создать сценарий"
      text="Укажите название сценария."
      isValid={name.length > 0}
      onSubmit={onSubmit}
    >
      <Input label="Название" value={name} onChange={setName} />
    </ModalWithForm>
  );
};

export default AddScenarioModal;
