import { FC } from 'react';

import styles from './TextStyleSelector.module.scss';
import { ETextStyle } from 'modules/my-pages/my-pages.types';
import Tool from '../Tool';
import { TEXT_STYLES_NAMES } from 'modules/my-pages/my-pages.constants';
import Icon from 'components/Icon';
import Menu from 'components/Menu';

type Props = {
  style: ETextStyle;
  setStyle: (value: ETextStyle) => void;
};

const TextStyleSelector: FC<Props> = ({ style, setStyle }) => {
  return (
    <Tool label="Стиль:">
      <Menu
        contextClassName={styles.Context}
        items={[
          {
            label: TEXT_STYLES_NAMES[ETextStyle.HEADING],
            onClick: () => setStyle(ETextStyle.HEADING),
            isSelected: style === ETextStyle.HEADING,
          },
          {
            label: TEXT_STYLES_NAMES[ETextStyle.TITLE],
            onClick: () => setStyle(ETextStyle.TITLE),
            isSelected: style === ETextStyle.TITLE,
          },
          {
            label: TEXT_STYLES_NAMES[ETextStyle.SUBTITLE],
            onClick: () => setStyle(ETextStyle.SUBTITLE),
            isSelected: style === ETextStyle.SUBTITLE,
          },
          {
            label: TEXT_STYLES_NAMES[ETextStyle.PARAGRAPH],
            onClick: () => setStyle(ETextStyle.PARAGRAPH),
            isSelected: style === ETextStyle.PARAGRAPH,
          },
          {
            label: TEXT_STYLES_NAMES[ETextStyle.SMALL_TEXT],
            onClick: () => setStyle(ETextStyle.SMALL_TEXT),
            isSelected: style === ETextStyle.SMALL_TEXT,
          },
        ]}
      >
        {TEXT_STYLES_NAMES[style]}{' '}
        <Icon className={styles.IconDown} iconName="arrow-down" size={18} />
      </Menu>
    </Tool>
  );
};

export default TextStyleSelector;
