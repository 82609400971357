interface DotsIconProps {
  className?: string;
}

export const DotsIcon = ({ className }: DotsIconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 13.7656C12.5523 13.7656 13 13.3179 13 12.7656C13 12.2133 12.5523 11.7656 12 11.7656C11.4477 11.7656 11 12.2133 11 12.7656C11 13.3179 11.4477 13.7656 12 13.7656Z"
        stroke="#5B5B65"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 13.7656C19.5523 13.7656 20 13.3179 20 12.7656C20 12.2133 19.5523 11.7656 19 11.7656C18.4477 11.7656 18 12.2133 18 12.7656C18 13.3179 18.4477 13.7656 19 13.7656Z"
        stroke="#5B5B65"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 13.7656C5.55228 13.7656 6 13.3179 6 12.7656C6 12.2133 5.55228 11.7656 5 11.7656C4.44772 11.7656 4 12.2133 4 12.7656C4 13.3179 4.44772 13.7656 5 13.7656Z"
        stroke="#5B5B65"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
