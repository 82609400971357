import { FC } from 'react';
import find from 'lodash-es/find';
import styles from './FormComponentSelector.module.scss';
import {
  useSelectFormComponentId,
  useSelectStatisticsId,
} from 'modules/landing-dashboard/landing-dashboard.selectors';
import Menu from 'components/Menu';
import Icon from 'components/Icon';
import { useFormComponentsRequest } from 'modules/landing-dashboard/landing-dashboard.hooks';
import { SlicedText } from 'components/SlicedText/SlicedText';

interface Props {
  type?: 'mobile';
  label?: string;
  isLabel?: boolean;
  display?: boolean;
  selectFormClick: (id: number) => void;
}

const FormComponentSelector: FC<Props> = ({ type, isLabel, label, selectFormClick }) => {
  const { data, isError, isLoading } = useFormComponentsRequest();
  const formComponentId = useSelectFormComponentId();
  const selectedPageId = useSelectStatisticsId();
  const formText =
    find(data?.body, { id: Number(formComponentId) })?.data.buttonStyle?.text ?? 'Форма';

  if (isError || isLoading) return null;
  if (!data?.body?.length || !selectedPageId) return null;

  return (
    <Menu
      className={styles.Menu}
      items={
        data?.body.map(formComponent => ({
          isSelected: formComponent.id === formComponentId,
          label: formComponent.data.buttonStyle?.text,
          onClick: () => selectFormClick(formComponent.id),
        })) ?? []
      }
      datatype={type}
      btnClassName={styles.btn}
    >
      <div>
        {isLabel && <p className={styles.Label}>{label}</p>}
        <SlicedText text={formText} className={styles.menuText} />
      </div>
      <Icon className={styles.IconDown} iconName="arrow-down" size={18} />
    </Menu>
  );
};

export default FormComponentSelector;
