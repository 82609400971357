import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from './PartnersNewsletterModal.module.scss';

import Modal, { CommonModalProps } from 'components/Modal';
import TextArea from 'components/TextArea';

import { PartnersNewsletterRequestBody } from 'modules/partnership-program/partnership-program.types';
import { partnersNewsletterSchema } from 'modules/partnership-program/partnership-program.schema';

export type PartnersNewsletterModalHandler = SubmitHandler<PartnersNewsletterRequestBody>;
type Props = CommonModalProps<PartnersNewsletterModalHandler>;

const PartnersNewsletterModal: FC<Props> = ({ onSubmit, ...props }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<PartnersNewsletterRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(partnersNewsletterSchema()),
  });

  useEffect(reset, [props.isOpen, reset]);

  return (
    <Modal
      btnText={'Отправить'}
      onSubmit={onSubmit && handleSubmit(onSubmit)}
      isValid={isValid}
      {...props}
    >
      <h1 className={styles.Title}>Сообщение</h1>
      <p className={styles.Text}>Введите текст, который хотите отправить партнерам</p>
      <TextArea
        label="Сообщение"
        invalid={!!errors.message?.message}
        errorMsg={errors.message?.message}
        register={register('message')}
      />
    </Modal>
  );
};

export default PartnersNewsletterModal;
