import { FC } from 'react';

import styles from './NavMobile.module.scss';
import Icon from 'components/Icon';
import useMyPagesUndoRedoProps from 'modules/my-pages/hooks/useMyPagesUndoRedoProps';
import BottomSheet from 'components/BottomSheet';
import Settings from '../Settings';
import Elements from '../Elements';
import { BlockSettings } from '../BlockSettings/BlockSettings';

const NavMobile: FC = () => {
  const undoRedoProps = useMyPagesUndoRedoProps();

  return (
    <nav className={styles.Nav}>
      <div className={styles.Wrapper}>
        <button
          className={styles.Btn}
          disabled={!undoRedoProps.canUndo}
          onClick={undoRedoProps.undo}
        >
          <Icon className={styles.Icon} iconName="undo" size={16} />
        </button>

        <button
          className={styles.Btn}
          disabled={!undoRedoProps.canRedo}
          onClick={undoRedoProps.redo}
        >
          <Icon className={styles.Icon} iconName="redo" size={16} />
        </button>

        <BottomSheet
          trigger={
            <button className={styles.MainBtn}>
              <Icon className={styles.Icon} iconName="add" size={16} />
            </button>
          }
        >
          <Elements />
        </BottomSheet>

        <BottomSheet
          type={'settings'}
          trigger={
            <button className={styles.Btn}>
              <Icon className={styles.Icon} iconName="settings" size={16} />
            </button>
          }
        >
          <Settings />
        </BottomSheet>

        <BottomSheet
          trigger={
            <button className={styles.Btn}>
              <Icon className={styles.Icon} iconName="menu-navigation" size={16} />
            </button>
          }
        >
          <BlockSettings />
        </BottomSheet>
      </div>
    </nav>
  );
};

export default NavMobile;
