import { FC } from 'react';
import styles from './PeriodComponentSelector.module.scss';
import Menu from 'components/Menu';
import { periodOptions } from 'modules/landing-dashboard/landing-dashboard.consts';
import { setPeriodType } from 'modules/landing-dashboard/landing-dashboard.reducer';
import { EPeriodType } from 'modules/landing-dashboard/landing-dashboard.types';
import Icon from 'components/Icon';
import { PeriodComponentsTypes } from './PeriodComponents.types';
import useAppDispatch from 'hooks/useAppDispatch';

export const PeriodComponentSelector: FC<PeriodComponentsTypes> = ({ period, type, isLabel }) => {
  const dispatch = useAppDispatch();
  return (
    <div className={styles.Wrapper}>
      {!isLabel && <span className={styles.Label}>Показывать:</span>}
      <Menu
        className={styles.Menu}
        items={
          Object.entries(periodOptions).map(([key, label]) => ({
            label,
            isSelected: key === period,
            onClick: () => dispatch(setPeriodType(key as EPeriodType)),
          })) ?? []
        }
        btnClassName={styles.btn}
        datatype={type}
      >
        <div>
          {isLabel && <p className={styles.MobileLabel}>Показывать</p>}
          <span>{periodOptions[period]}</span>
        </div>
        <Icon className={styles.IconDown} iconName="arrow-down" size={18} />
      </Menu>
    </div>
  );
};
