import { CSSProperties, FC } from 'react';
import { Reorder } from 'framer-motion';

import styles from './Block.module.scss';
import {
  AnyComponent,
  BlockComponent,
  EditorMode,
  EDraggableItem,
} from 'modules/my-pages/my-pages.types';
import Controls from './components/Controls';
import Component from '../Component';
import useAppDispatch from 'hooks/useAppDispatch';
import { reorderComponents } from 'modules/my-pages/my-pages.reducer';
import { useDrop } from 'react-dnd';
import { appendComponent } from 'modules/my-pages/my-pages.helpers';
import { useActivePage } from '../../../../my-pages.selectors';

type Props = {
  block: BlockComponent;
  mode?: EditorMode;
};

const Block: FC<Props> = ({ block, mode = 'edit' }) => {
  const page = useActivePage();
  const dispatch = useAppDispatch();
  const [{ isOver }, drop] = useDrop({
    accept: Object.values(EDraggableItem),
    drop: (item, monitor) => {
      const isBlock = monitor.getItemType() === EDraggableItem.BLOCK;

      appendComponent(
        monitor.getItemType() as EDraggableItem,
        dispatch,
        (isBlock ? page?.components.length : block.components?.length) ?? 0,
        block.hash,
      );
    },
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const items = [...block.components!].sort((a, b) => a.order - b.order);

  const onReorder = (newList: AnyComponent[]) => {
    dispatch(reorderComponents({ parentId: block.hash, newList }));
  };

  const style: CSSProperties = {
    background: block.data.bgImage ? `url(${block.data.bgImage})` : block.data.bgColor,
    opacity: isOver ? 0.5 : block.data.opacity,
  };

  return (
    <div className={styles.Wrapper} ref={drop}>
      <div className={styles.Block} style={style}>
        <Reorder.Group values={items} onReorder={onReorder} className={styles.Components}>
          {items.map(component => (
            <Component key={component.hash} mode={mode} component={component} />
          ))}
        </Reorder.Group>
      </div>

      {mode === 'edit' && <Controls {...block} />}
    </div>
  );
};

export default Block;
