import { FC, useEffect } from 'react';
import ModalWithForm from 'components/ModalWithForm';
import Input from 'components/Input';
import { CommonModalProps } from 'components/Modal';
import ToggleSwitch from 'components/ToggleSwitch';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import AddFiles from '../AddFiles';
import { TCommand, TTelegramErrorResult } from '../../telegram.types';
import isEmpty from 'lodash-es/isEmpty';
import { yupResolver } from '@hookform/resolvers/yup';
import { commandSchema } from '../../telegram.schema';
import styles from './CommandModal.module.scss';
import {
  useGetAdminCommandByIdQuery,
  usePostAdminCommandMutation,
  useUpdateAdminCommandByIdMutation,
} from '../../codegen/telegram.admin.api';
import { useBotId } from '../../telegram.hooks';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import isUndefined from 'lodash-es/isUndefined';
import SmallLoader from 'components/SmallLoader';
import FilesWrapper from '../FilesWrapper';

type TFile = {
  mime: string;
  name: string;
  size: string;
  url: string;
};

type Props = {
  commandId?: string;
} & CommonModalProps;

const CommandModal: FC<Props> = ({ onClose, isOpen, commandId }) => {
  const botId = useBotId();
  const [postCommand, { isLoading: isPosting }] = usePostAdminCommandMutation();
  const [updateCommand, { isLoading: isUpdating }] = useUpdateAdminCommandByIdMutation();
  const { data: editData, isFetching: isEditLoading } = useGetAdminCommandByIdQuery(
    { botId, commandId: String(commandId) },
    { skip: isUndefined(commandId) || isUndefined(botId) },
  );
  const isLoading = isPosting || isUpdating;

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<TCommand>({
    mode: 'onChange',
    resolver: yupResolver(commandSchema()),
  });

  const { prepend } = useFieldArray({
    control,
    name: 'files',
  });
  const values = watch();

  const onSubmit: SubmitHandler<TCommand> = form => {
    const formData = new FormData();
    formData.append('name', form.name);
    formData.append('description', form.description);
    formData.append('text', form.text);
    formData.append('isEnable', String(Boolean(form.isEnable)));
    for (let i = 0; i < form?.files?.length; i++) {
      formData.append(`files`, form.files[i]);
    }

    if (commandId) {
      updateCommand({ botId, commandId, form: formData }).then(res => {
        if ('data' in res) {
          showFeedback({
            title: 'ГОТОВО!',
            text: '',
            type: EFeedbackType.SUCCESS,
          });
          onClose();
          reset();
        }

        if ('error' in res) {
          const err: TTelegramErrorResult = { ...res } as TTelegramErrorResult;
          showFeedback({
            title: 'ОШИБКА!',
            text: err?.error?.data?.description,
            type: EFeedbackType.ERROR,
          });
          onClose();
          reset();
        }
      });
    } else {
      postCommand({ botId, form: formData }).then(res => {
        if ('data' in res) {
          showFeedback({
            title: 'ГОТОВО!',
            text: '',
            type: EFeedbackType.SUCCESS,
          });
          onClose();
          reset();
        }

        if ('error' in res) {
          const err: TTelegramErrorResult = { ...res } as TTelegramErrorResult;
          showFeedback({
            title: 'ОШИБКА!',
            text: err?.error?.data?.description,
            type: EFeedbackType.ERROR,
          });
          onClose();
          reset();
        }
      });
    }
  };

  const createFile = (url?: string, type?: string, fileName?: string) => {
    if (url) {
      const metadata = { type: 'image/' + type };
      const data = new Blob([url], metadata);
      return new File([data], `${fileName}.${type}`, metadata);
    }
  };

  useEffect(() => {
    if (!commandId) {
      reset();
      return;
    }
    if (commandId) {
      reset({
        name: editData?.name,
        description: editData?.description,
        text: editData?.description,
        files: editData?.files.map(item => {
          const cloned = { ...item } as unknown as TFile;
          return createFile(cloned.url, cloned.mime, cloned.name);
        }),
        isEnable: editData?.isEnable,
      });
    }
  }, [commandId, reset, editData]);

  return (
    <ModalWithForm
      onSubmit={handleSubmit(onSubmit)}
      title={'Настройки команды'}
      onClose={onClose}
      isValid={isValid || isLoading}
      isOpen={isOpen}
      formClassName={styles.Wrapper}
      btnText={'Сохранить'}
      noBtn={isLoading}
    >
      {isEditLoading ? (
        <SmallLoader />
      ) : (
        <>
          <Input register={register('name')} label={'Название'} />
          <Input register={register('description')} label={'Описание'} />
          <Input register={register('text')} label={'Сообщение'} />
          <div className={styles.Switcher}>
            <h5>Отображать в списке команд</h5>
            <ToggleSwitch
              toggle={() => {
                return setValue('isEnable', !values.isEnable);
              }}
              active={values.isEnable}
            />
          </div>
          <Controller
            control={control}
            render={() => {
              return (
                <AddFiles
                  setFile={() => {}}
                  setFilesList={event => {
                    if (event.target.files)
                      for (let i = 0; i < event.target.files?.length; i++) {
                        prepend(event.target.files[i]);
                      }
                  }}
                />
              );
            }}
            name={'files'}
          />

          {!isEmpty(values.files) && (
            <Controller
              control={control}
              render={({ field }) => {
                return (
                  <FilesWrapper
                    files={values.files}
                    onFileClose={(file: File) => {
                      const filtered = values.files.filter((item: File) => item.name !== file.name);
                      field.onChange([...filtered]);
                    }}
                  />
                );
              }}
              name={'files'}
            />
          )}

          {isLoading && <SmallLoader />}
        </>
      )}
    </ModalWithForm>
  );
};

export default CommandModal;
