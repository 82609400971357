import { FC } from 'react';

import Input from 'components/Input';
import Button from 'components/Button';

import ControlsPanel from '../ControlsPanel';
import FormFieldsWrapper from '../FormFieldsWrapper';
import Section from '../Section';
import SectionTitle from '../SectionTitle';
import SettingsButton from '../SettingsButton';
import useDisclosure from 'hooks/useDisclosure';
import NewPasswordModal from '../NewPasswordModal';
import NewFinPasswordModal from '../NewFinPasswordModal';
import InfoSettingsModal from '../InfoSettingsModal';

import { useResetFinPasswordMutation } from '../../settings.api';
import styles from './PasswordSettings.module.scss';

const PasswordSettings: FC = () => {
  const {
    open: newPassOpen,
    onOpen: onNewPassOpen,
    onClose: onNewPassClose,
  } = useDisclosure({ blockScroll: true });

  const {
    open: finPassOpen,
    onOpen: onFinPassOpen,
    onClose: onFinPassClose,
  } = useDisclosure({ blockScroll: true });

  const {
    open: resetPassOpen,
    onOpen: onResetPassOpen,
    onClose: onResetPassClose,
  } = useDisclosure({ blockScroll: true });

  const [resetPassword, { isLoading, isSuccess }] = useResetFinPasswordMutation();

  const resetPasswordHandler = () => {
    onResetPassOpen();
    resetPassword();
  };

  return (
    <>
      <Section>
        <SectionTitle style={{ fontSize: '16px', marginLeft: '16px' }}>
          Пароль и безопасность
        </SectionTitle>

        <FormFieldsWrapper>
          <Input label="Пароль от аккаунта" value={'************'} type="password" disabled />
          <ControlsPanel>
            <SettingsButton size={'l'} variant="tetriary" onClick={onNewPassOpen}>
              Сменить пароль
            </SettingsButton>
          </ControlsPanel>
          <Input label="Финансовый пароль" value={'************'} type="password" disabled />
          <ControlsPanel>
            <SettingsButton size={'l'} variant="tetriary" onClick={onFinPassOpen}>
              Сменить пароль
            </SettingsButton>
            <Button
              size="s"
              className={styles.TextButton}
              variant="text"
              onClick={resetPasswordHandler}
            >
              Сбросить пароль
            </Button>
          </ControlsPanel>
        </FormFieldsWrapper>
      </Section>

      <NewPasswordModal isOpen={newPassOpen} onClose={onNewPassClose} />
      <NewFinPasswordModal isOpen={finPassOpen} onClose={onFinPassClose} />
      <InfoSettingsModal
        isLoading={isLoading}
        isSuccess={isSuccess}
        isValid={true}
        isOpen={resetPassOpen}
        onClose={onResetPassClose}
        onSubmit={resetPasswordHandler}
      />
    </>
  );
};

export default PasswordSettings;
