import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import styles from './PageActions.module.scss';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import r from 'constants/routes';
import useDisclosure from 'hooks/useDisclosure';
import ShareLinkModal from '../ShareLinkModal';
import { useActivePage, useIsValidPage } from 'modules/my-pages/my-pages.selectors';
import useSavePage from 'modules/my-pages/hooks/useSavePage';
import toast from 'toasts';

const PageActions: FC = () => {
  const page = useActivePage();
  const isDisabled = !useIsValidPage();
  const navigate = useNavigate();
  const { open, onOpen: onShareOpenModal, onClose } = useDisclosure({ blockScroll: true });
  const onSave = useSavePage();
  const isPublished = page?.published;

  const onPreview = () => {
    navigate(generatePath(r.myPages.preview, { id: page?.id }));
  };

  const onSaveClick = () => {
    toast.success('Изменения сохранены');
    return onSave();
  };

  return (
    <>
      <div className={styles.Actions}>
        <IconButton iconName="eye" iconSize={18} size={40} onClick={onPreview} />
        <Tooltip label="Сохранить (Ctrl+S)">
          <IconButton
            iconName="save"
            iconSize={18}
            size={40}
            onClick={onSaveClick}
            styleStroke
            disabled={isDisabled}
          />
        </Tooltip>
        <IconButton
          iconName="share-out"
          iconSize={18}
          size={40}
          onClick={onShareOpenModal}
          disabled={!isPublished}
        />
      </div>

      <ShareLinkModal isOpen={open} onClose={onClose} horizontal />
    </>
  );
};

export default PageActions;
