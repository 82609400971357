import { FC } from 'react';

import styles from './Form.module.scss';
import {
  ETextStyle,
  Form as TForm,
  NonNullableActiveComponent,
} from 'modules/my-pages/my-pages.types';
import ToolsWrapper from '../../tools/ToolsWrapper';
import useSetComponentProperty from 'modules/my-pages/hooks/useSetComponentProperty';
import BlockStyleSelector from '../../tools/BlockStyleSelector';
import ColorSelector from '../../tools/ColorSelector';
import AlignmentSelector from '../../tools/AlignmentSelector';
import InputOutline from 'components/InputOutline';
import AnchorInput from '../../tools/AnchorInput';
import AddTextBtn from '../../tools/AddTextBtn';
import FormFieldsSelector from '../../tools/FormFieldsSelector';
import AccordionStandard from 'components/AccordionStandard';
import TextPropertiesEditor from '../../tools/TextPropertiesEditor';

type Props = {
  component: NonNullableActiveComponent<TForm>;
};

const Form: FC<Props> = ({ component }) => {
  const { hash, order, data } = component.component;
  const { setProperty, setText } = useSetComponentProperty(hash);
  const anchorActive = !!data.isAnchorActive;

  return (
    <ToolsWrapper>
      <FormFieldsSelector fields={data.fields} setFields={value => setProperty('fields', value)} />

      <AccordionStandard
        text="Стиль полей для ввода"
        contentClassName={styles.FieldsList}
        firstOpen
        noDivider
      >
        <BlockStyleSelector
          style={data.inputStyle.blockStyle}
          setStyle={value => setProperty('inputStyle.blockStyle', value)}
        />
        <ColorSelector
          label="Фон:"
          color={data.inputStyle.bgColor}
          setColor={value => setProperty('inputStyle.bgColor', value)}
        />
        <ColorSelector
          label="Контур:"
          color={data.inputStyle.borderColor}
          setColor={value => setProperty('inputStyle.borderColor', value)}
        />
      </AccordionStandard>
      <AccordionStandard
        text="Стиль кнопки"
        contentClassName={styles.FieldsList}
        firstOpen
        noDivider
      >
        <BlockStyleSelector
          style={data.buttonStyle.blockStyle}
          setStyle={value => setProperty('buttonStyle.blockStyle', value)}
        />
        <ColorSelector
          label="Фон:"
          color={data.buttonStyle.bgColor}
          setColor={value => setProperty('buttonStyle.bgColor', value)}
        />
        <ColorSelector
          label="Контур:"
          color={data.buttonStyle.borderColor}
          setColor={value => setProperty('buttonStyle.borderColor', value)}
        />
        <TextPropertiesEditor
          properties={data.buttonStyle.properties}
          setProperty={(key, value) => setProperty(`buttonStyle.properties.${key}`, value)}
        />
        <AlignmentSelector
          alignment={data.buttonStyle.alignment}
          setAlignment={value => setProperty('buttonStyle.alignment', value)}
        />
        <InputOutline
          label="Текст:"
          value={data.buttonStyle.text}
          onChange={e => setText('buttonStyle.text', e.target.value)}
        />
      </AccordionStandard>
      <AnchorInput
        anchor={data.anchor}
        setAnchor={value => setText('anchor', value)}
        setAnchorActive={() => setProperty('isAnchorActive', !anchorActive)}
        active={anchorActive}
      />
      <AddTextBtn parentId={component.parent.hash} atOrder={order} style={ETextStyle.TITLE} />
    </ToolsWrapper>
  );
};

export default Form;
