import { FC } from 'react';
import styles from './CheckedWelcomeBotCard.module.scss';
import CheckBox from 'components/CheckBox';
import IconButton from 'components/IconButton';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import Button from 'components/Button';
import useDisclosure from '../../../../../hooks/useDisclosure';
import { SettingsWelcomeBotModal } from '../SettingsWelcomeBotModal';
import { generatePath, useNavigate } from 'react-router-dom';
import r from 'constants/routes';
import { SimpleCipher } from '../../../telegram.utils';

type Props = {
  logo?: string;
  isChecked: boolean;
  onChecked: () => void;
  middleLabel: string;
  middleTitle: string;
  lowerLabel?: string;
  lowerTitle?: string;
  service: string;
  messageId?: string;
};

export const CheckedWelcomeBotCard: FC<Props> = ({
  logo,
  isChecked,
  lowerLabel,
  middleLabel,
  middleTitle,
  lowerTitle,
  onChecked,
  service,
  messageId,
}) => {
  const { copyAndNotify } = useCopyToClipboard();
  const navigate = useNavigate();

  const {
    open: settingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure({ blockScroll: true });

  return (
    <>
      <div className={styles.Root}>
        <div className={styles.Upper}>
          <img src={logo} alt="stars" />
          <div className={styles.Checkbox}>
            <CheckBox
              onChange={() => {
                onChecked();
              }}
              checked={isChecked}
              backgroundColor={isChecked ? '#1ED731' : ''}
              className={styles.Box}
              type={'circle'}
            />
          </div>
        </div>

        <div className={styles.Middle}>
          <div className={styles.MiddleText}>
            <p>{middleLabel}</p>
            <h1>{middleTitle}</h1>
          </div>

          <IconButton
            iconClassName={styles.Icon}
            iconName="copy"
            iconSize={24}
            onClick={() => copyAndNotify(middleTitle)}
          />
        </div>
        {lowerTitle && (
          <div className={styles.Lower}>
            <p>{lowerLabel}</p>
            <h1>{lowerTitle}</h1>
          </div>
        )}

        <div className={styles.Buttons}>
          <Button
            onClick={() => {
              const encrypted = SimpleCipher.encrypt(service);
              navigate(generatePath(r.telegram.welcomeBotStatistics, { service: encrypted }));
            }}
            className={styles.StatisticsButton}
          >
            Статистика
          </Button>
          <IconButton onClick={onSettingsOpen} iconName={'settings'} iconSize={20} />
        </div>
      </div>

      {settingsOpen && (
        <SettingsWelcomeBotModal
          service={service}
          isOpen={settingsOpen}
          onModalClose={onSettingsClose}
          onClose={() => {}}
          isEditing
          messageId={messageId}
        />
      )}
    </>
  );
};
