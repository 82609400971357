import standardDesktop from 'assets/img/tariff/tariff-standard-desktop.png';
import standardMobile from 'assets/img/tariff/tariff-standard-mobile.png';

import premiumDesktop from 'assets/img/tariff/tariff-premium-desktop.png';
import premiimMobile from 'assets/img/tariff/tariff-premium-mobile.png';

import basedDesktop from 'assets/img/tariff/tariff-based-desktop.png';
import basedMobile from 'assets/img/tariff/tariff-based-mobile.png';

export const tariffImages = {
  PREMIUM: {
    desktop: premiumDesktop,
    mobile: premiimMobile,
  },
  STANDARD: {
    desktop: standardDesktop,
    mobile: standardMobile,
  },
  BASED: {
    desktop: basedDesktop,
    mobile: basedMobile,
  },
};
