import { forwardRef } from 'react';
import classNames from 'classnames';
import { Dialog } from '@headlessui/react';

import styles from './Modal.module.scss';
import Button from 'components/Button';
import ModalOverlay from 'components/ModalOverlay';
import ColorfulBorder from 'components/ColorfulBorder';
import SmallLoader from '../SmallLoader';

export type OnSubmitDefaultHandler = (e?: React.FormEvent<HTMLFormElement>) => void;

export type CommonModalProps<T = OnSubmitDefaultHandler> = {
  isValid?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: T;
  isColumnBtn?: boolean;
};

type Props = {
  children: React.ReactNode;
  className?: string;
  btnText?: string;
  noBtn?: boolean;
  horizontal?: boolean;
  formClassName?: string;
  isNoColorBorder?: boolean;
  isLoading?: boolean;
  transparentBg?: boolean;
} & CommonModalProps;

const Modal = forwardRef<HTMLDivElement, Props>(
  (
    {
      isOpen,
      onClose,
      isValid,
      className,
      formClassName,
      children,
      onSubmit,
      btnText = 'Подтвердить',
      noBtn,
      horizontal,
      isNoColorBorder,
      isLoading,
      transparentBg,
      isColumnBtn,
    },
    ref,
  ) => {
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <ModalOverlay transparentBg={transparentBg}>
          <Dialog.Panel>
            <ColorfulBorder
              className={classNames(styles.Wrapper, {
                [styles.Horizontal]: horizontal,
                [styles.None]: isNoColorBorder,
              })}
            >
              {/* Use ref here or at another part of your component as needed */}
              <div ref={ref} className={classNames(styles.Content, className)}>
                {isLoading ? (
                  <SmallLoader />
                ) : (
                  <form
                    className={classNames(styles.ModalContent, formClassName)}
                    onSubmit={onSubmit}
                  >
                    {children}
                    <div
                      className={classNames(styles.Btns, {
                        [styles.NoBtn]: noBtn,
                        [styles.ColumnBtn]: isColumnBtn,
                      })}
                    >
                      <Button className={styles.BtnCLose} variant="text" onClick={onClose}>
                        Закрыть
                      </Button>
                      <Button
                        className={styles.Btn}
                        variant="primary"
                        type="submit"
                        disabled={!isValid}
                      >
                        {btnText}
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            </ColorfulBorder>
          </Dialog.Panel>
        </ModalOverlay>
      </Dialog>
    );
  },
);

export default Modal;
