import { FC } from 'react';

import styles from './LinkCopier.module.scss';
import IconButton from 'components/IconButton';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

type Props = {
  link: string;
};

const LinkCopier: FC<Props> = ({ link }) => {
  const { copyAndNotify } = useCopyToClipboard();

  return (
    <div className={styles.Copier}>
      <div className={styles.Link}>{link}</div>
      <IconButton
        iconClassName={styles.Icon}
        iconName="copy"
        iconSize={24}
        onClick={() => copyAndNotify(link)}
      />
    </div>
  );
};

export default LinkCopier;
