import { nanoid } from '@reduxjs/toolkit';

import {
  Block,
  Button,
  ComponentBody,
  EAlignment,
  EBlockStyle,
  EClickAction,
  EComponentType,
  EImageStyle,
  Email,
  ESocialNetwork,
  ETextStyle,
  Form,
  Image,
  Phone,
  SocialNetwork,
  Text,
  YouTubeVideo,
} from './my-pages.types';

export const createComponent = <T>(
  data: T,
  type: EComponentType,
  order = -1,
): ComponentBody<T> => ({
  hash: nanoid(),
  order,
  type,
  data,
  components: type === EComponentType.BLOCK ? [] : undefined,
});

export const createBlock = (): Block => ({
  bgColor: 'transparent',
  bgImage: null,
  opacity: 1,
});

export const createText = (style?: ETextStyle): Text => ({
  textStyle: style ?? ETextStyle.HEADING,
  properties: {
    bold: false,
    italic: false,
    underline: false,
    color: '#fff',
    alignment: EAlignment.LEFT,
  },
  onClick: {
    action: EClickAction.NONE,
  },
  text: 'Текст',
});

export const createButton = (): Button => ({
  blockStyle: EBlockStyle.ROUNDED,
  bgColor: '#ffffff',
  borderColor: '#ffffff',
  alignment: EAlignment.LEFT,
  text: 'Текст',
  onClick: {
    action: EClickAction.NONE,
  },
  properties: {
    bold: false,
    italic: false,
    underline: false,
    color: '#003bc5',
    alignment: EAlignment.CENTER,
  },
});

export const createYouTubeVideo = (): YouTubeVideo => ({});

export const createPhone = (): Phone => ({});

export const createEmail = (): Email => ({});

export const createSocialNetwork = (type: ESocialNetwork): SocialNetwork => ({
  type,
  blockStyle: EBlockStyle.ROUNDED,
  filled: false,
  hasBorder: true,
  onClick: {
    action: EClickAction.NONE,
  },
  alignment: EAlignment.LEFT,
});

export const createImage = (): Image => ({
  imageStyle: EImageStyle.FILL,
  opacity: 1,
  onClick: {
    action: EClickAction.NONE,
  },
  link: '',
});

export const createForm = (): Form => ({
  fields: [],
  inputStyle: {
    blockStyle: EBlockStyle.ROUNDED,
    bgColor: '#181818',
    borderColor: '#303031',
  },
  buttonStyle: {
    blockStyle: EBlockStyle.ROUNDED,
    bgColor: '#ffffff',
    borderColor: '#ffffff',
    alignment: EAlignment.LEFT,
    text: 'Текст',
    properties: {
      bold: false,
      italic: false,
      underline: false,
      color: '#003bc5',
      alignment: EAlignment.CENTER,
    },
  },
});
