import { FC, useId } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import styles from './RadioInput.module.scss';

type Props = {
  label?: string;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
};

const RadioInput: FC<Props> = ({ label, register, disabled }) => {
  const id = useId();

  return (
    <div className={styles.Wrapper}>
      <input className={styles.Input} type={'radio'} {...register} disabled={disabled} />
      <div className={styles.Indicator}>
        <div className={styles.IndicatorInner} />
      </div>
      <label className={styles.Label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default RadioInput;
