import { FC } from 'react';
import classNames from 'classnames';

import styles from './ChangeSourceModal.module.scss';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { useFinancesModal, useOpenModals } from 'modules/finances/finances.utils';
import { Sources } from 'modules/finances/finances.types';
import { useSelectFinancesType } from 'modules/finances/finances.selectors';
import masterCard from 'assets/svg/finances/Mastercard.svg';
import visa from 'assets/svg/finances/Visa.svg';
import qiwi from 'assets/svg/finances/Qiwi.svg';
import payeer from 'assets/svg/finances/Payeer.svg';

const SourcesMap = {
  [Sources.VISA]: visa,
  [Sources.PAYEER]: payeer,
  [Sources.QIWI]: qiwi,
  [Sources.MASTERCARD]: masterCard,
};

const ChangeSourceModal: FC = ({}) => {
  const { openBuy } = useOpenModals();
  const source = useSelectFinancesType();
  const [{ isChangeSource, selectNewType }, onClose] = useFinancesModal();

  return (
    <Modal
      isOpen={isChangeSource}
      onClose={onClose}
      className={classNames(styles.Modal, styles.ModalNew)}
    >
      <div className={styles.ModalContent}>
        <p className={styles.Title}>Хотите изменить способ оплаты?</p>
        <div className={styles.CardHolder}>
          {Object.entries(SourcesMap).map(([key, src]) => (
            <img
              key={key}
              src={src}
              alt={key}
              className={classNames(styles.Card, {
                [styles.Selected]: source === key,
              })}
              onClick={selectNewType(key as Sources)}
            />
          ))}
        </div>
        <Button className={styles.Btn} type="submit" onClick={openBuy}>
          Сохранить
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeSourceModal;
