import { FC } from 'react';

import styles from './Tariff.module.scss';
import { useSelectUser } from 'modules/account/account.selector';
import { EPlanName } from 'modules/account/account.interfaces';
import BalanceCard from './components/Balance';
import PlanCard from './components/Plan';
import { Advertising } from 'components/Advertising';
import { addsMap } from '../../../../constants/adds';

const Tariff: FC = () => {
  const user = useSelectUser();

  if (user?.plan.name === EPlanName.default) return null;

  return (
    <section className={styles.Tariff}>
      <BalanceCard />
      <PlanCard />
      <Advertising imageUrl={addsMap.pos2.imgUrl} link={addsMap.pos2.link} />
    </section>
  );
};

export default Tariff;
