import { FC } from 'react';

import useAppDispatch from 'hooks/useAppDispatch';
import { addComponent } from 'modules/my-pages/my-pages.reducer';
import { createComponent, createText } from 'modules/my-pages/my-pages.creators';
import { EComponentType, ETextStyle } from 'modules/my-pages/my-pages.types';
import AddBtn from '../AddBtn';
import { TEXT_STYLES_NAMES } from 'modules/my-pages/my-pages.constants';

type Props = {
  parentId: string;
  atOrder: number;
  style?: ETextStyle;
};

const AddTextBtn: FC<Props> = ({ parentId, atOrder, style = ETextStyle.SUBTITLE }) => {
  const dispatch = useAppDispatch();

  const onAdd = () => {
    dispatch(
      addComponent({
        parentId,
        component: createComponent(createText(style), EComponentType.TEXT, atOrder),
      }),
    );
  };

  return <AddBtn onAdd={onAdd}>{TEXT_STYLES_NAMES[style]}</AddBtn>;
};

export default AddTextBtn;
