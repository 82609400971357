import { FC } from 'react';
import CommandsTableRow from './CommandsTableRow';
import styles from './CommandsTableBody.module.scss';
import { useAdminSelectedCommands } from '../../../telegram.selectors';
import Pagination from 'components/Pagination';
import useAppDispatch from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { setCommandsParams } from '../../../codegen/telegram.admin.reducer';
import { useToggleSelectedCommandsMutation } from '../../../codegen/telegram.admin.api';
import { useBotId } from '../../../telegram.hooks';

const CommandsTableBody: FC = () => {
  const { data, count } = useAdminSelectedCommands();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const botId = useBotId();
  const [toggleCommand] = useToggleSelectedCommandsMutation();
  const dispatch = useAppDispatch();

  const pageChange = (page: number) => {
    let offset;
    queryParams.set('page', `${page}`);
    if (page === 1) {
      offset = 0;
      queryParams.set('offset', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setCommandsParams({ offset }));
    } else {
      offset = (page - 1) * 12;
      queryParams.set('offset', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setCommandsParams({ offset }));
    }
  };

  const toggleHandler = (id?: string) => {
    if (id) {
      toggleCommand({ botId, commandId: id });
    }
  };

  return (
    <>
      <tbody className={styles.Root}>
        <CommandsTableRow isHelp description={''} files={[]} name={''} text={''} />
        {data.map(row => (
          <CommandsTableRow
            onToggleBtn={() => toggleHandler(row.id)}
            isCommand
            key={row.id}
            isEnable={row.isEnable}
            isHelp={false}
            {...row}
          />
        ))}
      </tbody>
      <Pagination
        onPageChange={num => pageChange(num)}
        offset={1}
        totalCount={Math.ceil(count / 12)}
        currPage={Number(queryParams.get('page'))}
      />
    </>
  );
};

export default CommandsTableBody;
