import { FC, useMemo } from 'react';

import Pagination from 'components/Pagination';
import TableGeneric from 'components/TableGeneric';
import { ComplexColumn } from 'components/TableGeneric/TableGeneric.types';
import {
  BotWelcomeContact,
  useWelcomeBotIdContactsControllerGetContactListQuery,
} from 'modules/telegram/codegen/telegram.welcome.api';
import { useBotId } from 'modules/telegram/telegram.hooks';
import { setOffset } from 'modules/partnership-program/partnership-program.reducer';
import useAppDispatch from 'hooks/useAppDispatch';

const GreetingBotClientsTable: FC = () => {
  const botId = useBotId();
  const dispatch = useAppDispatch();
  const { data, isError, isLoading } = useWelcomeBotIdContactsControllerGetContactListQuery({
    botId,
    botWelcomeContactListParams: {},
  });

  const pageChange = (page: number) => {
    if (page === 1) {
      dispatch(setOffset(0));
    } else {
      dispatch(setOffset((page - 1) * 5));
    }
  };

  const columns = useMemo<ComplexColumn<BotWelcomeContact>[]>(
    () => [
      //   {
      //     header: 'Дата и время',
      //     accessor: 'date',
      //     accessorFn: ({ date }) => (
      //       <div className={styles.Text}>
      //         <span>{dayjs(date).format('DD.MM.YYYY')}</span>
      //         <span className={styles.Time}>{dayjs(date).format('HH:mm')}</span>
      //       </div>
      //     ),
      //   },
      {
        header: 'Логин',
        accessor: 'username',
        // accessorFn: ({ type }) => TransactionTypes[type].elem,
        // filters: Object.entries(TransactionTypes).map(([value, { name }]) => ({ name, value })),
      },
      {
        header: 'Телефон',
        accessor: 'phone',
        // accessorFn: ({ status }) => TransactionStatuses[status].elem,
        // filters: Object.entries(TransactionStatuses).map(([value, { name }]) => ({ name, value })),
      },
      {
        header: 'Статус',
        accessor: 'status',
        // accessorFn: ({ amount }) => (
        //   <span className={styles.Text}>{`${formatNumber(amount)} ${env.CURRENCY}`}</span>
        // ),
      },
    ],
    [],
  );

  if (isLoading) return null;
  if (isError) return null;
  return (
    <section>
      <TableGeneric columns={columns} data={data?.list} />
      <Pagination offset={5} onPageChange={pageChange} totalCount={data?.total} />
    </section>
  );
};

export default GreetingBotClientsTable;
