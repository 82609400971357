import { FC } from 'react';

import { useOpenModals } from 'modules/finances/finances.utils';
import MethodCard from '../MethodCard';

type Props = {
  photo: string;
  onClick?: () => void;
};

const TopUpMethod: FC<Props> = ({ photo, onClick }) => {
  const { openFin } = useOpenModals();

  return <MethodCard photo={photo} onClick={onClick ? onClick : openFin} />;
};

export default TopUpMethod;
