import { FC } from 'react';

import styles from './Mobile.module.scss';
import Label from 'components/Label';
import { getElements } from '../../elements.constants';

type Props = {
  elements: ReturnType<typeof getElements>;
  onClose: () => void;
};

const Mobile: FC<Props> = ({ elements, onClose }) => {
  return (
    <div className={styles.Wrapper}>
      <Label className={styles.Label}>Элементы</Label>
      {elements.map(({ title, elements: els }, index) => (
        <div onClick={onClose} key={index} className={styles.Elements}>
          <Label className={styles.Title}>{title}</Label>
          <div className={styles.Grid}>{els}</div>
        </div>
      ))}
    </div>
  );
};

export default Mobile;
