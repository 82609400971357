import { FC } from 'react';

import styles from './Desktop.module.scss';
import DateTime from 'components/DateTime';
import Button from 'components/Button';
import Stats from '../Stats';

const Desktop: FC = () => {
  return (
    <aside className={styles.Wrapper}>
      <div className={styles.Top}>
        <div className={styles.Label}>Конструктор чат-ботов</div>
        <img
          className={styles.Avatar}
          src={'https://placebear.com/300/300'}
          alt="Bot's avatar"
          width={72}
          height={72}
        />
        <div className={styles.Username}>@kitchenBOT</div>
        <DateTime className={styles.DateTime} date="12/08/2016" size={12} />
        <Button className={styles.DeleteBtn} variant="tetriary">
          Удалить проект
        </Button>
      </div>
      <Stats subscribers={14.3} unsubscribed={4} subscribed={7} messages={54.3} commands={54} />
    </aside>
  );
};

export default Desktop;
