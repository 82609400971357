import Modal, { CommonModalProps } from 'components/Modal';
import Button from 'components/Button';
import styles from './GroupsGrayModal.module.scss';
import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  title: string;
  text: string;
} & CommonModalProps;

const GroupsGrayModal: FC<Props> = ({ children, isValid, onClose, onSubmit, isOpen, text }) => {
  return (
    <Modal
      onSubmit={onSubmit}
      isValid={isValid}
      isOpen={isOpen}
      onClose={onClose}
      noBtn
      isNoColorBorder
      className={styles.Modal}
    >
      <div className={styles.InnerContainer}>
        <h1 className={styles.Title}>Создать папку</h1>
        <p className={styles.Text}>{text}</p>
        <div className={styles.Children}>{children}</div>
        <div className={styles.Buttons}>
          <Button className={styles.Button} variant="tetriary" onClick={onClose}>
            Отмена
          </Button>
          <Button className={styles.Button}>Создать</Button>
        </div>
      </div>
    </Modal>
  );
};

export default GroupsGrayModal;
