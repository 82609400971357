import { FC, useMemo } from 'react';
import styles from './WelcomeBot.module.scss';
import CheckBox from 'components/CheckBox';
import Button from 'components/Button';
import { EWelcomeBotState, TWelcomeBot, TWelcomeBotMessage } from '../../telegram.types';
import useDisclosure from 'hooks/useDisclosure';
import { ConnectWelcomeBotModal } from './ConnectWelcomeBotModal';
import { SettingsWelcomeBotModal } from './SettingsWelcomeBotModal';
import { CheckedWelcomeBotCard } from './CheckedWelcomeBotCard';
import env from 'config/env';
import r from 'constants/routes';
import { useSelectUser } from '../../../account/account.selector';
import { useToggleWelcomeBotMutation } from '../../../account/account.api';
import last from 'lodash-es/last';
import { isEmpty } from 'lodash-es';

interface Props extends TWelcomeBot {
  messagesArray?: TWelcomeBotMessage[];
}
export const WelcomeBot: FC<Props> = ({
  logoThumb,
  onClick,
  isLinked,
  service,
  isActive,
  serviceUsername,
  status,
  messagesArray,
}) => {
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });
  const {
    open: settingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure({ blockScroll: true });
  const user = useSelectUser();
  const refLink = `${env.APP_FRONT}${r.auth.signUp.registration}?referralCode=${user?.username}`;
  const [toggleService] = useToggleWelcomeBotMutation({});
  const handleCheck = () => {
    toggleService({ service: service.toLowerCase() });
  };

  const buttonClickHandler = () => {
    if (onClick) onClick();
    if (!isLinked) {
      onOpen();
    } else {
      onSettingsOpen();
    }
  };

  const filteredMessagesArr = useMemo(() => {
    if (messagesArray?.length) return messagesArray?.filter(i => i.service === service && i.status);
    return [];
  }, [messagesArray, service]);

  return (
    <>
      {isActive ? (
        <CheckedWelcomeBotCard
          isChecked={isActive}
          logo={logoThumb}
          service={service}
          onChecked={handleCheck}
          middleLabel={service === 'STARS_ONLINE' ? 'Реферальная ссылка:' : 'Логин'}
          middleTitle={service === 'STARS_ONLINE' ? refLink : serviceUsername}
          messageId={!isEmpty(filteredMessagesArr) ? last(filteredMessagesArr)?.id : ''}
        />
      ) : (
        <>
          <div className={styles.Root}>
            <div className={styles.Checkbox}>
              {isLinked && (
                <CheckBox
                  checked={isActive}
                  onClick={() => {
                    handleCheck();
                  }}
                  backgroundColor={isActive ? '#1ED731' : ''}
                  className={styles.Box}
                  type={'circle'}
                />
              )}
            </div>
            <div className={styles.Logo}>
              <img src={logoThumb} alt="stars" />
            </div>
            <div className={styles.Buttons}>
              {status === EWelcomeBotState.IN_PROGRESS ? (
                <p className={styles.Phar}>Ожидание подтверждения</p>
              ) : (
                <Button
                  onClick={() => {
                    buttonClickHandler();
                  }}
                  className={styles.Button}
                  variant={isLinked ? 'primary' : 'tetriary'}
                >
                  {isLinked ? 'Настроить' : 'Подключить'}
                </Button>
              )}
            </div>
          </div>
          {open && <ConnectWelcomeBotModal service={service} onClose={onClose} isOpen={open} />}
          {settingsOpen && (
            <SettingsWelcomeBotModal
              service={service}
              isOpen={settingsOpen}
              onModalClose={onSettingsClose}
              onClose={() => {}}
            />
          )}
        </>
      )}
    </>
  );
};
