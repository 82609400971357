export enum TranslationRepeatTime {
  NOT_REPEAT = 'NOT_REPEAT',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  OTHER = 'OTHER',
  YEARLY = 'YEARLY',
  WEEKEND = 'WEEKEND',
  WORKDAY = 'WORKDAY',
}

export enum WeekDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
export const shortWeeks = Object.freeze({
  [WeekDays.MONDAY]: 'Mon',
  [WeekDays.TUESDAY]: 'Tue',
  [WeekDays.WEDNESDAY]: 'Wed',
  [WeekDays.THURSDAY]: 'Thu',
  [WeekDays.FRIDAY]: 'Fri',
  [WeekDays.SATURDAY]: 'Sat',
  [WeekDays.SUNDAY]: 'Sun',
});

export enum TranslationPeriodTime {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum TranslationEndingTime {
  DATE = 'DATE',
  NEVER = 'NEVER',
  AFTER = 'AFTER',
}

export const periodDurations: { [key: string]: string } = Object.freeze({
  [TranslationPeriodTime.DAY]: 'дней',
  [TranslationPeriodTime.WEEK]: 'неделей',
  [TranslationPeriodTime.MONTH]: 'месяцев',
  [TranslationPeriodTime.YEAR]: 'лет',
});

export const selectOptions = [
  {
    label: 'Не повторять',
    value: TranslationRepeatTime.NOT_REPEAT,
  },
  {
    label: 'Ежедневно',
    value: TranslationRepeatTime.DAILY,
  },
  {
    label: 'Еженедельно',
    value: TranslationRepeatTime.WEEKLY,
  },
];

export const intervalOptions = [
  {
    label: 'День',
    value: TranslationPeriodTime.DAY,
  },
  {
    label: 'Неделя',
    value: TranslationPeriodTime.WEEK,
  },
  {
    label: 'Месяц',
    value: TranslationPeriodTime.MONTH,
  },
  {
    label: 'Год',
    value: TranslationPeriodTime.YEAR,
  },
];

export const endingSelect = [
  {
    label: 'Дата',
    value: TranslationEndingTime.DATE,
  },
  {
    label: 'Никогда',
    value: TranslationEndingTime.NEVER,
  },
  {
    label: 'После',
    value: TranslationEndingTime.AFTER,
  },
];
