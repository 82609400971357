export const StripesIcon = () => {
  return (
    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <line x1="8.90625" y1="1" x2="0.90625" y2="1" stroke="#FDFDFD" />
        <line x1="0.90625" y1="5" x2="8.90625" y2="5" stroke="#FDFDFD" />
        <line x1="0.90625" y1="9" x2="8.90625" y2="9" stroke="#FDFDFD" />
      </g>
    </svg>
  );
};
