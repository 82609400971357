import { FC } from 'react';
import { useForm } from 'react-hook-form';

import styles from './Form.module.scss';
import {
  Button as TButton,
  Component,
  EClickAction,
  EditorMode,
  Form as TForm,
} from 'modules/my-pages/my-pages.types';
import Button from '../Button';
import Input from './components/Input';
import { useSendFormMutation } from 'modules/landing-dashboard/landing-dashboard.api';
import { FormFieldMapper } from 'modules/my-pages/my-pages.helpers';
import { FormSendRequestForm } from 'modules/landing-dashboard/landing-dashboard.types';
import CheckBox from 'components/CheckBox';
import useDisclosure from 'hooks/useDisclosure';

type FormType = { [K in FormSendRequestForm]?: string };

type Props = {
  component: Component<TForm>;
  mode?: EditorMode;
};

const Form: FC<Props> = ({ component, mode }) => {
  const { data } = component;
  const [sendForm] = useSendFormMutation();
  const { handleSubmit, register, reset } = useForm<FormType>();
  const { open: agreesWithPolicy, toggleOpen: toggleAgreesWithPolicy, onClose } = useDisclosure();

  const onSubmit = handleSubmit(async formData => {
    if (mode === 'published') {
      await sendForm({ ...formData, componentId: component.id as string }).unwrap();
      reset();
      onClose();
    }
  });

  const borderColor = data.inputStyle.borderColor;
  const bgColor = data.inputStyle.bgColor;
  const labelColor = borderColor === '#303031' ? 'white' : borderColor;
  const checkBoxBackgroundColor = bgColor === '#181818' ? '#0f0f13' : bgColor;

  return (
    <form className={styles.Form} onSubmit={onSubmit}>
      <div className={styles.Fields}>
        {[...data.fields]
          .sort((a, b) => a.order - b.order)
          .map(field => (
            <Input
              key={field.type}
              style={data.inputStyle}
              type={field.type}
              register={register(FormFieldMapper[field.type])}
              maxLength={120}
              labelColor={labelColor}
            />
          ))}

        <CheckBox
          className={styles.Checkbox}
          checked={agreesWithPolicy}
          onChange={toggleAgreesWithPolicy}
          textClassName={styles.CheckboxText}
          text={
            <>
              Я подтверждаю, что ознакомлен(-а) с{' '}
              <a className={styles.Link} href={'#'} target="_blank">
                Политикой конфиденциальности
              </a>{' '}
              и даю свое согласие на обработку моих персональных данных.
            </>
          }
          backgroundColor={checkBoxBackgroundColor}
        />
      </div>

      <Button
        disabled={!agreesWithPolicy}
        component={
          {
            data: { ...data.buttonStyle, onClick: { action: EClickAction.FORM } },
          } as Component<TButton>
        }
      />
    </form>
  );
};

export default Form;
