import { FC, CSSProperties } from 'react';

import styles from './SocialNetwork.module.scss';
import {
  EOrientation,
  Landing,
  LandingButtonSocialNetwork,
  SocialNetworkProperties,
} from 'modules/landing-generator/landing-generator.types';
// import useAppDispatch from 'hooks/useAppDispatch';
// img
import vkImg from 'assets/img/social/vk.png';
import viberImg from 'assets/img/social/viber.png';
import whatsappImg from 'assets/img/social/whatsapp.png';
import instagramImg from 'assets/img/social/instagram.png';
import facebookImg from 'assets/img/social/facebook.png';
import tgImg from 'assets/img/social/tg.png';
// import { useRegisterMetricsMutation } from 'modules/cards/cards.api';

const imgMap: Record<LandingButtonSocialNetwork, { img: string; name: string }> = {
  vk: { img: vkImg, name: 'Vkontakte' },
  viber: { img: viberImg, name: 'Viber' },
  whatsapp: { img: whatsappImg, name: 'WhatsApp' },
  instagram: { img: instagramImg, name: 'Instagram' },
  facebook: { img: facebookImg, name: 'Facebook' },
  telegram: { img: tgImg, name: 'Telegram' },
};

type Props = {
  id: Landing['id'];
  btnId: Landing['id'];
  orientation: EOrientation;
  type: LandingButtonSocialNetwork;
  network: SocialNetworkProperties;
};

const SocialNetwork: FC<Props> = ({ type, network, orientation }) => {
  // const [register] = useRegisterMetricsMutation();
  // const dispatch = useAppDispatch();

  const cssVars = {
    '--bg-color': network.color,
  } as CSSProperties;

  // const handleLink = () => {
  //   register({ id: btnId, type: ElemType.LINK });
  //   // TODO: switch on type?
  //   // network.content
  // };

  return (
    <div
      className={styles.Wrapper}
      data-shape={network.shape}
      data-orientation={orientation}
      style={cssVars}
    >
      <img
        className={styles.Logo}
        src={imgMap[type].img}
        alt={`${network}'s logo`}
        width={32}
        height={32}
      />

      {orientation === EOrientation.VERTICAL && (
        <h3 className={styles.Name}>{imgMap[type].name}</h3>
      )}
    </div>
  );
};

export default SocialNetwork;
