import { FC, ReactNode } from 'react';

import IconButton from 'components/IconButton';

import styles from './FancyActionCard.module.scss';

type Props = {
  title: string;
  text: string;
  content: ReactNode;
  button?: ReactNode;
  buttonMobile?: ReactNode;
  noFancyBorder?: boolean;
  onClose?: () => void;
  buttonClose?: boolean;
};

const FancyActionCard: FC<Props> = ({
  title,
  text,
  content,
  button,
  buttonMobile,
  noFancyBorder,
  onClose,
  buttonClose,
}) => {
  return (
    <article className={styles.Wrapper} data-no-fancy-border={noFancyBorder}>
      <div className={styles.Card}>
        <div className={styles.Header}>
          <div>
            <h3 className={styles.Title}>{title}</h3>
          </div>
          {buttonClose && (
            <IconButton
              iconName="cross"
              iconSize={20}
              className={styles.ButtonClose}
              onClick={onClose}
            />
          )}
        </div>
        <p className={styles.Text}>{text}</p>
        <div className={styles.Content}>{content}</div>
        <div className={styles.Btn}>{button}</div>
        {buttonMobile && <div className={styles.BtnMobile}>{buttonMobile}</div>}
      </div>
    </article>
  );
};

export default FancyActionCard;
