export interface IObject {
  [field: string]: unknown;
}

export type Immutable<T> = {
  readonly [K in keyof T]: Immutable<T[K]>;
};

export enum ESex {
  MAN = 'Мужской',
  WOMAN = 'Женский',
  NOT_IMPORTANT = 'Не важно',
}

export enum ELastActivity {
  TODAY = 'Сегодня',
  YESTERDAY = 'Вчера',
  LAST_WEEK = 'Последняя неделя',
  NOT_IMPORTANT = 'Не важно',
}

export enum ETariffs {
  BASE = 'Base',
  STANDART = 'Standart',
  PRO = 'Pro',
}

export enum EPeriods {
  MONTH = 'Месяц',
  TMONTH = '3 месяца',
  HYEAR = 'Полгода',
  YEAR = 'Год',
}
