import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { ResetPasswordRequestBody } from 'modules/auth/auth.types';
import { ResetPasswordSchema } from 'modules/auth/auth.schema';
import { passTime } from 'modules/auth/auth.utils';
import useDispatch from 'hooks/useAppDispatch';
import { useResetPasswordMutation } from 'modules/auth/auth.api';

import AuthForm from 'modules/auth/components/AuthForm';
import Input from 'components/Input';
import TimerFeedback from 'modules/auth/components/TimerFeedback';

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const onEnter = () => {
    navigate(-1);
  };

  const onSubmit: SubmitHandler<ResetPasswordRequestBody> = async ({ email }) => {
    const res = await resetPassword({ email });

    if ('data' in res) {
      setSuccess(true);
    }

    passTime(dispatch);
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm<ResetPasswordRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema()),
  });

  const currentEmail = getValues('email');

  return success ? (
    <TimerFeedback email={currentEmail} />
  ) : (
    <AuthForm
      title="Не получается войти?"
      text="Введите Ваш Email и мы вышлем
      Вам ссылку для сброса пароля"
      buttonText="Сбросить пароль"
      footer={{ text: 'Назад к', btn: { text: 'Войти', onClick: onEnter } }}
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input label="Email" register={register('email')} type="email" invalid={!!errors.email} />
    </AuthForm>
  );
};

export default ResetPassword;
