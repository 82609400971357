import sortBy from 'lodash-es/sortBy';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';
import toPairs from 'lodash-es/toPairs';

import {
  Landing,
  LandingArray,
  LandingButton,
  LandingNormalized,
  LandingText,
  Ordered,
} from './landing-generator.types';

class GlobalOrder {
  private value = 0;
  inc() {
    // eslint-disable-next-line no-plusplus
    return this.value++;
  }
  set newOrder(newValue: number) {
    this.value = newValue + 1;
  }
}

export const GlobalOrderInstance = new GlobalOrder();
export const GlobalIdInstance = new GlobalOrder();

export const removeIdsFromLandings = (landings: Landing[]): LandingNormalized[] =>
  landings.map(landing => {
    const landingNoId = omit(landing, ['id', 'buttons']);
    return { ...landingNoId, buttons: landing.buttons.map(button => omit(button, 'id')) };
  });

const orderInner = <T>(elem: T & Ordered) => ({
  ...elem,
  order: elem?.order ?? GlobalOrderInstance.inc(),
});

export const enumirateLanding = (landing: Landing): Landing => {
  const newLanding = cloneDeep(landing);
  newLanding.buttons = newLanding.buttons.map(orderInner);
  if (newLanding.text) {
    newLanding.text.title = newLanding.text.title.map(orderInner);
    newLanding.text.subtitle = newLanding.text.subtitle.map(orderInner);
    newLanding.text.text = newLanding.text.text.map(orderInner);
  }
  if (newLanding.video)
    newLanding.video.order = newLanding.video?.order ?? GlobalOrderInstance.inc();
  if (newLanding.form) newLanding.form.order = newLanding.form?.order ?? GlobalOrderInstance.inc();
  if (newLanding.photo)
    newLanding.photo.order = newLanding.photo?.order ?? GlobalOrderInstance.inc();
  return newLanding;
};

const unwrapLandingButtons = (buttons: LandingButton[]) =>
  buttons.map(button => ({ type: 'button', value: button }));

const unwrapLandingText = (text: LandingText) => {
  const newTitle = text.title.map((_title, index) => ({ type: 'title', value: _title, index }));
  const newSubtitle = text.subtitle.map((_subtitle, index) => ({
    type: 'subtitle',
    value: _subtitle,
    index,
  }));
  const newText = text.text.map((_text, index) => ({ type: 'text', value: _text, index }));
  return newTitle.concat(newSubtitle, newText);
};

export const flattenLandingSorted = (landing: Landing) => {
  const elems = toPairs(omit(landing, ['id', 'color', 'name']))
    .filter(([, value]) => !!value)
    .flatMap(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ([key, value]: [string, any]) =>
        key === 'buttons'
          ? unwrapLandingButtons(value as LandingButton[])
          : key === 'text'
          ? unwrapLandingText(value as LandingText)
          : [{ type: key, value }],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any as LandingArray;
  return sortBy(elems, e => e.value.order);
};
