import Icon from 'components/Icon';
import { FC } from 'react';

import useCopyToClipboard from 'hooks/useCopyToClipboard';
import styles from './ReferralLink.module.scss';
import useTypedSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/account/account.selector';
import r from 'constants/routes';
import env from 'config/env';

const ReferralLink: FC = () => {
  const user = useTypedSelector(selectUser);
  const { copyAndNotify } = useCopyToClipboard();
  const refLink = `${env.APP_FRONT}${r.auth.signUp.registration}?referralCode=${user?.username}`;

  return (
    <div className={styles.Block}>
      <h2 className={styles.Title}>Моя реферальная ссылка</h2>
      <div
        className={styles.Wrapper}
        onClick={() => {
          if (user?.username) copyAndNotify(refLink);
        }}
      >
        <p className={styles.Link}>{refLink}</p>
        <Icon size={22} iconName="copy" className={styles.Icon} />
      </div>
    </div>
  );
};

export default ReferralLink;
