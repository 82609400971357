import { useCallback, useEffect, useState } from 'react';

import useScrollLock from './useScrollLock';

type DisclosureOptions = {
  blockScroll?: boolean;
  firstValue?: boolean;
};

function useDisclosure(options?: DisclosureOptions): {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  toggleOpen: () => void;
} {
  const { lock, unlock } = useScrollLock();
  const [open, setOpen] = useState(options?.firstValue ?? false);

  useEffect(() => {
    if (options?.blockScroll) {
      if (open) lock();
      else unlock();
    }
  }, [open, options?.blockScroll, lock, unlock]);

  return {
    open,
    onOpen: useCallback(() => setOpen(true), []),
    onClose: useCallback(() => setOpen(false), []),
    toggleOpen: useCallback(() => setOpen(prev => !prev), []),
  };
}

export default useDisclosure;
