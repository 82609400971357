import { FC } from 'react';

import styles from './PartnershipSearchField.module.scss';
import SearchField from 'components/SearchField';
import useAppDispatch from 'hooks/useAppDispatch';
import { searchUser } from 'modules/partnership-program/partnership-program.reducer';

const PartnershipSearchField: FC = () => {
  const dispatch = useAppDispatch();
  return (
    <SearchField
      className={styles.SearchField}
      placeholder="Поиск партнера"
      debounceDelay={1000}
      onSearch={user => dispatch(searchUser(user))}
    />
  );
};

export default PartnershipSearchField;
