import { RootState } from 'config/store';
import useTypedSelector from 'hooks/useAppSelector';

export const selectStatisticsId = (state: RootState) => state.landingDashBoard.id;

export const useSelectStatisticsId = () => useTypedSelector(selectStatisticsId);

export const selectPeriodType = (state: RootState) => state.landingDashBoard.periodType;

export const useSelectPeriodType = () => useTypedSelector(selectPeriodType);

export const useNewCardDialog = () => useTypedSelector(state => state.landingDashBoard.newCard);

export const useSelectFormComponentId = () =>
  useTypedSelector(state => state.landingDashBoard.formComponentId);
export const useApplicationsSelectIds = () => useTypedSelector(state => state.landingDashBoard.ids);
