import { FC, ReactNode } from 'react';

import styles from './EditorSubtitle.module.scss';

type Props = {
  children: ReactNode;
};

const EditorSubtitle: FC<Props> = ({ children }) => {
  return <h4 className={styles.Subtitle}>{children}</h4>;
};

export default EditorSubtitle;
