import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Accordion.module.scss';
import useDisclosure from 'hooks/useDisclosure';

type Props = {
  trigger: (isOpen: boolean) => JSX.Element;
  children: ReactNode;
  contentClassName?: string;
  wrapperClassName?: string;
  firstOpen?: boolean;
  contentMargin?: number;
};

const Accordion: FC<Props> = ({
  trigger,
  contentClassName,
  children,
  firstOpen,
  wrapperClassName,
  contentMargin = 24,
}) => {
  const { open, toggleOpen } = useDisclosure({ firstValue: firstOpen ?? false });

  return (
    <div className={classNames(styles.Accordion, wrapperClassName)} data-open={open}>
      <div className={styles.Trigger} onClick={toggleOpen}>
        {trigger(open)}
      </div>

      <div className={styles.ContentWrapper}>
        <div
          className={classNames(styles.Content, contentClassName)}
          style={{ marginTop: open ? contentMargin : undefined }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
