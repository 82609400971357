import { FC } from 'react';

import styles from './Link.module.scss';
import { LandingButton } from 'modules/landing-generator/landing-generator.types';
import LinksList from './components/LinksList';
import Website from './components/Website';
import Modalable from '../Modalable';

type Props = {
  button: LandingButton;
};

// TODO: remove id from inner
const Link: FC<Props> = ({ button }) => {
  return (
    <div className={styles.Wrapper}>
      <Modalable button={button.modal}>
        <LinksList id={'1'} button={button} />
      </Modalable>

      {button.websites.map((ws, index) => (
        <Website id={'2'} key={index} index={index} btnId={button.id} website={ws} />
      ))}
    </div>
  );
};

export default Link;
