import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Bots from './pages/Bots';
import BotsLayout from './components/BotsLayout';
import BotPageLayout from './components/BotPageLayout';
import Scenarios from './pages/Scenarios';
import Mailing from './pages/Mailing';

function BotBuilderRoutes(): JSX.Element {
  return (
    <>
      <Route path={r.botBuilder.index} element={<BotsLayout />}>
        <Route path={r.botBuilder.index} element={<Bots />} />
        <Route path={r.botBuilder.scenarios.index} element={<BotPageLayout />}>
          <Route path={r.botBuilder.scenarios.scenarios} element={<Scenarios />} />
          <Route path={r.botBuilder.scenarios.mailing} element={<Mailing />} />
        </Route>
      </Route>
    </>
  );
}

export default BotBuilderRoutes;
