import { FC } from 'react';
import classNames from 'classnames';
import { Dialog } from '@headlessui/react';

import styles from './ModalWithoutForm.module.scss';
import Button from 'components/Button';
import ModalOverlay from 'components/ModalOverlay';
import ColorfulBorder from 'components/ColorfulBorder';
import { Props } from './ModalWithoutForm.types';

export const ModalWithoutForm: FC<Props> = ({
  isOpen,
  onClose,
  isValid,
  className,
  formClassName,
  children,
  btnText = 'Подтвердить',
  noBtn,
  horizontal,
  isNoColorBorder,
  modalTitle,
  onClick,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ModalOverlay>
        <Dialog.Panel>
          <ColorfulBorder
            className={classNames(styles.Wrapper, {
              [styles.Horizontal]: horizontal,
              [styles.None]: isNoColorBorder,
            })}
          >
            <div className={classNames(styles.Content, className)}>
              <h2 className={styles.ModalTitle}>{modalTitle}</h2>
              <div className={classNames(styles.ModalContent, formClassName)}>
                {children}
                <div
                  className={classNames(styles.Btns, {
                    [styles.NoBtn]: noBtn,
                  })}
                >
                  <Button className={styles.BtnCLose} variant="text" onClick={onClose}>
                    Закрыть
                  </Button>
                  <Button
                    className={styles.Btn}
                    variant="primary"
                    type="submit"
                    disabled={!isValid}
                    onClick={onClick}
                  >
                    {btnText}
                  </Button>
                </div>
              </div>
            </div>
          </ColorfulBorder>
        </Dialog.Panel>
      </ModalOverlay>
    </Dialog>
  );
};
