export const getDeviceType = () => {
  if (window.innerWidth <= 670) {
    return 'mobile';
  }

  if (window.innerWidth > 670 && window.innerWidth <= 1100) {
    return 'tablet';
  }

  return 'desktop';
};
