import { FC, useMemo, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TextArea from 'components/TextArea';
import FormPageTemplate from 'components/FormPageTemplate';
import {
  CommentsRequestBody,
  TEditComment,
  TUpdateVkSetting,
  TVkSettings,
} from 'modules/vk/vk.types';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import ToggleSwitchWithLabel from 'components/ToggleSwitchWithLabel';
import {
  useCreateCommentMutation,
  useDeleteCommentByIdMutation,
  useEditCommentListMutation,
  useGetListCommentQuery,
  useGetVkUserSettingQuery,
  useUpdateVkUserSettingMutation,
} from 'modules/vk/vk.api';
import { first3PhotoCommentsSchema } from 'modules/vk/vk.schema';
import { useGetAllVkAccounts } from '../../vk.selectors';
import { useParams } from 'react-router-dom';
import { isEmpty, isString, sortBy } from 'lodash-es';
import First3EmptyFields from './First3EmptyFields';
import LoadingState from '../../components/LoadingState';

const First3PhotoComments: FC = () => {
  const vkAccountList = useGetAllVkAccounts();
  const { id } = useParams();

  const { data: commentsList, isFetching: isCommentsLoading } = useGetListCommentQuery();
  const { data: settingsData, isFetching: isSettingsDataLoading } = useGetVkUserSettingQuery();

  const [updateSetting] = useUpdateVkUserSettingMutation();
  const [updateComments] = useEditCommentListMutation();
  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentByIdMutation();
  const cachedValues = useRef<CommentsRequestBody>({});

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors, touchedFields },
  } = useForm<CommentsRequestBody>({
    mode: 'onChange',
    defaultValues: { hitLike: false },
    resolver: yupResolver(first3PhotoCommentsSchema()),
  });

  const onSubmit: SubmitHandler<CommentsRequestBody> = async formData => {
    const changedComments: TEditComment[] = [];
    const touchedFieldsKeys = Object.keys(touchedFields);
    let successState = false;

    if (formData.edit && isString(formData.edit)) {
      const res = await createComment({ comment: formData.edit });
      if ('data' in res && res?.data.success) {
        successState = true;
      }
    }
    if (!isEmpty(touchedFieldsKeys)) {
      const tempObj = { ...formData };
      delete tempObj.edit;
      delete tempObj.hitLike;

      for (const key of touchedFieldsKeys) {
        const resultObj: TEditComment = { id: NaN, comment: '' };
        resultObj.id = +key;
        resultObj.comment = tempObj[key] as string;
        if (!Number.isNaN(resultObj.id)) changedComments.push(resultObj);
      }

      const res = await updateComments({ comments: changedComments });
      if ('data' in res && res?.data.success) {
        successState = true;
        reset();
      }
    }

    if (successState) {
      await showFeedback({
        title: 'ГОТОВО!',
        text: 'Успешно',
        type: EFeedbackType.SUCCESS,
      });
    }
    reset();
  };

  const updateSettingHandler = async (arg: TUpdateVkSetting) => {
    await updateSetting({ data: [arg] });
  };

  const deleteCommentHandler = async (commentId: string) => {
    const res = await deleteComment({ commentId });
    if ('data' in res) {
      await showFeedback({
        title: 'ГОТОВО!',
        text: 'Вы удалили комментарий ',
        type: EFeedbackType.SUCCESS,
      });
    }
  };

  const currSetting: TVkSettings = useMemo(() => {
    if (id && !isEmpty(settingsData?.settings)) {
      return { ...settingsData?.settings.find(setting => setting.id === +id) } as TVkSettings;
    }
    return {} as TVkSettings;
  }, [settingsData, id]);

  const sortedComments = useMemo(() => {
    return sortBy(commentsList?.data, ['id']);
  }, [commentsList]);

  const handleCachedValues = (values: CommentsRequestBody) => {
    cachedValues.current = values;
  };

  if (isEmpty(vkAccountList)) return null;
  if (isSettingsDataLoading || isCommentsLoading) return <LoadingState />;

  return (
    <>
      {isEmpty(sortedComments) ? (
        <First3EmptyFields
          cachedValues={cachedValues.current}
          currSetting={currSetting}
          updateSettingHandler={updateSettingHandler}
          handleCachedValues={handleCachedValues}
        />
      ) : (
        <FormPageTemplate
          title={currSetting.description}
          description="Стоит написать разный текст, чтобы не улететь в бан за одинаковый текст. Не волнуйтесть, тексты можно будет потом редактировать!!"
          onSubmit={handleSubmit(onSubmit)}
          buttonText="Сохранить"
          isValid={isValid}
          headerAddon={
            <ToggleSwitchWithLabel
              active={currSetting.isEnabled}
              toggle={() => {
                if (id)
                  updateSettingHandler({
                    id: +id,
                    isEnabled: !currSetting.isEnabled,
                  });
              }}
            />
          }
          short
        >
          {sortedComments.map((comment, idx) => {
            return (
              <TextArea
                key={comment.id}
                label={`Вариант текста ${idx + 1}`}
                register={register(`${comment.id}`)}
                invalid={!!errors[`${comment.id}`]?.message}
                errorMsg={errors[`${comment.id}`]?.message}
                defaultValue={comment.comment}
                icons={[
                  { name: 'alert-delete', onClick: () => deleteCommentHandler('' + comment.id) },
                ]}
              />
            );
          })}
          <TextArea
            label="Добавить вариант +"
            register={register('edit')}
            invalid={!!errors.text1?.message}
            errorMsg={errors.text1?.message}
          />
        </FormPageTemplate>
      )}
    </>
  );
};

export default First3PhotoComments;
