import { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './ModalWithForm.module.scss';
import Modal, { CommonModalProps } from 'components/Modal';
import SmallLoader from '../SmallLoader';

type Props = {
  title: string;
  text?: string;
  btnText?: string;
  onSubmit: () => void;
  isValid: boolean;
  children: ReactNode;
  className?: string;
  formClassName?: string;
  noBtn?: boolean;
  isLoading?: boolean;
} & CommonModalProps;

const ModalWithForm = forwardRef<HTMLDivElement, Props>(
  ({ title, text, className, children, isLoading, ...modalProps }, ref) => {
    return (
      <Modal ref={ref} className={classNames(styles.Modal, className)} {...modalProps}>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <>
            <h2 className={styles.Title}>{title}</h2>
            {text ? <p className={styles.Text}>{text}</p> : null}
            {children}
          </>
        )}
      </Modal>
    );
  },
);

export default ModalWithForm;
