import { FC } from 'react';
import classNames from 'classnames';
import styles from '../DateModal.module.scss';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  current: Dayjs;
  selected: Dayjs;
  day: number;
  onClick: () => void;
};

const Day: FC<Props> = ({ current, selected, day, onClick }) => {
  const now = dayjs();
  const myDate = selected.toDate();
  myDate.setDate(day + 2);
  const temp = dayjs(myDate);
  temp.set('day', day);

  return (
    <span
      key={`curr-${day}`}
      onClick={onClick}
      className={classNames(styles.Day, {
        [styles.DaySelected]: selected.date() === day + 1,
        [styles.DayCurrent]: selected.isSame(current, 'month') && current.date() === day + 1,
        [styles.DayBefore]: temp.isBefore(now),
      })}
    >
      {day + 1}
    </span>
  );
};

export default Day;
