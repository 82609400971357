import { forwardRef, HTMLProps, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './CheckBox.module.scss';

type Props = {
  text?: ReactNode;
  rightText?: ReactNode;
  alignCenter?: boolean;
  className?: string;
  type?:
    | 'circle'
    | 'point'
    | 'blue-circle'
    | 'small-circle'
    | 'mobile-blue-point'
    | 'small-circle-mobile'
    | 'gradient'
    | 'small-gradient';
  textClassName?: string;
  backgroundColor?: string;
} & HTMLProps<HTMLInputElement>;

const CheckBox = forwardRef<HTMLInputElement, Props>(
  ({ text, alignCenter, className, type, backgroundColor, textClassName, ...rest }, ref) => {
    return (
      <div className={classNames(styles.Wrapper, className)} data-centered={alignCenter}>
        {rest.rightText && (
          <p className={classNames(styles.Text, textClassName)}>{rest.rightText}</p>
        )}

        <label className={styles.Container}>
          <input type="checkbox" className={styles.Input} {...rest} ref={ref} />
          <span
            className={styles.CheckMark}
            data-type={type}
            style={{ backgroundColor: backgroundColor }}
          ></span>
        </label>

        {text && <p className={classNames(styles.Text, textClassName)}>{text}</p>}
      </div>
    );
  },
);

export default CheckBox;
