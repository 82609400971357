import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import EmptyPage from 'components/EmptyPage';
import docsImg from 'assets/svg/empty-pages/docs.svg';
import r from 'constants/routes';
import BotCard, { BotCardProps } from 'components/BotCard';
import AutomationPageLayout from 'modules/vk/components/AutomationPageLayout';

const DbParsing: FC = () => {
  const navigate = useNavigate();

  const items: BotCardProps[] = [
    {
      name: 'Для РБ',
      link: generatePath(r.vk.dbParsing.edit, { id: '1' }),
      headerRight: <BotCard.Update onUpdate={() => {}} />,
      subHeader: <BotCard.LastUpdate date={'12/12/2022 19:46'} />,
      data: (
        <>
          <BotCard.DataEntry
            iconName="target"
            name="Цель"
            value={<BotCard.DataEntry.Success>Выполнена</BotCard.DataEntry.Success>}
          />
          <BotCard.DataEntry iconName="city" name="Минск, Гродно Брест" value={5} />
          <BotCard.DataEntry iconName="sex" name="Пол" value={'Мужской'} />
          <BotCard.DataEntry iconName="friends" name="Друзья" value={'320 - 3423'} />
          <BotCard.DataEntry iconName="activity" name="Активность" value={'Сегодня'} />
        </>
      ),
      subFooter: <BotCard.SaveToTable onSave={() => {}} />,
    },
    {
      name: 'Для РБ 2',
      link: generatePath(r.vk.dbParsing.edit, { id: '1' }),
      headerRight: <></>,
      subHeader: <BotCard.LastUpdate date={'12/12/2022 19:46'} />,
      data: (
        <>
          <BotCard.DataEntry
            iconName="target"
            name="Цель"
            value={<BotCard.DataEntry.Range total={13} of={24} />}
          />
          <BotCard.DataEntry iconName="city" name="Минск, Гродно Брест" value={5} />
          <BotCard.DataEntry iconName="sex" name="Пол" value={'Мужской'} />
          <BotCard.DataEntry iconName="friends" name="Друзья" value={'320 - 3423'} />
          <BotCard.DataEntry iconName="activity" name="Активность" value={'Сегодня'} />
        </>
      ),
      footer: <BotCard.Loading />,
    },
  ];

  return (
    <AutomationPageLayout
      title="Парсинг баз для рассылок"
      emptyPage={
        <EmptyPage
          img={<img src={docsImg} alt="Docs" width={164} height={204} />}
          title="укажите источники для парсинга"
          text="В несколько шагов"
          btnText="Указать источники"
          onClick={() => navigate(generatePath(r.vk.dbParsing.edit, { id: '1' }))}
        />
      }
      cards={items.map(item => (
        <BotCard key={item.name} {...item} />
      ))}
      onSearch={() => {}}
      onAdd={() => navigate(generatePath(r.vk.dbParsing.edit, { id: '1' }))}
      addText="Добавить источники"
    />
  );
};

export default DbParsing;
