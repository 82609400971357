import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import union from 'lodash-es/union';

import { EPlanName } from 'modules/account/account.interfaces';
import { EndDate, PartnershipProgramSliceState } from './partnership-program.types';

const initialState: PartnershipProgramSliceState = {
  filters: {
    search: '',
    offset: 0,
  },
  selectedPartners: [],
  mailModal: false,
};

const partnershipProgramSlice = createSlice({
  name: 'partnership-program',
  initialState,
  reducers: {
    addPartners: (state, { payload }: PayloadAction<Array<string | number>>) => {
      state.selectedPartners = union(state.selectedPartners, payload);
    },
    writeAll: state => {
      state.mailModal = true;
    },
    togglePartner: (state, { payload }: PayloadAction<string | number>) => {
      const found = state.selectedPartners.findIndex(partner => partner === payload);
      if (found !== -1) state.selectedPartners.splice(found, 1);
      else state.selectedPartners.push(payload);
    },
    clearPartners: state => {
      state.selectedPartners = [];
    },
    selectPlan: (state, { payload }: PayloadAction<EPlanName | null>) => {
      state.filters.plan = payload;
    },
    selectEndDate: (state, { payload }: PayloadAction<EndDate | null>) => {
      state.filters.endDate = payload;
    },
    searchUser: (
      state,
      { payload }: PayloadAction<PartnershipProgramSliceState['filters']['search']>,
    ) => {
      state.filters.search = payload;
    },
    setMailModal: (state, { payload }: PayloadAction<boolean>) => {
      state.mailModal = payload;
    },
    setOffset: (state, { payload }: PayloadAction<number>) => {
      state.filters.offset = payload;
    },
  },
});

export const {
  addPartners,
  clearPartners,
  togglePartner,
  searchUser,
  selectEndDate,
  selectPlan,
  setMailModal,
  writeAll,
  setOffset,
} = partnershipProgramSlice.actions;

export default partnershipProgramSlice.reducer;
