import { FC, useEffect } from 'react';
import FormPageTemplate from 'components/FormPageTemplate';
import TextArea from 'components/TextArea';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CommentsRequestBody } from '../../vk.types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { first3FieldCommentsSchema } from '../../vk.schema';
import { useParams } from 'react-router-dom';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { useCreateFirstThreeCommentsMutation } from '../../vk.api';
import { isEmpty } from 'lodash-es';

type Props = {
  cachedValues?: CommentsRequestBody;
  handleCachedValues?: (arg: CommentsRequestBody) => void;
};

const EmptyCommentsField: FC<Props> = ({ cachedValues, handleCachedValues }) => {
  const { folderId, parsingId } = useParams();
  const [createFirstThreeComment] = useCreateFirstThreeCommentsMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<CommentsRequestBody>({
    mode: 'onChange',
    defaultValues: { hitLike: false },
    resolver: yupResolver(first3FieldCommentsSchema()),
  });

  const onSubmit: SubmitHandler<CommentsRequestBody> = formData => {
    const cloned = { ...formData };
    delete cloned.hitLike;
    const comments: string[] = Object.values(cloned) as string[];

    if (handleCachedValues) {
      handleCachedValues(cloned);
    }

    createFirstThreeComment({
      comments,
      data: {
        folderId,
        parsingId,
      },
    }).then(res => {
      if ('data' in res) {
        showFeedback({
          title: 'ГОТОВО!',
          text: 'Успешно',
          type: EFeedbackType.SUCCESS,
        });
        if (handleCachedValues) {
          handleCachedValues({});
        }
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(cachedValues)) {
      reset({
        ...cachedValues,
      });
    }
  }, [isEmpty(cachedValues)]);

  return (
    <FormPageTemplate
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
      title={'Комментарии пользователям'}
      description={
        'Стоит написать разный текст, чтобы не улететь в бан за одинаковый текст. Не волнуйтесть, тексты можно будет потом редактировать'
      }
    >
      <TextArea
        label="Добавить вариант +"
        register={register('1')}
        invalid={!!errors.text1?.message}
        errorMsg={errors.text1?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('2')}
        invalid={!!errors.text2?.message}
        errorMsg={errors.text2?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('3')}
        invalid={!!errors.text3?.message}
        errorMsg={errors.text3?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('4')}
        invalid={!!errors.text4?.message}
        errorMsg={errors.text4?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('5')}
        invalid={!!errors.text5?.message}
        errorMsg={errors.text5?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('6')}
        invalid={!!errors.text6?.message}
        errorMsg={errors.text6?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('7')}
        invalid={!!errors.text7?.message}
        errorMsg={errors.text7?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('8')}
        invalid={!!errors.text8?.message}
        errorMsg={errors.text8?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('9')}
        invalid={!!errors.text9?.message}
        errorMsg={errors.text9?.message}
      />
      <TextArea
        label="Добавить вариант +"
        register={register('10')}
        invalid={!!errors.text10?.message}
        errorMsg={errors.text10?.message}
      />
    </FormPageTemplate>
  );
};

export default EmptyCommentsField;
