import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import r from 'constants/routes';

import AuthForm from 'modules/auth/components/AuthForm';
import Button from 'components/Button';

const ChooseRegistrationType: FC = () => {
  const navigate = useNavigate();

  const onEnter = () => {
    navigate(r.auth.signIn);
  };

  return (
    <AuthForm
      title="Регистрация"
      text="Выберите вариант регистрации"
      footer={{ text: 'Уже есть аккаунт?', btn: { text: 'Войти', onClick: onEnter } }}
      isValid={true}
      onSubmit={() => {}}
    >
      <Button
        variant="viper"
        size="m"
        onClick={() => navigate(r.auth.signUp.invitor)}
        rightIcon="arrow-right"
        data-shape="square"
      >
        У меня есть пригласитель
      </Button>
      <Button
        variant="viper"
        size="m"
        onClick={() => navigate(r.auth.signUp.registration)}
        rightIcon="arrow-right"
        data-shape="square"
      >
        У меня нет пригласителя
      </Button>
    </AuthForm>
  );
};

export default ChooseRegistrationType;
