import { FC, ReactNode } from 'react';

import styles from './ToolsWrapper.module.scss';

type Props = {
  children: ReactNode;
};

const ToolsWrapper: FC<Props> = ({ children }) => {
  return <div className={styles.Wrapper}>{children}</div>;
};

export default ToolsWrapper;
