import { FC } from 'react';

import styles from './Landing.module.scss';

import { Landing } from 'modules/landing-generator/landing-generator.types';
import { flattenLandingSorted } from 'modules/landing-generator/landing-generator.utils';
import RenderElement from '../RenderElement';

const LandingElement: FC<{ landing: Landing }> = ({ landing }) => {
  const elements = flattenLandingSorted(landing);
  return (
    <article className={styles.Wrapper}>
      {elements.map(elem => (
        <RenderElement id={Number(landing.id)} key={JSON.stringify(elem)} elem={elem} />
      ))}
    </article>
  );
};

export default LandingElement;
