import rootApi from 'config/http';
import { IUser, TWelcome } from './account.interfaces';
import { EAppTagType, TResponse } from 'interfaces/api';

const accountApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<TResponse<IUser>, null>({
      query: () => ({ url: '/users/profile' }),
      providesTags: [EAppTagType.USER],
    }),
    integrationController: builder.query<TResponse<TWelcome[]>, unknown>({
      query: () => ({
        url: '/account/link',
        method: 'GET',
      }),
      providesTags: [EAppTagType.INTEGRATION_CONTROLLER],
    }),
    linkBotIntoUser: builder.mutation<
      TResponse<null>,
      Pick<TWelcome, 'service' & 'serviceUsername'>
    >({
      query: body => ({
        url: '/account/link',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.INTEGRATION_CONTROLLER],
    }),

    toggleWelcomeBot: builder.mutation<unknown, { service: string }>({
      query: ({ service }) => ({
        url: `/account/link/${service}/toggle`,
        method: 'PATCH',
      }),
      invalidatesTags: [EAppTagType.INTEGRATION_CONTROLLER],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useIntegrationControllerQuery,
  useLinkBotIntoUserMutation,
  useToggleWelcomeBotMutation,
} = accountApi;

export default accountApi;
