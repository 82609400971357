import { FC } from 'react';

import styles from './Income.module.scss';
import { useGetLineDetailsQuery } from 'modules/partnership-program/partnership-program.api';
import { useLinesParams } from 'modules/partnership-program/partnership-program.hooks';
import formatNumber from 'utils/formatNumber';
import env from 'config/env';
import Icon from 'components/Icon';

const Income: FC = () => {
  const line = useLinesParams();
  const { data, isLoading, isError } = useGetLineDetailsQuery({ line });

  const { percents, profit, allProfit } = data || {};

  if (isLoading) return null;
  if (isError) return null;

  return (
    <div className={styles.Card}>
      <div className={styles.Content}>
        {percents === 0 ? (
          <div className={styles.infoIconBlock}>
            <Icon iconName={'info-icon-blue'} />
            <div className={styles.Border}>
              <div className={styles.infoBlock}>
                <Icon iconName={'info-icon-blue'} />
                <h3>Обратите внимание!</h3>
                <p>
                  Чтобы получать бонус с данной линии вам необходимо приобрести тариф либо повысить
                  действующий.
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className={styles.Wrapper}>
          <p className={styles.Number}>{(percents || 0) * 100} %</p>
          <p className={styles.Text}>Процент получаемый с линии</p>
        </div>

        <div className={styles.Wrapper}>
          <p className={styles.Number}>
            {formatNumber(profit || 0, 0)} {env.CURRENCY}
          </p>
          <p className={styles.Text}>Доход получаемый с линии</p>
        </div>
        <div className={styles.Wrapper}>
          <p className={styles.Number}>
            {formatNumber(allProfit || 0, 0)} {env.CURRENCY}
          </p>
          <p className={styles.Text}>Общий доход</p>
        </div>
      </div>
    </div>
  );
};

export default Income;
