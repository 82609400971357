import { RootState } from 'config/store';
import useTypedSelector from 'hooks/useAppSelector';
import { IUser } from './account.interfaces';

export const useSelectBalance = () => useTypedSelector(state => state.account.user?.balance ?? 0);

export const selectUser = (state: RootState): IUser | null => state.account.user;

export const useSelectUser = () => useTypedSelector(selectUser);

export const selectInviter = (
  state: RootState,
):
  | Omit<IUser, 'inviter' | 'balance' | 'role' | 'permissions' | 'notification' | 'plan'>
  | null
  | undefined => state.account.user?.inviter;

export const useVkAccount = (): number | string | undefined =>
  useTypedSelector(state => state.account.accounts.vk);
