import { FC } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';

import env from 'config/env';
import r from 'constants/routes';
import styles from './BusinessCard.module.scss';
import Button from 'components/Button';
import { BusinessCardType } from 'modules/landing-dashboard/landing-dashboard.types';
import IconButton from 'components/IconButton';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import {
  useClonePageMutation,
  useDeletePageMutation,
} from 'modules/landing-dashboard/landing-dashboard.api';
import { getSheetPrerenderImage } from 'utils/getImage';

type Props = {
  data: BusinessCardType;
};

const BusinessCard: FC<Props> = ({ data }) => {
  const { copyAndNotify } = useCopyToClipboard();
  const [clonePage] = useClonePageMutation();
  const [deletePage] = useDeletePageMutation();
  const link = data.link
    ? `${env.APP_FRONT}${generatePath(r.myPages.published, { link: data.link })}`
    : null;
  const edit = generatePath(r.myPages.edit, { id: data.id.toString() });
  const preview = generatePath(r.myPages.preview, { id: data.id.toString() });

  return (
    <article className={styles.Card}>
      {data.isCopy && (
        <div className={styles.Copy}>
          <div className={styles.CopyIndicator} />
          <p className={styles.CopyText}>Копия</p>
        </div>
      )}

      <img
        className={styles.Image}
        src={getSheetPrerenderImage(data?.prerenderImage)}
        alt={data.name}
      />
      <section className={classNames(styles.Panel, styles.PanelTop)}>
        <Button as={Link} to={edit} className={styles.Button} variant="tetriary" size="l">
          Редактировать
        </Button>
      </section>
      <section className={classNames(styles.Panel, styles.PanelBottom)}>
        <div className={styles.Header}>
          <h2 className={styles.Title}>{data.name}</h2>
          <Menu as="div" className={styles.Menu}>
            <Menu.Button as={IconButton} iconName="dots" iconSize={14} className={styles.Dots} />
            <Menu.Items className={styles.MenuItems}>
              <Menu.Item as="span" onClick={() => clonePage({ id: data.id })}>
                Создать копию
              </Menu.Item>
              <Menu.Item as={Link} to={preview}>
                Просмотреть
              </Menu.Item>
              <Menu.Item as={Link} to={edit}>
                Редактировать
              </Menu.Item>
              <Menu.Item
                as="span"
                className={styles.Delete}
                onClick={() => deletePage({ id: data.id })}
              >
                Удалить
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
        {link && (
          <>
            <h3 className={styles.Subtitle}>URL-адрес сайта</h3>
            <div className={styles.Link} onClick={() => copyAndNotify(link)}>
              <span>{link}</span>
              <IconButton iconName="copy" iconSize={24} className={styles.Icon} />
            </div>
          </>
        )}
      </section>
    </article>
  );
};

export default BusinessCard;
