export const addsMap = Object.freeze({
  header: {
    imgUrl: '',
    link: '',
  },
  pos2: {
    imgUrl: '',
    link: '',
  },
  sidebar: {
    imgUrl: '',
    link: '',
  },
});
