import { FC, ReactNode } from 'react';

import styles from './SettingsFormModal.module.scss';
import Modal, { CommonModalProps } from 'components/Modal';

type Props = {
  title: string;
  text: string;
  btnText?: string;
  isValid: boolean;
  children: ReactNode;
  reset?: () => void;
  noBtn?: boolean;
} & CommonModalProps;

const SettingsFormModal: FC<Props> = ({ title, text, children, reset, onClose, ...modalProps }) => {
  const onModalClose = () => {
    reset?.();
    onClose();
  };

  return (
    <Modal className={styles.Modal} onClose={onModalClose} {...modalProps}>
      <h2 className={styles.Title}>{title}</h2>
      <p className={styles.Text}>{text}</p>
      {children}
    </Modal>
  );
};

export default SettingsFormModal;
