import { FC, useState } from 'react';
import Modal from 'components/Modal';
import { useSelectedCurrFinancesModal } from '../../finances.selectors';
import { BodyType, Sources, TPayeerAddForm } from '../../finances.types';
import useAppDispatch from 'hooks/useAppDispatch';
import { closeCurrModal } from '../../finances.reducer';
import styles from './PayeerConfirmModal.module.scss';
import Title from 'components/Title';
import payeer from 'assets/svg/finances/Payeer.svg';
import Input from 'components/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAddPayeerAmountMutation } from '../../finances.api';
import { number } from 'yup';
import Button from 'components/Button';

const PayeerConfirmModal: FC = () => {
  const [amountValue, setAmountValue] = useState('');
  const currSource = useSelectedCurrFinancesModal();
  const dispatch = useAppDispatch();
  const [addPayeer, { isLoading }] = useAddPayeerAmountMutation();

  const { register, reset, handleSubmit } = useForm<TPayeerAddForm>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<TPayeerAddForm> = form => {
    addPayeer({ ...form, curr: 'RUB' }).then(res => {
      if ('data' in res) {
        const urlBody: BodyType = res.data as unknown as BodyType;
        if (urlBody.body.url) window.open(urlBody.body.url, '_blank')?.focus();
        dispatch(closeCurrModal());
        reset();
        setAmountValue('');
      }
    });
  };

  const onlyNumbersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmountValue(e.target.value.replace(/\D/g, ''));
  };

  const isValid = number().min(50).isValidSync(amountValue);

  if (currSource !== Sources.PAYEER) return null;

  return (
    <Modal
      onSubmit={handleSubmit(onSubmit)}
      isValid={isValid}
      isOpen={currSource === Sources.PAYEER}
      onClose={() => {}}
      formClassName={styles.Root}
      noBtn
      isLoading={isLoading}
    >
      <Title className={styles.Title}>Пополнить баланс</Title>

      <Input
        register={register('amount')}
        type={'text'}
        label={'Сумма: минимальная 50'}
        renderInput={props => <input {...props} value={amountValue} onChange={onlyNumbersChange} />}
      />

      <img className={styles.Img} src={payeer} alt="" />
      <div className={styles.Btns}>
        <Button
          className={styles.BtnCLose}
          variant="text"
          onClick={() => {
            dispatch(closeCurrModal());
            setAmountValue('');
          }}
        >
          Закрыть
        </Button>
        <Button className={styles.Btn} variant="primary" type="submit" disabled={!isValid}>
          Подтвердить
        </Button>
      </div>
    </Modal>
  );
};

export default PayeerConfirmModal;
