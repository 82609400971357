import { useEffect, useState, useCallback, ChangeEvent, KeyboardEvent } from 'react';

import useFirstRender from 'hooks/useFirstRender';

export type SearchFieldProps = {
  onSearch: (searchQuery?: string) => void;
  debounceDelay?: number;
  stringLengthToSearch?: number;
  defaultValue?: string;
};

function useSearchField({
  onSearch,
  debounceDelay = 1000,
  stringLengthToSearch,
  defaultValue,
}: SearchFieldProps): {
  value: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  updateSearchQuery: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
} {
  const firstRender = useFirstRender();
  const [searchQuery, setSearchQuery] = useState(defaultValue || '');

  const updateSearchQuery = useCallback(() => {
    if (stringLengthToSearch && searchQuery.length < stringLengthToSearch) {
      // don't call onSearch callback if search query's lenth is smaller than
      // stringLengthToSearch prop's value
      return;
    }

    if (searchQuery === '') {
      // call onSearch callback with no value if search query is an empty string
      onSearch();
      return;
    }

    onSearch(searchQuery);
  }, [searchQuery, stringLengthToSearch]);

  useEffect(() => {
    if (firstRender) return;
    // debounce
    const timeout = setTimeout(updateSearchQuery, debounceDelay);
    return () => clearTimeout(timeout);
  }, [debounceDelay, firstRender, updateSearchQuery]);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        updateSearchQuery();
      }
    },
    [updateSearchQuery],
  );

  return { value: searchQuery, setSearchQuery, updateSearchQuery, onChange, onKeyDown };
}

export default useSearchField;
