import { FC, useState } from 'react';

import BotMessageCardDisplay from './BotMessageCardDisplay';
import BotMessageCardEdit from './BotMessageCardEdit';

const BotMessageCard: FC = () => {
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!edit);

  if (edit) return <BotMessageCardEdit onClick={toggleEdit} />;
  return <BotMessageCardDisplay onClick={toggleEdit} />;
};

export default BotMessageCard;
