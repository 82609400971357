import { CSSProperties, FC } from 'react';

import styles from './YouTubeVideo.module.scss';
import { Component, YouTubeVideo as TYouTubeVideo } from 'modules/my-pages/my-pages.types';
import Icon from 'components/Icon';

function youtubeParser(url: string) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

type Props = {
  component: Component<TYouTubeVideo>;
  width?: CSSProperties['width'];
  height?: number;
};

const YouTubeVideo: FC<Props> = ({ component, width = 292, height = 148 }) => {
  const { data } = component;

  const parsedVideoId = youtubeParser(data.link ?? '');

  const style: CSSProperties = { width, height };

  return (
    <>
      {parsedVideoId ? (
        <iframe
          className={styles.Frame}
          style={style}
          src={`https://www.youtube.com/embed/${parsedVideoId}`}
        ></iframe>
      ) : (
        <div className={styles.NoVideo} style={style}>
          <Icon iconName="play-film" size={24} />
        </div>
      )}
    </>
  );
};

export default YouTubeVideo;
