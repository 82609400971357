import useTypedSelector from 'hooks/useAppSelector';

export const useTelegramGreetingModal = () =>
  useTypedSelector(state => state.telegram.telegram.greetingBot);
export const useTelegramAdminBotChats = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.adminBotChats);
export const useTelegramAdminBotMessages = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.adminBotMessages);
export const useAdminBotsChatParams = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.adminBotChatParams);

export const useAdminMessageChatParams = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.messagesPageParams);

export const useCommandsParams = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.commandsPageParams);

export const useSentMessagesAdminParams = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.sentMessagesPageParams);
export const useAdminSelectedMessageParams = () =>
  useTypedSelector(state => state.telegramPersisted.selectedMessage);

export const useAdminBotName = () => useTypedSelector(state => state.telegramPersisted.botName);

export const useAdminSelectedCommandsIds = () =>
  useTypedSelector(state => state.telegramPersisted.selectedCommands);
export const useAdminSelectedChats = () => useTypedSelector(state => state.telegramPersisted.chats);
export const useAdminSelectedCommands = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.adminBotCommands);

export const useAdminScheduledMessages = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.scheduledMessages);

export const useAdminScheduledMessageParams = () =>
  useTypedSelector(state => state.telegram.telegramAdmin.scheduledMessagesPageParams);

export const useWelcomeBotFloodError = () =>
  useTypedSelector(state => state.telegram.telegram.isFloodError);
