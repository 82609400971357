import { EFormField, EPageDomain, Page } from 'modules/my-pages/my-pages.types';

export type BusinessCardType = {
  id: number;
  name: string;
  link?: string;
  user: string;
  bgColor: string;
  domain: EPageDomain;
  prerenderImage: string;
  isCopy: boolean;
  isPublished?: boolean;
};

export type ApplicationsCard = {
  name: string;
  email: string;
  phone: string;
  date: string;
};

export type SocialMetric = {
  id: number;
  text: string;
  count: number;
};

export type SocialMetrics = {
  id: number;
  text: string;
  count: number;
};

export type Metrics = {
  id: number;
  socials: {
    buttons: SocialMetrics[];
    links: SocialMetrics[];
  };
  shows: number;
};

export enum EPeriodType {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  YEAR = 'year',
}

export type LandingDashBoardSliceState = {
  id?: number | string | null;
  formComponentId?: number | string | null;
  periodType: EPeriodType;
  newCard: boolean;
  ids: string[];
};

export type MetricsRequest = {
  pageId: string | number;
  timeBucket: EPeriodType;
};

export type MetricsSheet = {
  pageId: number;
  bucket: string;
  metrics: Array<{ time: string; count: number }>;
};

export type ChangePageParamsRequest = {
  id: number;
  name?: string;
  domain?: EPageDomain;
  link?: string | null;
  bgColor?: string;
};

export type ChangePageComponentsRequest = {
  id: number;
  components: PageComponents;
};

export type PageComponents = {
  components: Page['components'];
};

export type ChangePageRequest = {
  id: number;
  components: Page['components'];
};

export type PublishPageRequest = {
  id: number;
  link: string;
};

export type PageTemplate = {
  page: {
    id: number;
    name: string;
    link: string;
    domain: EPageDomain;
    bgColor: 'linear-gradient(180deg, #1B1B1B 0%, #101010 100%)';
    prerenderImage: 'https://placekitten.com/200/300';
  };
  description: string;
};

export type ChangeNameRequest = {
  id: number;
  pageName: string;
};

export type FormSendRequest = {
  created?: string;
  componentId: number | string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  dateOfBirth?: string | null;
  country?: string | null;
  position?: string | null;
  extra?: string | null;
  other?: string | null;
};

export type FormGetRequest = Omit<FormSendRequest, 'componentId'> & {
  id: number | string;
  created: string;
  isRead: boolean;
};

export type FormSendRequestForm = keyof Omit<FormSendRequest, 'componentId'>;

export type FormComponentsResponse = Array<{
  id: number;
  data: {
    id: number;
    fields: Array<{
      id: number;
      type: EFormField;
    }>;
    buttonStyle: {
      id: number;
      text: string;
    };
  };
}>;

export type TimeFormatValue = string | number | Date | { valueOf(): number };

export interface PeriodValues {
  periodValues: { time: string }[];
  numberOfPeriod: number;
  timeFormat: (date: TimeFormatValue) => string;
}
