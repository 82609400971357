import { useGetFormComponentsQuery, useGetPagesQuery } from './landing-dashboard.api';
import { useSelectStatisticsId } from './landing-dashboard.selectors';

export const useFormComponentsRequest = () => {
  const id = useSelectStatisticsId();
  const data = useGetFormComponentsQuery({ id: id as string }, { skip: !id });
  return data;
};

export const useGetPagesFiltered = () => {
  const { data, isError, isLoading } = useGetPagesQuery({});

  const publishedPages = data?.body.items.filter(item => item.isPublished) || [];

  return {
    data: publishedPages,
    isError,
    isLoading,
  };
};
