import { toast as defaultToast } from 'react-toastify';

import { ToastType, ToastContent } from './toasts.types';
import ToastsConstructor from './components/ToastsConstructor';
import './toast.scss';

const customToast = (type: ToastType, content: ToastContent) => {
  const options = {
    autoClose: 5000,
    hideProgressBar: true,
    position: defaultToast.POSITION.BOTTOM_CENTER,
  };

  defaultToast[type](
    ({ closeToast }) => <ToastsConstructor type={type} content={content} closeToast={closeToast} />,
    options,
  );
};

const toast = {
  success: (content: ToastContent) => customToast('success', content),
  error: (content: ToastContent) => customToast('error', content),
};

export default toast;
