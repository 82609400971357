// page

export type Page = {
  id: string;
  name?: string;
  hash: string;
  link?: string;
  domain: EPageDomain;
  bgColor: string;
  components: BlockComponent[];
  published: boolean;
  prerenderImage: string;
  copy: string;
};

export enum EPageDomain {
  HOST = 'HOST',
  PATH = 'PATH',
}

// wrapper

export enum EComponentType {
  BLOCK = 'BLOCK',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  BUTTON = 'BUTTON',
  FORM = 'FORM',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  SOCIAL_NETWORK = 'SOCIAL_NETWORK',
}

type ComponentId = {
  id?: string;
};

export type Component<Data> = ComponentId & ComponentBody<Data>;

export type ComponentBody<Data> = {
  hash: string;
  order: number;
  type: EComponentType;
  data: Data;
  components?: AnyComponent[];
};

export type AnyComponent = Component<
  Block | Text | Image | YouTubeVideo | Button | Form | Phone | Email | SocialNetwork
>;

export type BlockComponent = Component<Block>;

export type ActiveComponent<Data> = {
  parent: Page | BlockComponent;
  component: Data;
} | null;

export type NonNullableActiveComponent<Data> = NonNullable<ActiveComponent<Component<Data>>>;

// general

export type EditorMode = 'preview' | 'edit' | 'published';

export enum EOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum ETextStyle {
  HEADING = 'HEADING',
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
  PARAGRAPH = 'PARAGRAPH',
  SMALL_TEXT = 'SMALL_TEXT',
}

export enum EAlignment {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export type TextProps = {
  bold: boolean;
  italic: boolean;
  underline: boolean;
  color: string;
} & AlignmentMixin;

export enum EClickAction {
  ANCHOR = 'ANCHOR',
  LINK = 'LINK',
  FORM = 'FORM',
  NONE = 'NONE',
}

export type Click = {
  action: EClickAction;
  link?: string;
};

export enum EBlockStyle {
  ROUNDED = 'ROUNDED',
  SQUARE = 'SQUARE',
}

export enum ESocialNetwork {
  VK = 'VK',
  WHATSAPP = 'WHATSAPP',
  VIBER = 'VIBER',
  INSTAGRAM = 'INSTAGRAM',
  TELEGRAM = 'TELEGRAM',
}

export enum EImageStyle {
  FILL = 'FILL',
  FIT = 'FIT',
  CROP = 'CROP',
  REPEAT = 'REPEAT',
}

export enum EFormField {
  DATE = `DATE`,
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  COUNTRY = 'COUNTRY',
  POSITION = 'POSITION',
  OTHER = 'OTHER',
}

export type FormField = {
  order: number;
  type: EFormField;
};

export type FormFields = FormField[];

// react-dnd

export enum EDraggableItem {
  BLOCK = 'BLOCK',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  BUTTON = 'BUTTON',
  FORM = 'FORM',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  VK = 'VK',
  WHATSAPP = 'WHATSAPP',
  VIBER = 'VIBER',
  INSTAGRAM = 'INSTAGRAM',
  TELEGRAM = 'TELEGRAM',
}

// mixins

type AnchorMixin = { anchor?: string; isAnchorActive?: boolean };

type OnClickMixin = { onClick: Click };

type BlockStyleMixin = { blockStyle: EBlockStyle };

type AlignmentMixin = { alignment: EAlignment };

type OpacityMixin = { opacity: number };

type BgColorMixin = { bgColor: string };

type BorderColorMixin = { borderColor: string };

type TextMixin = { text?: string };

type LinkMixin = { link?: string };

type TextPropsMixin = { properties?: TextProps };

// components

export type Block = {
  bgImage: string | null;
} & OpacityMixin &
  BgColorMixin &
  LinkMixin;

export type Text = {
  textStyle: ETextStyle;
  properties: TextProps;
} & AnchorMixin &
  OnClickMixin &
  TextMixin;

export type Image = {
  imageStyle: EImageStyle;
  link: string;
} & OnClickMixin &
  AnchorMixin &
  OpacityMixin &
  LinkMixin;

export type YouTubeVideo = LinkMixin & AnchorMixin;

export type Button = AnchorMixin &
  OnClickMixin &
  BlockStyleMixin &
  AlignmentMixin &
  BgColorMixin &
  BorderColorMixin &
  TextMixin &
  TextPropsMixin;

export type Form = {
  fields: FormFields;
  inputStyle: BlockStyleMixin & BgColorMixin & BorderColorMixin;
  buttonStyle: BlockStyleMixin &
    BgColorMixin &
    BorderColorMixin &
    AlignmentMixin &
    TextMixin &
    TextPropsMixin;
} & AnchorMixin;

export type Phone = {
  phone?: string;
} & AnchorMixin;

export type Email = {
  email?: string;
} & AnchorMixin;

export type SocialNetwork = {
  type: ESocialNetwork;
  filled: boolean;
  hasBorder: boolean;
} & AnchorMixin &
  OnClickMixin &
  BlockStyleMixin &
  AlignmentMixin;
