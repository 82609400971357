import get from 'lodash-es/get';
import { ComplexColumnResolved, TableProps } from '../TableGeneric.types';

type TableColumnSortProps<Data extends object> = Pick<TableProps<Data>, 'sorts'> & {
  column: Required<Pick<ComplexColumnResolved<Data>, 'accessor' | 'header' | 'sorts'>>;
};

export const TableColumnSort = <Data extends object>({
  column,
  sorts,
}: TableColumnSortProps<Data>) => {
  const got = get(sorts, column.accessor);

  const onClick = () => {
    switch (got?.value) {
      case 'asc':
        return got?.onChange('desc');
      case 'desc':
        return got?.onChange(null);
      default:
        return got?.onChange('asc');
    }
  };

  return (
    <th onClick={onClick}>
      <span>{column.header}</span>
      {/* TODO: make sort cell */}
      {/* {got?.value === 'asc' ? '>' : got?.value === 'desc' ? '<' : '<>'} */}
    </th>
  );
};
