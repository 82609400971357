import { Route } from 'react-router-dom';

import r from 'constants/routes';
import SignIn from './pages/SignIn';
import ResetPassword from './pages/ResetPassword';
import Registration from './pages/Registration';
import NewPassword from './pages/NewPassword';
import Layout from './components/Layout';
import ChooseRegistrationType from './pages/ChooseRegistrationType';
import InvitorRegistration from 'modules/auth/pages/InvitorRegistration';

const AuthRoutes = (): JSX.Element => {
  return (
    <Route path={r.auth.index} element={<Layout />}>
      <Route path={r.auth.signIn} element={<SignIn />} />
      <Route path={r.auth.resetPassword} element={<ResetPassword />} />
      <Route path={r.auth.signUp.registration} element={<Registration />} />
      <Route path={r.auth.signUp.chooseRegistrationType} element={<ChooseRegistrationType />} />
      <Route path={r.auth.signUp.invitor} element={<InvitorRegistration />} />
      <Route path={r.auth.newPass} element={<NewPassword />} />
    </Route>
  );
};

export default AuthRoutes;
