import { FC } from 'react';

import TextMenu from 'components/TextMenu';
import { ETransactionStatus } from 'modules/finances/finances.types';
import { TRANSACTION_STATUSES_MAP } from 'modules/finances/finances.constants';

type Props = {
  status?: ETransactionStatus;
  setStatus: (value?: ETransactionStatus) => void;
};

const TransactionStatusSelector: FC<Props> = ({ status, setStatus }) => {
  return (
    <TextMenu
      label="Статус"
      items={Object.entries(TRANSACTION_STATUSES_MAP).map(([value, name]) => ({
        label: name,
        isSelected: status === value,
        onClick: () =>
          setStatus(status === value ? undefined : (value as unknown as ETransactionStatus)),
      }))}
      dontShowSelected
    />
  );
};

export default TransactionStatusSelector;
