import MethodsSection from '../MethodsSection';
import MethodCard from '../MethodCard';
import softpay from 'assets/svg/finances/SoftPay.svg';
import useDisclosure from 'hooks/useDisclosure';
import { AutoPayingModal } from '../AutoPayingModal';
import { useGetSoftPayProductsInfoQuery } from '../../finances.api';
import { isNil } from 'lodash-es';

export const AutoPayingMethods = () => {
  const { onOpen, open, onClose } = useDisclosure();
  const { data: infoData } = useGetSoftPayProductsInfoQuery({});
  const isActive = !isNil(infoData?.body);

  return (
    <>
      {/* TODO: https://stars-matrix.atlassian.net/browse/SO-1349 */}
      {/* Автопополнение */}
      <MethodsSection title="Пополнение с карты">
        <MethodCard
          isActive={isActive}
          photo={softpay}
          onClick={() => {
            if (!isActive) onOpen();
          }}
          amount={infoData?.body?.amount}
        />
      </MethodsSection>
      {open && <AutoPayingModal onClose={onClose} isOpen={open} />}
    </>
  );
};
