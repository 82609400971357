import rootApi from 'config/http';
import { EAppTagType, TResponse } from 'interfaces/api';
import {
  Landing,
  LandingNormalized,
  PublishSheetRequest,
} from 'modules/landing-generator/landing-generator.types';
import simpleFormData from 'utils/simpleFormData';

const landingGeneratorApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getLandingsByID: builder.query<TResponse<Array<Landing>>, { id: string | number }>({
      query: ({ id }) => ({
        url: `/sheets/id/${id}/landings`,
        method: 'GET',
      }),
      providesTags: [EAppTagType.LANDINGS],
    }),
    changeSheetByID: builder.mutation<
      TResponse<Array<Landing>>,
      { id: string | number; landings: LandingNormalized[] }
    >({
      query: ({ id, landings }) => ({
        url: `/sheets/${id}/change`,
        method: 'PATCH',
        body: landings,
      }),
      invalidatesTags: [EAppTagType.LANDINGS],
    }),
    setSheetImage: builder.mutation<TResponse<string>, { image: File }>({
      query: body => ({
        url: '/sheets/image',
        method: 'POST',
        body: simpleFormData(body),
      }),
    }),
    publishSheetById: builder.mutation<TResponse<string>, PublishSheetRequest>({
      query: body => ({
        url: `/sheets/publish`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.LANDINGS],
    }),
    // getPublishedSheetByName
  }),
  overrideExisting: false,
});

export const {
  useChangeSheetByIDMutation,
  useGetLandingsByIDQuery,
  usePublishSheetByIdMutation,
  useSetSheetImageMutation,
} = landingGeneratorApi;

export default landingGeneratorApi;
