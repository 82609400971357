import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import first from 'lodash-es/first';
import env from 'config/env';

import styles from './PublishLanding.module.scss';

import FormPageTemplate from 'components/FormPageTemplate';
import RadioSelect from 'components/RadioSelect';
import {
  PublishLandingForm,
  ELandingLinkType,
} from 'modules/landing-generator/landing-generator.types';
import { publishLandingSchema } from 'modules/landing-generator/landing-generator.schema';
import MaskInput from 'components/MaskInput';
import useDisclosure from 'hooks/useDisclosure';
import ShareLinkModal from 'modules/my-pages/components/ShareLinkModal';
import {
  useChangeSheetByIDMutation,
  usePublishSheetByIdMutation,
  useSetSheetImageMutation,
} from 'modules/landing-generator/landings-generator.api';
import { useSelectLandings } from 'modules/landing-generator/landing-generator.selectors';
import { removeIdsFromLandings } from 'modules/landing-generator/landing-generator.utils';
import { getSheetImage } from 'utils/getImage';

const PublishLanding: FC = () => {
  const { id } = useParams();
  const landings = useSelectLandings();
  const [changeSheet] = useChangeSheetByIDMutation();
  const [publishSheet] = usePublishSheetByIdMutation();
  const [setImage] = useSetSheetImageMutation();
  const {
    open: shareOpen,
    onOpen: onShareOpen,
    onClose: onShareClose,
  } = useDisclosure({ blockScroll: true });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm<PublishLandingForm>({
    mode: 'onChange',
    resolver: yupResolver(publishLandingSchema()),
    defaultValues: { type: ELandingLinkType.IN_PATH },
  });

  const values = watch();

  const onSubmit = handleSubmit(async ({ username }) => {
    if (!id) return;
    const landingFirst = first(landings);
    if (!landingFirst) return;
    try {
      // TODO: change image on sheet mb
      const newLandings = await Promise.all(
        landings.map(async landing => {
          if (landing.photo?.url.startsWith('blob:')) {
            const photo = { ...landing.photo };
            const blob = await (await fetch(landing.photo.url)).blob();
            const res = await setImage({
              image: new File([blob], 'sheetImage', { type: blob.type }),
            }).unwrap();
            // TODO simply pass body as we use getImage
            photo.url = getSheetImage(res.body);
            return { ...landing, photo };
          } else {
            return landing;
          }
        }),
      );

      await changeSheet({ id, landings: removeIdsFromLandings(newLandings) }).unwrap();
      await publishSheet({ sheetId: id, publishedName: username });
      onShareOpen();
      // prettier-ignore
      // eslint-disable-next-line
    } catch (e) {}
  });

  const getPrefix = () => {
    switch (values.type) {
      case ELandingLinkType.IN_PATH:
        return `${env.APP_FRONT}/card/`;
      default:
        return 'https://';
    }
  };

  const getPostfix = () => {
    switch (values.type) {
      case ELandingLinkType.IN_URL:
        return '/starsonline.wc';
      default:
        return '';
    }
  };

  return (
    <>
      <FormPageTemplate
        title="Опубликовать страницу"
        buttonText="Опубликовать"
        onSubmit={onSubmit}
        isValid={isValid}
        className={styles.Page}
        short
      >
        <RadioSelect
          selected={values.type}
          selectValue={value => setValue('type', value, { shouldValidate: true })}
          options={[
            { text: `${env.APP_FRONT}/card/username`, value: ELandingLinkType.IN_PATH },
            {
              text: 'https://username/starsonline.wc',
              value: ELandingLinkType.IN_URL,
              disabled: true,
            },
          ]}
        />

        <MaskInput
          register={register('username')}
          prefix={getPrefix()}
          postfix={getPostfix()}
          placeholder="username"
          invalid={!!errors.username?.message}
          errorMsg={errors.username?.message}
        />
      </FormPageTemplate>

      <ShareLinkModal isOpen={shareOpen} onClose={onShareClose} />
    </>
  );
};

export default PublishLanding;
