import { FC, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';

import {
  BotAdminTotal,
  useAdminBotControllerCreateBotMutation,
  useAdminBotControllerGetBotListQuery,
  useAdminBotControllerToggleBotMutation,
} from 'modules/telegram/codegen/telegram.admin.api';
import useDisclosure from 'hooks/useDisclosure';
import r from 'constants/routes';
import telegram from 'assets/svg/telegram/index.svg';

import BotsList from 'modules/telegram/components/BotsList';
import PageHeader from 'components/PageHeader';
import AddBotModal from 'modules/telegram/components/AddBotModal';
import Button from 'components/Button';
import AdminBotCreated from 'modules/telegram/pages/AdminBots/Components/AdminBotCreated';

import styles from './AdminBots.module.scss';
import EmptyPage from 'components/EmptyPage';
import { isTTelegramErrorResult } from '../../telegram.types';
import { size } from 'lodash-es';
import LoadingState from 'components/LoadingState';
import { videoTutorials } from '../../../../constants/videos';

const AdminBotsList: FC<{ onOpenBot: () => void; botsList: BotAdminTotal[] }> = ({
  botsList,
  onOpenBot,
}) => {
  const [toggleAdminBot] = useAdminBotControllerToggleBotMutation();
  return (
    <>
      <BotsList
        bots={botsList.map(bot => ({
          id: bot.id,
          name: bot.name,
          botInfo: bot.telegram,
          toggled: bot.status,
          canNotStart: bot.canNotStart,
          onToggle: () => toggleAdminBot({ id: bot.id }),
          link: generatePath(r.telegram.editAdminBot, { id: bot.id.toString() }),
        }))}
        emptyMessage={
          <EmptyPage
            img={<img src={telegram} alt={'img'} />}
            title="Подключи бота администратора"
            text="В несколько шагов"
            footer={
              <Button variant="magic" onClick={onOpenBot}>
                Добавить бота
              </Button>
            }
          />
        }
        extreBots={
          size(botsList) < 5 ? (
            <Button
              leftIcon="plus-circle"
              className={styles.MAddButton}
              variant="viper"
              onClick={onOpenBot}
            >
              Добавить бота
            </Button>
          ) : null
        }
      />
    </>
  );
};

const AdminBots: FC = () => {
  const { data: botsList, isFetching } = useAdminBotControllerGetBotListQuery({
    botAdminListParams: {},
  });
  const [createBot, { isSuccess }] = useAdminBotControllerCreateBotMutation();

  const [success, setSuccess] = useState(false);
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });

  useEffect(() => {
    if (isSuccess) {
      onClose();
      setSuccess(isSuccess);
    }
  }, [isSuccess, onClose]);

  const onSuccessModalClose = () => setSuccess(false);

  if (isFetching) return <LoadingState />;

  return (
    <div className={styles.Page}>
      <PageHeader
        title="БОТ-АДМИНИСТРАТОР"
        subtitle="Telegram"
        videoTutorials={[
          { label: 'Настройка бота администратор', url: videoTutorials.bot_administrator },
          { label: 'Получение токена', url: videoTutorials.token_reception },
          { label: 'Настройка бота', url: videoTutorials.bot_setup },
        ]}
        elem={
          size(botsList?.data) < 5 ? (
            <Button variant="magic" onClick={onOpen}>
              Добавить бота
            </Button>
          ) : null
        }
        defaultLink={r.admin.index}
      />
      <AdminBotsList botsList={botsList?.data ?? []} onOpenBot={onOpen} />
      <AddBotModal
        editPath={r.telegram.editAdminBot}
        isOpen={open}
        onClose={onClose}
        onSubmit={async ({ token, name, timezone }) => {
          await createBot({ createBotAdminPayload: { token, name, timezone } }).then(res => {
            if (!isTTelegramErrorResult(res)) {
              onClose();
            }
          });
        }}
      />
      {success && <AdminBotCreated onClose={onSuccessModalClose} />}
    </div>
  );
};

export default AdminBots;
