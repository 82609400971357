import { FC } from 'react';

import styles from './MyPagesUndoRedo.module.scss';
import UndoRedo from 'components/UndoRedo';
import useMyPagesUndoRedoProps from 'modules/my-pages/hooks/useMyPagesUndoRedoProps';

const MyPagesUndoRedo: FC = () => {
  const props = useMyPagesUndoRedoProps();

  return <UndoRedo className={styles.Wrapper} {...props} />;
};

export default MyPagesUndoRedo;
