import { FC } from 'react';
import ModalWithForm from 'components/ModalWithForm';
import { CommonModalProps } from 'components/Modal';
import Input from 'components/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { connectWelcomeBotSchema } from '../../../telegram.schema';
import { useLinkBotIntoUserMutation } from '../../../../account/account.api';
import { welcomeBotState } from '../../../codegen/telegram.constants';

interface Props extends CommonModalProps {
  service: string;
}

export const ConnectWelcomeBotModal: FC<Props> = ({ service, ...props }) => {
  const [linkBot, { isLoading }] = useLinkBotIntoUserMutation();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ serviceName: string }>({
    mode: 'onChange',
    resolver: yupResolver(connectWelcomeBotSchema()),
  });

  const onSubmit: SubmitHandler<{ serviceName: string }> = form => {
    linkBot({
      serviceUsername: form.serviceName,
      service,
    }).then(res => {
      if ('data' in res) {
        props.onClose();
      }
    });
  };

  return (
    <ModalWithForm
      onSubmit={handleSubmit(onSubmit)}
      title={'Подключить'}
      isValid={isValid}
      text={`Подключите ${welcomeBotState[service]} для рассылок приветственных сообщений`}
      {...props}
      noBtn={isLoading}
      isLoading={isLoading}
    >
      <Input
        register={register('serviceName')}
        label={'Введите свой логин в ' + welcomeBotState[service]}
      />
    </ModalWithForm>
  );
};
