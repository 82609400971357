import { FC } from 'react';
import classNames from 'classnames';

import styles from './EmptyCard.module.scss';
import Button from 'components/Button';

type Props = {
  text: string;
  onClick: () => void;
  className?: string;
};

const EmptyCard: FC<Props> = ({ text, onClick, className }) => {
  return (
    <article className={classNames(styles.Card, className)}>
      <Button variant="tetriary" size="l" onClick={onClick}>
        {text}
      </Button>
    </article>
  );
};

export default EmptyCard;
