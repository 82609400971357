import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FormRequestTable.module.scss';
import TableGeneric from 'components/TableGeneric';
import {
  useSelectFormComponentId,
  useSelectStatisticsId,
} from 'modules/landing-dashboard/landing-dashboard.selectors';
import { ComplexColumn } from 'components/TableGeneric/TableGeneric.types';
import { FormGetRequest } from 'modules/landing-dashboard/landing-dashboard.types';
import {
  useGetApplicationsByPageQuery,
  useGetFormsQuery,
} from 'modules/landing-dashboard/landing-dashboard.api';
import { EFormField } from 'modules/my-pages/my-pages.types';
import { FormFieldMapper } from 'modules/my-pages/my-pages.helpers';
import FormRequestsList from '../FormRequestsList';
import Pagination from 'components/Pagination';
import { FormRequestTableTypes } from './FormRequestTable.types';
import { checkReadApplications } from 'modules/landing-dashboard/landing-dashboard.helper';
import moment from 'moment-timezone';

const FormRequestsTable: FC<FormRequestTableTypes> = ({ selected }) => {
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const formComponentId = useSelectFormComponentId();
  const selectedPageId = useSelectStatisticsId();

  const { data: pageForms, isFetching: isPageFormFetching } = useGetApplicationsByPageQuery(
    { id: selectedPageId },
    { skip: !selectedPageId },
  );

  const {
    data: formApplications,
    isError,
    isFetching: isFormsFetching,
  } = useGetFormsQuery({
    componentId: formComponentId || '',
    limit: 9,
    offset: offset,
    isRead: checkReadApplications(selected),
  });

  const checkApplicationSource = () => {
    if (selectedPageId && !formComponentId && pageForms) {
      return pageForms?.body;
    }

    return formApplications;
  };

  const pageChange = (page: number) => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset((page - 1) * 9);
    }
  };

  const columns = useMemo<ComplexColumn<FormGetRequest>[]>(
    () =>
      Object.values(EFormField).map(field => {
        const isDate = field === 'DATE';

        const tableColumn = {
          accessor: FormFieldMapper[field],
          header: t(`formFields.${field}`),
        };

        if (isDate) {
          return {
            ...tableColumn,
            accessorFn: ({ created }) => {
              const newDate = moment(created).format('DD.MM.YYYY HH:mm');
              return <span>{newDate}</span>;
            },
          };
        }

        return tableColumn;
      }),
    [],
  );

  const { items = [], count = 0 } = checkApplicationSource() || {};

  if (isError) return null;
  if (isPageFormFetching || isFormsFetching) return null;

  return (
    <>
      <TableGeneric
        className={styles.DataDesktop}
        columns={columns}
        data={items}
        withCheckbox
        type={'applications'}
      />
      <FormRequestsList className={styles.DataMobile} items={items} />
      {count > 9 && <Pagination onPageChange={pageChange} offset={9} totalCount={count} />}
    </>
  );
};

export default FormRequestsTable;
