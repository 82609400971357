import { forwardRef, useMemo } from 'react';
import classNames from 'classnames';

import styles from './Avatar.module.scss';
import { getAvatar } from 'utils/getImage';
import getRandomColor from 'utils/getRandomColor';

type Props = {
  name: string;
  src?: string | null;
  size?: number;
  className?: string;
  color?: string;
  onClick?: () => void;
};

const Avatar = forwardRef<HTMLDivElement, Props>(
  ({ src, name, size = 30, className, onClick, color }, ref) => {
    const firstLetters =
      name
        .split(' ')
        .slice(0, 2)
        .map(substr => substr[0])
        .join('') ?? '';

    const background = useMemo(() => color ?? getRandomColor(), [color]);

    return (
      <div
        ref={ref}
        className={classNames(styles.AvatarWrapper, className)}
        style={{
          width: size,
          height: size,
          minWidth: size,
          maxWidth: size,
        }}
      >
        {src && (
          <img
            src={getAvatar(src)}
            className={styles.Avatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.style.display = 'none';
            }}
            alt={`${name}'s avatar`}
            onClick={onClick}
          />
        )}
        <div className={styles.ImageFallback} style={{ background }}>
          <svg width={size} height={size} viewBox="0 0 32 32">
            <text x="16" y="22" textAnchor="middle">
              {firstLetters.toUpperCase()}
            </text>
          </svg>
        </div>
      </div>
    );
  },
);

export default Avatar;
