import { FC, useEffect, useMemo } from 'react';

import r from 'constants/routes';
import ActionsLayout from 'modules/vk/components/ActionsLayout';
import ToggleActionCard from 'modules/vk/components/ToggleActionCard';
import { useGetVkUserSettingQuery, useUpdateVkUserSettingMutation } from 'modules/vk/vk.api';
import cracker from 'assets/svg/vk/cracker.svg';
import comment from 'assets/svg/vk/comment.svg';
import automessage from 'assets/svg/vk/automessage.svg';
import autoIcon from 'assets/svg/vk/autoIcon.svg';
import MainVkCard from '../../components/MainVkCard';
import MainVkCardWithTabs from '../../components/MainVkCardWithTabs';
import { useGetAllVkAccounts, useGetSettingsList } from '../../vk.selectors';
import { isEmpty } from 'lodash-es';
import { TNamingMap, TUpdateVkSetting, TVkSettings } from '../../vk.types';
import { generatePath, useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { setUserSettingsList, updateUserSettingsListById } from '../../vk.reducer';
import { namingMapHandler } from '../../vk.utils';
import LoadingState from '../../components/LoadingState';
import EmptyVkPage from '../EmptyVkPage';

const FriendsActions: FC = () => {
  const vkAccounts = useGetAllVkAccounts();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const settingsState = useGetSettingsList();

  const {
    data: settingsData,
    isFetching: isSettingsDataLoading,
    isSuccess: isSettingsFetched,
  } = useGetVkUserSettingQuery();
  const [updateSetting] = useUpdateVkUserSettingMutation();

  const namingMap: TNamingMap = useMemo(() => {
    return namingMapHandler(settingsState);
  }, [settingsState]);

  const settingsMemoArr = useMemo(() => {
    const resArr: TVkSettings[] & TVkSettings[][] = [];
    if (settingsState && !isEmpty(settingsState)) {
      const arr = [...settingsState];
      for (let i = 0; i < arr.length; i++) {
        const curr = arr[i];
        const next = arr[i + 1];
        if (curr.name === namingMap.AUTO_APPROVING_FRIENDSHIP) {
          resArr.unshift(curr);
        }
        if (
          curr?.name.includes('INPUT') &&
          next?.name.includes('OUTPUT') &&
          curr.id + 1 === next.id
        ) {
          resArr.push([curr, next]);
        } else {
          if (!(curr.name.includes('OUTPUT') || curr.name.includes('INPUT'))) {
            if (curr.name === namingMap.AUTO_APPROVING_FRIENDSHIP) {
              continue;
            }
            resArr.push(curr);
          }
        }
      }
    }
    return resArr;
  }, [settingsState, namingMap.AUTO_APPROVING_FRIENDSHIP]);

  const updateSettingHandler = async (arg: TUpdateVkSetting) => {
    await updateSetting({ data: [arg] });
  };

  const updateSettingsStateHandler = async (item: TVkSettings) => {
    dispatch(updateUserSettingsListById({ ...item, isEnabled: !item.isEnabled }));
    await updateSettingHandler({
      id: item.id,
      isEnabled: !item.isEnabled,
    });
  };

  const onSettingsClickHandler = (nameType: string, id: string) => {
    switch (nameType) {
      case namingMap.AUTO_COMMENTING_NEW_FRIEND_PHOTOS_INPUT:
        navigate(generatePath(r.vk.first3PhotoComments, { id }));
        break;
      case namingMap.AUTO_COMMENTING_NEW_FRIEND_PHOTOS_OUTPUT:
        navigate(generatePath(r.vk.first3PhotoComments, { id }));
        break;
    }
  };

  const cardsIconHandler = (nameType: string) => {
    const stateObj = {
      [namingMap.AUTO_COMMENTING_NEW_FRIEND_PHOTOS_INPUT]: comment,
      [namingMap.AUTO_COMMENTING_NEW_FRIEND_PHOTOS_OUTPUT]: comment,
      [namingMap.AUTO_MESSAGING_NEW_FRIEND_INPUT]: automessage,
      [namingMap.AUTO_MESSAGING_NEW_FRIEND_OUTPUT]: automessage,
      [namingMap.AUTO_APPROVING_FRIENDSHIP]: autoIcon,
    };

    return stateObj[nameType] || cracker;
  };

  const handleShowButton = (nameType: string) => {
    const stateObj = {
      [namingMap.AUTO_LIKING_NEW_FRIEND_PHOTOS_INPUT]: true,
      [namingMap.AUTO_LIKING_NEW_FRIEND_PHOTOS_OUTPUT]: true,
    };

    return stateObj[nameType] || false;
  };

  useEffect(() => {
    if (isSettingsFetched && settingsData) {
      dispatch(setUserSettingsList(settingsData.settings));
    }
  }, [isSettingsFetched]);

  if (isEmpty(vkAccounts) && !isSettingsDataLoading) return <EmptyVkPage />;
  if (isSettingsDataLoading) return <LoadingState />;

  return (
    <ActionsLayout title="Действия с друзьями" actionsTitle="">
      <>
        {settingsMemoArr.map(item => {
          if (item.name === namingMap.AUTO_APPROVING_FRIENDSHIP)
            return (
              <ToggleActionCard
                key={item.id + item.name}
                name={item.description}
                image={cardsIconHandler(item.name)}
                description={item.fullDescription}
                toggle={{
                  active: item.isEnabled,
                  toggle: () => {
                    updateSettingsStateHandler(item);
                  },
                }}
              />
            );
          else if (Array.isArray(item)) {
            return (
              <MainVkCardWithTabs
                key={item.id}
                inputChildren={
                  <MainVkCard
                    key={item[0].id}
                    icon={cardsIconHandler(item[0].name)}
                    name={item[0].description}
                    text={item[0].fullDescription}
                    toggle={{
                      active: item[0].isEnabled,
                      toggle: () => {
                        updateSettingsStateHandler(item[0]);
                      },
                    }}
                    onButtonClick={() => onSettingsClickHandler(item[0].name, item[0].id)}
                    noBtn={handleShowButton(item[0].name)}
                  />
                }
                outputChildren={
                  <MainVkCard
                    key={item[1].id}
                    icon={cardsIconHandler(item[1].name)}
                    name={item[1].description}
                    text={item[1].fullDescription}
                    toggle={{
                      active: item[1].isEnabled,
                      toggle: () => {
                        updateSettingsStateHandler(item[1]);
                      },
                    }}
                    onButtonClick={() => onSettingsClickHandler(item[1].name, item[1].id)}
                    noBtn={handleShowButton(item[1].name)}
                  />
                }
              />
            );
          } else {
            return (
              <MainVkCard
                key={item.id}
                icon={cardsIconHandler(item.name)}
                name={item.description}
                text={''}
                toggle={{
                  active: item.isEnabled,
                  toggle: () => {
                    updateSettingsStateHandler(item);
                  },
                }}
              />
            );
          }
        })}
      </>
    </ActionsLayout>
  );
};

export default FriendsActions;
