import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GreetinBotModalState } from './telegram.types';
import { telegramAdminSlice } from './codegen/telegram.admin.reducer';
import { telegramSpamSlice } from './codegen/telegram.spam.reducer';

type TelegramState = {
  greetingBot: {
    phone?: string;
    state?: GreetinBotModalState;
  };
  isFloodError: boolean;
};

const initialState: TelegramState = {
  greetingBot: {},
  isFloodError: false,
};

const telegram = createSlice({
  name: 'telegram',
  initialState,
  reducers: {
    confirmPhone: (state, { payload }: PayloadAction<string>) => {
      state.greetingBot.phone = payload;
      state.greetingBot.state = GreetinBotModalState.CONFIRMATION;
    },
    selectPlan: (state, { payload }: PayloadAction<GreetinBotModalState>) => {
      state.greetingBot.state = payload;
    },

    closeModal: () => initialState,

    setIsFloodError: state => {
      state.isFloodError = true;
    },
  },
});

export const { selectPlan, closeModal, confirmPhone, setIsFloodError } = telegram.actions;

const telegramSlice = combineReducers({
  telegram: telegram.reducer,
  telegramAdmin: telegramAdminSlice.reducer,
  telegramSpamBot: telegramSpamSlice.reducer,
});
export default telegramSlice;
