import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITokens, IAuthData } from './auth.interfaces';

type AuthState = {
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  time: string;
};

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  time: '',
};

export type Timer = string;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: state => {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.refreshToken = null;
    },
    updateRefreshToken: (state, { payload }: PayloadAction<IAuthData>) => {
      const authData: ITokens = {
        refreshToken: payload.refreshToken,
        accessToken: payload.accessToken,
      };

      // set data to state
      state.accessToken = authData.accessToken;
      state.refreshToken = authData.refreshToken;
    },
    setTimer: (state, { payload }: PayloadAction<Timer>) => {
      state.time = payload;
    },
    signInStateHandler: state => {
      // set data to state
      state.isAuthenticated = true;
    },
  },
});

export const { signOut, updateRefreshToken, setTimer, signInStateHandler } = authSlice.actions;

export default authSlice.reducer;
