import { createApi } from '@reduxjs/toolkit/query/react';
import { EAppTGTagType } from 'interfaces/api';
import env from './env';
import { makeBaseQuery, makeBaseQueryWithRefresh } from './http';

const tgBaseQuery = makeBaseQuery(env.APP_API_TG + '/api/v1');

export const tgApi = createApi({
  reducerPath: 'tgApi',
  baseQuery: makeBaseQueryWithRefresh(tgBaseQuery),
  tagTypes: Object.values(EAppTGTagType),
  endpoints: () => ({}),
});

export default tgApi;
