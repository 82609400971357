import { FC } from 'react';
import { generatePath } from 'react-router-dom';

import styles from './SpamBots.module.scss';
import r from 'constants/routes';
import BotsList from 'modules/telegram/components/BotsList';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import useDisclosure from 'hooks/useDisclosure';
import EmptyPage from 'components/EmptyPage';
import AddBotModal from 'modules/telegram/components/AddBotModal';
import {
  TSpamBot,
  useCreateSpamBotMutation,
  useDeleteSpamBotMutation,
  useGetAllSpamBotsQuery,
  useToggleSpamBotMutation,
} from 'modules/telegram/codegen/telegram.spam.api';
import telegram from 'assets/svg/telegram/index.svg';
import { isTTelegramErrorResult } from '../../telegram.types';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { size } from 'lodash-es';
import LoadingState from 'components/LoadingState';
import { videoTutorials } from '../../../../constants/videos';

const SpamBotsList: FC<{ onOpenBot: () => void; botsListArr?: TSpamBot[] }> = ({
  onOpenBot,
  botsListArr,
}) => {
  const [deleteBot] = useDeleteSpamBotMutation();
  const [toggleAdminBot] = useToggleSpamBotMutation();

  return (
    <BotsList
      bots={botsListArr?.map(bot => ({
        id: bot.id,
        name: bot.name,
        onDelete: () => deleteBot({ botId: bot.id }),
        botInfo: bot.telegram,
        toggled: bot.status,
        canNotStart: bot.canNotStart,
        onToggle: () => toggleAdminBot({ botId: bot.id }),
        link: generatePath(r.telegram.editSpamBot, { id: bot.id.toString() }),
      }))}
      emptyMessage={
        <EmptyPage
          img={<img src={telegram} alt={'img'} />}
          title="Подключи Ботa рассылки"
          text="В несколько шагов"
          footer={
            <Button variant="magic" onClick={onOpenBot}>
              Добавить бота
            </Button>
          }
        />
      }
      extreBots={
        size(botsListArr) < 5 ? (
          <Button
            leftIcon="plus-circle"
            className={styles.MAddButton}
            variant="viper"
            onClick={onOpenBot}
          >
            Добавить бота
          </Button>
        ) : null
      }
    />
  );
};
const SpamBots: FC = () => {
  const { data, isFetching } = useGetAllSpamBotsQuery();
  const [createBot] = useCreateSpamBotMutation();
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });

  if (isFetching) return <LoadingState />;

  return (
    <div className={styles.Page}>
      <PageHeader
        title="Бот рассылки"
        subtitle="Телеграм"
        videoTutorials={[
          { label: 'Настройка бота рассылки', url: videoTutorials.bot_broadcast },
          { label: 'Получение токена', url: videoTutorials.token_reception },
          { label: 'Настройка бота', url: videoTutorials.bot_setup },
        ]}
        elem={
          size(data?.data) < 5 ? (
            <Button variant="magic" onClick={onOpen}>
              Добавить бота
            </Button>
          ) : null
        }
      />
      <SpamBotsList botsListArr={data?.data} onOpenBot={onOpen} />
      <AddBotModal
        isOpen={open}
        onClose={onClose}
        editPath={r.telegram.editSpamBot}
        onSubmit={async body => {
          await createBot({ body }).then(res => {
            if (isTTelegramErrorResult(res)) {
            } else {
              onClose();
              showFeedback({
                title: 'ГОТОВО!',
                text: 'Успешно',
                type: EFeedbackType.SUCCESS,
              });
            }
          });
        }}
      />
    </div>
  );
};

export default SpamBots;
