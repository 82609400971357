import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Finances from './pages/Finances';
import Transactions from './pages/Transactions';

const FinancesRoutes = (): JSX.Element => {
  return (
    <>
      <Route path={r.finances.index} element={<Finances />} />
      <Route path={r.finances.transactions} element={<Transactions />} />
    </>
  );
};

export default FinancesRoutes;
