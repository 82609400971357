import { FC, Fragment, useMemo } from 'react';
import GroupsLayout from '../../components/GroupsLayout';
import GroupCard from '../../components/GroupCard';
import { isEmpty, size } from 'lodash-es';
import {
  useGetAllVkAccounts,
  useGetVkGroupParams,
  useGetVkSelectedGroupsId,
  useGetVkSelectedGroupsSubscribersCount,
} from '../../vk.selectors';
import { useSearchGroupQuery } from '../../vk.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { setSelectedGroupsId } from '../../vk.reducer';
import env from 'config/env';
import LoadingState from '../../components/LoadingState';
import EmptyVkPage from '../EmptyVkPage';

const GroupsActions: FC = () => {
  const vkAccounts = useGetAllVkAccounts();
  const params = useGetVkGroupParams();
  const selectedIds = useGetVkSelectedGroupsId();
  const dispatch = useAppDispatch();
  const { data, isFetching } = useSearchGroupQuery(params);
  const subscribersCount = useGetVkSelectedGroupsSubscribersCount();
  const layoutTitle =
    subscribersCount >= 500000 && size(selectedIds) > 1
      ? 'Число пользователей в группе превышает 500 000 подписчиков, можете выбрать для парсинга только 1 группу'
      : 'Максимальное число подписчиков в выбранных группах для проведения парсинга не должно превышать 500 000 подписчиков';

  const groupsArr = useMemo(() => {
    return data?.data.data.map(group => {
      if (selectedIds[group.id]) {
        return {
          ...group,
          isChecked: !group.isChecked,
        };
      }
      return {
        ...group,
        isChecked: false,
      };
    });
  }, [data?.data.data, selectedIds]);

  if (isEmpty(vkAccounts) && !isFetching) return <EmptyVkPage />;
  if (isFetching) return <LoadingState />;

  return (
    <GroupsLayout title={layoutTitle} isEmptyPage={isEmpty(data?.data.data)}>
      {groupsArr?.map(card => (
        <Fragment key={card.id}>
          <GroupCard
            key={card.id}
            subTitle={card.activity}
            subscribers={card.membersCount}
            title={card.name}
            image={card.photoUrl}
            isChecked={card.isChecked}
            onChoose={() => {
              dispatch(setSelectedGroupsId(card));
            }}
            onFollowLink={() => {
              window.open(`${env.VK_URL}/${card.screenName}`, '_blank')?.focus();
            }}
          />
        </Fragment>
      ))}
    </GroupsLayout>
  );
};

export default GroupsActions;
