import { FC } from 'react';

import styles from './NoAddedButtons.module.scss';
import Icon from 'components/Icon';

const NoAddedButtons: FC = () => {
  return (
    <div className={styles.Card}>
      <Icon className={styles.Icon} iconName="empty-smile" size={64} />
      <p className={styles.Text}>
        Пока тут ничего нет, начни редактирование, чтобы появились кнопки
      </p>
    </div>
  );
};

export default NoAddedButtons;
