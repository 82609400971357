import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TAutoSettings,
  TFoldersResponse,
  TPersistTimer,
  TUserGroupParams,
  TUserVkGroup,
  TVkAccount,
  TVKAutomationSettings,
  TVkSettings,
} from './vk.types';
import { removeDuplicatesById } from './vk.utils';
import { vkAuth } from './vk.api';
import { isEmpty, isNull } from 'lodash-es';

const userGroupParams: TUserGroupParams = {
  name: '',
  limit: 12,
  offset: 1,
  maxPageCount: 0,
};

type TvkGroupsState = {
  selectedGroups: { [key: string]: TUserVkGroup };
  subscribersCount: number;
  downloadFiveMinDelay: { [key in string]: TPersistTimer };
};

export type VkSliceState = {
  thematicGroups: {
    selected: number[];
  };
  accounts: TVkAccount[];
  settingsList: TVkSettings[];
  userGroupParams: TUserGroupParams;
  parseFolderSearchParams: TUserGroupParams;
  userGroups: TUserVkGroup[];
  currentGroupFolder: TFoldersResponse;
  groupFolderLikeToggle: boolean;
  groupFolderSettingAuto: TAutoSettings[];
  vkAutomationSettings: TVKAutomationSettings[];
};

const currentGroupFolder = {} as TFoldersResponse;

const initialState: VkSliceState = {
  thematicGroups: {
    selected: [],
  },
  accounts: [],
  settingsList: [],
  userGroupParams,
  parseFolderSearchParams: userGroupParams,
  userGroups: [],
  currentGroupFolder,
  groupFolderLikeToggle: false,
  groupFolderSettingAuto: [],
  vkAutomationSettings: [],
};

const vkGroupsState: TvkGroupsState = {
  selectedGroups: {},
  subscribersCount: 0,
  downloadFiveMinDelay: {},
};

const vkSlice = createSlice({
  name: 'vk',
  initialState,
  reducers: {
    selectThematicGroup: (state, { payload }: PayloadAction<number>) => {
      if (state.thematicGroups.selected.includes(payload)) {
        state.thematicGroups.selected = state.thematicGroups.selected.filter(g => g !== payload);
      } else {
        state.thematicGroups.selected.push(payload);
      }
    },
    resetSelectedThematicGroups: state => {
      state.thematicGroups.selected = [];
    },
    setUserSettingsList: (state, { payload }: PayloadAction<TVkSettings[]>) => {
      state.settingsList = payload;
    },

    updateUserSettingsListById: (state, { payload }: PayloadAction<TVkSettings>) => {
      state.settingsList = state.settingsList.map(setting => {
        if (setting.id === payload.id) {
          return {
            ...setting,
            ...payload,
          };
        }
        return setting;
      });
    },
    setVkGroupsParams: (state, { payload }: PayloadAction<TUserGroupParams>) => {
      state.userGroupParams = {
        ...state.userGroupParams,
        ...payload,
      };
    },

    setVkParseFolderSearchParams: (state, { payload }: PayloadAction<TUserGroupParams>) => {
      state.parseFolderSearchParams = {
        ...state.parseFolderSearchParams,
        ...payload,
      };
    },

    setVkGroup: (state, { payload }: PayloadAction<TUserVkGroup>) => {
      state.userGroups = state.userGroups.map(group => {
        if (payload.id === group.id) {
          return payload;
        }
        return group;
      });
    },

    setFolderGroupCheck: (state, { payload }: PayloadAction<number>) => {
      state.currentGroupFolder.groups = state.currentGroupFolder.groups.map(group => {
        if (payload === group.id) {
          return {
            ...group,
            isChecked: !group.isChecked,
          };
        }
        return group;
      });
    },

    clearVkAccountsFromState: state => {
      state.accounts = [];
    },

    setAutoGroupEnableSettings: (state, { payload }: PayloadAction<number>) => {
      state.groupFolderSettingAuto = state.groupFolderSettingAuto.map(item => {
        if (item.id === payload) {
          return {
            ...item,
            isEnabled: !item.isEnabled,
          };
        }
        return item;
      });
    },

    toggleVkAutomationSettings: (state, { payload }: PayloadAction<number>) => {
      state.vkAutomationSettings = state.vkAutomationSettings.map(card => {
        if (card.id === payload) {
          return {
            ...card,
            isEnabled: !card.isEnabled,
          };
        }
        return card;
      });
    },
  },

  extraReducers: builder => {
    builder
      .addMatcher(vkAuth.endpoints.getVkAccount.matchFulfilled, (state, { payload }) => {
        const res = payload.data;
        if (!isEmpty(res)) {
          state.accounts.push({ ...res });
          state.accounts = removeDuplicatesById(state.accounts);
        }
      })
      .addMatcher(vkAuth.endpoints.deleteVkAccount.matchFulfilled, state => {
        state.accounts = [];
      })
      .addMatcher(vkAuth.endpoints.searchGroup.matchFulfilled, (state, { payload }) => {
        state.userGroupParams.maxPageCount = payload.data.count;
        state.userGroups = payload.data.data.map(group => ({
          isChecked: false,
          ...group,
        }));
      })
      .addMatcher(vkAuth.endpoints.getFolderGroupsById.matchPending, state => {
        state.currentGroupFolder = {} as TFoldersResponse;
      })
      .addMatcher(vkAuth.endpoints.getFolderGroupsById.matchFulfilled, (state, { payload }) => {
        state.currentGroupFolder = {
          ...payload.data,
          groups: payload.data.groups.map(group => ({
            ...group,
            isChecked: false,
          })),
        };
      })
      .addMatcher(vkAuth.endpoints.getVkUserSetting.matchFulfilled, (state, { payload }) => {
        if (!isNull(payload?.settings)) {
          state.settingsList = payload?.settings;
        }
      })
      .addMatcher(
        vkAuth.endpoints.getParsingAutomationSettings.matchFulfilled,
        (state, { payload }) => {
          state.groupFolderSettingAuto = payload.data;
        },
      )
      .addMatcher(vkAuth.endpoints.getAutomationSettings.matchFulfilled, (state, { payload }) => {
        if (!isNull(payload?.settings)) {
          state.vkAutomationSettings = payload?.settings;
        }
      });
  },
});

export const vkGroupsSlice = createSlice({
  name: 'vkSelectedGroups',
  initialState: vkGroupsState,
  reducers: {
    setSelectedGroupsId: (state, { payload }: PayloadAction<TUserVkGroup>) => {
      const idMap = new Map(Object.entries(state.selectedGroups));
      if (idMap.has(payload.id + '')) {
        idMap.delete(payload.id + '');
        state.subscribersCount -= payload.membersCount;
      } else {
        idMap.set(payload.id + '', payload);
        state.subscribersCount += payload.membersCount;
      }
      state.selectedGroups = Object.fromEntries(idMap);
    },
    clearSelectedGroupsId: state => {
      state.selectedGroups = {};
      state.subscribersCount = 0;
    },

    setTimerInPersist: (state, { payload }: PayloadAction<{ [key in string]: TPersistTimer }>) => {
      if (!isEmpty(payload)) {
        state.downloadFiveMinDelay = {
          ...state.downloadFiveMinDelay,
          ...payload,
        };
      }
    },

    removeTimerFrom: (state, { payload }: PayloadAction<string>) => {
      const entries = Object.entries(state.downloadFiveMinDelay);
      const tempMap = new Map(entries);
      tempMap.delete(payload);
      state.downloadFiveMinDelay = Object.fromEntries(tempMap);
    },
  },
});

export const {
  selectThematicGroup,
  resetSelectedThematicGroups,
  setUserSettingsList,
  updateUserSettingsListById,
  setVkGroupsParams,
  setVkParseFolderSearchParams,
  setFolderGroupCheck,
  setAutoGroupEnableSettings,
  clearVkAccountsFromState,
  toggleVkAutomationSettings,
} = vkSlice.actions;

export const { setSelectedGroupsId, clearSelectedGroupsId, setTimerInPersist, removeTimerFrom } =
  vkGroupsSlice.actions;

export default vkSlice.reducer;
