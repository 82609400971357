import { FC } from 'react';

import styles from './Stats.module.scss';
import BotCard from 'components/BotCard';

type Props = {
  subscribers: number;
  unsubscribed: number;
  subscribed: number;
  messages: number;
  commands: number;
};

const Stats: FC<Props> = ({ subscribers, unsubscribed, subscribed, messages, commands }) => {
  return (
    <div className={styles.Stats}>
      <BotCard.DataEntry
        iconName="people"
        name="Подписчики"
        nameLabel="всего"
        value={`${subscribers} K`}
        nameMaxWidth
      />
      <BotCard.DataEntry
        iconName="unsubscribed"
        name="Отписались"
        nameLabel="за день"
        value={unsubscribed}
        nameMaxWidth
      />
      <BotCard.DataEntry
        iconName="subscribed"
        name="Подписались"
        nameLabel="за день"
        value={subscribed}
        nameMaxWidth
      />
      <BotCard.DataEntry
        iconName="message"
        name="Сообщений"
        nameLabel="всего"
        value={`${messages} K`}
        nameMaxWidth
      />
      <BotCard.DataEntry
        iconName="command"
        name="Команд"
        nameLabel="всего"
        value={commands}
        nameMaxWidth
      />
    </div>
  );
};

export default Stats;
