import useAppDispatch from 'hooks/useAppDispatch';
import {
  closeModal,
  openCurrBuyModal,
  openFinPass,
  openFinPassWithdraw,
  selectType,
  setModal,
} from './finances.reducer';
import { useSelectFinancesModal } from './finances.selectors';
import { FinanceModals, Sources } from './finances.types';

export const useFinancesModal = () => {
  const dispatch = useAppDispatch();
  const modal = useSelectFinancesModal();

  const isWithdraw = modal === FinanceModals.BUY;
  const isChangeSource = modal === FinanceModals.CHANGE_SOURCE;
  const isFinPassBuy = modal === FinanceModals.FIN_PASS_BUY;
  const isFinPassWithdraw = modal === FinanceModals.FIN_PASS_WITHDRAW;
  const isFinPass = isFinPassBuy || isFinPassWithdraw;

  const selectNewType = (type: Sources) => () => dispatch(selectType(type));

  const onClose = () => dispatch(closeModal());

  return [
    {
      modal,
      isChangeSource,
      isWithdraw,
      isFinPass,
      isFinPassBuy,
      isFinPassWithdraw,
      selectNewType,
    },
    onClose,
  ] as const;
};

export const useOpenModals = (defaultSource: Sources = Sources.VISA) => {
  const dispatch = useAppDispatch();

  const openFin = () => dispatch(openFinPass(defaultSource));

  const onOpenCurrModalBuy = (modal: Sources) => dispatch(openCurrBuyModal(modal));
  const openFinWithdraw = () => dispatch(openFinPassWithdraw());
  const openBuy = () => dispatch(setModal(FinanceModals.BUY));
  const openChange = () => dispatch(setModal(FinanceModals.CHANGE_SOURCE));
  const changeSource = (newType: Sources) => () => dispatch(selectType(newType));

  return {
    openFin,
    openFinWithdraw,
    openBuy,
    openChange,
    changeSource,
    onOpenCurrModalBuy,
  } as const;
};
