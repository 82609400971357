import { FC } from 'react';
import ModalWithForm from 'components/ModalWithForm';
import { CommonModalProps } from 'components/Modal';
import styles from './AddGreetingBotAccountChecking.module.scss';
import Button from 'components/Button';
import {
  GreetinBotModalState,
  TAutoErrorBotResponse,
  WelcomeBot,
  WelcomeBotError,
} from '../../telegram.types';
import {
  useDeleteWelcomeBotUserMutation,
  useWelcomeBotUserValidateMutation,
} from '../../codegen/telegram.api';
import { selectPlan, setIsFloodError } from '../../telegram.reducer';
import useAppDispatch from 'hooks/useAppDispatch';
import { useForm } from 'react-hook-form';

interface Props extends CommonModalProps {
  welcomeBot?: WelcomeBot;
  isBotLoading: boolean;
}

export const AddGreetingBotAccountChecking: FC<Props> = ({ welcomeBot, ...props }) => {
  const [deleteNumber] = useDeleteWelcomeBotUserMutation();
  const dispatch = useAppDispatch();
  const { handleSubmit } = useForm();
  const [validate] = useWelcomeBotUserValidateMutation();
  const onSubmit = handleSubmit(() => {
    validate({}).then(res => {
      dispatch(selectPlan(GreetinBotModalState.CONFIRMATION));
      if ('error' in res) {
        const error: TAutoErrorBotResponse = res as TAutoErrorBotResponse;
        if (error.error.data.type === WelcomeBotError.FLOOD) {
          dispatch(setIsFloodError());
        }
      }
    });
  });

  return (
    <>
      <ModalWithForm
        title="ПРАВИЛЬНО ЛИ УКАЗАН НОМЕР ТЕЛЕФОНА?"
        text=""
        onSubmit={onSubmit}
        isValid={true}
        {...props}
        noBtn
        formClassName={styles.Root}
        isLoading={props.isBotLoading}
      >
        <h1>{welcomeBot?.phone}</h1>
        <div className={styles.Buttons}>
          <Button type={'submit'}>Продолжить</Button>
          <Button
            onClick={(event: { preventDefault: () => void }) => {
              event.preventDefault();
              deleteNumber({});
              dispatch(selectPlan(GreetinBotModalState.ACCOUNT));
            }}
            variant={'text'}
          >
            Изменить
          </Button>
        </div>
      </ModalWithForm>
    </>
  );
};
