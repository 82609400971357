import { FC } from 'react';
import classNames from 'classnames';

import styles from './Logo.module.scss';

import logo from 'assets/svg/auth/newLogo.svg';
import env from 'config/env';

type Props = {
  className?: string;
  alt?: boolean;
};

const Logo: FC<Props> = ({ className }) => {
  return (
    <a href={env.LANDING_URL} target={'_blank'} className={classNames(styles.Logo, className)}>
      <img src={logo} alt="star" width={148} height={52} />
    </a>
  );
};

export default Logo;
