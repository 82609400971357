import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

type Diff = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

function useCountdown(date: Dayjs): Diff | null {
  const [diff, setDiff] = useState<Diff | null>(null);

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let interval: NodeJS.Timer;

    const handler = () => {
      // get diff
      const now = dayjs();
      const seconds = date.diff(now, 'second');
      // if target date has already passed,
      // clear interval and set diff to null
      if (Number.isNaN(seconds) || seconds < 0) {
        clearInterval(interval);
        setDiff(null);
        return;
      }
      // calculate
      const days = Math.floor(seconds / 24 / 60 / 60);
      const hoursLeft = Math.floor(seconds - days * 86400);
      const hours = Math.floor(hoursLeft / 3600);
      const minutesLeft = Math.floor(hoursLeft - hours * 3600);
      const minutes = Math.floor(minutesLeft / 60);
      const remainingSeconds = seconds % 60;
      // update state
      setDiff({
        days,
        hours,
        minutes,
        seconds: remainingSeconds,
      });
    };
    handler();
    // eslint-disable-next-line prefer-const
    interval = setInterval(handler, 500);
    return () => clearInterval(interval);
  }, [date]);

  return diff;
}

export default useCountdown;
