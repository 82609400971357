import styles from '../FormComponentSelector/FormComponentSelector.module.scss';
import Icon from 'components/Icon';
import Menu from 'components/Menu';
import { menuItems } from 'modules/landing-dashboard/landing-dashboard.consts';
import { StatusSelectorTypes } from './StatusSelector.types';
import { SlicedText } from 'components/SlicedText/SlicedText';

export const StatusSelector = ({
  selected,
  setSelected,
  type,
  label,
  isLabel,
}: StatusSelectorTypes) => {
  return (
    <Menu
      className={styles.Menu}
      items={menuItems.map(item => {
        return {
          isSelected: item === selected,
          label: item,
          onClick: () => setSelected(item),
        };
      })}
      datatype={type}
      btnClassName={styles.btn}
    >
      <div>
        {isLabel && <p className={styles.Label}>{label}</p>}
        <SlicedText text={selected} className={styles.menuText} />
      </div>
      <Icon className={styles.IconDown} iconName="arrow-down" size={18} />
    </Menu>
  );
};
