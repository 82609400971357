import { FC } from 'react';
import Icon from 'components/Icon';
import styles from './SmallLoader.module.scss';

const SmallLoader: FC = () => {
  return (
    <div className={styles.Loading}>
      <Icon className={styles.Loader} iconName={'loader'} />
    </div>
  );
};

export default SmallLoader;
