import { FC } from 'react';
import { Link } from 'react-router-dom';

import Balance from '../Balance';

import styles from './TopSection.module.scss';
import Icon from 'components/Icon';
import r from 'constants/routes';
import WithdrawModal from '../WithdrawModal';

const TopSection: FC<{ finPassword?: string }> = ({ finPassword }) => {
  return (
    <section className={styles.Profile}>
      <Balance />

      <Link className={styles.History} to={r.finances.transactions}>
        <Icon size={18} iconName="finances-clock" className={styles.Icon} />
        <p className={styles.Text}>История платежей</p>
      </Link>
      <WithdrawModal memoFinPassword={finPassword} />
    </section>
  );
};

export default TopSection;
