import { FC } from 'react';

import PageHeader from 'components/PageHeader';
import BotsList from 'modules/telegram/components/BotsList';
import useDisclosure from 'hooks/useDisclosure';
import AddBotModal from 'modules/telegram/components/AddBotModal';
import r from 'constants/routes';

const ConnectedBots: FC = () => {
  const { open, onClose } = useDisclosure({ blockScroll: true });

  return (
    <>
      <PageHeader title="Подключенные боты" />
      <BotsList
      // bots={fakeAdminBots.map(bot => ({
      //   ...bot,
      //   videoLink: 'https://youtube.com/',
      //   button: {
      //     children: 'Перейти в бота',
      //     onClick: () =>
      //       navigate(
      //         generatePath(r.telegram.constructor.connectedBotInfo, { id: bot.id.toString() }),
      //       ),
      //   },
      // }))}
      />

      <AddBotModal
        editPath={r.telegram.constructor.connectedBotInfo}
        isOpen={open}
        onClose={onClose}
      />
    </>
  );
};

export default ConnectedBots;
