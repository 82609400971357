import { FC } from 'react';
import classNames from 'classnames';

import spriteIcons from 'assets/svg/sprite.icons.svg';

export interface IIcon {
  iconName: string;
  size?: number;
  className?: string;
  onClick?: () => void;
}

const Icon: FC<IIcon> = ({ iconName, size = 24, className, onClick }) => {
  return (
    <svg width={size} height={size} className={classNames(className)} onClick={onClick}>
      <use xlinkHref={`${spriteIcons}#${iconName}`} />
    </svg>
  );
};

export default Icon;
