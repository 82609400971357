import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './WithSideIcon.module.scss';
import IconButton, { IconButtonProps } from 'components/IconButton';

export type SideIcon = Omit<IconButtonProps, 'iconSize'>;

type Props = {
  children: ReactNode;
  sideIcon?: SideIcon;
};

const WithSideIcon: FC<Props> = ({ children, sideIcon }) => {
  return (
    <div className={styles.Wrapper}>
      {children}
      {sideIcon && (
        <IconButton
          {...sideIcon}
          className={classNames(styles.SideIcon, sideIcon.className)}
          iconSize={24}
        />
      )}
    </div>
  );
};

export default WithSideIcon;
