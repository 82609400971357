import { FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CommonModalProps } from 'components/Modal';
import Input from 'components/Input';
import { addGreetingBotAccount } from 'modules/telegram/telegram.schema';
import { GreetinBotModalState, WelcomeBotAuthBody } from 'modules/telegram/telegram.types';
import ModalWithForm from 'components/ModalWithForm';
import useAppDispatch from 'hooks/useAppDispatch';
import { useCreateWelcomeBotMutation } from 'modules/telegram/codegen/telegram.api';
import styles from './AddGreetingBotAccount.module.scss';
import { closeModal, selectPlan } from 'modules/telegram/telegram.reducer';
import Button from 'components/Button';

type Props = { isBotLoading: boolean } & CommonModalProps;
const AddGreetingBotAccount: FC<Props> = props => {
  const [startAuth, { isLoading }] = useCreateWelcomeBotMutation();
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<WelcomeBotAuthBody>({
    mode: 'onChange',
    resolver: yupResolver(addGreetingBotAccount()),
  });

  const onSubmit = handleSubmit(async ({ phone, name }) => {
    const res = await startAuth({ phone, name }).unwrap();
    if (res) {
      dispatch(selectPlan(GreetinBotModalState.CHECKING));
    }
  });

  return (
    <ModalWithForm
      ref={modalRef}
      title="Привязать аккаунт "
      text="Пожалуйста, введите ваш номер телефона и мы вышлем код с подтверждением на Telegram аккаунт"
      onSubmit={onSubmit}
      isValid={isValid}
      formClassName={styles.Root}
      isLoading={isLoading || props.isBotLoading}
      noBtn
      {...props}
    >
      <Input
        label="Номер телефона"
        register={register('phone')}
        defaultValue="+7"
        invalid={!!errors.phone?.message}
        errorMsg={errors.phone?.message}
      />
      <Input
        label="Имя бота"
        register={register('name')}
        invalid={!!errors.name?.message}
        errorMsg={errors.name?.message}
      />

      <div className={styles.Btns}>
        <Button
          className={styles.BtnCLose}
          variant="text"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Закрыть
        </Button>
        <Button className={styles.Btn} variant="primary" type="submit" disabled={!isValid}>
          Подтвердить
        </Button>
      </div>
    </ModalWithForm>
  );
};

export default AddGreetingBotAccount;
