import { CSSProperties, FC } from 'react';

import styles from './Website.module.scss';
import {
  LandingIdType,
  Website as TWebsite,
} from 'modules/landing-generator/landing-generator.types';
import DependentText from '../../../DependentText';
import { ElemType } from 'modules/cards/cards.types';
import { useRegisterMetricsMutation } from 'modules/landing-dashboard/landing-dashboard.api';

type Props = {
  id: LandingIdType;
  btnId: LandingIdType;
  website: TWebsite;
  index: number;
};

const Website: FC<Props> = ({ btnId, website }) => {
  const [register] = useRegisterMetricsMutation();

  const cssVars = {
    '--bg-color': website?.color,
  } as CSSProperties;

  const handleLink = () => {
    register({ id: btnId, type: ElemType.LINK });
    window.location.assign(website.url);
  };

  return (
    <div className={styles.Wrapper} onClick={handleLink}>
      <div className={styles.Content} style={cssVars}>
        <DependentText type="subtitle" {...website.title} />
        <DependentText type="text" {...website.text} />
      </div>
    </div>
  );
};

export default Website;
