import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import get from 'lodash-es/get';

import TextArea from 'components/TextArea';
import FormPageTemplate from 'components/FormPageTemplate';
import { FirstPostsCommentsRequestBody } from 'modules/vk/vk.types';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import ToggleActionCard from 'modules/vk/components/ToggleActionCard';
import ToggleSwitchWithLabel from 'components/ToggleSwitchWithLabel';
import { useChangeRepostsGroupMutation, useGetRepostsGroupQuery } from 'modules/vk/vk.api';
import { useVkAccount } from 'modules/account/account.selector';
import { yupResolver } from '@hookform/resolvers/yup';
import { first3PhotoCommentsSchema } from 'modules/vk/vk.schema';

const FirstPostsComments: FC = () => {
  const vkId = useVkAccount();
  const { data, isError, isLoading } = useGetRepostsGroupQuery(
    { vkId: vkId as string },
    { skip: !vkId },
  );
  const [changeRepostsGroup] = useChangeRepostsGroupMutation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm<FirstPostsCommentsRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(first3PhotoCommentsSchema()),
    defaultValues: { hitLike: false },
  });

  const onSubmit: SubmitHandler<FirstPostsCommentsRequestBody> = async formData => {
    if (vkId)
      await changeRepostsGroup({
        vkId,
        comments: [formData.text1, formData.text2, formData.text3, formData.text4],
        // TODO: change to commentingNew?
        commentingNewPostsInGroups: formData.hitLike,
      }).unwrap();
    await showFeedback({
      title: 'Бот готов',
      text: 'Теперь бот будет писать сообщение за вас',
      type: EFeedbackType.SUCCESS,
    });
  };

  const values = watch();

  if (isLoading) return null;
  if (isError) return null;
  return (
    <FormPageTemplate
      title="Комментарии под первыми постами"
      description="Стоит написать разный текст, чтобы не улететь в бан. Позже его можно будет редактировать"
      onSubmit={handleSubmit(onSubmit)}
      buttonText="Сохранить"
      isValid={isValid}
      short
      headerAddon={
        <ToggleSwitchWithLabel
          active={values.isActive}
          toggle={() => setValue('isActive', !values.isActive)}
        />
      }
    >
      <TextArea
        label="Вариант текста 1"
        register={register('text1')}
        invalid={!!errors.text1?.message}
        errorMsg={errors.text1?.message}
        defaultValue={get(data?.body.comments, 0)}
      />
      <TextArea
        label="Вариант текста 2"
        register={register('text2')}
        invalid={!!errors.text2?.message}
        errorMsg={errors.text2?.message}
        defaultValue={get(data?.body.comments, 1)}
      />
      <TextArea
        label="Вариант текста 3"
        register={register('text3')}
        invalid={!!errors.text3?.message}
        errorMsg={errors.text3?.message}
        defaultValue={get(data?.body.comments, 2)}
      />
      <TextArea
        label="Вариант текста 4"
        register={register('text4')}
        invalid={!!errors.text4?.message}
        errorMsg={errors.text4?.message}
        defaultValue={get(data?.body.comments, 3)}
      />
      <ToggleActionCard
        name="Комментировать новые посты"
        variant="form-field"
        toggle={{
          active: values.hitLike,
          toggle: () => setValue('hitLike', !values.hitLike),
          defaultChecked: data?.body.commentingNewPostsInGroups,
        }}
      />
    </FormPageTemplate>
  );
};

export default FirstPostsComments;
