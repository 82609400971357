import { FC } from 'react';
import EmptyPage from 'components/EmptyPage';
import thumb from 'assets/svg/vk/userPlusBottom.svg';
import AddAccountBtn from '../../components/AddAccountBtn';
import styles from './EmptyVkPage.module.scss';

const EmptyVkPage: FC = () => {
  return (
    <div className={styles.Root}>
      <EmptyPage
        img={<img src={thumb} alt={'img'} />}
        title={'Добавьте новый аккаунт'}
        text={''}
        footer={<AddAccountBtn />}
      />
    </div>
  );
};

export default EmptyVkPage;
