import { FC } from 'react';

import r from 'constants/routes';
import HeaderWithTabs from 'components/HeaderWithTabs';

export const links = [
  { link: r.telegram.index, name: 'Бот-администратор' },
  { link: r.telegram.spamBots, name: 'Бот рассылки' },
  { link: r.telegram.greetingBot, name: 'Авто-приветствие' },
];

const Header: FC = () => {
  return <HeaderWithTabs links={links} />;
};

export default Header;
