import { FC } from 'react';

import styles from './WriteAllPartners.module.scss';
import Button from 'components/Button';
import { usePartnershipData } from 'modules/partnership-program/partnership-program.hooks';
import AllPartnersModal from '../PartnersNewsletterModal/AllPartnersModal';
import useDisclosure from 'hooks/useDisclosure';

const WriteAllPartners: FC = () => {
  const { onClose, onOpen, open } = useDisclosure();
  const [{ data: partners }] = usePartnershipData();

  if (!partners?.body.hasReferrals) return null;

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Content}>
        <h3 className={styles.Title}>Хотите отправить сообщение сразу же всем партнерам?</h3>
        <p className={styles.Text}>
          Отправьте рассылку всем партнерам или партерам одной линии в пару кликов.
        </p>
        <Button onClick={onOpen} variant="magic" className={styles.Btn}>
          Создать рассылку
        </Button>
      </div>
      <AllPartnersModal isOpen={open} onClose={onClose} />
    </div>
  );
};

export default WriteAllPartners;
