import { FC } from 'react';
import styles from './CreateSpamBotMessagesButton.module.scss';
import Button from 'components/Button';
import useDisclosure from 'hooks/useDisclosure';
import { useDeleteSpamBotChatMutation } from '../../../../codegen/telegram.spam.api';
import { useBotId } from '../../../../telegram.hooks';
import { TTelegramErrorResult } from '../../../../telegram.types';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import r from 'constants/routes';
import CreateSpamBotMessageModal from '../CreateSpamBotMessageModal';

const CreateSpamBotMessagesButton: FC = () => {
  const botId = useBotId();
  const { chatId } = useParams();
  const [deleteChatById, { isLoading }] = useDeleteSpamBotChatMutation();
  const navigate = useNavigate();
  const {
    open: isNewOpen,
    onOpen: onNewOpen,
    onClose: onNewClose,
  } = useDisclosure({ blockScroll: true });
  const onLeaveChatHandler = () => {
    if (chatId)
      deleteChatById({ botId, chatId }).then(res => {
        if ('error' in res) {
          const clonedError: TTelegramErrorResult = JSON.parse(JSON.stringify(res));
          showFeedback({
            title: 'Ошибка',
            text: clonedError.error.data.description,
            type: EFeedbackType.ERROR,
          });
        } else {
          showFeedback({
            title: 'ГОТОВО!',
            text: 'Вы удалили чат',
            type: EFeedbackType.SUCCESS,
          }).then(() => {
            navigate(generatePath(r.telegram.editSpamBot, { id: botId }));
          });
        }
      });
  };

  return (
    <>
      <div className={styles.Root}>
        <Button onClick={onNewOpen} variant={'magic'}>
          Новое сообщение
        </Button>
        <Button
          disabled={isLoading}
          onClick={onLeaveChatHandler}
          className={styles.Delete}
          variant={'tetriary'}
        >
          Покинуть чат
        </Button>
      </div>
      {isNewOpen && <CreateSpamBotMessageModal isOpen={isNewOpen} onClose={onNewClose} />}
    </>
  );
};

export default CreateSpamBotMessagesButton;
