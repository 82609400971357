import { FC, useRef } from 'react';

import { CommonModalProps } from 'components/Modal';
import { useChangeAvatarMutation } from 'modules/settings/settings.api';
import SettingsFormModal from '../SettingsFormModal';
import ImgInput from 'components/ImgInput';

const AvatarEditModal: FC<CommonModalProps> = props => {
  const file = useRef<File | null | undefined>(null);
  const [changeAvatar] = useChangeAvatarMutation();

  return (
    <SettingsFormModal
      title="СМЕНА ФОТО"
      text="Следуйте по инструкции при добавлении фотографии"
      isValid={true}
      btnText="Сохранить"
      onSubmit={event => {
        event?.preventDefault();
        if (file.current) changeAvatar({ file: file.current });
        props.onClose();
      }}
      {...props}
    >
      <ImgInput
        setFile={newFile => {
          file.current = newFile;
        }}
      />
    </SettingsFormModal>
  );
};

export default AvatarEditModal;
