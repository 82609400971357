import { FC, useCallback, useState } from 'react';
import isEmpty from 'lodash-es/isEmpty';

import styles from './PartnersList.module.scss';
import PartnerCard from '../PartnerCard';
import { usePartnershipData } from 'modules/partnership-program/partnership-program.hooks';
import EmptyPage from 'components/EmptyPage';
import groupsImg from 'assets/svg/empty-pages/groups.svg';
import { useSelectedPartners } from 'modules/partnership-program/partnership-program.selectors';
import SelectedPartnersModal from '../PartnersNewsletterModal/SelectedPartnerModal';
import useAppDispatch from 'hooks/useAppDispatch';
import { setMailModal } from 'modules/partnership-program/partnership-program.reducer';

const PartnersList: FC = () => {
  const dispatch = useAppDispatch();
  const [{ data, isError, isLoading }] = usePartnershipData();
  const recipients = useSelectedPartners();
  const [recipient, setRecipient] = useState<string | number | null>(null);

  const onWrite = useCallback(
    (id: string | number) => {
      setRecipient(id);
      dispatch(setMailModal(true));
    },
    [dispatch],
  );

  const onWriteCancel = useCallback(() => {
    setRecipient(null);
  }, []);

  if (isLoading) return null;
  if (isError) return null;

  return (
    <>
      <section className={styles.Section}>
        {isEmpty(data?.body.items) ? (
          <EmptyPage
            img={<img src={groupsImg} alt="Groups img" width={206} height={190} />}
            text="Пока партнеров нет"
          />
        ) : (
          <div className={styles.List}>
            {data?.body.items.map(p => (
              <PartnerCard key={p.id} partner={p} onWrite={onWrite} />
            ))}
          </div>
        )}
      </section>

      <SelectedPartnersModal
        recipients={recipient ? [recipient] : recipients}
        onCancel={onWriteCancel}
      />
    </>
  );
};

export default PartnersList;
