import { injectedRtkApi } from './telegram.admin.api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TAdminBotState,
  TAdminChatsParams,
  TAdminMessages,
  TCommand,
  TPaginationParams,
  TScheduledMessage,
  TTelegramChat,
} from '../telegram.types';
import cloneDeep from 'lodash-es/cloneDeep';
import { getPersistedState } from '../telegram.utils';
import { adminBotChatParams, dataCountLoadingState, paginationParams } from './telegram.constants';

type TState = {
  adminBotChats: TAdminBotState<TTelegramChat[]>;
  adminBotCommands: TAdminBotState<TCommand[]>;
  adminBotMessages: TAdminBotState<TAdminMessages[]>;
  adminBotChatParams: TAdminChatsParams;
  commandsPageParams: TPaginationParams;
  messagesPageParams: TPaginationParams;
  scheduledMessagesPageParams: TPaginationParams;
  sentMessagesPageParams: TPaginationParams;
  scheduledMessages: TAdminBotState<TScheduledMessage[]>;
  adminBotSelectedChats: {
    chats: string[];
    selectedMessage: { [key: string]: TAdminMessages };
    selectedCommands: string[];
    botName: string;
  };
};

const adminBotSelectedChats = {
  chats: [],
  selectedMessage: {},
  selectedCommands: [],
  botName: '',
};

const initialState: TState = {
  adminBotChats: dataCountLoadingState,
  adminBotCommands: dataCountLoadingState,
  adminBotMessages: dataCountLoadingState,
  scheduledMessages: dataCountLoadingState,
  adminBotChatParams,
  adminBotSelectedChats,
  commandsPageParams: paginationParams,
  messagesPageParams: paginationParams,
  scheduledMessagesPageParams: { ...paginationParams, limit: 5 },
  sentMessagesPageParams: { ...paginationParams, limit: 5 },
};

export const telegramAdminSlice = createSlice({
  name: 'telegramAdmin',
  initialState,
  reducers: {
    setAdminChatsParams: (state, { payload }: PayloadAction<TAdminChatsParams>) => {
      state.adminBotChatParams = {
        ...state.adminBotChatParams,
        ...payload,
      };
    },

    setCommandsParams: (state, { payload }: PayloadAction<TPaginationParams>) => {
      state.commandsPageParams = {
        ...state.commandsPageParams,
        ...payload,
      };
    },

    setMessagesParams: (state, { payload }: PayloadAction<TPaginationParams>) => {
      state.messagesPageParams = {
        ...state.messagesPageParams,
        ...payload,
      };
    },

    setScheduledMessagesPageParams: (state, { payload }: PayloadAction<TPaginationParams>) => {
      state.scheduledMessagesPageParams = {
        ...state.scheduledMessagesPageParams,
        ...payload,
      };
    },

    setSentMessagesPageParams: (state, { payload }: PayloadAction<TPaginationParams>) => {
      state.sentMessagesPageParams = {
        ...state.sentMessagesPageParams,
        ...payload,
      };
    },

    setSelectChatById: (state, { payload }: PayloadAction<Pick<TTelegramChat, 'chatId'>>) => {
      state.adminBotChats.data = state.adminBotChats.data.map(chat => {
        if (payload.chatId === chat.id) {
          return {
            ...chat,
            isSelected: !chat.isSelected,
          };
        }
        return chat;
      });
    },

    setSelectCommandById: (state, { payload }: PayloadAction<Pick<TCommand, 'id'>>) => {
      state.adminBotCommands.data = state.adminBotCommands.data.map(command => {
        if (payload.id === command.id) {
          return {
            ...command,
            isChecked: !command.isChecked,
          };
        }
        return command;
      });
    },

    setSelectAllChats: state => {
      if (state.adminBotChats.data.every(item => item.isSelected)) {
        state.adminBotChats.data = state.adminBotChats.data.map(chat => {
          return {
            ...chat,
            isSelected: false,
          };
        });
      } else {
        state.adminBotChats.data = state.adminBotChats.data.map(chat => {
          return {
            ...chat,
            isSelected: true,
          };
        });
      }
    },

    setSelectAllCommands: state => {
      if (state.adminBotCommands.data.every(item => item.isChecked)) {
        state.adminBotCommands.data = state.adminBotCommands.data.map(command => ({
          ...command,
          isChecked: false,
        }));
      } else {
        state.adminBotCommands.data = state.adminBotCommands.data.map(command => ({
          ...command,
          isChecked: true,
        }));
      }
    },
  },

  extraReducers: builder => {
    builder
      .addMatcher(
        injectedRtkApi.endpoints.getAdminBotChatListById.matchFulfilled,
        (state, { payload }) => {
          const telegramPersisted = getPersistedState('telegramPersisted');
          const data = payload?.data?.map(item => {
            const set = new Set<string>(telegramPersisted.chats);
            if (set.has(item.id)) {
              return {
                ...item,
                isSelected: true,
              };
            }
            return {
              ...item,
              isSelected: false,
            };
          });
          state.adminBotChats = { loading: false, count: payload.count, data };
        },
      )
      .addMatcher(
        injectedRtkApi.endpoints.getAdminCommands.matchFulfilled,
        (state, { payload }) => {
          const persist = getPersistedState('telegramPersisted');

          const data = payload.data.map(obj => {
            const set = new Set<string>(persist.selectedCommands);

            if (set.has(obj.id + '')) {
              return {
                ...obj,
                isChecked: true,
              };
            } else {
              return {
                ...obj,
                isChecked: false,
              };
            }
          });
          state.adminBotCommands = { loading: false, count: payload.count, data };
        },
      )
      .addMatcher(
        injectedRtkApi.endpoints.getAdminMessages.matchFulfilled,
        (state, { payload }) => {
          const data = payload.data.map(obj => {
            return {
              ...obj,
              isChecked: false,
            };
          });
          state.adminBotMessages = { count: payload.count, loading: false, data };
        },
      )
      .addMatcher(
        injectedRtkApi.endpoints.getScheduledAdminMessages.matchFulfilled,
        (state, { payload }) => {
          state.scheduledMessages = { count: payload.count, data: payload.data, loading: false };
        },
      );
  },
});

const telegramPersistSlice = createSlice({
  name: 'telegramPersisted',
  initialState: initialState.adminBotSelectedChats,
  reducers: {
    setBotName: (state, { payload }: PayloadAction<string>) => {
      state.botName = payload;
    },
    setSelectedChats: (state, { payload }: PayloadAction<string>) => {
      const set = new Set<string>(state.chats);
      if (set.has(payload)) {
        set.delete(payload);
      } else {
        set.add(payload);
      }
      state.chats = Array.from(set);
    },
    setSelectedAllChats: (state, { payload }: PayloadAction<string[]>) => {
      const set = new Set<string>(payload);
      state.chats = Array.from(set);
    },

    setToggledMessage: (state, { payload }: PayloadAction<{ [key: string]: TAdminMessages }>) => {
      state.selectedMessage = {};
      const map = new Map(Object.entries(cloneDeep(state).selectedMessage)) ?? new Map();
      const key = Object.keys(payload)[0];
      const value = Object.values(payload)[0];

      if (map.size === 0) {
        map.set(key, value);
      } else {
        map.delete(key);
      }

      state.selectedMessage = Object.fromEntries(map);
    },

    removeToggledMessage: state => {
      state.selectedMessage = {};
    },

    clearSelectedChats: state => {
      state.chats = [];
    },

    setPersistSelectedCommands: (state, { payload }: PayloadAction<string>) => {
      const commandsSet = new Set(state.selectedCommands);
      commandsSet.add(payload);
      state.selectedCommands = Array.from(commandsSet);
    },

    removePersistSelectedCommands: (state, { payload }: PayloadAction<string>) => {
      const commandsSet = new Set(state.selectedCommands);
      commandsSet.delete(payload);
      state.selectedCommands = Array.from(commandsSet);
    },

    removePersistSelectedAllCommands: state => {
      state.selectedCommands = [];
    },
  },
});

export const {
  setSelectedChats,
  setSelectedAllChats,
  clearSelectedChats,
  setToggledMessage,
  removeToggledMessage,
  setPersistSelectedCommands,
  removePersistSelectedCommands,
  removePersistSelectedAllCommands,
  setBotName,
} = telegramPersistSlice.actions;

export const {
  setAdminChatsParams,
  setCommandsParams,
  setScheduledMessagesPageParams,
  setSentMessagesPageParams,
  setMessagesParams,
  setSelectChatById,
  setSelectAllChats,
  setSelectCommandById,
  setSelectAllCommands,
} = telegramAdminSlice.actions;

export { telegramPersistSlice };
