import { FC } from 'react';

import styles from './AuthButton.module.scss';
import Button, { ButtonProps } from 'components/Button';

const AuthButton: FC<ButtonProps<'button'>> = ({ children, ...props }) => {
  return (
    <div className={styles.Wrapper}>
      <Button className={styles.Button} type="submit" size="l" {...props}>
        {children}
      </Button>
    </div>
  );
};

export default AuthButton;
