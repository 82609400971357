import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Icon from 'components/Icon';
import kbToMbConverter from 'utils/kbToMbConverter';
import first from 'lodash-es/first';
import { ImageFileType } from 'components/ImgInput';
import styles from './AddFiles.module.scss';

type Props = {
  setFile: (file?: File) => void;
  setFilesList: (event: ChangeEvent<HTMLInputElement>) => void;
  limit?: number;
  defaultFile?: File;
};

const AddFiles: FC<Props> = ({ setFile, limit, defaultFile, setFilesList }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<ImageFileType[]>([]);
  const [, setIsError] = useState(false);

  const handleReUpload = async (file: File, isEffect?: boolean) => {
    if (inputRef.current) inputRef.current.value = '';
    if (!file) return;

    const sizeFileInMb = kbToMbConverter(file.size);

    if (sizeFileInMb > 2.5) {
      setIsError(true);
      setTimeout(() => setIsError(false), 2500);
      return;
    }

    const imagesCopy = [...files];

    if (limit) {
      if (files.length >= Number(limit)) imagesCopy.splice(0, 1);
    }

    imagesCopy.push({
      name: file.name,
      img: URL.createObjectURL(file),
      size: sizeFileInMb,
    });
    if (!isEffect) {
      setFile(file);
    }
    setFiles(imagesCopy);
  };

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = first(e.target.files);
    setFilesList(e);
    if (file) handleReUpload(file);
  };

  useEffect(() => {
    if (defaultFile) {
      handleReUpload(defaultFile, true);
    }
  }, [defaultFile]);

  return (
    <div
      onClick={() => {
        if (inputRef.current) {
          inputRef?.current.click();
        }
      }}
      className={styles.Root}
    >
      <Icon iconName={'brace'} />
      <p className={styles.Par}>Добавить файл(-ы)</p>
      <input multiple onChange={handleUpload} ref={inputRef} type="file" className={styles.Input} />
    </div>
  );
};

export default AddFiles;
