import { FC } from 'react';

import styles from './BotBuilderLogo.module.scss';
import Title from 'components/Title';

const BotBuilderLogo: FC = () => {
  return (
    <div className={styles.Logo}>
      <Title className={styles.Title}>BuilderBOt</Title>
      <div className={styles.Label}>Free</div>
    </div>
  );
};

export default BotBuilderLogo;
