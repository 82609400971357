import { FC, useEffect } from 'react';

import styles from './Statistics.module.scss';
import StatSections from 'modules/landing-dashboard/components/StatSections';
import StatHeader from 'modules/landing-dashboard/components/StatHeader';
import StatGeneralSection from 'modules/landing-dashboard/components/StatGeneralSection';
import { useGetMetricsQuery } from 'modules/landing-dashboard/landing-dashboard.api';
import {
  selectPeriodType,
  useSelectStatisticsId,
} from 'modules/landing-dashboard/landing-dashboard.selectors';
import { setStatisticsId } from 'modules/landing-dashboard/landing-dashboard.reducer';
import { useGetPagesFiltered } from 'modules/landing-dashboard/landing-dashboard.hooks';
import useTypedSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';

const Statistics: FC = () => {
  const id = useSelectStatisticsId();
  const timeBucket = useTypedSelector(selectPeriodType);
  const dispatch = useAppDispatch();
  const selectedId = useSelectStatisticsId();

  const { data: pages } = useGetPagesFiltered();
  const { data, isError, isLoading } = useGetMetricsQuery(
    { pageId: id as string, timeBucket },
    { skip: !id },
  );

  useEffect(() => {
    if (!selectedId) {
      const firstPageId = pages[0]?.id;

      dispatch(setStatisticsId(firstPageId));
    }
  }, [pages]);

  if (isLoading) return null;

  if (isError) return null;
  if (isLoading) return null;

  const buttons = data?.body.socials.buttons ?? [];
  const links = data?.body.socials.links ?? [];
  return (
    <div className={styles.Statistics}>
      <StatHeader />
      <StatGeneralSection />
      <StatSections
        title={'Клики на кнопку'}
        block={buttons.map(({ count, text }) => ({ num: count, text }))}
      />
      <StatSections
        title={'Клики на ссылку'}
        block={links.map(({ count, text }) => ({ num: count, text }))}
        type={'links'}
      />
    </div>
  );
};

export default Statistics;
