import { FC } from 'react';
import range from 'lodash-es/range';

import styles from './Circles.module.scss';

const PurpleCircle: FC<{ id: number }> = ({ id }) => (
  <div className={styles.PurpleCircle} data-id={id} />
);
const BlueCircle: FC<{ id: number }> = ({ id }) => (
  <div className={styles.BlueCircle} data-id={id} />
);
const WhiteCircle = () => <div className={styles.WhiteCircle} />;

const Circles: FC = () => {
  return (
    <>
      {range(4).map(i => (
        <PurpleCircle key={i} id={i + 1} />
      ))}
      {range(3).map(i => (
        <BlueCircle key={i} id={i + 1} />
      ))}
      <WhiteCircle />
    </>
  );
};

export default Circles;
