import Modal from '../Modal';
import styles from './VideoModal.module.scss';
import { PlayIcon } from './PlayIcon';
import { FC } from 'react';
import useDisclosure from '../../hooks/useDisclosure';
import ColorfulBorder from '../ColorfulBorder';
import Button from '../Button';

export const VideoModal: FC<{ title?: string; link?: string }> = ({
  title = 'Видео презентация',
}) => {
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });

  return (
    <>
      <ColorfulBorder onClick={onOpen} className={styles.Root}>
        <div className={styles.InnerRoot}>
          <h1>{title}</h1>
          <PlayIcon />
        </div>
      </ColorfulBorder>
      <Modal
        className={styles.Modal}
        formClassName={styles.FormClassName}
        noBtn
        isOpen={open}
        onClose={onClose}
      >
        <div className={styles.InnerModal}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/nomLqfF_64Y"
            title="STARS ONLINE"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>

        <Button className={styles.Btn} onClick={onClose}>
          Закрыть
        </Button>
      </Modal>
    </>
  );
};
