import { FC } from 'react';

const SortIcon: FC = () => {
  return (
    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        d="M6.988 3.6546C6.96423 3.71093 6.90909 3.74756 6.84787 3.74756L0.152189 3.74748C0.0910365 3.74748 0.0358287 3.71085 0.0119771 3.65453C-0.0118002 3.59821 0.000460005 3.53304 0.0431105 3.4892L3.39095 0.0458899C3.41956 0.0164657 3.45894 -0.000178576 3.50003 -0.000178576C3.54112 -0.000178576 3.5805 0.0164657 3.60911 0.0458899L6.95694 3.48928C6.99959 3.53319 7.01178 3.59828 6.988 3.6546Z"
        fill="#70707C"
      />
      <path
        d="M6.988 6.84051C6.96423 6.78419 6.90909 6.74756 6.84787 6.74756L0.152189 6.74763C0.0910365 6.74763 0.0358287 6.78426 0.0119771 6.84059C-0.0118002 6.89691 0.000460005 6.96207 0.0431105 7.00591L3.39095 10.4492C3.41956 10.4787 3.45894 10.4953 3.50003 10.4953C3.54112 10.4953 3.5805 10.4787 3.60911 10.4492L6.95694 7.00584C6.99959 6.96193 7.01178 6.89684 6.988 6.84051Z"
        fill="#70707C"
      />
    </svg>
  );
};

export default SortIcon;
