import { FC } from 'react';

import styles from './Balance.module.scss';
import env from 'config/env';
import formatNumber from 'utils/formatNumber';
import useTypedSelector from 'hooks/useAppSelector';
import { selectUser } from 'modules/account/account.selector';

const Balance: FC = () => {
  const user = useTypedSelector(selectUser);

  const currency = env.CURRENCY;

  return (
    <div className={styles.Card}>
      <div className={styles.Balance}>
        <div className={styles.Wrapper}>
          <h1 className={styles.Number}>{formatNumber(Number(user?.balance ?? 0), 0)} </h1>
          <span className={styles.Currency}>{currency}</span>
        </div>

        <p className={styles.Text}>Основной баланс</p>
      </div>
    </div>
  );
};

export default Balance;
