import { FC } from 'react';

import styles from './RemoveComponentBtn.module.scss';
import Button from 'components/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { removeComponent, setActiveComponent } from 'modules/my-pages/my-pages.reducer';
import { useActiveComponent } from 'modules/my-pages/my-pages.selectors';

const RemoveComponentBtn: FC = () => {
  const dispatch = useAppDispatch();
  const component = useActiveComponent();

  if (!component) return null;

  const onRemove = () => {
    dispatch(setActiveComponent(null));
    dispatch(removeComponent({ hash: component.component.hash }));
  };

  return (
    <div className={styles.Wrapper}>
      <Button className={styles.Btn} variant="delete" onClick={onRemove}>
        Удалить
      </Button>
    </div>
  );
};

export default RemoveComponentBtn;
