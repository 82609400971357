import { FC, ReactNode } from 'react';

import styles from './Tooltip.module.scss';

type Props = {
  label: ReactNode;
  children: ReactNode;
};

const Tooltip: FC<Props> = ({ label, children }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Tool}>{children}</div>
      <div className={styles.Tip}>{label}</div>
    </div>
  );
};

export default Tooltip;
