import { FC } from 'react';
import { ParentSize } from '@visx/responsive';

import styles from './StatSectionBottom.module.scss';
import {
  selectPeriodType,
  useSelectPeriodType,
  useSelectStatisticsId,
} from 'modules/landing-dashboard/landing-dashboard.selectors';
import StatGraph from '../StatGraph';
import useTypedSelector from 'hooks/useAppSelector';
import { useGetMetricsSheetQuery } from 'modules/landing-dashboard/landing-dashboard.api';
import { PeriodComponentSelector } from '../PeriodComponentSelector/PeriodComponentSelector';

const StatGeneralSection: FC = () => {
  const id = useSelectStatisticsId();
  const timeBucket = useTypedSelector(selectPeriodType);
  const { data, isError, isLoading } = useGetMetricsSheetQuery(
    { pageId: id as string, timeBucket },
    { skip: !id },
  );

  const periodType = useSelectPeriodType();
  const metricsData = data?.body.metrics;

  if (isError) return null;
  if (isLoading) return null;

  return (
    <section className={styles.StatGeneral}>
      <div className={styles.PeriodSelectorWrapper}>
        <PeriodComponentSelector period={periodType} />
      </div>
      <div className={styles.Graph}>
        <ParentSize>
          {({ width, height }) => (
            <StatGraph width={width} height={height} data={metricsData ?? []} period={periodType} />
          )}
        </ParentSize>
      </div>
      {!metricsData?.length && (
        <h4 className={styles.StatNotification}>Статистика по данной странице отсутствует</h4>
      )}
    </section>
  );
};

export default StatGeneralSection;
