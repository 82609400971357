import { FC, useState } from 'react';
import classNames from 'classnames';

import { Partner } from 'modules/partnership-program/partnership-program.types';
import styles from './PartnerCard.module.scss';
import Avatar from 'components/Avatar';
import getTariffPlanDatesInfo from 'utils/tariff-plan-dates-info';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import { EPlanName } from 'modules/account/account.interfaces';
import { useSelectedPartners } from 'modules/partnership-program/partnership-program.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { togglePartner } from 'modules/partnership-program/partnership-program.reducer';
import { useLinesParams } from 'modules/partnership-program/partnership-program.hooks';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

type Props = {
  partner: Partner;
  onWrite: (id: string | number) => void;
};

const PartnerCard: FC<Props> = ({ partner, onWrite }) => {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const dispatch = useAppDispatch();
  const selectedPartners = useSelectedPartners();
  const isSelected = selectedPartners.includes(partner.id);
  const line = useLinesParams();
  const isFirstLine = line === 'first';
  const { copyAndNotify } = useCopyToClipboard();
  const onInfoClick = () => {
    setOpenInfoModal(true);
  };

  const onClose = () => {
    setOpenInfoModal(false);
  };

  return (
    <div
      className={classNames(styles.PartnerCardWrapper, {
        [styles.Selected]: isSelected,
      })}
    >
      <div className={styles.PartnerCard}>
        {isFirstLine && (
          <CheckBox
            className={styles.Checkbox}
            checked={isSelected}
            onChange={() => dispatch(togglePartner(partner.id))}
            type="circle"
          />
        )}
        <div className={styles.Content}>
          <div className={styles.Top}>
            <Avatar size={80} name="partners" className={styles.Avatar} />
            <div className={styles.Vertical}>
              <div className={styles.NameBlock}>
                <Icon
                  onClick={onInfoClick}
                  iconName={openInfoModal ? 'info-icon-blue' : 'info-icon'}
                />
                <p className={styles.FullName}>{partner.fullname}</p>
              </div>
              {isFirstLine && (
                <Button
                  className={styles.Btn}
                  variant="tetriary"
                  onClick={() => onWrite(partner.id)}
                >
                  Написать
                </Button>
              )}
            </div>
          </div>
          <div className={styles.Middle}>
            <p className={styles.MiddleTitle}>Партнеры</p>
            <div className={styles.Partners}>
              <div className={styles.Block}>
                <span className={styles.Text}>Зарегистрированные </span>
                <span className={styles.Number}>{partner.referrals}</span>
              </div>
              <div className={styles.Block}>
                <span className={styles.Text}>Активированные </span>
                <span className={styles.Number}>{partner.activeReferrals}</span>
              </div>
            </div>
          </div>
          {partner.plan.name !== EPlanName.default && partner.plan.startData && (
            <div className={styles.Bottom}>
              <div className={styles.Tariff} style={{ background: partner.plan.brand }}>
                <p className={styles.Name}>
                  {partner.plan.name === EPlanName.trial ? 'Пробный период' : 'Тариф'}{' '}
                </p>
                <div className={styles.Info}>
                  <span className={styles.Type}>{partner.plan.displayName}</span>
                </div>
              </div>
              <p className={styles.PlanEnd}>
                {partner.plan.name === EPlanName.trial ? 'Пробный период' : 'Тарифный план'}{' '}
                закончится через{' '}
                {
                  getTariffPlanDatesInfo(partner.plan.startData, partner.plan.expiredDate)
                    .daysTillEnd
                }{' '}
                дней
              </p>
            </div>
          )}
          {partner.plan.name === EPlanName.default && (
            <div className={styles.NoTariff}>Нет тарифного плана</div>
          )}
        </div>
      </div>
      <Modal noBtn isOpen={openInfoModal} onClose={onClose} className={styles.InfoModal}>
        <div className={styles.ModalContent}>
          <h3 className={styles.InfoTitle}>Информация о партнёре</h3>
          <div className={styles.InfoNameBlock}>
            <p className={styles.InfoBlockTitle}>Фамилия и имя партнёра:</p>
            <p className={styles.InfoName}>{partner.fullname}</p>
          </div>
          {isFirstLine && (
            <>
              <div className={styles.LoginInfoBlock}>
                <p className={styles.InfoBlockTitle}>Номер телефона:</p>
                <p className={styles.LoginInfo}>{partner.phone}</p>
              </div>
              <div className={styles.InfoNameBlock}>
                <p className={styles.InfoBlockTitle}>Email:</p>
                <p className={styles.InfoName}>{partner.email}</p>
              </div>
            </>
          )}
          <div className={styles.LoginInfoBlock}>
            <p className={styles.InfoBlockTitle}>Логин партнёра:</p>
            <p className={styles.LoginInfo}>{partner.username}</p>
          </div>
          {partner.telegram && isFirstLine && (
            <div className={styles.TelegramBlock}>
              <Icon iconName={'telegram-blue'} />
              <div className={styles.TelegramInfoBlock}>
                <a
                  href={`tg://resolve?domain=${partner.telegram}`}
                  className={styles.TelegramInfo}
                  onFocus={e => e.target.blur()}
                >
                  {partner.telegram}
                </a>
                <p className={styles.TelegramInfoTitle}>Telegram</p>
              </div>
              <Icon
                className={styles.CopyIcon}
                iconName={'copy-icon'}
                onClick={() => copyAndNotify(partner.telegram)}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PartnerCard;
