import { FC, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import styles from './Settings.module.scss';
import { useActiveComponent } from 'modules/my-pages/my-pages.selectors';
import Page from './components/editors/Page';
import {
  EComponentType,
  NonNullableActiveComponent,
  Block as TBlock,
  Text as TText,
  Button as TButton,
  YouTubeVideo as TYouTubeVideo,
  Phone as TPhone,
  Email as TEmail,
  SocialNetwork as TSocialNetwork,
  Image as TImage,
  Form as TForm,
} from 'modules/my-pages/my-pages.types';
import Block from './components/editors/Block';
import ElementControls from './components/tools/ElementControls';
import Text from './components/editors/Text';
import YouTubeVideo from './components/editors/YouTubeVideo';
import Button from './components/editors/Button';
import Phone from './components/editors/Phone';
import Email from './components/editors/Email';
import SocialNetwork from './components/editors/SocialNetwork';
import Image from './components/editors/Image';
import Form from './components/editors/Form';
import RemoveComponentBtn from './components/tools/RemoveComponentBtn';
import { setOpenModal } from 'modules/my-pages/my-pages.reducer';
import useAppDispatch from 'hooks/useAppDispatch';

type Props = {
  className?: string;
  pageSettings?: boolean;
  type?: string;
};

const Settings: FC<Props> = ({ className, pageSettings, type }) => {
  const activeComponent = useActiveComponent();
  const dispatch = useAppDispatch();

  const showPageSettings = type === 'settings' ? activeComponent : !pageSettings;

  useEffect(() => {
    return () => {
      dispatch(setOpenModal(false));
    };
  }, []);

  const settingsComponent = useMemo(() => {
    if (activeComponent) {
      // return component depending on active component's type
      switch (activeComponent.component.type) {
        case EComponentType.BLOCK:
          return <Block component={activeComponent as NonNullableActiveComponent<TBlock>} />;
        case EComponentType.TEXT:
          return <Text component={activeComponent as NonNullableActiveComponent<TText>} />;
        case EComponentType.BUTTON:
          return <Button component={activeComponent as NonNullableActiveComponent<TButton>} />;
        case EComponentType.YOUTUBE_VIDEO:
          return (
            <YouTubeVideo
              component={activeComponent as NonNullableActiveComponent<TYouTubeVideo>}
            />
          );
        case EComponentType.PHONE:
          return <Phone component={activeComponent as NonNullableActiveComponent<TPhone>} />;
        case EComponentType.EMAIL:
          return <Email component={activeComponent as NonNullableActiveComponent<TEmail>} />;
        case EComponentType.SOCIAL_NETWORK:
          return (
            <SocialNetwork
              component={activeComponent as NonNullableActiveComponent<TSocialNetwork>}
            />
          );
        case EComponentType.IMAGE:
          return <Image component={activeComponent as NonNullableActiveComponent<TImage>} />;
        case EComponentType.FORM:
          return <Form component={activeComponent as NonNullableActiveComponent<TForm>} />;
        default:
          return null;
      }
    }
  }, [activeComponent]);

  return (
    <section className={classNames(styles.Settings, className)}>
      {showPageSettings ? (
        <>
          <ElementControls />
          {settingsComponent}
          <RemoveComponentBtn />
        </>
      ) : (
        <Page />
      )}
    </section>
  );
};

export default Settings;
