import { FC } from 'react';
import { generatePath, Navigate, Outlet, useParams } from 'react-router-dom';

import styles from './BotPageLayout.module.scss';
import BotInfo from '../BotInfo';
import NavWithTabsMobile from 'components/NavWithTabsMobile';
import r from 'constants/routes';

const BotPageLayout: FC = () => {
  const { id } = useParams();

  if (!id) return <Navigate to={r.botBuilder.index} />;

  return (
    <div className={styles.Layout}>
      <BotInfo id={id} />
      <div className={styles.Content}>
        <Outlet />
      </div>

      <NavWithTabsMobile
        className={styles.NavMobile}
        links={[
          { name: 'Сценарии', link: generatePath(r.botBuilder.scenarios.scenarios, { id }) },
          { name: 'Рассылки', link: generatePath(r.botBuilder.scenarios.mailing, { id }) },
        ]}
      />
    </div>
  );
};

export default BotPageLayout;
