import { FC, useCallback } from 'react';

import AccountSelector, { Account } from 'components/AccountSelector';
import { useDeleteVkAccountMutation, useGetVkAccountQuery } from 'modules/vk/vk.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { useVkAccount } from 'modules/account/account.selector';
import { setSelectedVkAccount } from 'modules/account/account.reducer';
import { useGetAllVkAccounts } from '../../vk.selectors';
import Spinner from '../Spinner';

const VkAccountsSelector: FC = () => {
  const dispatch = useAppDispatch();
  const selectedVk = useVkAccount();
  const vkAccounts = useGetAllVkAccounts();
  const { isLoading, isError } = useGetVkAccountQuery();
  const [deleteVkAccount] = useDeleteVkAccountMutation();

  const onSelectAccount = useCallback(
    (acc: Account) => {
      dispatch(setSelectedVkAccount(acc.id));
    },
    [dispatch],
  );

  if (isLoading) return <Spinner />;
  if (isError) return null;
  return (
    <AccountSelector
      selectedAccount={selectedVk}
      onSelectAccount={onSelectAccount}
      accounts={vkAccounts.map(account => ({
        id: account.id,
        name: account.fullName,
        avatar: account.avatar ?? 'https://placebear.com/300/300',
        onSelect: () => {},
        onDelete: () => {
          deleteVkAccount({ vkId: account.id });
        },
      }))}
    />
  );
};

export default VkAccountsSelector;
