import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  useAdminBotControllerDeleteBotMutation,
  useAdminBotControllerGetBotQuery,
  useAdminBotControllerSyncBotMutation,
  useAdminBotControllerToggleBotMutation,
  useAdminBotControllerUpdateBotMutation,
  useAdminBotIdChatsControllerCreateChatMutation,
  useAdminBotIdChatsControllerGetChatListQuery,
} from 'modules/telegram/codegen/telegram.admin.api';
import { useBotId } from 'modules/telegram/telegram.hooks';
import r from 'constants/routes';

import PageHeader from 'components/PageHeader';
import InfoCard from 'modules/telegram/components/InfoCard';
import MobileBotEdit from 'modules/telegram/components/MobileBotEdit';
import Tag from 'components/Tag';
import ChatList from '../../components/ChatList';

import BotEditSidePanel from 'modules/telegram/components/BotEditSidePanel';
import styles from './EditAdminBot.module.scss';
import Command from 'assets/svg/telegram/command.svg';
import Greetings from 'assets/svg/telegram/greetings.svg';
import LoadingState from 'components/LoadingState';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { setBotName } from '../../codegen/telegram.admin.reducer';
import { videoTutorials } from '../../../../constants/videos';
import { isNil } from 'lodash-es';

const TagsPane: FC = () => {
  const botId = useBotId();

  const { data, isLoading, isError } = useAdminBotIdChatsControllerGetChatListQuery({
    botId,
    botAdminChatListParams: {},
  });
  const [deleteChat] = useAdminBotIdChatsControllerCreateChatMutation();

  if (isLoading) return null;

  if (isError) return null;

  return (
    <div className={styles.Tags}>
      {data?.list.map(chat => (
        <Tag
          key={chat.id}
          text={chat.chatName ?? chat.chatId}
          dimmed={!chat.isReachable}
          onClick={() => deleteChat({ botId, chatId: chat.chatId })}
        />
      ))}
    </div>
  );
};

const EditAdminBot: FC = () => {
  const botId = useBotId();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useAdminBotControllerGetBotQuery({ id: botId });
  const [toggleAdminBot] = useAdminBotControllerToggleBotMutation();
  const [syncAdminBot] = useAdminBotControllerSyncBotMutation();
  const [updateAdminBot] = useAdminBotControllerUpdateBotMutation();
  const [deleteBot] = useAdminBotControllerDeleteBotMutation();
  const params = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();

  const handleUpdate = async (args: { id: string; updatedName: { name: string } }) => {
    const result = await updateAdminBot(args);
    return result;
  };

  if (isLoading) return <LoadingState />;

  if (isError) return null;

  return (
    <div className={styles.Page}>
      <div className={styles.Content}>
        <PageHeader
          title={data?.name}
          subtitle="Бот-Администратор"
          videoTutorials={[
            { label: 'Настройка бота администратор', url: videoTutorials.bot_administrator },
            { label: 'Получение токена', url: videoTutorials.token_reception },
            { label: 'Настройка бота', url: videoTutorials.bot_setup },
          ]}
          onIconClick={() => {
            params.delete('chatName');
            navigate(r.telegram.index);
            dispatch(setBotName(''));
          }}
        />
        <div className={styles.Container}>
          <div className={styles.Body}>
            <MobileBotEdit
              isDisableToggle={!isNil(data?.canNotStart)}
              uuid={data?.id ?? ''}
              name={data?.name ?? ''}
              lastTokenSymbol={data?.lastTokenSymbol}
              toggled={data?.status ?? false}
              botInfo={data?.telegram}
              bottom={<TagsPane />}
              onUpdate={handleUpdate}
              onToggle={() => toggleAdminBot({ id: data?.id ?? '' })}
              onSync={() => syncAdminBot({ id: data?.id ?? '' })}
              timezone={data?.timezone}
              onDelete={async () => {
                await deleteBot({ id: data?.id ?? '' });
                navigate(r.telegram.index);
              }}
            />
            <BotEditSidePanel
              isDisableToggle={!isNil(data?.canNotStart)}
              title="Бот-Администратор"
              uuid={data?.id}
              name={data?.name}
              lastTokenSymbol={data?.lastTokenSymbol}
              toggled={data?.status}
              botInfo={data?.telegram}
              timezone={data?.timezone}
              bottom={<TagsPane />}
              onUpdate={handleUpdate}
              onToggle={() => toggleAdminBot({ id: data?.id ?? '' })}
              onSync={() => syncAdminBot({ id: data?.id ?? '' })}
              onDelete={async () => {
                await deleteBot({ id: data?.id ?? '' });
                navigate(r.telegram.index);
              }}
            />
            {/*<InfoCard title="Чаты" icon="chat" count={0} mobile />*/}
            <InfoCard
              title="Команды"
              icon="command"
              backIcon={Command}
              count={data?.countCommands || 0}
              onCardClick={() => {
                navigate(generatePath(r.telegram.commands, { id: botId }));
              }}
            />
            <InfoCard
              title="Приветственные сообщения"
              icon="message"
              backIcon={Greetings}
              count={data?.countMessages || 0}
              onCardClick={() => {
                navigate(generatePath(r.telegram.greetingsMessage, { id: botId }));
              }}
            />
          </div>
        </div>
      </div>
      <ChatList />
    </div>
  );
};

export default EditAdminBot;
