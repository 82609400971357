import { FC } from 'react';

import WithMultipleChoices from 'components/WithMultipleChoices';
import InputWithDropdown from 'components/InputWithDropdown';
import { InputProps } from 'components/Input';

type Props = {
  value: string[];
  setValue: (name: 'stacks', value: string[], options: { shouldValidate: boolean }) => void;
  inputProps?: Omit<InputProps, 'label'>;
};

const StackSelector: FC<Props> = ({ value, setValue, inputProps }) => {
  const stacks = ['Stack 1', 'Stack 2', 'Stack 3'];

  return (
    <WithMultipleChoices
      choices={value}
      deleteChoice={choice =>
        setValue(
          'stacks',
          value.filter(v => v !== choice),
          { shouldValidate: true },
        )
      }
    >
      <InputWithDropdown
        label="Выбор стопки"
        options={stacks.map(stack => ({
          label: stack,
          isSelected: value.includes(stack),
          onClick: () =>
            !value.includes(stack) &&
            setValue('stacks', [...value, stack], { shouldValidate: true }),
        }))}
        variant="dark"
        {...inputProps}
      />
    </WithMultipleChoices>
  );
};

export default StackSelector;
