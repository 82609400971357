import { FC } from 'react';
import Button from 'components/Button';
import styles from './ParsingBtn.module.scss';
import ParsingModal from './ParsingModal';
import useDisclosure from 'hooks/useDisclosure';

const ParsingBtn: FC = () => {
  const { open, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} size={'l'} className={styles.NewParsingBtn}>
        Новый парсинг
      </Button>
      {open && <ParsingModal isOpen={open} onClose={onClose} />}
    </>
  );
};

export default ParsingBtn;
