import { useCallback } from 'react';
import { toBlob } from 'html-to-image';

import { useActivePage } from '../my-pages.selectors';
import {
  useAddPagePrerenderMutation,
  useChangePageComponentsMutation,
  useChangePageParamsMutation,
  useGetPageQuery,
} from 'modules/landing-dashboard/landing-dashboard.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { setShootingPrerender } from '../my-pages.reducer';

function useSavePage(): () => Promise<void> {
  const page = useActivePage();
  const { data } = useGetPageQuery({ id: page?.id as string }, { skip: !page?.id });
  const dispatch = useAppDispatch();
  const [changeParams] = useChangePageParamsMutation();
  const [changeComponents] = useChangePageComponentsMutation();
  const [addPrerender] = useAddPagePrerenderMutation();
  const id = Number(page?.id);
  const sameLink = data?.body.link === page?.link;

  const onSave = useCallback(async () => {
    if (!page) return;
    // update
    await changeParams({
      id,
      name: page?.name,
      link: sameLink ? null : page?.link,
      bgColor: page?.bgColor,
    });

    const result = await changeComponents({ id, components: page });

    if (!('error' in result)) {
      // add prerender
      const previewElement = document.getElementById(`page-preview-${id}`);
      if (previewElement) {
        dispatch(setShootingPrerender(true));
        const imgData = await toBlob(previewElement, {
          cacheBust: true,
          pixelRatio: 1,
        });

        dispatch(setShootingPrerender(false));
        if (imgData) {
          await addPrerender({ id, image: imgData });
        }
      }
    }
  }, [changeParams, changeComponents, addPrerender, dispatch, page]);

  return onSave;
}

export default useSavePage;
