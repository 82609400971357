import { FC } from 'react';

import styles from './ImgInformation.module.scss';
import IconButton from 'components/IconButton';
import classnames from 'classnames';

type Props = {
  name: string;
  size: number;
  index: number;
  deleteImg: (index: number) => void;
  noSizeLimit?: boolean;
};

const ImgInformation: FC<Props> = ({ name, size, index, deleteImg, noSizeLimit }) => {
  const isLimit = size > 2.5;

  return (
    <div
      className={classnames(styles.Information, {
        [styles.MaxLimit]: isLimit && !noSizeLimit,
      })}
    >
      <div className={styles.Left}>
        <p className={styles.Name}>{name}</p>
      </div>
      <div className={styles.Right}>
        <p className={styles.Size}>{size}Mb</p>
        <IconButton
          iconName={isLimit && !noSizeLimit ? 'alert' : 'trash'}
          iconSize={16}
          className={styles.Icon}
          onClick={() => deleteImg(index)}
        />
      </div>
    </div>
  );
};

export default ImgInformation;
