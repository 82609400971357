import styles from './Folders.module.scss';
import FolderCard from '../FolderCard';
import { TFoldersItem } from '../../vk.types';
import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import r from 'constants/routes';

type Props = {
  foldersArr: TFoldersItem[];
  isLoading?: boolean;
};

const Folders: FC<Props> = ({ foldersArr, isLoading }) => {
  const navigate = useNavigate();
  if (isLoading) return <h1>...Loading</h1>;
  return (
    <div className={styles.Root}>
      {foldersArr.map(folder => (
        <FolderCard
          key={folder.id}
          folderName={folder.folderName}
          numberOfGroups={folder.groupCount}
          numberOfUsers={folder.membersCount}
          onClick={() => navigate(generatePath(r.vk.groupsFolder, { id: String(folder.id) }))}
        />
      ))}
    </div>
  );
};

export default Folders;
