import { FC, ReactNode } from 'react';
import Modal, { CommonModalProps, OnSubmitDefaultHandler } from 'components/Modal';
import Icon from 'components/Icon';

import styles from './InfoResetPasswordModal.module.scss';

type Props = {
  children?: ReactNode;
  isLoading: boolean;
  isSuccess: boolean;
  onSubmit: () => void;
} & CommonModalProps;

const InfoSettingsModal: FC<Props> = ({ children, isLoading, isSuccess, onSubmit, ...rest }) => {
  const onSubmitHandler: OnSubmitDefaultHandler = e => {
    e?.preventDefault();
    onSubmit();
  };

  return (
    <Modal onSubmit={onSubmitHandler} noBtn={isLoading} btnText={'Прислать еще'} {...rest}>
      <div className={styles.Modal}>
        <h3 className={styles.Title}>Письмо отправлено</h3>
        <p className={styles.Paragraph}>
          Пожалуйста, проверьте Ваш E-mail. Там должна быть ссылка с новым паролем
        </p>

        {isLoading && (
          <div className={styles.Loading}>
            <Icon className={styles.Icon} iconName="loader" size={24} />
            <p className={styles.loadingPar}>Через 3 мин можно повторить отправку</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default InfoSettingsModal;
