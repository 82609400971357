export const vkAccount = /(https{0,1}:\/\/)?(www\.)?(vk.com\/)(id\d|[a-zA-z][a-zA-Z0-9_.]{2,})/;

export const instagramAccount =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;

export const youtubeVideo =
  /^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/;

export const telegramRegExp = /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/;

export const mobilePhone = /^\+[1-9]{1}[0-9]{3,14}$/;

export const telegramUsername = /^[A-Za-z]{2,}[_-]?[A-Za-z0-9]{2,}$/;

//find
export const whatsapp =
  /^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/;

export const viber =
  /^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/;

export const facebook =
  /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/;

export const userName = /^[A-Za-z0-9]+$/;

export const password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
