import { FC } from 'react';

import styles from './ScenarioCard.module.scss';
import useDisclosure from 'hooks/useDisclosure';
import ToggleSwitchWithLabel from 'components/ToggleSwitchWithLabel';
import Menu from 'components/Menu';
import Icon from 'components/Icon';
import ChangedOrCreated from '../ChangedOrCreated';
import ActivityIndicator from '../ActivityIndicator';

type Props = {
  id: number;
  name: string;
  changed?: string;
  created: string;
};

const ScenarioCard: FC<Props> = ({ name, changed, created }) => {
  const { open: active, toggleOpen: toggleActive } = useDisclosure();

  return (
    <article className={styles.Card}>
      <ActivityIndicator className={styles.Indicator} active={active} />
      <div className={styles.Content}>
        <h4 className={styles.Name}>{name}</h4>
        <ChangedOrCreated className={styles.Time} created={created} changed={changed} />
        <ToggleSwitchWithLabel active={active} toggle={toggleActive} />
      </div>
      <Menu
        className={styles.Menu}
        contextClassName={styles.Context}
        items={[{ label: 'Option', isSelected: false, onClick: () => {} }]}
        iconName="options-vertical"
        iconSize={24}
      >
        <Icon iconName="options-vertical" size={24} />
      </Menu>
    </article>
  );
};

export default ScenarioCard;
