import { FC } from 'react';

import styles from './PartnershipProgramInfo.module.scss';
import PartnershipProgramMobileTabs from 'modules/partnership-program/components/PartnershipProgramMobileTabs';
import ProgramInfo from 'modules/partnership-program/components/ProgramInfo';
import PartnershipHeader from 'modules/partnership-program/components/PartnershipHeader';

const PartnershipProgramInfo: FC = () => {
  return (
    <div className={styles.Page}>
      <PartnershipHeader />
      <ProgramInfo />
      <PartnershipProgramMobileTabs />
    </div>
  );
};

export default PartnershipProgramInfo;
