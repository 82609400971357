import { FC } from 'react';
import styles from './WelcomeBotStatisticsTableHead.module.scss';

export const WelcomeBotStatisticsTableHead: FC = () => {
  return (
    <thead className={styles.Root}>
      <tr className={styles.Inner}>
        <th>Дата и время</th>
        <th>Имя</th>
        <th>Фамилия</th>
        <th>Логин</th>
        <th>Телефон</th>
        <th>Статус</th>
      </tr>
    </thead>
  );
};
