import { FC, FormHTMLAttributes, ReactNode } from 'react';

import styles from './EditorForm.module.scss';

type Props = {
  children: ReactNode;
  hasBack?: boolean;
} & FormHTMLAttributes<HTMLFormElement>;

const EditorForm: FC<Props> = ({ children, hasBack, ...props }) => {
  return (
    <form className={styles.Form} data-has-back={hasBack} {...props}>
      {children}
    </form>
  );
};

export default EditorForm;
