import { FC } from 'react';
import getElementWordByAmount from 'utils/getElementWordByAmount';

import styles from './SelectedPartnersNav.module.scss';

import useAppDispatch from 'hooks/useAppDispatch';
import {
  useSelectedPartners,
  useSelectPartnerFilters,
} from 'modules/partnership-program/partnership-program.selectors';
import {
  clearPartners,
  selectEndDate,
  selectPlan,
  setMailModal,
} from 'modules/partnership-program/partnership-program.reducer';

import Button from 'components/Button';
import Listbox from 'components/Listbox';
import { EPlanName } from 'modules/account/account.interfaces';
import { EndDate } from 'modules/partnership-program/partnership-program.types';
import Icon from 'components/Icon';
import { useGetAllPlansWithPriceQuery } from '../../../tariff/tariff.api';

interface IPlanOptions {
  [key: string]: string;
}

const PlanOptions: IPlanOptions = {
  [EPlanName.trial]: 'Пробный период',
  [EPlanName.default]: 'Без тарифа',
};

const PlanEndOptions = {
  [EndDate.days7]: '7 дней',
  [EndDate.days14]: '14 дней',
  [EndDate.days30plus]: '30 дней',
};

const SelectedPartnersNav: FC = () => {
  const dispatch = useAppDispatch();
  const filters = useSelectPartnerFilters();
  const selectedPartners = useSelectedPartners();
  const { data } = useGetAllPlansWithPriceQuery();

  const mergedOptions = data?.reduce((acc: Record<string, string>, plan) => {
    acc[plan.name] = plan.displayName;
    return acc;
  }, {});

  const combinedOptions: Record<string, string> = {
    ...PlanOptions,
    ...mergedOptions,
    all: 'Все',
  };

  const handleCancel = () => dispatch(clearPartners());

  return (
    <nav className={styles.Selected}>
      {!!selectedPartners.length ? (
        <>
          <div className={styles.Left}>
            <Button
              className={styles.SendMsg}
              variant="primary"
              onClick={() => dispatch(setMailModal(true))}
              disabled={!selectedPartners.length}
            >
              Написать
            </Button>
            <Button className={styles.Cancel} variant="text" onClick={handleCancel}>
              Отменить
            </Button>
          </div>
          <div className={styles.Right}>
            <p className={styles.Count}>
              Выбрано: {selectedPartners.length} {getElementWordByAmount(selectedPartners.length)}
            </p>
          </div>
        </>
      ) : (
        <div className={styles.Filters}>
          <Listbox
            buttonClassName={styles.Button}
            button={
              <>
                <span>{filters.plan ? combinedOptions[filters.plan] : 'Тариф'}</span>
                <Icon iconName="chevron-left" size={12} className={styles.Chevron} />
              </>
            }
            onChange={plan => dispatch(selectPlan(plan === 'all' ? null : (plan as EPlanName)))}
            options={Object.entries(combinedOptions).map(([value, name]) => ({
              name,
              value,
            }))}
            value={filters.plan || undefined}
            rightMargin={'-134px'}
          />
          <Listbox
            buttonClassName={styles.Button}
            button={
              <>
                <span>
                  {filters.endDate ? PlanEndOptions[filters.endDate] : 'Время до окончания'}
                </span>
                <Icon iconName="chevron-left" size={12} className={styles.Chevron} />
              </>
            }
            onChange={endOfPlan =>
              dispatch(selectEndDate(endOfPlan === 'all' ? null : (endOfPlan as EndDate)))
            }
            options={Object.entries(PlanEndOptions).map(([value, name]) => ({ name, value }))}
            value={filters.endDate || undefined}
            rightMargin={'-100px'}
          />
        </div>
      )}
    </nav>
  );
};

export default SelectedPartnersNav;
