import { FC } from 'react';
import styles from './LoadingState.module.scss';
import Spinner from '../Spinner';

const LoadingState: FC = () => {
  return (
    <div className={styles.Root}>
      <Spinner />
    </div>
  );
};

export default LoadingState;
