import { FC, useEffect, useState } from 'react';
import styles from './CountdownTimer.module.scss';
import { useGetCurrentTimerState } from '../../../../vk.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { setTimerInPersist, removeTimerFrom } from '../../../../vk.reducer';
import dayjs from 'dayjs';

type Props = {
  id: number;
  name: string;
};

const CountdownTimer: FC<Props> = ({ name, id }) => {
  const currName = name + id;
  const state = useGetCurrentTimerState();
  const currObj = state[currName];
  const dispatch = useAppDispatch();
  const [nowTime, setNowTime] = useState(new Date());

  function getMinutesDifference(date1: Date, date2: Date) {
    const d1 = dayjs(date1);
    const d2 = dayjs(date2);
    return d1.diff(d2, 'minute');
  }

  function areDatesEqual(date1: Date, date2: Date) {
    const d1 = dayjs(date1);
    const d2 = dayjs(date2);
    return d1.isSame(d2);
  }

  useEffect(() => {
    if (
      areDatesEqual(currObj.expireTime, nowTime) ||
      getMinutesDifference(currObj.expireTime, nowTime) <= 0
    ) {
      dispatch(
        setTimerInPersist({
          [currName]: {
            isRunning: false,
            timeLeft: 0,
            createdTime: currObj.createdTime,
            expireTime: currObj.expireTime,
          },
        }),
      );
      dispatch(removeTimerFrom(currName));
    }
  }, [nowTime]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (currObj.isRunning) {
      timer = setInterval(() => {
        setNowTime(new Date());
      }, 60 * 1000);
    } else {
      // @ts-ignore
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [nowTime]);

  return (
    <div className={styles.Root}>
      <span>{getMinutesDifference(currObj.expireTime, nowTime)} мин.</span>
    </div>
  );
};

export default CountdownTimer;
