import { useCallback } from 'react';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import { UndoRedoProps } from 'components/UndoRedo';
import useAppDispatch from 'hooks/useAppDispatch';
import { useSelectCanUndoRedoPage } from '../my-pages.selectors';

function useMyPagesUndoRedoProps(): UndoRedoProps {
  const dispatch = useAppDispatch();
  const { canUndo, canRedo } = useSelectCanUndoRedoPage();

  const undo = useCallback(() => dispatch(UndoActionCreators.undo()), [dispatch]);
  const redo = useCallback(() => dispatch(UndoActionCreators.redo()), [dispatch]);

  return { canUndo, canRedo, undo, redo };
}

export default useMyPagesUndoRedoProps;
