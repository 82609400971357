import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EPeriodType, LandingDashBoardSliceState } from './landing-dashboard.types';

const initialState: LandingDashBoardSliceState = {
  periodType: EPeriodType.MONTH,
  newCard: false,
  ids: [],
};

const landingDashBoardSlice = createSlice({
  name: 'landingDashBoard',
  initialState,
  reducers: {
    setStatisticsId: (state, action: PayloadAction<LandingDashBoardSliceState['id']>) => {
      state.id = action.payload;
    },
    setFormComponentId: (state, action: PayloadAction<LandingDashBoardSliceState['id']>) => {
      state.formComponentId = action.payload;
    },
    setPeriodType: (state, action: PayloadAction<EPeriodType>) => {
      state.periodType = action.payload;
    },
    toggleNewCardDialog: state => {
      state.newCard = !state.newCard;
    },
    setApplicationsIds: (state, action: PayloadAction<LandingDashBoardSliceState['ids']>) => {
      state.ids = action.payload;
    },
  },
});

export const {
  setStatisticsId,
  setPeriodType,
  toggleNewCardDialog,
  setFormComponentId,
  setApplicationsIds,
} = landingDashBoardSlice.actions;

export default landingDashBoardSlice.reducer;
