import { FC, useRef } from 'react';

import styles from './ColorChanger.module.scss';
// import ColorPicker from 'components/ColorPicker';
import useDisclosure from 'hooks/useDisclosure';
import useClickOutside from 'hooks/useClickOutside';
import { LandingIdType } from 'modules/landing-generator/landing-generator.types';
import { useSelectLanding } from 'modules/landing-generator/landing-generator.selectors';
// import { rgba2string, stringToRgba } from 'utils/colorConverters';
// import useAppDispatch from 'hooks/useAppDispatch';
// import { setLandingColor } from 'modules/landing-generator/landing-generator.reducer';

type Props = {
  id: LandingIdType;
};

const ColorChanger: FC<Props> = ({ id }) => {
  // const dispatch = useAppDispatch();
  const landing = useSelectLanding(id);

  const ref = useRef<HTMLDivElement>(null);
  const { open, toggleOpen, onClose } = useDisclosure();
  useClickOutside(ref, onClose);

  if (!landing) return null;

  return (
    <div className={styles.Wrapper} ref={ref}>
      <div
        className={styles.Element}
        style={{ backgroundColor: landing.color }}
        onClick={toggleOpen}
      />

      {open && (
        <div className={styles.Picker}>
          {/* <ColorPicker
            color={stringToRgba(landing.color)}
            setColor={color => dispatch(setLandingColor({ id, color: rgba2string(color) }))}
          /> */}
        </div>
      )}
    </div>
  );
};

export default ColorChanger;
