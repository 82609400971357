import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './EditGreetingBot.module.scss';

import GoBack from 'components/GoBack';
import BotEditSidePanel from 'modules/telegram/components/BotEditSidePanel';
import IconButton from 'components/IconButton';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import EditAdd from 'modules/telegram/components/EditAdd';
// import TransactionsTable from 'modules/finances/components/TransactionsTable';
import GreetingBotMessagges from './components/GreetingBotMessages';
import BotCard from 'components/BotCard';
import {
  useWelcomeBotControllerDeleteBotMutation,
  useWelcomeBotControllerGetBotQuery,
  useWelcomeBotControllerToggleBotMutation,
} from 'modules/telegram/codegen/telegram.api';
import r from 'constants/routes';
import GreetingBotUsers from './components/GreetingBotUsers';
import GreetingBotClientsTable from './components/GreetingBotClientsTable';

const EditGreetingBot: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { copyAndNotify } = useCopyToClipboard();
  const { data, isLoading, isError } = useWelcomeBotControllerGetBotQuery({
    account: id as string,
  });
  const [toggleWelcomeBot] = useWelcomeBotControllerToggleBotMutation();
  const [deleteBot] = useWelcomeBotControllerDeleteBotMutation();

  if (isLoading) return null;
  if (isError) return null;
  return (
    <div className={styles.Page}>
      <div className={styles.Content}>
        <GoBack />
        <div className={styles.Body}>
          <section className={styles.Header}>
            <EditAdd name="добавить приветствие" />
          </section>
          <section className={styles.Inner}>
            <h2 className={styles.Title}>Сообщения</h2>
            <GreetingBotMessagges />
            <h2 className={styles.Title}>Клиенты</h2>
            <GreetingBotClientsTable />
          </section>
        </div>
      </div>
      <BotEditSidePanel
        title="Бот приветствие"
        name={data?.account}
        toggled={data?.status}
        timezone={data?.timezone}
        onToggle={async () => {
          if (data?.account)
            await toggleWelcomeBot({ toggleBotWelcomePayload: { account: data.account } }).unwrap();
        }}
        onDelete={async () => {
          if (data?.account) await deleteBot({ account: data.account }).unwrap();
          navigate(r.telegram.greetingBot);
        }}
        data={
          <BotCard.DataEntryLong
            iconName="link"
            name="URL Stars"
            value={
              <div className={styles.Link}>
                <span className={styles.Text}>stars-community.com/sign-up?ref=z1</span>
                <IconButton
                  iconName="copy"
                  iconSize={22}
                  onClick={() => copyAndNotify('stars-community.com/sign-up?ref=z1')}
                />
              </div>
            }
          />
        }
        bottom={<GreetingBotUsers />}
      />
    </div>
  );
};

export default EditGreetingBot;
