import { FC } from 'react';
import dayjs from 'dayjs';

import styles from './TransactionCard.module.scss';
import { ETransactionType, UserTransaction } from 'modules/finances/finances.types';
import TransactionType from '../TransactionType';
import { TRANSACTION_TYPES_NAMES } from 'modules/finances/finances.constants';
import env from 'config/env';
import { transactionTypesMobile } from 'modules/finances/finances.helper';
import { TransactionStatuses } from '../TransactionsTable/components/TransactionStatuses';

const TransactionCard: FC<UserTransaction> = ({ type, createdAt, amount, status }) => {
  return (
    <article className={styles.Card}>
      <div className={styles.Left}>
        <div className={styles.Type}>
          <TransactionType type={transactionTypesMobile(type) as ETransactionType} />
        </div>
        <div className={styles.Info}>
          <div className={styles.Title}>
            {TRANSACTION_TYPES_NAMES[transactionTypesMobile(type) as ETransactionType]}
          </div>
          <time className={styles.Time}>{dayjs(createdAt).format('DD.MM.YYYY HH.mm')}</time>
        </div>
      </div>
      <div className={styles.Info}>
        <div className={styles.Title}>
          {amount} {env.CURRENCY}
        </div>
        {TransactionStatuses[status]?.elem}
      </div>
    </article>
  );
};

export default TransactionCard;
