import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import styles from './View.module.scss';
import Nav from '../Nav';
import Landing from '../Landing';
import Button from 'components/Button';
import { LandingIdType } from 'modules/landing-generator/landing-generator.types';
import r from 'constants/routes';
import GoBack from 'components/GoBack';

type Props = {
  id: LandingIdType;
};

const View: FC<Props> = ({ id }) => {
  const navigate = useNavigate();

  return (
    <section className={styles.Section}>
      <GoBack />

      <div className={styles.Wrapper}>
        <Nav id={id} />
        <Landing id={id} />
        <Button
          onClick={() => navigate(generatePath(r.landingGenerator.publish, { id: id.toString() }))}
          className={styles.Btn}
        >
          Закончить редактирование
        </Button>
      </div>
    </section>
  );
};

export default View;
