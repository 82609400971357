import { MouseEvent } from 'react';
import { addComponent } from 'modules/my-pages/my-pages.reducer';
import { AppDispatch } from 'config/store';
import { COMPONENTS_NAMES, SOCIAL_NETWORKS_NAMES, TEXT_STYLES_NAMES } from './my-pages.constants';
import {
  createBlock,
  createButton,
  createEmail,
  createForm,
  createImage,
  createPhone,
  createText,
  createYouTubeVideo,
  createSocialNetwork,
  createComponent,
} from './my-pages.creators';
import {
  AnyComponent,
  EComponentType,
  SocialNetwork,
  YouTubeVideo,
  Text,
  Button,
  Email,
  Image,
  Phone,
  EDraggableItem,
  ESocialNetwork,
  EFormField,
} from './my-pages.types';
import { FormSendRequestForm } from 'modules/landing-dashboard/landing-dashboard.types';
import * as Yup from 'yup';

export const getComponentNameAndValue = (
  component: AnyComponent,
  forSettings?: boolean,
): { title: string; value: string } => {
  switch (component.type) {
    case EComponentType.BLOCK:
      return {
        title: COMPONENTS_NAMES[EComponentType.BLOCK],
        value: '-',
      };
    case EComponentType.BUTTON:
      return {
        title: COMPONENTS_NAMES[EComponentType.BUTTON],
        value: (component.data as Button).text ?? '-',
      };
    case EComponentType.EMAIL:
      return {
        title: COMPONENTS_NAMES[EComponentType.EMAIL],
        value: (component.data as Email).email ?? '-',
      };
    case EComponentType.FORM:
      return {
        title: COMPONENTS_NAMES[EComponentType.FORM],
        value: COMPONENTS_NAMES[EComponentType.FORM],
      };
    case EComponentType.IMAGE:
      return {
        title: COMPONENTS_NAMES[EComponentType.IMAGE],
        value: (component.data as Image).link ?? '',
      };
    case EComponentType.PHONE:
      return {
        title: COMPONENTS_NAMES[EComponentType.PHONE],
        value: (component.data as Phone).phone ?? '-',
      };
    case EComponentType.SOCIAL_NETWORK:
      return {
        title: forSettings
          ? SOCIAL_NETWORKS_NAMES[(component.data as SocialNetwork).type]
          : COMPONENTS_NAMES[EComponentType.SOCIAL_NETWORK],
        value: SOCIAL_NETWORKS_NAMES[(component.data as SocialNetwork).type],
      };
    case EComponentType.TEXT:
      return {
        title: forSettings
          ? COMPONENTS_NAMES[EComponentType.TEXT]
          : TEXT_STYLES_NAMES[(component.data as Text).textStyle],
        value: (component.data as Text).text ?? '-',
      };
    case EComponentType.YOUTUBE_VIDEO:
      return {
        title: COMPONENTS_NAMES[EComponentType.YOUTUBE_VIDEO],
        value: (component.data as YouTubeVideo).link ?? '-',
      };
    default:
      return { title: 'Undefined', value: 'component' };
  }
};

export const appendComponent = (
  type: EDraggableItem,
  dispatch: AppDispatch,
  order: number,
  parentId: string,
) => {
  let data: AnyComponent['data'] | undefined;
  let cType: EComponentType | undefined;

  switch (type) {
    case EDraggableItem.BLOCK:
      data = createBlock();
      cType = EComponentType.BLOCK;
      break;
    case EDraggableItem.BUTTON:
      data = createButton();
      cType = EComponentType.BUTTON;
      break;
    case EDraggableItem.EMAIL:
      data = createEmail();
      cType = EComponentType.EMAIL;
      break;
    case EDraggableItem.PHONE:
      data = createPhone();
      cType = EComponentType.PHONE;
      break;
    case EDraggableItem.FORM:
      data = createForm();
      cType = EComponentType.FORM;
      break;
    case EDraggableItem.IMAGE:
      data = createImage();
      cType = EComponentType.IMAGE;
      break;
    case EDraggableItem.YOUTUBE_VIDEO:
      data = createYouTubeVideo();
      cType = EComponentType.YOUTUBE_VIDEO;
      break;
    case EDraggableItem.TEXT:
      data = createText();
      cType = EComponentType.TEXT;
      break;
    case EDraggableItem.INSTAGRAM:
      data = createSocialNetwork(ESocialNetwork.INSTAGRAM);
      cType = EComponentType.SOCIAL_NETWORK;
      break;
    case EDraggableItem.TELEGRAM:
      data = createSocialNetwork(ESocialNetwork.TELEGRAM);
      cType = EComponentType.SOCIAL_NETWORK;
      break;
    case EDraggableItem.VIBER:
      data = createSocialNetwork(ESocialNetwork.VIBER);
      cType = EComponentType.SOCIAL_NETWORK;
      break;
    case EDraggableItem.VK:
      data = createSocialNetwork(ESocialNetwork.VK);
      cType = EComponentType.SOCIAL_NETWORK;
      break;
    case EDraggableItem.WHATSAPP:
      data = createSocialNetwork(ESocialNetwork.WHATSAPP);
      cType = EComponentType.SOCIAL_NETWORK;
      break;
  }

  if (data && cType) {
    dispatch(
      addComponent({
        parentId,
        component: createComponent(data, cType, order),
      }),
    );
  }
};

export const FormFieldMapper: Record<EFormField, FormSendRequestForm> = {
  [EFormField.DATE]: 'created',
  [EFormField.FIRST_NAME]: 'firstName',
  [EFormField.LAST_NAME]: 'lastName',
  [EFormField.EMAIL]: 'email',
  [EFormField.PHONE]: 'phone',
  [EFormField.COUNTRY]: 'country',
  [EFormField.POSITION]: 'position',
  [EFormField.OTHER]: 'other',
};

export const validateInputWithoutForm = async (value: string) => {
  const validationSchema = Yup.string().matches(
    /^[0-9a-zA-Z]*$/,
    'Допустимо использовать только 0-9, a-z, A-Z',
  );
  return validationSchema
    .validate(value)
    .then(() => undefined)
    .catch(err => err.message);
};

export const handleOpenSettingModal = (e: MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};
