import { FC } from 'react';
import { SlicedTextTypes } from './SlicedText.types';

export const SlicedText: FC<SlicedTextTypes> = ({ text, className }) => {
  return (
    <span className={className} title={text}>
      {text}
    </span>
  );
};
