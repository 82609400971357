import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Title.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
  noMargin?: boolean;
};

const Title: FC<Props> = ({ noMargin, children, className }) => {
  return (
    <h1 className={classNames(styles.Title, className)} data-no-margin={noMargin}>
      {children}
    </h1>
  );
};

export default Title;
