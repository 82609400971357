import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { addBotSchema } from 'modules/telegram/telegram.schema';
import { AddBotRequestBody } from 'modules/telegram/telegram.types';
import { CommonModalProps } from 'components/Modal';

import Input from 'components/Input';
import ModalWithForm from 'components/ModalWithForm';

import styles from './AddBotModal.module.scss';
import { getTimeZones } from 'utils/getTimeZones';

type TAddBotModal = {
  editPath: string;
} & CommonModalProps<(data: { token: string; name: string; timezone: string }) => Promise<void>>;

const AddBotModal: FC<TAddBotModal> = ({ editPath, onSubmit, ...props }) => {
  const makeTimezoneOptions = useMemo(() => {
    return getTimeZones('RU');
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
  } = useForm<AddBotRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(addBotSchema()),
  });

  const onFormSubmit = handleSubmit(async data => {
    await Promise.resolve(onSubmit?.(data));
    reset();
  });

  return (
    <ModalWithForm
      className={styles.AddBotForm}
      title="Добавление бота"
      text="Заполните все поля для того, чтобы добавить бота."
      onSubmit={onFormSubmit}
      isValid={isValid}
      formClassName={styles.ModalForm}
      {...props}
    >
      <Input
        label="Название бота:"
        register={register('name')}
        invalid={!!errors.name?.message}
        errorMsg={errors.name?.message}
        maxLength={30}
        wrapperClassName={styles.AddBotInput}
      />
      <Input
        label="Токен бота:"
        register={register('token')}
        invalid={!!errors.token?.message}
        errorMsg={errors.token?.message}
        wrapperClassName={styles.AddBotInput}
      />
      <Link to={'#'} className={styles.Link}>
        Не знаете что это?
      </Link>
      <div className={styles.SelectWrapper}>
        <p className={styles.SelectInfo}>Часовой пояс:</p>
        <Controller
          control={control}
          name="timezone"
          defaultValue="Europe/Moscow"
          render={({ field }) => (
            <Select
              className={styles.Select}
              classNamePrefix={styles.SelectContainer}
              defaultValue={
                field.value
                  ? { label: field.value, value: field.value }
                  : { label: 'Europe/Moscow', value: 'Europe/Moscow' }
              }
              onChange={selectedOption => field.onChange(selectedOption?.value)}
              options={makeTimezoneOptions}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  borderColor: 'var(--m-2-outline, #2c2c35)',
                  backgroundColor: 'var(--color-modal-bg)',
                  paddingTop: '25px',
                  paddingLeft: '5px',
                  borderRadius: '12px',
                  '&:hover': {
                    border: '1px solid var(--color-m4)',
                    transition: 'border-color var(--animation-transition)',
                  },
                }),
                menu: baseStyles => ({
                  ...baseStyles,
                  backgroundColor: 'var(--color-modal-bg)',
                }),
                input: baseStyles => ({
                  ...baseStyles,
                  color: '#D0D0D0',
                  fontSize: '16px',
                }),
                singleValue: baseStyles => ({
                  ...baseStyles,
                  color: '#D0D0D0',
                  fontSize: '16px',
                }),
                option: (baseStyles, { isFocused, isSelected }) => ({
                  ...baseStyles,
                  backgroundColor: isSelected
                    ? 'var(--color-modal-bg)'
                    : isFocused
                    ? '#2C2C35'
                    : 'transparent',
                  '&:active': {
                    backgroundColor: 'var(--color-modal-bg)',
                  },
                }),

                menuList: base => ({
                  ...base,

                  '::-webkit-scrollbar': {
                    width: '4px',
                    height: '1px',
                  },
                  '::-webkit-scrollbar-track': {
                    background: 'rgba(255, 255, 255, 0.1)',
                    opacity: 0.5,
                  },
                  '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#fff',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    borderRadius: '4px',
                  },
                  '::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                  },
                }),
              }}
            />
          )}
        />
      </div>
    </ModalWithForm>
  );
};

export default AddBotModal;
