import { FC } from 'react';

import GreetingModalEntry from 'modules/telegram/components/GreetingModalEntry';

const GreetingBotUsers: FC = () => {
  return (
    <>
      <GreetingModalEntry name="Larisaiztelegi" value="Stars" />
      <GreetingModalEntry name="Larisaiztelegi" value="Stars" />
      <GreetingModalEntry name="Larisaiztelegi" value="Stars" />
      <GreetingModalEntry name="Larisaiztelegi" value="Stars" />
      <GreetingModalEntry name="Larisaiztelegi" value="Stars" />
      <GreetingModalEntry name="Larisaiztelegi" value="Stars" />
      <GreetingModalEntry name="Larisaiztelegi" value="Stars" />
    </>
  );
};

export default GreetingBotUsers;
