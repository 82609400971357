import { FC } from 'react';

import styles from './PhotoEditor.module.scss';
import ImgInput from 'components/ImgInput';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  EShape,
  LandingIdType,
  LandingPhoto,
} from 'modules/landing-generator/landing-generator.types';
import { setLandingPhoto } from 'modules/landing-generator/landing-generator.reducer';
import { useSelectLanding } from 'modules/landing-generator/landing-generator.selectors';

type Props = {
  id: LandingIdType;
};

const PhotoEditor: FC<Props> = ({ id }) => {
  const landing = useSelectLanding(id);
  const dispatch = useAppDispatch();

  const setImage = (file?: File) => {
    if (file) {
      const photo: LandingPhoto = {
        url: URL.createObjectURL(file),
        shape: landing?.photo?.shape ?? EShape.CIRCLE,
      };
      dispatch(setLandingPhoto({ id, photo }));
    }
  };

  return (
    <div className={styles.Wrapper}>
      <ImgInput setFile={setImage} />
    </div>
  );
};

export default PhotoEditor;
