import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import styles from './ControlButton.module.scss';
import Icon, { IIcon } from 'components/Icon';

type Props = {
  icon: IIcon;
  size: number;
  text?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ControlButton: FC<Props> = ({ icon, size = 28, text, ...btnProps }) => {
  return (
    <button
      className={styles.Btn}
      style={{ height: size, width: text ? undefined : size }}
      data-has-text={!!text}
      {...btnProps}
    >
      <Icon className={styles.Icon} {...icon} />
      {text && <span className={styles.Text}>{text}</span>}
    </button>
  );
};

export default ControlButton;
