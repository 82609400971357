import Editor from '../Editor';
import Preview from '../Preview';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { setActivePage, setOpenGoBackModal } from '../../my-pages.reducer';
import { useGetPageQuery } from '../../../landing-dashboard/landing-dashboard.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { ActionCreators } from 'redux-undo';
import { useActivePage, useOpenSettingsModal } from '../../my-pages.selectors';
import Settings from '../../components/Settings';
import BottomSheet from 'components/BottomSheet';
import LoadingState from 'components/LoadingState';

export const PagesWrapper = () => {
  const { id } = useParams();
  const { data, isError, isLoading } = useGetPageQuery({ id: id as string });
  const dispatch = useAppDispatch();
  const location = useLocation();
  const page = useActivePage();
  const openSettingModal = useOpenSettingsModal();

  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    if (data?.body) {
      dispatch(setActivePage(data.body));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(ActionCreators.clearHistory());
      dispatch(setOpenGoBackModal(true));
    };
  }, []);

  if (isError) return <h1>Loading...</h1>;
  if (isLoading || !page) return <LoadingState />;
  return (
    <>
      {lastSegment === 'preview' && <Preview />}
      {lastSegment === 'editor' && <Editor page={page} />}
      <BottomSheet type={'settings'} isOpen={openSettingModal}>
        <Settings pageSettings />
      </BottomSheet>
    </>
  );
};
