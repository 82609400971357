import { ETransactionType } from 'modules/finances/finances.types';
import styles from '../TransactionsTable.module.scss';
import TransactionType from 'modules/finances/components/TransactionType';

export const TransactionTypes = {
  [ETransactionType.INPUT]: {
    name: 'DEPOSIT_PAYEER',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.INPUT} />
        <span className={styles.Text}>Пополнение PAYEER</span>
      </div>
    ),
  },
  [ETransactionType.OUTPUT]: {
    name: 'WITHDRAWAL_PAYEER',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.OUTPUT} />
        <span className={styles.Text}>Вывод PAYEER</span>
      </div>
    ),
  },
  [ETransactionType.INPUT_FREE_KASSA]: {
    name: 'DEPOSIT_FREE_KASSA',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.INPUT} />
        <span className={styles.Text}>Пополнение FREE KASSA</span>
      </div>
    ),
  },
  [ETransactionType.OUTPUT_FREE_KASSA]: {
    name: 'WITHDRAWAL_FREE_KASSA',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.OUTPUT} />
        <span className={styles.Text}>Вывод FREE KASSA</span>
      </div>
    ),
  },
  [ETransactionType.PURCHASE]: {
    name: 'PLAN_PURCHASE',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.PURCHASE} />
        <span className={styles.Text}>Покупка плана</span>
      </div>
    ),
  },
  [ETransactionType.PAYOUTS]: {
    name: 'REFERRAL_DEPOSIT',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.PAYOUTS} />
        <span className={styles.Text}>Реферальные начисления</span>
      </div>
    ),
  },
  [ETransactionType.MODIFICATION]: {
    name: 'ADMIN_BALANCE_MODIFICATION',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.MODIFICATION} />
        <span className={styles.Text}>Изменение баланса администратором</span>
      </div>
    ),
  },
  [ETransactionType.DEPOSIT_TAX]: {
    name: 'DEPOSIT_TAX',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.DEPOSIT_TAX} />
        <span className={styles.Text}>Комиссия на пополнение баланса</span>
      </div>
    ),
  },
  [ETransactionType.WITHDRAWAL_TAX]: {
    name: 'WITHDRAWAL_TAX',
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.WITHDRAWAL_TAX} />
        <span className={styles.Text}>Комиссия на вывод средств</span>
      </div>
    ),
  },
  [ETransactionType.DEPOSIT_SOFTPAY]: {
    name: ETransactionType.DEPOSIT_SOFTPAY,
    elem: (
      <div className={styles.Entry}>
        <TransactionType type={ETransactionType.DEPOSIT_SOFTPAY} />
        <span className={styles.Text}>Пополнение баланса Softpay</span>
      </div>
    ),
  },
};
