import { FC } from 'react';

import styles from './AddFieldDropdown.module.scss';
import DropdownTemplate from 'components/DropdownTemplate';
import AddFieldButton from '../AddFieldButton';
import { LandingFormFormFields } from 'modules/landing-generator/landing-generator.types';
import { fieldsNamedEntries } from 'modules/landing-generator/landing-generator.getters';

type Props = {
  append: (name: LandingFormFormFields) => void;
};

const AddFieldDropdown: FC<Props> = ({ append }) => {
  return (
    <DropdownTemplate
      button={{
        content: () => <AddFieldButton append={() => {}}>Добавить поля</AddFieldButton>,
        className: styles.Btn,
      }}
      menu={{
        options: fieldsNamedEntries.map(([key, value]) => ({
          isSelected: key === 'other',
          displayValue: value,
          onClick: () => append(key),
        })),
        className: styles.Menu,
      }}
    />
  );
};

export default AddFieldDropdown;
