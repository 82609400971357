import { FC, useContext } from 'react';
import { Reorder, useDragControls } from 'framer-motion';
import useAppDispatch from 'hooks/useAppDispatch';

import Photo from './Photo';
import Video from './Video';
// import Text from './Text';
import Link from './Link';
import Form from './Form';
import DependentText from './DependentText';

import { LandingElement, LandingIdType } from 'modules/landing-generator/landing-generator.types';
import { updateLandingText } from 'modules/landing-generator/landing-generator.reducer';
import { DragControlsContext } from 'modules/landing-generator/landing-generator.contexts';

type Props = {
  elem: LandingElement;
  id: LandingIdType;
};
const RenderElementInner: FC<Props> = ({ elem, id }) => {
  const controls = useContext(DragControlsContext);
  const dispatch = useAppDispatch();

  switch (elem.type) {
    case 'title':
    case 'subtitle':
    case 'text':
      return (
        <div onPointerDown={e => controls.start(e)}>
          <DependentText
            type={elem.type}
            saveText={value =>
              dispatch(updateLandingText({ id, type: elem.type, text: value, index: elem.index }))
            }
            {...elem.value}
          />
        </div>
      );
    case 'photo':
      return (
        <div onPointerDown={e => controls.start(e)}>
          <Photo id={id} photo={elem.value} />
        </div>
      );
    case 'video':
      return (
        <div onPointerDown={e => controls.start(e)}>
          <Video id={id} video={elem.value} />
        </div>
      );
    case 'form':
      return (
        <div onPointerDown={e => controls.start(e)}>
          <Form id={id} form={elem.value} />
        </div>
      );
    case 'button':
      return (
        <div onPointerDown={e => controls.start(e)}>
          <Link id={id} button={elem.value} />
        </div>
      );
    default:
      return null;
  }
};

const RenderElement: FC<Props> = ({ elem, id }) => {
  const controls = useDragControls();

  return (
    <DragControlsContext.Provider value={controls}>
      <Reorder.Item
        value={elem}
        whileDrag={{ scale: 1.1 }}
        dragListener={false}
        dragControls={controls}
      >
        <RenderElementInner id={id} elem={elem} />
      </Reorder.Item>
    </DragControlsContext.Provider>
  );
};

export default RenderElement;
