import { FC } from 'react';
import classNames from 'classnames';

import styles from './InputWithDropdown.module.scss';
import Input, { InputProps } from 'components/Input';
import Menu, { MenuItem } from 'components/Menu';

type Props = {
  options: MenuItem[];
  menuClassName?: string;
  openingType?: 'up' | 'down';
} & InputProps;

const InputWithDropdown: FC<Props> = ({ options, menuClassName, openingType, ...inputProps }) => {
  return (
    <Menu
      btnClassName={styles.Wrapper}
      contextClassName={classNames(styles.Menu, menuClassName, {
        [styles.OpensUp]: openingType === 'up',
      })}
      items={options}
    >
      <Input icons={[{ name: 'arrow-down' }]} {...inputProps} readOnly />
    </Menu>
  );
};

export default InputWithDropdown;
