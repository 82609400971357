import { FC, ReactNode } from 'react';

import styles from './ModalOverlay.module.scss';

type Props = {
  children: ReactNode;
  transparentBg?: boolean;
};

const ModalOverlay: FC<Props> = ({ children, transparentBg }) => {
  const background = transparentBg ? 'none' : 'rgba(0, 0, 0, 0.7)';

  return (
    <div className={styles.Background} style={{ background: background }}>
      {children}
    </div>
  );
};

export default ModalOverlay;
