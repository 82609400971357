import { FC } from 'react';
import CommandsTableRow from '../../../components/CommandsTable/CommandsTableBody/CommandsTableRow';
import useDisclosure from 'hooks/useDisclosure';
import {
  useRemoveAdminMessageByIdMutation,
  useToggleAdminMessageByIdMutation,
} from '../../../codegen/telegram.admin.api';
import DeleteModal from '../../../components/DeleteModal';
import { TAdminMessages } from '../../../telegram.types';
import { useBotId } from '../../../telegram.hooks';
import useAppDispatch from 'hooks/useAppDispatch';
import GreetingMessageModal from '../../../components/GreetingMessageModal';
import { setToggledMessage } from '../../../codegen/telegram.admin.reducer';

type Props = {
  isOneStatusToggled: boolean;
} & TAdminMessages;

const GreetingMessageRow: FC<Props> = props => {
  const { isOneStatusToggled, ...rest } = props;
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });
  const [deleteMessage, { isLoading }] = useRemoveAdminMessageByIdMutation();
  const [toggleMessage, { isLoading: isToggleLoading }] = useToggleAdminMessageByIdMutation();
  const botId = useBotId();
  const selectedMap = new Map<string, TAdminMessages>();
  selectedMap.set(rest.id, rest);
  const dispatch = useAppDispatch();

  const {
    open: openDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure({ blockScroll: true });

  if (rest.status) return null;
  return (
    <>
      <CommandsTableRow
        key={rest.id}
        noSlash
        description={''}
        files={rest.files}
        name={rest.text}
        isChecked={rest.isChecked}
        text={rest.text}
        onDeleteBtn={onOpenDelete}
        toggleStatus={rest.status}
        onSettingBtnClick={onOpen}
        noToggle={isOneStatusToggled}
        onToggleBtn={() => {
          toggleMessage({ botId, messageId: rest.id }).then(() => {
            const obj = Object.fromEntries(selectedMap);
            dispatch(setToggledMessage(obj));
          });
        }}
        isToggleLoading={isToggleLoading}
        noCheckbox
      />
      {openDelete && (
        <DeleteModal
          title={'Удаление'}
          question={'Вы уверены что хотите удалить?'}
          info={'Все настройки сообщений и рассылок будут удалены!'}
          applyBtnText={'Удалить'}
          onClose={onCloseDelete}
          deleteAction={() =>
            deleteMessage({ botId, messageId: rest.id }).then(() => onCloseDelete())
          }
          isOpen={openDelete}
          isLoading={isLoading}
        />
      )}
      {open && <GreetingMessageModal isOpen={open} onClose={onClose} messageId={rest.id} />}
    </>
  );
};

export default GreetingMessageRow;
