import { FC } from 'react';
import classNames from 'classnames';

import styles from './SearchField.module.scss';
import Icon from 'components/Icon';
import useSearchField, { SearchFieldProps } from 'hooks/useSearchField';

type Props = {
  placeholder: string;
  className?: string;
} & SearchFieldProps;

const SearchField: FC<Props> = ({ placeholder, className, ...searchFieldProps }) => {
  const { updateSearchQuery, setSearchQuery, ...inputProps } = useSearchField(searchFieldProps);

  return (
    <div className={classNames(styles.Wrapper, className)}>
      <Icon className={styles.Icon} iconName="search" size={20} />
      <input className={styles.Input} placeholder={placeholder} {...inputProps} />
    </div>
  );
};

export default SearchField;
