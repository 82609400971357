import { ButtonHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';
import isUndefined from 'lodash-es/isUndefined';

import styles from './IconButton.module.scss';
import Icon from 'components/Icon';

export type IconButtonProps = {
  iconName: string;
  iconSize: number;
  size?: number;
  text?: string;
  className?: string;
  iconClassName?: string;
  styleStroke?: boolean;
  asCircle?: boolean;
  withBorder?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      iconName,
      iconSize,
      size,
      text,
      iconClassName,
      className,
      styleStroke,
      asCircle,
      withBorder,
      ...btnProps
    },
    ref,
  ) => {
    return (
      <button
        className={classNames(styles.IconButton, className)}
        type="button"
        data-clickable={!isUndefined(btnProps.onClick)}
        style={size ? { minWidth: size, height: size } : undefined}
        ref={ref}
        data-as-circle={asCircle}
        data-with-border={withBorder}
        {...btnProps}
      >
        <Icon
          className={classNames(iconClassName, styles.IconButtonIcon, {
            [styles.StyleStroke]: styleStroke,
          })}
          iconName={iconName}
          size={iconSize}
        />
        {text && <p className={styles.IconButtonText}>{text}</p>}
      </button>
    );
  },
);

export default IconButton;
