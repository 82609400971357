import { FC } from 'react';

import env from 'config/env';
import r from 'constants/routes';
import styles from './Balance.module.scss';
import Icon from 'components/Icon';
import { useSelectUser } from 'modules/account/account.selector';
import Card from '../Card';
import formatNumber from 'utils/formatNumber';
import ButtonWithArrow from '../ButtonWithArrow';

const BalanceCard: FC = () => {
  const user = useSelectUser();
  return (
    <Card className={styles.Card} link={r.finances.index}>
      <div className={styles.Balance}>
        <span className={styles.Title}>{formatNumber(Number(user?.balance), 0)}</span>
        <span className={styles.Currency}>{env.CURRENCY}</span>
      </div>
      <span className={styles.Text}>Основной баланс</span>
      <div className={styles.Link}>
        <ButtonWithArrow>
          Перейти в финансы
          <Icon iconName="arrow-right" size={12} />
        </ButtonWithArrow>
      </div>
    </Card>
  );
};

export default BalanceCard;
