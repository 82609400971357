import { ETransactionStatus, ETransactionType } from './finances.types';

export const TRANSACTION_TYPES_NAMES: Record<ETransactionType, string> = {
  [ETransactionType.INPUT]: 'Пополнение PAYEER',
  [ETransactionType.OUTPUT]: 'Вывод PAYEER',
  [ETransactionType.PURCHASE]: 'Покупка плана',
  [ETransactionType.PAYOUTS]: 'Реферальные начисления',
  [ETransactionType.INPUT_FREE_KASSA]: 'Пополнение FREE KASSA',
  [ETransactionType.OUTPUT_FREE_KASSA]: 'Вывод FREE KASSA',
  [ETransactionType.MODIFICATION]: 'Изменение баланса администратором',
  [ETransactionType.DEPOSIT_TAX]: 'Комиссия на пополнение баланса',
  [ETransactionType.WITHDRAWAL_TAX]: 'Комиссия на вывод средств',
  [ETransactionType.DEPOSIT_SOFTPAY]: 'Пополнение баланса Softpay',
};

export const TRANSACTION_STATUSES_MAP: Record<ETransactionStatus, string> = {
  [ETransactionStatus.COMPLETED]: 'Выполнено',
  [ETransactionStatus.IN_PROCESS]: 'В обработке',
  [ETransactionStatus.FAILED]: 'Ошибка',
  [ETransactionStatus.ON_VERIFICATION]: 'На верификации',
  [ETransactionStatus.CANCELLED]: 'Отклонено',
};

export const ICONS_MAP: Record<ETransactionType, string> = {
  [ETransactionType.INPUT]: 'icon-arrow-right',
  [ETransactionType.PAYOUTS]: 'referal-icon',
  [ETransactionType.OUTPUT]: 'icon-arrow-right',
  [ETransactionType.PURCHASE]: 'hexagonal',
  [ETransactionType.INPUT_FREE_KASSA]: 'icon-arrow-right',
  [ETransactionType.OUTPUT_FREE_KASSA]: 'icon-arrow-right',
  [ETransactionType.MODIFICATION]: 'hexagonal',
  [ETransactionType.DEPOSIT_TAX]: 'hexagonal',
  [ETransactionType.WITHDRAWAL_TAX]: 'hexagonal',
  [ETransactionType.DEPOSIT_SOFTPAY]: 'icon-arrow-right',
};
