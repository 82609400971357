import { FC } from 'react';
import isUndefined from 'lodash-es/isUndefined';
import { UseFormRegisterReturn } from 'react-hook-form';

import styles from './MaskInput.module.scss';
import FieldError from 'components/FieldError';

type Props = {
  register: UseFormRegisterReturn;
  placeholder?: string;
  prefix?: string;
  postfix?: string;
  invalid?: boolean;
  errorMsg?: string;
};

const MaskInput: FC<Props> = ({ register, prefix, postfix, invalid, errorMsg, placeholder }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Input}>
        {!isUndefined(prefix) && <div className={styles.Prefix}>{prefix}</div>}
        <input
          className={styles.Field}
          placeholder={placeholder}
          data-invalid={invalid}
          {...register}
        />
        {!isUndefined(postfix) && <div className={styles.Postfix}>{postfix}</div>}
      </div>

      {invalid && errorMsg && <FieldError errorMsg={errorMsg} />}
    </div>
  );
};

export default MaskInput;
