import { FC } from 'react';

import styles from './StatSections.module.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ArrowRight } from './components/ArrowRight';

type BlockType = {
  text: string;
  num: number;
};

type Props = {
  title: string | number;
  block: BlockType[];
  type?: string;
};

const StatSections: FC<Props> = ({ title, block, type }) => {
  return (
    <section className={styles.StatOn}>
      <h2 className={styles.Title}>{title}</h2>
      <div className={styles.Blocks}>
        {block.map(el => (
          <>
            {type === 'links' ? (
              <div className={styles.Border}>
                <div className={styles.Btn} key={el.text} datatype={'links'}>
                  <p className={styles.Number}>{el.num}</p>
                  <p className={styles.Text}>{el.text}</p>
                </div>
              </div>
            ) : (
              <div className={styles.Btn} key={el.text} style={{ marginBottom: '20px' }}>
                <p className={styles.Number}>{el.num}</p>
                <p className={styles.Text}>{el.text}</p>
              </div>
            )}
          </>
        ))}
        <div className={styles.mobileCarousel}>
          <Carousel
            showArrows
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <button onClick={onClickHandler} className={styles.carouselPrevBtn}>
                  <ArrowRight />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <button onClick={onClickHandler} className={styles.carouselBtn}>
                  <ArrowRight />
                </button>
              )
            }
          >
            {block.map(el => (
              <div className={styles.Btn} key={el.text} datatype={'mobileCard'}>
                <p className={styles.Number}>{el.num}</p>
                <p className={styles.Text}>{el.text}</p>
              </div>
            ))}
          </Carousel>
        </div>
        {!block.length && <p className={styles.StatNotification}>Статистика отсутствует</p>}
      </div>
    </section>
  );
};

export default StatSections;
