import { FC } from 'react';
import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';

import styles from './Video.module.scss';
import { LandingIdType, LandingVideo } from 'modules/landing-generator/landing-generator.types';
import DependentText from '../DependentText';
import useAppDispatch from 'hooks/useAppDispatch';
import { updateLandingVideoText } from 'modules/landing-generator/landing-generator.reducer';

function youtubeParser(url: string) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

type Props = {
  id: LandingIdType;
  video?: LandingVideo | null;
};

const Video: FC<Props> = ({ id, video }) => {
  const dispatch = useAppDispatch();
  const parsedVideoId = youtubeParser(video?.link ?? '');

  return !isNull(video) && !isUndefined(video) ? (
    <div className={styles.Wrapper}>
      {video.title && (
        <DependentText
          type="title"
          saveText={value => dispatch(updateLandingVideoText({ id, type: 'title', text: value }))}
          {...video.title}
        />
      )}

      {video.text && (
        <DependentText
          type="text"
          saveText={value => dispatch(updateLandingVideoText({ id, type: 'text', text: value }))}
          {...video.text}
        />
      )}

      {parsedVideoId ? (
        <iframe
          className={styles.Frame}
          src={`https://www.youtube.com/embed/${parsedVideoId}`}
        ></iframe>
      ) : (
        <p>Can't load video</p>
      )}
    </div>
  ) : null;
};

export default Video;
