import { FC } from 'react';
import Button from 'components/Button';
import styles from './BotsChatButtons.module.scss';
import useDisclosure from 'hooks/useDisclosure';
import NewMessageModal from '../NewMessageModal';
import { useRemoveAdminBotChatByIdMutation } from '../../codegen/telegram.admin.api';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useBotId } from '../../telegram.hooks';
import r from 'constants/routes';
import { TTelegramErrorResult } from '../../telegram.types';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';

const BotsChatButtons: FC = () => {
  const [deleteChatById, { isLoading }] = useRemoveAdminBotChatByIdMutation();
  const { chatId } = useParams();
  const botId = useBotId();
  const navigate = useNavigate();
  const {
    open: isNewOpen,
    onOpen: onNewOpen,
    onClose: onNewClose,
  } = useDisclosure({ blockScroll: true });

  const onLeaveChatHandler = () => {
    if (chatId) {
      deleteChatById({ chatId, botId }).then(res => {
        if ('error' in res) {
          const clonedError: TTelegramErrorResult = JSON.parse(JSON.stringify(res));
          showFeedback({
            title: 'Ошибка',
            text: clonedError.error.data.description,
            type: EFeedbackType.ERROR,
          });
        } else {
          showFeedback({
            title: 'ГОТОВО!',
            text: 'Вы удалили чат',
            type: EFeedbackType.SUCCESS,
          }).then(() => {
            navigate(generatePath(r.telegram.editAdminBot, { id: botId }));
          });
        }
      });
    }
  };
  return (
    <>
      <div className={styles.Root}>
        <Button onClick={onNewOpen} variant={'magic'}>
          Новое сообщение
        </Button>
        <Button
          disabled={isLoading}
          onClick={onLeaveChatHandler}
          className={styles.Delete}
          variant={'tetriary'}
        >
          Покинуть чат
        </Button>
      </div>
      {isNewOpen && <NewMessageModal isOpen={isNewOpen} onClose={onNewClose} />}
    </>
  );
};

export default BotsChatButtons;
