import { FC } from 'react';

type Props = {
  color: string;
};

export const Inbox: FC<Props> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M14.1211 10.5V13.1667C14.1211 13.5203 13.9806 13.8594 13.7306 14.1095C13.4805 14.3595 13.1414 14.5 12.7878 14.5H3.45443C3.10081 14.5 2.76167 14.3595 2.51162 14.1095C2.26157 13.8594 2.12109 13.5203 2.12109 13.1667V10.5"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78809 7.1665L8.12142 10.4998L11.4548 7.1665"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12109 10.5V2.5"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Outbox: FC<Props> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M14.1211 10.5V13.1667C14.1211 13.5203 13.9806 13.8594 13.7306 14.1095C13.4805 14.3595 13.1414 14.5 12.7878 14.5H3.45443C3.10081 14.5 2.76167 14.3595 2.51162 14.1095C2.26157 13.8594 2.12109 13.5203 2.12109 13.1667V10.5"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4548 5.83333L8.12142 2.5L4.78809 5.83333"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12109 2.5V10.5"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
