import { FC } from 'react';

import styles from './LastUpdate.module.scss';
import DateTime from 'components/DateTime';

type Props = {
  date: string;
};

const LastUpdate: FC<Props> = ({ date }) => {
  return (
    <div className={styles.Wrapper}>
      <DateTime date={date} size={12} />
      <div className={styles.Label}>Последнее обновление</div>
    </div>
  );
};

export default LastUpdate;
