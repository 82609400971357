import { FC } from 'react';

import styles from './Options.module.scss';
import OptionsMenu from 'components/OptionsMenu';
import useDisclosure from 'hooks/useDisclosure';
import SettingsModal from '../SettingsModal';
import LandingDeletePageModal from '../LandingDeletePageModal';

const Options: FC = () => {
  const {
    open: settingsModalOpen,
    onOpen: onSettingsModalOpen,
    onClose: onSettingsModalClose,
  } = useDisclosure();

  const {
    open: deletePageModalOpen,
    onOpen: onDeletePageModalOpen,
    onClose: onDeletePageClose,
  } = useDisclosure();

  return (
    <>
      <OptionsMenu
        iconName="options-vertical"
        menuClassName={styles.Menu}
        options={[
          { displayValue: 'Настройки страницы', isSelected: false, onClick: onSettingsModalOpen },
          { displayValue: 'Удалить страницу', isSelected: false, onClick: onDeletePageModalOpen },
        ]}
      />

      <SettingsModal
        modalProps={{
          isOpen: settingsModalOpen,
          onClose: onSettingsModalClose,
        }}
      />
      <LandingDeletePageModal
        modalProps={{
          isOpen: deletePageModalOpen,
          onClose: onDeletePageClose,
        }}
      />
    </>
  );
};

export default Options;
