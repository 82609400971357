import {
  TAutoPostsData,
  TCreateAutomationPostBody,
  TNamingMap,
  TResVkAccount,
  TVkFilesRes,
} from './vk.types';
import { isEmpty, isNull } from 'lodash-es';
import dayjs from 'dayjs';
import { some } from 'lodash';

export const parseParams = (url: string) =>
  Object.fromEntries(
    url
      .slice(url.indexOf('#') + 1)
      .split('&')
      .map(keyval => keyval.split('=')),
  );

export function removeDuplicatesById<T extends { id: K }, K>(arr: T[]): T[] {
  const uniqueMap = new Map<K, T>();
  arr.forEach(item => {
    if (!uniqueMap.has(item.id)) {
      uniqueMap.set(item.id, item);
    }
  });
  return Array.from(uniqueMap.values());
}

export function namingMapHandler<T extends { name: string }>(array: T[]): TNamingMap {
  if (!isEmpty(array) && array) {
    return array.reduce((acc: TNamingMap, cur) => {
      acc[cur.name] = cur.name;
      return acc;
    }, {} as TNamingMap);
  }
  return {};
}

export const automationFormHandler = (
  formData: Record<string, string | Date | File | undefined>,
) => {
  const tempMap = new Map(Object.entries(formData));
  if (!tempMap.has('date')) tempMap.set('date', new Date());
  if (!tempMap.has('photo')) tempMap.set('photo', '');
  const dateForm: Date = tempMap.get('date') as Date;
  const photoId: string = tempMap.get('photo') as string;
  tempMap.set('time', dayjs(dateForm).format('HH:mm'));
  tempMap.set('date', dayjs(dateForm).format('DD.MM.YYYY'));
  tempMap.set('fileId', photoId);
  tempMap.delete('photo');
  return Object.fromEntries(tempMap) as TCreateAutomationPostBody;
};

export const getFileIds = (postsData: TAutoPostsData) => {
  return postsData?.items
    .map(item => {
      if (isNull(item)) return '';
      return item.fileId;
    })
    .filter(item => item);
};

export const combinedPosts = (postsData: TAutoPostsData, files: TResVkAccount<TVkFilesRes[]>) => {
  const filesMap = files?.data.reduce((acc, cur) => {
    acc[cur.fileId] = cur;
    return acc;
  }, {} as { [key: string]: TVkFilesRes });

  return postsData?.items.map(obj => {
    if (filesMap) {
      return {
        ...filesMap[obj.fileId],
        ...obj,
      };
    }
  });
};

export const combinedTranslations = (
  postsData: TAutoPostsData,
  files: TResVkAccount<TVkFilesRes[]>,
) => {
  const filesMap = files?.data.reduce((acc, cur) => {
    acc[cur?.fileId] = cur;
    return acc;
  }, {} as { [key: string]: TVkFilesRes });

  return postsData?.items.map(obj => {
    if (filesMap) {
      if (obj.previewFileId)
        return {
          ...filesMap[obj.previewFileId],
          ...obj,
        };
    }
  });
};

export const fromStringToDate = (dateString: string, timeString: string) => {
  if (dateString && timeString) {
    const [day, month, year] = dateString.split('.').map(Number);
    const [hours, minutes] = timeString.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes);
  }
  return new Date();
};

export const createFile = async (url?: string, type?: string, fileName?: string) => {
  if (url) {
    const metadata = { type: 'image/' + type };
    const data = new Blob([url], metadata);
    return new File([data], `${fileName}.${type}`, metadata);
  }
};

export const isSomeObjectKeysEmpty = <T extends object>(obj: T) => {
  if (isEmpty(obj)) return true;
  return some(obj, isEmpty);
};
