import { FC } from 'react';
import styles from './BotChatBody.module.scss';
import PlannedMessages from '../PlannedMessages';
import SentMessages from '../SentMessages';
import BotsChatButtons from '../BotsChatButtons';
import useWindowSize from 'hooks/useWindowSize';

const BotChatBody: FC = () => {
  const { width } = useWindowSize();
  return (
    <div className={styles.Root}>
      <PlannedMessages />
      <SentMessages />
      {width <= 886 && <BotsChatButtons />}
    </div>
  );
};

export default BotChatBody;
