import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Settings from './pages';
import SettingsResetPassword from './pages/SettingsResetPassword';

const SettingsRoutes = (): JSX.Element => {
  return (
    <>
      <Route path={r.settings} element={<Settings />} />
      <Route path={r.auth.dropPassword} element={<SettingsResetPassword />} />
    </>
  );
};

export default SettingsRoutes;
