import { FC } from 'react';
import styles from './CommandsTable.module.scss';
import CommandsTableHead from './CommandsTableHead';
import CommandsTableBody from './CommandsTableBody';

const CommandsTable: FC = () => {
  return (
    <table className={styles.Root}>
      <CommandsTableHead />
      <CommandsTableBody />
    </table>
  );
};

export default CommandsTable;
