import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { landingVideoSchema } from 'modules/landing-generator/landing-generator.schema';
import { LandingIdType, LandingVideoForm } from 'modules/landing-generator/landing-generator.types';
import EditorForm from '../EditorForm';
import EditorTitle from '../EditorTitle';
import Input from 'components/Input';
import EditorBtn from '../EditorBtn';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  alignLandingVideoText,
  setLandingVideo,
  setLandingVideoTextColor,
  toggleLandingVideoTextBold,
  toggleLandingVideoTextItalic,
  toggleLandingVideoTextUnderline,
} from 'modules/landing-generator/landing-generator.reducer';
import AdjustableInput from '../AdjustableInput';
import { useSelectLanding } from 'modules/landing-generator/landing-generator.selectors';
import { convertLandingVideoFormToLandingVideo } from 'modules/landing-generator/landing-generator.converters';

type Props = {
  id: LandingIdType;
};

const VideoEditor: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const landing = useSelectLanding(id);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<LandingVideoForm>({
    mode: 'onChange',
    resolver: yupResolver(landingVideoSchema()),
    defaultValues: {
      link: 'https://',
    },
  });

  useEffect(() => {
    reset({
      link: landing?.video?.link,
      title: landing?.video?.title?.content,
      text: landing?.video?.text?.content,
    });
  }, [reset, landing?.video?.link, landing?.video?.title?.content, landing?.video?.text?.content]);

  const onSubmit: SubmitHandler<LandingVideoForm> = data => {
    const video = convertLandingVideoFormToLandingVideo(data, landing?.video);
    dispatch(setLandingVideo({ id, video }));
    reset();
  };

  return (
    <EditorForm onSubmit={handleSubmit(onSubmit)}>
      <EditorTitle>Добавление видео с Youtube</EditorTitle>

      <Input
        label="URL"
        register={register('link')}
        invalid={!!errors.link}
        errorMsg={errors.link?.message}
        variant="light"
      />
      <AdjustableInput
        handlers={{
          onToggleBold: () => dispatch(toggleLandingVideoTextBold({ id, type: 'title' })),
          onToggleItalic: () => dispatch(toggleLandingVideoTextItalic({ id, type: 'title' })),
          onToggleUnderline: () => dispatch(toggleLandingVideoTextUnderline({ id, type: 'title' })),
          onSetColor: color => dispatch(setLandingVideoTextColor({ id, color, type: 'title' })),
          onAlign: alignment => dispatch(alignLandingVideoText({ id, alignment, type: 'title' })),
        }}
        label="Заголовок"
        register={register('title')}
        invalid={!!errors.title}
        errorMsg={errors.title?.message}
        variant="light"
        color={landing?.video?.title?.color}
        isTitle
      />
      <AdjustableInput
        handlers={{
          onToggleBold: () => dispatch(toggleLandingVideoTextBold({ id, type: 'text' })),
          onToggleItalic: () => dispatch(toggleLandingVideoTextItalic({ id, type: 'text' })),
          onToggleUnderline: () => dispatch(toggleLandingVideoTextUnderline({ id, type: 'text' })),
          onSetColor: color => dispatch(setLandingVideoTextColor({ id, color, type: 'text' })),
          onAlign: alignment => dispatch(alignLandingVideoText({ id, alignment, type: 'text' })),
        }}
        color={landing?.video?.text?.color}
        label="Основной текст"
        register={register('text')}
        invalid={!!errors.text}
        errorMsg={errors.text?.message}
        variant="light"
      />

      <EditorBtn disabled={!isValid}>Сохранить</EditorBtn>
    </EditorForm>
  );
};

export default VideoEditor;
