import styles from './FolderCard.module.scss';
import folderThumb from 'assets/svg/vk/folder.svg';
import parsFolderThumb from 'assets/svg/vk/parssingFolder.svg';
import { FC } from 'react';
import ColorfulBorder from 'components/ColorfulBorder';
import classNames from 'classnames';

type Props = {
  isParsing?: boolean;
  folderName: string;
  numberOfUsers?: number;
  numberOfGroups?: number;
  onClick?: () => void;
};

const FolderCard: FC<Props> = ({
  isParsing,
  folderName,
  numberOfUsers,
  numberOfGroups,
  onClick,
}) => {
  return (
    <ColorfulBorder
      className={classNames(styles.Main, {
        [styles.None]: !isParsing,
      })}
    >
      <div
        className={classNames(styles.Root, {
          [styles.Parsing]: isParsing,
        })}
        onClick={onClick}
      >
        <div className={styles.FolderState}>
          <img
            className={styles.FolderIcon}
            src={isParsing ? parsFolderThumb : folderThumb}
            alt={''}
          />
          {isParsing && <h2 className={styles.ParsingState}>Идет парсинг</h2>}
        </div>
        <div className={styles.FolderInfo}>
          <h1 className={styles.FolderName}>{folderName}</h1>
          {numberOfGroups && <p className={styles.FolderGroupPar}>Групп: {numberOfGroups}</p>}
          {numberOfUsers && <p className={styles.FolderGroupPar}>Пользователей: {numberOfUsers}</p>}
        </div>
      </div>
    </ColorfulBorder>
  );
};

export default FolderCard;
