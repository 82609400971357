import { useMemo, useReducer, useCallback, useEffect } from 'react';

// types

type Tabs<T extends string, C> = {
  [K in T]: C;
};

type UseTabsState<T extends string, C> = {
  name: T;
  content: C;
  isActive: boolean;
}[];

enum UseTabsActionKind {
  SWITCH_TAB = 'SWITCH_TAB',
  UPDATE_CONTENT = 'UPDATE_CONTENT',
}

type SwitchTabActionType<T extends string> = {
  type: UseTabsActionKind.SWITCH_TAB;
  payload: T;
};

type UpdateContentActionType<T extends string, C> = {
  type: UseTabsActionKind.UPDATE_CONTENT;
  payload: Tabs<T, C>;
};

type UseTabsAction<T extends string, C> = UpdateContentActionType<T, C> | SwitchTabActionType<T>;

// actions

const switchTab = <T extends string>(tab: T): SwitchTabActionType<T> => ({
  type: UseTabsActionKind.SWITCH_TAB,
  payload: tab,
});

const updateContent = <T extends string, C>(tabs: Tabs<T, C>): UpdateContentActionType<T, C> => ({
  type: UseTabsActionKind.UPDATE_CONTENT,
  payload: tabs,
});

// initial state

const getInitialState = <T extends string, C>(tabs: Tabs<T, C>): UseTabsState<T, C> => {
  const initialState: UseTabsState<T, C> = [];
  Object.keys(tabs).forEach((tabName, index) =>
    initialState.push({
      name: tabName as T,
      content: tabs[tabName as keyof typeof tabs],
      isActive: index === 0,
    }),
  );
  return initialState;
};

// selectors

const selectActiveTab = <T extends string, C>(
  state: UseTabsState<T, C>,
): { name: T; content: C } => {
  const activeTab = state.find(tab => tab.isActive);
  return activeTab
    ? { name: activeTab.name, content: activeTab.content }
    : { name: state[0].name, content: state[0].content };
};

// reducer

const createReducer =
  <T extends string, C>() =>
  (state: UseTabsState<T, C>, { type, payload }: UseTabsAction<T, C>) => {
    switch (type) {
      case UseTabsActionKind.SWITCH_TAB:
        const nextActive = state.find(tab => tab.name === payload);
        const prevActive = state.find(tab => tab.isActive);
        if (nextActive && prevActive)
          return [
            ...state.filter(tab => tab.name !== prevActive.name && tab.name !== nextActive.name),
            { ...prevActive, isActive: false },
            { ...nextActive, isActive: true },
          ];
        return state;
      case UseTabsActionKind.UPDATE_CONTENT:
        const stateCopy = [...state];
        const processedTabs = getInitialState(payload as Tabs<T, C>);
        processedTabs.forEach(tab => {
          const existingTab = stateCopy.find(t => t.name === tab.name);
          if (existingTab) existingTab.content = tab.content;
        });
        return stateCopy;
      default:
        return state;
    }
  };

// the hook

function useTabs<T extends string, C>(
  tabs: Tabs<T, C>,
): {
  switchTab: (tab: T) => void;
  activeTab: {
    name: T;
    content: C;
  };
} {
  const [state, dispatch] = useReducer(createReducer<T, C>(), getInitialState(tabs));
  const active = useMemo(() => selectActiveTab<T, C>(state), [state]);

  useEffect(() => dispatch(updateContent(tabs)), [tabs]);

  return {
    switchTab: useCallback((tab: T) => dispatch(switchTab(tab)), []),
    activeTab: active,
  };
}

export default useTabs;
