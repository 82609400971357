import { FC } from 'react';

import styles from './AdjustableInput.module.scss';
import Input, { InputProps } from 'components/Input';
import useDisclosure from 'hooks/useDisclosure';
import TextEditorMenu, { TextEditorMenuHandlers } from '../TextEditorMenu';
import Portal from 'components/Portal';

type Props = {
  handlers: TextEditorMenuHandlers;
  color?: string;
} & InputProps;

const AdjustableInput: FC<Props> = ({ handlers, color, ...inputProps }) => {
  const { open, onOpen, onClose } = useDisclosure();

  return (
    <div className={styles.Wrapper}>
      <Input icons={[{ name: 'settings-controls', onClick: onOpen }]} {...inputProps} />
      <TextEditorMenu
        className={styles.Menu}
        color={color}
        handlers={handlers}
        open={open}
        onClose={onClose}
      />

      {open && (
        <Portal container={document.getElementById('landing-generator-editor') ?? undefined}>
          <div className={styles.Fade} />
        </Portal>
      )}
    </div>
  );
};

export default AdjustableInput;
