import { FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '../Header';
import ActionsNav from '../ActionsNav';
import Circles from 'components/Circles';
import { NavTypes } from '../../vk.constants';
import AutoGroupNav from '../AutoGroupNav';
import styles from './Layout.module.scss';
import { useSelectUser } from '../../../account/account.selector';
import EmptyPage from 'components/EmptyPage';
import lock from 'assets/svg/lock.svg';
import r from 'constants/routes';
import CenterBox from 'components/CenterBox';

type Props = {
  type?: NavTypes;
};
const Layout: FC<Props> = ({ type }) => {
  const user = useSelectUser();
  const navigate = useNavigate();

  const navHandler = (navType?: NavTypes) => {
    const navTable = {
      [NavTypes.AUTOMATION_NAV]: <AutoGroupNav />,
    };

    if (navType) {
      return navTable[navType];
    }

    return <ActionsNav />;
  };

  return (
    <>
      {user?.permissions.vkAllowed && <Header />}

      {user?.permissions.vkAllowed ? (
        <div className={styles.Content}>
          {navHandler(type)}
          <Outlet />
        </div>
      ) : (
        <CenterBox>
          <EmptyPage
            img={<img src={lock} alt="" />}
            title={'Упс, У вас нет доступа'}
            text={
              'Для того, чтобы воспользоваться функционалом “В Контакте”, вам необходимо приобрести любой тарифный план'
            }
            btnText={'Выбрать тариф'}
            onClick={() => {
              navigate(r.tariff);
            }}
          />
        </CenterBox>
      )}

      <Circles />
    </>
  );
};

export default Layout;
