import { FC, ReactNode, useState } from 'react';
import { isNil } from 'lodash-es';

import formatLongText from 'utils/formatLongTrxt';

import Avatar from 'components/Avatar';
import Button from 'components/Button';

import styles from './MobileBotEdit.module.scss';
import BotEditModal from 'modules/telegram/components/BotEditModal';
import TimezoneDiv from '../TimezoneDiv';

type Props = {
  uuid: string;
  name: string | null;
  toggled: boolean;
  onUpdate: (args: { id: string; updatedName: { name: string } }) => void;
  onToggle: () => void;
  onDelete: () => void;
  onSync: () => void;
  bottom?: ReactNode;
  timezone?: string;
  botInfo?: {
    displayName: string;
    name: string;
    photoUrl: string | null;
  };
  lastTokenSymbol?: string;
  isDisableToggle?: boolean;
};

const MobileBotEdit: FC<Props> = ({
  uuid,
  name,
  toggled = false,
  onUpdate,
  onToggle,
  onDelete,
  onSync,
  botInfo,
  lastTokenSymbol,
  timezone,
  isDisableToggle,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.Flex}>
          <div className={styles.AvatarWrapper}>
            {!isNil(botInfo) && (
              <>
                {botInfo.photoUrl ? (
                  <img src={botInfo.photoUrl} className={styles.Image} alt={''} />
                ) : (
                  <>
                    <Avatar name={botInfo.name} src={botInfo.photoUrl} size={40} />
                  </>
                )}
              </>
            )}
            <h3 className={styles.Text}>{botInfo && formatLongText(botInfo.name, 18)}</h3>
          </div>
          <Button
            className={styles.Settings}
            centralIcon="gear"
            size="m"
            variant="tetriary"
            onClick={onToggleModal}
          ></Button>
        </div>
        <TimezoneDiv timezone={timezone} />
      </div>
      <BotEditModal
        uuid={uuid}
        name={name}
        toggled={toggled}
        onUpdate={onUpdate}
        onToggle={onToggle}
        onDelete={onDelete}
        onSync={onSync}
        onOpen={onToggleModal}
        isOpenModal={isOpenModal}
        botInfo={botInfo}
        lastTokenSymbol={lastTokenSymbol}
        timezone={timezone}
        isDisableToggle={isDisableToggle}
      />
    </>
  );
};

export default MobileBotEdit;
