import { FC } from 'react';

import FeedbackCard from 'modules/auth/components/FeedbackCard';
import partyFace from 'assets/svg/auth/feedback/partyFace.svg';

import styles from '../AdminBots.module.scss';

type TAdminBotCreated = {
  onClose: () => void;
};

const AdminBotCreated: FC<TAdminBotCreated> = ({ onClose }) => {
  return (
    <div
      className={styles.AdminBotCreatedBackground}
      onClick={event => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
    >
      <div className={styles.AdminBotCreatedWrapper}>
        <FeedbackCard
          title="Бот добавлен"
          text="Поздравляем! Вы подключили нового бота"
          button={{ text: 'Начнем', onClick: () => onClose() }}
          img={partyFace}
          cardStyles={styles.AdminBotCreated}
        />
      </div>
    </div>
  );
};

export default AdminBotCreated;
