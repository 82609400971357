import { CSSProperties, FC, useMemo } from 'react';

import styles from './Text.module.scss';
import { Component, ETextStyle, Text as TText } from 'modules/my-pages/my-pages.types';
import useOnClick from 'modules/my-pages/hooks/useOnClick';

type Props = {
  component: Component<TText>;
};

const Text: FC<Props> = ({ component }) => {
  const { data } = component;
  const onClick = useOnClick(data.onClick);

  const fontSize = useMemo(() => {
    switch (data.textStyle) {
      case ETextStyle.HEADING:
        return 32;
      case ETextStyle.TITLE:
        return 28;
      case ETextStyle.SUBTITLE:
        return 24;
      case ETextStyle.PARAGRAPH:
        return 14;
      case ETextStyle.SMALL_TEXT:
        return 10;
    }
  }, [data.textStyle]);

  const style: CSSProperties = {
    fontSize,
    fontWeight: data.properties?.bold ? 700 : 400,
    fontStyle: data.properties?.italic ? 'italic' : 'normal',
    textDecoration: data.properties?.underline ? 'underline' : 'none',
    color: data.properties?.color,
    textAlign: data.properties?.alignment.toLowerCase() as 'left' | 'right' | 'center',
  };

  return (
    <p className={styles.Text} style={style} onClick={onClick}>
      {component.data.text}
    </p>
  );
};

export default Text;
