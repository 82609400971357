import { FC } from 'react';

import styles from './MethodCard.module.scss';
import classNames from 'classnames';
import CheckBox from 'components/CheckBox';
import { QuestionMark } from './QuestionMark';
import { FinanceTooltip } from '../FinanceTooltip';
import env from 'config/env';

type Props = {
  photo: string;
  onClick: () => void;
  isActive?: boolean;
  amount?: number;
};

const MethodCard: FC<Props> = ({ photo, isActive, amount, onClick }) => {
  const ActiveComponent = (
    <div className={styles.ActiveCard}>
      <div className={styles.UpperCard}>
        <img className={styles.UpperCardImg} src={photo} alt="payMethod" />
        <FinanceTooltip
          label={
            <div className={styles.ToolTipWrapper}>
              <h1>Автопополнение</h1>
              <p>
                На данный момент у вас действует автоматическое пополнение, если вы хотите его
                выключить, то можете это сделать в разделе «настройки».
              </p>
              <p>
                Если вы хотите изменить сумму автоматического пополнения, вам необходимо выключить
                действующее пополнение в разделе «настройки» и вернуться на данную страницу для
                создание нового авто-пополнения на желаемую сумму.»
              </p>
            </div>
          }
        >
          <QuestionMark />
        </FinanceTooltip>
      </div>
      <div className={styles.UpperCard}>
        <CheckBox
          text={<p className={styles.Label}>Активно</p>}
          checked={isActive}
          type="gradient"
        />
        <div className={styles.NumBack}>
          <div className={styles.Wrapper}>
            <p className={styles.Top}>{amount}</p>
            <span>{env.CURRENCY}.</span>
          </div>
          <p className={styles.Back}>{amount}</p>
        </div>
      </div>
    </div>
  );
  return (
    <button
      className={classNames(styles.Card, {
        [styles.CardActive]: isActive,
      })}
      onClick={onClick}
    >
      {isActive ? (
        <>{ActiveComponent}</>
      ) : (
        <>
          {isActive && (
            <CheckBox
              rightText={<p className={styles.Label}>Активно</p>}
              checked={isActive}
              className={styles.ActiveCheckbox}
              type="gradient"
            />
          )}
          <img src={photo} alt="payMethod" />
        </>
      )}
    </button>
  );
};

export default MethodCard;
