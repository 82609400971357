import { CSSProperties, FC } from 'react';

import styles from './Image.module.scss';
import { Component, Image as TImage } from 'modules/my-pages/my-pages.types';
import useOnClick from 'modules/my-pages/hooks/useOnClick';

type Props = {
  component: Component<TImage>;
};

const Image: FC<Props> = ({ component }) => {
  const { data } = component;
  const onClick = useOnClick(data.onClick);

  const style: CSSProperties = {
    backgroundImage: `url('${data.link}')`,
    opacity: data.opacity,
  };

  return (
    <div
      className={styles.Image}
      data-image-style={data.imageStyle}
      style={style}
      onClick={onClick}
    />
  );
};

export default Image;
