import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EditSpamBot.module.scss';
import BotEditSidePanel from 'modules/telegram/components/BotEditSidePanel';
import Tag from 'components/Tag';
import {
  useSpamBotControllerDeleteBotMutation,
  useSpamBotControllerGetBotQuery,
  useSpamBotControllerSyncBotMutation,
  useSpamBotControllerToggleBotMutation,
  useSpamBotIdChatsControllerCreateChatMutation,
  useSpamBotIdChatsControllerGetChatListQuery,
} from 'modules/telegram/codegen/telegram.spam.api';
import r from 'constants/routes';
import { useBotId } from 'modules/telegram/telegram.hooks';
import LoadingState from 'components/LoadingState';
import PageHeader from 'components/PageHeader';
import MobileBotEdit from '../../components/MobileBotEdit';
import SpamBotChatList from './components/SpamBotChatList';
import { videoTutorials } from '../../../../constants/videos';
import { isNil } from 'lodash-es';

const TagsPane: FC<{ id?: string }> = ({ id }) => {
  const { data, isLoading, isError } = useSpamBotIdChatsControllerGetChatListQuery({
    botId: id || '',
    botSpamChatListParams: {},
  });
  const [deleteChat] = useSpamBotIdChatsControllerCreateChatMutation();

  if (isLoading) return null;
  if (isError) return null;
  return (
    <div className={styles.Tags}>
      {data?.list.map(chat => (
        <Tag
          key={chat.id}
          text={chat.chatName ?? chat.chatId}
          dimmed={!chat.isReachable}
          onClick={() => deleteChat({ botId: id || '', chatId: chat.chatId })}
        />
      ))}
    </div>
  );
};

const EditSpamBot: FC = () => {
  const id = useBotId();
  const navigate = useNavigate();
  const { data, isLoading } = useSpamBotControllerGetBotQuery({ id });
  const [toggleSpamBot] = useSpamBotControllerToggleBotMutation();
  const [syncSpamBot] = useSpamBotControllerSyncBotMutation();
  const [deleteBot] = useSpamBotControllerDeleteBotMutation();

  if (isLoading) return <LoadingState />;

  return (
    <div className={styles.Page}>
      <div className={styles.Content}>
        <PageHeader
          title={data?.name + ''}
          subtitle={'Бот рассылки'}
          videoTutorials={[
            { label: 'Настройка бота рассылки', url: videoTutorials.bot_broadcast },
            { label: 'Получение токена', url: videoTutorials.token_reception },
            { label: 'Настройка бота', url: videoTutorials.bot_setup },
          ]}
          onIconClick={() => {
            navigate(r.telegram.spamBots);
          }}
        />

        <div className={styles.Body}>
          <MobileBotEdit
            isDisableToggle={!isNil(data?.canNotStart)}
            uuid={data?.id ?? ''}
            name={data?.name ?? ''}
            lastTokenSymbol={data?.lastTokenSymbol}
            toggled={data?.status ?? false}
            botInfo={data?.telegram}
            bottom={<TagsPane />}
            onUpdate={() => {}}
            timezone={data?.timezone}
            onToggle={() => {
              if (data?.id) toggleSpamBot({ id: data.id }).unwrap();
            }}
            onSync={() => {
              if (data?.id) syncSpamBot({ id: data.id });
            }}
            onDelete={() => {
              if (data?.id)
                deleteBot({ id: data.id }).then(() => {
                  navigate(r.telegram.spamBots);
                });
            }}
          />
          <BotEditSidePanel
            isDisableToggle={!isNil(data?.canNotStart)}
            title="Бот рассылки"
            name={data?.name}
            uuid={data?.id}
            lastTokenSymbol={data?.lastTokenSymbol}
            botInfo={data?.telegram}
            toggled={data?.status}
            timezone={data?.timezone}
            onToggle={() => {
              if (data?.id) toggleSpamBot({ id: data.id }).unwrap();
            }}
            onDelete={() => {
              if (data?.id)
                deleteBot({ id: data.id }).then(() => {
                  navigate(r.telegram.spamBots);
                });
            }}
            onSync={() => {
              if (data?.id) syncSpamBot({ id: data.id });
            }}
            bottom={<TagsPane id={id} />}
          />
        </div>
      </div>
      <SpamBotChatList />
    </div>
  );
};

export default EditSpamBot;
