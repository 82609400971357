import { FC, ChangeEvent } from 'react';
import styles from './DisplayField.module.scss';
import { blockInvalidChar } from 'utils/blockInvalidChar';
import { isEmpty } from 'lodash-es';
import Icon from '../Icon';

type Props = {
  value: string;
  label: string;
  isInput?: boolean;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  noValue?: boolean;
};

const DisplayField: FC<Props> = ({ value, label, isInput, onInputChange, noValue }) => {
  return (
    <div className={styles.Display}>
      <p className={styles.Text}>{label}</p>

      {noValue ? (
        <Icon iconName={'add-big'} />
      ) : (
        <span className={styles.Date}>
          {isInput ? (
            <input
              type={'number'}
              onChange={onInputChange}
              className={styles.Input}
              onKeyDown={blockInvalidChar}
            />
          ) : (
            <>{isEmpty(value) ? <Icon iconName={'add-big'} /> : value}</>
          )}
        </span>
      )}
    </div>
  );
};

export default DisplayField;
