import { FC } from 'react';

import styles from './Range.module.scss';

type Props = {
  total: number;
  of: number;
};

const Range: FC<Props> = ({ total, of }) => {
  return (
    <>
      {total}
      <div className={styles.Of}>/{of}</div>
    </>
  );
};

export default Range;
