import { FC, ReactNode } from 'react';
import { Menu as HeadlessMenu } from '@headlessui/react';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';

import styles from './Menu.module.scss';
import IconButton from 'components/IconButton';

export type MenuItem = {
  label: string;
  onClick: () => void;
  isSelected: boolean;
  className?: string;
};

type Props = {
  items: MenuItem[];
  children: ReactNode;
  className?: string;
  contextClassName?: string;
  btnClassName?: string;
  iconPosition?: 'left' | 'right';
  iconName?: string;
  iconSize?: number;
  datatype?: string;
};

const Menu: FC<Props> = ({
  children,
  className,
  items,
  btnClassName,
  contextClassName,
  iconName = 'arrow-up',
  iconPosition = 'right',
  iconSize = 12,
  datatype,
}) => {
  return (
    <HeadlessMenu as="div" className={classNames(styles.Menu, className)}>
      <HeadlessMenu.Button className={classNames(styles.Btn, btnClassName)} data-type={datatype}>
        {children}
      </HeadlessMenu.Button>
      {!isEmpty(items) && (
        <HeadlessMenu.Items as="ul" className={classNames(styles.Context, contextClassName)}>
          <HeadlessMenu.Item as="li" className={styles.Icon} data-position={iconPosition}>
            <IconButton iconName={iconName} size={24} iconSize={iconSize} />
          </HeadlessMenu.Item>

          {items.map((item, index) => (
            <HeadlessMenu.Item
              key={`item_${index}`}
              as="li"
              onClick={item.onClick}
              data-selected={item.isSelected}
              className={item.className}
            >
              {item.label}
            </HeadlessMenu.Item>
          ))}
        </HeadlessMenu.Items>
      )}
    </HeadlessMenu>
  );
};

export default Menu;
