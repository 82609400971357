import {
  EShape,
  Landing,
  LandingButton,
  LandingButtonSocialNetwork,
  LandingFormFormFields,
  LandingGeneratorSliceState,
  LandingText,
  LandingVideoText,
  SocialNetworkProperties,
  TextProperties,
  Website,
} from './landing-generator.types';
import toPairs from 'lodash-es/toPairs';
import isNumber from 'lodash-es/isNumber';

export const getLanding = (
  state: LandingGeneratorSliceState,
  id: Landing['id'],
): Landing | undefined => state.landings.find(savedLanding => id === savedLanding.id);

export const getSelectedText = (
  state: LandingGeneratorSliceState,
  id: Landing['id'],
  type: keyof LandingText,
  index: number,
): [TextProperties | undefined, Landing] | undefined => {
  const saved = getLanding(state, id);
  if (type === 'order') return;
  if (saved && saved.text) {
    const selected = saved.text[type][index];
    return [selected, saved];
  }
};

export const getLandingVideoText = (
  state: LandingGeneratorSliceState,
  id: Landing['id'],
  type: LandingVideoText,
): TextProperties | undefined => {
  const saved = getLanding(state, id);
  if (saved && saved.video) {
    const selected = saved.video[type];
    return isNumber(selected) ? undefined : selected;
  }
};

export const getLandingButton = (
  state: LandingGeneratorSliceState,
  id: Landing['id'],
  btnId: string | number,
): LandingButton | undefined => {
  const landing = getLanding(state, id);
  if (landing) {
    const button = landing.buttons.find(b => b.id === btnId);
    return button;
  }
};

export const getLandingButtonSocialNetwork = (
  state: LandingGeneratorSliceState,
  id: Landing['id'],
  btnId: string | number,
  type: LandingButtonSocialNetwork,
): SocialNetworkProperties | undefined => {
  const button = getLandingButton(state, id, btnId);
  if (button) {
    const socialNetwork = button[type];
    return socialNetwork;
  }
};

export const getLandingWebsite = (
  state: LandingGeneratorSliceState,
  id: Landing['id'],
  btnId: string | number,
  index: number,
): Website | undefined => {
  const button = getLandingButton(state, id, btnId);
  if (button) {
    const website = button.websites[index];
    return website;
  }
};

export const getSocialNetworkPropertiesForConversion = (
  content: string,
  saved?: SocialNetworkProperties,
): SocialNetworkProperties => (saved ? { ...saved, content } : { content, shape: EShape.CIRCLE });

export const fieldsNamed: Record<LandingFormFormFields, string> = {
  firstname: 'Имя',
  lastname: 'Фамилия',
  phone: 'Номер телефона',
  email: 'E-mail',
  dateOfBirth: 'Дата рождения',
  country: 'Страна',
  position: 'Должность',
  extra: 'Дополнительная информация',
  other: 'Другое',
};

export const fieldsNamedEntries = toPairs(fieldsNamed) as [LandingFormFormFields, string][];

export const fieldsDefault: Record<LandingFormFormFields, string | null> = {
  firstname: '',
  lastname: null,
  phone: null,
  email: null,
  dateOfBirth: null,
  country: null,
  position: null,
  extra: null,
  other: null,
};
