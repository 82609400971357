import { FC, ReactNode } from 'react';

import styles from './SocialWrapper.module.scss';

type Props = {
  children: ReactNode;
};

const SocialWrapper: FC<Props> = ({ children }) => {
  return <div className={styles.Wrapper}>{children}</div>;
};

export default SocialWrapper;
