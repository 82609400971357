import { FC, ReactNode } from 'react';

import styles from './Modal.module.scss';
import IconButton from 'components/IconButton';

type Props = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};

const Modal: FC<Props> = ({ open, onClose, children }) => {
  return open ? (
    <div className={styles.Modal}>
      <IconButton className={styles.CloseIcon} onClick={onClose} iconName="cross" iconSize={24} />

      {children}
    </div>
  ) : null;
};

export default Modal;
