import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Navigation.module.scss';
import { NAVIGATION_OPTIONS } from 'constants/navigation';
import Icon from 'components/Icon';

const Navigation: FC = () => {
  return (
    <ul className={styles.List}>
      {Object.values(NAVIGATION_OPTIONS).map(option => (
        <li key={option.name} className={styles.Item}>
          <NavLink
            className={({ isActive }) => classNames(styles.Option, { [styles.Active]: isActive })}
            to={option.to}
          >
            <Icon className={styles.Icon} iconName={option.iconName} size={18} />
            <span className={styles.Name}>{option.name}</span>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Navigation;
