import { FC } from 'react';
import { generatePath } from 'react-router-dom';

import styles from './ShareLinkModal.module.scss';
import Modal, { CommonModalProps } from 'components/Modal';
import ShareLinkButton from 'components/ShareLinkButton';
import LinkCopier from 'components/LinkCopier';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';
import r from 'constants/routes';
import env from 'config/env';

type Props = {
  horizontal?: boolean;
} & CommonModalProps;

const ShareLinkModal: FC<Props> = ({ horizontal, ...modalProps }) => {
  const page = useActivePage();
  const link = page?.link
    ? `${env.APP_FRONT}${generatePath(r.myPages.published, { link: page.link })}`
    : null;

  const shareUrls = {
    vk: `https://vk.com/share.php?url=${link}`,
    tg: `https://telegram.me/share/url?url=${link}`,
    whatsapp: `https://web.whatsapp.com://send?text=${link}`,
  };

  const shareUrl = (url: string) => {
    window.open(url, 'sharer');
  };

  return (
    <Modal {...modalProps} className={styles.Modal} noBtn horizontal>
      <h3 className={styles.Title}>Поделиться ссылкой</h3>
      <div className={styles.Links}>
        <ShareLinkButton
          icon="vk-outline"
          name="VKontakte"
          onClick={() => shareUrl(shareUrls.vk)}
        />
        <ShareLinkButton icon="tg-outline" name="Telegram" onClick={() => shareUrl(shareUrls.tg)} />
        <ShareLinkButton
          icon="whatsapp-outline"
          name="Whatsapp"
          onClick={() => shareUrl(shareUrls.whatsapp)}
        />
        {/*TODO add links*/}
        {/*<ShareLinkButton icon="insta-outline" name="Instagram" onClick={() => {}} />*/}
        {/*<ShareLinkButton icon="email-outline" name="E-mail" onClick={() => {}} disabled />*/}
        {/*<ShareLinkButton icon="share" name="More" onClick={() => {}} />*/}
      </div>

      <h4 className={styles.AltTitle}>Или копировать ссылку</h4>
      {link && <LinkCopier link={link} />}
    </Modal>
  );
};

export default ShareLinkModal;
