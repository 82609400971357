import { FC, Fragment, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import styles from './CardHolder.module.scss';
import Card from 'modules/tariff/components/Card';
import { useSelectedSubscriptionPlan } from 'modules/tariff/tariff.selectors';
import useAppDispatch from 'hooks/useAppDispatch';

import { TPlanService, TPlansMap } from 'modules/tariff/tariff.type';
import { useGetAllPlansWithPriceQuery, useGetPlanServicesQuery } from '../../tariff.api';
import { setTariffPlans } from '../../tariff.reducer';
import { cloneObjectWithExcludedKey, makeTariffMapByName } from '../../tariff.utils';
import TariffModal from '../TariffModal';
import LoadingState from 'components/LoadingState';

export type Props = {
  className?: string;
  isLines?: boolean;
};

export const CardSelected: FC<Props> = ({ isLines }) => {
  const selectedSubscriptionPlan = useSelectedSubscriptionPlan();
  const { data, isLoading } = useGetAllPlansWithPriceQuery();
  const {
    data: planServicesData,
    isLoading: isServicesLoading,
    isSuccess: isServicesFetched,
  } = useGetPlanServicesQuery();
  const dispatch = useAppDispatch();

  const mapData: TPlansMap = useMemo(() => {
    if (Array.isArray(data)) {
      return makeTariffMapByName(data);
    }
    return {};
  }, [data]);

  const combinedData = useMemo(() => {
    if (Array.isArray(data) && isServicesFetched) {
      const servicesArr: TPlanService[] = planServicesData.body as unknown as TPlanService[];
      return data.map(val => {
        const findService = servicesArr.find(service => service.id === val.id);
        if (findService) {
          const planServices = cloneObjectWithExcludedKey(findService, 'id');

          return { ...val, planServices };
        }
      });
    }
    return [];
  }, [data, planServicesData]);

  useEffect(() => {
    dispatch(setTariffPlans(mapData));
  }, [mapData]);

  if (isLoading && isServicesLoading) return <LoadingState />;

  return (
    <>
      {Array.isArray(combinedData) &&
        combinedData.map(tariff => {
          const { name = '', planServices, id = '', brand, image } = tariff || {};

          const isSelected = selectedSubscriptionPlan === name;
          const tariffObj = planServices;

          return (
            <Fragment key={id}>
              {/*
                Компонент потребуется для задачи с обновлением интерфейса динамических
                тарифных планов по пока что будет работать другой "TariffModal"
              */}
              {/* {isSelected && (
                <CardExtended
                  key={id}
                  planId={id}
                  tariff={tariffObj as TPlanService}
                  show={isSelected}
                  brand={brand}
                />
              )} */}
              {isSelected && <TariffModal key={id} planName={name} isOpen={isSelected} />}

              <Card
                planData={mapData[name]?.periodDto}
                tariff={tariffObj as TPlanService}
                plan={name}
                isLines={isLines}
                brand={brand}
                mainImage={image}
              />
            </Fragment>
          );
        })}
    </>
  );
};

const CardHolder: FC<Props> = ({ className, isLines }) => {
  return (
    <div className={classNames(styles.CardHolder, className)}>
      <CardSelected isLines={isLines} />
    </div>
  );
};

export default CardHolder;
