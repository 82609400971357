import { FC, ReactNode } from 'react';

import styles from './Success.module.scss';

type Props = {
  children: ReactNode;
};

const Success: FC<Props> = ({ children }) => {
  return <div className={styles.Success}>{children}</div>;
};

export default Success;
