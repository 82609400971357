import { FC } from 'react';

import ImgInformation from '../ImgInformation/index';
import styles from './ImagesList.module.scss';
import { ImageFileType } from '../../index';

type Props = {
  images: ImageFileType[];
  deleteImg: (index: number) => void;
  noSizeLimit?: boolean;
};

const ImagesList: FC<Props> = ({ images, deleteImg, noSizeLimit }) => {
  return (
    <div className={styles.Images}>
      {images.length > 0 &&
        images.map((img, index) => (
          <ImgInformation
            key={index}
            {...img}
            index={index}
            deleteImg={deleteImg}
            noSizeLimit={noSizeLimit}
          />
        ))}
    </div>
  );
};

export default ImagesList;
