import { FC } from 'react';

import styles from './Block.module.scss';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { ToastContent, ToastType } from 'toasts/toasts.types';

type BlockProps = {
  content: ToastContent;
  iconName: string;
  closeToast?: () => void;
  type: ToastType;
};

const Block: FC<BlockProps> = ({ content, iconName, closeToast }) => {
  return (
    <div className={styles.Block}>
      <div className={styles.Left}>
        <Icon iconName={iconName} size={16} className={styles.Icon} />
        <div className={styles.Content}>{content}</div>
      </div>
      <IconButton iconName="close" iconSize={10} className={styles.Close} onClick={closeToast} />
    </div>
  );
};

export default Block;
