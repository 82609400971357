import { FC } from 'react';
import styles from './WelcomeBotStatisticsTableBody.module.scss';
import { WelcomeBotStatisticsTableRow } from './WelcomeBotStatisticsTableRow';
import { TWelcomeBotContactsStatistic } from '../../../telegram.types';

export const WelcomeBotStatisticsTableBody: FC<{
  statistics?: TWelcomeBotContactsStatistic[];
}> = ({ statistics }) => {
  return (
    <tbody className={styles.Root}>
      {statistics?.map(statistic => (
        <WelcomeBotStatisticsTableRow key={statistic.id} {...statistic} />
      ))}
    </tbody>
  );
};
