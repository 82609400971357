import { FC } from 'react';
import styles from './SpamBotMessagesBody.module.scss';
import SpamBotPlannedMessages from '../SpamBotPlannedMessages';
import SpamBotSentMessages from '../SpamBotSentMessages';
import CreateSpamBotMessagesButton from '../CreateSpamBotMessagesButton';
import useWindowSize from '../../../../../../hooks/useWindowSize';

const SpamBotMessagesBody: FC = () => {
  const { width } = useWindowSize();

  return (
    <div className={styles.Root}>
      <SpamBotPlannedMessages />
      <SpamBotSentMessages />
      {width <= 886 && <CreateSpamBotMessagesButton />}
    </div>
  );
};

export default SpamBotMessagesBody;
