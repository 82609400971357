import { FC } from 'react';

import {
  NonNullableActiveComponent,
  YouTubeVideo as TYouTubeVideo,
} from 'modules/my-pages/my-pages.types';
import ToolsWrapper from '../../tools/ToolsWrapper';
import InputOutline from 'components/InputOutline';
import YouTubeVideoPreview from 'modules/my-pages/components/Preview/components/YouTubeVideo';
import AnchorInput from '../../tools/AnchorInput';
import AddTextBtn from '../../tools/AddTextBtn';
import useSetComponentProperty from 'modules/my-pages/hooks/useSetComponentProperty';

type Props = {
  component: NonNullableActiveComponent<TYouTubeVideo>;
};

const YouTubeVideo: FC<Props> = ({ component }) => {
  const { hash, order, data } = component.component;
  const { setText, setProperty } = useSetComponentProperty(hash);
  const anchorActive = !!data.isAnchorActive;

  return (
    <ToolsWrapper>
      <InputOutline
        label="Ссылка на видео:"
        value={data.link}
        onChange={e => setText('link', e.target.value)}
        type="url"
      />
      <YouTubeVideoPreview component={component.component} width="100%" height={176} />
      <AnchorInput
        anchor={data.anchor}
        setAnchor={value => setText('anchor', value)}
        setAnchorActive={() => setProperty('isAnchorActive', !anchorActive)}
        active={anchorActive}
      />
      <AddTextBtn parentId={component.parent.hash} atOrder={order} />
    </ToolsWrapper>
  );
};

export default YouTubeVideo;
