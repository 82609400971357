import { FC } from 'react';

import styles from './BlockStyleSelector.module.scss';
import { EBlockStyle } from 'modules/my-pages/my-pages.types';
import Tool from '../Tool';

type Props = {
  style: EBlockStyle;
  setStyle: (value: EBlockStyle) => void;
};

const BlockStyleSelector: FC<Props> = ({ style, setStyle }) => {
  return (
    <Tool
      label="Стиль:"
      right={
        <>
          <button
            className={styles.Block}
            onClick={() => setStyle(EBlockStyle.ROUNDED)}
            data-block-style={EBlockStyle.ROUNDED.toLowerCase()}
            data-active={style === EBlockStyle.ROUNDED}
          />
          <button
            className={styles.Block}
            onClick={() => setStyle(EBlockStyle.SQUARE)}
            data-block-style={EBlockStyle.SQUARE.toLowerCase()}
            data-active={style === EBlockStyle.SQUARE}
          />
        </>
      }
    />
  );
};

export default BlockStyleSelector;
