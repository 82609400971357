const formatNumber = (
  num: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
): string => {
  return new Intl.NumberFormat('ru', {
    maximumFractionDigits,
    minimumFractionDigits: Math.min(maximumFractionDigits, minimumFractionDigits),
  }).format(num);
};

export default formatNumber;
