import rootApi from 'config/http';
import { EAppTagType, TPaginated, TPaginatedRequest, TResponse } from 'interfaces/api';
import {
  AutoPayProductList,
  PurchaseRequest,
  TPayeerAddForm,
  TransactionRequest,
  TResUrl,
  UrlType,
  UserTransaction,
} from './finances.types';

const financesApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getFinances: builder.query<
      TPaginated<Array<UserTransaction>>,
      TPaginatedRequest & TransactionRequest
    >({
      query: params => ({
        url: '/users/transaction',
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.TRANSACTIONS],
    }),
    startTrial: builder.mutation<TResponse<TPaginated<string>>, void>({
      query: () => ({
        url: '/plan/user/startTrial',
        method: 'PATCH',
      }),
      invalidatesTags: [EAppTagType.USER],
    }),
    getPlanShotInfo: builder.query<TResponse<number>, unknown>({
      query: () => ({
        url: '/plan/trial/shotInfo',
        method: 'GET',
      }),
      providesTags: [EAppTagType.TRANSACTIONS, EAppTagType.USER],
    }),
    getSoftPayProducts: builder.query<AutoPayProductList, unknown>({
      query: () => ({
        url: '/payment/softpay/product/list',
        method: 'GET',
      }),
      providesTags: [EAppTagType.SOFTPAYPRODUCTS],
    }),
    getSoftPayProductsInfo: builder.query<TResponse<{ amount: number; link: string }>, unknown>({
      query: () => ({
        url: '/payment/softpay/info',
        method: 'GET',
      }),
      providesTags: [EAppTagType.SOFTPAYPRODUCTS_INFO],
    }),

    cancelSoftPayOrder: builder.mutation<TResponse<unknown>, unknown>({
      query: () => ({
        url: `/payment/softpay/recurrent/cancel`,
        method: 'POST',
      }),
      invalidatesTags: [EAppTagType.SOFTPAYPRODUCTS_INFO],
    }),

    createSoftPayProducts: builder.mutation<UrlType, { link: string }>({
      query: ({ link }) => ({
        url: `/payment/softpay/order/${link}`,
        method: 'POST',
      }),
    }),
    purchasePlan: builder.mutation<TResponse<string>, PurchaseRequest>({
      query: body => ({
        url: '/payments/purchase',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.TRANSACTIONS],
    }),

    addPayeerAmount: builder.mutation<TResUrl, TPayeerAddForm & { curr: string }>({
      query: body => ({
        url: '/payment/payeer/form/generate',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.TRANSACTIONS, EAppTagType.USER],
    }),
    withdrawPayeerAmount: builder.mutation<
      TResponse<unknown, null>,
      TPayeerAddForm & { account: string; finPassword: string }
    >({
      query: body => ({
        url: '/payment/payeer/payout',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.TRANSACTIONS, EAppTagType.USER],
    }),
    addFreeKassaAmount: builder.mutation<TResUrl, TPayeerAddForm>({
      query: body => ({
        url: '/payment/freekassa/form/generate',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTagType.TRANSACTIONS, EAppTagType.USER],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFinancesQuery,
  useWithdrawPayeerAmountMutation,
  usePurchasePlanMutation,
  useGetSoftPayProductsInfoQuery,
  useCancelSoftPayOrderMutation,
  useGetSoftPayProductsQuery,
  useCreateSoftPayProductsMutation,
  useGetPlanShotInfoQuery,
  useStartTrialMutation,
  useAddPayeerAmountMutation,
  useAddFreeKassaAmountMutation,
} = financesApi;

export default financesApi;
