import { FC } from 'react';

import BotMessageCard from 'modules/telegram/components/BotMessageCard';

import styles from './GreetingBotMessages.module.scss';

const GreetingBotMessagges: FC = () => {
  return (
    <div className={styles.Messages}>
      <BotMessageCard />
      <BotMessageCard />
      <BotMessageCard />
      <BotMessageCard />
      <BotMessageCard />
      <BotMessageCard />
      <BotMessageCard />
    </div>
  );
};

export default GreetingBotMessagges;
