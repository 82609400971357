import { FC } from 'react';

import styles from './LinksList.module.scss';
import SocialNetwork from '../SocialNetwork';
import { Landing, LandingButton } from 'modules/landing-generator/landing-generator.types';

type Props = {
  id: Landing['id'];
  button: LandingButton;
};

const LinksList: FC<Props> = ({ id, button }) => {
  const commonProps = {
    id,
    btnId: button.id,
    orientation: button.orientation,
  };

  return (
    <div className={styles.Socials} data-orientation={button.orientation}>
      {button.vk && <SocialNetwork {...commonProps} type="vk" network={button.vk} />}
      {button.viber && <SocialNetwork {...commonProps} type="viber" network={button.viber} />}
      {button.whatsapp && (
        <SocialNetwork {...commonProps} type="whatsapp" network={button.whatsapp} />
      )}
      {button.instagram && (
        <SocialNetwork {...commonProps} type="instagram" network={button.instagram} />
      )}
      {button.facebook && (
        <SocialNetwork {...commonProps} type="facebook" network={button.facebook} />
      )}
      {button.telegram && (
        <SocialNetwork {...commonProps} type="telegram" network={button.telegram} />
      )}
    </div>
  );
};

export default LinksList;
