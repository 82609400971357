import get from 'lodash-es/get';
import classNames from 'classnames';

import styles from './TableGeneric.module.scss';
import { ComplexColumnResolved, TableProps } from './TableGeneric.types';
import { isEmpty } from 'lodash-es';
import { PhoneSvg } from './svg/phone';
import EmailSvg from './svg/email';
import CheckBox from 'components/CheckBox';
import { useEffect, useState } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import { setApplicationsIds } from 'modules/landing-dashboard/landing-dashboard.reducer';
import { useApplicationsSelectIds } from 'modules/landing-dashboard/landing-dashboard.selectors';
import { TableColumnSort } from './components/TableColumnSort';
import { TableColumnFilters } from './components/TableColumnFilters';
import { handleSelectCheckBox } from 'modules/landing-dashboard/landing-dashboard.helper';
import { NoPaymentHistoryBlock } from 'modules/finances/components/NoPaymentHistoryBlock/NoPaymentHistoryBlock';

interface BaseDataRow {
  isRead: boolean;
  id: string | number;
}
const TableGeneric = <Data extends Partial<BaseDataRow>>({
  columns,
  data,
  filters,
  sorts,
  className,
  withCheckbox,
  type,
}: TableProps<Data>) => {
  const applicationSelectIds = useApplicationsSelectIds();
  const [readAll, setReadAll] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isAllChecked = applicationSelectIds?.length === data?.length;

  const readAllApplications = () => {
    setReadAll(prev => !prev);
  };

  useEffect(() => {
    if (readAll) {
      const allIds: string[] | undefined = data?.map(item => {
        return Object(item).id;
      });

      dispatch(setApplicationsIds(allIds ?? []));
    } else {
      dispatch(setApplicationsIds([]));
    }
  }, [readAll]);

  return (
    <table
      className={classNames(styles.Table, styles.RoundedCorners, className)}
      data-no-data={isEmpty(data)}
    >
      <thead>
        <tr>
          {withCheckbox && (
            <th>
              <CheckBox
                checked={isAllChecked}
                type={'small-circle'}
                onChange={readAllApplications}
              />
            </th>
          )}
          {columns.map(column => {
            const resolved = column as ComplexColumnResolved<Data>;
            if (resolved.filters)
              return (
                <TableColumnFilters
                  key={column.header}
                  column={resolved as Required<typeof resolved>}
                  filters={filters}
                />
              );
            if (resolved?.sorts && data?.length)
              return (
                <TableColumnSort
                  key={column.header}
                  column={resolved as Required<typeof resolved>}
                  sorts={sorts}
                />
              );
            return <th key={resolved.header}>{resolved.header}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data?.map((row, index) => {
          const isRead = row.isRead;
          const id = row.id as string;
          const checkedItem = applicationSelectIds?.includes(id);
          return (
            <tr
              style={{
                background:
                  !isRead && !checkedItem && applicationSelectIds.length
                    ? 'rgba(52, 53, 55, 0.5)'
                    : '',
              }}
              key={index}
            >
              {withCheckbox && (
                <td>
                  <CheckBox
                    type={checkedItem && !isRead ? 'blue-circle' : 'small-circle'}
                    onChange={() => handleSelectCheckBox(id, applicationSelectIds, dispatch)}
                    checked={applicationSelectIds?.includes(id) || isRead}
                    disabled={isRead}
                  />
                </td>
              )}
              {columns.map(column => {
                const resolved = column as ComplexColumnResolved<Data>;

                return (
                  <td className={styles.td} key={resolved.accessor}>
                    {resolved.accessor === 'email' && <EmailSvg className={styles.svgIcon} />}
                    {resolved.accessor === 'phone' && <PhoneSvg className={styles.svgIcon} />}
                    {resolved.accessorFn ? resolved.accessorFn(row) : get(row, resolved.accessor)}
                  </td>
                );
              })}
            </tr>
          );
        })}

        {!data?.length && type === 'applications' && (
          <div>
            <h4 className={styles.NoApplications}>Заявки отсутствуют</h4>
          </div>
        )}
        {!data?.length && type === 'transactions' && <NoPaymentHistoryBlock />}
      </tbody>
    </table>
  );
};

export default TableGeneric;
