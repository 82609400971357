import { CSSProperties, FC } from 'react';

import styles from './Button.module.scss';
import {
  Button as TButton,
  Component,
  EClickAction,
  EditorMode,
} from 'modules/my-pages/my-pages.types';
import useOnClick from 'modules/my-pages/hooks/useOnClick';
import { ElemType } from 'modules/cards/cards.types';
import { useRegisterMetricsMutation } from 'modules/landing-dashboard/landing-dashboard.api';

type Props = {
  component: Component<TButton>;
  mode?: EditorMode;
  disabled?: boolean;
};

const Button: FC<Props> = ({ component, mode, disabled }) => {
  const { data } = component;
  const [registerMetric] = useRegisterMetricsMutation();
  const onClick = useOnClick(data.onClick);

  const borderColor = data.borderColor;

  const style: CSSProperties = {
    background: data.bgColor,
    border: `1px solid ${data.borderColor}`,
    fontWeight: data.properties?.bold ? 'bold' : 'normal',
    fontStyle: data.properties?.italic ? 'italic' : 'normal',
    textDecoration: data.properties?.underline ? 'underline' : 'none',
    color: borderColor === '#ffffff' ? '#003bc5' : data.borderColor,
    textAlign: data.properties?.alignment.toLowerCase() as 'start' | 'end',
  };

  return (
    <div className={styles.Wrapper} data-alignment={data?.alignment?.toLowerCase()}>
      <button
        className={styles.Button}
        style={style}
        data-block-style={data?.blockStyle?.toLowerCase()}
        data-has-click-handler={data.onClick.action !== EClickAction.NONE}
        data-text-alignment={data.properties?.alignment.toLowerCase()}
        onClick={async () => {
          if (mode === 'published')
            await registerMetric({ id: component.id as string, type: ElemType.BUTTON }).unwrap();
          if (mode === 'edit') {
            return null;
          } else {
            return onClick();
          }
        }}
        disabled={disabled}
      >
        {data.text}
      </button>
    </div>
  );
};

export default Button;
