import { FC } from 'react';
import styles from '../TransactionsTable.module.scss';
import TransactionType from '../../TransactionType';
import { ETransactionType } from '../../../finances.types';

type Props = {
  type?: ETransactionType | string;
  text: string;
};

const TransactionEntry: FC<Props> = ({ type, text }) => {
  return (
    <div className={styles.Entry}>
      {type && <TransactionType type={type as ETransactionType} />}
      <span className={styles.Text}>{text}</span>
    </div>
  );
};

export default TransactionEntry;
