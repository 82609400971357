import rootApi from 'config/http';

import { EAppTagType, EAppVKTagType, TResponse } from 'interfaces/api';
import { IRestorePasswordRequest, ISignIn, IAuthData } from 'modules/auth/auth.interfaces';
import {
  IInvitorRegistration,
  IInvitorRegistrationResponse,
  SignUp,
  ResetPasswordRequest,
} from './auth.types';

const authApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    signIn: builder.mutation<IAuthData, ISignIn>({
      query: body => ({ url: '/login', method: 'POST', body }),
      invalidatesTags: [...Object.values(EAppTagType), ...Object.values(EAppVKTagType)],
    }),
    createUser: builder.mutation<IAuthData, SignUp>({
      query: body => ({ url: '/register', method: 'POST', body }),
    }),
    getInvitor: builder.mutation<IInvitorRegistrationResponse, IInvitorRegistration>({
      query: params => ({ url: '/users/short-info', method: 'GET', params }),
    }),
    restorePassword: builder.mutation<{ status: boolean }, IRestorePasswordRequest>({
      query: body => ({ url: '/users/reset-password/confirm', method: 'PATCH', body }),
    }),
    resetPassword: builder.mutation<{ status: boolean }, ResetPasswordRequest>({
      query: body => ({ url: '/users/reset-password', method: 'PATCH', body }),
    }),
    signOutRequest: builder.mutation<TResponse<string>, void>({
      query: body => ({ url: '/logout', method: 'POST', body }),
      // invalidatesTags: [...Object.values(EAppTagType), ...Object.values(EAppVKTagType)],
    }),

    signOut: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInMutation,
  useRestorePasswordMutation,
  useResetPasswordMutation,
  useCreateUserMutation,
  useSignOutMutation,
  useGetInvitorMutation,
  useSignOutRequestMutation,
} = authApi;

export default authApi;
