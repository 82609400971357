import { FC } from 'react';

import TopUpMethod from '../TopUpMethod';
import MethodsSection from '../MethodsSection';
import payeer from 'assets/svg/finances/Payeer.svg';
import freeKassa from 'assets/svg/finances/FreeKassa.svg';
import { useOpenModals } from '../../finances.utils';
import { Sources } from '../../finances.types';

const TopUpMethods: FC = () => {
  const { onOpenCurrModalBuy } = useOpenModals();

  return (
    <MethodsSection title="Пополнить баланс">
      <TopUpMethod photo={freeKassa} onClick={() => onOpenCurrModalBuy(Sources.FREE_KASSA)} />
      <TopUpMethod photo={payeer} onClick={() => onOpenCurrModalBuy(Sources.PAYEER)} />
    </MethodsSection>
  );
};

export default TopUpMethods;
