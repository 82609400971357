import { FC, useId } from 'react';

import styles from './Input.module.scss';

type Props = {
  label: string;
  required?: boolean;
};

const Input: FC<Props> = ({ label, required }) => {
  const id = useId();

  return (
    <div className={styles.Wrapper}>
      <label className={styles.Label} htmlFor={id} data-required={required}>
        {label}
      </label>
      <input className={styles.Input} id={id} />
    </div>
  );
};

export default Input;
