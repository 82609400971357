import { EPlanName } from 'modules/account/account.interfaces';

export enum ETransactionStatus {
  COMPLETED = 'SUCCESS',
  FAILED = 'ERROR',
  IN_PROCESS = 'IN_PROCESS',
  ON_VERIFICATION = 'ON_VERIFICATION',
  CANCELLED = 'CANCELLED',
}

export enum ETransactionType {
  INPUT = 'DEPOSIT_PAYEER',
  OUTPUT = 'WITHDRAWAL_PAYEER',
  INPUT_FREE_KASSA = 'DEPOSIT_FREE_KASSA',
  OUTPUT_FREE_KASSA = 'WITHDRAWAL_FREE_KASSA',
  PURCHASE = 'PLAN_PURCHASE',
  PAYOUTS = 'REFERRAL_DEPOSIT',
  MODIFICATION = 'ADMIN_BALANCE_MODIFICATION',
  DEPOSIT_TAX = 'DEPOSIT_TAX',
  WITHDRAWAL_TAX = 'WITHDRAWAL_TAX',
  DEPOSIT_SOFTPAY = 'DEPOSIT_SOFTPAY',
}

export enum Sources {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  QIWI = 'qiwi',
  PAYEER = 'payeer',
  FREE_KASSA = 'free_kassa',
}

export type UserTransaction = {
  id: number;
  type: ETransactionType;
  createdAt: string;
  amount: number;
  status: ETransactionStatus;
  description: string;
  from: string;
  to: string;
  updatedAt: string;
};

export type UserTransactionExtended = UserTransaction & {
  time: string;
};

export type FinPassModalTypes = {
  finPass: string;
};

export type WithdrawModalTypes = {
  account: string;
  amount: number;
  type?: string;
};

export enum FinanceModals {
  FIN_PASS_BUY,
  FIN_PASS_WITHDRAW,
  BUY,
  CHANGE_SOURCE,
}

export type PurchaseRequest = {
  plan: EPlanName;
  planCycle: string;
};

export type TransactionRequest = {
  type?: ETransactionType;
  status?: ETransactionStatus;
};

export type TPayeerAddForm = {
  amount: string;
};

export type TResUrl = {
  data: {
    body: {
      url: string;
    };
  };
};

export type BodyType = TResUrl['data'];
export type UrlType = BodyType['body'];

export type AutoPayProduct = {
  name: string;
  description: string;
  price: {
    inRussia: number;
    outRussia: number | null;
    crypto: number | null;
  };
  link: string;
};

export type AutoPayProductList = {
  products: AutoPayProduct[];
};
