interface EmailSvgProps {
  className?: string;
}

const EmailSvg = ({ className }: EmailSvgProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.1"
        width="24"
        height="24"
        rx="12"
        transform="matrix(-1 0 0 1 24 0.5)"
        fill="#216FE0"
      />
      <path
        d="M15.3488 17.1512H6.4186C6.26901 17.1512 6.13082 17.0714 6.05606 16.9419C5.98131 16.8124 5.98131 16.6528 6.05606 16.5233C6.13082 16.3938 6.26901 16.314 6.4186 16.314H15.3488C15.8295 16.3126 16.2901 16.1209 16.6299 15.7811C16.9697 15.4413 17.1614 14.9807 17.1628 14.5V11.1512C17.1614 10.6705 16.9697 10.2099 16.6299 9.87009C16.2901 9.53026 15.8295 9.3386 15.3488 9.33722H9.76747C9.28681 9.33862 8.82625 9.53026 8.48641 9.87009C8.14648 10.2099 7.95492 10.6705 7.95344 11.1512V11.7093C7.95344 11.8589 7.87364 11.9971 7.74413 12.0718C7.61463 12.1466 7.45503 12.1466 7.32552 12.0718C7.19602 11.9971 7.11622 11.8589 7.11622 11.7093V11.1512C7.11622 10.448 7.39561 9.77367 7.8928 9.27648C8.38999 8.7793 9.06435 8.5 9.76747 8.5H15.3488C16.052 8.5 16.7263 8.7793 17.2235 9.27648C17.7207 9.77367 18 10.448 18 11.1512V14.5C18 15.2032 17.7207 15.8775 17.2235 16.3747C16.7263 16.8719 16.052 17.1512 15.3488 17.1512Z"
        fill="#1E60C0"
      />
      <path
        d="M9.20917 15.4767H6.4186C6.26901 15.4767 6.13082 15.3969 6.05606 15.2674C5.98131 15.1379 5.98131 14.9783 6.05606 14.8488C6.13082 14.7193 6.26901 14.6395 6.4186 14.6395H9.20917C9.35877 14.6395 9.49696 14.7193 9.57171 14.8488C9.64646 14.9783 9.64646 15.1379 9.57171 15.2674C9.49696 15.3969 9.35876 15.4767 9.20917 15.4767Z"
        fill="#1E60C0"
      />
      <path
        d="M9.20917 13.8023H6.4186C6.26901 13.8023 6.13082 13.7225 6.05606 13.593C5.98131 13.4635 5.98131 13.3039 6.05606 13.1744C6.13082 13.0449 6.26901 12.9651 6.4186 12.9651H9.20917C9.35877 12.9651 9.49696 13.0449 9.57171 13.1744C9.64646 13.3039 9.64646 13.4635 9.57171 13.593C9.49696 13.7225 9.35876 13.8023 9.20917 13.8023Z"
        fill="#1E60C0"
      />
      <path
        d="M12.5581 13.2443C11.9678 13.2453 11.3941 13.0488 10.9283 12.6862L9.02502 11.207C8.91906 11.1117 8.86898 10.969 8.89197 10.8283C8.91495 10.6877 9.00802 10.5684 9.13873 10.5117C9.26955 10.4551 9.42018 10.4689 9.53856 10.5485L11.4418 12.0275C11.7615 12.2747 12.154 12.4088 12.558 12.4088C12.9621 12.4088 13.3547 12.2747 13.6744 12.0275L15.5776 10.5485C15.664 10.4707 15.7787 10.4324 15.8944 10.4426C16.0102 10.4528 16.1164 10.5106 16.1878 10.6022C16.2592 10.6938 16.2894 10.8109 16.271 10.9257C16.2527 11.0403 16.1875 11.1423 16.0912 11.207L14.1879 12.6861L14.1878 12.6862C13.7221 13.0488 13.1484 13.2453 12.558 13.2443L12.5581 13.2443Z"
        fill="#1E60C0"
      />
    </svg>
  );
};

export default EmailSvg;
