import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import isObjectLike from 'lodash-es/isObjectLike';
import toast from 'toasts';
import r from '../constants/routes';
import { WelcomeBotError } from '../modules/telegram/telegram.types';

const rtkQueryErrorLogger: Middleware = () => next => action => {
  if (isRejectedWithValue(action)) {
    const payloadData = action.payload.data;
    let msg = 'Не удалось подключиться к серверу';
    if (window.location.pathname === r.auth.signIn) return;

    if (isObjectLike(payloadData)) {
      msg = 'Произошла неизвестная ошибка.';

      // TODO: Use relevant keys
      if ('error_description' in payloadData) {
        msg = payloadData.error_description;
      } else if ('type' in payloadData) {
        if (new Set(Object.values(WelcomeBotError)).has(payloadData.type)) return next(action);
      } else if ('message' in payloadData) {
        msg = payloadData.message;
      } else if ('body' in payloadData) {
        msg = payloadData.body;
      } else if ('description' in payloadData) {
        msg = payloadData.description;
      }
    }

    toast.error(msg);
  }

  return next(action);
};

export default rtkQueryErrorLogger;
