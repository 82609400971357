import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import isUndefined from 'lodash-es/isUndefined';

import styles from './FormPageTemplate.module.scss';
import Button from 'components/Button';
import GoBack from 'components/GoBack';
import Title from 'components/Title';

type Props = {
  title: string;
  description?: string;
  children: ReactNode;
  onSubmit: () => void;
  isValid: boolean;
  buttonText?: string;
  short?: boolean;
  className?: string;
  headerAddon?: JSX.Element;
};

const FormPageTemplate: FC<Props> = ({
  title,
  description,
  children,
  isValid,
  onSubmit,
  short,
  className,
  headerAddon,
  buttonText = 'Создать',
}) => {
  return (
    <div className={styles.Page}>
      <header className={styles.Header} data-short={short}>
        <GoBack backWordClassName={styles.GoBack} />
        {headerAddon}
      </header>

      <section className={classNames(styles.Content, className)} data-short={short}>
        <Title className={styles.Title} data-no-description={isUndefined(description)}>
          {title}
        </Title>
        {!isUndefined(description) && <p className={styles.Text}>{description}</p>}

        <form onSubmit={onSubmit} className={styles.Form}>
          <div className={styles.FormFields}>{children}</div>

          <div className={styles.ButtonWrapper}>
            <Button
              className={styles.Button}
              variant="magic"
              size="l"
              type="submit"
              disabled={!isValid}
            >
              {buttonText}
            </Button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default FormPageTemplate;
