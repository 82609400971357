import { ChangeEvent, FC, useState, useRef, useEffect } from 'react';
import first from 'lodash-es/first';
import classNames from 'classnames';

import styles from './ImgInput.module.scss';
import kbToMbConverter from 'utils/kbToMbConverter';
import ImgInputContent from './components/ImgInputContent';
import ImagesList from './components/ImagesList';
import ImgInputText from './components/ImgInputText';

export type ImgInputProps = {
  setFile: (file?: File) => void;
  limit?: number;
  defaultFile?: File;
  noSizeLimit?: boolean;
  hasRecommendedSize?: boolean;
};

export type ImageFileType = {
  name: string;
  img: string;
  size: number;
};

const ImgInput: FC<ImgInputProps> = ({
  setFile,
  limit = 1,
  defaultFile,
  noSizeLimit,
  hasRecommendedSize,
}) => {
  const [images, setImages] = useState<ImageFileType[]>([]);
  const [isError, setIsError] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleReUpload = async (file: File, isEffect?: boolean) => {
    if (inputRef.current) inputRef.current.value = '';
    if (!file) return;

    const sizeImageInMb = kbToMbConverter(file.size);

    if (sizeImageInMb > 2.5 && !noSizeLimit) {
      setIsError(true);
      setTimeout(() => setIsError(false), 2500);
      return;
    }

    const imagesCopy = [...images];

    if (images.length >= limit) imagesCopy.splice(0, 1);

    imagesCopy.push({
      name: file.name,
      img: URL.createObjectURL(file),
      size: sizeImageInMb,
    });
    if (!isEffect) {
      setFile(file);
    }
    setImages(imagesCopy);
  };

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = first(e.target.files);
    if (file) handleReUpload(file);
  };

  useEffect(() => {
    if (defaultFile) {
      handleReUpload(defaultFile, true);
    }
  }, [defaultFile]);

  const deleteImg = (key: number) => {
    setImages(images.filter((el, index) => index !== key));
    setFile();
  };

  return (
    <div
      className={classNames(styles.Wrapper, {
        [styles.Error]: isError,
      })}
    >
      <input
        className={styles.Input}
        onChange={handleUpload}
        type={'file'}
        accept="image/*"
        ref={inputRef}
      />

      <div className={styles.Content}>
        <ImgInputContent hasRecommendedSize={hasRecommendedSize} isError={isError} />
      </div>

      <ImagesList images={images} deleteImg={deleteImg} noSizeLimit={noSizeLimit} />

      {images.length > 0 && <div className={styles.Line}></div>}

      {!noSizeLimit && <ImgInputText isError={isError} />}
    </div>
  );
};

export default ImgInput;
