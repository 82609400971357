import { FC } from 'react';

import styles from './Link.module.scss';
import { LandingButton, LandingIdType } from 'modules/landing-generator/landing-generator.types';
import LinksList from './components/LinksList';
import Website from './components/Website';
import Modalable from '../Modalable';
import {
  removeLandingModalButton,
  setLandingModalButtonColor,
} from 'modules/landing-generator/landing-generator.reducer';
import useAppDispatch from 'hooks/useAppDispatch';

type Props = {
  id: LandingIdType;
  button: LandingButton;
};

const Link: FC<Props> = ({ id, button }) => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.Wrapper}>
      <Modalable
        button={button.modal}
        onSetColor={color => dispatch(setLandingModalButtonColor({ id, color, btnId: button.id }))}
        onRemoveBtn={() => dispatch(removeLandingModalButton({ id, btnId: button.id }))}
      >
        <LinksList id={id} button={button} />
      </Modalable>

      {button.websites.map((ws, index) => (
        <Website key={index} id={id} index={index} btnId={button.id} website={ws} />
      ))}
    </div>
  );
};

export default Link;
