import { FC } from 'react';

import styles from './PreviewHeader.module.scss';
import GoBack from 'components/GoBack';
import TextMenu from 'components/TextMenu';
import Button from 'components/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { usePreviewOrientation } from 'modules/my-pages/my-pages.selectors';
import { EOrientation } from 'modules/my-pages/my-pages.types';
import { setPreviewOrientation } from 'modules/my-pages/my-pages.reducer';
import PublishModal from '../PublishModal';
import useDisclosure from 'hooks/useDisclosure';
import { useParams } from 'react-router-dom';
import { useGetPageQuery } from 'modules/landing-dashboard/landing-dashboard.api';

const PreviewHeader: FC = ({}) => {
  const dispatch = useAppDispatch();
  const orientation = usePreviewOrientation();
  const { id } = useParams();
  const { data } = useGetPageQuery({ id: id as string });

  const {
    open: publishOpen,
    onOpen: onPublishOpen,
    onClose: onPublishClose,
  } = useDisclosure({ blockScroll: true });

  const isPublished = data?.body?.published;

  return (
    <>
      <header className={styles.Header}>
        <GoBack backWord="Закрыть" staticPos />

        <div className={styles.Part}>
          <TextMenu
            label="Ориентация"
            items={[
              {
                label: 'Вертикальная',
                isSelected: orientation === EOrientation.VERTICAL,
                onClick: () => dispatch(setPreviewOrientation(EOrientation.VERTICAL)),
              },
              {
                label: 'Горизонтальная',
                isSelected: orientation === EOrientation.HORIZONTAL,
                onClick: () => dispatch(setPreviewOrientation(EOrientation.HORIZONTAL)),
              },
            ]}
          />
          <Button className={styles.PublishBtn} variant="primary" onClick={onPublishOpen}>
            {isPublished ? 'Сохранить' : 'Опубликовать'}
          </Button>
        </div>
      </header>

      <PublishModal
        isOpen={publishOpen}
        onClose={onPublishClose}
        isPublished={isPublished}
        currentLink={data?.body.link}
      />
    </>
  );
};

export default PreviewHeader;
