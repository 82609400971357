import { Route } from 'react-router-dom';

import r from 'constants/routes';
import Notifications from './pages/Notifications';

function NotificationsRoutes(): JSX.Element {
  return (
    <>
      <Route path={r.notifications} element={<Notifications />} />
    </>
  );
}

export default NotificationsRoutes;
