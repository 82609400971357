import { FC, useLayoutEffect } from 'react';
import styles from './SentMessages.module.scss';
import SentMessageCard from '../SentMessageCard';
import { useGetAdminSentMessagesQuery } from '../../codegen/telegram.admin.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useBotId } from '../../telegram.hooks';
import isUndefined from 'lodash-es/isUndefined';
import { useSentMessagesAdminParams } from '../../telegram.selectors';
import { urlOffset } from '../../telegram.utils';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { setSentMessagesPageParams } from 'modules/telegram/codegen/telegram.admin.reducer';
import Pagination from 'components/Pagination';
import LoadingState from 'components/LoadingState';
import SentMessagesWrapper from '../SentMessagesWrapper';
import EmptyText from '../EmptyText';

const SentMessages: FC = () => {
  const { chatId } = useParams();
  const params = useSentMessagesAdminParams();
  const dispatch = useAppDispatch();
  const botId = useBotId();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const isParamsMatch = params.offset === +urlOffset(queryParams.get('offsetSent'));

  const { data, isFetching } = useGetAdminSentMessagesQuery(
    { botId, chatId: String(chatId), ...params },
    { skip: isUndefined(chatId) || !isParamsMatch, refetchOnMountOrArgChange: true },
  );

  useLayoutEffect(() => {
    const offset = urlOffset(queryParams.get('offsetSent'));
    dispatch(setSentMessagesPageParams({ offset }));
  }, []);

  const pageChange = (page: number) => {
    let offset;
    queryParams.set('pageSent', `${page}`);
    if (page === 1) {
      offset = 0;
      queryParams.set('offsetSent', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setSentMessagesPageParams({ offset }));
    } else {
      offset = (page - 1) * 5;
      queryParams.set('offsetSent', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setSentMessagesPageParams({ offset }));
    }
  };

  return (
    <div className={styles.Root}>
      <h1 className={styles.Title}>Отправленные сообщения</h1>

      {data?.count === 0 ? (
        <EmptyText text={'У вас пока нет отправленных сообщений'} />
      ) : (
        <SentMessagesWrapper>
          {isFetching ? (
            <LoadingState />
          ) : (
            <>
              {data?.data?.map(item => (
                <SentMessageCard key={item.createdAt + item.text} {...item} />
              ))}
            </>
          )}
        </SentMessagesWrapper>
      )}

      <Pagination
        onPageChange={pageChange}
        offset={1}
        totalCount={data?.count ? Math.ceil(data?.count / 5) : 0}
        currPage={Number(queryParams.get('pageSent'))}
      />
    </div>
  );
};

export default SentMessages;
