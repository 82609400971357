import { FC, useState } from 'react';
import LandingSelector from '../LandingSelector';
import FormComponentSelector from '../FormComponentSelector';
import { StatusSelector } from '../StatusSelector/StatusSelector';
import { ModalWithoutForm } from 'components/ModalWithoutForm/ModalWithoutForm';
import styles from './MobileFilter.module.scss';
import { FilterIcon } from 'components/ModalWithoutForm/svg/FilterIcon';
import { MobileFilterTypes } from './MobileFilter.types';
import { PeriodComponentSelector } from '../PeriodComponentSelector/PeriodComponentSelector';
import { EPeriodType } from '../../landing-dashboard.types';

export const MobileFilter: FC<MobileFilterTypes> = ({
  statusParams,
  statusSelect,
  landingSelect,
  formSelect,
  closeFilter,
  type = 'applications' || 'statistics',
  periodType,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onModalClose = () => {
    setIsOpen(false);
  };

  const handleCloseFilter = () => {
    closeFilter();
    onModalClose();
  };

  const modalType = type === 'applications' ? 'заявок' : 'cтатистики';

  return (
    <div className={styles.FilterButtonWrapper} onClick={() => setIsOpen(true)}>
      <FilterIcon />
      <ModalWithoutForm
        modalTitle={`Настройки Фильтрации ${modalType}`}
        isOpen={isOpen}
        onClose={handleCloseFilter}
        isValid
        onClick={onModalClose}
      >
        <LandingSelector
          type={'mobile'}
          isLabel
          label={'Страница'}
          landingSelectorClick={landingSelect}
        />
        {type === 'applications' && (
          <>
            <FormComponentSelector
              type={'mobile'}
              isLabel
              label={'Элемент'}
              selectFormClick={formSelect as (id: number) => void}
            />
            <StatusSelector
              selected={statusParams as string}
              setSelected={statusSelect as (value: string) => void}
              type={'mobile'}
              isLabel
              label={'Показывать'}
            />
          </>
        )}
        {type === 'statistics' && (
          <PeriodComponentSelector period={periodType as EPeriodType} type={'mobile'} isLabel />
        )}
      </ModalWithoutForm>
    </div>
  );
};
