import { createRoot } from 'react-dom/client';

const confirm = (
  modal: (handleConfirm: (confirms: boolean) => void) => JSX.Element,
): Promise<boolean> => {
  return new Promise<boolean>(resolve => {
    let container: HTMLDivElement | null = document.createElement('div');
    document.body.appendChild(container);
    const root = createRoot(container);

    const handleResolve = (result: boolean) => {
      if (container) {
        root.unmount();
      }

      container = null;
      resolve(result);
    };

    root.render(modal(handleResolve));
  });
};

export default confirm;
