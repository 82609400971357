import { FC } from 'react';
import isEmpty from 'lodash-es/isEmpty';

import InputWithMultipleChoices from 'components/InputWithMultipleChoices';
import { InputProps } from 'components/Input';

type Props = {
  value: string[];
  setValue: (name: 'geolocation', value: string[], options: { shouldValidate: boolean }) => void;
  inputProps?: Omit<InputProps, 'label'>;
};

const GeolocationInput: FC<Props> = ({ value, setValue, inputProps }) => {
  return (
    <InputWithMultipleChoices
      addChoice={choice => setValue('geolocation', [...value, choice], { shouldValidate: true })}
      choicesProps={{
        choices: value,
        deleteChoice: choice =>
          setValue(
            'geolocation',
            value.filter(gl => gl !== choice),
            { shouldValidate: true },
          ),
      }}
      inputProps={{
        label: 'Населенный пункт',
        variant: 'dark',
        example: isEmpty(value) ? 'Пример: Россия, Москва' : undefined,
        ...inputProps,
      }}
    />
  );
};

export default GeolocationInput;
