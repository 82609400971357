import { FC } from 'react';

import styles from './Messages.module.scss';

import NoMessages from '../NoMessages';
import Message from '../Message';
import PaginationFlexEnd from 'components/PaginationFlexEnd';
import { useGetMailQuery } from 'modules/notifications/notifications.api';
import { NotificationTypes } from 'modules/notifications/notifications.types';

const Messages: FC = () => {
  const { data, isError, isLoading } = useGetMailQuery({
    type: NotificationTypes.MESSAGE,
    limit: 25,
  });

  if (isError) return null;
  if (isLoading) return null;
  if (data?.body.items.length === 0) return <NoMessages />;
  return (
    <>
      <div className={styles.Messages}>
        {data?.body.items.map(message => (
          <Message key={message.id} {...message} />
        ))}
      </div>
      <PaginationFlexEnd />
    </>
  );
};

export default Messages;
