import { FC, useMemo } from 'react';

import Button from 'components/Button';
import EmptyPage from 'components/EmptyPage';
import imageImg from 'assets/svg/empty-pages/image.svg';
import useDisclosure from 'hooks/useDisclosure';
import PostCard from 'modules/vk/components/PostCard';
import PostModalStory from 'modules/vk/components/PostModalStory';
import AutomationPageLayout from 'modules/vk/components/AutomationPageLayout';
import { useGetAllAutoStoriesQuery, useGetFilesFromVkQuery } from 'modules/vk/vk.api';
import { isEmpty } from 'lodash-es';
import { useGetAllVkAccounts } from '../../vk.selectors';
import { combinedPosts, getFileIds } from '../../vk.utils';
import isUndefined from 'lodash-es/isUndefined';
import LoadingState from '../../components/LoadingState';

const AutoStories: FC = () => {
  const { data: autoStories } = useGetAllAutoStoriesQuery();
  const ids: string[] = useMemo(() => {
    if (autoStories) return getFileIds(autoStories);
  }, [autoStories]) as string[];

  const { data: files, isFetching: areFilesLoading } = useGetFilesFromVkQuery(
    { ids },
    { skip: isUndefined(ids) },
  );

  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });
  const vkAccounts = useGetAllVkAccounts();

  const combinedData = useMemo(() => {
    if (autoStories && files) return combinedPosts(autoStories, files);
  }, [autoStories, files]);

  if (areFilesLoading) return <LoadingState />;
  if (isEmpty(vkAccounts)) return null;

  return (
    <>
      <AutomationPageLayout
        title="Авто-сториз"
        addBtnText={'Новая сториз'}
        subTitle={'Не более 10 историй в сутки'}
        emptyPage={
          <EmptyPage
            img={<img src={imageImg} width={205} height={205} alt="Image" />}
            title="Добавь свою первую сториз"
            text="В несколько шагов"
            btnText="Добавить сториз"
            onClick={onOpen}
          />
        }
        cards={combinedData?.map(post => {
          if (post?.id) {
            return (
              <PostCard
                key={post?.id}
                id={+post.id}
                img={post?.s3Url}
                date={post?.date}
                time={post?.time}
                type="story"
                fileType={post?.type}
              />
            );
          }
        })}
        onAdd={onOpen}
        navAddon={
          <Button
            variant="primary"
            onClick={onOpen}
            disabled={!isUndefined(combinedData) && combinedData?.length >= 50}
          >
            Новая сториз
          </Button>
        }
        dontChangeHeaderDirectionOnMobile
      />
      {open && <PostModalStory isOpen={open} onClose={onClose} />}
    </>
  );
};

export default AutoStories;
