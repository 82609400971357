import useTypedSelector from 'hooks/useAppSelector';

export const useSelectedThematicGroups = (): number[] =>
  useTypedSelector(state => state.vk.thematicGroups.selected);

export const useGetAllVkAccounts = () => useTypedSelector(state => state.vk.accounts);

export const useGetSettingsList = () => useTypedSelector(state => state.vk.settingsList);
export const useGetAutomationSettingsList = () =>
  useTypedSelector(state => state.vk.vkAutomationSettings);

export const useGetVkGroupParams = () => useTypedSelector(state => state.vk.userGroupParams);
export const useParseFolderSearchParams = () =>
  useTypedSelector(state => state.vk.parseFolderSearchParams);
export const useGetVkGroups = () => useTypedSelector(state => state.vk.userGroups);
export const useGetVkSelectedGroupsId = () =>
  useTypedSelector(state => state.vkSelectedGroups.selectedGroups);
export const useGetVkSelectedGroupsSubscribersCount = () =>
  useTypedSelector(state => state.vkSelectedGroups.subscribersCount);

export const useGetVkParsedFolderAutomationSettings = () =>
  useTypedSelector(state => state.vk.groupFolderSettingAuto);

export const useGetCurrentFolderData = () => useTypedSelector(state => state.vk.currentGroupFolder);
export const useGetCurrentTimerState = () =>
  useTypedSelector(state => state.vkSelectedGroups.downloadFiveMinDelay);
