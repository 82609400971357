import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormPageTemplate from 'components/FormPageTemplate';
import { VkMailingRequestBody } from 'modules/vk/vk.types';
import { vkMailingFormSchema } from 'modules/vk/vk.schema';
import showFeedback from 'components/FeedbackModal/showFeedback';
import { EFeedbackType } from 'components/FeedbackModal';
import Input from 'components/Input';
import InputWithDropdown from 'components/InputWithDropdown';
import TextAreaWithImgInput from 'components/TextAreaWithImgInput';
import ImgInput from 'components/ImgInput';

const MailingEdit: FC = () => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<VkMailingRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(vkMailingFormSchema()),
    defaultValues: { photos: [] },
  });

  const onSubmit: SubmitHandler<VkMailingRequestBody> = async () => {
    await showFeedback({
      title: 'Готово',
      text: 'Теперь бот будет писать сообщения за вас',
      type: EFeedbackType.SUCCESS,
    });
  };

  const values = watch();

  return (
    <FormPageTemplate
      title="Рассылки"
      description="Заполните поля для начала рассылки"
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
      short
    >
      <Input
        label="Название рассылки"
        register={register('name')}
        invalid={!!errors.name?.message}
        errorMsg={errors.name?.message}
        variant="dark"
      />
      <InputWithDropdown label="Выбор аккаунта" options={[]} variant="dark" />
      <TextAreaWithImgInput
        textareaProps={{ label: 'Текст рассылки', register: register('text'), variant: 'dark' }}
        modalWithImgInputProps={{
          images: values.photos,
          setImages: images => setValue('photos', images, { shouldValidate: true }),
        }}
      />
      <ImgInput setFile={file => setValue('file', file, { shouldValidate: true })} />
      <Input
        label="Кол-во сообщений в день"
        register={register('messagesCount')}
        type="number"
        invalid={!!errors.messagesCount?.message}
        errorMsg={errors.messagesCount?.message}
        variant="dark"
      />
    </FormPageTemplate>
  );
};

export default MailingEdit;
