import { FC, ReactNode } from 'react';

import styles from './MethodsSection.module.scss';
import Title from 'components/Title';

type Props = {
  title: string;
  children: ReactNode;
};

const MethodsSection: FC<Props> = ({ title, children }) => {
  return (
    <section className={styles.Section}>
      <Title className={styles.Title}>{title}</Title>
      <div className={styles.Grid}>{children}</div>
    </section>
  );
};

export default MethodsSection;
