import styles from './CheckboxSelect.module.scss';
import CheckBox from 'components/CheckBox';
export type CheckboxOption<T> = {
  text: string;
  value: T;
  contentIfSelected?: JSX.Element;
  disabled?: boolean;
};

type Props<T> = {
  title: string;
  selectValue: (value: T) => void;
  selected: T;
  options: CheckboxOption<T>[];
};

const CheckboxSelect = <T extends string | number | boolean>({
  title,
  selected,
  selectValue,
  options,
}: Props<T>) => {
  return (
    <div className={styles.Wrapper}>
      <h4 className={styles.Title}>{title}</h4>

      <div className={styles.Content}>
        <div className={styles.Options}>
          {options.map(option => (
            <div key={option.text}>
              <CheckBox
                checked={option.value === selected}
                onChange={() => selectValue(option.value)}
                text={option.text}
                type="point"
              />
              {option.contentIfSelected && option.value === selected && option.contentIfSelected}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheckboxSelect;
