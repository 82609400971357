import { FC } from 'react';

import styles from './TextEditorMenu.module.scss';
import IconButton from 'components/IconButton';
import ContextMenu from 'components/ContextMenu';
import classNames from 'classnames';
import useDisclosure from 'hooks/useDisclosure';
// import ColorPicker from 'components/ColorPicker';
// import { rgba2string, stringToRgba } from 'utils/colorConverters';
import { TextAlignment } from 'modules/landing-generator/landing-generator.types';

export type TextEditorMenuHandlers = {
  onToggleBold: () => void;
  onToggleItalic: () => void;
  onToggleUnderline: () => void;
  onSetColor: (color: string) => void;
  onAlign: (alignment: TextAlignment) => void;
};

type Props = {
  open: boolean;
  onClose: () => void;
  handlers: TextEditorMenuHandlers;
  color?: string;
  className?: string;
};

const TextEditorMenu: FC<Props> = ({ open, onClose, handlers, className }) => {
  const { toggleOpen: toggleColorOpen, onClose: onColorClose } = useDisclosure();

  return (
    <ContextMenu
      open={open}
      onClose={() => {
        onClose();
        onColorClose();
      }}
      className={classNames(styles.Nav, className)}
    >
      <div className={styles.Options}>
        <IconButton iconName="bold" onClick={handlers.onToggleBold} iconSize={24} />
        <IconButton iconName="italic" onClick={handlers.onToggleItalic} iconSize={24} />
        <IconButton iconName="underline" onClick={handlers.onToggleUnderline} iconSize={24} />
        <IconButton iconName="text-color" onClick={toggleColorOpen} iconSize={24} />
      </div>

      <div className={styles.Options}>
        <IconButton iconName="align-left" onClick={() => handlers.onAlign('left')} iconSize={24} />
        <IconButton
          iconName="align-center"
          onClick={() => handlers.onAlign('center')}
          iconSize={24}
        />
        <IconButton
          iconName="align-right"
          onClick={() => handlers.onAlign('right')}
          iconSize={24}
        />
      </div>

      {/* {colorOpen && (
        <div className={styles.ColorPicker}>
          <ColorPicker
            color={stringToRgba(color)}
            setColor={nextColor => handlers.onSetColor(rgba2string(nextColor))}
          />
        </div>
      )} */}
    </ContextMenu>
  );
};

export default TextEditorMenu;
