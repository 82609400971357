import { FC, ReactNode, useState } from 'react';
import styles from './MainVkCardWithTabs.module.scss';
import classNames from 'classnames';
import { Inbox, Outbox } from './InboxOutbox';

type Props = {
  inputChildren: ReactNode;
  outputChildren: ReactNode;
};

const MainVkCardWithTabs: FC<Props> = ({ inputChildren, outputChildren }) => {
  const [isRightActive, setIsRightActive] = useState(false);
  const children = isRightActive ? outputChildren : inputChildren;
  const tabClickHandler = () => setIsRightActive(prev => !prev);

  return (
    <div className={styles.Root}>
      <div className={styles.Tabs}>
        <div
          className={classNames(styles.Tab, {
            [styles.IsTabActive]: !isRightActive,
          })}
          onClick={tabClickHandler}
        >
          <div className={styles.Inner}>
            <Inbox color={isRightActive ? '#70707C' : '#1ED731'} />
            <span className={styles.Span}>Входящие</span>
          </div>
        </div>
        <div
          className={classNames(styles.Tab, {
            [styles.IsTabActive]: isRightActive,
          })}
          onClick={tabClickHandler}
        >
          <div className={styles.Inner}>
            <Outbox color={isRightActive ? '#1ED731' : '#70707C'} />
            <span className={styles.Span}>Исходящие</span>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.InnerCardContainer, {
          [styles.IsRightActive]: isRightActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default MainVkCardWithTabs;
