import { FC } from 'react';
import styles from './ParsingCard.module.scss';
import ColorfulBorder from 'components/ColorfulBorder';
import classNames from 'classnames';
import Button from 'components/Button';
import dayjs from 'dayjs';

import { TParsedFolder } from '../../../vk.types';
import { useDeleteParsingMutation, useStopParsingMutation } from '../../../vk.api';
import DownloadCsvBtn from './DownloadCsvBtn';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import r from 'constants/routes';

const ParsingCard: FC<TParsedFolder> = ({
  name,
  countParsedMembers,
  countMembers,
  status,
  createdAt,
  id,
}) => {
  const { id: groupId } = useParams();
  const navigate = useNavigate();
  const [deleteParsing] = useDeleteParsingMutation();
  const [stopParsing] = useStopParsingMutation();

  const statusMap = Object.freeze({
    progress: 'В работе.',
    done: 'Выполнено.',
    error: 'Ошибка.',
    indentSequence: 'В очереди.',
  });

  const onSettingsClickHandler = () => {
    navigate(generatePath(r.vk.groupAutomation, { id: groupId + '', parsingId: id + '' }));
  };

  return (
    <ColorfulBorder
      className={classNames(styles.Root, {
        [styles.None]: !(statusMap.progress === status),
      })}
    >
      <div className={styles.InnerContainer}>
        <div className={styles.DateTime}>
          <p className={styles.Par}>{dayjs(createdAt).format('DD.MM.YYYY')}</p>
          <p className={styles.Par}>{dayjs(createdAt).format('HH:mm')}</p>
        </div>
        <h2 className={styles.Title}>{name}</h2>
        <hr className={styles.Divider} />
        <div className={styles.Status}>
          {!(statusMap.indentSequence === status) && (
            <p className={styles.Par}>
              {statusMap.done === status ? 'Выгружено пользователей:' : 'Осталось:'}
            </p>
          )}

          {!(statusMap.indentSequence === status) && (
            <h1 className={styles.StatusProgress}>
              {statusMap.done === status
                ? countParsedMembers
                : countParsedMembers + ' / ' + countMembers}
            </h1>
          )}

          <h5
            className={classNames(styles.StatusState, {
              [styles.StatusDone]: statusMap.done === status,
              [styles.StatusDone]: statusMap.indentSequence === status,
              [styles.StatusError]: statusMap.error === status,
            })}
          >
            {status}
          </h5>
        </div>
        <div className={styles.Buttons}>
          {statusMap.done === status ? (
            <>
              <DownloadCsvBtn className={styles.RegBtn} id={id} name={name} />
              <Button
                className={styles.RegBtn}
                variant={'tetriary'}
                centralIcon={'settings'}
                onClick={onSettingsClickHandler}
              ></Button>
              <Button
                className={styles.RegBtn}
                variant={'tetriary'}
                centralIcon={'alert-delete'}
                onClick={() => {
                  deleteParsing({ groupId: groupId + '', parsingId: id });
                }}
              ></Button>
            </>
          ) : (
            <>
              <Button
                className={styles.SetBtn}
                variant={'tetriary'}
                centralIcon={'settings'}
                onClick={onSettingsClickHandler}
              ></Button>
              <Button
                variant={'delete'}
                onClick={() => {
                  stopParsing({ groupId: groupId + '', parsingId: id });
                }}
              >
                Отменить
              </Button>
            </>
          )}
        </div>
      </div>
    </ColorfulBorder>
  );
};

export default ParsingCard;
