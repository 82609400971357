const NoPaymentMobileIcon = () => {
  return (
    <svg
      width="99"
      height="100"
      viewBox="0 0 99 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.9258 15.1456H23.9595C22.4693 15.1456 21.0402 14.5524 19.9865 13.4963C18.9328 12.4402 18.3408 11.0079 18.3408 9.5144V2.00607C18.3408 1.70737 18.4592 1.42091 18.67 1.20969C18.8807 0.99848 19.1665 0.879822 19.4646 0.879822H58.4207C58.7176 0.883711 59.0011 1.00362 59.211 1.21399C59.4209 1.42437 59.5406 1.70858 59.5445 2.00607V9.5144C59.5405 11.0067 58.9473 12.4367 57.8944 13.4919C56.8416 14.5471 55.4147 15.1417 53.9258 15.1456ZM20.5883 3.13232V9.5144C20.5883 10.4105 20.9435 11.2699 21.5757 11.9035C22.2079 12.5372 23.0654 12.8931 23.9595 12.8931H53.9258C54.8199 12.8931 55.6774 12.5372 56.3096 11.9035C56.9418 11.2699 57.297 10.4105 57.297 9.5144V3.13232H20.5883Z"
        fill="#8F8F90"
      />
      <path
        d="M67.4106 85.7244C61.2616 85.7244 55.2506 83.8969 50.1379 80.4731C45.0252 77.0492 41.0403 72.1828 38.6872 66.4891C36.334 60.7954 35.7183 54.5303 36.918 48.4859C38.1176 42.4416 41.0786 36.8895 45.4266 32.5317C49.7746 28.174 55.3144 25.2063 61.3452 24.004C67.3761 22.8017 73.6273 23.4188 79.3082 25.7772C84.9892 28.1356 89.8448 32.1294 93.261 37.2535C96.6772 42.3777 98.5006 48.4021 98.5006 54.5648C98.4887 62.8252 95.2094 70.7438 89.3814 76.5848C83.5535 82.4258 75.6525 85.7125 67.4106 85.7244ZM67.4106 25.6578C61.7061 25.6578 56.1297 27.3532 51.3865 30.5295C46.6434 33.7059 42.9466 38.2205 40.7636 43.5026C38.5805 48.7847 38.0094 54.5969 39.1222 60.2043C40.2351 65.8118 42.9821 70.9625 47.0158 75.0052C51.0495 79.0479 56.1888 81.8011 61.7837 82.9165C67.3786 84.0318 73.1779 83.4594 78.4482 81.2715C83.7184 79.0836 88.223 75.3785 91.3923 70.6247C94.5616 65.871 96.2531 60.2821 96.2531 54.5648C96.2452 46.9007 93.2039 39.5527 87.7966 34.1332C82.3893 28.7138 75.0577 25.6657 67.4106 25.6578Z"
        fill="#8F8F90"
      />
      <path
        d="M44.7411 47.8381C44.6222 47.8598 44.5004 47.8598 44.3815 47.8381C44.2355 47.7878 44.1011 47.7086 43.9862 47.6053C43.8713 47.5019 43.7783 47.3765 43.7126 47.2365C43.6469 47.0965 43.61 46.9446 43.6039 46.79C43.5979 46.6354 43.6228 46.4812 43.6773 46.3364C44.8545 42.9458 46.7397 39.8457 49.2071 37.2433C51.6746 34.6408 54.6675 32.5958 57.9862 31.2447C58.123 31.1885 58.2694 31.1598 58.4172 31.1603C58.565 31.1609 58.7113 31.1906 58.8476 31.2477C58.984 31.3049 59.1078 31.3884 59.2119 31.4936C59.316 31.5987 59.3985 31.7233 59.4546 31.8604C59.5106 31.9974 59.5392 32.1442 59.5387 32.2923C59.5382 32.4405 59.5086 32.587 59.4515 32.7237C59.3945 32.8603 59.3111 32.9844 59.2063 33.0888C59.1014 33.1931 58.977 33.2758 58.8403 33.332C55.8171 34.5567 53.09 36.4148 50.8418 38.782C48.5937 41.1491 46.8763 43.9706 45.8049 47.0572C45.7303 47.2819 45.5879 47.4778 45.3973 47.6177C45.2067 47.7576 44.9774 47.8346 44.7411 47.8381Z"
        fill="#8F8F90"
      />
      <path
        d="M43.4377 56.9074C43.1498 56.9078 42.8727 56.7974 42.6636 56.599C42.4545 56.4006 42.3293 56.1294 42.314 55.8412C42.314 55.4207 42.314 55.0003 42.314 54.5648C42.314 53.6771 42.359 52.79 42.4488 51.9068C42.4584 51.7551 42.4985 51.6069 42.5668 51.4711C42.6351 51.3353 42.7301 51.2148 42.8461 51.1168C42.9621 51.0188 43.0967 50.9454 43.2418 50.901C43.3869 50.8565 43.5394 50.842 43.6903 50.8582C43.8411 50.8744 43.9871 50.9211 44.1195 50.9954C44.2518 51.0697 44.3678 51.1701 44.4604 51.2906C44.553 51.411 44.6203 51.549 44.6583 51.6962C44.6963 51.8434 44.7041 51.9968 44.6813 52.1471C44.6813 52.943 44.5614 53.7689 44.5614 54.5648C44.5614 54.9552 44.5614 55.3457 44.5614 55.7361C44.5675 55.884 44.5443 56.0317 44.4933 56.1706C44.4424 56.3096 44.3646 56.4371 44.2644 56.5459C44.1642 56.6547 44.0435 56.7426 43.9094 56.8047C43.7753 56.8667 43.6303 56.9016 43.4826 56.9074H43.4377Z"
        fill="#8F8F90"
      />
      <path
        d="M67.4107 69.3411C64.4947 69.3411 61.6442 68.4745 59.2197 66.8508C56.7951 65.2272 54.9054 62.9194 53.7895 60.2194C52.6736 57.5193 52.3817 54.5483 52.9505 51.682C53.5194 48.8156 54.9236 46.1827 56.9855 44.1162C59.0474 42.0497 61.6744 40.6424 64.5344 40.0722C67.3943 39.5021 70.3587 39.7947 73.0527 40.9131C75.7467 42.0315 78.0493 43.9254 79.6694 46.3554C81.2894 48.7853 82.1541 51.6422 82.1541 54.5647C82.1501 58.4824 80.5955 62.2385 77.8314 65.0088C75.0674 67.779 71.3196 69.3371 67.4107 69.3411ZM67.4107 42.0408C64.9392 42.0408 62.5232 42.7753 60.4683 44.1515C58.4133 45.5276 56.8117 47.4836 55.8659 49.772C54.9201 52.0605 54.6727 54.5786 55.1548 57.008C55.637 59.4374 56.8271 61.6689 58.5747 63.4204C60.3223 65.1719 62.5488 66.3647 64.9728 66.8479C67.3968 67.3312 69.9093 67.0832 72.1927 66.1353C74.476 65.1874 76.4276 63.5821 77.8007 61.5226C79.1737 59.4631 79.9066 57.0417 79.9066 54.5647C79.9026 51.2444 78.5848 48.0612 76.2422 45.7134C73.8997 43.3655 70.7236 42.0448 67.4107 42.0408Z"
        fill="#8F8F90"
      />
      <path
        d="M17.9661 33.1659H11.9729C11.2775 33.1659 10.6105 32.8891 10.1188 32.3962C9.62708 31.9034 9.35083 31.235 9.35083 30.538V24.5314C9.35083 23.8344 9.62708 23.166 10.1188 22.6731C10.6105 22.1803 11.2775 21.9035 11.9729 21.9035H17.9661C18.6603 21.9074 19.325 22.1855 19.8159 22.6775C20.3067 23.1695 20.5843 23.8356 20.5882 24.5314V30.538C20.5882 31.235 20.3119 31.9034 19.8202 32.3962C19.3285 32.8891 18.6615 33.1659 17.9661 33.1659ZM11.9729 24.1559C11.8735 24.1559 11.7783 24.1955 11.708 24.2659C11.6378 24.3363 11.5983 24.4318 11.5983 24.5314V30.538C11.5983 30.6376 11.6378 30.7331 11.708 30.8035C11.7783 30.8739 11.8735 30.9134 11.9729 30.9134H17.9661C18.0655 30.9134 18.1608 30.8739 18.231 30.8035C18.3013 30.7331 18.3407 30.6376 18.3407 30.538V24.5314C18.337 24.433 18.2964 24.3396 18.2269 24.27C18.1574 24.2004 18.0643 24.1597 17.9661 24.1559H11.9729Z"
        fill="#8F8F90"
      />
      <path
        d="M17.9661 51.1863H11.9729C11.2775 51.1863 10.6105 50.9095 10.1188 50.4166C9.62708 49.9238 9.35083 49.2554 9.35083 48.5584V42.5517C9.35083 41.8548 9.62708 41.1864 10.1188 40.6935C10.6105 40.2007 11.2775 39.9238 11.9729 39.9238H17.9661C18.6603 39.9278 19.325 40.2059 19.8159 40.6979C20.3067 41.1899 20.5843 41.856 20.5882 42.5517V48.5584C20.5882 49.2554 20.3119 49.9238 19.8202 50.4166C19.3285 50.9095 18.6615 51.1863 17.9661 51.1863ZM11.9729 42.1763C11.8735 42.1763 11.7783 42.2159 11.708 42.2863C11.6378 42.3567 11.5983 42.4522 11.5983 42.5517V48.5584C11.5983 48.658 11.6378 48.7535 11.708 48.8239C11.7783 48.8943 11.8735 48.9338 11.9729 48.9338H17.9661C18.0655 48.9338 18.1608 48.8943 18.231 48.8239C18.3013 48.7535 18.3407 48.658 18.3407 48.5584V42.5517C18.337 42.4534 18.2964 42.36 18.2269 42.2904C18.1574 42.2208 18.0643 42.18 17.9661 42.1763H11.9729Z"
        fill="#8F8F90"
      />
      <path
        d="M17.9662 69.2216H11.9729C11.6273 69.2216 11.2851 69.1531 10.966 69.0201C10.6469 68.8871 10.3572 68.6922 10.1135 68.4466C9.86986 68.201 9.67703 67.9095 9.54615 67.5889C9.41526 67.2684 9.3489 66.925 9.35087 66.5787V60.572C9.35087 59.875 9.62712 59.2066 10.1189 58.7138C10.6106 58.221 11.2775 57.9441 11.9729 57.9441H17.9662C18.6604 57.948 19.325 58.2262 19.8159 58.7182C20.3068 59.2101 20.5843 59.8763 20.5882 60.572V66.5787C20.5882 67.277 20.3125 67.947 19.8212 68.4422C19.3299 68.9374 18.663 69.2176 17.9662 69.2216ZM11.9729 60.2116C11.8736 60.2116 11.7783 60.2512 11.7081 60.3216C11.6378 60.392 11.5983 60.4875 11.5983 60.587V66.5937C11.5983 66.6933 11.6378 66.7887 11.7081 66.8591C11.7783 66.9296 11.8736 66.9691 11.9729 66.9691H17.9662C18.0643 66.9654 18.1575 66.9246 18.2269 66.855C18.2964 66.7854 18.3371 66.6921 18.3408 66.5937V60.572C18.3371 60.4736 18.2964 60.3803 18.2269 60.3107C18.1575 60.2411 18.0643 60.2003 17.9662 60.1966L11.9729 60.2116Z"
        fill="#8F8F90"
      />
      <path
        d="M17.9662 87.2401H11.9729C11.6273 87.2401 11.2851 87.1717 10.966 87.0387C10.6469 86.9056 10.3572 86.7107 10.1135 86.4651C9.86986 86.2195 9.67703 85.928 9.54615 85.6075C9.41526 85.2869 9.3489 84.9435 9.35087 84.5972V78.5905C9.35483 77.8962 9.63283 77.2316 10.1241 76.742C10.6154 76.2524 11.2801 75.9776 11.9729 75.9776H17.9662C18.659 75.9776 19.3237 76.2524 19.815 76.742C20.3063 77.2316 20.5843 77.8962 20.5882 78.5905V84.5972C20.5882 85.2955 20.3125 85.9655 19.8212 86.4607C19.3299 86.9559 18.663 87.2361 17.9662 87.2401ZM11.9729 78.2301C11.8736 78.2301 11.7783 78.2697 11.7081 78.3401C11.6378 78.4105 11.5983 78.506 11.5983 78.6055V84.6122C11.5983 84.7118 11.6378 84.8073 11.7081 84.8777C11.7783 84.9481 11.8736 84.9876 11.9729 84.9876H17.9662C18.0643 84.9839 18.1575 84.9432 18.2269 84.8735C18.2964 84.8039 18.3371 84.7106 18.3408 84.6122V78.5905C18.3408 78.491 18.3013 78.3955 18.231 78.3251C18.1608 78.2547 18.0655 78.2151 17.9662 78.2151L11.9729 78.2301Z"
        fill="#8F8F90"
      />
      <path
        d="M47.9325 25.6578H36.83C36.532 25.6578 36.2462 25.5391 36.0354 25.3279C35.8247 25.1167 35.7063 24.8302 35.7063 24.5315C35.7063 24.2328 35.8247 23.9464 36.0354 23.7351C36.2462 23.5239 36.532 23.4053 36.83 23.4053H47.9325C48.2306 23.4053 48.5164 23.5239 48.7271 23.7351C48.9379 23.9464 49.0563 24.2328 49.0563 24.5315C49.0563 24.8302 48.9379 25.1167 48.7271 25.3279C48.5164 25.5391 48.2306 25.6578 47.9325 25.6578Z"
        fill="#8F8F90"
      />
      <path
        d="M30.8367 25.6578H25.4577C25.1597 25.6578 24.8739 25.5391 24.6631 25.3279C24.4524 25.1167 24.334 24.8302 24.334 24.5315C24.334 24.2328 24.4524 23.9464 24.6631 23.7351C24.8739 23.5239 25.1597 23.4053 25.4577 23.4053H30.8367C31.1347 23.4053 31.4205 23.5239 31.6313 23.7351C31.842 23.9464 31.9604 24.2328 31.9604 24.5315C31.9604 24.8302 31.842 25.1167 31.6313 25.3279C31.4205 25.5391 31.1347 25.6578 30.8367 25.6578Z"
        fill="#8F8F90"
      />
      <path
        d="M41.9392 31.6646H25.4577C25.1597 31.6646 24.8739 31.546 24.6631 31.3347C24.4524 31.1235 24.334 30.8371 24.334 30.5384C24.334 30.2397 24.4524 29.9532 24.6631 29.742C24.8739 29.5308 25.1597 29.4121 25.4577 29.4121H41.9392C42.2372 29.4121 42.523 29.5308 42.7338 29.742C42.9445 29.9532 43.0629 30.2397 43.0629 30.5384C43.0629 30.8371 42.9445 31.1235 42.7338 31.3347C42.523 31.546 42.2372 31.6646 41.9392 31.6646Z"
        fill="#8F8F90"
      />
      <path
        d="M34.4476 43.6782H25.4577C25.1597 43.6782 24.8739 43.5595 24.6631 43.3483C24.4524 43.1371 24.334 42.8506 24.334 42.5519C24.334 42.2532 24.4524 41.9668 24.6631 41.7555C24.8739 41.5443 25.1597 41.4257 25.4577 41.4257H34.4476C34.7456 41.4257 35.0315 41.5443 35.2422 41.7555C35.4529 41.9668 35.5713 42.2532 35.5713 42.5519C35.5713 42.8506 35.4529 43.1371 35.2422 43.3483C35.0315 43.5595 34.7456 43.6782 34.4476 43.6782Z"
        fill="#8F8F90"
      />
      <path
        d="M32.9493 49.6851H25.4577C25.1597 49.6851 24.8739 49.5665 24.6631 49.3552C24.4524 49.144 24.334 48.8576 24.334 48.5589C24.334 48.2602 24.4524 47.9737 24.6631 47.7625C24.8739 47.5513 25.1597 47.4326 25.4577 47.4326H32.9493C33.2473 47.4326 33.5332 47.5513 33.7439 47.7625C33.9546 47.9737 34.073 48.2602 34.073 48.5589C34.073 48.8576 33.9546 49.144 33.7439 49.3552C33.5332 49.5665 33.2473 49.6851 32.9493 49.6851Z"
        fill="#8F8F90"
      />
      <path
        d="M32.9493 61.698H25.4577C25.1597 61.698 24.8739 61.5793 24.6631 61.3681C24.4524 61.1569 24.334 60.8705 24.334 60.5718C24.334 60.2731 24.4524 59.9866 24.6631 59.7754C24.8739 59.5642 25.1597 59.4455 25.4577 59.4455H32.9493C33.2473 59.4455 33.5332 59.5642 33.7439 59.7754C33.9546 59.9866 34.073 60.2731 34.073 60.5718C34.073 60.8705 33.9546 61.1569 33.7439 61.3681C33.5332 61.5793 33.2473 61.698 32.9493 61.698Z"
        fill="#8F8F90"
      />
      <path
        d="M34.4476 67.7193H25.4577C25.1597 67.7193 24.8739 67.6006 24.6631 67.3894C24.4524 67.1782 24.334 66.8917 24.334 66.593C24.334 66.2943 24.4524 66.0079 24.6631 65.7967C24.8739 65.5855 25.1597 65.4668 25.4577 65.4668H34.4476C34.7456 65.4668 35.0315 65.5855 35.2422 65.7967C35.4529 66.0079 35.5713 66.2943 35.5713 66.593C35.5713 66.8917 35.4529 67.1782 35.2422 67.3894C35.0315 67.6006 34.7456 67.7193 34.4476 67.7193Z"
        fill="#8F8F90"
      />
      <path
        d="M41.9392 79.733H25.4577C25.1597 79.733 24.8739 79.6143 24.6631 79.4031C24.4524 79.1919 24.334 78.9054 24.334 78.6067C24.334 78.308 24.4524 78.0216 24.6631 77.8103C24.8739 77.5991 25.1597 77.4805 25.4577 77.4805H41.9392C42.2372 77.4805 42.523 77.5991 42.7338 77.8103C42.9445 78.0216 43.0629 78.308 43.0629 78.6067C43.0629 78.9054 42.9445 79.1919 42.7338 79.4031C42.523 79.6143 42.2372 79.733 41.9392 79.733Z"
        fill="#8F8F90"
      />
      <path
        d="M47.9324 85.7388H40.4409C40.1428 85.7388 39.857 85.6202 39.6463 85.409C39.4355 85.1977 39.3171 84.9113 39.3171 84.6126C39.3171 84.3139 39.4355 84.0274 39.6463 83.8162C39.857 83.605 40.1428 83.4863 40.4409 83.4863H47.9324C48.2305 83.4863 48.5163 83.605 48.727 83.8162C48.9378 84.0274 49.0562 84.3139 49.0562 84.6126C49.0562 84.9113 48.9378 85.1977 48.727 85.409C48.5163 85.6202 48.2305 85.7388 47.9324 85.7388Z"
        fill="#8F8F90"
      />
      <path
        d="M34.4476 85.7388H25.4577C25.1597 85.7388 24.8739 85.6202 24.6631 85.409C24.4524 85.1977 24.334 84.9113 24.334 84.6126C24.334 84.3139 24.4524 84.0274 24.6631 83.8162C24.8739 83.605 25.1597 83.4863 25.4577 83.4863H34.4476C34.7456 83.4863 35.0315 83.605 35.2422 83.8162C35.4529 84.0274 35.5713 84.3139 35.5713 84.6126C35.5713 84.9113 35.4529 85.1977 35.2422 85.409C35.0315 85.6202 34.7456 85.7388 34.4476 85.7388Z"
        fill="#8F8F90"
      />
      <path
        d="M88.387 10.6423H82.3938C82.0957 10.6423 81.8099 10.5236 81.5992 10.3124C81.3884 10.1012 81.27 9.81475 81.27 9.51605C81.27 9.21735 81.3884 8.93088 81.5992 8.71967C81.8099 8.50846 82.0957 8.3898 82.3938 8.3898H88.387C88.685 8.3898 88.9709 8.50846 89.1816 8.71967C89.3924 8.93088 89.5107 9.21735 89.5107 9.51605C89.5107 9.81475 89.3924 10.1012 89.1816 10.3124C88.9709 10.5236 88.685 10.6423 88.387 10.6423Z"
        fill="#8F8F90"
      />
      <path
        d="M85.3903 13.6439C85.0923 13.6439 84.8065 13.5253 84.5957 13.3141C84.385 13.1028 84.2666 12.8164 84.2666 12.5177V6.51101C84.2666 6.21231 84.385 5.92585 84.5957 5.71464C84.8065 5.50342 85.0923 5.38477 85.3903 5.38477C85.6884 5.38477 85.9742 5.50342 86.1849 5.71464C86.3957 5.92585 86.5141 6.21231 86.5141 6.51101V12.5177C86.5102 12.8152 86.3906 13.0994 86.1806 13.3098C85.9707 13.5201 85.6872 13.64 85.3903 13.6439Z"
        fill="#8F8F90"
      />
      <path
        d="M97.3768 24.1559H91.3835C91.0855 24.1559 90.7996 24.0373 90.5889 23.8261C90.3782 23.6149 90.2598 23.3284 90.2598 23.0297C90.2598 22.731 90.3782 22.4445 90.5889 22.2333C90.7996 22.0221 91.0855 21.9035 91.3835 21.9035H97.3768C97.6748 21.9035 97.9606 22.0221 98.1714 22.2333C98.3821 22.4445 98.5005 22.731 98.5005 23.0297C98.5005 23.3284 98.3821 23.6149 98.1714 23.8261C97.9606 24.0373 97.6748 24.1559 97.3768 24.1559Z"
        fill="#8F8F90"
      />
      <path
        d="M94.3801 27.1588C94.0821 27.1588 93.7962 27.0402 93.5855 26.8289C93.3747 26.6177 93.2563 26.3313 93.2563 26.0326V20.0259C93.2563 19.7272 93.3747 19.4407 93.5855 19.2295C93.7962 19.0183 94.0821 18.8997 94.3801 18.8997C94.6781 18.8997 94.9639 19.0183 95.1747 19.2295C95.3854 19.4407 95.5038 19.7272 95.5038 20.0259V26.0326C95.4999 26.3301 95.3803 26.6143 95.1704 26.8246C94.9605 27.035 94.6769 27.1549 94.3801 27.1588Z"
        fill="#8F8F90"
      />
      <path
        d="M91.3836 88.7273H85.3903C85.0923 88.7273 84.8065 88.6086 84.5957 88.3974C84.385 88.1862 84.2666 87.8997 84.2666 87.601C84.2666 87.3023 84.385 87.0158 84.5957 86.8046C84.8065 86.5934 85.0923 86.4748 85.3903 86.4748H91.3836C91.6816 86.4748 91.9675 86.5934 92.1782 86.8046C92.3889 87.0158 92.5073 87.3023 92.5073 87.601C92.5073 87.8997 92.3889 88.1862 92.1782 88.3974C91.9675 88.6086 91.6816 88.7273 91.3836 88.7273Z"
        fill="#8F8F90"
      />
      <path
        d="M88.387 91.7318C88.2389 91.7338 88.0919 91.7061 87.9546 91.6502C87.8174 91.5943 87.6927 91.5114 87.5879 91.4064C87.4832 91.3015 87.4005 91.1765 87.3447 91.0389C87.289 90.9014 87.2613 90.754 87.2633 90.6056V84.5989C87.2633 84.3002 87.3817 84.0137 87.5924 83.8025C87.8032 83.5913 88.089 83.4727 88.387 83.4727C88.6851 83.4727 88.9709 83.5913 89.1816 83.8025C89.3924 84.0137 89.5108 84.3002 89.5108 84.5989V90.6056C89.5108 90.9043 89.3924 91.1907 89.1816 91.4019C88.9709 91.6132 88.6851 91.7318 88.387 91.7318Z"
        fill="#8F8F90"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.6562 60.221C78.326 60.8745 77.9405 61.4952 77.5057 62.0769L92.3957 70.3888C92.6461 70.5218 92.9394 70.5555 93.2146 70.483C93.4898 70.4105 93.7258 70.2374 93.8735 69.9996C94.0049 69.753 94.0352 69.4668 93.9584 69.1992C93.8816 68.9315 93.7033 68.7024 93.4598 68.5581L78.6562 60.221ZM60.2105 64.8178C59.6117 64.3934 59.0517 63.9178 58.5369 63.3974L47.2635 76.2125C47.0745 76.4317 46.9811 76.7144 47.0032 77C47.0253 77.2856 47.1612 77.5514 47.3817 77.7405C47.5828 77.9099 47.8402 78.0021 48.1059 78C48.4028 77.9793 48.6792 77.8445 48.8743 77.6252L60.2105 64.8178Z"
        fill="#8F8F90"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.2866 42.0535V24.5315C66.2866 24.2328 66.405 23.9464 66.6158 23.7351C66.8265 23.5239 67.1123 23.4053 67.4104 23.4053C67.7084 23.4053 67.9942 23.5239 68.205 23.7351C68.4157 23.9464 68.5341 24.2328 68.5341 24.5315V42.046C68.1777 42.0155 67.817 42 67.4527 42C67.0595 42 66.6706 42.0181 66.2866 42.0535ZM75.5003 44.9233L87.8773 32.5955C87.9725 32.4565 88.0976 32.3406 88.2433 32.2566C88.3891 32.1725 88.5519 32.1222 88.7196 32.1096C88.8873 32.0969 89.0557 32.1222 89.2124 32.1835C89.3691 32.2447 89.5101 32.3405 89.6249 32.4636C89.7398 32.5868 89.8256 32.7342 89.876 32.8951C89.9264 33.0559 89.9402 33.226 89.9162 33.3929C89.8923 33.5597 89.8313 33.7191 89.7378 33.8591C89.6442 33.9992 89.5205 34.1165 89.3757 34.2022L77.0775 46.5017C76.5994 45.9301 76.0715 45.4017 75.5003 44.9233Z"
        fill="#8F8F90"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.97976 99.2389H71.9056C73.3945 99.2349 74.8214 98.6404 75.8742 97.5852C76.9271 96.53 77.5203 95.0999 77.5243 93.6077V62.0519C76.8779 62.9212 76.1219 63.7038 75.2768 64.3795V93.6077C75.2768 94.5038 74.9216 95.3632 74.2894 95.9968C73.6572 96.6304 72.7997 96.9864 71.9056 96.9864H5.97976C5.08566 96.9864 4.22818 96.6304 3.59596 95.9968C2.96374 95.3632 2.60856 94.5038 2.60856 93.6077V6.51107C2.60856 5.61497 2.96374 4.75557 3.59596 4.12193C4.22818 3.48829 5.08566 3.13232 5.97976 3.13232H71.9056C72.7997 3.13232 73.6572 3.48829 74.2894 4.12193C74.9216 4.75557 75.2768 5.61497 75.2768 6.51107V44.7404C76.1219 45.416 76.8779 46.1987 77.5243 47.0679V6.51107C77.5203 5.01879 76.9271 3.58876 75.8742 2.53355C74.8214 1.47835 73.3945 0.883785 71.9056 0.879822H5.97976C4.4896 0.879822 3.06046 1.47311 2.00676 2.52918C0.95305 3.58524 0.361084 5.01757 0.361084 6.51107V93.6077C0.361084 95.1012 0.95305 96.5335 2.00676 97.5896C3.06046 98.6456 4.4896 99.2389 5.97976 99.2389Z"
        fill="#8F8F90"
      />
    </svg>
  );
};

export default NoPaymentMobileIcon;
