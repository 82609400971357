import { FC } from 'react';

export const QuestionMark: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.18182 12C2.18182 6.57762 6.57762 2.18182 12 2.18182C17.4224 2.18182 21.8182 6.57762 21.8182 12C21.8182 17.4229 17.4224 21.8188 12 21.8188C6.57762 21.8188 2.18182 17.423 2.18182 12ZM12 0C5.37226 0 0 5.37251 0 12C0 18.6277 5.37276 24.0006 12 24.0006C18.6272 24.0006 24 18.6279 24 12C24 5.37226 18.6272 0 12 0ZM13.7493 7.08717C12.7943 6.18963 11.2826 6.18134 10.3006 7.07548C9.76435 7.56371 9.45463 8.26064 9.45463 8.97871V9.00014C9.45463 9.60258 8.96616 10.091 8.36372 10.091C7.76104 10.091 7.27281 9.60258 7.27281 9.00014V8.97871C7.27281 7.63945 7.84651 6.3591 8.83175 5.46224C10.643 3.81298 13.4457 3.80737 15.2438 5.49731C16.1906 6.3873 16.7273 7.62894 16.7273 8.92831V9.37295C16.7273 11.0023 15.7856 12.4845 14.3107 13.1765C13.6016 13.5092 13.1489 14.2217 13.1489 15.0048V15.4782C13.1489 16.0806 12.6604 16.5691 12.058 16.5691C11.4555 16.5691 10.9671 16.0806 10.9671 15.4782V15.0048C10.9671 13.3755 11.9087 11.8933 13.3836 11.2012C14.0927 10.8686 14.5454 10.156 14.5454 9.37295V8.92831C14.5454 8.23088 14.2574 7.56467 13.7494 7.08717H13.7493ZM10.7272 19.0909C10.7272 18.3882 11.2972 17.8181 12 17.8181C12.7028 17.8181 13.2728 18.3881 13.2728 19.0909C13.2728 19.7937 12.7028 20.3637 12 20.3637C11.2972 20.3637 10.7272 19.7937 10.7272 19.0909Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
