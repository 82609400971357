import { tgApi as api } from 'config/http.tg';
import { EAppTGTagType } from 'interfaces/api';
import {
  TAdminChatsParams,
  TAdminMessages,
  TCommand,
  TCountType,
  TPaginationParams,
  TScheduledMessage,
  TSentMessage,
  TTelegramChat,
} from '../telegram.types';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    createAdminScheduledMessage: build.mutation<
      TCountType<unknown>,
      { botId: string; chatId: string; body: FormData }
    >({
      query: ({ botId, chatId, body }) => ({
        url: `/bot/admin/${botId}/chat/${chatId}/messages`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_SCHEDULED_MESSAGES],
    }),
    getAdminSentMessages: build.query<
      TCountType<TSentMessage[]>,
      { botId: string; chatId: string } & TAdminChatsParams
    >({
      query: ({ botId, chatId, ...params }) => ({
        url: `/bot/admin/${botId}/chat/${chatId}/messages/sent`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTGTagType.ADMIN_SCHEDULED_MESSAGES],
    }),
    updateAdminScheduledMessage: build.mutation<
      TCountType<unknown>,
      { botId: string; chatId: string; messageId: string; body: FormData }
    >({
      query: ({ botId, chatId, messageId, body }) => ({
        url: `/bot/admin/${botId}/chat/${chatId}/messages/${messageId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_SCHEDULED_MESSAGES],
    }),
    deleteAdminScheduledMessage: build.mutation<
      TCountType<unknown>,
      { botId: string; chatId: string; messageId: string }
    >({
      query: ({ botId, chatId, messageId }) => ({
        url: `/bot/admin/${botId}/chat/${chatId}/messages/${messageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_SCHEDULED_MESSAGES],
    }),
    getScheduledAdminMessages: build.query<
      TCountType<TScheduledMessage[]>,
      { botId: string; chatId: string } & TAdminChatsParams
    >({
      query: ({ botId, chatId, ...params }) => ({
        url: `/bot/admin/${botId}/chat/${chatId}/messages`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTGTagType.ADMIN_SCHEDULED_MESSAGES],
    }),
    getScheduledAdminMessageById: build.query<
      TScheduledMessage,
      { botId: string; chatId: string; messageId: string }
    >({
      query: ({ botId, chatId, messageId }) => ({
        url: `/bot/admin/${botId}/chat/${chatId}/messages/${messageId}`,
        method: 'GET',
      }),
      providesTags: [EAppTGTagType.ADMIN_SCHEDULED_MESSAGES],
    }),
    removeAdminMessageById: build.mutation<
      TCountType<unknown>,
      { botId: string; messageId: string }
    >({
      query: ({ botId, messageId }) => ({
        url: `/bot/admin/${botId}/welcome/${messageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),
    toggleAdminMessageById: build.mutation<
      TCountType<unknown>,
      { botId: string; messageId: string }
    >({
      query: ({ botId, messageId }) => ({
        url: `/bot/admin/${botId}/welcome/${messageId}/toggle`,
        method: 'PATCH',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),
    postAdminMessage: build.mutation<TCountType<unknown>, { botId: string; form: FormData }>({
      query: ({ botId, form }) => ({
        url: `/bot/admin/${botId}/welcome`,
        method: 'POST',
        body: form,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_MESSAGES, EAppTGTagType.ADMIN],
    }),

    getAdminMessagesById: build.query<
      TAdminMessages,
      { botId: string; messageId: string } & TPaginationParams
    >({
      query: ({ botId, messageId }) => ({
        url: `/bot/admin/${botId}/welcome/${messageId}`,
        method: 'GET',
      }),
      providesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),

    updateAdminMessage: build.mutation<
      TCountType<unknown>,
      { botId: string; messageId: string; form: FormData }
    >({
      query: ({ botId, messageId, form }) => ({
        url: `/bot/admin/${botId}/welcome/${messageId}`,
        method: 'PATCH',
        body: form,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),
    getAdminMessages: build.query<
      TCountType<TAdminMessages[]>,
      { botId: string } & TPaginationParams & { status?: boolean }
    >({
      query: ({ botId, ...params }) => ({
        url: `/bot/admin/${botId}/welcome`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),

    getAdminBotChatListById: build.query<
      TCountType<TTelegramChat[]>,
      { botId: string } & TAdminChatsParams
    >({
      query: ({ botId, ...params }) => ({
        url: `/bot/admin/${botId}/chat`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTGTagType.ADMIN_CHATS_LIST],
    }),

    getAdminCommands: build.query<TCountType<TCommand[]>, { botId: string } & TPaginationParams>({
      query: ({ botId, ...params }) => ({
        url: `/bot/admin/${botId}/commands`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),
    getAdminCommandById: build.query<TCommand, { botId: string; commandId: string }>({
      query: ({ botId, commandId }) => ({
        url: `/bot/admin/${botId}/commands/${commandId}`,
        method: 'GET',
      }),
      providesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),

    removeAdminCommandById: build.mutation<
      TCountType<unknown>,
      { botId: string; commandId: string }
    >({
      query: ({ botId, commandId }) => ({
        url: `/bot/admin/${botId}/commands/${commandId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),

    toggleSelectedCommands: build.mutation<
      TCountType<unknown>,
      { botId: string; commandId: string }
    >({
      query: ({ botId, commandId }) => ({
        url: `/bot/admin/${botId}/commands/${commandId}/toggle`,
        method: 'PATCH',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),

    removeSelectedCommands: build.mutation<TCountType<unknown>, { botId: string; ids: string[] }>({
      query: ({ botId, ids }) => ({
        url: `/bot/admin/${botId}/commands`,
        method: 'DELETE',
        body: { ids },
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),

    updateAdminCommandById: build.mutation<
      TCountType<unknown>,
      { botId: string; commandId: string; form: FormData }
    >({
      query: ({ botId, commandId, form }) => ({
        url: `/bot/admin/${botId}/commands/${commandId}`,
        method: 'PATCH',
        body: form,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),

    postAdminCommand: build.mutation<TCountType<unknown>, { botId: string; form: FormData }>({
      query: ({ botId, form }) => ({
        url: `/bot/admin/${botId}/commands`,
        method: 'POST',
        body: form,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_COMMANDS, EAppTGTagType.ADMIN],
    }),

    removeAdminBotChatById: build.mutation<void, { botId: string; chatId: string }>({
      query: ({ botId, chatId }) => ({
        url: `/bot/admin/${botId}/chat/${chatId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_CHATS_LIST],
    }),
    removeAdminAllBotChats: build.mutation<void, { botId: string; chatIds: string[] }>({
      query: ({ botId, ...body }) => ({
        url: `/bot/admin/${botId}/chat`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_CHATS_LIST],
    }),
    adminBotControllerGetBot: build.query<
      AdminBotControllerGetBotApiResponse,
      AdminBotControllerGetBotApiArg
    >({
      query: queryArg => ({ url: `/bot/admin/${queryArg.id}` }),
      providesTags: [EAppTGTagType.ADMIN],
    }),
    adminBotControllerDeleteBot: build.mutation<
      AdminBotControllerDeleteBotApiResponse,
      AdminBotControllerDeleteBotApiArg
    >({
      query: queryArg => ({ url: `/bot/admin/${queryArg.id}`, method: 'DELETE' }),
      invalidatesTags: [EAppTGTagType.ADMIN],
    }),
    adminBotControllerCreateBot: build.mutation<
      AdminBotControllerCreateBotApiResponse,
      AdminBotControllerCreateBotApiArg
    >({
      query: queryArg => ({
        url: `/bot/admin`,
        method: 'POST',
        body: queryArg.createBotAdminPayload,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN],
    }),
    adminBotControllerGetBotList: build.query<
      AdminBotControllerGetBotListApiResponse,
      AdminBotControllerGetBotListApiArg
    >({
      query: () => ({
        url: `/bot/admin`,
        method: 'GET',
      }),
      providesTags: [EAppTGTagType.ADMIN],
    }),
    adminBotControllerUpdateBot: build.mutation<
      AdminBotControllerUpdateBotApiResponse,
      AdminBotControllerUpdateBotApiArg
    >({
      query: queryArg => ({
        url: `/bot/admin/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updatedName,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN],
    }),
    adminBotControllerToggleBot: build.mutation<
      AdminBotControllerToggleBotApiResponse,
      AdminBotControllerToggleBotApiArg
    >({
      query: queryArg => ({ url: `/bot/admin/${queryArg.id}/toggle`, method: 'PATCH' }),
      invalidatesTags: [EAppTGTagType.ADMIN],
    }),
    adminBotControllerSyncBot: build.mutation<
      AdminBotControllerSyncBotApiResponse,
      AdminBotControllerSyncBotApiArg
    >({
      query: queryArg => ({ url: `/bot/admin/${queryArg.id}/sync`, method: 'PATCH' }),
      invalidatesTags: [EAppTGTagType.ADMIN],
    }),
    adminBotControllerRefreshBot: build.mutation<
      AdminBotControllerRefreshBotApiResponse,
      AdminBotControllerRefreshBotApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.id}/update_token`,
        method: 'PATCH',
        body: queryArg.updateBotAdminTokenPayload,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN],
    }),
    adminBotIdMessagesControllerCreateMessage: build.mutation<
      AdminBotIdMessagesControllerCreateMessageApiResponse,
      AdminBotIdMessagesControllerCreateMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/messages`,
        method: 'POST',
        body: queryArg.createScheduledMessage,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),
    adminBotIdMessagesControllerGetMessageList: build.query<
      AdminBotIdMessagesControllerGetMessageListApiResponse,
      AdminBotIdMessagesControllerGetMessageListApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/messages/list`,
        method: 'POST',
        body: queryArg.botAdminScheduledMessageListParams,
      }),
      providesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),
    adminBotMessagesControllerUpdateMessage: build.mutation<
      AdminBotMessagesControllerUpdateMessageApiResponse,
      AdminBotMessagesControllerUpdateMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/messages/${queryArg.messageId}`,
        method: 'PATCH',
        body: queryArg.updateScheduledMessage,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),
    adminBotMessagesControllerDeleteMessage: build.mutation<
      AdminBotMessagesControllerDeleteMessageApiResponse,
      AdminBotMessagesControllerDeleteMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/messages/${queryArg.messageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_MESSAGES],
    }),
    adminBotIdCommandsControllerCreateCommand: build.mutation<
      AdminBotIdCommandsControllerCreateCommandApiResponse,
      AdminBotIdCommandsControllerCreateCommandApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/commands`,
        method: 'POST',
        body: queryArg.createBotAdminCommandPayload,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),
    adminBotIdCommandsControllerGetCommandList: build.query<
      AdminBotIdCommandsControllerGetCommandListApiResponse,
      AdminBotIdCommandsControllerGetCommandListApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/commands/list`,
        method: 'POST',
        body: queryArg.botAdminCommandListParams,
      }),
      providesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),
    adminBotCommandsControllerUpdateCommand: build.mutation<
      AdminBotCommandsControllerUpdateCommandApiResponse,
      AdminBotCommandsControllerUpdateCommandApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/commands/${queryArg.commandId}`,
        method: 'PATCH',
        body: queryArg.updateBotAdminCommandPayload,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),
    adminBotCommandsControllerDeleteCommand: build.mutation<
      AdminBotCommandsControllerDeleteCommandApiResponse,
      AdminBotCommandsControllerDeleteCommandApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/commands/${queryArg.commandId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_COMMANDS],
    }),
    adminBotIdWelcomeMessagesControllerCreateWelcomeMessage: build.mutation<
      AdminBotIdWelcomeMessagesControllerCreateWelcomeMessageApiResponse,
      AdminBotIdWelcomeMessagesControllerCreateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/welcomeMessages`,
        method: 'POST',
        body: queryArg.botAdminCreateWelcomeMessagePayload,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_WELCOME_MESSAGES],
    }),
    adminBotIdWelcomeMessagesControllerGetWelcomeMessageList: build.query<
      AdminBotIdWelcomeMessagesControllerGetWelcomeMessageListApiResponse,
      AdminBotIdWelcomeMessagesControllerGetWelcomeMessageListApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/welcomeMessages/list`,
        method: 'POST',
        body: queryArg.botAdminWelcomeMessageListParams,
      }),
      providesTags: [EAppTGTagType.ADMIN_WELCOME_MESSAGES],
    }),
    adminBotWelcomeMessagesControllerUpdateWelcomeMessageStatus: build.mutation<
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiResponse,
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages/${queryArg.welcomeMessageId}/toggle`,
        method: 'PATCH',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_WELCOME_MESSAGES],
    }),
    adminBotWelcomeMessagesControllerUpdateWelcomeMessage: build.mutation<
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiResponse,
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages/${queryArg.welcomeMessageId}`,
        method: 'PATCH',
        body: queryArg.botAdminUpdateWelcomeMessagePayload,
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_WELCOME_MESSAGES],
    }),
    adminBotWelcomeMessagesControllerDeleteWelcomeMessage: build.mutation<
      AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiResponse,
      AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages/${queryArg.welcomeMessageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_WELCOME_MESSAGES],
    }),
    adminBotIdChatsControllerGetChatList: build.query<
      AdminBotIdChatsControllerGetChatListApiResponse,
      AdminBotIdChatsControllerGetChatListApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/chats/list`,
        method: 'POST',
        body: queryArg.botAdminChatListParams,
      }),
      providesTags: [EAppTGTagType.ADMIN_CHATS],
    }),
    adminBotIdChatsControllerCreateChat: build.mutation<
      AdminBotIdChatsControllerCreateChatApiResponse,
      AdminBotIdChatsControllerCreateChatApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/chats/${queryArg.chatId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [EAppTGTagType.ADMIN_CHATS],
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as tgAdminApiInjected };
export type AdminBotControllerUpdateBotApiResponse = /** status 200  */ BotAdminTotal;
export type AdminBotControllerUpdateBotApiArg = {
  id: string;
  updatedName: { name: string };
};
export type AdminBotControllerGetBotApiResponse = /** status 200  */ BotAdminTotal;
export type AdminBotControllerGetBotApiArg = {
  id: string;
};
export type AdminBotControllerDeleteBotApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotControllerDeleteBotApiArg = {
  id: string;
};
export type AdminBotControllerCreateBotApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotControllerCreateBotApiArg = {
  createBotAdminPayload: CreateBotAdminPayload;
};
export type AdminBotControllerGetBotListApiResponse = /** status 200  */ BotAdminListResponse;
export type AdminBotControllerGetBotListApiArg = {
  botAdminListParams: BotAdminListParams;
};
export type AdminBotControllerToggleBotApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotControllerToggleBotApiArg = {
  id: string;
};
export type AdminBotControllerSyncBotApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotControllerSyncBotApiArg = {
  id: string;
};
export type AdminBotControllerRefreshBotApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotControllerRefreshBotApiArg = {
  id: string;
  updateBotAdminTokenPayload: UpdateBotAdminTokenPayload;
};
export type AdminBotIdMessagesControllerCreateMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotIdMessagesControllerCreateMessageApiArg = {
  botId: string;
  createScheduledMessage: CreateScheduledMessage;
};
export type AdminBotIdMessagesControllerGetMessageListApiResponse =
  /** status 200  */
  BotAdminScheduledMessageListResponse;
export type AdminBotIdMessagesControllerGetMessageListApiArg = {
  botId: string;
  botAdminScheduledMessageListParams: BotAdminScheduledMessageListParams;
};
export type AdminBotMessagesControllerUpdateMessageApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotMessagesControllerUpdateMessageApiArg = {
  messageId: number;
  updateScheduledMessage: UpdateScheduledMessage;
};
export type AdminBotMessagesControllerDeleteMessageApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotMessagesControllerDeleteMessageApiArg = {
  messageId: number;
};
export type AdminBotIdCommandsControllerCreateCommandApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotIdCommandsControllerCreateCommandApiArg = {
  botId: string;
  createBotAdminCommandPayload: CreateBotAdminCommandPayload;
};
export type AdminBotIdCommandsControllerGetCommandListApiResponse =
  /** status 200  */
  BotAdminCommandListResponse;
export type AdminBotIdCommandsControllerGetCommandListApiArg = {
  botId: string;
  botAdminCommandListParams: BotAdminCommandListParams;
};
export type AdminBotCommandsControllerUpdateCommandApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotCommandsControllerUpdateCommandApiArg = {
  commandId: number;
  updateBotAdminCommandPayload: UpdateBotAdminCommandPayload;
};
export type AdminBotCommandsControllerDeleteCommandApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotCommandsControllerDeleteCommandApiArg = {
  commandId: number;
};
export type AdminBotIdWelcomeMessagesControllerCreateWelcomeMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotIdWelcomeMessagesControllerCreateWelcomeMessageApiArg = {
  botId: string;
  botAdminCreateWelcomeMessagePayload: BotAdminCreateWelcomeMessagePayload;
};
export type AdminBotIdWelcomeMessagesControllerGetWelcomeMessageListApiResponse =
  /** status 200  */ BotAdminWelcomeMessageListResponse;
export type AdminBotIdWelcomeMessagesControllerGetWelcomeMessageListApiArg = {
  botId: string;
  botAdminWelcomeMessageListParams: BotAdminWelcomeMessageListParams;
};
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiArg = {
  welcomeMessageId: number;
};
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiArg = {
  welcomeMessageId: number;
  botAdminUpdateWelcomeMessagePayload: BotAdminUpdateWelcomeMessagePayload;
};
export type AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiArg = {
  welcomeMessageId: number;
};
export type AdminBotIdChatsControllerGetChatListApiResponse =
  /** status 200  */
  BotAdminChatListResponse;
export type AdminBotIdChatsControllerGetChatListApiArg = {
  botId: string;
  botAdminChatListParams: BotAdminChatListParams;
};
export type AdminBotIdChatsControllerCreateChatApiResponse = /** status 200  */ DefaultResponse;
export type AdminBotIdChatsControllerCreateChatApiArg = {
  botId: string;
  chatId: string;
};

export type BotAdminTotal = {
  id: string;
  lastTokenSymbol: string;
  userId: string;
  timezone: string;
  status: boolean;
  firstName: string;
  name: string;
  countChats: number;
  canNotStart?: null | {
    message: string;
  };
  countCommands: number;
  countMessages: number;
  telegram: {
    name: string;
    displayName: string;
    photoUrl: string | null;
  };
};
export type DefaultResponse = {
  status: boolean;
};
export type CreateBotAdminPayload = {
  token: string;
  name: string;
  timezone: string;
};
export type BotAdminListResponse = {
  data: BotAdminTotal[];
  count: number;
};
export type BotAdminListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export type UpdateBotAdminTokenPayload = {
  token: string;
};
export type FileType = {
  mime: string;
  url: string;
};
export type CreateScheduledMessage = {
  text?: string;
  persist: boolean;
  dates: string[];
  files: FileType[];
};
export type BotAdminScheduledMessageDate = {
  id: number;
  date: string;
  scheduledMessageId: number;
};
export type BotAdminScheduledMessageFile = {
  id: number;
  mime: string;
  url: string;
  scheduledMessageId: number;
};
export type BotAdminScheduledMessageFull = {
  dates: BotAdminScheduledMessageDate[];
  files: BotAdminScheduledMessageFile[];
  id: number;
  text: string | null;
  persist: boolean;
  botAdminId: number;
};
export type BotAdminScheduledMessageListResponse = {
  list: BotAdminScheduledMessageFull[];
  total: number;
};
export type BotAdminScheduledMessageListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export type UpdateScheduledMessage = {
  text?: string;
  persist?: boolean;
  dates?: string[];
  files?: FileType[];
};
export type CreateBotAdminCommandPayload = {
  name: string;
  description: string;
  files: FileType[];
  text?: string;
};
export type BotAdminCommandFile = {
  id: number;
  mime: string;
  url: string;
  commandId: number | null;
};
export type BotAdminCommandFull = {
  files: BotAdminCommandFile[];
  id: number;
  name: string;
  description: string;
  text: string | null;
  callsCount: number;
  botAdminId: number;
};
export type BotAdminCommandListResponse = {
  list: BotAdminCommandFull[];
  total: number;
};
export type BotAdminCommandListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export type UpdateBotAdminCommandPayload = {
  name?: string;
  description?: string;
  files?: FileType[];
  text?: string;
};
export type BotAdminCreateWelcomeMessagePayload = {
  text?: string;
  files: FileType[];
};
export type BotAdminWelcomeMessageFile = {
  id: number;
  mime: string;
  url: string;
  welcomeMessageId: number;
};
export type BotAdminWelcomeMessageFull = {
  files: BotAdminWelcomeMessageFile[];
  id: number;
  text: string | null;
  status: boolean;
  botAdminId: number;
};
export type BotAdminWelcomeMessageListResponse = {
  list: BotAdminWelcomeMessageFull[];
  total: number;
};
export type BotAdminWelcomeMessageListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export type BotAdminUpdateWelcomeMessagePayload = {
  files?: FileType[];
  text?: string;
};
export type BotAdminChat = {
  id: number;
  chatId: string;
  chatName: string | null;
  isReachable: boolean;
  botAdminId: number;
};
export type BotAdminChatListResponse = {
  list: BotAdminChat[];
  total: number;
};
export type BotAdminChatListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};

export { injectedRtkApi };
export const {
  useGetAdminSentMessagesQuery,
  useCreateAdminScheduledMessageMutation,
  useUpdateAdminScheduledMessageMutation,
  useDeleteAdminScheduledMessageMutation,
  useGetScheduledAdminMessageByIdQuery,
  useGetAdminMessagesQuery,
  useGetScheduledAdminMessagesQuery,
  useGetAdminMessagesByIdQuery,
  useRemoveAdminMessageByIdMutation,
  useToggleAdminMessageByIdMutation,
  usePostAdminMessageMutation,
  useUpdateAdminMessageMutation,
  useGetAdminBotChatListByIdQuery,
  useGetAdminCommandsQuery,
  useGetAdminCommandByIdQuery,
  useUpdateAdminCommandByIdMutation,
  useRemoveAdminCommandByIdMutation,
  usePostAdminCommandMutation,
  useToggleSelectedCommandsMutation,
  useRemoveSelectedCommandsMutation,
  useRemoveAdminBotChatByIdMutation,
  useRemoveAdminAllBotChatsMutation,
  useAdminBotControllerGetBotQuery,
  useAdminBotControllerDeleteBotMutation,
  useAdminBotControllerCreateBotMutation,
  useAdminBotControllerUpdateBotMutation,
  useAdminBotControllerGetBotListQuery,
  useAdminBotControllerToggleBotMutation,
  useAdminBotControllerSyncBotMutation,
  useAdminBotIdMessagesControllerCreateMessageMutation,
  useAdminBotIdMessagesControllerGetMessageListQuery,
  useAdminBotMessagesControllerUpdateMessageMutation,
  useAdminBotMessagesControllerDeleteMessageMutation,
  useAdminBotIdCommandsControllerGetCommandListQuery,
  useAdminBotCommandsControllerUpdateCommandMutation,
  useAdminBotCommandsControllerDeleteCommandMutation,
  useAdminBotIdWelcomeMessagesControllerCreateWelcomeMessageMutation,
  useAdminBotIdWelcomeMessagesControllerGetWelcomeMessageListQuery,
  useAdminBotWelcomeMessagesControllerUpdateWelcomeMessageMutation,
  useAdminBotWelcomeMessagesControllerDeleteWelcomeMessageMutation,
  useAdminBotIdChatsControllerGetChatListQuery,
  useAdminBotIdChatsControllerCreateChatMutation,
} = injectedRtkApi;
