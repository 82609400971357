import { FC, ReactNode } from 'react';

import styles from './DataEntryLong.module.scss';
import Icon from 'components/Icon';

type Props = {
  iconName: string;
  name: string;
  value: ReactNode;
};

const DataEntryLong: FC<Props> = ({ iconName, name, value }) => {
  return (
    <div className={styles.Entry}>
      <Icon iconName={iconName} size={18} className={styles.Icon} />
      <div className={styles.Long}>
        <span className={styles.Name}>{name}</span>
        <div className={styles.Value}>{value}</div>
      </div>
    </div>
  );
};

export default DataEntryLong;
