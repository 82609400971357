import { FC, Fragment } from 'react';

import Button from 'components/Button';

import styles from './NewPageModal.module.scss';

import { useNewCardDialog } from 'modules/landing-dashboard/landing-dashboard.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { toggleNewCardDialog } from 'modules/landing-dashboard/landing-dashboard.reducer';
import { Dialog } from '@headlessui/react';
import IconButton from 'components/IconButton';
import {
  useCreatePageByTemplateMutation,
  useGetTemplatesQuery,
} from 'modules/landing-dashboard/landing-dashboard.api';
import Icon from 'components/Icon';
import {
  sampleBgImg1,
  sampleBgImg2,
  sampleBgImg5,
  sampleBgImg3,
  sampleBgImg4,
} from '../../landing-dashboard.consts';

const NewPageModal: FC = () => {
  const { data, isLoading, isError } = useGetTemplatesQuery();
  const [createPage] = useCreatePageByTemplateMutation();
  const dispatch = useAppDispatch();
  const isOpen = useNewCardDialog();
  const onClose = () => dispatch(toggleNewCardDialog());

  if (isLoading) return null;
  if (isError) return null;

  const randomImg = (random: number) => {
    const arr = [sampleBgImg1, sampleBgImg2, sampleBgImg3, sampleBgImg4, sampleBgImg5];

    return arr[random];
  };

  return (
    <Dialog open={isOpen} onClose={onClose} as={Fragment}>
      <div className={styles.Background}>
        <div className={styles.ModalWrapper}>
          <Dialog.Panel className={styles.Modal}>
            <Dialog.Title className={styles.Header}>
              <span>Выберите шаблон</span>
              <IconButton
                iconName="cross"
                iconSize={20}
                className={styles.Button}
                onClick={onClose}
              />
            </Dialog.Title>
            <section className={styles.Body}>
              {data?.body.map(template => {
                const random = Math.floor(Math.random() * 5);
                return (
                  <div key={template.page.id} className={styles.Template}>
                    <img
                      className={styles.Image}
                      src={randomImg(random)}
                      alt={template.page.name}
                    />
                    <div className={styles.Panel}>
                      <h3>{template.page.name}</h3>
                      <p>{template.description}</p>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          createPage({ id: template.page.id });
                          onClose();
                        }}
                        className={styles.Button}
                      >
                        <span>Выбрать</span>
                        <Icon className={styles.Icon} iconName="arrow-down" size={22} />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </section>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default NewPageModal;
