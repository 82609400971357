import { FC } from 'react';
import { Dialog } from '@headlessui/react';

import styles from './GroupsStackModal.module.scss';
import GroupCardSelectable, { GroupCardSelectableProps } from '../GroupCardSelectable';
import { useSelectedThematicGroups } from 'modules/vk/vk.selectors';
import IconButton from 'components/IconButton';
import SelectedThematicGroupsNav from '../SelectedThematicGroupsNav';
import Button from 'components/Button';

type Props = {
  open: boolean;
  onClose: () => void;
  groups: GroupCardSelectableProps[];
  name: string;
};

const GroupsStackModal: FC<Props> = ({ open, onClose, groups, name }) => {
  const selected = useSelectedThematicGroups();
  const hasSelected = selected.length > 0;

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.Wrapper}>
        <Dialog.Panel className={styles.Modal}>
          <div className={styles.Header}>
            <div className={styles.Info}>
              <h3 className={styles.Title}>Просмотр стопки</h3>
              <h4 className={styles.Label}>{name}</h4>
            </div>
            <IconButton
              className={styles.CloseIcon}
              iconName="cross"
              size={24}
              iconSize={18}
              onClick={onClose}
            />
          </div>
          <SelectedThematicGroupsNav
            left={
              <Button className={styles.DeleteBtn} variant="delete">
                Удалить
              </Button>
            }
            selected={selected}
            hasSelected={hasSelected}
          />
          <div className={styles.Grid}>
            {groups.map(g => (
              <GroupCardSelectable
                key={g.name}
                wrapperClassName={styles.GroupCardWrapper}
                className={styles.GroupCard}
                {...g}
              />
            ))}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default GroupsStackModal;
