import { FC } from 'react';

import styles from './TransactionTypeSelector.module.scss';
import TextMenu from 'components/TextMenu';
import { ETransactionType } from 'modules/finances/finances.types';
import { TRANSACTION_TYPES_NAMES } from 'modules/finances/finances.constants';

type Props = {
  type?: ETransactionType;
  setType: (value?: ETransactionType) => void;
};

const TransactionTypeSelector: FC<Props> = ({ type, setType }) => {
  return (
    <TextMenu
      label="Вид операции"
      items={Object.entries(TRANSACTION_TYPES_NAMES).map(([value, name]) => ({
        label: name,
        isSelected: type === value,
        onClick: () => setType(type === value ? undefined : (value as unknown as ETransactionType)),
      }))}
      menuClassName={styles.Menu}
      iconPosition="left"
      dontShowSelected
    />
  );
};

export default TransactionTypeSelector;
