import { FC, useMemo } from 'react';
import ToggleSwitch from 'components/ToggleSwitch';
import Button from 'components/Button';
import Icon from 'components/Icon';
import styles from './GreetingsMessageHead.module.scss';

import isUndefined from 'lodash-es/isUndefined';
import { useToggleAdminMessageByIdMutation } from '../../codegen/telegram.admin.api';
import { useBotId } from '../../telegram.hooks';
import SmallSpinner from '../SmallSpinner';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { removeToggledMessage } from '../../codegen/telegram.admin.reducer';
import { TAdminMessages } from '../../telegram.types';

type Props = {
  data?: TAdminMessages[];
};
const GreetingsMessageHead: FC<Props> = ({ data }) => {
  const botId = useBotId();
  const [toggleMessage, { isLoading: isToggleLoading }] = useToggleAdminMessageByIdMutation();
  const dispatch = useAppDispatch();

  const headItem = useMemo(() => {
    const values = Object.values(data || []);
    return values[0];
  }, [data]);

  if (isUndefined(headItem)) return null;

  return (
    <tr className={styles.Root}>
      <th className={styles.Col}>
        <div className={styles.CheckboxWrap}>
          <h1 className={styles.Title}>{headItem?.text}</h1>
        </div>
        <p className={styles.SubTitle}></p>
      </th>
      <th className={styles.Col}>
        <div className={styles.Count}>
          <Icon iconName={'brace'} />
          <p className={styles.CountNumber}>{headItem?.files.length}</p>
        </div>
        <div className={styles.Buttons}>
          {isToggleLoading ? (
            <SmallSpinner />
          ) : (
            <ToggleSwitch
              toggle={() => {
                toggleMessage({ botId, messageId: headItem?.id }).then(() => {
                  dispatch(removeToggledMessage());
                });
              }}
              active
            />
          )}

          <Button className={styles.Btn} centralIcon={'settings'} variant={'tetriary'}></Button>
          <Button
            onClick={() => {}}
            className={styles.DeleteBtn}
            centralIcon={'delete'}
            variant={'tetriary'}
          ></Button>
        </div>
      </th>
    </tr>
  );
};

export default GreetingsMessageHead;
