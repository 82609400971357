import { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import useDispatch from 'hooks/useAppDispatch';
import useTypedSelector from 'hooks/useAppSelector';
import r from 'constants/routes';
import useCountdown from 'hooks/useCountDown';
import { useResetPasswordMutation } from 'modules/auth/auth.api';
import { selectTime } from 'modules/auth/auth.selectors';
import { passTime } from 'modules/auth/auth.utils';

import Button from 'components/Button';

import styles from './TimerFeedback.module.scss';

type TTimerFeedback = {
  email: string;
};

const TimerFeedback: FC<TTimerFeedback> = ({ email }) => {
  const navigate = useNavigate();
  const time = useTypedSelector(selectTime);
  const dayjsDate = useMemo(() => dayjs(time), [time]);
  const [resetPassword] = useResetPasswordMutation();

  const diff = useCountdown(dayjsDate);

  const dispatch = useDispatch();

  const onHandleClick = () => {
    resetPassword({ email }).then(res => {
      if ('data' in res) {
        passTime(dispatch);
      }
    });
  };

  const goBack = () => {
    navigate(r.auth.signIn);
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Timer}>
        <div className={styles.Content}>
          <h1 className={styles.Title}>Письмо отправлено</h1>
          <p className={styles.Email}>
            Пожалуйста, проверьте Ваш Email. Там должна быть ссылка для сброса пароля
          </p>
          {diff ? (
            <>
              <div className={styles.Loader}></div>
              <p className={styles.Repeat}>
                Через {!!diff.minutes && `${diff.minutes} мин`} {diff.seconds} сек можно повторить
                отправку
              </p>
            </>
          ) : (
            <div className={styles.Btns}>
              <Button variant="text" className={styles.Exit} onClick={goBack}>
                Закрыть
              </Button>
              <Button variant="primary" className={styles.OneMore} onClick={onHandleClick}>
                Отправить ещё
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimerFeedback;
