export enum NotificationTypes {
  MESSAGE = 'messages',
  NOTIFICATION = 'notifications',
}

export type MessageType = {
  id: number | string;
  title: string;
  message: string;
  date: string;
  read?: string | null;
};

export type SendMessageRequest = {
  recipients: (number | string)[];
  title: string;
  message: string;
};

export type SendMessageAllPartnersRequest = {
  line: number;
  message: string;
};

export type MailUnreadResponse = {
  count: number;
  messages_count: number;
  notifications_count: number;
  messages: MessageType[];
};
