import { FC } from 'react';
import styles from './FolderGroupsCard.module.scss';
import GroupNameDisplay from '../../GroupNameDisplay';
import Button from 'components/Button';
import { TUserVkGroup } from '../../../vk.types';

type Props = {
  onChoose: () => void;
  onDelete: () => void;
} & TUserVkGroup;

const FolderGroupsCard: FC<Props> = ({
  photoUrl,
  // onChoose,
  isChecked,
  membersCount,
  activity,
  name,
  onDelete,
}) => {
  return (
    <div className={styles.Root}>
      <div className={styles.UpperCard}>
        <div className={styles.CardHeader}>
          <GroupNameDisplay image={photoUrl} subTitle={`${membersCount} подписчика`} />
          {/*
              At the moment, this checkbox does not make any sense.
              Unlock when an active action appears with the selection of several group elements.
          */}
          {/* <CheckBox onChange={() => onChoose()} checked={isChecked} defaultChecked={isChecked} /> */}
        </div>
        <p className={styles.Subtitle}>{activity}</p>
        <h1 className={styles.Description}>{name}</h1>
      </div>

      <div className={styles.Btn}>
        <Button
          disabled={isChecked}
          onClick={onDelete}
          leftIcon={'alert-delete'}
          variant={'tetriary'}
        >
          Удалить
        </Button>
      </div>
    </div>
  );
};

export default FolderGroupsCard;
