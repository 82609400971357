// prettier-ignore
export type TResponse<T, Err = T> =
  | { success: true; body: T; }
  | { success: false; body: Err; }

export type TPaginated<T> = {
  items: T;
  count: number;
};

export type TPaginatedRequest = {
  limit?: number;
  offset?: number;
};

export enum EAppTagType {
  USER = 'User',
  PARTNERS = 'Partners',
  TRANSACTIONS = 'Transactions',
  SOFTPAYPRODUCTS = 'SOFTPAYPRODUCTS',
  SOFTPAYPRODUCTS_INFO = 'SOFTPAYPRODUCTS_INFO',
  SHEETS = 'Sheets',
  SHEET_TEMPLATES = 'Sheet_templates',
  LANDINGS = 'Landings',
  METRICS = 'Metrics',
  NOTIFICATIONS = 'Notifications',
  VkGroupsPage = 'VkGroupsPage',
  VkFriendsPage = 'VkFriendsPage',
  VkAutomationPage = 'VkAutomationPage',
  VkFriendsPhotos = 'VkFriendsPhotos',
  VkGroupComments = 'VkGroupComments',
  VKStories = 'VKStories',
  VKPosts = 'VKPosts',
  APPLICATIONS = 'applications',
  INTEGRATION_CONTROLLER = 'INTEGRATION_CONTROLLER',
}

export enum EAppTGTagType {
  ADMIN = 'Admin',
  ADMIN_MESSAGES = 'AdminMessages',
  ADMIN_SCHEDULED_MESSAGES = 'ADMIN_SCHEDULED_MESSAGES',
  ADMIN_WELCOME_MESSAGES = 'AdminWelcomeMessages',
  ADMIN_CHATS = 'AdminChats',
  ADMIN_COMMANDS = 'AdminCommands',
  SPAM = 'Spam',
  SPAM_MESSAGES = 'SpamMessages',
  SPAM_CHATS = 'SpamChats',
  SPAM_SCHEDULED_MESSAGES = 'SPAM_SCHEDULED_MESSAGES',
  INTEGRATION_CONTROLLER = 'INTEGRATION_CONTROLLER',

  ADMIN_CHATS_LIST = 'ADMIN_CHATS_LIST',
  WELCOME = 'Welcome',
  WELCOME_BOT = 'WELCOME_BOT',
  WELCOME_MESSAGES = 'WelcomeMessages',
  WELCOME_CHATS = 'WelcomeChats',
  WELCOME_BOT_CONTACTS = 'WELCOME_BOT_CONTACTS',
}

export enum EAppVKTagType {
  VKACCOUNT = 'VKAccount',
  VK_SETTINGS = 'VK_SETTINGS',
  VK_SETTINGS_COMMENTS = 'VK_SETTINGS_COMMENTS',
  VK_FOLDER_GROUPS = 'VK_FOLDER_GROUPS',
  VK_FOLDER = 'VK_FOLDER',
  VK_ALL_PARSED = 'VK_ALL_PARSED',
  VK_GROUP_SETTINGS_AUTO = 'VK_GROUP_SETTINGS_AUTO',
  VK_ALL_AUTOMATION_SETTING = 'VK_ALL_AUTOMATION_SETTING',
  VK_AUTO_POSTING_POSTS = 'VK_AUTO_POSTING_POSTS',
  VK_AUTO_POSTING_STORIES = 'VK_AUTO_POSTING_STORIES',
  VK_AUTO_POSTING_TRANSLATIONS = 'VK_AUTO_POSTING_TRANSLATIONS',
  VK_AUTOMATION_STORAGE = 'VK_AUTOMATION_STORAGE',
}
