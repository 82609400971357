import { FC } from 'react';

import styles from './ThematicGroups.module.scss';
import GoBack from 'components/GoBack';
import SearchField from 'components/SearchField';
import Title from 'components/Title';
import FancyActionCard from 'components/FancyActionCard';
import Input from 'components/Input';
import Button from 'components/Button';
import VideoInstructions from 'components/VideoInstructions';
import GroupsStack from 'modules/vk/components/GroupsStack';
import GroupCardSelectable, {
  GroupCardSelectableProps,
} from 'modules/vk/components/GroupCardSelectable';
import useAppDispatch from 'hooks/useAppDispatch';
import { useSelectedThematicGroups } from 'modules/vk/vk.selectors';
import { selectThematicGroup } from 'modules/vk/vk.reducer';
import SelectedThematicGroupsNav from 'modules/vk/components/SelectedThematicGroupsNav';
import useDisclosure from 'hooks/useDisclosure';
import AddGroupsStackModal from 'modules/vk/components/AddGroupsStackModal';
import Icon from 'components/Icon';
import NavWithTabsMobile from 'components/NavWithTabsMobile';
import r from 'constants/routes';
import SelectThematicGroupsModal from 'modules/vk/components/SelectThematicGroupsModal';

const ThematicGroups: FC = () => {
  const dispatch = useAppDispatch();
  const selected = useSelectedThematicGroups();
  const hasSelected = selected.length > 0;

  const { open: addStackOpen, onOpen: onAddStackOpen, onClose: onAddStackClose } = useDisclosure();
  const {
    open: selectGroupsOpen,
    onOpen: onSelectGroupsOpen,
    onClose: onSelectGroupsClose,
  } = useDisclosure();

  const groups: GroupCardSelectableProps[] = [
    {
      id: 1,
      avatar: 'https://placebear.com/300/300',
      subscribersCount: 123_343,
      type: 'Еда / рецепты',
      name: 'Кулинарная книга',
      selected: selected.includes(1),
      onSelect: () => dispatch(selectThematicGroup(1)),
      subscribed: false,
    },
    {
      id: 2,
      avatar: 'https://placebear.com/300/300',
      subscribersCount: 123_343,
      type: 'Еда / рецепты',
      name: 'Кулинарная книга',
      selected: selected.includes(2),
      onSelect: () => dispatch(selectThematicGroup(2)),
      subscribed: true,
    },
    {
      id: 3,
      avatar: 'https://placebear.com/300/300',
      subscribersCount: 123_343,
      type: 'Еда / рецепты',
      name: 'Кулинарная книга',
      selected: selected.includes(3),
      onSelect: () => dispatch(selectThematicGroup(3)),
      subscribed: false,
    },
  ];

  return (
    <div className={styles.Page}>
      <div className={styles.Header}>
        <GoBack staticPos />
        {hasSelected && <SearchField placeholder="Поиск подписок" onSearch={() => {}} />}
      </div>
      <Title className={styles.Title}>
        <GoBack className={styles.GoBack} backWordClassName={styles.GoBackText} staticPos /> Мои
        подписки
      </Title>
      {hasSelected && (
        <SearchField
          className={styles.SearchMobile}
          placeholder="Поиск подписок"
          onSearch={() => {}}
        />
      )}
      <section className={styles.Content}>
        <div className={styles.GridWrapper}>
          <SelectedThematicGroupsNav selected={selected} hasSelected={hasSelected} />
          <div className={styles.Grid}>
            {groups.map(g => (
              <GroupCardSelectable key={g.id} {...g} />
            ))}
          </div>
        </div>
        <aside className={styles.Aside}>
          <div className={styles.Cards}>
            <FancyActionCard
              title="Выбрать тематические сообщества"
              text="5 key components for aspiring designers that shape stylish design. Practice in Figma and create our first layout. That's all we're going to do in this lesson, buddy!"
              content={<Input label="Ссылка на группу" />}
              button={<Button variant="magic">Выбрать</Button>}
              buttonMobile={
                <Button variant="text" onClick={onSelectGroupsOpen}>
                  Выбрать
                  <Icon iconName="arrow-right" size={12} />
                </Button>
              }
            />
            <FancyActionCard
              title="Создать новую стопку"
              text="5 key components for aspiring designers that shape stylish design. Practice in Figma and create our first layout. That's all we're going to do in this lesson, buddy!"
              content={<VideoInstructions link="#" />}
              button={
                <Button variant="secondary" onClick={onAddStackOpen}>
                  Создать
                </Button>
              }
              buttonMobile={
                <Button variant="text" onClick={onAddStackOpen}>
                  Выбрать
                  <Icon iconName="arrow-right" size={12} />
                </Button>
              }
              noFancyBorder
            />
          </div>
          <div className={styles.Stacks}>
            <GroupsStack id={1} name="Кулинарная" groups={groups} />
            <GroupsStack id={1} name="Кулинарная" groups={groups} />
            <GroupsStack id={1} name="Кулинарная" groups={groups} />
          </div>
        </aside>
      </section>

      <AddGroupsStackModal isOpen={addStackOpen} onClose={onAddStackClose} />
      <SelectThematicGroupsModal
        isOpen={selectGroupsOpen}
        onClose={onSelectGroupsClose}
        onSearch={() => {}}
      />
      <NavWithTabsMobile
        className={styles.NavWithTabsMobile}
        links={[
          { name: 'Подписки', link: r.vk.thematicGroups.index },
          { name: 'Просмотр стопок', link: r.vk.thematicGroups.stacks },
        ]}
      />
    </div>
  );
};

export default ThematicGroups;
