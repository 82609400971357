import { FC } from 'react';

import styles from './BotStatCard.module.scss';
import Avatar from 'components/Avatar';
import TinyStatCard from '../TinyStatCard';
import VideoInstructions from 'components/VideoInstructions';

type Props = {
  avatar: string | null;
  username: string;
  name: string;
};

const BotStatCard: FC<Props> = ({ avatar, username, name }) => {
  return (
    <article className={styles.Card}>
      <div className={styles.Header}>
        <Avatar src={avatar} size={58} name={username} />
        <h3 className={styles.Name}>{name}</h3>
        <p className={styles.Username}>{username}</p>
      </div>
      <div className={styles.Stats}>
        <TinyStatCard name="Подписчиков" value={1234} legend="Всего" />
        <div className={styles.VerticalDivider} />
        <TinyStatCard name="Подписались" value={1234} legend="День" />
        <div className={styles.VerticalDivider} />
        <TinyStatCard name="Активность" value={1234} legend="День" />
        <div className={styles.VerticalDivider} />
        <TinyStatCard name="Отписались" value={1234} legend="День" />
      </div>
      <VideoInstructions className={styles.VideoLink} link="" />
    </article>
  );
};

export default BotStatCard;
