import { FC, useEffect } from 'react';
import ModalWithForm from 'components/ModalWithForm';
import { welcomeBotState } from '../../../codegen/telegram.constants';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { settingWelcomeBotSchema } from '../../../telegram.schema';
import { CommonModalProps } from 'components/Modal';
import {
  useGetWelcomeBotMessageByIdQuery,
  useUpdateWelcomeBotMessageByIdMutation,
  useWelcomeBotMessageMutation,
} from '../../../codegen/telegram.welcome.api';
import toast from 'toasts';
import isUndefined from 'lodash-es/isUndefined';
import { isEmpty } from 'lodash-es';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import styles from './SettingsWelcomeBotModal.module.scss';

interface Props extends CommonModalProps {
  service: string;
  isEditing?: boolean;
  messageId?: string;
  onModalClose: () => void;
}

export const SettingsWelcomeBotModal: FC<Props> = ({
  service,
  messageId,
  onModalClose,
  isEditing,
  ...props
}) => {
  const [sendMessage, { isLoading }] = useWelcomeBotMessageMutation();
  const [updateMessage, { isLoading: isUpdating }] = useUpdateWelcomeBotMessageByIdMutation({});
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<{ text: string }>({
    mode: 'onChange',
    resolver: yupResolver(settingWelcomeBotSchema()),
  });

  const { isLoading: isMessageLoading, data: message } = useGetWelcomeBotMessageByIdQuery(
    { messageId: String(messageId) },
    { skip: isUndefined(messageId) || isEmpty(messageId) },
  );

  const checkState = (serviceName: string) => {
    if (welcomeBotState[serviceName]) {
      return welcomeBotState[serviceName];
    }

    return 'Stars Online';
  };

  const onSubmit: SubmitHandler<{ text: string }> = form => {
    if (isEditing && messageId) {
      updateMessage({
        messageId,
        ...form,
      }).then(res => {
        if ('data' in res) {
          onModalClose();
          toast.success('Отправлено');
        }
      });
    } else {
      sendMessage({
        service,
        ...form,
      }).then(res => {
        if ('data' in res) {
          onModalClose();
          toast.success('Отправлено');
        }
      });
    }
  };

  useEffect(() => {
    if (messageId && isEditing && message) {
      reset({
        text: message?.data.text,
      });
    }
  }, [messageId, isEditing, message]);

  return (
    <ModalWithForm
      onSubmit={handleSubmit(onSubmit)}
      title={'Настройки сообщения'}
      isValid={isValid}
      text={`Настройте приветственные сообщения ${checkState(service)}`}
      {...props}
      noBtn
      isLoading={isLoading || isMessageLoading || isUpdating}
      isColumnBtn
    >
      <TextArea maxLength={4096} register={register('text')} label={'Приветственное сообщение'} />
      <div className={styles.Buttons}>
        <Button className={styles.Btn} variant="primary" type="submit" disabled={!isValid}>
          Подтвердить
        </Button>
        <Button className={styles.BtnCLose} variant="text" onClick={onModalClose}>
          Закрыть
        </Button>
      </div>
    </ModalWithForm>
  );
};
