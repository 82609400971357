import { FC } from 'react';
import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';

import styles from './Video.module.scss';

import { LandingVideo } from 'modules/landing-generator/landing-generator.types';
import DependentText from '../DependentText';

function youtubeParser(url: string) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

type Props = {
  video?: LandingVideo | null;
};

const Video: FC<Props> = ({ video }) => {
  const parsedVideoId = youtubeParser(video?.link ?? '');

  if (isNull(video) || isUndefined(video)) return null;
  return (
    <div className={styles.Wrapper}>
      {video.title && <DependentText type="title" {...video.title} />}

      {video.text && <DependentText type="text" {...video.text} />}

      {parsedVideoId ? (
        <iframe
          className={styles.Frame}
          src={`https://www.youtube.com/embed/${parsedVideoId}`}
        ></iframe>
      ) : (
        <p>Can't load video</p>
      )}
    </div>
  );
};

export default Video;
