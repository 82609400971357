import { FC, ReactNode } from 'react';

import Modal from '../Modal';
import useDisclosure from 'hooks/useDisclosure';
import ModalButton from '../ModalButton';
import { LandingModal } from 'modules/landing-generator/landing-generator.types';

type Props = {
  button?: LandingModal;
  onSetColor: (color: string) => void;
  onRemoveBtn: () => void;
  children: ReactNode;
};

const Modalable: FC<Props> = ({ button, onSetColor, onRemoveBtn, children }) => {
  const { open, onOpen, onClose } = useDisclosure();

  return button ? (
    <>
      <ModalButton
        button={button}
        onClick={onOpen}
        onSetColor={onSetColor}
        onRemoveBtn={onRemoveBtn}
      />

      <Modal open={open} onClose={onClose}>
        {children}
      </Modal>
    </>
  ) : (
    <>{children}</>
  );
};

export default Modalable;
