import { FC, useMemo } from 'react';
import ParsingCard from './ParsingCard';
import styles from './FoldersParsingSequence.module.scss';
import { useGetAllParsedQuery } from '../../vk.api';
import { useParams } from 'react-router-dom';
import { orderBy } from 'lodash-es';
import LoadingState from '../LoadingState';

const FoldersParsingSequence: FC = () => {
  const { id } = useParams();
  const { data, isFetching } = useGetAllParsedQuery({ groupId: id + '' });

  const orderedData = useMemo(() => {
    return orderBy(data?.data?.parsingList, 'id', 'desc');
  }, [data?.data]);

  if (isFetching) return <LoadingState />;

  return (
    <div className={styles.Root}>
      {orderedData?.map(folder => (
        <ParsingCard key={folder.id} {...folder} />
      ))}
    </div>
  );
};

export default FoldersParsingSequence;
