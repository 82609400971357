import { FC, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import styles from './HeaderMobile.module.scss';
import Burger from './components/Burger';
import BellNotifications from 'components/BellNotifications';
import r from 'constants/routes';
import VkAccountsSelector from 'modules/vk/components/VkAccountsSelector';
import BotBuilderLogo from 'modules/bot-builder/components/BotBuilderLogo';
import { useGetPagesQuery } from 'modules/landing-dashboard/landing-dashboard.api';
import { MyPagesMobileModal } from 'modules/my-pages/components/MobileModal/MobileModal';
import { Advertising } from '../Advertising';
import { addsMap } from '../../constants/adds';

const HeaderMobile: FC = () => {
  const { pathname } = useLocation();

  const { data, isLoading } = useGetPagesQuery({});
  const numberOfPages = data?.body?.count ?? 0;

  const isCurrentPath = (path: string): boolean => !!matchPath(path, pathname);

  const showHeader = useMemo(() => {
    if (isCurrentPath(r.myPages.preview)) {
      return false;
    }
    return true;
  }, [pathname]);

  const sections = useMemo(() => {
    if (
      isCurrentPath(r.vk.index) ||
      isCurrentPath(r.vk.groupsActions) ||
      isCurrentPath(r.vk.automationActions)
    ) {
      return {
        center: (
          <Advertising imageUrl={addsMap.header.imgUrl} link={addsMap.header.link} isMobile isRow />
        ),
        right: <VkAccountsSelector />,
      };
    } else if (
      isCurrentPath(r.vk.first3PhotoComments) ||
      isCurrentPath(r.vk.firstPostsComments) ||
      isCurrentPath(r.vk.autoPosting) ||
      isCurrentPath(r.vk.autoStories) ||
      isCurrentPath(r.vk.dbParsing.index) ||
      isCurrentPath(r.vk.dbParsing.edit) ||
      isCurrentPath(r.vk.mailing.index) ||
      isCurrentPath(r.vk.mailing.edit) ||
      isCurrentPath(r.vk.thematicGroups.index) ||
      isCurrentPath(r.vk.thematicGroups.stacks)
    ) {
      return {
        center: (
          <Advertising imageUrl={addsMap.header.imgUrl} link={addsMap.header.link} isMobile isRow />
        ),
        right: <VkAccountsSelector />,
      };
    } else if (
      isCurrentPath(r.botBuilder.index) ||
      isCurrentPath(r.botBuilder.scenarios.index) ||
      isCurrentPath(r.botBuilder.scenarios.scenarios) ||
      isCurrentPath(r.botBuilder.scenarios.mailing)
    ) {
      return {
        center: (
          <Advertising imageUrl={addsMap.header.imgUrl} link={addsMap.header.link} isMobile isRow />
        ),
        right: <BotBuilderLogo />,
      };
    } else if (isCurrentPath(r.landingDashboard.pages)) {
      return {
        center: (
          <Advertising imageUrl={addsMap.header.imgUrl} link={addsMap.header.link} isMobile isRow />
        ),
        right: <BellNotifications iconSize={18} />,
      };
    } else if (
      isCurrentPath(r.landingDashboard.statistics) ||
      isCurrentPath(r.landingDashboard.applications)
    ) {
      return {
        center: (
          <Advertising imageUrl={addsMap.header.imgUrl} link={addsMap.header.link} isMobile isRow />
        ),
        right: <BellNotifications iconSize={18} />,
      };
    } else if (isCurrentPath(r.myPages.edit)) {
      return { center: <></>, right: <MyPagesMobileModal /> };
    } else {
      return {
        center: (
          <Advertising imageUrl={addsMap.header.imgUrl} link={addsMap.header.link} isMobile isRow />
        ),
        right: <BellNotifications iconSize={18} />,
      };
    }
  }, [pathname, numberOfPages, isLoading]);

  if (!showHeader) return null;

  return (
    <header className={styles.Header}>
      <Burger />
      <div className={styles.Center}>{sections.center}</div>
      {sections.right}
    </header>
  );
};

export default HeaderMobile;
