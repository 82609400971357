import { ChangeEvent, FC, useEffect } from 'react';

import styles from './Page.module.scss';
import InputOutline from 'components/InputOutline';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  setValidPageValue,
  setPageBgColor,
  setPageLink,
  setPageName,
} from 'modules/my-pages/my-pages.reducer';
import BgColorSelector from '../../tools/BgColorSelector';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { IMyPage } from 'modules/my-pages/my-pages.intarfaces';
import { myPagesSchema } from 'modules/my-pages/my-pages.schema';

const Page: FC = () => {
  const dispatch = useAppDispatch();
  const page = useActivePage();

  const {
    control,
    formState: { errors },
  } = useForm<IMyPage>({
    mode: 'all',
    resolver: yupResolver(myPagesSchema()),
  });

  const hasErrorMessage = !!Object.keys(errors).length;

  const handleFormChange = (
    onChange: (v: string) => void,
    e: ChangeEvent<HTMLInputElement>,
    type?: string,
  ) => {
    if (type === 'name') {
      dispatch(setPageName(e.target.value));
    } else {
      dispatch(setPageLink(e.target.value));
    }

    onChange(e.target.value);
  };

  useEffect(() => {
    dispatch(setValidPageValue(!hasErrorMessage));
  }, [hasErrorMessage]);

  return (
    <>
      <h5 className={styles.Title}>Настройки страниы</h5>

      <form className={styles.form}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputOutline
              label="Название:"
              defaultValue={page?.name}
              maxLength={120}
              errorMsg={errors.name?.message}
              onChange={e => handleFormChange(field.onChange, e, 'name')}
            />
          )}
        />
        <Controller
          name="link"
          control={control}
          render={({ field }) => (
            <InputOutline
              label="Шорт код страницы:"
              description={
                'придумайте короткое название вашей страницы по которой она будет доступна в сети'
              }
              maxLength={15}
              errorMsg={errors.link?.message}
              defaultValue={page?.link}
              onChange={e => handleFormChange(field.onChange, e)}
            />
          )}
        />
        <BgColorSelector
          color={page?.bgColor}
          setColor={color => dispatch(setPageBgColor(color))}
        />
      </form>
    </>
  );
};

export default Page;
