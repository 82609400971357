import { FC } from 'react';
import ToggleSwitch, { ToggleSwitchProps } from 'components/ToggleSwitch';
import Button from 'components/Button';
import styles from './MainVkCard.module.scss';

type Props = {
  name: string;
  text: string;
  toggle: ToggleSwitchProps;
  toggleText?: string;
  icon?: string;
  onButtonClick?: () => void;
  noBtn?: boolean;
};

const MainVkCard: FC<Props> = ({ icon, noBtn, toggleText, name, text, toggle, onButtonClick }) => {
  return (
    <div className={styles.Card}>
      <div className={styles.Tabs}></div>

      <div className={styles.Header}>
        {icon && <img src={icon} alt={'icon'} />}
        <ToggleSwitch {...toggle} />
        {toggleText && <span className={styles.ToggleText}>{toggleText}</span>}
      </div>
      <h3 className={styles.Name}>{name}</h3>

      <p className={styles.Text}>{text}</p>
      {noBtn ? (
        <div className={styles.NoBtn} />
      ) : (
        <Button
          onClick={onButtonClick}
          className={styles.Btn}
          variant={'tetriary'}
          type={'button'}
          size={'l'}
          disabled={noBtn}
        >
          Настроить
        </Button>
      )}
    </div>
  );
};

export default MainVkCard;
