import { FC, InputHTMLAttributes, useId } from 'react';
import classNames from 'classnames';

import styles from './InputOutline.module.scss';
import Label from 'components/Label';
import { InputIcon } from 'components/Input';
import Icon from 'components/Icon';

type Props = {
  label?: string;
  prefix?: string;
  postfix?: string;
  icon?: InputIcon;
  noBorder?: boolean;
  as?: React.ElementType;
  description?: string;
  wrapperClassName?: string;
  errorMsg?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const InputOutline: FC<Props> = ({
  label,
  prefix,
  postfix,
  icon,
  noBorder,
  as: Input,
  wrapperClassName,
  errorMsg,
  description,
  ...inputProps
}) => {
  const id = useId();

  return (
    <>
      <div className={classNames(styles.Wrapper, wrapperClassName)} data-no-border={noBorder}>
        {label && <Label htmlFor={id}>{label}</Label>}
        {description && <p className={styles.description}>{description}</p>}

        <div className={styles.InputWrapper}>
          {prefix && <div className={styles.Prefix}>{prefix}</div>}

          {Input ? (
            <Input className={styles.Input} id={id} {...inputProps} />
          ) : (
            <input className={styles.Input} id={id} {...inputProps} />
          )}

          {postfix && <div className={styles.Postfix}>{postfix}</div>}

          {icon && (
            <Icon
              className={classNames(styles.Icon, {
                [styles.IconWithClickHandler]: !!icon.onClick,
              })}
              iconName={icon.name}
              onClick={icon.onClick}
              size={24}
            />
          )}
        </div>
      </div>
      {errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}
    </>
  );
};

export default InputOutline;
