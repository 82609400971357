import { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './SmallCard.module.scss';
import Icon from 'components/Icon';
import Button from 'components/Button';

type Props = {
  icon: string;
  link: string;
  title?: string;
  description: string;
  backIcon?: string;
};
const SmallCard: FC<Props> = ({ title, icon, link, description, backIcon }) => {
  return (
    <div className={styles.CardSmall}>
      {backIcon && <img src={backIcon} alt="backIcon" className={styles.BackIcon} />}
      <div className={styles.Content}>
        <div className={styles.IconWrapper}>
          <Icon iconName={icon} size={20} className={styles.Icon} />
        </div>

        <div className={styles.Title}> {title}</div>
        <div className={styles.Text}>{description}</div>
        <Button as={Link} to={link} variant="white">
          Начать
        </Button>
      </div>
    </div>
  );
};

export default SmallCard;
