import { FC } from 'react';
import styles from './SpamBotChatTable.module.scss';
import SpamBotChatTableHead from './SpamBotChatTableHead';
import SpamBotChatTableBody from './SpamBotChatTableBody';
import EmptyText from '../../../../../components/EmptyText';

type Props = {
  isDataEmpty?: boolean;
};

const SpamBotChatTable: FC<Props> = ({ isDataEmpty }) => {
  if (isDataEmpty) return <EmptyText text={'Данный бот пока не добавлен в чаты'} />;

  return (
    <table className={styles.Main}>
      <SpamBotChatTableHead />
      <SpamBotChatTableBody />
    </table>
  );
};

export default SpamBotChatTable;
