import get from 'lodash-es/get';
import find from 'lodash-es/find';
import Listbox from '../../Listbox';
import styles from '../TableGeneric.module.scss';
import Icon from '../../Icon';
import { ComplexColumnResolved, TableProps } from '../TableGeneric.types';

type TableColumnFiltersProps<Data extends object> = Pick<TableProps<Data>, 'filters'> & {
  column: Required<Pick<ComplexColumnResolved<Data>, 'accessor' | 'header' | 'filters'>>;
};

export const TableColumnFilters = <Data extends object>({
  column,
  filters,
}: TableColumnFiltersProps<Data>) => {
  const got = get(filters, column.accessor);

  if (!got) return null;

  const name = find(column.filters, { value: got.selected })?.name ?? column.header;

  return (
    <th>
      <Listbox
        value={got.selected}
        onChange={got.onChange}
        button={
          <>
            <span className={styles.BigText}>{name}</span>
            <div className={styles.Circle}>
              <Icon iconName="chevron-left" size={10} className={styles.Chevron} />
            </div>
          </>
        }
        buttonClassName={styles.TableFiltersButtons}
        options={column.filters}
      />
    </th>
  );
};
