import { FC } from 'react';

import styles from './ElementControls.module.scss';
import Label from 'components/Label';
import { useActiveComponent } from 'modules/my-pages/my-pages.selectors';
import IconButton from 'components/IconButton';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  moveComponent,
  removeComponent,
  setActiveComponent,
} from 'modules/my-pages/my-pages.reducer';
import Button from 'components/Button';
import ControlButton from 'components/ControlButton';
import { getComponentNameAndValue } from 'modules/my-pages/my-pages.helpers';

const ElementControls: FC = () => {
  const dispatch = useAppDispatch();
  const component = useActiveComponent();

  if (!component || !parent) return null;

  const name = getComponentNameAndValue(component?.component, true);

  const canMoveUp = component.component.order !== 0;
  const canMoveDown = component.component.order !== (component.parent.components?.length ?? 0) - 1;

  const onMoveUp = () =>
    dispatch(
      moveComponent({ hash: component.component.hash, newOrder: component.component.order - 1 }),
    );

  const onMoveDown = () =>
    dispatch(
      moveComponent({ hash: component.component.hash, newOrder: component.component.order + 1 }),
    );

  const onClose = () => dispatch(setActiveComponent(null));

  const onRemove = () => {
    dispatch(removeComponent({ hash: component.component.hash }));
    onClose();
  };

  return (
    <>
      <div className={styles.MobileTitle}>{name.title}</div>
      <nav className={styles.Wrapper}>
        <div className={styles.Row}>
          <Label>{name.title}</Label>
          <IconButton iconName="cross" iconSize={24} onClick={onClose} />
        </div>
        <div className={styles.Row}>
          <div className={styles.Controls}>
            <ControlButton
              icon={{ iconName: 'arrow-up-long', size: 16 }}
              size={30}
              disabled={!canMoveUp}
              onClick={onMoveUp}
              text="Выше"
            />
            <ControlButton
              icon={{ iconName: 'arrow-down-long', size: 16 }}
              size={30}
              disabled={!canMoveDown}
              onClick={onMoveDown}
              text="Ниже"
            />
          </div>

          <Button className={styles.DeleteBtn} variant="text" onClick={onRemove}>
            Удалить
          </Button>
        </div>
      </nav>
    </>
  );
};

export default ElementControls;
