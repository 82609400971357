import { FC } from 'react';

import styles from './Form.module.scss';
import { LandingForm } from 'modules/landing-generator/landing-generator.types';
import DependentText from '../DependentText';
import Button from 'components/Button';
import Input from './components/Input';
import isNull from 'lodash-es/isNull';
import { fieldsNamedEntries } from 'modules/landing-generator/landing-generator.getters';
import { useForm } from 'react-hook-form';
import { FormSendRequest } from 'modules/cards/cards.types';
import { useSendFormMutation } from 'modules/landing-dashboard/landing-dashboard.api';

type Props = {
  id: number;
  form?: LandingForm | null;
};

const Form: FC<Props> = ({ id, form }) => {
  const [sendForm] = useSendFormMutation();
  const { handleSubmit, register } = useForm<FormSendRequest>({
    defaultValues: { landingId: id },
  });
  const onSubmit = handleSubmit(data => sendForm({ ...data, componentId: id }));

  if (!form) return null;
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <DependentText type="title" {...form.title} />
        <DependentText type="text" {...form.text} />
      </div>

      <form className={styles.Form} onSubmit={onSubmit}>
        {fieldsNamedEntries.map(
          ([key, value]) =>
            !isNull(form.fields[key]) && (
              <Input key={key} {...register(key)} label={value ?? ''} required />
            ),
        )}

        <Button className={styles.Button} type="submit">
          {form.buttonName}
        </Button>
      </form>
    </div>
  );
};

export default Form;
