import classNames from 'classnames';
import { FC, ReactNode, useId } from 'react';

import styles from './ToggleSwitch.module.scss';

export type ToggleSwitchProps = {
  label?: (active: boolean) => ReactNode;
  active?: boolean;
  toggle?: () => void;
  defaultChecked?: boolean;
  wrapperClassName?: string;
  labelLeft?: boolean;
  labelWidth?: number;
  disabled?: boolean;
};

const ToggleSwitch: FC<ToggleSwitchProps> = ({
  label,
  active = false,
  toggle,
  wrapperClassName,
  labelLeft,
  labelWidth,
  defaultChecked,
  disabled,
}) => {
  const id = useId();

  return (
    <div className={classNames(styles.Wrapper, wrapperClassName)} data-label-left={labelLeft}>
      <div className={styles.Switch}>
        <input
          id={id}
          className={styles.Input}
          checked={active}
          defaultChecked={defaultChecked}
          onChange={toggle}
          type="checkbox"
          disabled={disabled}
        />

        <span className={styles.Slider}>
          <div
            className={classNames(styles.SliderHead, {
              [styles.DisabledSlideHead]: disabled,
            })}
          >
            {disabled && (
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 4.25H6.5V3.25C6.5 1.87 5.38 0.75 4 0.75C2.62 0.75 1.5 1.87 1.5 3.25V4.25H1C0.45 4.25 0 4.7 0 5.25V10.25C0 10.8 0.45 11.25 1 11.25H7C7.55 11.25 8 10.8 8 10.25V5.25C8 4.7 7.55 4.25 7 4.25ZM2.5 3.25C2.5 2.42 3.17 1.75 4 1.75C4.83 1.75 5.5 2.42 5.5 3.25V4.25H2.5V3.25ZM7 10.25H1V5.25H7V10.25ZM4 8.75C4.55 8.75 5 8.3 5 7.75C5 7.2 4.55 6.75 4 6.75C3.45 6.75 3 7.2 3 7.75C3 8.3 3.45 8.75 4 8.75Z"
                  fill="#9B9B9B"
                />
              </svg>
            )}
          </div>
        </span>
      </div>

      {label && (
        <label className={styles.Label} htmlFor={id} style={{ width: labelWidth }}>
          {disabled ? 'Недост.' : label(active)}
        </label>
      )}
    </div>
  );
};

export default ToggleSwitch;
