import { FC } from 'react';
import take from 'lodash-es/take';

import styles from './GroupsStack.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import useDisclosure from 'hooks/useDisclosure';
import GroupsStackModal from '../GroupsStackModal';
import { GroupCardSelectableProps } from '../GroupCardSelectable';

type Props = {
  id: number;
  groups: GroupCardSelectableProps[];
  name: string;
};

const GroupsStack: FC<Props> = ({ groups, name }) => {
  const groupsToRender = take(groups, 5);
  const notRenderedGroupsCount = groups.length - groupsToRender.length;
  const { open, onOpen, onClose } = useDisclosure();

  const onDelete = () => {};

  return (
    <>
      <article className={styles.Card}>
        <div className={styles.Header}>
          <div className={styles.Avatars}>
            {groupsToRender.map((group, index) => (
              <div
                key={group.id}
                className={styles.AvatarWrapper}
                style={{ left: `-${20 * index}px` }}
              >
                <img
                  className={styles.Avatar}
                  src={group.avatar ?? ''}
                  alt={group.name}
                  width={40}
                  height={40}
                />

                {index === groupsToRender.length - 1 && notRenderedGroupsCount > 0 && (
                  <div className={styles.NotRenderedGroupsCount}>{notRenderedGroupsCount}+</div>
                )}
              </div>
            ))}
          </div>
          <Button variant="delete" onClick={onDelete}>
            Удалить
          </Button>
        </div>
        <div className={styles.Content}>
          <div className={styles.Label}>Стопка:</div>
          <button className={styles.Name} onClick={onOpen}>
            {name} <Icon className={styles.Icon} iconName="arrow-right" size={14} />
          </button>
        </div>
      </article>
      <GroupsStackModal open={open} onClose={onClose} groups={groups} name={name} />
    </>
  );
};

export default GroupsStack;
