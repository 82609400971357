import rootApi from 'config/http';
import { EAppTagType, TPaginated, TPaginatedRequest, TResponse } from 'interfaces/api';
import {
  LineDetailsResponse,
  Partner,
  PartnerLines,
  PartnersRequestParams,
} from './partnership-program.types';

const partnershipProgramApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getPartners: builder.query<
      TResponse<TPaginated<Array<Partner>> & { hasReferrals: boolean }>,
      PartnersRequestParams & TPaginatedRequest
    >({
      query: ({ line, ...params }) => ({
        url: `/partners/lines/${line}`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.PARTNERS],
    }),
    getLineDetails: builder.query<LineDetailsResponse, { line: PartnerLines }>({
      query: ({ line }) => ({
        url: `/partners/lines/${line}/profit`,
        method: 'GET',
      }),
      providesTags: [EAppTagType.PARTNERS],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPartnersQuery, useGetLineDetailsQuery } = partnershipProgramApi;

export default partnershipProgramApi;
