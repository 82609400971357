import { FC } from 'react';
import styles from './EmptyPage.module.scss';

const EmptyText: FC<{ text: string }> = ({ text }) => {
  return (
    <div className={styles.Empty}>
      <h1 className={styles.Title}>{text}</h1>
    </div>
  );
};

export default EmptyText;
