import { FC, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import styles from './Generator.module.scss';
import View from 'modules/landing-generator/components/View';
import Editor from 'modules/landing-generator/components/Editor';
import r from 'constants/routes';
import useFirstRender from 'hooks/useFirstRender';
import useAppDispatch from 'hooks/useAppDispatch';
import { useGetLandingsByIDQuery } from 'modules/landing-generator/landings-generator.api';
import { setLandings } from 'modules/landing-generator/landing-generator.reducer';

const Generator: FC = () => {
  const { id } = useParams();
  const { data } = useGetLandingsByIDQuery({ id: id as string }, { skip: !id });

  const dispatch = useAppDispatch();
  useFirstRender(() => dispatch(UndoActionCreators.clearHistory()));

  useEffect(() => {
    if (!data?.body) return;
    if (id) dispatch(setLandings(data.body.map(landing => ({ ...landing, id }))));
  }, [data, id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!id) return <Navigate to={r.landingDashboard.pages} />;

  return (
    <div className={styles.Layout}>
      <View id={id} />
      <Editor id={id} />
    </div>
  );
};

export default Generator;
