import { CSSProperties, FC } from 'react';

import styles from './Website.module.scss';
import {
  LandingIdType,
  Website as TWebsite,
} from 'modules/landing-generator/landing-generator.types';
import DependentText from '../../../DependentText';
import EditableElement from '../../../EditableElement';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  deleteLandingWebsite,
  // setLandingWebsiteColor,
} from 'modules/landing-generator/landing-generator.reducer';
import useDisclosure from 'hooks/useDisclosure';
// import ColorPickerInContextMenu from 'components/ColorPickerInContextMenu';
// import { rgba2string, stringToRgba } from 'utils/colorConverters';

type Props = {
  id: LandingIdType;
  btnId: LandingIdType;
  website: TWebsite;
  index: number;
};

const Website: FC<Props> = ({ id, btnId, website }) => {
  const dispatch = useAppDispatch();
  const { toggleOpen: toggleColorOpen, onClose: onColorClose } = useDisclosure({
    blockScroll: true,
  });

  const cssVars = {
    '--bg-color': website?.color,
  } as CSSProperties;

  return website ? (
    <EditableElement
      menuElements={[
        { iconName: 'pick-color', onClick: toggleColorOpen },
        { iconName: 'delete', onClick: () => dispatch(deleteLandingWebsite({ id, btnId })) },
      ]}
      onClose={onColorClose}
      // menuAddon={
      //   <ColorPickerInContextMenu
      //     open={colorOpen}
      //     color={stringToRgba(website.color)}
      //     setColor={color =>
      //       dispatch(setLandingWebsiteColor({ id, btnId, index, color: rgba2string(color) }))
      //     }
      // />
      // }
    >
      <div className={styles.Wrapper}>
        <div className={styles.Content} style={cssVars}>
          <DependentText type="subtitle" {...website.title} />
          <DependentText type="text" {...website.text} />
        </div>
      </div>
    </EditableElement>
  ) : null;
};

export default Website;
