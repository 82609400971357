import { FC, Fragment, useState } from 'react';
import { Dialog, Tab, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { NotificationTypes } from 'modules/notifications/notifications.types';
import { useGetMailQuery, useReadMailMutation } from 'modules/notifications/notifications.api';

import NotificationPanel from '../NotificationPanel';
import IconButton from 'components/IconButton';
import Icon from 'components/Icon';

import styles from './NotificationsDrawer.module.scss';

const MessageOptions = [
  {
    name: 'Сообщения',
    type: NotificationTypes.MESSAGE,
  },
  {
    name: 'Напоминания',
    type: NotificationTypes.NOTIFICATION,
  },
];

type Props = {
  open?: boolean;
  onClose: () => void;
};
const NotificationsDrawer: FC<Props> = ({ open, onClose }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [readMail] = useReadMailMutation();
  const { data: mails } = useGetMailQuery({ type: MessageOptions[selectedIndex].type });

  return (
    <Transition
      show={open}
      as={Fragment}
      enter={styles.Transition}
      enterFrom={styles.TransitionEFrom}
      enterTo={styles.TransitionETo}
      leave={styles.Transition}
      leaveFrom={styles.TransitionLFrom}
      leaveTo={styles.TransitionLFTo}
    >
      <Dialog onClose={onClose} as={Fragment}>
        <div className={styles.DrawerWrapper}>
          <Dialog.Panel className={styles.Drawer}>
            <Dialog.Title className={styles.Title}>
              <IconButton
                className={styles.Cross}
                iconClassName={styles.CrossIcon}
                iconName="cross"
                iconSize={25}
                onClick={onClose}
              />
              <span className={styles.TitleText}>Уведомления</span>
              <button
                className={styles.TitleButton}
                onClick={() => {
                  if (mails) readMail(mails.body.items.map(mail => mail.id));
                }}
              >
                <Icon iconName="double-check" size={20} />
                <span>Отметить все как прочитанные</span>
              </button>
            </Dialog.Title>
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
              <Tab.List className={styles.Tabs}>
                {MessageOptions.map(({ name, type }) => (
                  <Tab
                    key={name}
                    className={({ selected }) =>
                      classNames(styles.Tab, {
                        [styles.Active]: selected,
                      })
                    }
                  >
                    <span>{name}</span>
                    <span
                      className={classNames(styles.Number, {
                        [styles.Notification]: type === NotificationTypes.NOTIFICATION,
                      })}
                    ></span>
                  </Tab>
                ))}
                <div className={styles.Slider} data-tab={selectedIndex} />
              </Tab.List>
              <Tab.Panels className={styles.TabsPanels} id="notifications-container">
                {MessageOptions.map(({ type }, idx) => (
                  <Tab.Panel key={idx} as={'div'}>
                    <NotificationPanel type={type} />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NotificationsDrawer;
