import Icon from 'components/Icon';
import { FC } from 'react';

import styles from './NoMessages.module.scss';

const NoMessages: FC = () => {
  return (
    <div className={styles.NoMessages}>
      <Icon iconName="empty-smile" size={64} />
      <p className={styles.Text}>
        Пока тут ничего нет, когда будут уведомления их можно будет просмотреть тут
      </p>
    </div>
  );
};

export default NoMessages;
