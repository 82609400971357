import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './CreatePageBtn.module.scss';
import Button from 'components/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { toggleNewCardDialog } from 'modules/landing-dashboard/landing-dashboard.reducer';

type Props = {
  children: ReactNode;
  className?: string;
};

const CreatePageBtn: FC<Props> = ({ children, className }) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      variant="magic"
      className={classNames(styles.Button, className)}
      onClick={() => dispatch(toggleNewCardDialog())}
    >
      {children}
    </Button>
  );
};

export default CreatePageBtn;
