import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TAdminBotState,
  TAdminChatsParams,
  TPaginationParams,
  TTelegramChat,
} from '../telegram.types';
import { adminBotChatParams, dataCountLoadingState, paginationParams } from './telegram.constants';
import { tgSpamApiInjected } from './telegram.spam.api';

type TState = {
  spamBotChats: TAdminBotState<TTelegramChat[]>;
  spamChatsParams: TAdminChatsParams;
  scheduledSpamMessagesPageParams: TPaginationParams;
  selectedSpamBotChats: string[];
  sentSpamBotMessagesPageParams: TPaginationParams;
};

const initialState: TState = {
  spamBotChats: dataCountLoadingState,
  spamChatsParams: adminBotChatParams,
  scheduledSpamMessagesPageParams: { ...paginationParams, limit: 5 },
  selectedSpamBotChats: [],
  sentSpamBotMessagesPageParams: { ...paginationParams, limit: 5 },
};

export const telegramSpamSlice = createSlice({
  name: 'telegramSpamBot',
  initialState,
  reducers: {
    setSpamChatsParams: (state, { payload }: PayloadAction<TAdminChatsParams>) => {
      state.spamChatsParams = {
        ...state.spamChatsParams,
        ...payload,
      };
    },

    setSelectSpamChatsById: (state, { payload }: PayloadAction<Pick<TTelegramChat, 'id'>>) => {
      state.spamBotChats.data = state.spamBotChats.data.map(chat => {
        if (payload.id === chat.id) {
          return {
            ...chat,
            isSelected: !chat.isSelected,
          };
        }
        return chat;
      });
    },

    setSelectedAllChats: state => {
      if (state.spamBotChats.data.every(obj => obj.isSelected)) {
        state.spamBotChats.data = state.spamBotChats.data.map(obj => ({
          ...obj,
          isSelected: false,
        }));
      } else {
        state.spamBotChats.data = state.spamBotChats.data.map(obj => ({
          ...obj,
          isSelected: true,
        }));
      }
    },

    setSpamScheduledMessagesPageParams: (state, { payload }: PayloadAction<TPaginationParams>) => {
      state.scheduledSpamMessagesPageParams = {
        ...state.scheduledSpamMessagesPageParams,
        ...payload,
      };
    },

    setSpamSentMessagesPageParams: (state, { payload }: PayloadAction<TPaginationParams>) => {
      state.sentSpamBotMessagesPageParams = {
        ...state.sentSpamBotMessagesPageParams,
        ...payload,
      };
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      tgSpamApiInjected.endpoints.getAllSpamChatsBots.matchFulfilled,
      (state, { payload }) => {
        const data = payload.data.map(obj => ({
          ...obj,
          isSelected: false,
        }));
        state.spamBotChats = {
          count: payload.count,
          loading: false,
          data,
        };
      },
    );
  },
});

export const {
  setSpamChatsParams,
  setSelectSpamChatsById,
  setSelectedAllChats,
  setSpamScheduledMessagesPageParams,
  setSpamSentMessagesPageParams,
} = telegramSpamSlice.actions;
