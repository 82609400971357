import { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash-es/isEmpty';

import styles from './ButtonEditor.module.scss';

import {
  EOrientation,
  LandingButtonForm,
  LandingIdType,
} from 'modules/landing-generator/landing-generator.types';
import {
  useSelectLandingButton,
  useSelectPrevLandingButton,
} from 'modules/landing-generator/landing-generator.selectors';
import EditorForm from '../EditorForm';
import EditorTitle from '../EditorTitle';
import GoBack from 'components/GoBack';
import CheckboxSelect from 'components/CheckboxSelect';
import Input from 'components/Input';
import EditorBtn from '../EditorBtn';
import { landingButtonSchema } from 'modules/landing-generator/landing-generator.schema';
import { convertLandingFormToLandingButton } from 'modules/landing-generator/landing-generator.converters';
import useAppDispatch from 'hooks/useAppDispatch';
import { setLandingButton } from 'modules/landing-generator/landing-generator.reducer';
import WebsiteForm from '../WebsiteForm';

import { compareLandingButtons } from 'modules/landing-generator/landing-generator.comparators';
import { GlobalIdInstance } from 'modules/landing-generator/landing-generator.utils';

import AddFieldButton from '../AddFieldButton';
import EditorSubtitle from '../EditorSubtitle';

type Props = {
  landingId: LandingIdType;
  onBack: () => void;
  buttonId?: string | number;
};

const ButtonEditor: FC<Props> = ({ landingId, buttonId, onBack }) => {
  const dispatch = useAppDispatch();
  const [btnId, setBtnId] = useState(buttonId);
  const button = useSelectLandingButton(landingId, btnId ?? '');
  const prevButton = useSelectPrevLandingButton(landingId, btnId ?? '');

  useEffect(() => {
    setBtnId(buttonId);
  }, [buttonId]);

  const defaultValues = useMemo(
    () =>
      button
        ? {
            vk: button.vk?.content,
            viber: button.viber?.content,
            whatsapp: button.whatsapp?.content,
            instagram: button.instagram?.content,
            facebook: button.facebook?.content,
            telegram: button.telegram?.content,
            orientation: button.orientation,
            inModal: !!button.modal,
            modalText: button.modal?.buttonName,
            websites: !isEmpty(button.websites)
              ? button.websites.map(w => ({
                  title: w.title.content,
                  text: w.text.content,
                  url: w.url,
                }))
              : [{ title: '', text: '', url: '' }],
          }
        : {
            inModal: false,
            orientation: EOrientation.VERTICAL,
            websites: [{ title: '', text: '', url: '' }],
          },
    [button],
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { isValid, errors },
  } = useForm<LandingButtonForm>({
    mode: 'onChange',
    resolver: yupResolver(landingButtonSchema()),
    defaultValues,
  });

  useEffect(() => {
    if (button && prevButton) {
      if (!compareLandingButtons(button, prevButton)) {
        reset(defaultValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, defaultValues]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'websites',
  });

  const values = watch();

  const onSubmit: SubmitHandler<LandingButtonForm> = data => {
    const converted = convertLandingFormToLandingButton(data, button);
    const newId = btnId ?? GlobalIdInstance.inc();
    if (!btnId) setBtnId(newId);
    dispatch(setLandingButton({ id: landingId, btn: { id: newId, ...converted } }));
  };

  return (
    <EditorForm hasBack onSubmit={handleSubmit(onSubmit)}>
      <GoBack onClick={onBack} />

      <EditorTitle>Добавление социальных сетей</EditorTitle>

      <div>
        <CheckboxSelect
          title="Расставление"
          selected={values.orientation}
          selectValue={value => setValue('orientation', value, { shouldValidate: true })}
          options={[
            { text: 'Расставить в линию', value: EOrientation.VERTICAL },
            { text: 'Расставить в ряд', value: EOrientation.HORIZONTAL },
          ]}
        />

        <CheckboxSelect
          title="Расположение"
          selected={values.inModal}
          selectValue={value => setValue('inModal', value, { shouldValidate: true })}
          options={[
            {
              text: 'Расположить в модальном окне',
              value: true,
              contentIfSelected: (
                <Input
                  wrapperClassName={styles.ModalInput}
                  label="Название кнопки"
                  register={register('modalText')}
                />
              ),
            },
            { text: 'Расположить без дополнительной кнопки и модального окна', value: false },
          ]}
        />
      </div>

      <EditorSubtitle>Добавление ссылки на сайт</EditorSubtitle>

      {fields.map((field, index) => (
        <WebsiteForm
          key={field.id}
          id={landingId}
          btnId={btnId}
          index={index}
          register={register}
          renderInAccordion={fields.length > 1}
          onRemove={() => remove(index)}
          getError={key => {
            const { websites } = errors;
            if (websites) {
              return websites[index]?.[key]?.message;
            }
          }}
        />
      ))}

      <AddFieldButton append={() => append({ title: '', text: '', url: '' })}>
        Добавить сайт
      </AddFieldButton>

      <EditorBtn className={styles.SaveBtn} disabled={!isValid} type="submit">
        Сохранить
      </EditorBtn>
    </EditorForm>
  );
};

export default ButtonEditor;
