import { FC, useEffect } from 'react';

import styles from './AnchorInput.module.scss';
import Tool from '../Tool';
import ToggleSwitch from 'components/ToggleSwitch';
import InputOutline from 'components/InputOutline';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

type Props = {
  anchor?: string;
  setAnchor: (value?: string) => void;
  setAnchorActive?: (value?: boolean) => void;
  active?: boolean;
};

const AnchorInput: FC<Props> = ({ anchor, setAnchor, active, setAnchorActive }) => {
  const { copyAndNotify } = useCopyToClipboard();

  useEffect(() => {
    if (!active) {
      setAnchor();
    }
  }, [active]);

  return (
    <Tool label="Якорь" right={<ToggleSwitch active={active} toggle={setAnchorActive} />}>
      {active && (
        <InputOutline
          wrapperClassName={styles.Input}
          prefix="#"
          value={anchor}
          onChange={e => setAnchor(e.target.value)}
          icon={anchor ? { name: 'copy', onClick: () => copyAndNotify(anchor) } : undefined}
          noBorder
        />
      )}
    </Tool>
  );
};

export default AnchorInput;
