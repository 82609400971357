import { useEffect } from 'react';

function useScrollLockFix() {
  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(() => {
        if (document.body.style.overflow === 'hidden') {
          document.body.style.overflow = 'clip';
        }
      });
    });

    const target = document.body;
    observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    return observer.disconnect;
  }, []);
}

export default useScrollLockFix;
