import packageJson from '../../package.json';
import { IEnv, IEnvFile } from 'interfaces/env';
import parseEnvironmentVariables from 'utils/parseEnvVariables';

const envFile = {
  APP_VERSION: packageJson.version,
  APP_FRONT: process.env.REACT_APP_FRONT,
  APP_API: process.env.REACT_APP_API,
  APP_IMAGES: process.env.REACT_APP_IMAGES,
  ENVIRONMENT: process.env.REACT_APP_ENV,
  CURRENCY: process.env.REACT_APP_CURRENCY,
  LOCALE: process.env.REACT_APP_LOCALE,
  ZOOM_AUTH: process.env.REACT_APP_ZOOM_AUTH,
  ZOOM_API: process.env.REACT_APP_ZOOM_API,
  ZOOM_ID: process.env.REACT_APP_ZOOM_ID,
  ZOOM_SECRET: process.env.REACT_APP_ZOOM_SECRET,
  ZOOM_SDK_KEY: process.env.REACT_APP_ZOOM_SDK_KEY,
  APP_API_TG: process.env.REACT_APP_TG_API,
  APP_API_VK: process.env.REACT_APP_VK_API,
  VK_URL: process.env.REACT_APP_VK_URL,
  LANDING_URL: process.env.REACT_APP_LANDING_URL,
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
  APP_ID_VK: process.env.REACT_APP_ID_VK,
  WITHDRAW_COMMISSION: process.env.REACT_APP_WITHDRAW_COMMISSION,
};

const {
  APP_VERSION,
  APP_API,
  APP_API_TG,
  APP_API_VK,
  ENVIRONMENT,
  CURRENCY,
  APP_IMAGES,
  APP_FRONT,
  LOCALE,
  ZOOM_AUTH,
  ZOOM_API,
  ZOOM_ID,
  ZOOM_SECRET,
  ZOOM_SDK_KEY,
  VK_URL,
  LANDING_URL,
  SENTRY_DNS,
  APP_ID_VK,
  WITHDRAW_COMMISSION,
} = parseEnvironmentVariables(envFile) as unknown as IEnvFile;

const env: IEnv = Object.freeze({
  APP_VERSION,
  APP_FRONT,
  APP_IMAGES,
  APP_API,
  APP_API_TG,
  ZOOM_AUTH,
  ZOOM_API,
  ZOOM_ID,
  ZOOM_SECRET,
  ZOOM_SDK_KEY,
  VK_URL,
  APP_API_VK,
  LANDING_URL,
  SENTRY_DNS,
  ENVIRONMENT,
  IS_PRODUCTION: ENVIRONMENT === 'live',
  IS_DEVELOPMENT: ENVIRONMENT === 'development',
  IS_STAGING: ENVIRONMENT === 'staging',
  CURRENCY,
  LOCALE,
  APP_ID_VK,
  WITHDRAW_COMMISSION,
});

declare global {
  interface Window {
    soConfig: IEnv;
  }
}

if (env.IS_DEVELOPMENT || env.IS_STAGING) {
  // eslint-disable-next-line no-console
  console.log(env);
} else if (env.IS_PRODUCTION) {
  window.soConfig = env;
}

export default env;
