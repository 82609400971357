import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from './NewFinPasswordModal.module.scss';

import { CommonModalProps } from 'components/Modal';
import PasswordInput from 'components/PasswordInput';
import SettingsFormModal from '../SettingsFormModal';
import { NewPasswordRequestBody } from 'modules/settings/settings.types';
import { newPasswordSchema } from 'modules/settings/settings.schema';
import { useChangeFinPasswordMutation } from 'modules/settings/settings.api';
import toast from '../../../../toasts';

const NewFinPasswordModal: FC<CommonModalProps> = props => {
  const [changeFinPassword] = useChangeFinPasswordMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<NewPasswordRequestBody>({
    mode: 'onChange',
    resolver: yupResolver(newPasswordSchema()),
  });

  const onSubmit: SubmitHandler<NewPasswordRequestBody> = data =>
    changeFinPassword({ oldPassword: data.old, password: data.new }).then(res => {
      if ('data' in res) {
        toast.success('Успешно');
        reset();
        props.onClose();
      }
    });

  return (
    <SettingsFormModal
      title="Новый финансовый пароль"
      text="Придумайте финансовый пароль, который ранее не был использован"
      btnText="Подтвердить"
      isValid={isValid}
      reset={reset}
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <PasswordInput
        label="Старый пароль"
        register={register('old')}
        invalid={!!errors.old}
        errorMsg={errors.old?.message}
      />

      {/* <Button variant="text" type="button" className={styles.TextLink}>
        Забыли пароль?
      </Button> */}

      <div className={styles.FormFields}>
        <PasswordInput
          label="Новый пароль"
          register={register('new')}
          invalid={!!errors.new}
          errorMsg={errors.new?.message}
        />
        <PasswordInput
          label="Подтвердить пароль"
          register={register('new2')}
          invalid={!!errors.new2}
          errorMsg="Пароли не совпадают"
        />
      </div>
    </SettingsFormModal>
  );
};

export default NewFinPasswordModal;
