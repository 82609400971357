import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './ColorfulBorder.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

const ColorfulBorder: FC<Props> = ({ children, className, onClick }) => {
  return (
    <div onClick={onClick} className={classNames(styles.Wrapper, className)}>
      {children}
    </div>
  );
};

export default ColorfulBorder;
