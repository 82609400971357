import { FC, ReactNode } from 'react';

import styles from './ButtonWithArrow.module.scss';

type Props = {
  children: ReactNode;
};

const ButtonWithArrow: FC<Props> = ({ children }) => {
  return <button className={styles.Btn}>{children}</button>;
};

export default ButtonWithArrow;
