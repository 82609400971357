import { FC, ReactNode } from 'react';

import styles from './SectionTitle.module.scss';
import Title from 'components/Title';

type Props = {
  children: ReactNode;
};

const SectionTitle: FC<Props> = ({ children }) => {
  return <Title className={styles.Title}>{children}</Title>;
};

export default SectionTitle;
