import { FC, useEffect } from 'react';
import * as VKID from '@vkid/sdk';
import Button, { ButtonProps } from 'components/Button';
import AddAccountModal from '../AddAccountModal';
import useDisclosure from 'hooks/useDisclosure';
import { useGetAllVkAccounts } from '../../vk.selectors';
import { useSearchParams } from 'react-router-dom';
import env from 'config/env';

type Props = {
  buttonSize?: ButtonProps<'button'>['size'];
};

const AddAccountBtn: FC<Props> = ({ buttonSize = 'l' }) => {
  const { open: modalOpen, onClose, onOpen } = useDisclosure();
  const vkAccounts = useGetAllVkAccounts();
  const [searchParams, setSearchParams] = useSearchParams();
  const vkPayload = searchParams.get('payload');

  VKID.Config.set({
    app: Number(env.APP_ID_VK), // Идентификатор приложения.
    redirectUrl: `${env.APP_FRONT}/vk`, // Адрес для перехода после авторизации.
    state: crypto.randomUUID(), // Произвольная строка состояния приложения.
  });

  const handleClick = () => {
    // Открытие авторизации.
    VKID.Auth.login();
  };

  const handleClose = () => {
    setSearchParams({});
    onClose();
  };

  useEffect(() => {
    if (vkPayload) {
      onOpen();
    }
  }, [vkPayload]);

  if (vkAccounts.length) return null;

  return (
    <>
      <Button id="VKIDSDKAuthButton" variant="primary" size={buttonSize} onClick={handleClick}>
        Добавить аккаунт
      </Button>

      <AddAccountModal vkPayload={vkPayload} isOpen={modalOpen} onClose={handleClose} />
    </>
  );
};

export default AddAccountBtn;
