import { createApi } from '@reduxjs/toolkit/query/react';
import { EAppVKTagType } from 'interfaces/api';
import env from './env';
import { makeBaseQuery, makeBaseQueryWithRefresh } from './http';

const vkBaseQuery = makeBaseQuery(env.APP_API_VK + '/api/v1');

export const vkApi = createApi({
  reducerPath: 'vkApi',
  baseQuery: makeBaseQueryWithRefresh(vkBaseQuery),
  tagTypes: Object.values(EAppVKTagType),
  endpoints: () => ({}),
});

export default vkApi;
