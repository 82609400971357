import { FC } from 'react';
import styles from './AutomationStorage.module.scss';
import { useGetAutomationStorageInfoQuery } from '../../vk.api';
import { formatBytesToGB } from 'utils/formatBytesToGb';

const AutomationStorage: FC = () => {
  const { data: storage } = useGetAutomationStorageInfoQuery();

  return (
    <div className={styles.Root}>
      <h1 className={styles.Title}>Объем хранилища:</h1>{' '}
      <span className={styles.Title}>
        {formatBytesToGB(storage?.data.usedCapacity)} из{' '}
        {formatBytesToGB(storage?.data.maximumCapacity)}
      </span>
    </div>
  );
};

export default AutomationStorage;
