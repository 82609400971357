import { FC } from 'react';

import styles from './LastActivitySelector.module.scss';
import InputWithDropdown from 'components/InputWithDropdown';
import { ELastActivity } from 'interfaces/common';
import { InputProps } from 'components/Input';

type Props = {
  selected?: ELastActivity;
  select: (value: ELastActivity) => void;
  openingType?: 'up' | 'down';
} & Omit<InputProps, 'label'>;

const LastActivitySelector: FC<Props> = ({ selected, select, openingType, ...inputProps }) => {
  return (
    <InputWithDropdown
      label="Последняя активность"
      menuClassName={styles.ActivityMenu}
      openingType={openingType}
      options={[
        {
          label: ELastActivity.TODAY,
          isSelected: selected === ELastActivity.TODAY,
          onClick: () => {
            select(ELastActivity.TODAY);
          },
        },
        {
          label: ELastActivity.YESTERDAY,
          isSelected: selected === ELastActivity.YESTERDAY,
          onClick: () => select(ELastActivity.YESTERDAY),
        },
        {
          label: ELastActivity.LAST_WEEK,
          isSelected: selected === ELastActivity.LAST_WEEK,
          onClick: () => select(ELastActivity.LAST_WEEK),
        },
        {
          label: ELastActivity.NOT_IMPORTANT,
          isSelected: selected === ELastActivity.NOT_IMPORTANT,
          onClick: () => select(ELastActivity.NOT_IMPORTANT),
        },
      ]}
      {...inputProps}
    />
  );
};

export default LastActivitySelector;
