import { FC, ReactNode } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import styles from './BotsList.module.scss';
import BotCard, { BotCardProps } from 'components/BotCard';

type Props = {
  bots?: ({ id: number | string } & BotCardProps)[];
  extreBots?: ReactNode | ReactNode[];
  emptyMessage?: ReactNode;
  rightSection?: ReactNode;
};

const BotsList: FC<Props> = ({ emptyMessage, bots, extreBots, rightSection }) => {
  const botsIsEmpty = isEmpty(bots);
  return (
    <section className={styles.Wrapper}>
      {botsIsEmpty ? (
        emptyMessage
      ) : (
        <>
          <section className={styles.Bots}>
            {bots?.map(bot => {
              return <BotCard key={bot.id} {...bot} />;
            })}
            {extreBots}
          </section>
          {rightSection && <div className={styles.RightSection}>{rightSection}</div>}
        </>
      )}
    </section>
  );
};

export default BotsList;
