import { FC } from 'react';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import UndoRedo from 'components/UndoRedo';
import { useSelectCanUndoRedoLandingGenerator } from 'modules/landing-generator/landing-generator.selectors';
import useAppDispatch from 'hooks/useAppDispatch';

const LandingGeneratorUndoRedo: FC = () => {
  const dispatch = useAppDispatch();
  const { canUndo, canRedo } = useSelectCanUndoRedoLandingGenerator();

  const undo = () => dispatch(UndoActionCreators.undo());
  const redo = () => dispatch(UndoActionCreators.redo());

  return <UndoRedo undo={undo} redo={redo} canUndo={canUndo} canRedo={canRedo} />;
};

export default LandingGeneratorUndoRedo;
