import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Tariff.module.scss';
import GoBack from 'components/GoBack';
import CardHolder from 'modules/tariff/components/CardHolder';
import r from 'constants/routes';
import Title from 'components/Title';

const Tariff: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.Page}>
      <GoBack
        className={styles.GoBack}
        backWord="Назад на главную"
        onClick={() => navigate(r.account)}
      />
      <Title className={styles.Title}>Тарифные планы</Title>

      <CardHolder />
    </div>
  );
};

export default Tariff;
