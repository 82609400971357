import { Route, Routes } from 'react-router-dom';

import 'config/env';
import r from 'constants/routes';
import Layout from 'components/Layout';
import useTypedSelector from 'hooks/useAppSelector';
import { selectAppState } from 'modules/app/app.selectors';
// modules
import BasicLayout from 'components/BasicLayout';
import AccountRoutes from 'modules/account';
import SettingsRoutes from 'modules/settings';
import AuthRoutes from 'modules/auth';
import PartnershipProgramRoutes from 'modules/partnership-program';
import FinancesRoutes from 'modules/finances';
import LandingGeneratorRoutes from 'modules/landing-generator';
import LandingDashboardRoutes from 'modules/landing-dashboard';
import VkRoutes from 'modules/vk';
import TelegramRoutes from 'modules/telegram';
import TariffRoutes from 'modules/tariff';
import NotificationsRoutes from 'modules/notifications';
import PublicCardRoutes from 'modules/cards';
import MyPagesRoutes from 'modules/my-pages';
import MyPagesPublicRoutes from 'modules/my-pages/public-routes';
import BotBuilderRoutes from 'modules/bot-builder';

import 'i18n';
import Published from 'modules/my-pages/pages/Published';
import useScrollLockFix from 'hooks/useScrollLockFix';
import LoadingState from 'components/LoadingState';

function App(): JSX.Element {
  // const { initLocale } = useLocale();
  const { initializing } = useTypedSelector(selectAppState);
  useScrollLockFix();

  // useComponentWillMount(() => initLocale());

  if (initializing) return <LoadingState />;

  return (
    <Routes>
      {AuthRoutes()}
      {PublicCardRoutes()}
      {MyPagesPublicRoutes()}

      <Route path={r.root} element={<Layout />}>
        <Route path={r.root} element={<BasicLayout />}>
          {AccountRoutes()}
          {SettingsRoutes()}
          {PartnershipProgramRoutes()}
          {FinancesRoutes()}
          {NotificationsRoutes()}
          {TariffRoutes()}
        </Route>

        {LandingDashboardRoutes()}
        {LandingGeneratorRoutes()}
        {MyPagesRoutes()}
        {VkRoutes()}
        {TelegramRoutes()}
        {BotBuilderRoutes()}
      </Route>

      {/* Public routes */}
      <Route path={r.myPages.published} element={<Published />} />
    </Routes>
  );
}

export default App;
