import rootApi from 'config/http';
import { EAppTagType, TPaginated, TPaginatedRequest, TResponse } from 'interfaces/api';
import {
  MessageType,
  NotificationTypes,
  SendMessageAllPartnersRequest,
  SendMessageRequest,
} from './notifications.types';

const notificationsApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getMail: builder.query<
      TResponse<TPaginated<Array<MessageType>>>,
      TPaginatedRequest & { type: NotificationTypes }
    >({
      query: ({ type, ...params }) => ({
        url: `/mails/${type}`,
        method: 'GET',
        params,
      }),
      providesTags: [EAppTagType.NOTIFICATIONS],
    }),
    sendMessage: builder.mutation<TResponse<string>, SendMessageRequest>({
      query: body => ({
        url: '/mails/messages',
        method: 'POST',
        body,
      }),
    }),
    sendMessageAllPartners: builder.mutation<TResponse<string>, SendMessageAllPartnersRequest>({
      query: body => ({
        url: '/mails/messages/send-all',
        method: 'POST',
        body,
      }),
    }),
    readMail: builder.mutation<TResponse<string>, Array<string | number>>({
      query: body => ({
        url: '/mails/mark-as-read',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppTagType.NOTIFICATIONS],
    }),
    deleteMail: builder.mutation<TResponse<string>, Array<string | number>>({
      query: body => ({
        url: '/mails',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [EAppTagType.NOTIFICATIONS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMailQuery,
  useReadMailMutation,
  useSendMessageMutation,
  useSendMessageAllPartnersMutation,
  useDeleteMailMutation,
} = notificationsApi;

export default notificationsApi;
