import Button from 'components/Button';
import GroupsGrayModal from '../GroupsGrayModal';
import Input from 'components/Input';
import GroupNameDisplay from '../GroupNameDisplay';
import useDisclosure from 'hooks/useDisclosure';
import styles from './FormingFolders.module.scss';
import {
  useGetVkSelectedGroupsId,
  useGetVkSelectedGroupsSubscribersCount,
} from '../../vk.selectors';
import { isEmpty, size } from 'lodash-es';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFolderScheme } from '../../vk.schema';
import { TFormAddFolder } from '../../vk.types';
import { useAddFolderGroupMutation } from '../../vk.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { clearSelectedGroupsId, setSelectedGroupsId } from '../../vk.reducer';
import toast from 'toasts';
import Icon from 'components/Icon';

const FormingFoldersBtn = () => {
  const { open, onClose, onOpen } = useDisclosure({ blockScroll: true });
  const selectedGroups = useGetVkSelectedGroupsId();
  const subscribersCount = useGetVkSelectedGroupsSubscribersCount();
  const isDisabled =
    isEmpty(selectedGroups) || (subscribersCount >= 500000 && size(selectedGroups) > 1);
  const [addGroupFolder] = useAddFolderGroupMutation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<TFormAddFolder>({
    mode: 'onChange',
    resolver: yupResolver(createFolderScheme()),
  });

  const onSubmit: SubmitHandler<TFormAddFolder> = async ({ folderName }) => {
    const ids = Object.keys(selectedGroups).map(Number);
    const res = await addGroupFolder({
      ids,
      folderName,
    });

    if ('data' in res) {
      dispatch(clearSelectedGroupsId());
      toast.success('Успешно!');
      reset();
    }
    onClose();
  };

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
        }}
        variant="primary"
        className={styles.Root}
        disabled={isDisabled}
      >
        Сформировать папку
      </Button>
      <GroupsGrayModal
        isOpen={open}
        onClose={onClose}
        text={
          'Максимальное число пользователей в выбранных группах для проведения парсинга не должно превышать 500 000 подписчиков'
        }
        title={'Создать папку'}
        onSubmit={handleSubmit(onSubmit)}
        isValid={isValid}
      >
        <Input
          register={register('folderName')}
          errorMsg={errors.folderName?.message}
          invalid={!!errors.folderName?.message}
          label={'Название *'}
        />
        <div className={styles.CardsContainer}>
          {Object.values(selectedGroups).map(group => (
            <div key={group.id} className={styles.CardsInnerContainer}>
              <GroupNameDisplay
                title={group.name}
                subTitle={group.membersCount + ' подписчика '}
                image={group.photoUrl}
              />
              <Icon
                className={styles.Icon}
                iconName={'alert-delete'}
                onClick={() => {
                  dispatch(setSelectedGroupsId(group));
                }}
              />
            </div>
          ))}
        </div>
      </GroupsGrayModal>
    </>
  );
};

export default FormingFoldersBtn;
