import { FC } from 'react';
import { generatePath } from 'react-router-dom';

import styles from './MediumCard.module.scss';
import r from 'constants/routes';
import Icon from 'components/Icon';
import { PartnerLines } from 'modules/partnership-program/partnership-program.types';
import LinkOverlay from 'components/LinkOverlay';

type Props = {
  id: PartnerLines;
  backPhoto: string;
  num: string;
  background: string;
};

const MediumCard: FC<Props> = ({ id, num, backPhoto, background }) => {
  return (
    <div className={styles.CardMedium} data-back={background}>
      <img src={num} alt="num" className={styles.Num} width={78} height={82} />
      <p className={styles.Line}>Линия</p>
      <div className={styles.Link}>
        <span className={styles.LinkText}>Перейти</span>
        <Icon className={styles.LinkIcon} iconName="arrow-right" size={10} />
      </div>
      <img src={backPhoto} alt="back" className={styles.BackGround} />

      <LinkOverlay to={generatePath(r.partnershipProgram.index, { line: id })} />
    </div>
  );
};

export default MediumCard;
