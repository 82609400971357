import { FC } from 'react';
import classNames from 'classnames';

import { DropdownOption } from 'components/DropdownMenu';
import Icon from 'components/Icon';
import styles from './OptionsMenu.module.scss';
import DropdownTemplate from 'components/DropdownTemplate';

type Props = {
  options: DropdownOption[];
  iconName?: string;
  menuClassName?: string;
};

const OptionsMenu: FC<Props> = ({ options, iconName = 'options', menuClassName }) => {
  return (
    <DropdownTemplate
      button={{
        content: () => <Icon className={styles.Icon} iconName={iconName} size={24} />,
        className: styles.Btn,
      }}
      menu={{ options, className: classNames(styles.Menu, menuClassName) }}
      wrapperClassName={styles.Wrapper}
    />
  );
};

export default OptionsMenu;
