import { Menu } from '@headlessui/react';
import IconButton from 'components/IconButton';
import { FC } from 'react';
import styles from './MobileModal.module.scss';
import { generatePath, useNavigate } from 'react-router-dom';
import useSavePage from '../../hooks/useSavePage';
import r from 'constants/routes';
import toast from 'toasts';
import { useActivePage } from 'modules/my-pages/my-pages.selectors';
import { setOpenSettingsModal } from '../../my-pages.reducer';
import useAppDispatch from '../../../../hooks/useAppDispatch';

export const MyPagesMobileModal: FC = () => {
  const page = useActivePage();
  const navigate = useNavigate();
  const onSave = useSavePage();
  const dispatch = useAppDispatch();
  const onPreview = () => {
    navigate(generatePath(r.myPages.preview, { id: page?.id }));
  };

  const onSaveClick = () => {
    toast.success('Изменения сохранены');
    return onSave();
  };

  const onSettingsClick = () => {
    dispatch(setOpenSettingsModal(true));
  };

  return (
    <Menu>
      <Menu.Button as={IconButton} iconName="dots" iconSize={14} className={styles.Dots} />
      <Menu.Items className={styles.MenuItems}>
        <Menu.Button
          as={IconButton}
          iconName="arrow-up"
          iconSize={14}
          className={styles.CloseIcon}
        />
        <Menu.Item as="span" onClick={onSaveClick}>
          Сохранить
        </Menu.Item>
        <Menu.Item as="span" onClick={onPreview}>
          Опубликовать
        </Menu.Item>
        <Menu.Item as="span" onClick={onPreview}>
          Просмотреть
        </Menu.Item>
        <Menu.Item as="span" onClick={onSettingsClick}>
          Настройки
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};
