import r from 'constants/routes';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import FeedbackCard from '../FeedbackCard';
import partyFace from 'assets/svg/auth/feedback/partyFace.svg';

const AccountRecovered: FC = () => {
  const navigate = useNavigate();

  return (
    <FeedbackCard
      title="Аккаунт восстановлен"
      text="Поздравляем! Ваш аккаунт восстановлен. Теперь можно снова начинать работу!"
      button={{ text: 'Начнем', onClick: () => navigate(r.auth.signIn) }}
      img={partyFace}
    />
  );
};

export default AccountRecovered;
