import { FC, useDeferredValue, useMemo, useState } from 'react';
import Input from 'components/Input';
import styles from './ParsingModal.module.scss';
import Button from 'components/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TNewParsingForm } from '../../../vk.types';
import { useFindCityFormParsingModalQuery, useNewParsingMutation } from '../../../vk.api';
import { debounce } from 'lodash-es';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { vkNewParsingFolderFormSchema } from '../../../vk.schema';
import ModalWithForm from 'components/ModalWithForm';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import toast from 'toasts';
import { errorStyle, selectStyles } from './utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ParsingModal: FC<Props> = ({ isOpen, onClose }) => {
  const [searchInput, setSearchInput] = useState<string>('Москва');
  const { id } = useParams();
  const city = useDeferredValue(searchInput ?? 'Москва');
  const { data } = useFindCityFormParsingModalQuery({ city });
  const [parse] = useNewParsingMutation();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<TNewParsingForm>({
    mode: 'onChange',
    resolver: yupResolver(vkNewParsingFolderFormSchema()),
  });

  const gendersMap = Object.freeze({
    male: 'Мужской',
    female: 'Женский',
  });

  const phoneStateMap = Object.freeze({
    hasPhone: 'Есть номер',
    hasNotPhone: 'Нет номера',
  });

  const debouncedHandleChange = debounce(event => {
    setSearchInput(event);
  }, 500);

  const makeGenderOptions = useMemo(() => {
    const genders = [gendersMap.male, gendersMap.female];
    return genders.map(item => {
      return {
        label: item,
        value: item,
      };
    });
  }, []);

  const makeOptionsOptions = useMemo(() => {
    const phoneState = [phoneStateMap.hasPhone, phoneStateMap.hasNotPhone];
    return phoneState.map(item => {
      return {
        label: item,
        value: item,
      };
    });
  }, []);

  const makeCityOptions = useMemo(() => {
    return data?.data.map(item => {
      return {
        label: item.name,
        value: item.id + '',
      };
    });
  }, [data]);

  const onSubmit: SubmitHandler<TNewParsingForm> = async formData => {
    const tempForm = { ...formData };
    if (tempForm.sex === gendersMap.male) tempForm.sex = 2;
    else tempForm.sex = 1;
    tempForm.needPhoneNumber = tempForm.needPhoneNumber === phoneStateMap.hasPhone;
    const res = await parse({ groupId: id + '', ...tempForm });
    if ('data' in res) {
      toast.success('Успешно!');
      reset();
      onClose();
    } else {
      toast.error('Ooops');
    }
  };

  return (
    <ModalWithForm
      isOpen={isOpen}
      isValid={isValid}
      onClose={onClose}
      text={'Укажите параметры для парсинга данных пользователей в выбранной папке.'}
      title={'Новый парсинг'}
      onSubmit={handleSubmit(onSubmit)}
      noBtn
    >
      <Input
        register={register('name')}
        invalid={!!errors.name?.message}
        errorMsg={errors.name?.message}
        wrapperClassName={styles.Input}
        label="Название *"
      />
      <div className={styles.SelectInput}>
        <Controller
          control={control}
          name="cityId"
          render={({ field }) => (
            <Select
              className={styles.Select}
              classNamePrefix={styles.SelectContainer}
              components={{
                IndicatorSeparator: () => null,
              }}
              defaultValue={
                field.value
                  ? { label: field.value, value: field.value }
                  : { label: 'Город', value: '1' }
              }
              onChange={selectedOption => {
                field.onChange(selectedOption?.value);
              }}
              onInputChange={e => {
                debouncedHandleChange(e);
              }}
              options={makeCityOptions}
              styles={
                errors.cityId?.message
                  ? {
                      ...selectStyles,
                      ...errorStyle,
                    }
                  : { ...selectStyles }
              }
            />
          )}
        />
      </div>
      <div className={styles.SelectInput}>
        <Controller
          control={control}
          name="sex"
          defaultValue={gendersMap.male}
          render={({ field }) => (
            <Select
              className={styles.Select}
              classNamePrefix={styles.SelectContainer}
              components={{
                IndicatorSeparator: () => null,
              }}
              defaultValue={
                field.value
                  ? { label: field.value, value: field.value }
                  : { label: gendersMap.male, value: gendersMap.male }
              }
              onChange={selectedOption => field.onChange(selectedOption?.value)}
              options={makeGenderOptions}
              styles={selectStyles}
              placeholder={'Пол'}
            />
          )}
        />
      </div>
      <p>Возраст</p>
      <div className={styles.Inputs}>
        <Input
          register={register('ageFrom')}
          invalid={!!errors.ageFrom?.message}
          errorMsg={errors.ageFrom?.message}
          label="от"
          wrapperClassName={styles.SmallerInput}
          type={'number'}
        />
        <Input
          register={register('ageTo')}
          invalid={!!errors.ageTo?.message}
          errorMsg={errors.ageTo?.message}
          wrapperClassName={styles.SmallerInput}
          label="до"
          type={'number'}
        />
      </div>
      <div className={styles.SelectInput}>
        <Controller
          control={control}
          name="needPhoneNumber"
          defaultValue="Есть номер"
          render={({ field }) => (
            <Select
              className={styles.Select}
              classNamePrefix={styles.SelectContainer}
              components={{
                IndicatorSeparator: () => null,
              }}
              defaultValue={
                field.value
                  ? { label: field.value, value: field.value }
                  : { label: phoneStateMap.hasPhone, value: phoneStateMap.hasPhone }
              }
              onChange={selectedOption => {
                field.onChange(selectedOption?.value);
              }}
              options={makeOptionsOptions}
              styles={selectStyles}
              placeholder={'Пол'}
            />
          )}
        />
      </div>
      <div className={styles.Buttons}>
        <Button
          onClick={() => {
            reset();
            onClose();
          }}
          size={'m'}
          className={styles.Btn}
          variant={'tetriary'}
        >
          Закрыть
        </Button>
        <Button type={'submit'} size={'m'} className={styles.Btn}>
          Начать
        </Button>
      </div>
    </ModalWithForm>
  );
};

export default ParsingModal;
