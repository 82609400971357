import { ChangeEvent, FC, useRef, useState } from 'react';
import Icon from 'components/Icon';
import styles from './TimeDisplay.module.scss';
import { blockInvalidChar } from 'utils/blockInvalidChar';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import useClickOutside from 'hooks/useClickOutside';

type Props = {
  setDay?: (day: Dayjs) => void;
};

const TimeDisplay: FC<Props> = ({ setDay }) => {
  const [open, setOpen] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  const now = dayjs();
  const { register, setValue, watch } = useForm<{
    hour: number;
    minute: number;
  }>({
    defaultValues: {
      hour: now.hour(),
      minute: now.minute(),
    },
  });

  const values = watch();

  useClickOutside(divRef, () => setOpen(false));

  const onDropDownClickHandler = () => setOpen(p => !p);

  const handleHourChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputNumber = event.target.value;

    if (!Number.isNaN(Number(inputNumber)) && Number(inputNumber) <= 23) {
      setValue('hour', +inputNumber);
    }
  };

  const handleMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputNumber = event.target.value;

    if (!Number.isNaN(Number(inputNumber)) && Number(inputNumber) <= 59) {
      setValue('minute', +inputNumber);
    }
  };

  const onSubmit = () => {
    const date = now.set('hour', values.hour).set('minute', values.minute);
    if (setDay) setDay(date);
    setOpen(false);
  };

  return (
    <div ref={divRef} className={styles.MainContainer}>
      <div onClick={onDropDownClickHandler} className={styles.DateDisplay}>
        <p className={styles.Text}>Время:</p>
        <div className={styles.TimeWrap}>
          <Icon iconName={'add-big'} />
        </div>
      </div>
      {open && (
        <div className={styles.Dropdown}>
          <label className={styles.DropPar}>Выбрать время</label>
          <form className={styles.Form} action="">
            <div>
              <input
                className={styles.TimeInput}
                type="number"
                {...register('hour')}
                min={0}
                max={23}
                value={values.hour}
                onKeyDown={blockInvalidChar}
                onChange={handleHourChange}
              />
              <span>:</span>
              <input
                className={styles.TimeInput}
                type="number"
                min={0}
                max={59}
                {...register('minute')}
                value={values.minute}
                onKeyDown={blockInvalidChar}
                onChange={handleMinChange}
              />
            </div>
            <Button className={styles.Button} type="button" variant="tetriary" onClick={onSubmit}>
              Сохранить
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default TimeDisplay;
