import { FC } from 'react';
import classNames from 'classnames';

import styles from './ActivityIndicator.module.scss';

type Props = {
  active: boolean;
  className?: string;
};

const ActivityIndicator: FC<Props> = ({ active, className }) => {
  return <div className={classNames(styles.ActivityIndicator, className)} data-active={active} />;
};

export default ActivityIndicator;
