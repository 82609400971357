import { FC } from 'react';

import styles from './Loading.module.scss';
import loadingImg from 'assets/svg/loading.svg';

const Loading: FC = () => {
  return (
    <div className={styles.Wrapper}>
      <img className={styles.Img} src={loadingImg} alt="Loading" width={40} height={52} />
      <p className={styles.Text}>Ожидайте, идет загрузка</p>
    </div>
  );
};

export default Loading;
