import { FC, useState } from 'react';
import isEmpty from 'lodash-es/isEmpty';

import CardButton from 'components/CardButton';
import EditorForm from '../EditorForm';
import EditorTitle from '../EditorTitle';
import NoAddedButtons from '../NoAddedButtons';
import ButtonEditor from '../ButtonEditor';
import { LandingIdType } from 'modules/landing-generator/landing-generator.types';
import useDisclosure from 'hooks/useDisclosure';
import { useSelectLandingButtons } from 'modules/landing-generator/landing-generator.selectors';
import AddedButtons from '../AddedButtons';
import FormEditor from '../FormEditor';

type Props = {
  id: LandingIdType;
};

const LinksEditor: FC<Props> = ({ id }) => {
  const buttons = useSelectLandingButtons(id);
  const [btnId, setBtnId] = useState<LandingIdType | undefined>();
  const { open: editBtnOpen, onOpen: onEditBtnOpen, onClose: onEditBtnClose } = useDisclosure();
  const { open: editFormOpen, onOpen: onEditFormOpen, onClose: onEditFormClose } = useDisclosure();

  const handleEditButton = (buttonId: LandingIdType) => {
    setBtnId(buttonId);
    onEditBtnOpen();
  };

  const handleGoBackFromButtonEditor = () => {
    setBtnId(undefined);
    onEditBtnClose();
  };

  return editBtnOpen ? (
    <ButtonEditor landingId={id} buttonId={btnId} onBack={handleGoBackFromButtonEditor} />
  ) : editFormOpen ? (
    <FormEditor id={id} onBack={onEditFormClose} />
  ) : (
    <>
      <EditorForm>
        <EditorTitle>Добавление кнопок</EditorTitle>
        <CardButton onClick={onEditBtnOpen} icon="link">
          Добавить ссылку
        </CardButton>
        <CardButton icon="edit-form" onClick={onEditFormOpen}>
          Добавить форму
        </CardButton>
      </EditorForm>
      <EditorForm>
        <EditorTitle>Добавленные кнопки</EditorTitle>
        {isEmpty(buttons) ? (
          <NoAddedButtons />
        ) : (
          <AddedButtons buttons={buttons} onEdit={handleEditButton} />
        )}
      </EditorForm>
    </>
  );
};

export default LinksEditor;
