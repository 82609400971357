import { FC } from 'react';
import classNames from 'classnames';

import styles from './Scenarios.module.scss';
import Button from 'components/Button';
import Title from 'components/Title';
import AddScenarioModal from 'modules/bot-builder/components/AddScenarioModal';
import useDisclosure from 'hooks/useDisclosure';
import ScenarioCard from 'modules/bot-builder/components/ScenarioCard';
import MailingCard from 'modules/bot-builder/components/MailingCard';
import SearchField from 'components/SearchField';
import IconButton from 'components/IconButton';

const Scenarios: FC = () => {
  const {
    open: addScenarioOpen,
    onOpen: onAddScenarioOpen,
    onClose: onAddScenarioClose,
  } = useDisclosure();

  return (
    <div className={styles.Page}>
      <div className={styles.MobileHeader}>
        <SearchField
          className={styles.SearchField}
          placeholder="Поиск по названию"
          onSearch={() => {}}
        />
        <IconButton
          className={styles.AddBtn}
          iconClassName={styles.AddBtnIcon}
          iconName="add"
          size={52}
          iconSize={28}
          onClick={onAddScenarioOpen}
          asCircle
          withBorder
        />
      </div>

      <section className={classNames(styles.Section, styles.Scenarios)}>
        <div className={styles.Header}>
          <Title noMargin>Сценарии</Title>
          <Button variant="primary" onClick={onAddScenarioOpen}>
            Создать +
          </Button>
        </div>
        <div className={styles.Grid}>
          <ScenarioCard
            id={1}
            name="Каталог опрыскивателей"
            created="2022/08/12"
            changed="2022/08/12"
          />
          <ScenarioCard id={2} name="Каталог опрыскивателей ВИП" created="2022/08/12" />
          <ScenarioCard
            id={3}
            name="Каталог опрыскивателей"
            created="2022/08/12"
            changed="2022/09/21"
          />
        </div>
      </section>

      <section className={classNames(styles.Section, styles.Mailing)}>
        <div className={styles.Header}>
          <Title noMargin>Рассылки</Title>
          <Button variant="primary">Создать +</Button>
        </div>
        <div className={styles.List}>
          <MailingCard
            id={1}
            name="Каталог опрыскивателей"
            created="2022/08/12"
            changed="2022/08/12"
            active
          />
          <MailingCard
            id={2}
            name="Каталог опрыскивателей"
            created="2022/08/12"
            active={false}
            shouldRepeat
          />
        </div>
      </section>

      <AddScenarioModal isOpen={addScenarioOpen} onClose={onAddScenarioClose} />
    </div>
  );
};

export default Scenarios;
