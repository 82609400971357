import {
  EClickAction,
  EComponentType,
  ESocialNetwork,
  ETextStyle,
  EImageStyle,
  EFormField,
} from './my-pages.types';

export const COMPONENTS_NAMES: Record<EComponentType, string> = {
  [EComponentType.BLOCK]: 'Блок',
  [EComponentType.BUTTON]: 'Кнопка',
  [EComponentType.EMAIL]: 'Email',
  [EComponentType.FORM]: 'Форма заявки',
  [EComponentType.IMAGE]: 'Картинка',
  [EComponentType.PHONE]: 'Телефон',
  [EComponentType.SOCIAL_NETWORK]: 'Социальная сеть',
  [EComponentType.TEXT]: 'Текст',
  [EComponentType.YOUTUBE_VIDEO]: 'Видео',
};

export const TEXT_STYLES_NAMES: Record<ETextStyle, string> = {
  [ETextStyle.HEADING]: 'Большой заголовок',
  [ETextStyle.TITLE]: 'Заголовок',
  [ETextStyle.SUBTITLE]: 'Подзаголовок',
  [ETextStyle.PARAGRAPH]: 'Текст',
  [ETextStyle.SMALL_TEXT]: 'Маленький текст',
};

export const SOCIAL_NETWORKS_NAMES: Record<ESocialNetwork, string> = {
  [ESocialNetwork.INSTAGRAM]: 'Instagram',
  [ESocialNetwork.TELEGRAM]: 'Телеграм',
  [ESocialNetwork.VIBER]: 'Viber',
  [ESocialNetwork.VK]: 'Вконтакте',
  [ESocialNetwork.WHATSAPP]: 'WhatsApp',
};

export const CLICK_ACTIONS_NAMES: Omit<Record<EClickAction, string>, EClickAction.FORM> = {
  [EClickAction.ANCHOR]: 'Переход к якорю',
  [EClickAction.LINK]: 'Переход по ссылке',
  [EClickAction.NONE]: 'Нет',
};

export const IMAGE_STYLE_NAMES: Record<EImageStyle, string> = {
  [EImageStyle.FILL]: 'Заполнить',
  [EImageStyle.FIT]: 'Подогнать',
  [EImageStyle.REPEAT]: 'Повторять',
  [EImageStyle.CROP]: 'Обрезать',
};

export const FORM_FIELDS_NAMES: Record<EFormField, string> = {
  [EFormField.DATE]: 'Дата и время',
  [EFormField.FIRST_NAME]: 'Имя',
  [EFormField.LAST_NAME]: 'Фамилия',
  [EFormField.EMAIL]: 'Email',
  [EFormField.PHONE]: 'Номер телефона',
  [EFormField.COUNTRY]: 'Страна',
  [EFormField.POSITION]: 'Должность',
  [EFormField.OTHER]: 'Другое',
};

export const pageName = /^[0-9a-zA-Zа-яА-Я\s]*$/;
export const pageLink = /^[0-9a-zA-Z]*$/;

export const settingItems = ['Перейти', 'Удалить'];

export const defaultBackground = 'RGBA(0, 0, 0, 0)';
