import { FC } from 'react';

import styles from './PostCard.module.scss';
import useDisclosure from 'hooks/useDisclosure';
import Menu from 'components/Menu';
import Icon from 'components/Icon';
import DateTime from 'components/DateTime';

import PostModalStory from '../PostModalStory';
import PostModalPost from '../PostModalPost';
import { useVkAccount } from 'modules/account/account.selector';
import {
  useDeleteAutomationPostMutation,
  useDeleteAutomationStoryMutation,
  useDeleteAutoTranslationMutation,
} from 'modules/vk/vk.api';
import dayjs from 'dayjs';
import TranslationModalPost from '../TranslationModalPost';
import classNames from 'classnames';

export type PostCardProps = {
  id?: number;
  date?: string;
  img?: string | null;
  text?: string;
  type: 'story' | 'post' | 'translation';
  time?: string;
  title?: string;
  isDone?: boolean;
  fileType?: string;
};

const PostCard: FC<PostCardProps> = ({
  id,
  date,
  img,
  text,
  type,
  time,
  isDone,
  title,
  fileType,
}) => {
  const vkId = useVkAccount();
  const { open: editOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [deleteStory] = useDeleteAutomationStoryMutation();
  const [deletePost] = useDeleteAutomationPostMutation();
  const [deleteTranslation] = useDeleteAutoTranslationMutation();
  const videos = new Set(['mp3', 'mp4', 'm4v']);
  const isVideo = videos.has(fileType as string);

  const onDelete = async () => {
    if (!vkId) return;

    try {
      switch (type) {
        case 'story':
          if (id) await deleteStory({ storyId: id });
          break;
        case 'post':
          if (id) await deletePost({ postId: id });
          break;
        case 'translation':
          if (id) await deleteTranslation({ stramId: String(id) });
          break;
      }
    } catch {}
  };

  const combinedDateTime = dayjs(`${date} ${time}`, 'DD.MM.YYYY HH:mm').format();

  return (
    <>
      {/* TODO: fallback image? */}
      <article
        className={classNames(styles.Card, {
          [styles.CardVideo]: isVideo,
        })}
        style={{ backgroundImage: img ? `url(${img})` : undefined }}
      >
        <Menu
          className={styles.Menu}
          contextClassName={styles.Context}
          items={[
            { label: 'Редактировать', isSelected: false, onClick: onEditOpen },
            { label: 'Удалить', isSelected: false, onClick: onDelete, className: styles.Delete },
          ]}
          iconName="options-vertical"
          iconSize={24}
        >
          <Icon className={styles.Icon} iconName="options-vertical" size={24} />
        </Menu>

        <div className={styles.Content}>
          <div className={styles.Planned}>
            <span className={styles.Label}>{isDone ? title : 'Запланировано'}</span>
            <DateTime date={combinedDateTime} />
          </div>
          <p className={styles.Text}>{text}</p>
        </div>
        {isVideo && (
          <video className={styles.MyVideo} autoPlay loop muted>
            <source src={img as string} type="video/mp4" />
          </video>
        )}
      </article>

      {type === 'story' && <PostModalStory isOpen={editOpen} onClose={onEditClose} postId={id} />}
      {type === 'post' && <PostModalPost isOpen={editOpen} onClose={onEditClose} postId={id} />}
      {type === 'translation' && editOpen && (
        <TranslationModalPost isOpen={editOpen} onClose={onEditClose} postId={id} />
      )}
    </>
  );
};

export default PostCard;
