export const videoTutorials = Object.freeze({
  workWithGroups: 'https://youtu.be/dQ7GvIuwvPk?si=BEg3L-PjGPF89BMK',
  autoPostingAutomation: 'https://youtu.be/rBgTbiNKEY4?si=l_tryl-l3BeY6vd5',
  autoStoriesAutomation: 'https://youtu.be/nIc4f0XE8oQ?si=N5nvjdinndLZqSm0',
  autoBroadcastingAutomation: 'https://youtu.be/GLCmqFgdwT0?si=ncw_d6nIE8JXRd9e',
  workWithFriendsSection: 'https://youtu.be/J7BOvNvcPAs?si=umS8ABmGNnjbJX',
  workWithStatisticsAndRequestsInBUSINESSCARD: 'https://youtu.be/AeF7uWJEouM?si=4qdwOpVW70EeW0pF',
  createBusinessCardSite: 'https://youtu.be/SmEKNT0FLG4?si=GGhYycP47cFJZy0g',
  createBusinessCardSitewithoutTemplate: 'https://youtu.be/fKoZobuhdj8?si=3F9mOyGLbqXuGPZ-',
  bot_administrator: 'https://youtu.be/gBWu_UBRcqM?si=d3mjcHvBVRqQRldZ',
  bot_greeting: 'https://youtu.be/1ResE7PpqvM?si=5SCHX7dbAr2dyk4P',
  token_reception: 'https://youtu.be/YZxoUsC-cos?si=KUDtRfci3NDsxc8N',
  bot_setup: 'https://youtu.be/OYoGWYQl7wI?si=lhqhY6CPCG1TbDX3',
  bot_broadcast: 'https://youtu.be/JVk8A3yd9-A?si=-UnuzlX0mVQ2ivAl',
});
