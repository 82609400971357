import { tgApi as api } from 'config/http.tg';
import {
  TDataResponse,
  TResponseData,
  TWelcomeBotContactsParams,
  TWelcomeBotContactsStatistic,
  TWelcomeBotMessage,
  WelcomeBot,
} from '../telegram.types';
import { EAppTGTagType, TResponse } from '../../../interfaces/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    adminBotIdWelcomeMessagesControllerCreateWelcomeMessage: build.mutation<
      AdminBotIdWelcomeMessagesControllerCreateWelcomeMessageApiResponse,
      AdminBotIdWelcomeMessagesControllerCreateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/welcomeMessages`,
        method: 'POST',
        body: queryArg.botAdminCreateWelcomeMessagePayload,
      }),
    }),
    adminBotIdWelcomeMessagesControllerGetWelcomeMessageList: build.query<
      AdminBotIdWelcomeMessagesControllerGetWelcomeMessageListApiResponse,
      AdminBotIdWelcomeMessagesControllerGetWelcomeMessageListApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/${queryArg.botId}/welcomeMessages/list`,
        method: 'POST',
        body: queryArg.botAdminWelcomeMessageListParams,
      }),
    }),
    toggleWelcomeBotMessage: build.mutation<TResponse<unknown>, { messageId: string }>({
      query: ({ messageId }) => ({
        url: `/bot/welcome/message/${messageId}/toggle`,
        method: 'POST',
      }),
      invalidatesTags: [EAppTGTagType.WELCOME_BOT],
    }),
    welcomeBotMessage: build.mutation<TResponse<unknown>, { text: string; service: string }>({
      query: body => ({
        url: '/bot/welcome/message',
        method: 'POST',
        body,
      }),
      invalidatesTags: [EAppTGTagType.WELCOME_BOT],
    }),
    welcomeGetChangeBotMessage: build.query<TDataResponse<TWelcomeBotMessage[]>, unknown>({
      query: () => ({
        url: '/bot/welcome/message',
        method: 'GET',
      }),
      providesTags: [EAppTGTagType.WELCOME_BOT, EAppTGTagType.WELCOME_MESSAGES],
    }),
    getWelcomeBotMessageById: build.query<TDataResponse<TWelcomeBotMessage>, { messageId: string }>(
      {
        query: ({ messageId }) => ({
          url: `/bot/welcome/message/${messageId}`,
          method: 'GET',
        }),
        providesTags: [EAppTGTagType.WELCOME_MESSAGES],
      },
    ),
    updateWelcomeBotMessageById: build.mutation<unknown, { messageId: string; text: string }>({
      query: ({ messageId, ...body }) => ({
        url: `/bot/welcome/message/${messageId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [EAppTGTagType.WELCOME_MESSAGES],
    }),
    welcomeGetBotContact: build.query<
      TDataResponse<TWelcomeBotContactsStatistic[]>,
      Partial<TWelcomeBotContactsParams>
    >({
      query: params => ({
        url: '/bot/welcome/contacts',
        method: 'GET',
        params,
      }),
    }),
    getWelcomeBot: build.query<TResponseData<WelcomeBot>, unknown>({
      query: () => ({
        url: '/bot/welcome',
        method: 'GET',
      }),
      providesTags: [EAppTGTagType.WELCOME_BOT],
    }),

    adminBotWelcomeMessagesControllerUpdateWelcomeMessageStatus: build.mutation<
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiResponse,
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages/${queryArg.welcomeMessageId}/toggle`,
        method: 'PATCH',
      }),
    }),
    adminBotWelcomeMessagesControllerUpdateWelcomeMessage: build.mutation<
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiResponse,
      AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages/${queryArg.welcomeMessageId}`,
        method: 'PATCH',
        body: queryArg.botAdminUpdateWelcomeMessagePayload,
      }),
    }),
    adminBotWelcomeMessagesControllerDeleteWelcomeMessage: build.mutation<
      AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiResponse,
      AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin-bot/welcomeMessages/${queryArg.welcomeMessageId}`,
        method: 'DELETE',
      }),
    }),
    welcomeBotControllerGetBot: build.query<
      WelcomeBotControllerGetBotApiResponse,
      WelcomeBotControllerGetBotApiArg
    >({
      query: queryArg => ({ url: `/welcome-bot/${queryArg.id}/bot` }),
    }),
    welcomeBotControllerDeleteBot: build.mutation<
      WelcomeBotControllerDeleteBotApiResponse,
      WelcomeBotControllerDeleteBotApiArg
    >({
      query: queryArg => ({ url: `/welcome-bot/${queryArg.id}`, method: 'DELETE' }),
    }),
    welcomeBotControllerStartAuthentication: build.mutation<
      WelcomeBotControllerStartAuthenticationApiResponse,
      WelcomeBotControllerStartAuthenticationApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/auth`,
        method: 'POST',
        body: queryArg.createBotWelcomePayload,
      }),
    }),
    welcomeBotControllerFinishAuthentication: build.mutation<
      WelcomeBotControllerFinishAuthenticationApiResponse,
      WelcomeBotControllerFinishAuthenticationApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/code`,
        method: 'POST',
        body: queryArg.finishAuthRequest,
      }),
    }),

    welcomeBotControllerGetBotList: build.query<
      WelcomeBotControllerGetBotListApiResponse,
      WelcomeBotControllerGetBotListApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/list`,
        method: 'POST',
        body: queryArg.botWelcomeListParams,
      }),
    }),
    welcomeBotControllerToggleBot: build.mutation<
      WelcomeBotControllerToggleBotApiResponse,
      WelcomeBotControllerToggleBotApiArg
    >({
      query: queryArg => ({ url: `/welcome-bot/${queryArg.id}/toggle`, method: 'PATCH' }),
    }),
    welcomeBotControllerSendMessageByServer: build.mutation<
      WelcomeBotControllerSendMessageByServerApiResponse,
      WelcomeBotControllerSendMessageByServerApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/send_server`,
        method: 'POST',
        body: queryArg.sendWelcomeMessagePayloadByServer,
        headers: { 'X-Stars-key': queryArg['X-Stars-key'] },
      }),
    }),
    welcomeBotControllerGetKey: build.query<
      WelcomeBotControllerGetKeyApiResponse,
      WelcomeBotControllerGetKeyApiArg
    >({
      query: () => ({ url: `/welcome-bot/key` }),
    }),
    welcomeBotIdMessagesControllerCreateWelcomeMessage: build.mutation<
      WelcomeBotIdMessagesControllerCreateWelcomeMessageApiResponse,
      WelcomeBotIdMessagesControllerCreateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/${queryArg.botId}/welcomeMessages`,
        method: 'POST',
        body: queryArg.botWelcomeCreateWelcomeMessagePayload,
      }),
    }),
    welcomeBotIdMessagesControllerGetWelcomeMessageList: build.query<
      WelcomeBotIdMessagesControllerGetWelcomeMessageListApiResponse,
      WelcomeBotIdMessagesControllerGetWelcomeMessageListApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/${queryArg.botId}/welcomeMessages/list`,
        method: 'POST',
        body: queryArg.botWelcomeWelcomeMessageListParams,
      }),
    }),
    welcomeBotMessagesControllerUpdateWelcomeMessageStatus: build.mutation<
      WelcomeBotMessagesControllerUpdateWelcomeMessageStatusApiResponse,
      WelcomeBotMessagesControllerUpdateWelcomeMessageStatusApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/welcomeMessages/${queryArg.welcomeMessageId}/toggle`,
        method: 'PATCH',
      }),
    }),
    welcomeBotMessagesControllerUpdateWelcomeMessage: build.mutation<
      WelcomeBotMessagesControllerUpdateWelcomeMessageApiResponse,
      WelcomeBotMessagesControllerUpdateWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/welcomeMessages/${queryArg.welcomeMessageId}`,
        method: 'PATCH',
        body: queryArg.botWelcomeUpdateWelcomeMessagePayload,
      }),
    }),
    welcomeBotMessagesControllerDeleteWelcomeMessage: build.mutation<
      WelcomeBotMessagesControllerDeleteWelcomeMessageApiResponse,
      WelcomeBotMessagesControllerDeleteWelcomeMessageApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/welcomeMessages/${queryArg.welcomeMessageId}`,
        method: 'DELETE',
      }),
    }),
    welcomeBotIdContactsControllerGetContactList: build.query<
      WelcomeBotIdContactsControllerGetContactListApiResponse,
      WelcomeBotIdContactsControllerGetContactListApiArg
    >({
      query: queryArg => ({
        url: `/welcome-bot/${queryArg.botId}/contacts/list`,
        method: 'POST',
        body: queryArg.botWelcomeContactListParams,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as tgWelcomeApiInjected };
export type AdminBotIdWelcomeMessagesControllerCreateWelcomeMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type AdminBotIdWelcomeMessagesControllerCreateWelcomeMessageApiArg = {
  botId: string;
  botAdminCreateWelcomeMessagePayload: BotAdminCreateWelcomeMessagePayload;
};
export type AdminBotIdWelcomeMessagesControllerGetWelcomeMessageListApiResponse =
  /** status 200  */ BotAdminWelcomeMessageListResponse;
export type AdminBotIdWelcomeMessagesControllerGetWelcomeMessageListApiArg = {
  botId: string;
  botAdminWelcomeMessageListParams: BotAdminWelcomeMessageListParams;
};
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageStatusApiArg = {
  welcomeMessageId: number;
};
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerUpdateWelcomeMessageApiArg = {
  welcomeMessageId: number;
  botAdminUpdateWelcomeMessagePayload: BotAdminUpdateWelcomeMessagePayload;
};
export type AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type AdminBotWelcomeMessagesControllerDeleteWelcomeMessageApiArg = {
  welcomeMessageId: number;
};
export type WelcomeBotControllerGetBotApiResponse = /** status 200  */ BotWelcomeTotal;
export type WelcomeBotControllerGetBotApiArg = {
  id: number;
};
export type WelcomeBotControllerDeleteBotApiResponse = /** status 200  */ DefaultResponse;
export type WelcomeBotControllerDeleteBotApiArg = {
  id: number;
};
export type WelcomeBotControllerStartAuthenticationApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotControllerStartAuthenticationApiArg = {
  createBotWelcomePayload: CreateBotWelcomePayload;
};
export type WelcomeBotControllerFinishAuthenticationApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotControllerFinishAuthenticationApiArg = {
  finishAuthRequest: FinishAuthRequest;
};
export type WelcomeBotControllerGetBotListApiResponse = /** status 200  */ BotWelcomeListResponse;
export type WelcomeBotControllerGetBotListApiArg = {
  botWelcomeListParams: BotWelcomeListParams;
};
export type WelcomeBotControllerToggleBotApiResponse = /** status 200  */ DefaultResponse;
export type WelcomeBotControllerToggleBotApiArg = {
  id: number;
};
export type WelcomeBotControllerSendMessageByServerApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotControllerSendMessageByServerApiArg = {
  'X-Stars-key': string;
  sendWelcomeMessagePayloadByServer: SendWelcomeMessagePayloadByServer;
};
export type WelcomeBotControllerGetKeyApiResponse = /** status 200  */ string;
export type WelcomeBotControllerGetKeyApiArg = void;
export type WelcomeBotIdMessagesControllerCreateWelcomeMessageApiResponse =
  /** status 200  */
  DefaultResponse | /** status 201  */ undefined;
export type WelcomeBotIdMessagesControllerCreateWelcomeMessageApiArg = {
  botId: string;
  botWelcomeCreateWelcomeMessagePayload: BotWelcomeCreateWelcomeMessagePayload;
};
export type WelcomeBotIdMessagesControllerGetWelcomeMessageListApiResponse =
  /** status 200  */
  BotWelcomeWelcomeMessageListResponse;
export type WelcomeBotIdMessagesControllerGetWelcomeMessageListApiArg = {
  botId: string;
  botWelcomeWelcomeMessageListParams: BotWelcomeWelcomeMessageListParams;
};
export type WelcomeBotMessagesControllerUpdateWelcomeMessageStatusApiResponse =
  /** status 200  */ DefaultResponse;
export type WelcomeBotMessagesControllerUpdateWelcomeMessageStatusApiArg = {
  welcomeMessageId: number;
};
export type WelcomeBotMessagesControllerUpdateWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type WelcomeBotMessagesControllerUpdateWelcomeMessageApiArg = {
  welcomeMessageId: number;
  botWelcomeUpdateWelcomeMessagePayload: BotWelcomeUpdateWelcomeMessagePayload;
};
export type WelcomeBotMessagesControllerDeleteWelcomeMessageApiResponse =
  /** status 200  */ DefaultResponse;
export type WelcomeBotMessagesControllerDeleteWelcomeMessageApiArg = {
  welcomeMessageId: number;
};
export type WelcomeBotIdContactsControllerGetContactListApiResponse =
  /** status 200  */
  BotWelcomeContactListResponse;
export type WelcomeBotIdContactsControllerGetContactListApiArg = {
  botId: string;
  botWelcomeContactListParams: BotWelcomeContactListParams;
};
export type DefaultResponse = {
  status: boolean;
};
export type FileType = {
  mime: string;
  url: string;
};
export type BotAdminCreateWelcomeMessagePayload = {
  text?: string;
  files: FileType[];
};
export type BotAdminWelcomeMessageFile = {
  id: number;
  mime: string;
  url: string;
  welcomeMessageId: number;
};
export type BotAdminWelcomeMessageFull = {
  files: BotAdminWelcomeMessageFile[];
  id: number;
  text: string | null;
  status: boolean;
  botAdminId: number;
};
export type BotAdminWelcomeMessageListResponse = {
  list: BotAdminWelcomeMessageFull[];
  total: number;
};
export type BotAdminWelcomeMessageListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export type BotAdminUpdateWelcomeMessagePayload = {
  files?: FileType[];
  text?: string;
};
export type BotWelcomeCount = {
  contacts: number;
  messages: number;
};
export type BotWelcomeTotal = {
  _count: BotWelcomeCount;
  id: number;
  account: string;
  userId: string;
  status: boolean;
};
export type CreateBotWelcomePayload = {
  account: string;
};
export type FinishAuthRequest = {
  account: string;
  code: string;
};
export type BotWelcomeListResponse = {
  list: BotWelcomeTotal[];
  total: number;
};
export type BotWelcomeListParams = {
  range?: number[];
  sort: string[][];
  filter: string[][];
};
export type SendWelcomeMessagePayloadByServer = {
  userId: string;
  contact: {
    phone?: string;
    firstName?: string;
    lastName?: string;
    username?: string;
  };
};
export type BotWelcomeCreateWelcomeMessagePayload = {
  text?: string;
  files: FileType[];
};
export type BotWelcomeWelcomeMessageFile = {
  id: number;
  mime: string;
  url: string;
  welcomeMessageId: number;
};
export type BotWelcomeWelcomeMessageFull = {
  files: BotWelcomeWelcomeMessageFile[];
  id: number;
  text: string | null;
  status: boolean;
  botWelcomeId: number;
};
export type BotWelcomeWelcomeMessageListResponse = {
  list: BotWelcomeWelcomeMessageFull[];
  total: number;
};
export type BotWelcomeWelcomeMessageListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export type BotWelcomeUpdateWelcomeMessagePayload = {
  files?: FileType[];
  text?: string;
};
export type BotWelcomeContact = {
  id: number;
  username: string | null;
  phone: string;
  firstName: string | null;
  lastName: string | null;
  status: object;
  botWelcomeId: number;
};
export type BotWelcomeContactListResponse = {
  list: BotWelcomeContact[];
  total: number;
};
export type BotWelcomeContactListParams = {
  range?: number[];
  sort?: string[];
  filter?: string[];
};
export const {
  useWelcomeGetBotContactQuery,
  useWelcomeBotIdContactsControllerGetContactListQuery,
  useWelcomeBotMessageMutation,
  useGetWelcomeBotQuery,
  useWelcomeGetChangeBotMessageQuery,
  useGetWelcomeBotMessageByIdQuery,
  useUpdateWelcomeBotMessageByIdMutation,
} = injectedRtkApi;
