import { ETransactionType } from './finances.types';

export const transactionTypesMobile = (name: string) => {
  switch (name) {
    case 'PLAN_PURCHASE':
      return ETransactionType.PURCHASE;
    case 'DEPOSIT_PAYEER':
      return ETransactionType.INPUT;
    case 'WITHDRAWAL_PAYEER':
      return ETransactionType.OUTPUT;
    case 'DEPOSIT_FREE_KASSA':
      return ETransactionType.INPUT_FREE_KASSA;
    case 'WITHDRAWAL_FREE_KASSA':
      return ETransactionType.OUTPUT_FREE_KASSA;
    case 'REFERRAL_DEPOSIT':
      return ETransactionType.PAYOUTS;
    case 'ADMIN_BALANCE_MODIFICATION':
      return ETransactionType.MODIFICATION;
    case 'DEPOSIT_SOFTPAY':
      return ETransactionType.DEPOSIT_SOFTPAY;
  }
};
