import { FC } from 'react';
import classNames from 'classnames';

import styles from './UndoRedo.module.scss';
import IconButton from 'components/IconButton';

export type UndoRedoProps = {
  className?: string;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
};

const UndoRedo: FC<UndoRedoProps> = ({ undo, redo, canUndo, canRedo, className }) => {
  return (
    <div className={classNames(styles.Wrapper, className)}>
      <IconButton iconName="undo" iconSize={24} onClick={undo} disabled={!canUndo} />
      <IconButton iconName="redo" iconSize={24} onClick={redo} disabled={!canRedo} />
    </div>
  );
};

export default UndoRedo;
