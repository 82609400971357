import { FC } from 'react';
import Button from 'components/Button';
import styles from '../CommandsTableRow.module.scss';
import CommandModal from '../../../../CommandModal';
import useDisclosure from 'hooks/useDisclosure';

type Props = {
  id?: string;
};
const CommandsTableRowUpdateButton: FC<Props> = ({ id }) => {
  const { open, onOpen, onClose } = useDisclosure({ blockScroll: true });
  return (
    <>
      <Button
        onClick={onOpen}
        className={styles.Btn}
        centralIcon={'settings'}
        variant={'tetriary'}
      ></Button>
      {open && <CommandModal isOpen={open} onClose={onClose} commandId={id} />}
    </>
  );
};

export default CommandsTableRowUpdateButton;
