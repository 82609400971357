import { FC } from 'react';

import ToolsWrapper from '../../tools/ToolsWrapper';
import { Email as TEmail, NonNullableActiveComponent } from 'modules/my-pages/my-pages.types';
import InputOutline from 'components/InputOutline';
import AnchorInput from '../../tools/AnchorInput';
import AddTextBtn from '../../tools/AddTextBtn';
import useSetComponentProperty from 'modules/my-pages/hooks/useSetComponentProperty';

type Props = {
  component: NonNullableActiveComponent<TEmail>;
};

const Email: FC<Props> = ({ component }) => {
  const { hash, order, data } = component.component;
  const { setText, setProperty } = useSetComponentProperty(hash);
  const anchorActive = !!data.isAnchorActive;

  return (
    <ToolsWrapper>
      <InputOutline
        label="Email:"
        value={data.email}
        onChange={e => setText('email', e.target.value)}
        type="email"
      />
      <AnchorInput
        anchor={data.anchor}
        setAnchor={value => setText('anchor', value)}
        setAnchorActive={() => setProperty('isAnchorActive', !anchorActive)}
        active={anchorActive}
      />
      <AddTextBtn parentId={component.parent.hash} atOrder={order} />
    </ToolsWrapper>
  );
};

export default Email;
