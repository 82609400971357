import { FC, ReactNode } from 'react';

import styles from './ControlsPanel.module.scss';

type Props = {
  children: ReactNode;
};

const ControlsPanel: FC<Props> = ({ children }) => {
  return <div className={styles.Panel}>{children}</div>;
};

export default ControlsPanel;
