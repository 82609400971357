import { FC } from 'react';

import styles from './TransactionsList.module.scss';
import { UserTransaction } from 'modules/finances/finances.types';
import TransactionTypeSelector from '../TransactionTypeSelector';
import TransactionStatusSelector from '../TransactionStatusSelector';
import { useSelectFinancesFilters } from 'modules/finances/finances.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import { setFilter } from 'modules/finances/finances.reducer';
import TransactionCard from '../TransactionCard';
import { NoPaymentHistoryBlock } from '../NoPaymentHistoryBlock/NoPaymentHistoryBlock';

type Props = {
  items: UserTransaction[];
};

const TransactionsList: FC<Props> = ({ items }) => {
  const dispatch = useAppDispatch();
  const { type, status } = useSelectFinancesFilters();

  return (
    <div className={styles.Wrapper}>
      <nav className={styles.Nav}>
        <TransactionTypeSelector
          type={type}
          setType={value => dispatch(setFilter({ field: 'type', value }))}
        />
        <TransactionStatusSelector
          status={status}
          setStatus={value => dispatch(setFilter({ field: 'status', value }))}
        />
      </nav>
      <div className={styles.List}>
        {items.map(item => (
          <TransactionCard key={item.id} {...item} />
        ))}
      </div>
      {!items.length && <NoPaymentHistoryBlock type={'mobile'} />}
    </div>
  );
};

export default TransactionsList;
