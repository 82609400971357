import { FC, ReactNode, useMemo } from 'react';
import styles from './GroupsLayout.module.scss';
import emptySearch from 'assets/svg/empty-pages/emptySearch.svg';
import EmptyPage from 'components/EmptyPage';
import {
  useGetVkGroupParams,
  useGetVkSelectedGroupsId,
  useGetVkSelectedGroupsSubscribersCount,
} from '../../vk.selectors';
import useWindowSize from 'hooks/useWindowSize';
import Pagination from 'components/Pagination';
import GroupsSearch from '../GroupsSearch';
import useAppDispatch from 'hooks/useAppDispatch';
import { setVkGroupsParams } from '../../vk.reducer';
import { useNavigate } from 'react-router-dom';
import FormingFoldersBtn from '../FormingFoldersBtn';
import { useGetAllFoldersQuery } from '../../vk.api';
import Folders from '../Folders';
import { TFoldersItem } from '../../vk.types';
import { isEmpty, orderBy, size } from 'lodash-es';

type Props = {
  title: string;
  actionsTitle?: string;
  children: ReactNode;
  isEmptyPage: boolean;
};

const GroupsLayout: FC<Props> = ({ title, actionsTitle, children, isEmptyPage }) => {
  const { maxPageCount } = useGetVkGroupParams();
  const { data: groupsFolders, isFetching: isFoldersLoading } = useGetAllFoldersQuery();
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedIds = useGetVkSelectedGroupsId();
  const queryParams = new URLSearchParams(location.search);
  const subscribersCount = useGetVkSelectedGroupsSubscribersCount();
  const isMoreSubs = subscribersCount >= 500000 && size(selectedIds) > 1;

  const pageChange = (page: number) => {
    let offset;
    queryParams.set('page', `${page}`);
    if (page === 1) {
      offset = 0;
      queryParams.set('offset', '0');
      navigate({ search: queryParams.toString() });
      dispatch(setVkGroupsParams({ offset }));
    } else {
      offset = (page - 1) * 12;
      queryParams.set('offset', '' + offset);
      navigate({ search: queryParams.toString() });
      dispatch(setVkGroupsParams({ offset }));
    }
  };

  const groupsFoldersArr: TFoldersItem[] = useMemo(() => {
    return orderBy(groupsFolders?.data, 'id', 'desc') as TFoldersItem[];
  }, [groupsFolders]);

  return (
    <section className={styles.Section}>
      <h2 className={isMoreSubs ? styles.IsTitleError : styles.Title}>{title}</h2>
      {!isEmpty(groupsFoldersArr) && (
        <Folders foldersArr={groupsFoldersArr} isLoading={isFoldersLoading} />
      )}
      {width < 1367 && <GroupsSearch className={styles.SearchField} />}
      {width < 877 && <FormingFoldersBtn />}
      <div className={styles.Content}>
        <div className={styles.Actions}>
          {actionsTitle && <h2 className={styles.ActionsTitle}>{actionsTitle}</h2>}
          {isEmptyPage ? (
            <EmptyPage
              img={<img src={emptySearch} alt={'img'} />}
              title={'Для начала работы с группами найдите интересующую тему'}
              text={
                'Ищите интересующие вас сообщества и группируйте их в папки, создавайте парсинг, что бы найти свою целевую аудитория'
              }
              btnText={'Начать поиск'}
              onClick={() => {}}
            />
          ) : (
            <div className={styles.Grid}>{children}</div>
          )}
        </div>
        <Pagination
          onPageChange={num => pageChange(num)}
          offset={1}
          totalCount={maxPageCount && Math.ceil(maxPageCount / 12)}
          currPage={Number(queryParams.get('page'))}
        />
      </div>
    </section>
  );
};

export default GroupsLayout;
