import { FC } from 'react';
import classNames from 'classnames';

import styles from './FormRequestsList.module.scss';
import { FormGetRequest } from 'modules/landing-dashboard/landing-dashboard.types';
import FormRequestItem from '../FormRequestItem';
import { useApplicationsSelectIds } from 'modules/landing-dashboard/landing-dashboard.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import CheckBox from 'components/CheckBox';
import { handleSelectCheckBox } from 'modules/landing-dashboard/landing-dashboard.helper';

type Props = {
  items: FormGetRequest[];
  className?: string;
};

const FormRequestsList: FC<Props> = ({ items, className }) => {
  const applicationSelectIds = useApplicationsSelectIds();
  const dispatch = useAppDispatch();

  return (
    <div className={classNames(styles.List, className)}>
      {items.map(item => {
        const { id, isRead } = item;
        const checkedItem = applicationSelectIds?.includes(id as string);
        return (
          <div key={item.id} className={styles.container}>
            <CheckBox
              checked={applicationSelectIds?.includes(id as string) || isRead}
              onChange={() => handleSelectCheckBox(id as string, applicationSelectIds, dispatch)}
              type={checkedItem && !isRead ? 'mobile-blue-point' : 'small-circle-mobile'}
              className={styles.checkbox}
              disabled={isRead}
            />
            <FormRequestItem {...item} />
          </div>
        );
      })}

      {!items?.length && (
        <div>
          <h4 className={styles.NoApplications}>Заявки отсутствуют</h4>
        </div>
      )}
    </div>
  );
};

export default FormRequestsList;
