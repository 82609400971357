import { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './UserBalance.module.scss';
import r from 'constants/routes';
import env from 'config/env';
import { selectUser } from 'modules/account/account.selector';
import useAppSelector from 'hooks/useAppSelector';
import formatNumber from 'utils/formatNumber';

const UserBalance: FC = () => {
  const user = useAppSelector(selectUser);

  return (
    <Link to={r.finances.index} className={styles.Wallet}>
      <span>{formatNumber(Number(user?.balance || 0), 0)}</span>
      <span className={styles.Currency}>{env.CURRENCY}</span>
    </Link>
  );
};

export default UserBalance;
